export default function propsProvider(props){
    const {
        intl,
        checked,
        orderDetail,
        orderProducts,
        amountRefund,
        control,
        fileInputRef,
        handleSelectRow,
        handleSelectItem,
        handleUpload,
        handleClick,
        handleDeleteProductImage,
        productImages,
        openEditEmail,
        isDisabled,
        handleSubmitRefund,
        reasonRefund,
        isOpenReasonModal,
        closeReasonModal,
        openReasonModal,
        handleSelectReason,
        lstReason,
        handleSubmitReason,
        navigateTo,
        detailRefund,
        justSubmitSuccessfully
    } = props;

    return {
        intl,
        handleSubmitRefund,
        navigateTo,
        detailRefund,
        justSubmitSuccessfully,
        headerProps:{
            intl,
            checked,
            orderDetail,
            orderProducts,
            handleSelectRow,
            handleSelectItem,
            navigateTo,
            detailRefund,
            justSubmitSuccessfully,
            reviewDetailProps:{
                intl,
                detailRefund
            }
        },
        reasonProps:{
            intl,
            handleUpload,
            fileInputRef,
            handleClick,
            productImages,
            handleDeleteProductImage,
            reasonRefund,
            isOpenReasonModal,
            closeReasonModal,
            openReasonModal,
            handleSelectReason,
            lstReason,
            handleSubmitReason,
            detailRefund
        },
        noteProps:{
            intl,
            control,
            detailRefund
        },
        amountProps:{
            intl,
            amountRefund,
            detailRefund
        },
        emailProps:{
            intl,
            openEditEmail,
            isDisabled,
            control,
            detailRefund
        },
    }
}