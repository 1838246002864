import { ErrorMark } from 'assets/images';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { addPaymentCart } from 'features/Payment/paymentSlice';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Events from 'utils/Events';
import propsProvider from './CartPropsProvider';
import { deleteCartProducts, getVoucherList, handleCheckedAll, handleCheckedItems } from './cartSlice';
import CartMainView from './template/CartMainView';
import { defaultValues } from './template/subViews/VoucherModal/VoucherModal';
import { getEndOfDate } from 'utils/getEndOfDate';
import moment from 'moment';

const CartContainer = (props) => {
    const { dispatch, cart, history } = props;
    const intl = useIntl();
    const [modalContent, setModalContent] = useState({});
    const [currentStallId, setCurrentStallId] = useState('');
    const [cartItems, setCartItems] = useState(new Map());
    const [isOpenVoucherModal, setIsOpenVoucherModal] = useState(false);
    const [appliedVoucher, setAppliedVoucher] = useState(null);

    const { control, watch, handleSubmit, reset } = useForm({ defaultValues });
    const watchAllFields = watch();
    const isApplyDisabled = watchAllFields.coupon.length <= 0;

    const openVoucherModal = () => setIsOpenVoucherModal(true);
    const closeVoucherModal = () => setIsOpenVoucherModal(false);

    const { cartList, checkedCartItemStates, voucherList } = cart;

    const navigateTo = (path) => {
        history.push(path);
    };

    const isAllCheckedHandle = (state) => {
        let isAllChecked = true;

        state.forEach((value) => {
            if (!value.isChecked && value.quantityInStorage !== 0) isAllChecked = false;
        });

        return isAllChecked;
    };

    const checkItemsHandler = ({ type, payload }) => {
        if (type === 'all') {
            dispatch(handleCheckedAll());
        } else if (type === 'stall') {
            cartItems.forEach((value, key) => {
                if (key === payload) {
                    value.items.forEach((item, cartProductId) => {
                        if ((value.isChecked || item.isChecked === value.isChecked) && item.quantityInStorage !== 0) {
                            dispatch(handleCheckedItems(cartProductId));
                        }
                    });
                }
            });
        } else {
            dispatch(handleCheckedItems(payload));
        }
    };

    const deleteItemsHandler = ({ type, payload }) => {
        try {
            const modalPopupState = {
                title: intl.messages.confirm,
                closeText: intl.messages.cancel,
                confirmText: intl.messages.delete
            };
            let contentText;
            if (type === 'all') {
                Object.assign(modalPopupState, {
                    onConfirm: async () => {
                        for (const item of cartList) {
                            await dispatch(deleteCartProducts(item.id));
                        }
                        Events.emit(Config.eventsKey.updateCart);
                    }
                });
                contentText = intl.messages.deleteAllProductsMessage;
            } else if (type === 'stall') {
                Object.assign(modalPopupState, {
                    onConfirm: async () => {
                        const deletedCartProductIdList = [];
                        cartItems.get(payload).items.forEach((item) => {
                            deletedCartProductIdList.push(item.cartProductId);
                        });

                        for (const item of deletedCartProductIdList) {
                            await dispatch(deleteCartProducts(item));
                        }

                        Events.emit(Config.eventsKey.updateCart);
                    }
                });
                contentText = intl.messages.deleteAllProductsMessage;
            } else {
                Object.assign(modalPopupState, {
                    onConfirm: () => {
                        dispatch(deleteCartProducts(payload)).then(() => Events.emit(Config.eventsKey.updateCart));
                    }
                });
                contentText = intl.messages.deleteThisProductMessage;
            }

            modalPopupState.content = (
                <div className='d-flex justify-content-center align-items-center gap-2 modal-delete'>
                    <ErrorMark />
                    {contentText}?
                </div>
            );

            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
    };

    const voucherHandler = async (stallId) => {
        const params = {
            Sorts: '-expiry',
            Filters: `status==e22cbf21-506d-4d40-a37a-3e648f54d563,expiry>=${getEndOfDate(moment(new Date())).format(
                'YYYY/MM/DD'
            )}`
        };

        await dispatch(getVoucherList(params));
        openVoucherModal();
        setCurrentStallId(stallId);
    };

    const goToPdp = (id) => {
        navigateTo(`${pagePath.pdpUrl}/${id}`);
    };

    const goToPaymentPage = async () => {
        const itemStatesValues = Object.values(checkedCartItemStates).map((item, idx) => item && idx);
        const itemStatesKeys = Object.keys(checkedCartItemStates).map((item, idx) => {
            const found = itemStatesValues.find((el) => el === idx);
            if (found !== -1 && found !== undefined) return item;
        });
        const paymentCarts = itemStatesKeys
            .filter((item) => item)
            .map((item) => {
                return {
                    cartProductId: item,
                    voucherIdList: []
                };
            });
        if (appliedVoucher) {
            paymentCarts[0].voucherIdList = [appliedVoucher.id];
        }

        const res = await dispatch(addPaymentCart(paymentCarts)).unwrap();
        const { success } = res;
        if (success) {
            navigateTo(pagePath.paymentUrl);
        }
    };

    useEffect(() => {
        if (cartList) {
            const tempCartItems = new Map();

            cartList.forEach((cartItem, idx) => {
                const {
                    product_id: productId,
                    id: cartProductId,
                    extend_product,
                    extend_brand_voucher,
                    quantity: quantityInCart,
                    product_type_text,
                    category_ids
                } = cartItem;
                const {
                    name,
                    price,
                    origin_price,
                    quantity: quantityInStorage,
                    default_image,
                    manufacturer_id,
                    is_hidden,
                    is_delete
                } = extend_product;
                // temporarily set same stall Id
                const tempStallId = manufacturer_id || '0';

                const storedCartItem = {
                    name,
                    price,
                    origin_price,
                    quantityInCart,
                    quantityInStorage,
                    image: Config.apiConfig.endPoint + default_image,
                    isChecked: checkedCartItemStates[cartProductId],
                    productId,
                    cartProductId,
                    product_type_text,
                    category_ids,
                    is_hidden,
                    is_delete
                };

                if (tempCartItems.has(tempStallId)) {
                    tempCartItems.get(tempStallId).items.set(cartProductId, storedCartItem);

                    tempCartItems.get(tempStallId).isChecked = isAllCheckedHandle(tempCartItems.get(tempStallId).items);
                } else {
                    const items = new Map();
                    tempCartItems.set(tempStallId, {
                        stallName: intl.messages.product,
                        stallId: tempStallId,
                        isChecked: storedCartItem.isChecked,
                        items: items.set(cartProductId, storedCartItem),
                        voucherList: extend_brand_voucher || []
                    });
                }
            });

            setCartItems(tempCartItems);
        }
    }, [cartList, checkedCartItemStates, intl]);

    const cartContainerProps = {
        ...props,
        dispatch,
        navigateTo,
        intl,
        modalContent,
        deleteItemsHandler,
        voucherHandler,
        cartItems,
        checkItemsHandler,
        isAllCheckedHandle,
        isOpenVoucherModal,
        closeVoucherModal,
        currentStallId,
        control,
        handleSubmit,
        isApplyDisabled,
        reset,
        goToPaymentPage,
        goToPdp,
        voucherList,
        appliedVoucher,
        setAppliedVoucher
    };

    return <CartMainView {...propsProvider(cartContainerProps)} />;
};

export default CartContainer;
