export default function propsProvider(props) {
    const {
        intl,
        isOpenModal,
        handleOpenModal,
        handleCloseModal,
        handleSubmitFormModal,
        submitFilterProduct,
        setFilterData,
        isAddNew,
        setIsAddNew,
        handleAddNew,
        handleAddNewModal,
        setAddNewModal,
        isAddNewModal,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        flashDealFilter,
        setFlashDealFilter,
        flashDealEditData,
        setFlashDealEditData,
        isEdit,
        setIsEdit,
        isDublicate,
        setDublicate,
        startDealFilter,
        endtDealFilter,
        errorDate,
        setErrorDate,
        errorEndDate,
        setErrorEndDate
    } = props;

    return {
        intl,
        handleOpenModal,
        isAddNew,
        setIsAddNew,
        handleAddNew,
        handleAddNewModal,
        setAddNewModal,
        isAddNewModal,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        flashDealFilter,
        setFlashDealFilter,
        flashDealEditData,
        setFlashDealEditData,
        isEdit,
        setIsEdit,
        isDublicate,
        setDublicate,
        startDealFilter,
        endtDealFilter,
        errorDate,
        setErrorDate,
        errorEndDate,
        setErrorEndDate,
        shippingUnitsTableProps: {
            intl
        },
        voucherFormModalProps: {
            intl,
            isOpenModal,
            isAddNewModal,
            handleCloseModal,
            handleSubmitFormModal
        },
        filterBarProps: {
            intl,
            submitFilterProduct,
            setFilterData,
            setFlashDealFilter
        }
    };
}
