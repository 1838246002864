import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './categoryManagement.i18n';
import CategoryManagementContainer from './CategoryManagementContainer';

const CategoryManagement = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const categoryManagementProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...categoryManagementProps} childComponent={CategoryManagementContainer} />;
};

export default CategoryManagement;
