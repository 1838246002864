import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import orderApi from "api/orderApi"
import orderRefundApi from "api/orderRefundApi"
import serviceRequest from "app/serviceRequest"

const initialState=()=>{
    return{
        userRefund:[],
        lstProduct:[]
    }
}

export const getOrderRefund = createAsyncThunk('orderRefund/getOrderRefund', async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch:thunkAPI.dispatch,
        serviceMethod: orderRefundApi.getOrderRefund,
        payload:data
    })
})

export const postOrderRefund = createAsyncThunk('orderRefund/postOrderRefund',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderRefundApi.postOrderRefund,
        payload:data
    })
})

export const updateOrderRefund = createAsyncThunk('orderRefund/updateOrderRefund',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderRefundApi.updateOrderRefund,
        payload:data
    })
})

export const getOrderById = createAsyncThunk('order/getOrderById',async(orderId,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrderById,
        payload: orderId
    })
})

const userOrderRefund = createSlice({
    name:'userOrderRefund',
    initialState,
    reducers:{},
    extraReducers:(builders)=>{}
})

const {reducer} = userOrderRefund;
export default reducer;