import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import Radio from '@mui/material/Radio';

const PaymentMethod = (props) => {
    const { paymentMethodList, paymentMethod, setPaymentMethod } = props;

    const handleToggleMethod = (e, method) => {
        setPaymentMethod({
            id: method?.id,
            code: method?.code
        });
    };

    return (
        <div className='payment-methods'>
            <SectionTitle paled={false}>{'Phương thức thanh toán'}</SectionTitle>
            <div>
                {paymentMethodList &&
                    paymentMethodList.map((method, idx) => (
                        <div
                            key={method.id}
                            className='d-flex align-items-center'
                            style={{ marginBottom: idx !== paymentMethodList.length - 1 ? 16 : 0 }}>
                            <Radio
                                checked={paymentMethod?.code === method.code}
                                onChange={(e) => handleToggleMethod(e, method)}
                                name='payment-method'
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 16
                                    }
                                }}
                            />
                            <img src={method.img_link} alt={method.code} style={{ marginRight: '4px' }} />
                            <label
                                htmlFor='receive'
                                style={{ fontSize: 13, color: paymentMethod?.code === method.code && '#4568f2' }}>
                                {method.name}
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PaymentMethod;
