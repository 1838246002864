import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const brandApi = {
    getBrands: () => {
        const url = '/api/brands';
        return axiosClient.get(url);
    },
    getBrandById: (id) => {
        const url = `/api/brands/${id}`;
        return axiosClient.get(url);
    },
    updateBrand: (data) => {
        const {id, payload} = data;
        const url = `/api/brands/${id}`;
        return axiosClient.put(url, payload);
    },
    uploadBrandLogo: (data) => {
        const {id, file} = data;
        const url = `/api/brands/${id}/logo`;
        return axiosFormData.put(url, file);
    },
    getBrandAddresses: (params) => {
        const url = '/api/brandAddress';
        return axiosClient.get(url, {params});
    },
    addBrandAddress: (data) => {
        const url = '/api/brandAddress';
        return axiosClient.post(url, data);
    },
    updateBrandAddress: (data) => {
        const {id} = data;
        const url = `/api/brandAddress/${id}`;
        return axiosClient.put(url, data);
    },
    deleteBrandAddress: (id) => {
        const url = `/api/brandAddress/${id}`;
        return axiosClient.delete(url);
    }
};

export default brandApi;
