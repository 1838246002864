import React, {useRef} from 'react';
import {CameraChange} from 'assets/images';
import Config from 'configuration';

const ShopLogo = (props) => {
    const {intl, brandLogo, handleUploadLogo} = props;
    const fileRef = useRef(null);

    return (
        <div className='shop-info__avatar' style={{marginTop: '16px', paddingBottom: '16px'}}>
            <p style={{fontSize: '13px', color: '#333'}}>{intl.messages.shopLogoLable}</p>
            <div className='d-flex align-items-center' style={{gap: '10px'}}>
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer'
                    }}
                    onClick={() => fileRef.current.click()}>
                    {!brandLogo ? (
                        <CameraChange />
                    ) : (
                        <img
                            src={`${Config.apiConfig.endPoint}/${brandLogo}`}
                            alt='logo'
                            style={{width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover'}}
                        />
                    )}
                </div>
                <div style={{fontSize: '13px', color: '#9e9e9e'}}>
                    <p style={{margin: 0}}>{intl.messages.formatImage}</p>
                    <p style={{margin: 0}}>{intl.messages.imageRequirement}</p>
                </div>
            </div>
            <input
                ref={fileRef}
                type='file'
                name='store-logo'
                id='store-logo'
                style={{display: 'none'}}
                accept={['.png', '.jpeg', '.jpg']}
                onChange={handleUploadLogo}
            />
        </div>
    );
};

export default ShopLogo;
