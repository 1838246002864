import React, { useEffect, useRef, useState } from 'react';
import { checkOverflow } from 'utils/checkOverflow';
import { purifyHTML } from 'utils/purifyHTML';

const ReadMoreSection = (props) => {
    const { children, intl, setIsSectionOverflowed, productId } = props;
    const [isReadmore, setIsReadmore] = useState(false);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const readMoreParagraphRef = useRef(null);

    useEffect(() => {
        readMoreParagraphRef.current.innerHTML = purifyHTML(children, ['iframe']);
        setIsOverflowed(checkOverflow(readMoreParagraphRef.current));
    }, [children]);

    useEffect(() => {
        if (isOverflowed) setIsSectionOverflowed(true);
    }, [isOverflowed, setIsSectionOverflowed]);

    useEffect(() => {
        if (productId) {
            setIsReadmore(false);
        }
    }, [productId]);

    return (
        <div className='read-more'>
            <div
                className={`read-more__content ${isOverflowed ? 'content-blur' : ''} ${isReadmore ? 'content-expand' : ''
                    }`}
                ref={readMoreParagraphRef}></div>
            {isOverflowed && !isReadmore && (
                <div className='read-more__control' onClick={() => setIsReadmore(!isReadmore)}>
                    {isReadmore ? intl.messages.readLess : intl.messages.readMore}
                </div>
            )}
        </div>
    );
};

export default ReadMoreSection;
