import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import serviceRequest from 'app/serviceRequest';
import productApi from 'api/productApi';
import hotdealApi from 'api/hotdealApi';
import categoryApi from 'api/categoryApi';
import productTypesApi from 'api/productTypesApi';

const initialState = () => {
    return {};
};

export const getProducts = createAsyncThunk('productList/getProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data
    });
});
export const getFlashDeals = createAsyncThunk('adminHotdeal/getFlashDeals', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: hotdealApi.getFlashDeals,
        payload: data
    });
});

export const getProductCategory = createAsyncThunk('adminHotdeal/getProductCategory', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories,
        payload: data
    });
});

export const addNewHotDeal = createAsyncThunk('adminHotdeal/add', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: hotdealApi.addNewHotDeal,
        payload: data
    });
});

export const updateFlashDeal = createAsyncThunk('adminHotdeal/update', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: hotdealApi.updateNewHotDeal,
        payload: data
    });
});

export const upDateProductTypes = createAsyncThunk('adminHotdeal/update', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productTypesApi.updateProductType,
        payload: data
    });
});

export const deleteFlashDeal = createAsyncThunk('adminHotdeal/delete', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: hotdealApi.deleteHotDeal,
        payload: data
    });
});

const flashDeal = createSlice({
    name: 'flashDeal',
    initialState,
    reducers: {},
    extraReducers: (builders) => {}
});

const {reducer, actions} = flashDeal;
export const {} = actions;
export default reducer;
