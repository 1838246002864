const i18nLabels = {
    vi: {
        order: 'đơn hàng',
        addOrder: 'Thêm đơn hàng',
        totalPrice: 'Tổng thanh toán',
        dateOfOrder: 'Ngày đặt hàng',
        delivery: 'Vận chuyển',
        status: 'Trạng thái',
        help: 'Trợ giúp',
        productList: 'Danh sách sản phẩm',
        all: 'Tất cả',
        onSold: 'Đang bán',
        outOfStock: 'Hết hàng',
        hidden: 'Đã ẩn',
        draft: 'Nháp',
        needConfirm: 'Chờ xác nhận',
        waitGetOrder: 'Chờ lấy hàng',
        delivering: 'Đang giao',
        delivered: 'Đã giao',
        canceled: 'Đã huỷ',
        cancel: 'huỷ',
        update: 'cập nhật',
        refund: 'Hoàn tiền',
        product: 'sản phẩm',
        other: 'khác',
        multipleAddingTool: 'Công cụ xử lý hàng loạt',
        SKUtype: 'SKU phân loại',
        classify: 'Phân loại',
        price: 'Đơn giá (VNĐ)',
        storage: 'Kho hàng',
        sold: 'Đã bán',
        action: 'Thao tác',
        rowsPerPage: 'Số dòng trên trang',
        updateProduct: 'Cập nhật sản phẩm',
        deleteProduct: 'Xóa sản phẩm',
        hideProduct: 'Ẩn sản phẩm',
        noDataFound: 'Không có kết quả được tìm thấy',
        skuProduct: 'SKU Sản phẩm:',
        readMore: 'Xem thêm',
        readLess: 'Ẩn bớt',
        selectAll: 'Chọn tất cả',
        show: 'Hiển thị',
        search: 'Tìm kiếm',
        reInput: 'Nhập lại',
        searchProduct: 'Tìm kiếm sản phẩm',
        searchOrder: 'Tìm kiếm mã đơn hàng',
        productName: 'Tên hàng hóa',
        orderId: 'ID đơn hàng',
        orderer: 'Người đặt hàng',
        deliveryAgent: 'Đơn vị vận chuyển',
        enterOrdererName: 'Nhập tên người đặt hàng',
        enterDeliveryAgent: 'Chọn đơn vị vận chuyển',
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        orderDate: 'Ngày đặt hàng',
        seeDetail: 'Xem chi tiết',
        prepareOrder: 'Chuẩn bị đơn hàng',
        cancelOrder: 'Hủy đơn hàng',
        printOrder: 'In phiếu giao',
        handOverForDelivery: 'Bàn giao cho ĐVVC',
        confirmOrder: 'Xác nhận đơn hàng',
        confirmDelivery: 'Xác nhận đã giao',
        confirmed: 'Đã xác nhận',
        waitForShippingUnit: 'Chờ bàn giao cho ĐVVC',
        deliveryFail: 'Giao không thành công',
        deliverySuccess: 'Giao thành công',
        shopCancel: 'Shop hủy',
        userCancel: 'Người mua hủy',
        process: 'Đã xử lý',
        unprocess: 'Chưa xử lý',
        preparingOrder: 'Đang chuẩn bị hàng',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công',
        accept: 'Đồng ý',
        reject: 'Từ chối'
    },
    en: {
        order: 'Order',
        addOrder: 'Add Order',
        totalPrice: 'Total Price',
        dateOfOrder: 'Date of Order',
        delivery: 'Delivery',
        status: 'Status',
        help: 'Help',
        productList: 'Product List',
        all: 'All',
        onSold: 'On Sold',
        outOfStock: 'Out of Stock',
        hidden: 'Hidden',
        draft: 'Draft',
        needConfirm: 'Need Confirmation',
        waitGetOrder: 'Waiting to Get Order',
        delivering: 'Delivering',
        delivered: 'Delivered',
        canceled: 'Canceled',
        cancel: 'Cancel',
        update: 'Update',
        refund: 'Refund',
        product: 'Product',
        other: 'Other',
        multipleAddingTool: 'Multiple Adding Tool',
        SKUtype: 'SKU Type',
        classify: 'Classify',
        price: 'Price (VND)',
        storage: 'Storage',
        sold: 'Sold',
        action: 'Action',
        rowsPerPage: 'Rows per Page',
        updateProduct: 'Update Product',
        deleteProduct: 'Delete Product',
        hideProduct: 'Hide Product',
        noDataFound: 'No Data Found',
        skuProduct: 'Product SKU:',
        readMore: 'Read More',
        readLess: 'Read Less',
        selectAll: 'Select All',
        show: 'Show',
        search: 'Search',
        reInput: 'Reset',
        searchProduct: 'Search Product',
        searchOrder: 'Search Order Code',
        productName: 'Product Name',
        orderId: 'Order ID',
        orderer: 'Orderer',
        deliveryAgent: 'Delivery Agent',
        enterOrdererName: 'Enter Orderer Name',
        enterDeliveryAgent: 'Select Delivery Agent',
        fromDate: 'From Date',
        toDate: 'To Date',
        orderDate: 'Order Date',
        seeDetail: 'See Detail',
        prepareOrder: 'Prepare Order',
        cancelOrder: 'Cancel Order',
        printOrder: 'Print Delivery Note',
        handOverForDelivery: 'Hand Over to Delivery Service',
        confirmOrder: 'Confirm Order',
        confirmDelivery: 'Confirm Delivery',
        confirmed: 'Confirmed',
        waitForShippingUnit: 'Waiting to be handed over to the delivery service',
        deliveryFail: 'Delivery Unsuccessful',
        deliverySuccess: 'Successfully Delivered',
        shopCancel: 'Shop Cancellation',
        userCancel: 'Buyer Cancellation',
        process: 'Processed',
        unprocess: 'Unprocessed',
        preparingOrder: 'Preparing Order',
        actionFail: 'Action Failed',
        actionSuccess: 'Action Successful',
        accept: 'Accept',
        reject: 'Reject'
    }
};

export default i18nLabels;
