const i18nLabels = {
    vi: {
        index: 'STT',
        voucherName: 'Tên voucher',
        voucherNamePlaceholder: 'Vui lòng nhập tên voucher',
        voucherCode: 'Mã voucher',
        category: 'Ngành hàng',
        categoryList: 'Danh sách ngành hàng',
        productQuantity: 'Số lượng sản phẩm',
        apply: 'Áp dụng',
        applyShippingUnit: 'Đơn vị vận chuyển áp dụng',
        applyPaymentMethod: 'Hình thức thanh toán áp dụng',
        voucherCodePlaceholder: 'Vui lòng nhập mã voucher',
        quantity: 'Số lượng voucher',
        quantityPlaceholder: 'Vui lòng nhập số lượng voucher',
        usage: 'SL đã sử dụng / Tổng',
        expiration: 'Thời gian sử dụng',
        status: 'Tình trạng',
        createdAt: 'Ngày tạo',
        creator: 'Người tạo',
        rowsPerPage: 'Số dòng trên trang',
        voucherList: 'Danh sách vouchers',
        addNew: 'Thêm mới',
        addNewVoucher: 'Thêm mới voucher',
        editVoucher: 'Chỉnh sửa voucher',
        back: 'Trở về',
        close: 'Đóng',
        next: 'Tiếp theo',
        complete: 'Hoàn thành',
        applyFrom: 'Ngày áp dụng',
        expiryDate: 'Ngày hết hạn',
        minOrder: 'Giá trị đơn hàng tối thiểu',
        minOrderPlaceholder: 'Nhập giá trị đơn hàng tối thiểu',
        voucherValue: 'Giá trị khuyến mãi',
        percentage: '% khuyến mãi',
        percentPlaceholder: 'Nhập % khuyến mãi',
        maxVal: 'Giá trị khuyến mãi tối đa',
        maxValPlaceholder: 'Nhập giá trị tối đa được giảm',
        otherInfo: 'Thông tin khác (nếu có)',
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        createDate: 'Ngày tạo',
        activeDate: 'Thời gian sử dụng',
        statusPlaceholder: 'Chọn tình trạng',
        activeDatePlaceholder: 'Chọn ngày kích hoạt',
        search: 'Tìm kiếm',
        reset: 'Nhập lại',
        deleteTitle: 'Xác nhận xoá voucher',
        deleteConfirmContent: 'Bạn có muốn xoá voucher này không?',
        cancel: 'Huỷ',
        delete: 'Xoá',
        basicInfoStep: 'Thông tin cơ bản',
        applyInfoStep: 'Thông tin áp dụng',
        otherInfoStep: 'Thông tin khác',
        noDataFound: 'Chưa có dữ liệu',
        type: 'Phân loại',
        storage: 'Kho hàng',
        product: 'Sản phẩm',
        productList: 'Danh sách sản phẩm',
        price: 'Đơn giá (VNĐ)',
        chooseCategory: 'Chọn ngành hàng',
        readMore: 'Xem thêm',
        readLess: 'Rút gọn'
    },
    en: {
        index: 'No.',
        voucherName: 'Voucher Name',
        voucherNamePlaceholder: 'Please enter voucher name',
        voucherCode: 'Voucher Code',
        category: 'Category',
        categoryList: 'Category List',
        productQuantity: 'Product Quantity',
        apply: 'Apply',
        applyShippingUnit: 'Apply Shipping Unit',
        applyPaymentMethod: 'Apply Payment Method',
        voucherCodePlaceholder: 'Please enter voucher code',
        quantity: 'Quantity',
        quantityPlaceholder: 'Please enter quantity',
        usage: 'Usage',
        expiration: 'Expiration',
        status: 'Status',
        createdAt: 'Created At',
        creator: 'Creator',
        rowsPerPage: 'Rows Per Page',
        voucherList: 'Voucher List',
        addNew: 'Add New',
        addNewVoucher: 'Add New Voucher',
        editVoucher: 'Edit Voucher',
        back: 'Back',
        close: 'Close',
        next: 'Next',
        complete: 'Complete',
        applyFrom: 'Apply From',
        expiryDate: 'Expiry Date',
        minOrder: 'Minimum Order Value',
        minOrderPlaceholder: 'Enter minimum order value',
        voucherValue: 'Voucher Value',
        percentage: 'Discount Percentage',
        percentPlaceholder: 'Enter discount percentage',
        maxVal: 'Maximum Discount Value',
        maxValPlaceholder: 'Enter maximum discount value',
        otherInfo: 'Other Information (if any)',
        fromDate: 'From Date',
        toDate: 'To Date',
        createDate: 'Creation Date',
        activeDate: 'Active Date',
        statusPlaceholder: 'Select status',
        activeDatePlaceholder: 'Select activation date',
        search: 'Search',
        reset: 'Reset',
        deleteTitle: 'Confirm Voucher Deletion',
        deleteConfirmContent: 'Are you sure you want to delete this voucher?',
        cancel: 'Cancel',
        delete: 'Delete',
        basicInfoStep: 'Basic Information',
        applyInfoStep: 'Apply Information',
        otherInfoStep: 'Other Information',
        noDataFound: 'No data found',
        type: 'Type',
        storage: 'Storage',
        product: 'Product',
        productList: 'Product List',
        price: 'Price (VND)',
        chooseCategory: 'Choose category',
        readMore: 'Read More',
        readLess: 'Read Less'
    }
};

export default i18nLabels;
