export default function propsProviders(props) {
    const {
        dispatch,
        intl,
        filterValue,
        handleFilterValue,
        timeSelectOptions,
        handleMouseEnterFilter,
        selectedFilterIndex,
        hoverFilterIndex,
        filterTimePicker,
        dateValue,
        handleDateValueChange,
        salesCardList,
        selectedCard,
        handleSelectedSalesCard,
        userCardList,
        orderData,
        calendarRef,
        buyerPercentage,
        filteredTime,
        userAccessData,
        widthSize
    } = props;

    const {current_buyer_current_month, new_buyer_current_month} = buyerPercentage;

    const getLineChartOptions = ({legendPosition = 'top'}) => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: legendPosition,
                    labels: {
                        usePointStyle: true,
                        pointStyleWidth: 8,
                        useBorderRadius: true,
                        boxHeight: 6,
                        color: '#333',
                        font: {
                            size: 15,
                            family: 'Inter, sans-serif'
                        }
                    }
                },
                tooltip: {
                    usePointStyle: true
                }
            },
            scales: {
                x: {
                    grid: {display: false}
                },
                y: {
                    beginAtZero: true,
                    ticks: {display: false},
                    grid: {drawTicks: false},
                    border: {width: 0, dash: [4, 2]}
                }
            }
        };
    };

    const getPieChartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            cutout: 60,
            plugins: {
                legend: {
                    position: widthSize > 1400 ? 'right' : 'top',
                    labels: {
                        useBorderRadius: true,
                        boxHeight: 12,
                        boxWidth: 12,
                        borderRadius: 12,
                        color: '#333',
                        font: {
                            size: 15,
                            family: 'Inter, sans-serif'
                        }
                    }
                }
            }
        };
    };

    const salesChartData = {
        labels: timeSelectOptions[selectedFilterIndex].chartLabels,
        datasets: [
            {
                label: salesCardList[selectedCard]?.title,
                data: orderData,
                fill: false,
                borderColor: '#4568f2',
                backgroundColor: '#fff',
                borderWidth: 2,
                tension: 0.4
            }
        ]
    };

    const userAccessChartData = {
        labels: timeSelectOptions[selectedFilterIndex].chartLabels,
        datasets: [
            {
                label: intl.messages.accessTimes,
                data: userAccessData,
                fill: true,
                borderColor: 'rgba(172, 128, 247, 1)',
                backgroundColor: 'rgba(172, 128, 247, 0.5)',
                pointBackgroundColor: '#fff',
                borderWidth: 2,
                tension: 0.4
            }
        ]
    };

    const userRatingChartData = {
        labels: [intl.messages.currentBuyer, intl.messages.newBuyer],
        datasets: [
            {
                data: [current_buyer_current_month, new_buyer_current_month],
                backgroundColor: ['#4669f3', '#6cd2ff'],
                hoverBorderWidth: 0
            }
        ]
    };

    const shopReportProductTabs = [
        {
            title: intl.messages.bySales,
            label: intl.messages.sales
        },
        {
            title: intl.messages.pyProducts,
            label: intl.messages.products
        },
        {
            title: intl.messages.byView,
            label: intl.messages.views
        },
        {
            title: intl.messages.byExchangeRate,
            label: intl.messages.exchangeRate
        }
    ];

    return {
        shopReportTimeProps: {
            intl,
            filterValue,
            handleFilterValue,
            timeSelectOptions,
            handleMouseEnterFilter,
            selectedFilterIndex,
            hoverFilterIndex,
            filterTimePicker,
            dateValue,
            handleDateValueChange,
            calendarRef
        },
        shopReportSalesProps: {
            salesCardList,
            intl,
            compareWith: timeSelectOptions[selectedFilterIndex].compareWith,
            selectedCard,
            handleSelectedSalesCard,
            salesChartOptions: getLineChartOptions({legendPosition: 'top'}),
            salesChartData
        },
        shopReportUserProps: {
            intl,
            userAccessChartData,
            userRatingChartData,
            userAccessOptions: getLineChartOptions({legendPosition: 'bottom'}),
            userRatingChartOptions: getPieChartOptions(),
            userCardList,
            compareWith: timeSelectOptions[selectedFilterIndex].compareWith
        },
        shopReportProductProps: {
            intl,
            shopReportProductTabs,
            dispatch,
            filteredTime
        }
    };
}
