import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import CustomPagination from 'components/Pagination/Pagination';
import CardOrder from './CardOrder';

const MyPurchaseList = (props) => {
    const {intl, navigateTo, myPurchaseList = [], setPage, setPageSize, totalPage, page, pageSize, handleOrderStep} = props;
    const isDataEmpty = myPurchaseList.length === 0;

    return (
        <div className='tabs-detail__table'>
            {!isDataEmpty ? (
                myPurchaseList.map((data, idx) => {
                    const {orderInfo, products} = data;

                    return <CardOrder key={idx} navigateTo={navigateTo} order={orderInfo} products={products} intl={intl} handleOrderStep={handleOrderStep} />;
                })
            ) : (
                <EmptyTableData label={intl.messages.noDataFound} />
            )}
            {!isDataEmpty && (
                <div className='table-pagination'>
                    <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={page} />
                    <CustomPageSize
                        label={intl.messages.rowOnPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageSizeRange={[5, 10, 15]}
                        id={'temp'}
                    />
                </div>
            )}
        </div>
    );
};

export default MyPurchaseList;
