import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import ShopReportContainer from './ShopReportContainer';
import i18nLabels from './shopReport.i18n';
import { useDispatch } from 'react-redux';

const ShopReport = (props) => {
	const dispatch = useDispatch();

	const shopReportProps = {
		...props,
		i18nLabels,
		dispatch
	};

	return (
		<DecorateContainer childComponent={ShopReportContainer} {...shopReportProps} />
	);
};

export default ShopReport;