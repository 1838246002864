import React from 'react';

const ExtendWrapperContainer = (props) => {

	return (
		<div className='container'>
			<div className={`product-details-container`}>{props.children}</div>
		</div>
	);
};

export default ExtendWrapperContainer;