import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const hotdealApi = {
    getHotDeals: () => {
        const url = '/api/hotDeals/hotDealsWithSoldProductTotal';
        return axiosClient.get(url);
    },
    getFlashDeals: ({ data, signal }) => {
        const url = '/api/hotDeals';
        return axiosClient.get(url, {params: data, signal});
    },
    addNewHotDeal: (data) => {
        const url = '/api/hotDeals';

        return axiosClient.post(url, data);
    },
    updateNewHotDeal: (data) => {
        const {id} = data;
        const url = '/api/hotDeals';

        return axiosClient.put(`${url}/${id}`, data);
    },
    deleteHotDeal: (id) => {
        const url = '/api/hotDeals';
        return axiosClient.delete(`${url}/${id}`);
    }
};

export default hotdealApi;
