import CustomInput from 'components/CustomInput/CustomInput';
import React, { useEffect, useState } from 'react';
import {Controller, useForm} from 'react-hook-form';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { save, Edit, CloseGray } from 'assets/images';
import TableQuickChat from './subViews/TableQuickChat';
import { Tooltip } from '@mui/material';

const ChatSettingMainView = (props) => {
	const {
		tableQuickChatListProps,
		enableAutoChat,
		enableQuickChat,
		handleChangeQuickChat,
		handleChangeAutoChat,
		contentAutoChat,
		handleSubmitAutoChat,
		handleCloseAutoChat,
		editAutoChat,
		intl
	} = props
	const {control,setValue} = useForm({
        defaultValues:{
			...contentAutoChat
		}
    });
	const [valueTextBox, setValueTextBox] = useState('')
	//set value default
	useEffect(()=>{
		Object.keys(contentAutoChat)?.map((key)=>{
			setValue(key,contentAutoChat[key])
		})
	},[contentAutoChat])

	const AntSwitch = styled(Switch)(({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		display: 'flex',
		'&:active': {
		  '& .MuiSwitch-thumb': {
			width: 15,
		  },
		  '& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		  },
		},
		'& .MuiSwitch-switchBase': {
		  padding: 2,
		  '&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
			  opacity: 1,
			  backgroundColor: theme.palette.mode === 'dark' ? '#c3c9d0' : '#4568f2',
			},
			'&.Mui-disabled':{
				color:'#fff',
				'& + .MuiSwitch-track':{
					backgroundColor: theme.palette.mode === 'dark' ? '#c3c9d0' : '#c3c9d0',
				}
			}
		  },
		  
		},
		'& .MuiSwitch-thumb': {
		  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		  width: 22,
		  height: 22,
		  borderRadius: 100,
		  transition: theme.transitions.create(['width'], {
			duration: 200,
		  }),
		},
		'& .MuiSwitch-track': {
		  borderRadius: 1000 / 2,
		  opacity: 1,
		  backgroundColor:
			theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		  boxSizing: 'border-box',
		},
	  }));

	return (
		<div className='view-chatsetting'>
			<h3>{intl.messages.titlePage}</h3>
			<div className='view-chatsetting-header d-flex align-items-center mt-4'>
				<AntSwitch 
					checked={enableAutoChat} 
					onChange={(event)=>handleChangeAutoChat(event)} 
					inputProps={{ 'aria-label': 'ant design' }}
					disabled={!contentAutoChat.disabled}
					/>
				
				<Controller
					name='content'
					control={control}
					render={({field}) => {
						setValueTextBox(field.value)
						return <CustomInput
							{...field}
							type='textarea'
							minRows={2}
							className='mx-4'
							disabled={contentAutoChat.disabled}
							label={<span>{intl.messages.titleChatAuto}</span>}
							placeholder={intl.messages.titleChatAuto}
						/>
					}}
				/>
				{contentAutoChat.isEdit ? (
					<div className='wrapper-edit'>
						<Tooltip title={intl.messages.tlpSave}>
							<img src={save} alt='save'  className='icon-save' onClick={()=>handleSubmitAutoChat(valueTextBox)}/>
						</Tooltip>
						<Tooltip title={intl.messages.tlpCancel}>
							<CloseGray className="cursorPointer" onClick={()=>handleCloseAutoChat()}/>
						</Tooltip>
					</div>
					
				):(
					<Tooltip title={intl.messages.tlpEdit}>
						<Edit className="cursorPointer" onClick={()=>editAutoChat()} />
					</Tooltip>
				)}
				
			</div>
			<div className='view-chatsetting-tilet-table'>
				<AntSwitch 
					checked={enableQuickChat}
					onChange={(event)=>handleChangeQuickChat(event)}
					inputProps={{ 'aria-label': 'ant design' }}
				/>
				<span className='title-table ml-6'>{intl.messages.titleQuickChat}</span>
			</div>
			<TableQuickChat {...tableQuickChatListProps}/>
		</div>
	);
};

export default ChatSettingMainView;