import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import AddProductSection from '../AddProductSection/AddProductSection';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import InputFiles from '../InputFiles/InputFiles';
import CustomInput from 'components/CustomInput/CustomInput';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import CustomButton from 'components/CustomButton/CustomButton';
import TextEditor from 'components/TextEditor/TextEditor';
import { convertToRaw } from 'draft-js';
import { checkObjectEmpty } from 'utils/checkObjectEmpty';
import CategorySelect from 'components/CategorySelect/CategorySelect';

const BasicInformationSection = forwardRef((props, ref) => {
    const {
        intl,
        handleSubmitBasicInformation,
        categories,
        productImages,
        handleDeleteProductImage,
        handleUploadProductImages,
        formActivated,
        control,
        watchAllFields,
        errors,
        isExpanded,
        toggleExpand,
        isEdit,
        setAttributes
    } = props;
    const basicInformationInputs = [
        {
            label: intl.messages.productName,
            name: 'name',
            rules: { required: { value: true }, maxLength: 120 }
        },
        {
            label: intl.messages.categoryName,
            name: 'product_category_id',
            rules: { required: { value: true } },
            type: 'select',
            options: categories.map((option) => {
                return {
                    id: option.id,
                    value: option.name
                };
            })
        },
        {
            label: intl.messages.productDescription,
            name: 'description',
            type: 'textarea',
            rules: { required: { value: true } }
        }
    ];
    const maxImagesAllowed = 9;
    const addProductSectionProps = {
        title: intl.messages.progressBasicInformation,
        formActivated,
        firstSection: true,
        className: 'basic-information',
        isExpanded,
        toggleExpand
    };
    const { name, product_category_id, description } = watchAllFields;
    const data = [name, product_category_id];

    const descriptionElem = convertToRaw(description.getCurrentContent());
    const isDescriptionValid =
        descriptionElem.blocks.every((value) => value.text !== '') || !checkObjectEmpty(descriptionElem.entityMap);
    const isFormValid = productImages.length > 0 && data.every((value) => value.length > 0) && isDescriptionValid;

    return (
        <AddProductSection {...addProductSectionProps} ref={ref}>
            <div className='mt-2'>
                <div className='mb-3'>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {productImages.map((image, idx) => (
                            <PreviewImage
                                key={idx}
                                image={image.url}
                                onDeleteImage={handleDeleteProductImage.bind(this, idx)}
                            />
                        ))}
                        {productImages.length < maxImagesAllowed && (
                            <InputFiles
                                type='image'
                                maxLength={maxImagesAllowed}
                                count={productImages.length}
                                inputNotes={[intl.messages.formatImage, intl.messages.imageRequirement]}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadProductImages}
                                name='images'
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        (isEdit && productImages.length > 0) ||
                                        (!isEdit && value.length > 0) ||
                                        intl.messages.imageError
                                }}
                            />
                        )}
                    </div>

                    {errors['images']?.message && <FormHelperText error>{errors['images']?.message}</FormHelperText>}
                </div>
                <div className='row mb-4'>
                    {/* <InputFiles
                        type='video'
                        inputNotes={[intl.messages.formatVideo, intl.messages.lengthVideo, intl.messages.sizeVideo]}
                        accept={['.mp4']}
                    /> */}
                </div>
                <div className='row'>
                    {basicInformationInputs.map((inputValue, idx) => {
                        const { rules, type, label, name, ...inputValueProps } = inputValue;
                        const currentValue = watchAllFields[name];
                        const labelContent = (
                            <span>
                                {label} {rules.required?.value ? <span className='text-required'>*</span> : ''}
                            </span>
                        );

                        const { maxLength } = rules;
                        const helperText =
                            type !== 'select' ? `${currentValue?.length}/${maxLength} ${intl.messages.digit}` : null;

                        const isErrorOnInput = currentValue?.length > maxLength;

                        return (
                            <Controller
                                key={name}
                                control={control}
                                rules={rules}
                                name={name}
                                render={({ field }) => (
                                    <FormControl
                                        className={
                                            idx < basicInformationInputs.length - 1 ? 'col-6 mb-4' : 'col-12 mb-4'
                                        }
                                        fullWidth>
                                        {idx === 0 ? (
                                            <CustomInput
                                                {...field}
                                                type={type}
                                                valid={!(errors[name] || isErrorOnInput)}
                                                label={labelContent}
                                                className='validate-input'
                                            />
                                        ) : idx === 1 ? (
                                            <CategorySelect
                                                {...field}
                                                {...inputValueProps}
                                                placeholder={intl.messages.chooseCategory}
                                                label={labelContent}
                                                categories={categories}
                                                setAttributes={setAttributes}
                                            />
                                        ) : (
                                            <TextEditor
                                                editorState={field.value}
                                                onEditorStateChange={field.onChange}
                                            />
                                        )}

                                        {(helperText || errors[name]?.message) && idx !== 2 && (
                                            <FormHelperText error={!!errors[name] || isErrorOnInput}>
                                                {errors[name]?.message ? errors[name].message : helperText}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        );
                    })}
                </div>

                {!formActivated && (
                    <div className='d-flex justify-content-end mb-2'>
                        <CustomButton onClick={handleSubmitBasicInformation} disabled={!isFormValid}>
                            {intl.messages.confirm}
                        </CustomButton>
                    </div>
                )}
            </div>
        </AddProductSection>
    );
});

export default BasicInformationSection;
