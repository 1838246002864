export const homeSelector = (state) => state.home;

export const productListSelector = (state) => state.productList;

export const productDetailsSelector = (state) => state.productDetails;

export const authSelector = (state) => state.auth;

export const footerSelector = (state) => state.footer;

export const headerSelector = (state) => state.header;

export const setPasswordSelector = (state) => state.setPassword;

export const ecomSelector = (state) => state.ecom;

export const signupSelector = (state) => state.signup;

export const cartSelector = (state) => state.cart;

export const notificationSelector = (state) => state.notification;

export const paymentSelector = (state) => state.payment;

export const loaderSelector = (state) => state.loader;

export const adminSelector = (state) => state.admin;

export const adminViewProductsSelector = (state) => state.adminViewProducts;

export const adminViewOrdersSelector = (state) => state.adminViewOrders;

export const adminOrderDetailSelector = (state) => state.adminOrderDetail;
export const adminAddProductSelector = (state) => state.adminAddProduct;
export const adminConfigCategoriesSelector = (state) => state.adminConfigCategories;

export const userSelector = (state) => state.userProfile;
export const adminShopInformationSelector = (state) => state.adminShopInformation;
export const addressListSelector = (state) => state.addressList;
export const userManagementSelector = (state) => state.userManagement;

export const userVoucherListSelector = (state) => state.userVoucherList;
export const userLikeListSelector = (state) => state.likeProductList;

export const notificationSettingSelector = (state) => state.notificationSetting;
export const adminChatSettingSelector = (state) => state.adminChatSetting;

export const adminDesignBannerSelector = (state) => state.adminDesignBanner;

export const marketingListVouchers = (state) => state.marketingListVouchers;
export const adminMarketingVoucherSelector = (state) => state.adminMarketingVoucher;

export const shopReportSelector = (state) => state.shopReport;

export const myPurchaseSelector = (state) => state.myPurchase;