import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import DesignBannerContainer from './DesignBannerContainer';
import i18nLabels from './designBanner.i18n';

const DesignBanner = (props) => {
	const designBannerProps = { ...props, i18nLabels };

	return (
		<DecorateContainer {...designBannerProps} childComponent={DesignBannerContainer} />
	);
};

export default DesignBanner;