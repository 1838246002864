const i18nLabels = {
    vi: {
        accountTab: 'Tài khoản',
        configurationTab: 'Thiết lập',
        orderNumber: 'STT',
        privilege: 'Vai trò',
        description: 'Mô tả',
        status: 'Trạng thái',
        addNew: 'Thêm mới',
        addNewRole: '+ Thêm vai trò',
        search: 'Tìm kiếm',
        statusAll: 'Tất cả trạng thái',
        statusActive: 'Đang hoạt động',
        statusUnActive: 'Không hoạt động',
        roleQuantity: 'Số lượng người có vai trò',
        accountTitle: 'Danh sách người dùng',
        fullName: 'Tên hiển thị',
        email: 'Email',
        phone: 'Số điện thoại',
        deleteConfirmContent: 'Bạn có chắc chắn xóa người dùng này ra khỏi hệ thống',
        cancel: 'Hủy',
        delete: 'Xóa',
        rowOnPage: 'Số dòng trên trang',
        confirm: 'Xác nhận',
        addNewAccount: 'Thêm mới người dùng',
        editAccount: 'Chỉnh sửa người dùng',
        password: 'Mật khẩu',
        sendRequireToEmail: 'Gửi mật khẩu và tên người dùng đến email của tài khoản',
        placeholder: 'Vui lòng',
        add: 'Thêm',
        save: 'Lưu',
        close: 'Đóng',
        displayName: 'Tên hiển thị',
        confirmDelete: 'Bạn có chắc chắn xóa',
        confirmDeletePrivilege: 'Bạn có chắc chắn xóa phân quyền',
        outOfPrivilege: 'ra khỏi phân quyền',
        userRoleList: 'Danh sách người có quyền',
        noDataFound: 'Không có kết quả được tìm thấy',
        addUser: 'Thêm người dùng',
        save: 'Lưu',
        added: 'Đã thêm',
        addRole: 'Thêm vai trò mới',
        editRole: 'Chỉnh sửa vai trò',
        adjustRole: 'Thay đổi vai trò',
        roleName: 'Tên vai trò',
        enterRoleName: 'Nhập vai trò',
        enterDescription: 'Nhập mô tả',
        searchRole: 'Tìm phân quyền',
        roleList: 'Danh sách phân quyền',
        select: 'Chọn',
        privilegeName: 'Tên quyền',
        addSuccess: 'Thêm thành công',
        deleteSuccess: 'Xóa thành công',
        deleteFailed: 'Xóa thất bại',
        role: 'Vai trò',
        errorHappen: 'Đã có lỗi xảy ra, vui lòng thử lại',
        actionSuccess: 'Thao tác thành công',
        currentRole: 'hiện đang có vai trò',
        user: 'Người dùng',
        changeRoleConfirm: 'Bạn chắc chắn thay đổi sang vai trò'
    },
    en: {
        accountTab: 'Account',
        configurationTab: 'Configuration',
        orderNumber: 'Order Number',
        privilege: 'Role',
        description: 'Description',
        status: 'Status',
        addNew: 'Add New',
        addNewRole: '+ Add Role',
        search: 'Search',
        statusAll: 'All Status',
        statusActive: 'Active',
        statusUnActive: 'Inactive',
        roleQuantity: 'Number of Users with Role',
        accountTitle: 'User List',
        fullName: 'Full Name',
        email: 'Email',
        phone: 'Phone Number',
        deleteConfirmContent: 'Are you sure you want to delete this user from the system?',
        cancel: 'Cancel',
        delete: 'Delete',
        rowOnPage: 'Rows per Page',
        confirm: 'Confirm',
        addNewAccount: 'Add New User',
        editAccount: 'Edit User',
        password: 'Password',
        sendRequireToEmail: "Send password and username to the account's email",
        placeholder: 'Please',
        add: 'Add',
        save: 'Save',
        close: 'Close',
        displayName: 'Display Name',
        confirmDelete: 'Are you sure you want to delete',
        confirmDeletePrivilege: 'Are you sure you want to delete privilege',
        outOfPrivilege: 'out of privilege',
        userRoleList: 'List of Users with Privilege',
        noDataFound: 'No data found',
        addUser: 'Add User',
        save: 'Save',
        added: 'Added',
        addRole: 'Add New Role',
        editRole: 'Edit Role',
        adjustRole: 'Adjust Role',
        roleName: 'Role Name',
        enterRoleName: 'Enter role name',
        enterDescription: 'Enter description',
        searchRole: 'Search Privilege',
        roleList: 'Privilege List',
        select: 'Select',
        privilegeName: 'Privilege Name',
        addSuccess: 'Add successfully',
        deleteSuccess: 'Delete successfully',
        deleteFailed: 'Delete failed',
        role: 'Role',
        errorHappen: 'An error occurred. Please try again',
        actionSuccess: 'Action successful',
        currentRole: 'currently has the role',
        user: 'User',
        changeRoleConfirm: 'Are you sure you want to change to the role'
    }
};

export default i18nLabels;
