const enviromentConfig = {
    development: {
        endPoint: 'https://gateway.dev.meu-solutions.com/haidanggroup/'
        // endPoint: 'http://localhost:5000/'
    },
    production: {
        endPoint: 'https://haidanggroup.meu-solutions.com'
    },
    empty: {
        endPoint: 'https://gateway.dev.meu-solutions.com/haidanggroup/'
    }
};
export default enviromentConfig;
