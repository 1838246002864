import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const passwordDefaultValus = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
};

const PasswordSetting = (props) => {
    const {handleSubmitChangePassword, handleSubmitInvalid, passwordSettingInputs, intl} = props;

    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm({defaultValues: passwordDefaultValus});

    return (
        <div className='password-setting'>
            <h6>{intl.messages.password}</h6>
            <form
                action='#'
                className='password-setting__form'
                noValidate
                onSubmit={handleSubmit(handleSubmitChangePassword, handleSubmitInvalid)}>
                <div className='form-content'>
                    {passwordSettingInputs.map((inputValue) => {
                        const {rules = {}, name, label, placeholder} = inputValue;
                        const requiredLabel = (
                            <span>
                                {label}
                                <span className='text-required'> * </span>
                            </span>
                        );
                        const validator = {...rules};
                        if (name === 'confirmPassword') {
                            Object.assign(validator, {
                                validate: (value) =>
                                    value === getValues('newPassword') || intl.messages.passwordNotMatch
                            });
                        }

                        return (
                            <FormControl className='passwordForm-control w-100' key={name}>
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={validator}
                                    render={({field}) => (
                                        <CustomInput
                                            {...field}
                                            valid={!errors[name]}
                                            label={requiredLabel}
                                            placeholder={placeholder}
                                            type='password'
                                            ishavingpwicon='true'
                                        />
                                    )}
                                />
                                {errors[name]?.message && (
                                    <FormHelperText error={!!errors[name]}>{errors[name].message}</FormHelperText>
                                )}
                            </FormControl>
                        );
                    })}
                </div>

                <div className='d-flex justify-content-end mt-3'>
                    <CustomButton>{intl.messages.update}</CustomButton>
                </div>
            </form>
        </div>
    );
};

export default PasswordSetting;
