import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const bannerApi = {
	getbanners: () => {
		const url = '/api/banner?Sorts=sort_order';
		return axiosClient.get(url);
	},


	addBanner: (data) => {
		const url = '/api/banner';
		return axiosFormData.post(url, data);
	},

	deleteBannner: (id) => {
		const url = `/api/banner/${id}`;
		return axiosClient.delete(url);
	},

	sortOrderBannner: (data) => {
		const url = '/api/banner/sortOrder';
		return axiosClient.put(url,data);
	}
};

export default bannerApi;
