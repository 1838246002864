import {Dialog, DialogActions, DialogContent, DialogTitle, Drawer} from '@mui/material';
import React from 'react';
import {close, LeftArrow} from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomButton from 'components/CustomButton/CustomButton';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useEffect} from 'react';
import CustomOtpInput from 'components/CustomOtpInput/CustomOtpInput';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const UpdateEmailPhoneModal = (props) => {
    const {
        intl,
        isEmailModal,
        isOpenUpdateModal,
        closeUpdateModal,
        isHaveUpdateSubmit,
        auth,
        handleSubmitUpdateForm,
        handleBackUpdateForm,
        handleConfirmOtp,
        handleSendPhoneOTP,
        setEnteredOTP,
        enteredOTP,
        handleConfirmPhoneOTP
    } = props;
    const {userLoginInfo} = auth;

    //Email/Phone
    const [updateFormDefaultValue, setUpdateFormDefaultValue] = useState({
        email: userLoginInfo?.email,
        phone: userLoginInfo?.phone
    });
    const {control, handleSubmit, reset} = useForm({defaultValues: updateFormDefaultValue});
    const onDeleteField = () => {
        reset({
            email: '',
            phone: '',
            password: ''
        });
    };

    //OTP Confirm
    const [windowSize] = useDeviceSize();
    const [timer, setTimer] = useState(60);
    const handleOTPChange = (otp) => setEnteredOTP(otp);
    const disabledButton = enteredOTP.length !== 6 || timer <= 0;
    const resendOTP = async () => {
        setEnteredOTP('');
        setTimer(60);

        if (isEmailModal) {
            handleSubmitUpdateForm();
        } else {
            handleSendPhoneOTP();
        }
    };
    const {width} = windowSize;
    const isMobile = width < 576;

    useEffect(() => {
        if (userLoginInfo) {
            const newData = {...updateFormDefaultValue};
            newData.email = userLoginInfo.email;
            newData.phone = userLoginInfo.phone;
            setUpdateFormDefaultValue(newData);
            reset(newData);
        }
    }, [reset, isOpenUpdateModal]);

    useEffect(() => {
        if (timer > 0 && isHaveUpdateSubmit) {
            const timerInterval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [timer, isHaveUpdateSubmit]);

    const modalTitle = (
        <>
            <span>
                {isEmailModal
                    ? intl.messages.accountSettingUpdateEmailModalTitle
                    : intl.messages.accountSettingUpdatePhoneModalTitle}
            </span>
            <button className='dialog-title__close'>
                <img
                    src={close}
                    alt='close-icon'
                    onClick={(e) => {
                        e.preventDefault();
                        closeUpdateModal();
                    }}
                />
            </button>
        </>
    );

    const modalContent = (
        <>
            <div className='row'>
                <div className='col'>
                    <Controller
                        name={isEmailModal ? 'email' : 'phone'}
                        control={control}
                        render={({field}) => (
                            <CustomInput
                                id='outlined-basic'
                                label={
                                    isEmailModal
                                        ? intl.messages.accountSettingUpdateModalMailController
                                        : intl.messages.accountSettingUpdateModalPhoneController
                                }
                                variant='outlined'
                                fullWidth
                                {...field}
                                endicon={
                                    <img
                                        style={{
                                            filter: 'brightness(0) saturate(100%) invert(96%) sepia(2%) saturate(1157%) hue-rotate(183deg) brightness(86%) contrast(87%)',
                                            cursor: 'pointer',
                                            width: 24,
                                            height: 24
                                        }}
                                        src={close}
                                        alt='close-icon'
                                        onClick={() => onDeleteField()}
                                    />
                                }
                                valid={false}
                            />
                        )}
                    />
                </div>
            </div>

            {isEmailModal && (
                <div className='row' style={{marginTop: 32}}>
                    <div className='col'>
                        <Controller
                            name={'password'}
                            control={control}
                            render={({field}) => (
                                <CustomInput
                                    id='outlined-basic'
                                    label={'Mật khẩu'}
                                    variant='outlined'
                                    fullWidth
                                    {...field}
                                    type='password'
                                    ishavingpwicon='true'
                                />
                            )}
                        />
                    </div>
                </div>
            )}
            <div style={{padding: 8}}>
                <span style={{color: '#c3c9d0'}}>
                    {isEmailModal
                        ? intl.messages.accountSettingUpdateModalMailOTPDescription
                        : intl.messages.accountSettingUpdateModalMailPhoneDescription}
                </span>
            </div>
        </>
    );

    const modalActions = (
        <>
            <CustomButton
                variant='text'
                className='header-account__signup'
                onClick={(e) => {
                    e.preventDefault();
                    closeUpdateModal();
                }}>
                {intl.messages.accountSettingUpdateModalCancelButton}
            </CustomButton>
            {isHaveUpdateSubmit && (
                <CustomButton variant='contained' type='submit'>
                    {intl.messages.accountSettingUpdateModalUpdateButton}
                </CustomButton>
            )}
        </>
    );

    const otpPhone = (
        <div className='otp-phone'>
            {!isMobile && (
                <button className='dialog-title__close'>
                    <img src={LeftArrow} alt='' onClick={() => handleBackUpdateForm()} />
                </button>
            )}
            <div className='opt-phone__header text-center'>
                <h3 className='mb-1' style={{color: '#3753c2', fontSize: 23}}>
                    {intl.messages.accountSettingUpdateModalOTPConfirmTitle}
                </h3>
                <p className='mb-2' style={{color: '#333', fontSize: 12}}>
                    {intl.messages.accountSettingUpdateModalOTPConfirmDescription}
                </p>
            </div>
            <div className='d-flex justify-content-center'>
                <CustomOtpInput
                    value={enteredOTP}
                    onChange={handleOTPChange}
                    numInputs={6}
                    isInputNum
                    isDisabled={timer <= 0}
                />
            </div>

            <p className='text-center my-3'>
                {timer > 0 && (
                    <span>
                        {intl.messages.accountSettingUpdateModalOTPConfirmCodeActiveIn}:{' '}
                        <span className='otp-variant' style={{color: '#4568f2'}}>
                            00:{timer >= 10 ? timer : `0${timer}`}
                        </span>
                    </span>
                )}
            </p>

            <div className='w-100'>
                <CustomButton
                    type='submit'
                    disabled={disabledButton}
                    fullwidth
                    onClick={() => (isEmailModal ? handleConfirmOtp() : handleConfirmPhoneOTP())}>
                    {intl.messages.accountSettingUpdateModalOTPSubmitButton}
                </CustomButton>

                <div style={{textAlign: 'center', marginTop: 8}}>
                    <span
                        className='resend-otp-text cursor-pointer'
                        style={{color: '#4568f2'}}
                        onClick={() => {
                            resendOTP();
                        }}>
                        {intl.messages.accountSettingUpdateModalOTPResendCode}
                    </span>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {!isHaveUpdateSubmit ? (
                <Dialog
                    maxWidth={'xs'}
                    fullWidth={true}
                    open={isOpenUpdateModal}
                    onClose={closeUpdateModal}
                    className='dialog d-none d-sm-block'>
                    <form
                        action='#'
                        noValidate
                        onSubmit={handleSubmit(isEmailModal ? handleSubmitUpdateForm : handleSendPhoneOTP)}>
                        <DialogTitle className='dialog-title'>{modalTitle}</DialogTitle>

                        <DialogContent className='dialog-content'>{modalContent}</DialogContent>

                        <DialogActions className='dialog-actions'>{modalActions}</DialogActions>
                    </form>
                </Dialog>
            ) : (
                <Dialog
                    maxWidth={'xs'}
                    fullWidth={true}
                    open={isOpenUpdateModal}
                    onClose={closeUpdateModal}
                    className='dialog d-none d-sm-block'>
                    <DialogTitle className='dialog-title'>{modalTitle}</DialogTitle>
                    <DialogContent className='dialog-content'>{otpPhone}</DialogContent>
                    <DialogActions className='dialog-actions'>{modalActions}</DialogActions>
                </Dialog>
            )}
            <Drawer
                anchor='bottom'
                open={isOpenUpdateModal}
                onClose={closeUpdateModal}
                className='fixed-card__drawer d-block d-sm-none'>
                <div className='drawer-modal update-modal'>
                    <div className='drawer-modal__header'>{modalTitle}</div>

                    <div className='drawer-modal__body'>{!isHaveUpdateSubmit ? modalContent : otpPhone}</div>

                    <div className='drawer-modal__footer d-flex justify-content-end'>
                        <div className='d-flex align-items-center gap-2'>{modalActions}</div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default UpdateEmailPhoneModal;
