import React from 'react';
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';

export default function DecorateContainer(props) {
    const {childComponent: ChildComponent, i18nLabels} = props;
    const locale = useSelector((state) => state.header.lang);

    const geti18nLabels = (translatedI18nLabels) => {
        const i18Arr = Object.entries(translatedI18nLabels);
        return i18Arr.reduce((map, item) => {
            map[item[0]] = {
                id: item[0],
                defaultMessage: item[1]
            };
            return map;
        }, {});
    };

    const prepareIntlProps = () => {
        const props = {locale};

        if (typeof i18nLabels !== 'undefined') {
            const translatedI18nLabels = {...i18nLabels.vi, ...i18nLabels[locale]};
            props.i18nLabels = geti18nLabels(translatedI18nLabels);
            props.messages = translatedI18nLabels;
        }
        return props;
    };

    const intlProps = prepareIntlProps();
    const wrapperProps = {...props, ...intlProps};
    delete wrapperProps.childComponent;
    return (
        <IntlProvider {...intlProps}>
            <ChildComponent {...wrapperProps} />
        </IntlProvider>
    );
}
