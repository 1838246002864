import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import addressApi from 'api/addressApi';
import einvoiceApi from 'api/einvoiceApi';
import paymentApi from 'api/paymentApi';
import shippingUnitApi from 'api/shippingUnit';
import voucherApi from 'api/voucherApi';
import serviceRequest from 'app/serviceRequest';
import Config from 'configuration';
import {storageKey} from 'configuration/storageKey';

const initialState = () => {
    const storePayment = sessionStorage.getItem(Config.storageKey.payment);
    if (storePayment) {
        return {...JSON.parse(storePayment)};
    }

    return {
        addressList: [],
        paymentMethods: [],
        userAddresses: [],
        defaultAddress: {},
        paymentProducts: [],
        shippingUnits: [],
        paymentMethod: {},
        voucherList: [],
    };
};

// export const getVoucher = createAsyncThunk('payment/getVoucher', async (data, thunkAPI) => {
//     return serviceRequest({
//         dispatch: thunkAPI.dispatch,
//         serviceMethod: voucherApi.getVoucher,
//         options: {
//             skipLoader: true
//         }
//     });
// });

export const getPaymentAddress = createAsyncThunk('payment/getAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.getAddressList,
        options: {
            skipLoader: true
        }
    });
});

export const getPaymentMethodData = createAsyncThunk('payment/getPaymentMethodData', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getPaymentMethodData,
        options: {
            skipLoader: false
        }
    });
});

export const getUserAddresses = createAsyncThunk('payment/getUserAddresses', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.getUserAddresses,
        options: {
            skipLoader: true
        }
    });
});

export const getUserAddressById = createAsyncThunk('payment/getUserAddressById', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.getUserAddressById,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

export const addUserAddress = createAsyncThunk('payment/addUserAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.addUserAddress,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const deleteUserAddress = createAsyncThunk('payment/deleteUserAddress', async (addressId, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.deleteUserAddress,
        payload: addressId,
        options: {
            skipLoader: true
        }
    });
});

export const updateUserAddress = createAsyncThunk('payment/updateUserAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: addressApi.updateUserAddress,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getPaymentCart = createAsyncThunk('payment/getPaymentCart', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getPaymentCart,
        options: {
            skipLoader: false
        }
    });
});

export const getShippingUnit = createAsyncThunk('payment/getShippingUnit', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.getShippingUnits,
        payload: params,
        options: {
            skipLoader: true
        }
    });
});

export const paymentCartOrder = createAsyncThunk('payment/paymentCartOrder', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.paymentCartOrder,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const addPaymentCart = createAsyncThunk('payment/addCart', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.addPaymentCart,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getVNPayPaymentUrl = createAsyncThunk('payment/getVNPayPaymentUrl', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getVNPayPaymentUrl,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const confirmVNPayPayment = createAsyncThunk('payment/confirmVNPayPayment', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.confirmVNPayPayment,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getMomoPaymentUrl = createAsyncThunk('payment/getMomoPaymentUrl', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getMomoPaymentUrl,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const confirmMomoPayment = createAsyncThunk('payment/confirmMomoPayment', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.confirmMomoPayment,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

//GHN API
export const createOrderGHN = createAsyncThunk('shipping-order/create', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.createOrderGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const calculateShippingFeeGHN = createAsyncThunk('shipping-order/fee', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.calculateShippingFeeGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getAllProvinceGHN = createAsyncThunk('master-data/province', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getAllProvinceGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getAllDistrictByProvinceGHN = createAsyncThunk('master-data/district', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getAllDistrictByProvinceGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getAllWardByDistrictGHN = createAsyncThunk('master-data/ward', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getAllWardByDistrictGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const getVoucher = createAsyncThunk('payment/voucher', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucher,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

export const getVoucherList = createAsyncThunk('payment/voucherList', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucherList,
        payload: id
    });
});

export const applyVoucher = createAsyncThunk('payment/voucher/apply', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.applyVoucher,
        payload: data,
        options: {
            skipLoader: true
        }
    })
});

export const getAvailableServices = createAsyncThunk('shipping-order/available-services', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: paymentApi.getAvailableServicesGHN,
        payload: data,
        options: {
            skipLoader: false
        }
    });
});

export const cancelVoucher = createAsyncThunk('payment/voucher/cancel', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.cancelVoucher,
        payload: data,
        options: {
            skipLoader: true
        }
    })
});

export const addInvoice = createAsyncThunk('payment/addInvoice', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: einvoiceApi.addInvoice,
        payload: data
    });
});

export const createInvoiceToken = createAsyncThunk('payment/create-invoice-token', async (data, thunkAPI) => {
    const createTokenData = {
        TenantId: '5d1a348028069517e0d6f4aa',
        Email: 'simon@linktek.vn',
        Password: 'MTIzNDU2'
    };

    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: einvoiceApi.createToken,
        payload: createTokenData
    });
});

const payment = createSlice({
    name: 'payment',
    initialState: initialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentAddress.fulfilled, (state, action) => {
                state.addressList = action.payload.data;
                sessionStorage.setItem(Config.storageKey.payment, JSON.stringify(state));
            })
            .addCase(getPaymentMethodData.fulfilled, (state, action) => {
                state.paymentMethods = action.payload.data?.collection;
            })
            .addCase(getUserAddresses.fulfilled, (state, action) => {
                const userAddresses = action.payload.data;
                state.userAddresses = userAddresses;
                const defaultAddress = userAddresses?.collection.find((el) => el.is_default === true);
                state.defaultAddress = defaultAddress;
            })
            .addCase(getPaymentCart.fulfilled, (state, action) => {
                state.paymentProducts = action.payload.data?.collection;
            })
            .addCase(getShippingUnit.fulfilled, (state, action) => {
                state.shippingUnits = action.payload.data?.collection;
            })
            .addCase(paymentCartOrder.fulfilled, (state, action) => {
                const {success} = action.payload;
                if (success) {
                    const {data} = action.payload;
                    const info = {
                        id: data.id,
                        code: data.code,
                        totalAmount: data.total_price
                    };
                    if (info) localStorage.setItem('paymentOrderInfo', JSON.stringify(info));
                }
            })
            .addCase(createInvoiceToken.fulfilled, (state, action) => {
                const token = action.payload.data;
                sessionStorage.setItem(storageKey.eInvoice, token);
            })
            .addCase(getVoucherList.fulfilled, (state, action) => {
                state.voucherList = action.payload.data?.collection;
            });
    }
});

const {reducer, actions} = payment;
export const {} = actions;
export default reducer;
