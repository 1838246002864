import ActionBar from 'components/ActionBar/ActionBar';
import LayoutWithSidebar from 'components/LayoutWithSidebar/LayoutWithSidebar';
import AddProductProgress from './subViews/AddProductProgress/AddProductProgress';
import BasicInformationSection from './subViews/BasicInformationSection/BasicInformationSection';
import DeliveryInformationSection from './subViews/DeliveryInformationSection/DeliveryInformationSection';
import DetailInformationSection from './subViews/DetailInformationSection/DetailInformationSection';
import OtherInformationSection from './subViews/OtherInformationSection/OtherInformationSection';
import SalesInformationSection from './subViews/SalesInformationSection/SalesInformationSection';

const AddProductMainView = (props) => {
    const {
        addProductProgressProps,
        otherInformationProps,
        actionBarControlButtons,
        formActivated,
        salesInformationProps,
        deliveryInformationProps,
        basicInformationProps,
        detailInformationProps,
        handleSubmit,
        handleSubmitInvalid,
        handleSave,
        formRef
    } = props;

    return (
        <div className='add-product'>
            <LayoutWithSidebar sidebar={<AddProductProgress {...addProductProgressProps} />}>
                <form method='POST' action='#' noValidate onSubmit={handleSubmit(handleSave, handleSubmitInvalid)} ref={formRef}>
                    <BasicInformationSection {...basicInformationProps} />
                    <DetailInformationSection {...detailInformationProps} />
                    <SalesInformationSection {...salesInformationProps} />
                    <DeliveryInformationSection {...deliveryInformationProps} />
                    <OtherInformationSection {...otherInformationProps} />
                </form>
                {formActivated && <ActionBar actionBarControlButtons={actionBarControlButtons} />}
            </LayoutWithSidebar>
        </div>
    );
};

export default AddProductMainView;
