import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import voucherApi from 'api/voucherApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        userVoucherList: []
    };
};

export const getAllVoucher = createAsyncThunk('UserVoucher/getAllVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucherList,
        payload: data
    });
});

const userVoucherList = createSlice({
    name: 'userVoucherList',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getAllVoucher.fulfilled, (state, action) => {
            state.userVoucherList = action.payload?.data;
        });
    }
});

const {reducer} = userVoucherList;
export default reducer;
