import LayoutWithSidebar from 'components/LayoutWithSidebar/LayoutWithSidebar';
import Sidebar from 'components/Sidebar/Sidebar';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {matchPath, Route, Switch, useLocation} from 'react-router-dom';
import MobileLayout from './subViews/MobileLayout/MobileLayout';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const UserProfileMainView = (props) => {
    const {userSidebarProps, userRouteList} = props;
    const [windowSize] = useDeviceSize();
    const location = useLocation();
    const {pathname} = location;

    const isSidebarRemoved = [pagePath.userMyDetailPurchaseUrl].some((path) => matchPath(pathname, {path}));
    const isMobile = windowSize.width < 576;

    return (
        <div className='user-profile'>
            <WrapperContainer
                bodyContent
                isPaddingOnMobile={false}
                className={isMobile && pathname !== pagePath.userUrl ? 'pt-3 pb-5' : ''}>
                <LayoutWithSidebar
                    className='d-none d-sm-flex'
                    sidebar={!isSidebarRemoved && <Sidebar {...userSidebarProps} />}>
                    <Switch>
                        {userRouteList.map((route, idx) => (
                            <Route key={`route-${idx}`} exact {...route} />
                        ))}
                    </Switch>
                </LayoutWithSidebar>
                {isMobile && (
                    <Switch>
                        {userRouteList.map((route, idx) => (
                            <Route key={`route-${idx}`} exact {...route} />
                        ))}
                    </Switch>
                )}
            </WrapperContainer>
        </div>
    );
};

export default UserProfileMainView;
