import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notificationApi from 'api/notificationApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        notificationDetails: {}
    };
};

export const getAllNotifications = createAsyncThunk('notification/getData', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.getNotifications,
        payload: data
    });
});

export const updateNotifications = createAsyncThunk('notification/getData', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.updateNotification,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const markAllAsRead = createAsyncThunk('notification/markAllAsRead', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.markAllAsRead,
        payload: data
    })
})

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setActiveNotiType: (state, action) => {
            state.activeNotiType = action.payload;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getAllNotifications.fulfilled, (state, action) => {
            if (!action.payload.success) return;
            if (
                action.payload.data.collection === undefined ||
                action.payload.data.collection.length === 0 ||
                action.payload.data.collection[0].main_category_type === state.activeNotiType
            ) state.notificationDetails = action.payload.data;
        });
    }
});

const { reducer, actions } = notification;
export const { setActiveNotiType } = actions;
export default reducer;
