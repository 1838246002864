import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addressListSelector } from 'app/selectors';
import { useHistory } from 'react-router-dom';
import MyRefundContainer from './myRefundContainer';

const MyRefund = (props) => {
	const dispatch = useDispatch();
    const addressList = useSelector(addressListSelector);
    const history = useHistory();

	const myRefundProps = {
		dispatch,
        history,
		addressList,
		...props,
	};

	return (
        <MyRefundContainer {...myRefundProps} />
	);
};

export default MyRefund;