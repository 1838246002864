import React from 'react';
import {Line, Doughnut} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ArcElement
} from 'chart.js';
import ShopReportCard from '../ShopReportCard';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ArcElement);

const ShopReportUser = (props) => {
    const {
        intl,
        userAccessChartData,
        userAccessOptions,
        userRatingChartData,
        userRatingChartOptions,
        userCardList,
        compareWith
    } = props;

    return (
        <section className='shop-report__user'>
            <h4 className='shop-report__title'>{intl.messages.userData}</h4>
            <p className='shop-report__subTitle'>{intl.messages.websiteAccessedChart}</p>

            <div className='user-access__chart'>
                <Line data={userAccessChartData} options={userAccessOptions} />
            </div>

            <p className='shop-report__subTitle mt-3'>{intl.messages.buyerRate}</p>

            <div className='user-rating row flex-nowrap m-0'>
                <div className='user-rating__chart'>
                    <Doughnut data={userRatingChartData} options={userRatingChartOptions} />
                </div>

                <div className='user-rating__cards'>
                    <div className='row m-0 align-items-center justify-content-between'>
                        {userCardList.slice(0, 3).map((card, idx) => {
                            const shopReportCardProps = {
                                ...card,
                                label: intl.messages.compareWith + ' ' + compareWith
                            };

                            return (
                                <div className='card-list__item card-list__item--30 p-0' key={idx}>
                                    <ShopReportCard {...shopReportCardProps} />
                                </div>
                            );
                        })}
                    </div>
                    <div className='row m-0 align-items-center justify-content-between'>
                        {userCardList.slice(3).map((card, idx) => {
                            const shopReportCardProps = {
                                ...card,
                                label: intl.messages.compareWith + ' ' + compareWith
                            };

                            return (
                                <div className='card-list__item card-list__item--50 p-0' key={idx}>
                                    <ShopReportCard {...shopReportCardProps} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShopReportUser;
