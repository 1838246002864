import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {close} from 'assets/images';
import ConditionForm from './ConditionForm/ConditionForm';
import BasicInfo from './BasicInfo';
import Other from './Other';
import dayjs from 'dayjs';
import CustomButton from 'components/CustomButton/CustomButton';
import {getVoucherProducts, getVouchers, postVoucher, putVoucher} from '../../../VoucherSlice';
import {useDispatch, useSelector} from 'react-redux';
import {adminMarketingVoucherSelector} from 'app/selectors';
import {Toast} from 'utils/toast';

const defaultValues = {
    // Step 1
    name: '',
    code: '',
    quantity: '',
    startDate: null,
    endDate: null,
    createdAt: dayjs(),
    status: '',
    // Step 2
    category: [],
    shippingUnit: [],
    paymentMethod: [],
    applyCategory: [],
    product: [],
    // Step 3
    minVal: '',
    percentage: '',
    maxVal: '',
    otherInfo: ''
};

const VoucherForm = (props) => {
    const {
        isOpen,
        setIsOpen,
        step,
        setStep,
        intl,
        statusList,
        setEditVoucher,
        editVoucher,
        categoryList,
        paymentMethodList,
        shippingUnitList,
        categoryColumns,
        userLoginInfo,
        params,
        voucherList
    } = props;
    const [unsetProduct, setUnsetProduct] = useState(false);
    const dispatch = useDispatch();
    const {productList} = useSelector(adminMarketingVoucherSelector);
    const {control, watch, setValue, reset} = useForm({
        defaultValues
    });
    const watchAllFields = watch();
    const selectedCategoryList = watch('category');

    const removeCategory = (id) => {
        const newSelectedCategory = selectedCategoryList.filter((categoryId) => categoryId !== id);

        setValue('category', newSelectedCategory);
    };

    const basicInfoProps = {control, intl, statusList, watch, setValue, userLoginInfo};
    const conditionProps = {
        control,
        intl,
        categoryList,
        paymentMethodList,
        shippingUnitList,
        categoryColumns,
        watch,
        setValue,
        productList,
        removeCategory,
        selectedProducts: watchAllFields.product,
        selectedCategoryList,
        dispatch,
        unsetProduct,
        setUnsetProduct
    };
    const otherProps = {control, intl, watch, setValue};

    const proceedCheck = (step) => {
        switch (step) {
            case 1:
                return (
                    watchAllFields.name.length === 0 ||
                    watchAllFields.code.length === 0 ||
                    watchAllFields.quantity.length === 0 ||
                    watchAllFields.startDate === null ||
                    watchAllFields.endDate === null ||
                    new Date(watchAllFields.endDate) < new Date(watchAllFields.startDate) ||
                    watchAllFields.status.length === 0
                );
            case 2:
                return (
                    watchAllFields.category.length === 0 ||
                    watchAllFields.shippingUnit.length === 0 ||
                    watchAllFields.paymentMethod.length === 0
                );
            case 3:
                return watchAllFields.percentage.length === 0 || watchAllFields.maxVal.length === 0;
            default:
                return false;
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setEditVoucher(null);
        reset(defaultValues);
        setTimeout(() => {
            setStep(1);
        }, 100);
    };

    const postData = async (formData) => {
        const {
            code,
            maxVal,
            endDate,
            quantity,
            status,
            percentage,
            minVal,
            name,
            otherInfo,
            startDate,
            product,
            shippingUnit,
            paymentMethod,
            category
        } = formData;

        let body = {
            code,
            value: Number(maxVal),
            expiry: endDate.format(),
            quantity: Number(quantity),
            status,
            value_percentage: Number(percentage),
            min_order_apply: Number(minVal),
            other_info: otherInfo,
            name,
            apply_date: startDate.format(),
            product,
            shipping_unit: shippingUnit,
            payment_method: paymentMethod,
            category
        };
        let res = await dispatch(postVoucher(body)).unwrap();
        if (res.success) {
            Toast(true, 'Thêm voucher thành công');
            return dispatch(getVouchers(params));
        } else {
            Toast(false, 'Thêm voucher thất bại');
        }
    };
    const editData = async (data) => {
        const {
            code,
            maxVal,
            endDate,
            quantity,
            status,
            percentage,
            minVal,
            name,
            otherInfo,
            startDate,
            product,
            shippingUnit,
            paymentMethod,
            category
        } = data;
        let current = voucherList.filter((item) => item.id === editVoucher.id)[0];
        if (!current) {
            Toast(false, 'Không tìm thấy voucher để sửa');
            return;
        }
        let body = {};
        const checkTwoArrays = () => {};
        console.log(current.product, product);
        if (current.name !== name) body.name = name;
        if (current.code !== code) body.code = code;
        if (current.quantity.toString() !== quantity) body.quantity = Number(quantity);
        if (current.apply_date !== startDate.format()) body.apply_date = startDate.format();
        if (current.expiry !== endDate.format()) body.expiry = endDate.format();
        if (current.status !== status) body.status = status;
        if (!checkTwoArrays(current.product, product)) body.product = product;
        if (!checkTwoArrays(current.shipping_unit, shippingUnit)) body.shipping_unit = shippingUnit;
        if (!checkTwoArrays(current.payment_method, paymentMethod)) body.payment_method = paymentMethod;
        if (!checkTwoArrays(current.category, category)) body.category = category;
        if (current.value.toString() !== maxVal) body.value = Number(maxVal);
        if (current.value_percentage.toString() !== percentage) body.value_percentage = Number(percentage);
        if (current.min_order_apply.toString() !== minVal) body.min_order_apply = Number(minVal);
        if (current.other_info !== otherInfo) body.other_info = otherInfo;

        let res = await dispatch(putVoucher({id: current.id, data: body})).unwrap();
        if (res.success) {
            Toast(true, 'Sửa voucher thành công');
            return dispatch(getVouchers(params));
        } else {
            Toast(false, 'Sửa voucher thất bại');
        }
    };

    useEffect(() => {
        if (statusList.length > 0) setValue('status', statusList.filter((item) => item.code === 'INACTIVE')[0].id);
    }, [setValue, statusList]);

    useEffect(() => {
        if (editVoucher && !isOpen) {
            Object.keys(watchAllFields).forEach((key) => {
                setValue(key, editVoucher[key]);
            });
            setIsOpen(true);
            setUnsetProduct(true);
        }
    }, [editVoucher, isOpen, watchAllFields]);

    return (
        <Dialog open={isOpen} maxWidth={'md'} fullWidth={true} className='voucher-setting__form' onClose={closeModal}>
            <DialogTitle className='form-header'>
                <span>{editVoucher === null ? intl.messages.addNewVoucher : intl.messages.editVoucher}</span>
                <IconButton disableRipple onClick={closeModal}>
                    <img src={close} alt='close-icon' />
                </IconButton>
            </DialogTitle>
            <DialogContent className='form-content'>
                <div className='form-content__progress'>
                    <div className='form-content__progress--step' aria-current={step >= 1}>
                        <span>1</span>
                        {intl.messages.basicInfoStep}
                    </div>
                    <div className='form-content__progress--separator' aria-current={step >= 2} />
                    <div className='form-content__progress--step' aria-current={step >= 2}>
                        <span>2</span>
                        {intl.messages.applyInfoStep}
                    </div>
                    <div className='form-content__progress--separator' aria-current={step >= 3} />
                    <div className='form-content__progress--step' aria-current={step >= 3}>
                        <span>3</span>
                        {intl.messages.otherInfoStep}
                    </div>
                </div>
                <div className='form-content__main'>
                    {step === 1 && <BasicInfo {...basicInfoProps} />}
                    {step === 2 && <ConditionForm {...conditionProps} />}
                    {step === 3 && <Other {...otherProps} />}
                </div>
            </DialogContent>
            <DialogActions className='form-footer'>
                {step > 1 && (
                    <CustomButton
                        variant='outlined'
                        onClick={() => {
                            setStep(step - 1);
                        }}>
                        {intl.messages.back}
                    </CustomButton>
                )}
                <div className='form-footer__group d-flex gap-2'>
                    <CustomButton variant='text' onClick={closeModal}>
                        {intl.messages.close}
                    </CustomButton>
                    <CustomButton
                        variant='contained'
                        onClick={() => {
                            if (step < 3) return setStep(step + 1);
                            if (editVoucher === null) postData(watchAllFields);
                            else {
                                editData(watchAllFields);
                                setEditVoucher(null);
                            }
                            setIsOpen(false);
                            setStep(1);
                            if (step === 3) reset(defaultValues);
                            return;
                        }}
                        disabled={proceedCheck(step)}>
                        {step < 3 ? intl.messages.next : intl.messages.complete}
                    </CustomButton>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default VoucherForm;
