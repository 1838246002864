import CustomButton from 'components/CustomButton/CustomButton';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import React from 'react';
import CartSummary from './subViews/CartSummary/CartSummary';
import CartTitle from './subViews/CartTitle/CartTitle';
import DetailCart from './subViews/DetailCart/DetailCart';
import EmptyCart from './subViews/EmptyCart/EmptyCart';
import VoucherModal from './subViews/VoucherModal/VoucherModal';

const CartMainView = (props) => {
    const {
        intl,
        emptyCartProps,
        detailCartProps,
        cartSummaryProps,
        voucherModalProps,
        cartItems,
        isCheckedAll,
        modalContent,
        deleteItemsHandler,
        checkItemsHandler,
        voucherList
    } = props;
    const isCartEmpty = cartItems.size === 0;

    return (
        <div className='cart'>
            <ModalPopup {...modalContent} />
            <VoucherModal {...voucherModalProps} />
            <WrapperContainer bodyContent>
                <div className='row'>
                    <div className={`col-lg-${isCartEmpty ? 12 : 8} col-12`}>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <CartTitle intl={intl} quantity={-1} />

                            {!isCartEmpty && (
                                <div className='d-flex gap-2'>
                                    {isCheckedAll && (
                                        <CustomButton
                                            size='md'
                                            variant='half-contained'
                                            color='danger'
                                            className='form-button'
                                            onClick={() => deleteItemsHandler({ type: 'all' })}>
                                            <span>{intl.messages.deleteAll}</span>
                                        </CustomButton>
                                    )}
                                    <CustomButton
                                        size='md'
                                        variant='half-contained'
                                        className='form-button'
                                        onClick={() => checkItemsHandler({ type: 'all' })}>
                                        <span>
                                            {isCheckedAll ? intl.messages.deselectAll : intl.messages.selectAll}
                                        </span>
                                    </CustomButton>
                                </div>
                            )}
                        </div>

                        <div className='cart-content'>
                            {isCartEmpty ? <EmptyCart {...emptyCartProps} /> : <DetailCart {...detailCartProps} />}
                        </div>
                    </div>

                    {!isCartEmpty && (
                        <div className='col-lg-4 d-none d-lg-block'>
                            <CartSummary {...cartSummaryProps} />
                        </div>
                    )}

                    <div className='d-block d-lg-none cart-drawer p-0'>
                        <CartSummary {...cartSummaryProps} />
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
};

export default CartMainView;
