import axiosClient from './axiosClient';

const orderApi = {
    getOrders: ({data, signal}) => {
        const url = '/api/orders';
        return axiosClient.get(url, {params: data, signal});
    },
    getOrderById: (orderId) => {
        const url = `/api/orders/${orderId}`;
        return axiosClient.get(url);
    },
    proceedStep: (params) => {
        const {id} = params;
        const url = `/api/orders/${id}/proceedStep`;
        return axiosClient.put(url, params, {params});
    },
    proceedSubStep: (params) => {
        const {id} = params;
        const url = `/api/orders/${id}/proceedSubStep`;
        return axiosClient.put(url, params, {params});
    },
    getMyOrders: (data) => {
        const url = '/api/orders/myOrders';
        return axiosClient.get(url, {...data});
    }
};

export default orderApi;
