import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import bannerApi from 'api/bannerApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
	return {
		currentBanners: []
	};
};

export const getDesignBanners = createAsyncThunk('adminDesignBanner/getBanners', async (_, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: bannerApi.getbanners
	});
});

export const addDesignBanners = createAsyncThunk('adminDesignBanner/addBanners', async (data, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: bannerApi.addBanner,
		payload: data
	});
});

export const deleteDesignBanners = createAsyncThunk('adminDesignBanner/deleteBanners', async (data, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: bannerApi.deleteBannner,
		payload: data
	});
});

export const sortOrderBanners = createAsyncThunk('adminDesignBanner/sortOrderBanners', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: bannerApi.sortOrderBannner,
        payload: data,
    });
});

const adminDesignBanner = createSlice({
	name: 'adminDesignBanner',
	initialState,
	reducers: {
	},
	extraReducers: (builders) => {
		builders
			.addCase(getDesignBanners.fulfilled, (state, action) => {
				state.currentBanners = action.payload.data.collection;
			});
	}
});

const { reducer } = adminDesignBanner;
export default reducer;
