import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import React, {useEffect, useState} from 'react';
import {deleteCategoriesImage, getCategories} from '../designCategorySlice';
import ItemCard from './subViews/ItemCard';

const categoryOption = [];
const DesignCategoryMainView = (props) => {
    const {intl, dispatch} = props;
    const [category, setCategory] = useState();
    const [categoryFilter, setCategoryFilter] = useState(intl.messages.allCategory);
    const [save, setSave] = useState(false);

    const getCategory = async () => {
        const controller = new AbortController();
        const {signal} = controller;
        const productParam = {
            // Sorts: 'default'
        };
        const params = await dispatch(getCategories({data: productParam, signal}));
        if (params.payload.success) {
            setCategory(params.payload.data);
        }
    };
    const handleChange = (e) => {
        setCategoryFilter(e.target.value);
    };
    const handleSave = () => {
        setSave(true);
    };
    useEffect(() => {
        getCategory();
    }, []);
    return (
        <>
            <div className='design-Category-wrapper'>
                <div className='design-Category-header'>
                    <h1>{intl.messages.designCategoryTitle}</h1>
                    <p>{intl.messages.designCategorySubTitle}</p>
                </div>
                <div className='design-Category-content'>
                    <h5>{intl.messages.categoryList}</h5>
                    <FormControl sx={{minWidth: 390, height: 44}}>
                        <InputLabel>{intl.messages.category}</InputLabel>
                        <Select
                            label={intl.messages.category}
                            value={categoryFilter}
                            onChange={handleChange}
                            defaultValue={intl.messages.allCategory}>
                            <MenuItem value={intl.messages.allCategory}>{intl.messages.allCategory}</MenuItem>
                            {category?.map((e) => (
                                <MenuItem value={e.id} key={e.id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className='designCategory__table'>
                        <header className='designCategory__table__header'>
                            <div className='designCategory__table__header__cell col-img'>{intl.messages.image}</div>
                            <div className='designCategory__table__header__cell col-categories'>
                                {intl.messages.categoryName}
                            </div>
                        </header>
                        <div className='designCategory__table__card'>
                            {category?.map((item) => (
                                <ItemCard
                                    data={item}
                                    key={item.id}
                                    save={save}
                                    setSave={setSave}
                                    intl={intl}></ItemCard>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='designCategory-footer'>
                <CustomButton
                    size='md'
                    variant='text'
                    onClick={(e) => {
                        window.location.reload(false);
                    }}>
                    {intl.messages.cancel}
                </CustomButton>
                <CustomButton size='md' onClick={handleSave}>
                    {intl.messages.save}
                </CustomButton>
            </div>
        </>
    );
};

export default DesignCategoryMainView;
