import {FormControl, MenuItem, Select, Tab, Tabs} from '@mui/material';
import {WhitePlus} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomChip from 'components/CustomChip/CustomChip';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import TableProductList from './TableProductList';

const ViewProductList = (props) => {
    const {
        intl,
        productListTabs = [],
        activeProductListTab,
        changeProductListTab,
        totalRows = 0,
        addProductTool,
        handleAddProductToolChange,
        tableProductListProps,
        navigateTo
    } = props;

    const isProductListEmpty = totalRows === 0;

    return (
        <div className='view-product__list'>
            <div className='product-list__header'>
                <h1>{intl.messages.productList}</h1>
                <CustomButton
                    className='header-button'
                    onClick={() => navigateTo(pagePath.adminAddProductManagementUrl)}>
                    <img src={WhitePlus} alt='add product' />
                    {intl.messages.addProduct}
                </CustomButton>
            </div>
            <div className='product-list__content'>
                <div className='tabs-list'>
                    <Tabs value={activeProductListTab} onChange={changeProductListTab}>
                        {productListTabs.map((tab, key) => (
                            <Tab
                                label={
                                    <span className='d-flex align-items-center gap-2'>
                                        {tab.title}{' '}
                                        {tab.count ? (
                                            <CustomChip
                                                label={tab.count}
                                                size='small'
                                                color={key === 2 ? 'danger' : 'default'}
                                            />
                                        ) : null}
                                    </span>
                                }
                                key={tab.title}
                            />
                        ))}
                    </Tabs>
                </div>

                <div className='tabs-detail'>
                    <div className={`detail-header ${isProductListEmpty ? 'detail-header--empty' : ''}`}>
                        <span>
                            {totalRows} {intl.messages.product}
                        </span>
                        {/* 
                        <FormControl className='detail-header__select'>
                            <Select
                                size='small'
                                value={addProductTool}
                                onChange={handleAddProductToolChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'adding product tool' }}>
                                <MenuItem value=''>{intl.messages.multipleAddingTool}</MenuItem>
                            </Select>
                        </FormControl> */}
                    </div>

                    <TableProductList {...tableProductListProps} />
                </div>
            </div>
        </div>
    );
};

export default ViewProductList;
