import {Tooltip} from '@mui/material';
import {TagIcon} from 'assets/images';
import ProgressBarWithLabel from 'components/ProgressBar/ProgressBar';
import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import React, {useEffect} from 'react';
import {FormattedNumber} from 'react-intl';

const FlashDealCard = (props) => {
    const {item, navigateTo, intl} = props;
    const label = (
        <div className='progress-label'>
            {item?.sold < Number.parseInt(item?.sale_quantity)
                ? `${intl.messages.sold} ${item?.sold}`
                : `${intl.messages.soldOut}`}
        </div>
    );
    const goToPdp = () => navigateTo(`${pagePath.pdpUrl}/${item.product_id ? item.product_id : item.id}`);

    return (
        <div className='flashdeal-card' onClick={goToPdp}>
            <div className='flashdeal-card__media'>
                <img src={`${Config.apiConfig.endPoint}${item?.default_image}`} alt='product-img' />
                <div className='item-tag'>
                    <img src={TagIcon} alt='tag-icon' />
                    <p>-{item?.discount_price ? item?.discount_price : '0'}%</p>
                </div>
            </div>
            <div className='flashdeal-card__content'>
                <div className='flashdeal-card__top'>
                    <Tooltip title={item.product_name} placement='top' arrow>
                        <h6 className='item-title'>{item.product_name}</h6>
                    </Tooltip>
                    {/* <p style={{marginBottom: 0}}>{`${item.type1?.name ? item.type1?.name : ''}${
                        item.type2?.name ? ` - ${item.type2?.name}` : ''
                    }`}</p> */}
                    <div className='item-price'>
                        <div className='item-sale-price'>
                            <FormattedNumber style='currency' currency='VND' value={item.price} />
                        </div>
                        <div className='item-origin-price'>
                            <FormattedNumber style='currency' currency='VND' value={item.origin_price} />
                        </div>
                    </div>
                </div>
                <div className='flashdeal-card__bottom'>
                    <div className='sold-quantity-bar'>
                        <ProgressBarWithLabel data={item?.sold} label={label} max={item?.sale_quantity} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashDealCard;
