export default function propsProvider(props) {
    const { navigateTo, handleInvalidSubmit, handleSignupValid, dispatch, intl, userSignupInfo } = props;
    const footerProps = {
        dispatch,
        navigateTo,
        intl
    };

    return {
        signupProps: {
            navigateTo,
            handleInvalidSubmit,
            handleSignupValid,
            footerProps,
            intl
        },
        signupOtpProps: {
            dispatch,
            footerProps,
            navigateTo,
            userSignupInfo,
            intl
        }
    };
}
