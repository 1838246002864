import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import {Repeat, SearchBlue, SearchIcon, WhitePlus} from 'assets/images';
import React, {useEffect, useState} from 'react';
import {close} from 'assets/images';
import GroupInput from 'components/GroupInput/GroupInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import './flashDealAddNewModal.scss';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import {FormattedNumber} from 'react-intl';
import {useDispatch} from 'react-redux';
import {getProductCategory, getProducts} from '../../FlashDealSlice';
import Config from 'configuration';

const defaultFilterValues = {
    searchProductValue: '',
    category: '',
    minStock: '',
    maxStock: '',
    searchType: ''
};
const categoryOption = [];

export const AddNewModal = ({intl, isAddNewModal, handleAddNewModal, setTableData, tableData}) => {
    // const {intl, isAddNewModal, handleAddNewModal} = props;
    const [typeSearch, setTypeSearch] = useState('productName');
    const [checkedData, setCheckedData] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [data, setData] = useState();
    const [category, setCategory] = useState();
    const [maxStock, setMaxStock] = useState('');
    const [minStock, setMinStock] = useState('');
    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultFilterValues
    });
    const dispatch = useDispatch();

    function extendProductType(collection) {
        const tempCollection = collection.map((obj) => ({
            ...obj,
            extend_type: obj.extend_type.map((t) => ({...t}))
        }));

        tempCollection.forEach((obj) => {
            // if (typeof obj.extend_category.attributes === 'string') {
            //     obj.extend_category.attributes = JSON.parse(obj.extend_category.attributes);
            // }
            obj.extend_product_type?.forEach((productType) => {
                productType.type_id.forEach((typeId) => {
                    let extendedType = obj.extend_type.find((type) => type.id === typeId);
                    if (extendedType) {
                        extendedType.sold = productType.sold;
                        extendedType.price = productType.price;
                        extendedType.quantity = productType.quantity;
                        extendedType.origin_price = productType.origin_price;
                        extendedType.sku = productType.sku;
                    }
                });
            });
        });
        return tempCollection;
    }
    const extendProductType2 = (collection) => {
        const tempCollection = collection.map((obj) => ({
            ...obj,
            extend_product_type: obj.extend_product_type.map((t) => ({...t})),
            extend_type: obj.extend_type.map((t) => ({...t}))
        }));
        tempCollection.forEach((obj, idx) => {
            if (obj?.extend_product_type?.every((el) => el.quantity === 0)) {
                tempCollection.splice(idx, 1);
            }

            obj.extend_product_type?.forEach((productType, index) => {
                if (productType?.quantity === 0) {
                    obj?.extend_product_type?.splice(index, 1);
                }
                productType.type_id.forEach((typeId, idx) => {
                    let extendedType = obj.extend_type.find((type) => type.id === typeId);
                    if (extendedType) {
                        productType[`type${idx + 1}`] = extendedType;
                    }
                });
            });
        });
        return tempCollection;
    };
    const getProduct = async () => {
        const controller = new AbortController();
        const {signal} = controller;
        const productParam = {
            Filters: 'is_hidden==false,is_published==true,' + filterValue,
            Page: 1,
            PageSize: 100
        };
        const params = await dispatch(
            getProducts({
                data:
                    minStock != '' && maxStock != ''
                        ? {...productParam, maxQuantity: maxStock, minQuantity: minStock}
                        : productParam,
                signal
            })
        );
        if (params.payload.success) {
            setData(extendProductType2(params.payload.data.collection));
        }
    };
    const getCategory = async () => {
        const controller = new AbortController();
        const {signal} = controller;
        const productParam = {
            // Sorts: 'default'
        };
        const params = await dispatch(getProductCategory({data: productParam, signal}));
        if (params.payload.success) {
            setCategory(params.payload.data);
        }
    };
    useEffect(() => {
        getProduct();
        getCategory();
    }, []);
    useEffect(() => {
        if (category) {
            category.map((item) => {
                categoryOption.push({id: item.id, value: item.name});
            });
        }
    }, [category]);
    const selectedRows = checkedData.length;
    const rowCount = data?.length;

    const groupStockInputs = [
        {
            name: 'minStock',
            placeholder: intl.messages.minStock
        },
        {
            name: 'maxStock',
            placeholder: intl.messages.maxStock
        }
    ];
    const handleChangeType = (event) => {
        setTypeSearch(event.target.value);
    };
    const handleReset = () => {
        // setFilterData({Filters: [], productName: ''});
        reset(defaultFilterValues);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((data) => data.id);
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };

    const submitFilterProduct = (data) => {
        const searchValue =
            typeSearch === 'productName'
                ? `(name|slug|keyword)@=${data.searchProductValue}`
                : `product_code==${data.searchProductValue}`;
        const filter = `product_category_id==${data.category},` + searchValue;
        setFilterValue(filter);
        setMaxStock(data.maxStock);
        setMinStock(data.minStock);
    };

    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    const getSelectedItem = () => {
        const objectItem = [];
        data?.forEach((e) => {
            if (checkedData.includes(e.id)) {
                objectItem.push(e);
            }
        });
        return objectItem;
    };

    useEffect(() => {
        getProduct();
    }, [filterValue, maxStock]);

    useEffect(() => {
        defaultFilterValues.searchType = typeSearch;
    }, [typeSearch]);
    useEffect(() => {
        if (data) {
            if (tableData) {
                const check = [];
                tableData.forEach((element) => {
                    check.push(element.id);
                });
                setCheckedData(check);
            }
        }
    }, [data]);

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={isAddNewModal} onClose={handleAddNewModal} scroll={'paper'}>
            <DialogTitle className='flashModal-title'>
                <h5>Chọn sản phẩm</h5>
                <button>
                    <img src={close} alt='' onClick={handleAddNewModal} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content' dividers={true}>
                <form action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterProduct)}>
                    <div className='flashModal-filter'>
                        <FormControl sx={{minWidth: 140, height: 22}}>
                            <Select value={typeSearch} onChange={handleChangeType} className={'type-input'}>
                                <MenuItem value={'productName'}>Tên sản phẩm</MenuItem>
                                <MenuItem value={'productCode'}>Mã sản phẩm</MenuItem>
                            </Select>
                        </FormControl>
                        <div className='search-by'>
                            <Controller
                                name='searchProductValue'
                                control={control}
                                render={({field}) => {
                                    return (
                                        <CustomInput
                                            {...field}
                                            starticon={<SearchIcon />}
                                            inputClassName='filter-search'
                                            placeholder={intl.messages.searchOrder}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className='row m-0 mb-4 gap-10px'>
                        <div className='col p-0'>
                            <Controller
                                name='category'
                                control={control}
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        label={intl.messages.category}
                                        placeholder={intl.messages.enterCategory}
                                        options={categoryOption}
                                    />
                                )}
                            />
                        </div>
                        <div className='col p-0'>
                            <GroupInput
                                control={control}
                                label={intl.messages.storage}
                                groupInputData={groupStockInputs}
                            />
                        </div>
                    </div>
                    <div className='col p-0 d-flex gap-3'>
                        <div className='col p-0 d-flex gap-3'>
                            <CustomButton className='p-2' type='submit' variant='half-contained'>
                                <SearchBlue className='me-1' />
                                {intl.messages.search}
                            </CustomButton>
                            <CustomButton className='p-2' type='reset' variant='text' onClick={handleReset}>
                                <Repeat className='me-1' />
                                {intl.messages.reInput}
                            </CustomButton>
                        </div>
                    </div>
                </form>
                <div className='flashDeal-addNewContent'>
                    <h5>{intl.messages.listProduct}</h5>
                    <div className='flashDeal-addNew__table'>
                        <header className='flashDeal-addNew__table__header'>
                            <div className='flashDeal-addNew__table__header__cell col-checkbox'>
                                <CustomCheckbox
                                    disableRipple
                                    isContrast
                                    color='primary'
                                    indeterminate={selectedRows > 0 && selectedRows < rowCount}
                                    checked={rowCount > 0 && selectedRows === rowCount}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all data'
                                    }}
                                />
                            </div>
                            <div className='flashDeal-addNew__table__header__cell col-product'>
                                {intl.messages.product}
                            </div>
                            <div className='flashDeal-addNew__table__header__cell col-classified'>
                                {intl.messages.classified}
                            </div>
                            <div className='flashDeal-addNew__table__header__cell col-sold'>{intl.messages.sold}</div>
                            <div className='flashDeal-addNew__table__header__cell col-price'>{intl.messages.price}</div>
                            <div className='flashDeal-addNew__table__header__cell col-storage'>
                                {intl.messages.storage}
                            </div>
                        </header>
                        <div className='flashDeal-addNew__table__card'>
                            {data?.map((data) => {
                                const isItemchecked = checkedData.includes(data.id);
                                return (
                                    <div className='flashDeal-addNew__table__card__col' key={data.id}>
                                        <div className='flashDeal-addNew__table__card col-checkbox'>
                                            <CustomCheckbox
                                                disableRipple
                                                color='primary'
                                                checked={isItemchecked}
                                                onChange={(e) => handleSelectRow(e, data.id)}
                                                inputProps={{
                                                    'aria-label': 'select all data'
                                                }}
                                            />
                                        </div>
                                        <div className='flashDeal-addNew__table__card__cell col-product'>
                                            <img src={`${Config.apiConfig.endPoint}${data.default_image}`} alt='' />
                                            <div className='product-content'>
                                                <p>{data.name}</p>
                                                {data?.extend_category.name}
                                            </div>
                                        </div>
                                        <div className='flashDeal-addNew__table__card__cell col-classified'>
                                            <div className='classified-content'>
                                                {data?.extend_product_type.length > 0
                                                    ? data.extend_product_type.map((e) => (
                                                          <>
                                                              <p key={e.id}>{`${e.type1?.name ? e.type1?.name : ''}${
                                                                  e.type2?.name ? ` - ${e.type2?.name}` : ''
                                                              }`}</p>
                                                          </>
                                                      ))
                                                    : '--'}
                                            </div>
                                        </div>
                                        <div className='flashDeal-addNew__table__card__cell col-sold'>
                                            {data?.extend_product_type.length > 0
                                                ? data.extend_product_type.map((e) => (
                                                      <>
                                                          <p key={e.id}>{e.sold ? e.sold : '--'}</p>
                                                      </>
                                                  ))
                                                : data?.sold}
                                        </div>
                                        <div className='flashDeal-addNew__table__card__cell col-price'>
                                            {data?.extend_product_type.length > 0 ? (
                                                data.extend_product_type.map((e) => (
                                                    <>
                                                        <p key={e.id}>
                                                            {' '}
                                                            <FormattedNumber
                                                                style='currency'
                                                                currency='VND'
                                                                value={e.origin_price}></FormattedNumber>
                                                        </p>
                                                    </>
                                                ))
                                            ) : (
                                                <FormattedNumber
                                                    style='currency'
                                                    currency='VND'
                                                    value={data?.origin_price}></FormattedNumber>
                                            )}
                                        </div>
                                        <div className='flashDeal-addNew__table__card__cell col-storage'>
                                            {data?.extend_product_type.length > 0
                                                ? data.extend_product_type.map((e) => (
                                                      <>
                                                          <p key={e.id}>{e.quantity}</p>
                                                      </>
                                                  ))
                                                : data?.quantity}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='flashDeal-addNew-action'>
                <p className='flashDeal-addnew-action-text'>
                    Đã chọn {checkedData.length}/{data?.length} sản phẩm
                </p>
                <CustomButton
                    size='md'
                    variant='text'
                    onClick={(e) => {
                        handleAddNewModal();
                    }}>
                    Đóng
                </CustomButton>
                <CustomButton
                    size='md'
                    disabled={!checkedData.length}
                    onClick={(e) => {
                        setTableData(getSelectedItem(checkedData));
                        handleAddNewModal();
                    }}>
                    Xác nhận
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};
