import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const notificationApi = {
    getNotifications: (data) => {
        const url = '/api/notification';
        return axiosClient.get(url, { ...data });
    },

    getNotificationsFastData: (data) => {
        console.log(data)
        const url = '/api/notification/fastData';
        return axiosClient.get(url, { ...data});
    },

    getNotification: (id) => {
        const url = `/api/notification/${id}`;
        return axiosClient.get(url);
    },

    updateNotification: ({ id, data, params }) => {
        const url = `/api/notification/${id}`;
        return axiosClient.put(url, data, { params });
    },

    markAllAsRead: (data) => {
        const url = '/api/notification/markAllAsRead'
        return axiosClient.put(url, data);
    },

    addNotification: (data) => {
        const url = '/api/notification';
        return axiosClient.post(url, data);
    },

    addNotificationWithStatus: ({ notificationParams, data }) => {
        const url = '/api/notification/includeApprovedStatus';
        return axiosClient.post(url, data, { params: notificationParams });
    },

    updateNotificationWithStatus: ({ notificationUpdateParams, data, id }) => {
        const url = `/api/notification/${id}`;
        return axiosClient.put(url, data, { params: notificationUpdateParams });
    },

    uploadNotificationImage: (data) => {
        const { id, file } = data;
        const url = `/api/notification/${id}/uploadImage`;
        return axiosFormData.put(url, file);
    },

    deleteNotification: (id) => {
        const url = '/api/notification';
        return axiosClient.delete(`${url}/${id}`);
    },

    sendPushNotification: (data) => {
        const { id, fcmClientToken } = data;
        const url = `/api/notification/${id}/sendPushNotification`;
        return axiosClient.post(url, fcmClientToken);
    }
};

export default notificationApi;
