import React from 'react';

const ProductDetail = (props) => {
    const { intl, productDetailItems } = props;

    return (
        <div className='product-information__detail'>
            <h4>{intl.messages.productDetail}</h4>

            <div className='detail__content'>
                <div className='row m-0'>
                    {productDetailItems.map((item, idx) => (
                        <div
                            key={idx}
                            className={`col-12 col-lg-12 col-md-${idx % 2 === 0 ? 7 : 5} p-0 mb-3 content-item`}>
                            <div className='content-item__label'>{item.label}</div>
                            <div className={`content-item__value ${idx === 0 ? 'active' : ''}`}>{item.value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
