import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import productApi from 'api/productApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
const initialProductList = () => {
    if (sessionStorage.getItem(Config.storageKey.productList)) {
        return { ...JSON.parse(sessionStorage.getItem(Config.storageKey.productList)) };
    }
    return {
        productListDetails: {},
        showSearchFilterState: 'closed',
        subCategory: {},
        searchValueStore: '',
        relatedCategories: []
    };
};


export const getProducts = createAsyncThunk('productList/getProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data
    });
});

export const getAllRelatedCategories = createAsyncThunk('productList/subSubCategories', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getAllRelatedCategories,
        payload: id
    });
});

const productList = createSlice({
    name: 'productList',
    initialState: initialProductList(),
    reducers: {
        handleSearchFilter: (state, action) => {
            state.showSearchFilterState = action.payload;
        },
        removeSubSubCategory: (state, action) => {
            state.subCategory[action.payload].subSubCategories = [];
        },
        resetSubCategory: (state, action) => {
            state.subCategory = {};
        },
        storeSearchValue: (state, action) => {
            state.searchValueStore = action.payload;
        },
        handleResetRelatedCategories: (state) => {
            state.relatedCategories = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                state.productListDetails = action.payload;
                sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
            })
            .addCase(getAllRelatedCategories.fulfilled, (state, action) => {
                state.relatedCategories = action.payload.data;
                sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
            });

    }
});

const { reducer, actions } = productList;
export const {
    handleSearchFilter,
    removeSubSubCategory,
    resetSubCategory,
    storeSearchValue,
    handleResetRelatedCategories
} = actions;
export default reducer;
