import React from 'react';
import {close} from 'assets/images';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';

const CancelReasonModal = (props) => {
    const {intl, isOpenReasonModal, closeReasonModal, handleCancelOrder, reasonValue, setReasonValue} = props;
    const checkButtonDisabled = reasonValue.length === 0;

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                fullWidth={true}
                open={isOpenReasonModal}
                onClose={closeReasonModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <DialogTitle className='dialog-title' id='voucher-modal'>
                    <span>{intl.messages.cancelOrderModalTitle}</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                closeReasonModal();
                            }}
                        />
                    </button>
                </DialogTitle>
                <DialogContent className='dialog-content'>
                    <div>
                        <CustomInput
                            type='textarea'
                            label={intl.messages.reasonLabel}
                            placeholder={intl.messages.reasonInputPlaceholder}
                            value={reasonValue}
                            onChange={(e) => setReasonValue(e.target.value)}
                            isRequired={true}
                        />
                    </div>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                    <CustomButton
                        variant='text'
                        className='header-account__signup'
                        onClick={(e) => {
                            e.preventDefault();
                            closeReasonModal();
                        }}>
                        {intl.messages.closeText}
                    </CustomButton>
                    <CustomButton
                        variant='contained'
                        disabled={checkButtonDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            handleCancelOrder();
                            closeReasonModal();
                        }}>
                        {intl.messages.confirmText}
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CancelReasonModal;
