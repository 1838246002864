import { adminViewProductsSelector } from 'app/selectors';
import { ErrorMark } from 'assets/images';
import { pagePath } from 'configuration/routeConfig';
import { eventsKey } from 'configuration/storageKey';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Events from 'utils/Events';
import { Toast } from 'utils/toast';
import ViewProductsMainView from './template/ViewProductsMainView';
import propsProvider from './ViewProductsPropsProvider';
import {
    deleteProduct,
    getAdminProducts,
    getCategories,
    handleChangePage,
    handleChangePageSize,
    updateProduct
} from './ViewProductsSlice';

const pageSizeRange = [5, 10, 15, 20];

const ViewProductsContainer = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const navigateTo = (path) => {
        history.push(path);
    };

    const intl = useIntl();
    const adminViewProducts = useSelector(adminViewProductsSelector);
    const { adminProductList, paginationData, categories } = adminViewProducts;
    const [addProductTool, setAddProductTool] = useState('');
    const [checkedData, setCheckedData] = useState([]);
    const [activeProductListTab, setActiveProductListTab] = useState(0);
    const [modalContent, setModalContent] = useState({});
    const [filterData, setFilterData] = useState([]);

    const changeProductListTab = (e, newValue) => {
        setActiveProductListTab(newValue);
    };

    const handleAddProductToolChange = (e, newValue) => {
        setAddProductTool(newValue);
    };

    const handleProductItemAction = async ({ type = '', payload }) => {
        try {
            const modalPopupState = {
                title: intl.messages.confirm,
                closeText: intl.messages.cancel
            };
            let contentText = '';

            switch (type) {
                case 'update':
                    navigateTo(`${pagePath.adminEditProductManagementBaseUrl}/${payload[0]}`);
                    break;
                case 'show':
                    Object.assign(modalPopupState, {
                        onConfirm: async () => {
                            for (const productId of payload) {
                                await dispatch(updateProduct({ id: productId, is_hidden: false }));
                            }
                            Toast(true, intl.messages.actionSuccess);
                            handleUpdateProductList();
                        },
                        confirmText: intl.messages.confirm,
                        isDelete: false
                    });
                    contentText = intl.messages.showProductsConfirm;
                    break;
                case 'delete':
                    Object.assign(modalPopupState, {
                        onConfirm: async () => {
                            for (const productId of payload) {
                                await dispatch(deleteProduct(productId));
                            }
                            Toast(true, intl.messages.actionSuccess);
                            handleUpdateProductList();
                        },
                        confirmText: intl.messages.delete
                    });
                    contentText = intl.messages.deleteProductsConfirm;
                    break;
                case 'hide':
                    Object.assign(modalPopupState, {
                        onConfirm: async () => {
                            for (const productId of payload) {
                                await dispatch(updateProduct({ id: productId, is_hidden: true }));
                            }
                            Toast(true, intl.messages.actionSuccess);
                            handleUpdateProductList();
                        },
                        confirmText: intl.messages.hide
                    });
                    contentText = intl.messages.hideProductsConfirm;
                    break;
                default:
                    break;
            }

            modalPopupState.content = (
                <div className='d-flex justify-content-center align-items-center gap-2 modal-delete'>
                    {type !== 'show' && <ErrorMark />}
                    {contentText}
                </div>
            );

            setModalContent(modalPopupState);
        } catch (err) {
            console.error('unexpected error');
        }
    };

    const handleUpdateProductList = () => {
        Events.emit(eventsKey.updateAdminProductList);
    };

    const setPage = (value) => {
        dispatch(handleChangePage({ activePage: activeProductListTab, page: value }));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({ activePage: activeProductListTab, pageSize: value }));
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = adminProductList?.collection.map((data) => data.id);
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };

    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    const submitFilterProduct = (data) => {
        const { category, filterBy, maxQuantity, maxSold, minQuantity, minSold, searchValue } = data;
        const tempFilterData = [];

        if (searchValue.length > 0) {
            tempFilterData.push(`${filterBy}@=*${searchValue}`);
        }
        if (category.length > 0) {
            tempFilterData.push(`product_category_id==${category}`);
        }
        if (minQuantity.length > 0) {
            tempFilterData.push(`quantity>=${minQuantity}`);
        }
        if (maxQuantity.length > 0) {
            tempFilterData.push(`quantity<=${maxQuantity}`);
        }
        if (minSold.length > 0) {
            tempFilterData.push(`sold>=${minSold}`);
        }
        if (maxSold.length > 0) {
            tempFilterData.push(`sold<=${maxSold}`);
        }
        setFilterData(tempFilterData);
    };

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        const updateProductList = Events.addListener(eventsKey.updateAdminProductList, async () => {
            const { page, pageSize } = paginationData[activeProductListTab];
            const data = {
                Filters: filterData.join(','),
                Sorts: '-created_at',
                Page: page,
                PageSize: pageSize
            };

            if (activeProductListTab === 0) {
            } else if (activeProductListTab === 4) {
                data.Filters += ',is_published==false';
            } else {
                data.Filters += ',is_published==true';
                if (activeProductListTab === 3) {
                    data.Filters += ',is_hidden==true';
                } else {
                    data.Filters += ',is_hidden==false';

                    if (activeProductListTab === 2) {
                        data.Filters += ',quantity==0';
                    } else {
                        data.Filters += ',quantity>0';
                    }
                }
            }
            await dispatch(getAdminProducts({ data, signal })).unwrap();
            setCheckedData([]);
        });

        handleUpdateProductList();

        return () => {
            updateProductList.remove();
            controller.abort();
        };
    }, [activeProductListTab, paginationData, filterData]);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const viewProductsContainerProps = {
        ...props,
        activeProductListTab,
        changeProductListTab,
        adminViewProducts,
        addProductTool,
        handleAddProductToolChange,
        pageSizeRange,
        adminProductList,
        paginationData,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        modalContent,
        intl,
        handleProductItemAction,
        submitFilterProduct,
        categories,
        setFilterData,
        navigateTo
    };

    return <ViewProductsMainView {...propsProvider(viewProductsContainerProps)} />;
};

export default ViewProductsContainer;
