export default function propsProvider(props) {
    const {
        intl,
        dispatch,
        adminShopInformation,
        handleSubmitShopInfo,
        isEdit,
        setIsEdit,
        brandLogo,
        handleUploadLogo,
        isOpenAddressFormModal,
        closeAddressFormModal,
        onOpenAddressFormModal,
        onSubmitAddressFormModal,
        addressModalType,
        selectedAddressObj,
        isOpenDeleteModal,
        onOpenDeleteModal,
        closeDeleteModal,
        handleDeleteAddress
    } = props;

    return {
        intl,
        dispatch,
        shopInfoProps: {
            intl,
            shopInfo: adminShopInformation && adminShopInformation.shopInfo,
            handleSubmitShopInfo,
            isEdit,
            setIsEdit,
            brandLogo,
            handleUploadLogo
        },
        addressProps: {
            intl,
            brandAddressList: adminShopInformation && adminShopInformation.brandAddressList,
            onOpenAddressFormModal,
            addressFormModalProps: {
                intl,
                isOpenAddressFormModal,
                closeAddressFormModal,
                onSubmitAddressFormModal,
                cityList: adminShopInformation && adminShopInformation.addressList?.collection,
                addressModalType,
                selectedAddressObj,
                addressType: 'BRAND'
            },
            onOpenDeleteModal,
            deleteModalProps: {
                intl,
                isOpenDeleteModal,
                closeDeleteModal,
                handleDeleteAddress
            }
        }
    };
}
