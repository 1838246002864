const i18nLabels = {
    vi: {
        actionBtn: 'Dùng',
        myVoucher: 'Voucher của tôi',
        noVoucher: 'Voucher của bạn trống',
        voucherCode: 'Mã đơn hàng',
        expireDay: 'Hạn sử dụng',
        condition: 'Điều kiện',
        paymentMethod: 'Thanh toán',
        shippingUnit: 'Đơn vị vận chuyển',
        company: 'Hai Dang',
        maximum: 'Cho đơn tối đa',
        discount: 'Giảm',
        enterVoucherCode: 'Nhập mã khuyến mãi',
        used: 'Đã dùng',
        expire: 'HSD',
        decreaseMax: 'Giảm tối đa',
        applyMsg: 'Áp dụng cho một số sản phẩm nhất định',
        applyMsgAll: 'Áp dụng cho tất cả sản phẩm',
        clientMsg: 'Mỗi khách hàng được sử dụng tối đa 2 lần',
        fromOrder: 'cho đơn hàng từ',
        copyText: 'Đã sao chép mã'
    },
    en: {
        actionBtn: 'Use',
        myVoucher: 'My Vouchers',
        noVoucher: 'Your voucher collection is empty',
        voucherCode: 'Voucher Code',
        expireDay: 'Expiration Date',
        condition: 'Condition',
        paymentMethod: 'Payment Method',
        shippingUnit: 'Shipping Unit',
        company: 'Hai Dang',
        maximum: 'For maximum order',
        discount: 'Discount',
        enterVoucherCode: 'Enter a promo code',
        used: 'Used',
        expire: 'EXP',
        decreaseMax: 'Decrease maximum',
        applyMsg: 'Applicable to certain products',
        applyMsgAll: 'Applicable to all products',
        clientMsg: 'Each customer is allowed to use it a maximum of 2 times',
        fromOrder: 'for orders from',
        copyText: 'Code copied to clipboard'
    }
};

export default i18nLabels;
