import {Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs} from '@mui/material';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import React, {useEffect, useState} from 'react';
import {
    getCategories,
    getProductConversionRate,
    getProductSales,
    getProductSold,
    getProductView
} from '../../shopReportSlice';
import {Toast} from 'utils/toast';
import {useSelector} from 'react-redux';
import {shopReportSelector} from 'app/selectors';
import Config from 'configuration';
import {FormattedNumber} from 'react-intl';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';

const ShopReportProduct = (props) => {
    const {intl, shopReportProductTabs, dispatch, filteredTime} = props;

    const shopReport = useSelector(shopReportSelector);
    const {productRankingData} = shopReport;
    const [activeProductTab, setActiveProductTab] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);

    const handleChangeCategory = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleChangeProductTab = (e, newValue) => {
        setActiveProductTab(newValue);
    };

    const shopProductHeadCells = [
        {
            id: intl.messages.rank,
            align: 'left',
            padding: 'checkbox',
            label: intl.messages.rank
        },
        {
            id: intl.messages.productInformation,
            align: 'left',
            padding: 'normal',
            label: intl.messages.productInformation
        },
        {
            id: shopReportProductTabs[activeProductTab].label,
            align: 'right',
            padding: 'normal',
            label: shopReportProductTabs[activeProductTab].label
        }
    ];

    useEffect(() => {
        try {
            if (filteredTime) {
                const fetchData = async () => {
                    const mainParams = {
                        ...filteredTime
                    };

                    if (selectedCategory !== 'all') {
                        Object.assign(mainParams, {Filters: `product_category_id==${selectedCategory}`});
                    }

                    switch (activeProductTab) {
                        case 0:
                            await dispatch(getProductSales(mainParams)).unwrap();
                            break;
                        case 1:
                            await dispatch(getProductSold(mainParams)).unwrap();
                            break;
                        case 2:
                            await dispatch(getProductView(mainParams)).unwrap();
                            break;
                        case 3:
                            await dispatch(getProductConversionRate(mainParams)).unwrap();
                            break;
                        default:
                            break;
                    }
                };

                fetchData();
            }
        } catch (err) {
            Toast(false, intl.messages.error);
        }
    }, [filteredTime, selectedCategory, activeProductTab]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await dispatch(getCategories()).unwrap();
            const {success, data} = res;

            if (success) {
                const categories = [{id: 'all', name: intl.messages.allCategories}, ...data].map((option) => {
                    return {
                        id: option.id,
                        value: option.name
                    };
                });
                setCategoryOptions(categories);
                setSelectedCategory('all');
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className='shop-report__product'>
            <h4 className='shop-report__title'>{intl.messages.productStatistic}</h4>

            <div className='tabs-list'>
                <Tabs value={activeProductTab} onChange={handleChangeProductTab}>
                    {shopReportProductTabs.map((tab, key) => (
                        <Tab label={<span className='d-flex align-items-center gap-2'>{tab.title} </span>} key={key} />
                    ))}
                </Tabs>

                <div className='tabs-list__category'>
                    <CustomSelect
                        label={intl.messages.category}
                        options={categoryOptions}
                        value={selectedCategory}
                        onChange={handleChangeCategory}
                        noPlaceholder
                    />
                </div>
            </div>

            <div className='tabs-detail'>
                <TableContainer className='tabs-detail__table'>
                    <Table aria-labelledby='tableTitle'>
                        <CustomTableHead headCells={shopProductHeadCells} />
                        <TableBody>
                            {productRankingData && productRankingData.length > 0 ? (
                                productRankingData?.map((data, idx) => (
                                    <TableRow className='table-row' tabIndex={-1} key={idx}>
                                        <TableCell align='center' padding='checkbox'>
                                            <b>{idx + 1}</b>
                                        </TableCell>
                                        <TableCell className='table-cell__product' align='left' padding='normal'>
                                            <div className='d-flex gap-2'>
                                                <img src={Config.apiConfig.endPoint + data.image} alt='avatar' />

                                                <div className='d-flex flex-column gap-1'>
                                                    <p className='m-0'>{data.name}</p>

                                                    <small>
                                                        đ
                                                        <FormattedNumber currency='vnd' value={data.price} />
                                                    </small>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right' padding='normal'>
                                            {data.value}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={shopProductHeadCells.length}>
                                        <EmptyTableData label={intl.messages.noDataFound} />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </section>
    );
};

export default ShopReportProduct;
