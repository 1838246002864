const i18nLabels = {
    vi: {
        status: 'Trạng thái',
        fromLowToHigh: 'Giá thấp đến cao',
        fromHighToLow: 'Giá cao đến thấp',
        newUpdate: 'Mới cập nhật',
        newProduct: 'Sản phẩm mới',
        oldProduct: 'Sản phẩm cũ',
        chooseCategory: 'Ngành hàng',
        category: 'Ngành hàng',
        favoriteProduct: 'Sản phẩm yêu thích',
        product: 'sản phẩm',
        sortBy: 'Sắp xếp theo',
        price: 'Giá',
        emptyFavoriteProducts: 'Sản phẩm yêu thích trống',
        confirm: 'Xác nhận',
        unlikeProductMsg: 'Bạn chắc chắn bỏ thích 1 sản phẩm?',
        cancel: 'Hủy',
        unlike: 'Bỏ thích'
    },
    en: {}
};

export default i18nLabels;
