import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import serviceRequest from 'app/serviceRequest';
import productApi from 'api/productApi';
import hotdealApi from 'api/hotdealApi';
import categoryApi from 'api/categoryApi';

const initialState = () => {
    return {};
};

export const getCategories = createAsyncThunk('designCategory/getCategories', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories,
        payload: data
    });
});

export const uploadCategoryImage = createAsyncThunk('designCategory/imageCategories', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.uploadCategoryImages,
        payload: data
    });
});

export const deleteCategoriesImage = createAsyncThunk(
    'designCategory/deleteCategoriesImage',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: categoryApi.deleteImageCategoryById,
            payload: data
        });
    }
);

const adminDesignCategory = createSlice({
    name: 'adminDesignCategory',
    initialState,
    reducers: {},
    extraReducers: (builders) => {}
});

const {reducer, actions} = adminDesignCategory;
export const {} = actions;
export default reducer;
