import {pagePath} from 'configuration/routeConfig';
import {eventsKey} from 'configuration/storageKey';
import {
    proceedOrderStep,
    proceedOrderSubStep
} from 'features/Admin/template/subViews/Order/OrderDetail/OrderDetailSlice';
import {addCartProducts} from 'features/Cart/cartSlice';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {calcTotalPage} from 'utils/calcTotalPage';
import Events from 'utils/Events';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import propsProvider from './myPurchasePropsProvider';
import {
    changeActiveTab,
    getMyOrders,
    getProductReviewByIdAndOrderProductId,
    postProductReview,
    postProductReviewAttachment,
    publishInvoice,
    uploadProductReviewAttachment
} from './myPurchaseSlice';
import MyPurchaseMainView from './template/MyPurchaseMainView';
import {
    addNotificationWithStatus,
    sendPushNotification
} from 'features/Admin/template/subViews/ShopSetting/NotificationSetting/NotificationSettingSlice';
import {getUserDetail} from 'features/UserProfile/UserProfileSlice';
import {eInvoiceSerial} from 'api/einvoiceApi';
import {useSelector} from 'react-redux';
import {myPurchaseSelector} from 'app/selectors';

const statusCodes = ['', 'Unconfirm', 'Packing-Pending', 'Delivery', 'Done', 'Cancel', 'Refund'];

export const orderActions = {
    confirmReceived: 'confirmReceived',
    refund: 'refund',
    review: 'review',
    buyAgain: 'buyAgain',
    reviewDetail: 'reviewDetail',
    cancel: 'cancel'
};

const MyPurchaseContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();
    const [myPurchaseList, setMyPurchaseList] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [myPurchaseParams, setMyPurchaseParams] = useState({
        Sorts: '',
        Page: 1,
        PageSize: 5,
        productName: ''
    });
    const [isOpenReviewModal, setOpenReviewModal] = useState(false);
    const [reviewValues, setReviewValues] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedCode, setSelectedCode] = useState('');
    const [isViewDetail, setIsViewDetail] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchBy, setSearchBy] = useState('name');
    const [filterData, setFilterData] = useState({statusCode: '', code: ''});
    const [isOpenReasonModal, setOpenReasonModal] = useState(false);
    const [reasonValue, setReasonValue] = useState('');
    const myPurchase = useSelector(myPurchaseSelector);
    const {activePurchaseListTab} = myPurchase;

    const closeReasonModal = () => {
        setOpenReasonModal(false);
    };

    const openReasonModal = (orderId, userId, code) => {
        setSelectedOrderId(orderId);
        setSelectedUserId(userId);
        setSelectedCode(code);
        setOpenReasonModal(true);
    };

    const handleUploadImages = async (e, productIndex) => {
        const files = e.target.files;
        const previewImageUrls = [];
        let isOverSize = false;

        for (const file of files) {
            const {size} = file;

            if (size > 5 * 1024 * 1024) {
                isOverSize = true;
                return;
            }
            const result = await getBase64(file);
            previewImageUrls.push({url: result, file});
        }

        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else {
            const newReviewValues = [...reviewValues].map((el, idx) => {
                if (idx === productIndex) {
                    const newPreviewImageUrls = [...el.detailImages, ...previewImageUrls];
                    el['detailImages'] = newPreviewImageUrls;
                    return el;
                }
                return el;
            });
            setReviewValues(newReviewValues);
        }
    };

    const closeReviewModal = () => {
        setReviewValues([]);
        setOpenReviewModal(false);
        setIsViewDetail(false);
    };

    const sendNoti = async (summary, details, code, userId, orderId) => {
        let notificationParams = {
            approvedStatusCode: 'SENT'
        };
        const res = await dispatch(
            addNotificationWithStatus({
                notificationParams,
                data: {
                    summary: `${summary} ${code}`,
                    details: `${intl.messages.order} ${details}`,
                    user_id: userId,
                    main_category_type: 0,
                    reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                }
            })
        ).unwrap();
        if (res?.success) {
            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
            if (userRes?.success) {
                await dispatch(
                    sendPushNotification({
                        id: res?.data?.id,
                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                    })
                ).unwrap();
            }
        }
    };

    const openReviewModal = (productList, orderId) => {
        if (productList) {
            const initialState = productList.map((product) => ({
                ...product,
                comment: '',
                rating: 0,
                ratingHover: -1,
                detailImages: [],
                is_name_display: false
            }));
            setReviewValues(initialState);
            setSelectedOrderId(orderId);
        }
        setOpenReviewModal(true);
    };

    const openReviewDetailModal = async (productList) => {
        if (productList) {
            const initialState = [];

            for (const product of productList) {
                const {productId, orderProductId} = product;
                const res = await dispatch(getProductReviewByIdAndOrderProductId({productId, orderProductId})).unwrap();
                const {success} = res;
                if (success) {
                    const {data} = res;
                    if (data?.total > 0) {
                        const reviewData = data?.collection[0];
                        const {user_id, rating, review_text, extend_product_review_attachment} = reviewData;
                        initialState.push({
                            ...product,
                            comment: review_text,
                            rating: rating,
                            detailImages: extend_product_review_attachment,
                            is_name_display: !!user_id
                        });
                    }
                }
            }
            setReviewValues(initialState);
        }
        setIsViewDetail(true);
        setOpenReviewModal(true);
    };

    const handleReviewOrder = async () => {
        if (reviewValues) {
            for (const product of reviewValues) {
                const {
                    orderProductId,
                    productId,
                    userId,
                    comment,
                    rating,
                    is_name_display,
                    detailImages,
                    productCreator,
                    productName
                } = product;
                const payload = {
                    order_product_id: orderProductId,
                    product_id: productId,
                    user_id: is_name_display ? userId : '',
                    review_text: comment,
                    rating: parseInt(rating)
                };
                const reviewResponse = await dispatch(postProductReview(payload)).unwrap();
                const {success} = reviewResponse;
                if (success) {
                    const {data} = reviewResponse;
                    const reviewId = data?.id;

                    //send push notification
                    let notificationParams = {
                        approvedStatusCode: 'SENT'
                    };
                    const res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Sản phẩm ${productName} của bạn vừa được đánh giá`,
                                details: comment,
                                user_id: productCreator || '',
                                reference_link: `${pagePath.pdpUrl}/${productId}`
                            }
                        })
                    ).unwrap();

                    const {success} = res;
                    if (success) {
                        const userRes = await dispatch(getUserDetail({id: productCreator})).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    //end send push notification

                    if (detailImages && detailImages.length > 0) {
                        for (const idx in detailImages) {
                            const res = await dispatch(
                                postProductReviewAttachment({product_review_id: reviewId})
                            ).unwrap();
                            const {success} = res;
                            if (success) {
                                const {data} = res;

                                const fileFormData = new FormData();
                                const file = detailImages[idx].file;
                                if (file) {
                                    fileFormData.append('file', file);
                                    await dispatch(uploadProductReviewAttachment({id: data?.id, file: fileFormData}));
                                }
                            }
                        }
                    }
                }
            }
            await dispatch(proceedOrderStep({id: selectedOrderId, nextStepCode: 'Done', isReviewed: true}));
            Toast(true, 'Đánh giá thành công');
            fetchMyPurchaseList();
        }
        closeReviewModal();
    };

    const handleCancelOrder = async (reason) => {
        const params = {
            id: selectedOrderId,
            nextStepCode: 'Cancel',
            cancelReason: reason || reasonValue
        };
        const res = await dispatch(proceedOrderStep(params)).unwrap();
        await dispatch(proceedOrderSubStep({id: selectedOrderId, nextStepCode: 'USER_CANCEL'}));
        const {success} = res;
        if (success) {
            // await sendNoti(
            //     intl.messages.updateOrderStatus,
            //     intl.messages.canceled,
            //     selectedCode,
            //     selectedUserId,
            //     selectedOrderId
            // );
            Toast(success, 'Đã hủy đơn hàng');
            fetchMyPurchaseList();
        } else {
            Toast(success, 'Hủy đơn không thành công!');
        }
        closeReasonModal();
    };

    const navigateTo = (path) => {
        history.push(path);
    };

    const setPage = (page) => {
        setMyPurchaseParams((prev) => ({
            ...prev,
            Page: page
        }));
    };

    const setPageSize = (pageSize) => {
        setMyPurchaseParams((prev) => ({
            ...prev,
            Page: 1,
            PageSize: pageSize
        }));
    };

    const myPurchaseListTabs = [
        {
            key: '0',
            label: intl.messages.all
        },
        {
            key: '1',
            label: intl.messages.waitingForConfirming
        },
        {
            key: '2',
            label: intl.messages.waitingForPacking
        },
        {
            key: '3',
            label: intl.messages.delivering
        },
        {
            key: '4',
            label: intl.messages.complete
        },
        {
            key: '5',
            label: intl.messages.cancelled
        },
        {
            key: '6',
            label: intl.messages.returnRefund
        }
    ];

    const handleBuyAgain = async (products) => {
        try {
            if (products) {
                for (const product of products) {
                    const {productId, subTypeId} = product;
                    await dispatch(addCartProducts({productId, quantity: 1, type: 'cart', subTypeId}));
                }
                Events.emit(eventsKey.updateCart);
                navigateTo(pagePath.cartUrl);
                Toast(true, 'Mua lại thành công');
            }
        } catch (err) {
            Toast(false, 'Mua lại thất bại');
        }
    };

    const handleOrderStep = async (type = '', payload) => {
        const {orderId, products, invoice_id, orderCodeNumber} = payload;
        const {userId} = products[0];

        try {
            switch (type) {
                case orderActions.confirmReceived:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Done'})).unwrap();
                    await dispatch(publishInvoice({invoiceIds: [invoice_id], serial: eInvoiceSerial})).unwrap();
                    // await sendNoti(
                    //     intl.messages.updateOrderStatus,
                    //     intl.messages.confirmedDelivery,
                    //     orderCodeNumber,
                    //     userId,
                    //     orderId
                    // );
                    fetchMyPurchaseList();
                    break;
                case orderActions.refund:
                    // await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Refund'}));
                    // fetchMyPurchaseList();
                    navigateTo('/user/refund/' + orderId);
                    break;
                case orderActions.review:
                    return openReviewModal(products, orderId);
                case orderActions.reviewDetail:
                    return openReviewDetailModal(products);
                case orderActions.buyAgain:
                    return handleBuyAgain(products);
                case orderActions.cancel:
                    openReasonModal(orderId, userId, orderCodeNumber);
                    return;
                default:
                    break;
            }
            Toast(true, intl.messages.actionSuccess);
        } catch (err) {
            Toast(false, intl.messages.actionFail);
        }
    };

    const handleSearch = () => {
        if (searchBy === 'name') {
            setMyPurchaseParams((prev) => ({
                ...prev,
                Page: 1,
                productName: searchValue
            }));
            setFilterData((prev) => ({
                ...prev,
                code: ''
            }));
        } else {
            setMyPurchaseParams((prev) => ({
                ...prev,
                Page: 1,
                productName: ''
            }));
            setFilterData((prev) => ({
                ...prev,
                code: `code@=${searchValue}`
            }));
        }
    };

    const changePurchaseListTab = (e, newValue) => {
        dispatch(changeActiveTab(newValue));
        setMyPurchaseParams((prev) => ({
            ...prev,
            Page: 1
        }));
        setFilterData((prev) => ({
            ...prev,
            statusCode:
                newValue === 2
                    ? `status_code==${statusCodes[newValue]}||Delivery-Pending`
                    : newValue === 3
                    ? `status_code==${statusCodes[newValue]}||Delivered`
                    : `status_code==${statusCodes[newValue]}`
        }));
    };

    const fetchMyPurchaseList = async () => {
        const params = {
            ...myPurchaseParams,
            Filters: `${filterData.statusCode},${filterData.code}`
        };
        const res = await dispatch(getMyOrders({params})).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            setMyPurchaseList(data?.collection);
            setTotalPage(calcTotalPage(data?.total, data?.pagesize));
        }
    };

    useEffect(() => {
        setReasonValue('');
    }, [isOpenReasonModal]);

    useEffect(() => {
        fetchMyPurchaseList();
    }, [myPurchaseParams, filterData]);

    const myPurchaseContainerProps = {
        intl,
        navigateTo,
        myPurchaseListTabs,
        activePurchaseListTab,
        changePurchaseListTab,
        setPage,
        setPageSize,
        myPurchaseList,
        totalPage,
        myPurchaseParams,
        handleOrderStep,
        isOpenReviewModal,
        closeReviewModal,
        handleReviewOrder,
        handleUploadImages,
        reviewValues,
        setReviewValues,
        isViewDetail,
        searchValue,
        setSearchValue,
        handleSearch,
        searchBy,
        setSearchBy,
        isOpenReasonModal,
        closeReasonModal,
        handleCancelOrder,
        reasonValue,
        setReasonValue
    };

    return <MyPurchaseMainView {...propsProvider(myPurchaseContainerProps)} />;
};

export default MyPurchaseContainer;
