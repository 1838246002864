import CustomButton from 'components/CustomButton/CustomButton';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import React from 'react';
import {FormattedNumber} from 'react-intl';

const OrderProductItem = (props) => {
    const {product} = props;

    return (
        <div className='order-product-item'>
            <img className='order-product-img' src={`${Config.apiConfig.endPoint}${product?.productImg}`} alt='product' />
            <div className='order-product-info'>
                <div className='order-product-info__top'>
                    <p className='product-name'>{product?.productName}</p>
                </div>
                <div className='order-product-info__middle'>
                    <p className='product-props'>{product?.productDescription}</p>
                    <p className='product-quantity'>x{product?.productQuantity}</p>
                </div>
                <div className='order-product-info__bottom'>
                    <p className='product-price__title'>Đơn giá</p>
                    <p className='product-price__value'>
                        <FormattedNumber
                            style='currency'
                            currency='VND'
                            value={product?.productPrice}></FormattedNumber>
                    </p>
                </div>
            </div>
        </div>
    );
};

const OrderItem = (props) => {
    const {navigateTo, order, isMessageBox = false, handleSendOrder} = props;
    const {orderInfo, products} = order;

    return (
        <div className='order-item'>
            <div className='order-item__header'>
                <p className='order-code'>#{orderInfo?.orderCodeNumber}</p>
                <div className={`order-status order-status__${orderInfo?.orderStatusCode}`}>{orderInfo?.orderStatus}</div>
            </div>
            <div className='order-item__body'>
                <div className='order-product-list'>
                    {products &&
                        products.map((product) => <OrderProductItem key={product?.productId} product={product} />)}
                </div>
                <div className='order-total-price'>
                    <div className='total-price-title'>Tổng cộng</div>
                    <div className='total-price-value'>
                        {products?.length} sản phẩm |{' '}
                        <FormattedNumber
                            style='currency'
                            currency='VND'
                            value={orderInfo?.orderTotalPrice}></FormattedNumber>
                    </div>
                </div>
            </div>
            {!isMessageBox && <div className='order-item__footer'>
                <CustomButton
                    size='small'
                    variant='text'
                    fullwidth
                    onClick={() => navigateTo(`${pagePath.userMyPurchaseUrl}/${orderInfo?.orderId}`)}>
                    Chi tiết
                </CustomButton>
                <CustomButton size='small' variant='contained' fullwidth onClick={() => handleSendOrder(orderInfo?.orderId)}>
                    Gửi
                </CustomButton>
            </div>}
        </div>
    );
};

export default OrderItem;
