import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {Trash} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import {NumericFormat} from 'react-number-format';
import InputFiles from '../InputFiles/InputFiles';
import ValidatingInput from '../ValidatingInput/ValidatingInput';

const AdvanceForm = ({
    intl,
    advancedSalesInformationInputs,
    handleAddGroupType,
    handleDeleteGroupType,
    applyAllHeadCells,
    errors,
    control,
    setValue,
    mainDataState,
    handleUploadProductTypeImage,
    handleGroupTypeInputChange,
    handleDeleteProductTypeImage,
    productTypeImages,
    clearErrors
}) => {
    const [applyAllInputs, setApplyAllInputs] = useState([
        {
            name: 'unitPrice',
            label: intl.messages.price,
            isPrice: true,
            placeholder: '0',
            value: '',
            novalidate: true
        },
        {
            name: 'promotionalPrice',
            label: intl.messages.promotionalPrice,
            placeholder: '0',
            isPrice: true,
            value: '',
            novalidate: true
        },
        {
            name: 'quantity',
            label: intl.messages.storage,
            isPrice: false,
            placeholder: '0',
            type: 'number',
            value: '',
            novalidate: true
        },
        {
            name: 'skuType',
            label: intl.messages.skuType,
            isPrice: false,
            placeholder: '--',
            value: '',
            novalidate: true
        }
    ]);

    const handleApplyAllChange = (value, name) => {
        const newApplyAllInputs = applyAllInputs.map((input) => {
            if (name === input.name) input.value = value;

            return input;
        });

        setApplyAllInputs(newApplyAllInputs);
    };

    const handleApplyAll = () => {
        mainDataState?.forEach((dataState) => {
            dataState.items.forEach((item) => {
                for (const data of item) {
                    const {name = null} = data;

                    if (name) {
                        const tempName = name.split(' ')[0];
                        setValue(name, applyAllInputs.find((type) => type.name === tempName).value);
                        clearErrors(name);
                    }
                }
            });
        });
    };

    return (
        <div className='advanced-form'>
            <section className='group-type'>
                <p>{intl.messages.groupType}</p>
                <div className='group-type__control w-100'>
                    {advancedSalesInformationInputs.map((group_type, idx) => (
                        <div key={idx} className='d-flex gap-10px w-100'>
                            <div className='control-inputs row m-0'>
                                {group_type.map((type, type_idx) => {
                                    const {name, maxLength, value} = type;

                                    const helperText = `${value.length}/${maxLength} ${intl.messages.digit}`;

                                    const validatingInputProps = {
                                        inputValue: type,
                                        helperText
                                    };

                                    return (
                                        <div className='control-inputs__item p-0' key={name}>
                                            <ValidatingInput
                                                {...validatingInputProps}
                                                onChangeValue={(e) =>
                                                    handleGroupTypeInputChange(e.target.value, [idx, type_idx], name)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <Trash className='control-btn' onClick={() => handleDeleteGroupType(idx)} />
                        </div>
                    ))}

                    {advancedSalesInformationInputs.length < 2 && (
                        <div className='group-type__control--btn'>
                            <CustomButton type='button' variant='outlined' onClick={handleAddGroupType}>
                                {intl.messages.addGroupType}
                            </CustomButton>
                        </div>
                    )}
                </div>
            </section>

            {mainDataState?.length > 0 && (
                <>
                    <section className='group-type__list'>
                        <p>{intl.messages.groupTypeList}</p>
                        <div className='list-applyAll'>
                            {applyAllInputs.map((input) => (
                                <div className='input-field' key={input.name}>
                                    <ValidatingInput
                                        inputValue={input}
                                        onChangeValue={(e) => handleApplyAllChange(e.target.value, input.name)}
                                    />
                                </div>
                            ))}

                            <div className='list-applyAll__button'>
                                <CustomButton type='button' variant='half-contained' onClick={handleApplyAll}>
                                    {intl.messages.applyForAllGroupTypes}
                                </CustomButton>
                            </div>
                        </div>
                    </section>
                    <section className='group-type__detail'>
                        <TableContainer className='detail-table'>
                            <Table aria-labelledby='tableTitle'>
                                <CustomTableHead headCells={applyAllHeadCells} />

                                <TableBody>
                                    {mainDataState.map((row, i) => (
                                        <TableRow className='detail-table__row' tabIndex={-1} key={i}>
                                            <TableCell
                                                className='row-cell'
                                                component='th'
                                                scope='row'
                                                align='center'
                                                padding='none'>
                                                <div className='d-flex flex-column align-items-center gap-10px'>
                                                    {productTypeImages[i]?.url ? (
                                                        <PreviewImage
                                                            image={productTypeImages[i].url}
                                                            onDeleteImage={() => handleDeleteProductTypeImage(i)}
                                                        />
                                                    ) : (
                                                        <InputFiles
                                                            accept={['.png', '.jpeg', '.jpg']}
                                                            className='input-center'
                                                            handleUpload={(e) => handleUploadProductTypeImage(e, i)}
                                                        />
                                                    )}

                                                    {row.title}
                                                </div>
                                            </TableCell>

                                            {row.items.map(
                                                (item, j) =>
                                                    item.length > 0 && (
                                                        <TableCell
                                                            className='row-cell'
                                                            key={j}
                                                            align={j === 0 ? 'center' : 'right'}>
                                                            <div className='row-cell__data'>
                                                                {item.map((data, dataIdx) => {
                                                                    const {isPrice, rules, name, ...restProps} = data;
                                                                    const baseInputProps = {
                                                                        ...restProps,
                                                                        className: 'data-value',
                                                                        size: 'small',
                                                                        valid: !errors[name]
                                                                    };
                                                                    return typeof data === 'string' ? (
                                                                        <div className='data-value' key={dataIdx}>
                                                                            {data}
                                                                        </div>
                                                                    ) : (
                                                                        <Controller
                                                                            key={dataIdx}
                                                                            rules={rules}
                                                                            name={name}
                                                                            control={control}
                                                                            render={({
                                                                                field: {ref, value, ...restField}
                                                                            }) =>
                                                                                isPrice ? (
                                                                                    <NumericFormat
                                                                                        {...baseInputProps}
                                                                                        {...restField}
                                                                                        customInput={CustomInput}
                                                                                        thousandSeparator={true}
                                                                                        suffix='đ'
                                                                                        value={value ? value : ''}
                                                                                    />
                                                                                ) : (
                                                                                    <CustomInput
                                                                                        {...baseInputProps}
                                                                                        {...restField}
                                                                                        ref={ref}
                                                                                        value={value ? value : ''}
                                                                                    />
                                                                                )
                                                                            }
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        </TableCell>
                                                    )
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </section>
                </>
            )}
        </div>
    );
};

export default AdvanceForm;
