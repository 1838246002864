import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyCEcMqn7YSFzgINK9PRl1aJZJbWI3EaGr4',
    authDomain: 'ecommerce-3574d.firebaseapp.com',
    projectId: 'ecommerce-3574d',
    storageBucket: 'ecommerce-3574d.appspot.com',
    messagingSenderId: '1058555325067',
    appId: '1:1058555325067:web:8c304e4f1cdc04dc93428e',
    measurementId: 'G-D87CN7Y97N'
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
const messaging = getMessaging(app);

export const fetchToken = async () => {
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: 'BDAh3hWYLzFzboDKiFj6YrGS3uo2H3-CekAwSB8pV4eO8xtw8PCQ46KQglK7Ov0jSxEiUogOyBIIKBjpZboAveI'
        });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            return null;
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export default firebaseAuth;
export const firestore = getFirestore(app);
