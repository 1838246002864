import { adminOrderDetailSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch, useSelector} from 'react-redux';
import i18nLabels from './OrderDetail.i18n';
import OrderDetailContainer from './OrderDetailContainer';

const OrderDetail = (props) => {
    const dispatch = useDispatch();
    const adminOrderDetail = useSelector(adminOrderDetailSelector);

    const orderDetailProps = {
        ...props,
        dispatch,
        i18nLabels,
        adminOrderDetail
    };
    return <DecorateContainer {...orderDetailProps} childComponent={OrderDetailContainer} />;
};

export default OrderDetail;
