import React from 'react';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && children}
        </div>
    );
};

export default TabPanel;
