import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {CloseGray, SearchIcon} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CustomInput/CustomInput';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import React from 'react';
import {checkObjectEmpty} from 'utils/checkObjectEmpty';
import RoleAccordion from './RoleAccordion';

const RoleModal = (props) => {
    const {
        intl,
        roleHeadCells,
        roleData,
        open,
        doClose,
        title = '',
        actionButtonTitle = '',
        doConfirm,
        roleInputs,
        handleChangeRoleInput,
        searchRoleValue,
        handleSearchRole,
        checkedRoleData,
        handleChecked
    } = props;
    const isDataEmpty = checkObjectEmpty(roleData);
    const isButtonValid = roleInputs[0].value.length > 0 && Object.keys(checkedRoleData).length > 0;

    return (
        <Dialog
            maxWidth='md'
            fullWidth={true}
            open={open}
            onClose={doClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className='dialog dialog-user-role'>
            <DialogTitle className='dialog-title' id='alert-dialog-title'>
                <span>{title}</span>
                <button className='dialog-title__close'>
                    <CloseGray onClick={doClose} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content'>
                <div className='dialog-content__container'>
                    <div className='role-inputs'>
                        {roleInputs.map((input) => (
                            <CustomInput
                                key={input.name}
                                {...input}
                                onChange={(e) => handleChangeRoleInput(input.name, e.target.value)}
                            />
                        ))}
                    </div>

                    <div className='role-table' role='table'>
                        <div className='role-table__title'>
                            <h4 className='m-0'>{intl.messages.roleList}</h4>
                            <div className='role-search'>
                                <CustomInput
                                    name='searchRole'
                                    starticon={<SearchIcon />}
                                    placeholder={intl.messages.searchRole}
                                    value={searchRoleValue}
                                    onChange={handleSearchRole}
                                />
                            </div>
                        </div>
                        <div className='role-table__header'>
                            {roleHeadCells.map((headCell, idx) => (
                                <div className={`data-col data-col-${idx} ${headCell.className}`} key={headCell.id}>
                                    {headCell.label}
                                </div>
                            ))}
                        </div>

                        <div className='role-table__body'>
                            {!isDataEmpty ? (
                                <div className='body-content'>
                                    {Object.entries(roleData).map((data, idx) => {
                                        return (
                                            <RoleAccordion
                                                data={data}
                                                key={idx}
                                                handleChecked={handleChecked}
                                                checkedRoleData={checkedRoleData}
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <EmptyTableData label={intl.messages.noDataFound} />
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <CustomButton size='md' variant='text' onClick={doClose}>
                    {intl.messages.close}
                </CustomButton>
                <CustomButton size='md' onClick={doConfirm} disabled={!isButtonValid}>
                    {actionButtonTitle}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default RoleModal;
