import React, {useLayoutEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './UserProfilePropsProvider';
import UserProfileMainView from './template/UserProfileMainView';
import {pagePath} from 'configuration/routeConfig';
import {Bell, CardHeart, Clipboard, Connection, UserOutline, Voucher} from 'assets/images';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {authSelector} from 'app/selectors';

const UserProfileContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();

    const location = useLocation();
    const {pathname} = location;

    const auth = useSelector(authSelector);
    const {permission} = auth;

    const [sidebarItems, setSidebarItems] = useState([
        {
            title: intl.messages.myAccount,
            icon: <UserOutline />,
            isExpanded: false,
            items: [
                {
                    title: intl.messages.profile,
                    to: pagePath.userProfileUrl,
                    isActive: false
                },
                {
                    title: intl.messages.addressList,
                    to: pagePath.userAddressUrl,
                    isActive: false
                }
            ]
        },
        {
            title: intl.messages.myOrder,
            to: pagePath.userMyPurchaseUrl,
            icon: <Clipboard />,
            isExpanded: false
        },
        {
            title: intl.messages.notification,
            icon: <Bell />,
            items: [
                {
                    title: intl.messages.updateOrder,
                    to: pagePath.userNotiOrderUrl,
                    isActive: false
                },
                {
                    title: intl.messages.voucher,
                    to: pagePath.userNotiPromotionUrl,
                    isActive: false
                },
                {
                    title: intl.messages.other,
                    to: pagePath.userNotiOtherUrl,
                    isActive: false
                }
            ],
            isExpanded: false
        },
        {
            title: intl.messages.favoriteProduct,
            icon: <CardHeart />,
            to: pagePath.userMyLikeProductUrl,
            isExpanded: false
        },
        {
            title: intl.messages.voucherStorage,
            icon: <Voucher />,
            to: pagePath.userVoucherUrl,
            isExpanded: false
        }
        // {
        //     title: intl.messages.setting,
        //     icon: <Setting />,
        //     isExpanded: false
        // },
        // {
        //     title: intl.messages.supportCenter,
        //     icon: <Help />,
        //     isExpanded: false
        // }
    ]);

    const toggleAccordion = (title) => {
        const newAdminSidebar = sidebarItems.map((navItem) => {
            if (navItem.title === title) {
                navItem.isExpanded = !navItem.isExpanded;
            }

            return navItem;
        });

        setSidebarItems(newAdminSidebar);
    };

    const navigateTo = (path) => {
        history.push(path);
    };

    useLayoutEffect(() => {
        const newAdminSidebar = sidebarItems.map((page) => {
            if (page.to && page.to === pathname) {
                page.isActive = true;
            } else {
                page.items?.map((item) => {
                    if (item.to === pathname) {
                        item.isActive = true;
                    } else item.isActive = false;
                    return item;
                });
            }

            return page;
        });

        setSidebarItems(newAdminSidebar);
    }, [pathname]);

    useLayoutEffect(() => {
        if (
            Array.isArray(permission) &&
            permission.length > 0 &&
            sidebarItems[sidebarItems.length - 1].to !== pagePath.adminUrl
        ) {
            const newSidebarItems = [...sidebarItems];
            newSidebarItems.push({
                title: intl.messages.sellerPage,
                icon: <Connection />,
                isExpanded: false,
                to: pagePath.adminUrl
            });

            setSidebarItems(newSidebarItems);
        }
    }, [permission]);

    const userProfileContainerProps = {
        ...props,
        intl,
        dispatch,
        sidebarItems,
        toggleAccordion,
        navigateTo
    };

    return <UserProfileMainView {...propsProvider(userProfileContainerProps)} />;
};

export default UserProfileContainer;
