import axios from 'axios';
import axiosClient from './axiosClient';

const paymentApi = {
    getPaymentMethodData: () => {
        const url = '/api/proceedSubSteps';
        return axiosClient.get(url, {params: {Filters: 'type==payment'}});
    },
    addPaymentCart: (data) => {
        const url = `/api/paymentCart/addCartProductToPaymentCart`;
        return axiosClient.post(url, data);
    },
    getPaymentCart: () => {
        const url = '/api/paymentCart';
        return axiosClient.get(url);
    },
    paymentCartOrder: ({paymentParams, data}) => {
        const url = '/api/paymentCart/order';
        return axiosClient.post(url, data, {params: paymentParams});
    },
    getVNPayPaymentUrl: (params) => {
        const url = '/api/payment/getVNPayPaymentUrl';
        return axiosClient.get(url, {params});
    },
    confirmVNPayPayment: (data) => {
        const url = '/api/payment/vnPayPaymentConfirm';
        return axiosClient.post(url, data);
    },
    getMomoPaymentUrl: (params) => {
        const url = '/api/payment/getMoMoPayUrl';
        return axiosClient.get(url, {params});
    },
    confirmMomoPayment: (data) => {
        const url = '/api/payment/confirmMoMoPaymentClient';
        return axiosClient.post(url, data);
    },
    createOrderGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/create';
        return axios.post(
            url,
            {...data},
            {
                headers: {
                    Token: '1986b6a9-18b9-11ed-b136-06951b6b7f89',
                    ShopId: '123853'
                }
            }
        );
    },
    calculateShippingFeeGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/fee';
        return axios.post(
            url,
            {...data},
            {
                headers: {
                    Token: '1986b6a9-18b9-11ed-b136-06951b6b7f89',
                    ShopId: '123853'
                }
            }
        );
    },
    getAllProvinceGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/province';
        return axios.get(url, {
            headers: {
                Token: '1986b6a9-18b9-11ed-b136-06951b6b7f89'
            }
        });
    },
    getAllDistrictByProvinceGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/district';
        return axios.post(
            url,
            {...data},
            {
                headers: {
                    Token: '1986b6a9-18b9-11ed-b136-06951b6b7f89'
                }
            }
        );
    },
    getAllWardByDistrictGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/master-data/ward?district_id';
        return axios.post(
            url,
            {...data},
            {
                headers: {
                    Token: '1986b6a9-18b9-11ed-b136-06951b6b7f89'
                }
            }
        );
    },
    getAvailableServicesGHN: (data) => {
        const url = 'https://dev-online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/available-services';
        return axios.post(
            url,
            {...data},
            {
                headers: {
                    token: '1986b6a9-18b9-11ed-b136-06951b6b7f89'
                }
            }
        );
    }
};

export default paymentApi;
