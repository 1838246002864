import React, {useState} from 'react';
import {Chat} from 'assets/images';
import {Popover} from '@mui/material';
import ChatBoxContainer from './subViews/ChatBoxContainer/ChatBoxContainer';

const ChatBoxMainView = (props) => {
    const {intl, navigateTo, chatBoxContainerProps, unreadCount} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setIsExpanded(false);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <button className='chatbox-button' onClick={handleClick} aria-describedby={id}>
                <Chat />
                {unreadCount > 0 && <div className='unread-badge'>{unreadCount < 10 ? unreadCount : '+9'}</div>}
            </button>
            <Popover
                PaperProps={{className: 'chatbox-paper'}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableScrollLock={true}
                aria-expanded={isExpanded}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}>
                <ChatBoxContainer
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    handleClose={handleClose}
                    {...chatBoxContainerProps}
                />
            </Popover>
        </>
    );
};

export default ChatBoxMainView;
