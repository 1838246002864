import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import reportApi from 'api/reportApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        categories: [],
        orderStatistics: {},
        buyerPercentage: {},
        productRankingData: [],
        userAccess: {}
    };
};

export const getCategories = createAsyncThunk('shopReport/categories', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const getUserAccess = createAsyncThunk('shopReport/getUserAccess', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.userAccess,
        payload: data
    });
});

export const getOrderStatistics = createAsyncThunk('shopReport/getOrderStatistics', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.orderStatistics,
        payload: data
    });
});

export const getBuyerPercentage = createAsyncThunk('shopReport/getBuyerPercentage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.buyerPercentage,
        payload: data
    });
});

export const getProductSales = createAsyncThunk('shopReport/getProductSales', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.productSales,
        payload: data
    });
});

export const getProductView = createAsyncThunk('shopReport/getProductView', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.productView,
        payload: data
    });
});

export const getProductConversionRate = createAsyncThunk(
    'shopReport/getProductConversionRate',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: reportApi.conversionRate,
            payload: data
        });
    }
);

export const getProductSold = createAsyncThunk('shopReport/getProductSold', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: reportApi.productSold,
        payload: data
    });
});

const shopReport = createSlice({
    name: 'shopReport',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload.data;
            })
            .addCase(getBuyerPercentage.fulfilled, (state, action) => {
                state.buyerPercentage = action.payload.data;
            })
            .addCase(getOrderStatistics.fulfilled, (state, action) => {
                state.orderStatistics = action.payload.data;
            })
            .addCase(getUserAccess.fulfilled, (state, action) => {
                state.userAccess = action.payload.data;
            })
            .addCase(getProductSales.fulfilled, (state, action) => {
                const {data} = action.payload;
                const displayData = [];

                data.forEach((item) => {
                    const {product, sales_value} = item;
                    if (product) {
                        const {price, name, default_image} = product;

                        displayData.push({
                            value: 'đ' + (sales_value > 0 ? `${(sales_value / 1000).toFixed(2)}K` : sales_value),
                            price,
                            name,
                            image: default_image
                        });
                    }
                });
                state.productRankingData = displayData;
            })
            .addCase(getProductView.fulfilled, (state, action) => {
                const {data} = action.payload;
                const displayData = [];

                data.forEach((item) => {
                    const {product, total_view} = item;
                    if (product) {
                        const {price, name, default_image} = product;

                        displayData.push({
                            value: total_view,
                            price,
                            name,
                            image: default_image
                        });
                    }
                });
                state.productRankingData = displayData;
            })
            .addCase(getProductConversionRate.fulfilled, (state, action) => {
                const {data} = action.payload;
                const displayData = [];

                data.forEach((item) => {
                    const {product, convention_rate} = item;
                    if (product) {
                        const {price, name, default_image} = product;

                        displayData.push({
                            value: Math.round(convention_rate),
                            price,
                            name,
                            image: default_image
                        });
                    }
                });
                state.productRankingData = displayData;
            })
            .addCase(getProductSold.fulfilled, (state, action) => {
                const {data} = action.payload;
                const displayData = [];

                data.forEach((item) => {
                    const {product, solded_value} = item;
                    if (product) {
                        const {price, name, default_image} = product;

                        displayData.push({
                            value: solded_value,
                            price,
                            name,
                            image: default_image
                        });
                    }
                });
                state.productRankingData = displayData;
            });
    }
});

const {reducer} = shopReport;
export default reducer;
