export default function propsProvider(props) {
    const {
        intl,
        setFilterData,
        submitFilterNotification,
        notificationList,
        currentPage,
        handleChangePageSize,
        handleChangePageIndex,
        isSchedule,
        handleRadioScheduleChange,
        handleSubmitNotification,
        handleChangeDateBasicInfomation,
        handleChangeTimeBasicInfomation,
        notificationName,
        setNotificationName,
        setNotificationDetail,
        setDetailImages,
        detailImages,
        isImageUploadedError,
        setIsImageUploadedError,
        setIsAdd,
        isNotAdd,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalPage,
        pageSizeRange,
        selectedItem,
        setselectedItem,
        isEdit,
        setIsEdit,
        isDublicate,
        setIsDublicate,
        isDelete,
        setIsDelete,
        deleteId,
        setDeleteId,
        handleClodeModal,
        handleDelete
    } = props;

    return {
        intl,
        isNotAdd,
        setIsAdd,
        isEdit,
        setIsEdit,
        setIsDublicate,
        deleteId,
        setDeleteId,
        handleClodeModal,
        handleDelete,
        isDublicate,
        setselectedItem,
        notificationFilterBarProps: {
            intl,
            submitFilterNotification,
            setFilterData
        },
        basicInformationProps: {
            intl,
            isSchedule,
            handleRadioScheduleChange,
            notificationName,
            setNotificationName,
            handleChangeDateBasicInfomation,
            handleChangeTimeBasicInfomation,
            selectedItem
        },
        notificationDetailProps: {
            intl,
            setDetailImages,
            detailImages,
            setNotificationDetail,
            setIsImageUploadedError,
            isImageUploadedError,
            selectedItem
        },
        NotificationFooterProps: {
            intl,
            handleSubmitNotification
        },
        notificationTableProps: {
            intl,
            notificationList,
            currentPage: page,
            handleChangePageSize,
            handleChangePageIndex,
            page,
            setPage,
            pageSize,
            setPageSize,
            totalPage,
            pageSizeRange,
            selectedItem,
            setselectedItem,
            isEdit,
            setIsEdit,
            isDublicate,
            setIsDublicate,
            isDelete,
            setIsDelete,
            setDeleteId
        }
    };
}
