export default function propsProvider(props) {
    const {
        intl,
        dispatch,
        adminOrderDetail,
        currentStep,
        handleOrderStep,
        countDown,
        isOpenReasonModal,
        closeReasonModal,
        handleCancelOrder,
        reasonValue,
        setReasonValue,
        isOpenRefundModal,
        closeRefundModal,
        openRefundModal
    } = props;
    const {orderDetail} = adminOrderDetail;
    const {
        order_information,
        order_receive_user,
        extend_order_product,
        brand_voucher,
        shipping_unit,
        payment_method,
        order_price_information,
        cancel_description,
        sub_step_name,
        sub_step_code,
        extend_order_shop_cancel_user_information,
        applied_voucher_list
    } = orderDetail;

    const productList =
        extend_order_product &&
        extend_order_product.map((item) => ({
            productId: item?.product_id,
            orderProductId: item?.order_product_id,
            name: item?.product_name,
            default_img: item?.product_default_image,
            price: item?.product_price,
            type: item?.product_type_value_text,
            quantity: item?.quantity,
            productCategoryId: item?.product_category
        }));

    const calcAppliedVoucherValue = () => {
        if (applied_voucher_list && applied_voucher_list.length > 0 && productList) {
            const {value, value_percentage, category_ids} = applied_voucher_list[0];
            let productVoucherPrice = 0;
            productList.forEach((product) => {
                if (category_ids.includes(product?.productCategoryId)) {
                    productVoucherPrice += product?.price * product?.quantity;
                }
            });
            return Math.min(value, (value_percentage / 100) * productVoucherPrice);
        }
    };

    const appliedVoucherValue = calcAppliedVoucherValue();

    return {
        intl,
        dispatch,
        isOpenRefundModal,
        closeRefundModal,
        openRefundModal,
        paymentMethodProps: {
            intl,
            paymentMethodInfo: payment_method && {
                methodName: payment_method?.name,
                methodIcon: payment_method?.image,
                methodType: payment_method?.type
            }
        },
        productListProps: {
            intl,
            note: order_information?.order_note,
            shippingUnitInfo: shipping_unit && {
                unitName: shipping_unit?.shipping_unit_name,
                shippingFee: shipping_unit?.shipping_unit_fee
            },
            voucherInfo: brand_voucher && {
                voucherCode: brand_voucher?.voucher_code,
                voucherValue: brand_voucher?.voucher_value,
                appliedVoucherValue
            },
            productList,
            totalProduct: order_price_information?.total_product,
            totalPrice: order_price_information?.total_product_price
        },
        transportInformationProps: {
            intl,
            transportInfo: order_receive_user && {
                receiverName: order_receive_user?.fullname,
                receiverPhone: order_receive_user?.phone,
                receiverAddress: order_receive_user?.full_address,
                billOfLandingCode: order_receive_user?.bill_of_lading_code,
                expectedDeliveryTime: order_receive_user?.expected_delivery_time
            }
        },
        yourBillProps: {
            intl,
            billInfo: order_price_information && {
                totalProduct: order_price_information?.total_product,
                totalProductPrice: order_price_information?.total_product_price,
                shippingFee: order_price_information?.shipping_fee,
                discount: appliedVoucherValue,
                totalPrice:
                    order_price_information?.total_product_price +
                    order_price_information?.shipping_fee -
                    (appliedVoucherValue || 0),
                user_email: order_receive_user.email
            },
            productList
        },
        orderInformationProps: {
            intl,
            openRefundModal,
            orderInfo: order_information && {
                orderCode: order_information?.order_code,
                created_at: order_information?.order_created_at,
                statusName: order_information.extend_step?.name,
                statusColor: order_information.extend_step?.color,
                statusTextColor: order_information.extend_step?.text_color,
                statusCode: order_information.extend_step?.code,
                statusDescription: order_information.extend_step?.description
            },
            subStepName: sub_step_name,
            subStepCode: sub_step_code,
            cancelDescription: cancel_description,
            currentStep,
            handleOrderStep,
            countDown,
            cancelerAdminName: extend_order_shop_cancel_user_information?.full_name
        },
        cancelReasonModalProps: {
            intl,
            isOpenReasonModal,
            closeReasonModal,
            handleCancelOrder,
            reasonValue,
            setReasonValue
        }
    };
}
