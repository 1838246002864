import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './headerPropsProvider';
import HeaderMainView from './template/HeaderMainView';
import {handleResetRelatedCategories, handleSearchFilter} from 'features/PLP/productListSlice';
import {handleModalBackdrop} from 'app/ecomSlice';
import {useForm} from 'react-hook-form';
import {validateToken, getMyInfo, getMyPermissions, logout} from 'features/Auth/authSlice';
import {getCartProducts} from 'features/Cart/cartSlice';
import {pagePath} from 'configuration/routeConfig';
import Events from 'utils/Events';
import Config from 'configuration';
import {matchPath, useLocation} from 'react-router-dom';
import {paramsKey} from 'configuration/storageKey';
import {getHeaderNotifications, setMobileTitle} from './headerSlice';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const defaultValues = {searchValue: ''};

const HeaderContainer = (props) => {
    const {dispatch, history, auth, productList, isInProductList, cart, header} = props;
    const intl = useIntl();
    const [accountDropdownDataState, setAccountDropdownDataState] = useState('closed');
    const [notiDropdownDataState, setNotiDropdownDataState] = useState('closed');
    const notificationDropdownRef = useRef(null);
    const accountDropdownRef = useRef(null);
    const location = useLocation();
    const {pathname} = location;
    const {isLogin} = auth;
    const [windowSize] = useDeviceSize();
    const isMobile = windowSize.width < 576;

    const navigateTo = async (path) => {
        if (path === 'logout') {
            await dispatch(logout());
        } else history.push(path);
    };
    const openDropdown = (type = 'noti') => {
        if (type === 'noti') {
            handleUpdateNoti();

            if (!isMobile) {
                setNotiDropdownDataState('open');
            } else {
                goToNotification();
            }
        } else setAccountDropdownDataState('open');
    };
    const closeNotiDropdown = () => {
        setNotiDropdownDataState('closing');

        notificationDropdownRef.current?.addEventListener('animationend', () => setNotiDropdownDataState('closed'), {
            once: true
        });
    };
    const closeAccountDropdown = () => {
        setAccountDropdownDataState('closing');

        accountDropdownRef.current?.addEventListener('animationend', () => setAccountDropdownDataState('closed'), {
            once: true
        });
    };

    const toggleDropdown = (type) => {
        if (type === 'noti') {
            if (notiDropdownDataState === 'closed' && !isMobile) {
                openDropdown('noti');
            } else {
                if (isMobile) {
                    goToNotification();
                } else {
                    closeNotiDropdown();
                }
            }
        } else if (type === 'account') {
            if (accountDropdownDataState === 'closed') {
                openDropdown(type);
            } else {
                closeAccountDropdown();
            }
        }
    };
    const goToNotification = () => {
        navigateTo(pagePath.userNotiUrl);
        closeNotiDropdown();
    };

    const showProductListSearchFilter = () => {
        dispatch(handleSearchFilter('open'));
        dispatch(handleModalBackdrop(true));
    };
    const {handleSubmit, register, reset} = useForm({defaultValues});
    const onSearchProduct = (data) => {
        const {searchValue} = data;
        reset(defaultValues);
        dispatch(handleResetRelatedCategories());
        navigateTo(pagePath.plpUrl + `?${paramsKey.searchBy}=${searchValue}`);
    };
    const handleUpdateNoti = () => {
        Events.emit(Config.eventsKey.updateHeaderNoti);
    };

    useEffect(() => {
        if (!isLogin && !localStorage.getItem(Config.storageKey.auth)) return;
        if (!isLogin && localStorage.getItem(Config.storageKey.auth)) {
            if (sessionStorage.getItem('logout')) return;
            dispatch(validateToken(JSON.parse(localStorage.getItem(Config.storageKey.auth)).token));
        }
        if (isLogin) {
            dispatch(getMyInfo());
            dispatch(getCartProducts());
            dispatch(getMyPermissions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    useEffect(() => {
        if (isLogin && pathname !== pagePath.userNotiUrl) {
            const data = {
                Sorts: '-created_at',
                Page: 1,
                PageSize: 4
            };
            const updateNoti = Events.addListener(Config.eventsKey.updateHeaderNoti, async () => {
                await dispatch(getHeaderNotifications({params: data}));
            });

            handleUpdateNoti();

            return () => updateNoti.remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, pathname]);

    useEffect(() => {
        const updateCart = Events.addListener(Config.eventsKey.updateCart, async () => {
            await dispatch(getCartProducts());
        });

        return () => updateCart.remove();
    });

    useLayoutEffect(() => {
        if (notiDropdownDataState === 'open') {
            closeNotiDropdown();
        } else if (accountDropdownDataState === 'open') {
            closeAccountDropdown();
        }
    }, [pathname]);

    useLayoutEffect(() => {
        if (isMobile) {
            if (pathname.includes('user')) {
                const navSectionList = [
                    [
                        {
                            title: intl.messages.favoriteProduct,
                            to: pagePath.userMyLikeProductUrl
                        },
                        {
                            title: intl.messages.voucherStorage,
                            to: pagePath.userVoucherUrl
                        },
                        {
                            title: intl.messages.myOrder,
                            to: pagePath.userMyPurchaseUrl
                        },
                        {
                            title: intl.messages.notification,
                            items: [
                                {
                                    title: intl.messages.updateOrder,
                                    to: pagePath.userNotiOrderUrl
                                },
                                {
                                    title: intl.messages.promotion,
                                    to: pagePath.userNotiPromotionUrl
                                },
                                {
                                    title: intl.messages.other,
                                    to: pagePath.userNotiOtherUrl
                                }
                            ]
                        }
                    ],
                    [
                        {
                            title: intl.messages.setting,
                            items: [
                                {
                                    title: intl.messages.myProfile,
                                    to: pagePath.userProfileUrl
                                },
                                {
                                    title: intl.messages.myAddressList,
                                    to: pagePath.userAddressUrl
                                }
                            ]
                        },
                        {
                            title: intl.messages.supportCenter
                        }
                    ]
                ];
                let mobileTitle = null;
                for (const section of navSectionList) {
                    for (const page of section) {
                        const {to, title, items} = page;
                        if (to && to === pathname) {
                            mobileTitle = title;
                            break;
                        } else {
                            if (items) {
                                for (const item of items) {
                                    if (item.to === pathname) {
                                        mobileTitle = item.title;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }

                if (!mobileTitle) {
                    if (matchPath(pathname, {path: pagePath.userRefundUrl})) {
                        mobileTitle = intl.messages.refund;
                    }
                }

                dispatch(setMobileTitle(mobileTitle));
            } else {
                dispatch(setMobileTitle(null));
            }
        } else {
            dispatch(setMobileTitle(null));
        }
    }, [pathname, isMobile]);

    const headerProps = {
        ...props,
        intl,
        navigateTo,
        dispatch,
        auth,
        showProductListSearchFilter,
        productList,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toggleDropdown,
        accountDropdownDataState,
        cart,
        header,
        goToNotification,
        notificationDropdownRef,
        accountDropdownRef,
        notiDropdownDataState,
        closeAccountDropdown,
        closeNotiDropdown
    };

    return <HeaderMainView {...propsProvider(headerProps)} />;
};

export default HeaderContainer;
