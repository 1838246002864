import React, { useEffect } from 'react';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import i18nLabels from './notification.i18n';
import NotificationContainer from './NotificationContainer';
import { notificationSelector } from 'app/selectors';
import { pagePath } from 'configuration/routeConfig';

export default function Notification(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const notification = useSelector(notificationSelector);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        if (pathname === pagePath.userNotiUrl) {
            history.push(pagePath.userNotiOrderUrl);
        }
    }, [pathname]);

    const notificationProps = {
        ...props,
        dispatch,
        history,
        i18nLabels,
        notification,
        pathname
    };
    return <DecorateContainer {...notificationProps} childComponent={NotificationContainer} />;
}
