import { handleModalBackdrop } from 'app/ecomSlice';
import { getMyInfo } from 'features/Auth/authSlice';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { reFormatNumber } from 'utils/reFormatNumber';
import propsProvider from './ProductListPropsProvider';
import {
    getAllRelatedCategories,
    getProducts,
    handleSearchFilter,
    resetSubCategory,
    storeSearchValue
} from './productListSlice';
import ProductListMainView from './template/ProductListMainView';
import { regionMockdata } from './template/subViews/SearchFilter/regionMockdata';

export default function ProductListContainer(props) {
    /*-----TODO-----
- To declare state, props, func
- Call API
- To setup data for views
*/
    const intl = useIntl();

    const maxStars = 5;
    const initialPriceRange = useMemo(() => {
        return { fromPrice: '', toPrice: '' };
    }, []);
    const ratingData = Array.from({ length: maxStars }, (_, i) => i + 1).reverse();
    const recommededPriceRange = [
        { from: 50, to: 100 },
        { from: 100, to: 200 },
        { from: 200, to: 500 },
        { from: 500, to: 1000 },
        { from: intl.messages.overOneMil }
    ];

    const { dispatch, productList, i18nLabels, auth, history, searchValue, searchCate } = props;
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('default');
    const [sortOrder, setSortOrder] = useState(0);
    const [activeRecommendedRange, setActiveRecommendedRange] = useState(-1);
    const [priceRange, setPriceRange] = useState(initialPriceRange);
    const [subSubCategoryChecked, setSubSubCategoryChecked] = useState(Array(100).fill(false));
    const [regionChecked, setRegionChecked] = useState(Array(regionMockdata.length).fill(false));
    const [ratingChecked, setRatingChecked] = useState(Array(maxStars).fill(false));
    const { searchValueStore, showSearchFilterState, relatedCategories } = productList;
    const [searchFilterData, setSearchFilterData] = useState({});
    const searchFilterRef = useRef(null);
    const { isLogin } = auth;

    let cateName, cateId;
    if (searchCate) {
        [cateName, cateId] = searchCate.split('.');
    }

    const navigateTo = (path) => {
        history.push(path);
    };

    const selectRecommendedPrice = (range, idx) => {
        setActiveRecommendedRange(idx);

        if (!range.to) {
            setPriceRange({ fromPrice: '1000000', toPrice: '0' });
        } else {
            setPriceRange({ fromPrice: (range.from * 1000).toString(), toPrice: (range.to * 1000).toString() });
        }
    };

    const handlePriceChange = (e) => {
        setActiveRecommendedRange(-1);
        setPriceRange((prevPriceRange) => {
            return {
                ...prevPriceRange,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleCheckedChange = ({ type, position }) => {
        if (type === 'subSubCategories') {
            const newCategoryChecked = subSubCategoryChecked.map((item, idx) => (position === idx ? !item : item));
            setSubSubCategoryChecked(newCategoryChecked);
        } else if (type === 'region') {
            const newRegionChecked = regionChecked.map((item, idx) => (position === idx ? !item : item));
            setRegionChecked(newRegionChecked);
        } else {
            const newRatingChecked = ratingChecked.map((item, idx) => (position === idx ? !item : item));
            setRatingChecked(newRatingChecked);
        }
    };

    const resetFilter = useCallback(() => {
        setActiveRecommendedRange(-1);
        setPriceRange(initialPriceRange);
        setRegionChecked(Array(regionMockdata.length).fill(false));
        setRatingChecked(Array(maxStars).fill(false));
        setSearchFilterData({});
    }, [initialPriceRange]);

    const applyFilter = (e) => {
        e.preventDefault();

        const filterData = {
            location: [...regionMockdata.map((region) => region.name).filter((_, idx) => regionChecked[idx])],
            rating: [...ratingData.filter((_, idx) => ratingChecked[idx])],
            fromPrice: priceRange.fromPrice.length > 0 ? reFormatNumber(priceRange.fromPrice) : undefined,
            toPrice: priceRange.toPrice.length > 0 ? reFormatNumber(priceRange.toPrice) : undefined
        };
        const filterDataAfterRemovingEmptyValue = {};

        Object.entries(filterData)
            .filter(([_, value]) => value && !(value.length === 0))
            .forEach(([key, value]) => {
                filterDataAfterRemovingEmptyValue[key] = value;
            });

        setPage(1);
        setSearchFilterData(filterDataAfterRemovingEmptyValue);
        closingSearchFilter();
    };

    const closingSearchFilter = useCallback(() => {
        dispatch(handleSearchFilter('closing'));
        dispatch(handleModalBackdrop(false));

        searchFilterRef.current?.addEventListener(
            'animationend',
            () => {
                dispatch(handleSearchFilter('closed'));
            },
            { once: true }
        );
    }, []);

    useEffect(() => {
        if (isLogin) {
            dispatch(getMyInfo());
        }
    }, [isLogin]);

    useEffect(() => {
        if (showSearchFilterState === 'open') {
            closingSearchFilter();
        }
    }, [searchValue, closingSearchFilter]);

    useEffect(() => {
        if (cateId) {
            dispatch(getAllRelatedCategories(cateId));
        }
    }, [cateId]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        const searchby =
            relatedCategories.length > 0
                ? `product_category_id==${relatedCategories.join('|')}`
                : searchValue
                    ? `(name|slug|keyword)@=*${searchValue.toLowerCase()}`
                    : "(name|slug|keyword)@=*''";
        const isInitialSearch = searchValueStore === '';
        const sameSearchValue = searchValue === searchValueStore || isInitialSearch;

        const productsParams = {
            Filters:
                'is_hidden==false,is_published==true,' +
                searchby +
                (searchFilterData.fromPrice ? `,price>=${searchFilterData.fromPrice}` : '') +
                (searchFilterData.toPrice ? `,price<=${searchFilterData.toPrice}` : '') +
                (searchFilterData.rating ? `,avg_rate==${searchFilterData.rating.join('|')}` : ''),
            Sorts: sortBy === 'default' ? null : sortOrder === 0 ? sortBy : `-${sortBy}`,
            Page: page,
            PageSize: 16
        };

        dispatch(getProducts({ data: productsParams, signal }));

        if (searchValue) {
            dispatch(resetSubCategory());

            if (!sameSearchValue || isInitialSearch) {
                setSearchFilterData({});
                resetFilter();
                dispatch(storeSearchValue(searchValue));
            }
        }

        return () => {
            controller.abort();
        };
    }, [sortBy, page, sortOrder, searchFilterData, searchValue, relatedCategories, resetFilter]);

    // useEffect(() => {
    //     let tempSubCate = [],
    //         tempSubSubCate = [];

    //     Object.keys(subCategory).forEach((key) => {
    //         tempSubCate.push({id: key, name: subCategory[key].name});
    //         tempSubSubCate.push(...subCategory[key].subSubCategories);
    //     });

    //     setSubCategoryData(tempSubCate);
    //     setSubSubCategoryData(tempSubSubCate);
    // }, [subCategory, subCategoryChecked]);

    // useEffect(() => {
    //     setSubCategoryChecked(Array(subCategoryData.length).fill(false));
    // }, [subCategoryData.length]);

    // useEffect(() => {
    //     setSubSubCategoryChecked(Array(subSubCategoryData.length).fill(false));
    // }, [subSubCategoryData.length]);

    const productListContainerProps = {
        ...props,
        intl,
        i18nLabels,
        productList,
        navigateTo,
        page,
        setPage,
        setSortBy,
        setSortOrder,
        searchValue,
        cateName,
        activeRecommendedRange,
        selectRecommendedPrice,
        handlePriceChange,
        handleCheckedChange,
        resetFilter,
        applyFilter,
        recommededPriceRange,
        setSubSubCategoryChecked,
        searchFilterRef,
        closingSearchFilter,
        subSubCategoryChecked,
        regionChecked,
        priceRange,
        setPriceRange,
        ratingData,
        ratingChecked
    };

    return <ProductListMainView {...propsProvider(productListContainerProps)} />;
}
