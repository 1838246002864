import React from 'react';
import NotificationEmpty from './subViews/NotificationEmpty';
import NotificationList from './subViews/NotificationList';

const NotificationMainView = (props) => {
    const {notificationListProps, notifications, intl} = props;
    return (
        <main className='notification'>
            <div className='col p-0'>
                {notifications.length > 0 ? (
                    <NotificationList {...notificationListProps} />
                ) : (
                    <NotificationEmpty intl={intl} />
                )}
            </div>
        </main>
    );
};

export default NotificationMainView;
