import {adminViewOrdersSelector} from 'app/selectors';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ViewOrdersMainView from './template/ViewOrdersMainView';
import propsProvider from './ViewOrdersPropsProvider';
import {getAdminOrders, getShippingUnits, handleChangePage, handleChangePageSize} from './ViewOrdersSlice';
import moment from 'moment';
import {pagePath} from 'configuration/routeConfig';
import {proceedOrderStep, proceedOrderSubStep} from '../OrderDetail/OrderDetailSlice';
import Events from 'utils/Events';
import {eventsKey} from 'configuration/storageKey';
import {Toast} from 'utils/toast';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
    addNotificationWithStatus,
    sendPushNotification
} from '../../ShopSetting/NotificationSetting/NotificationSettingSlice';
import {getUserDetail} from 'features/UserProfile/UserProfileSlice';

const pageSizeRange = [5, 10, 15, 20];
export const orderActions = {
    detail: 'detail',
    confirmOrder: 'confirmOrder',
    prepare: 'prepareOrder',
    handOver: 'handOver',
    confirmDelivered: 'confirmDelivered',
    successDelivery: 'successDelivery',
    cancel: 'cancel',
    print: 'print',
    accept: 'accept',
    reject: 'reject'
};

const ViewOrdersContainer = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const navigateTo = (path) => {
        history.push(path);
    };
    const adminViewOrders = useSelector(adminViewOrdersSelector);
    const {adminOrderList, paginationData, shippingUnits} = adminViewOrders;
    const [checkedData, setCheckedData] = useState([]);
    const [filterData, setFilterData] = useState({Filters: [], productName: ''});
    const [activeOrderListTab, setActiveOrderListTab] = useState(0);
    const sum = adminOrderList?.sum;
    const orderListTabs = [
        {
            title: intl.messages.all
        },
        {
            title: intl.messages.needConfirm
        },
        {
            title: intl.messages.waitGetOrder
        },
        {
            title: intl.messages.delivering
        },
        {
            title: intl.messages.delivered
        },
        {
            title: intl.messages.canceled
        },
        {
            title: intl.messages.refund
        }
    ];
    const subTabs = {
        [orderListTabs[2].title]: [
            {title: intl.messages.confirmed, total: sum?.sum_packing_pending, code: 'CONFIRMED'},
            {title: intl.messages.waitForShippingUnit, total: sum?.sum_packing_deli, code: 'PENDING'}
        ],
        [orderListTabs[4].title]: [
            {title: intl.messages.delivered, total: sum?.sum_ship_completed, code: 'SHIPPED'},
            {title: intl.messages.deliverySuccess, total: sum?.sum_ship_completed_user, code: 'DONE'}
        ],
        [orderListTabs[5].title]: [
            {title: intl.messages.shopCancel, total: sum?.sum_cancel_shop, code: 'SHOP_CANCEL'},
            {title: intl.messages.userCancel, total: sum?.sum_cancel_user, code: 'USER_CANCEL'}
        ],
        [orderListTabs[6].title]: [
            {title: intl.messages.unprocess, total: sum?.sum_refund_pending, code: 'REFUND_PENDING'},
            {title: intl.messages.process, total: sum?.sum_refund_handled, code: 'REFUND_HANDLED'}
        ]
    };
    const [activeSubTab, setActiveSubTab] = useState(0);

    const changeSubOrderListTab = (e, newValue) => {
        setActiveSubTab(newValue);
        setPage(1);
    };

    const changeOrderListTab = (e, newValue) => {
        setActiveOrderListTab(newValue);
        setActiveSubTab(0);
    };

    const handleOrderItemAction = async ({type = '', payload}) => {
        let notificationParams = {
            approvedStatusCode: 'SENT'
        };
        const userId = adminOrderList?.collection?.find((el) => el.id === payload[0])?.user_id;
        const orderCode = adminOrderList?.collection?.find((el) => el.id === payload[0])?.code;

        try {
            switch (type) {
                case orderActions.detail:
                    navigateTo(`${pagePath.adminOrderDetailBaseUrl}/${payload}`);
                    break;
                case orderActions.confirmOrder:
                    for (const id of payload) {
                        await dispatch(proceedOrderStep({id, nextStepCode: 'Packing-Pending'})).unwrap();
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'CONFIRMED'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đã được Xác nhận thành công',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.prepare:
                    for (const id of payload) {
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'PENDING'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đang được chờ vận chuyển',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.handOver:
                    for (const id of payload) {
                        await dispatch(proceedOrderStep({id, nextStepCode: 'Delivery'})).unwrap();
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'DELIVERING'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đang trong quá trình vận chuyển',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.confirmDelivered:
                    for (const id of payload) {
                        await dispatch(proceedOrderStep({id, nextStepCode: 'Delivered'})).unwrap();
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'SHIPPED'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đã được giao thành công',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.cancel:
                    for (const id of payload) {
                        await dispatch(proceedOrderStep({id, nextStepCode: 'Cancel'})).unwrap();
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'SHOP_CANCEL'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đã được hủy',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.accept:
                    for (const id of payload) {
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'REFUND_ACCEPTED'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đã được chấp nhận hoàn tiền',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                case orderActions.reject:
                    for (const id of payload) {
                        await dispatch(proceedOrderSubStep({id, nextStepCode: 'REFUND_DENIED'})).unwrap();
                        const res = await dispatch(
                            addNotificationWithStatus({
                                notificationParams,
                                data: {
                                    summary: `Cập nhật trạng thái đơn hàng ${orderCode}`,
                                    details: 'Đơn hàng của bạn đã bị từ chối hoàn tiền',
                                    user_id: userId,
                                    reference_link: `${pagePath.userMyPurchaseUrl}/${payload}`
                                }
                            })
                        ).unwrap();
                        if (res?.success) {
                            const userRes = await dispatch(getUserDetail({id: userId})).unwrap();
                            if (userRes?.success) {
                                await dispatch(
                                    sendPushNotification({
                                        id: res?.data?.id,
                                        fcmClientToken: userRes?.data?.fcm_client_token || ''
                                    })
                                ).unwrap();
                            }
                        }
                    }
                    handleUpdateOrderList();
                    break;
                default:
                    break;
            }

            if (orderActions.detail !== type) Toast(true, intl.messages.actionSuccess);
        } catch (err) {
            Toast(false, intl.messages.actionFail);
        }
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: activeOrderListTab, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: activeOrderListTab, pageSize: value}));
    };

    const submitFilterProduct = (data) => {
        const {fromDate, toDate, searchOrderValue, searchProductValue, orderer, deliveryAgent} = data;

        const tempFilterData = {Filters: [], productName: ''};

        if (searchOrderValue.length > 0) {
            tempFilterData.Filters.push(`code@=*${searchOrderValue}`);
        }
        if (searchProductValue.length > 0) {
            tempFilterData.productName = searchProductValue;
        }
        if (orderer.length > 0) {
            tempFilterData.Filters.push(`fullname@=*${orderer}`);
        }
        if (deliveryAgent.length > 0) {
            tempFilterData.Filters.push(`shipping_unit_name@=*${deliveryAgent}`);
        }
        if (fromDate) {
            tempFilterData.Filters.push(`created_at>=${moment(new Date(fromDate)).format('YYYY/MM/DD')}`);
        }
        if (toDate) {
            tempFilterData.Filters.push(`created_at<=${moment(new Date(toDate)).add(1, 'd').format('YYYY/MM/DD')}`);
        }

        setFilterData(tempFilterData);
    };

    const handleUpdateOrderList = () => {
        Events.emit(eventsKey.updateAdminOrderList);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = adminOrderList?.collection.map((data) => data.id);
            setCheckedData(newSelected);
            return;
        }

        setCheckedData([]);
    };

    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;
        try {
            const updateOrderList = Events.addListener(eventsKey.updateAdminOrderList, async () => {
                const {page, pageSize} = paginationData[activeOrderListTab];
                const targetSubTab = subTabs[orderListTabs[activeOrderListTab].title]
                    ? subTabs[orderListTabs[activeOrderListTab].title][activeSubTab].code
                    : null;
                let data = {
                    Filters: filterData.Filters.join(','),
                    Sorts: '-created_at',
                    Page: page,
                    PageSize: pageSize,
                    productName: filterData.productName || ''
                };

                if (activeOrderListTab === 0) {
                } else if (activeOrderListTab === 1) {
                    data.Filters += ',status_code==Unconfirm';
                } else if (activeOrderListTab === 2) {
                    data.Filters += `,status_code==Packing-Pending,sub_step_code==${targetSubTab}`;
                } else if (activeOrderListTab === 3) {
                    data.Filters += ',status_code==Delivery';
                } else if (activeOrderListTab === 4) {
                    data.Filters += `,status_code==Delivered,sub_step_code==${targetSubTab}`;
                } else if (activeOrderListTab === 5) {
                    data.Filters += `,status_code==Cancel,sub_step_code==${targetSubTab}`;
                } else {
                    if (activeSubTab === 0) {
                        data.Filters += `,status_code==Refund,sub_step_code==${targetSubTab}`;
                    } else {
                        data.Filters += `,status_code==Refund,sub_step_code!=REFUND_PENDING`;
                    }
                }
                await dispatch(getAdminOrders({data, signal})).unwrap();
                setCheckedData([]);
            });

            handleUpdateOrderList();

            return () => {
                updateOrderList.remove();
                controller.abort();
            };
        } catch (e) {
            console.error(e);
        }
        return () => controller.abort();
    }, [activeOrderListTab, dispatch, paginationData, filterData, activeSubTab]);

    useEffect(() => {
        dispatch(getShippingUnits());
    }, []);

    const viewOrdersContainerProps = {
        ...props,
        intl,
        orderListTabs,
        activeOrderListTab,
        changeOrderListTab,
        adminViewOrders,
        pageSizeRange,
        adminOrderList,
        paginationData,
        setPage,
        setPageSize,
        submitFilterProduct,
        setFilterData,
        handleOrderItemAction,
        shippingUnits,
        handleSelectAllClick,
        handleSelectRow,
        checkedData,
        activeSubTab,
        changeSubOrderListTab,
        subTabs
    };

    return <ViewOrdersMainView {...propsProvider(viewOrdersContainerProps)} />;
};

export default ViewOrdersContainer;
