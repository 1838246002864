import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import store from 'app/store';
import { StyledEngineProvider } from '@mui/material';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Routers from 'routers';
import './_styles.scss';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StyledEngineProvider injectFirst>
        <Provider store={store}>
            <Routers />
        </Provider>
    </StyledEngineProvider>
);

serviceWorkerRegistration.register();