import { Tab, Tabs } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';
import { formatQuantity } from 'utils/formatQuantity';
import TableOrderList from './TableOrderList';

const ViewOrderList = (props) => {
    const {
        intl,
        orderListTabs = [],
        activeOrderListTab,
        changeOrderListTab,
        totalRows = 0,
        tableOrderListProps,
        headerActions = [],
        activeSubTab,
        changeSubOrderListTab,
        isShowHeaderActions = false,
        subTabs = {},
    } = props;

    const isOrderListEmpty = totalRows === 0;
    const targetSubTabs = subTabs[orderListTabs[activeOrderListTab]?.title];

    return (
        <div className='view-order__list'>
            <div className='order-list__header'>
                <h1>{intl.messages.orderList}</h1>
            </div>
            <div className='order-list__content'>
                <div className='tabs-list'>
                    <Tabs value={activeOrderListTab} onChange={changeOrderListTab}>
                        {orderListTabs.map((tab) => (
                            <Tab label={tab.title} key={tab.title} />
                        ))}
                    </Tabs>
                </div>

                <div className='tabs-detail'>
                    <div className={`detail-header ${isOrderListEmpty ? 'detail-header--empty' : ''}`}>
                        <h5>
                            {formatQuantity(totalRows)} {intl.messages.order}
                        </h5>

                        {isShowHeaderActions && (
                            <div className='detail-header__actions'>
                                {headerActions.map((action, idx) => {
                                    const { title, ...buttonProps } = action;

                                    return (
                                        <CustomButton key={idx} {...buttonProps}>
                                            {action.title}
                                        </CustomButton>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    {targetSubTabs?.length > 0 && (
                        <div className='d-flex gap-3' role='tablist'>
                            {targetSubTabs.map((orderTab, idx) => (
                                <div
                                    className={`sub-tab ${idx === activeSubTab ? 'sub-tab--active' : ''}`}
                                    key={idx}
                                    onClick={(e) => changeSubOrderListTab(e, idx)}
                                    role='tab'>
                                    {orderTab.title} ({formatQuantity(orderTab.total)})
                                </div>
                            ))}
                        </div>
                    )}

                    <TableOrderList {...tableOrderListProps} />
                </div>
            </div>
        </div>
    );
};

export default ViewOrderList;
