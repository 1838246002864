const i18nLabels = {
    vi: {
        categoryManagement: 'Quản lý danh mục',
        subCategoryManagement: 'Thiết lập, quản lý danh mục ngành hàng',
        categoryList: 'Danh sách ngành hàng',
        searchCategory: 'Tìm kiếm ngành hàng',
        addCategory: 'Thêm ngành hàng',
        cancel: 'Hủy',
        complete: 'Hoàn thành',
        enterCategory: 'Nhập tên ngành hàng',
        categoryName: 'Tên ngành hàng',
        addSuccess: 'Thêm danh mục thành công',
        update: 'Chỉnh sửa',
        delete: 'Xóa',
        editCategoryError: 'Đã có lỗi xảy ra trong quá trình chỉnh sửa danh mục',
        editSuccess: 'Chỉnh sửa danh mục thành công',
        deleteError: 'Không thể xóa danh mục có từ 1 sản phẩm trở lên',
        deleteSuccess: 'Xóa danh mục thành công',
        editAttribute: 'Chỉnh sửa thuộc tính',
        addAttribute: 'Thêm thuộc tính',
        save: 'Lưu',
        addCategoryLevel: 'Thêm danh mục cấp',
        attributeName: 'Tên thuộc tính',
        inheritFromParent: 'Kế thừa thuộc tính danh mục mẹ',
        required: 'Bắt buộc',
        actionSuccess: 'Thao tác thành công',
        actionFailure: 'Thao tác thất bại'
    },
    en: {
        categoryManagement: 'Category Management',
        subCategoryManagement: 'Set up, manage category',
        categoryList: 'Category List',
        searchCategory: 'Search Category',
        addCategory: 'Add Category',
        cancel: 'Cancel',
        complete: 'Complete',
        enterCategory: 'Enter category name',
        categoryName: 'Category Name',
        addSuccess: 'Category added successfully',
        update: 'Update',
        delete: 'Delete',
        editCategoryError: 'An error occurred while editing the category',
        editSuccess: 'Category edited successfully',
        deleteError: 'Cannot delete a category with one or more products',
        deleteSuccess: 'Category deleted successfully',
        editAttribute: 'Edit Attribute',
        addAttribute: 'Add Attribute',
        save: 'Save',
        addCategoryLevel: 'Add Category Level',
        attributeName: 'Attribute Name',
        inheritFromParent: 'Inherit from parent category',
        required: 'Required',
        actionSuccess: 'Action successful',
        actionFailure: 'Action failed'
    }
};

export default i18nLabels;
