const i18nLabels = {
    vi: {
        searchLabel: 'Tìm kiếm sản phẩm',
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        filter: 'Lọc',
        signout: 'Đăng xuất',
        errorMessage: 'Đã xảy ra sự cố. Vui lòng thử lại sau giây lát',
        notification: 'Thông báo',
        readAll: 'Xem tất cả',
        myAccount: 'Tài khoản của tôi',
        myOrder: 'Đơn hàng của tôi',
        sellerPage: 'Kênh người bán',
        emptyNoti: 'Bạn chưa có thông báo nào!',
        vietnamese: "Tiếng Việt",
        english: "Tiếng Anh",
        myProfile: 'Hồ sơ của tôi',
        myAddressList: 'Địa chỉ của tôi',
        updateOrder: 'Cập nhật đơn hàng',
        promotion: 'Khuyến mãi',
        other: 'Khác',
        favoriteProduct: 'Sản phẩm yêu thích',
        voucherStorage: 'Kho voucher',
        sellePage: 'Kênh người bán',
        setting: 'Cài đặt',
        supportCenter: 'Trung tâm hỗ trợ',
        refund: 'Trả hàng/Hoàn tiền',
    },
    en: {
        searchLabel: 'Search product',
        login: 'Log in',
        signup: 'Sign up',
        filter: 'Filter',
        signout: 'Sign out',
        errorMessage: 'An error has occurred. Please try again later!',
        notification: 'Notification',
        readAll: 'Read all',
        myAccount: 'My account',
        myOrder: 'My orders',
        sellerPage: 'Seller channel',
        emptyNoti: "You don't have any notifications!",
        vietnamese: "Vietnamese",
        english: "English",
        myProfile: 'My Profile',
        myAddressList: 'My Address List',
        updateOrder: 'Update Order',
        promotion: 'Promotion',
        other: 'Other',
        favoriteProduct: 'Favorite Products',
        voucherStorage: 'Voucher Storage',
        setting: 'Setting',
        supportCenter: 'Support Center',
        refund: 'Return/Refund',

    }
};

export default i18nLabels;
