const i18nLabels = {
    vi: {
        shippingUnitList: 'Danh sách nhà vận chuyển',
        allStatus: 'Tất cả trạng thái',
        flashDealSetting: 'Cấu hình giờ Flash sales',
        basicInfo: 'Thông tin cơ bản',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        addNew: 'Thêm mới',
        noProduct: 'Chưa có sản phẩm',
        date: 'Ngày',
        timeStart: 'Thời gian bắt đầu',
        allSelected: 'Đã chọn tất cả sản phẩm',
        timeEnd: 'Thời gian kết thúc',
        minStock: 'Tối thiểu',
        maxStock: 'Tối đa',
        category: 'Ngành hàng',
        delete: 'Xóa',
        deleteFDtitle: 'Xóa flash deal',
        delteFdConfirm: 'Bạn có chắc muốn xóa flash deal này không ?',
        enterCategory: 'Chọn ngành hàng',
        storage: 'Kho hàng',
        edit: 'Chỉnh sửa',
        limitOrder: 'Giới hạn đặt hàng',
        limit: 'Giới hạn đặt hàng',
        noLimit: 'Không giới hạn đặt hàng',
        listProduct: 'Danh sách sản phẩm',
        product: 'Sản phẩm',
        classified: 'Phân loại',
        sold: 'Đã bán',
        price: 'Đơn giá (VNĐ)',
        discountPrice: 'Giá đã giảm',
        promotion: 'Khuyến mãi',
        ammountPromotion: 'Sl sp khuyến mãi',
        orderNumber: 'STT',
        shippingUnit: 'Đơn vị vận chuyển',
        transportForm: 'Hình thức vận chuyển',
        activeLabel: 'Đang hoạt động',
        rowOnPage: 'Số dòng trên trang',
        createNew: 'Tạo mới',
        fastShip: 'Giao nhanh',
        normalShip: 'Giao thường',
        ultimateShip: 'Hỏa tốc',
        selfShip: 'Tự vận chuyển',
        description: 'Mô tả',
        close: 'Đóng',
        add: 'Thêm',
        unsuccessfullyDelete: 'Xóa không thành công',
        deleteNotification:
            'Hiện đang có các sản phẩm đang áp dụng đơn vị vận chuyển này, bạn vui lòng đổi đơn vị vận chuyển khác trước khi xóa đơn vị vận chuyển này.',
        homepage: 'Trang chủ',
        storeManagement: 'Quản lý cửa hàng',
        productManagement: 'Quản lý sản phẩm',
        allProducts: 'Tất cả sản phẩm',
        addProduct: 'Thêm sản phẩm',
        editProduct: 'Chỉnh sửa sản phẩm',
        orderManagement: 'Quản lý đơn hàng',
        orderDetail: 'Chi tiết đơn hàng',
        order: 'đơn hàng',
        addOrder: 'Thêm đơn hàng',
        totalPrice: 'Tổng thanh toán',
        dateOfOrder: 'Ngày đặt hàng',
        delivery: 'Vận chuyển',
        status: 'Trạng thái',
        marketing: 'Kênh marketing',
        financial: 'Tài chính',
        statisticData: 'Dữ liệu thống kê',
        customerService: 'Chăm sóc khách hàng',
        design: 'Thiết kế',
        designBanner: 'Thiết kế hình ảnh banner',
        designCategory: 'Thiết kế hình ảnh danh mục',
        shopSetting: 'Cấu hình cửa hàng',
        help: 'Trợ giúp',
        productList: 'Danh sách sản phẩm',
        all: 'Tất cả',
        onSold: 'Đang bán',
        outOfStock: 'Hết hàng',
        hidden: 'Đã ẩn',
        draft: 'Nháp',
        needConfirm: 'Chờ xác nhận',
        waitGetOrder: 'Chờ lấy hàng',
        delivering: 'Đang giao',
        delivered: 'Đã giao',
        canceled: 'Đã huỷ',
        cancel: 'huỷ',
        update: 'Cập nhật',
        refund: 'Hoàn tiền',
        other: 'khác',
        multipleAddingTool: 'Công cụ xử lý hàng loạt',
        SKUtype: 'SKU phân loại',
        classify: 'Phân loại',
        action: 'Thao tác',
        rowsPerPage: 'Số dòng trên trang',
        updateProduct: 'Cập nhật sản phẩm',
        deleteProduct: 'Xóa sản phẩm',
        hideProduct: 'Ẩn sản phẩm',
        noDataFound: 'Không có kết quả được tìm thấy',
        skuProduct: 'SKU Sản phẩm:',
        readMore: 'Xem thêm',
        readLess: 'Ẩn bớt',
        selectAll: 'Chọn tất cả',
        show: 'Hiển thị',
        search: 'Tìm kiếm',
        reInput: 'Nhập lại',
        searchProduct: 'Tìm kiếm sản phẩm',
        searchOrder: 'Tìm kiếm mã đơn hàng',
        productName: 'Tên hàng hóa',
        orderId: 'ID đơn hàng',
        activeDate: 'Ngày kích hoạt',
        enterOrdererName: 'Nhập tên người đặt hàng',
        enterStatus: 'Chọn tình trạng vouchers',
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        createDate: 'Thời gian',
        shopInformation: 'Thông tin cửa hàng',
        userManagement: 'Quản lý người dùng',
        categoryManagement: 'Quản lý danh mục',
        deliverySetting: 'Cài đặt vận chuyển',
        chatSetting: 'Cài đặt chat',
        subCategoryManagement: 'Thiết lập, quản lý danh mục ngành hàng',
        notificationSetting: 'Cài đặt thông báo',
        seeDetail: 'Xem chi tiết',
        prepareOrder: 'Chuẩn bị đơn hàng',
        cancelOrder: 'Hủy đơn hàng',
        printOrder: 'In phiếu giao',
        handOverForDelivery: 'Bàn giao cho ĐVVC',
        confirmOrder: 'Xác nhận đơn hàng',
        confirmDelivery: 'Xác nhận đã giao',
        confirmed: 'Đã xác nhận',
        waitForShippingUnit: 'Chờ bàn giao cho ĐVVC',
        deliveryFail: 'Giao không thành công',
        deliverySuccess: 'Giao thành công',
        shopCancel: 'Shop hủy',
        userCancel: 'Người mua hủy',
        process: 'Đã xử lý',
        unprocess: 'Chưa xử lý',
        preparingOrder: 'Đang chuẩn bị hàng',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công',
        accept: 'Đồng ý',
        reject: 'Từ chối',
        vouchersList: 'Danh sách khuyến mãi'
    },
    en: {
        shippingUnitList: 'Shipping Unit List',
        allStatus: 'All Status',
        flashDealSetting: 'Flash Sales Setting',
        basicInfo: 'Basic Information',
        startDate: 'Start Date',
        endDate: 'End Date',
        addNew: 'Add New',
        noProduct: 'No Product',
        date: 'Date',
        timeStart: 'Start Time',
        allSelected: 'All Products Selected',
        timeEnd: 'End Time',
        minStock: 'Minimum Stock',
        maxStock: 'Maximum Stock',
        category: 'Category',
        delete: 'Delete',
        deleteFDtitle: 'Delete Flash Deal',
        delteFdConfirm: 'Are you sure you want to delete this flash deal?',
        enterCategory: 'Select Category',
        storage: 'Storage',
        edit: 'Edit',
        limitOrder: 'Limit Order',
        limit: 'Limit',
        noLimit: 'No Limit',
        listProduct: 'Product List',
        product: 'Product',
        classified: 'Classified',
        sold: 'Sold',
        price: 'Unit Price (VND)',
        discountPrice: 'Discounted Price',
        promotion: 'Promotion',
        ammountPromotion: 'Promotion Quantity',
        orderNumber: 'Order Number',
        shippingUnit: 'Shipping Unit',
        transportForm: 'Transportation Form',
        activeLabel: 'Active',
        rowOnPage: 'Rows Per Page',
        createNew: 'Create New',
        fastShip: 'Fast Shipping',
        normalShip: 'Normal Shipping',
        ultimateShip: 'Express Shipping',
        selfShip: 'Self-Shipping',
        description: 'Description',
        close: 'Close',
        add: 'Add',
        unsuccessfullyDelete: 'Delete Unsuccessful',
        deleteNotification:
            'There are currently products using this shipping unit. Please change to a different shipping unit before deleting.',
        homepage: 'Homepage',
        storeManagement: 'Store Management',
        productManagement: 'Product Management',
        allProducts: 'All Products',
        addProduct: 'Add Product',
        editProduct: 'Edit Product',
        orderManagement: 'Order Management',
        orderDetail: 'Order Detail',
        order: 'Order',
        addOrder: 'Add Order',
        totalPrice: 'Total Price',
        dateOfOrder: 'Order Date',
        delivery: 'Delivery',
        status: 'Status',
        marketing: 'Marketing',
        financial: 'Financial',
        statisticData: 'Statistical Data',
        customerService: 'Customer Service',
        design: 'Design',
        designBanner: 'Design Banner Image',
        designCategory: 'Design Category Image',
        shopSetting: 'Shop Setting',
        help: 'Help',
        productList: 'Product List',
        all: 'All',
        onSold: 'On Sale',
        outOfStock: 'Out of Stock',
        hidden: 'Hidden',
        draft: 'Draft',
        needConfirm: 'Awaiting Confirmation',
        waitGetOrder: 'Waiting to Pick Up',
        delivering: 'Delivering',
        delivered: 'Delivered',
        canceled: 'Canceled',
        cancel: 'Cancel',
        update: 'Update',
        refund: 'Refund',
        other: 'Other',
        multipleAddingTool: 'Multiple Adding Tool',
        SKUtype: 'SKU Classification',
        classify: 'Classify',
        action: 'Action',
        rowsPerPage: 'Rows Per Page',
        updateProduct: 'Update Product',
        deleteProduct: 'Delete Product',
        hideProduct: 'Hide Product',
        noDataFound: 'No Data Found',
        skuProduct: 'Product SKU:',
        readMore: 'Read More',
        readLess: 'Read Less',
        selectAll: 'Select All',
        show: 'Show',
        search: 'Search',
        reInput: 'Re-input',
        searchProduct: 'Search Product',
        searchOrder: 'Search Order ID',
        productName: 'Product Name',
        orderId: 'Order ID',
        activeDate: 'Activation Date',
        enterOrdererName: 'Enter Orderer Name',
        enterStatus: 'Select Vouchers Status',
        fromDate: 'From Date',
        toDate: 'To Date',
        createDate: 'Creation Date',
        shopInformation: 'Store Information',
        userManagement: 'User Management',
        categoryManagement: 'Category Management',
        deliverySetting: 'Delivery Setting',
        chatSetting: 'Chat Setting',
        subCategoryManagement: 'Subcategory Management',
        notificationSetting: 'Notification Setting',
        seeDetail: 'See Detail',
        prepareOrder: 'Prepare Order',
        cancelOrder: 'Cancel Order',
        printOrder: 'Print Delivery Note',
        handOverForDelivery: 'Hand Over for Delivery',
        confirmOrder: 'Confirm Order',
        confirmDelivery: 'Confirm Delivery',
        confirmed: 'Confirmed',
        waitForShippingUnit: 'Awaiting Shipping Unit',
        deliveryFail: 'Delivery Failed',
        deliverySuccess: 'Delivery Successful',
        shopCancel: 'Shop Canceled',
        userCancel: 'Buyer Canceled',
        process: 'Processed',
        unprocess: 'Unprocessed',
        preparingOrder: 'Preparing Order',
        actionFail: 'Action Failed',
        actionSuccess: 'Action Successful',
        accept: 'Accept',
        reject: 'Reject',
        vouchersList: 'Vouchers List'
    }
};

export default i18nLabels;
