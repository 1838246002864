import React from 'react';
import FilterBar from './subViews/FilterBar';
import ViewOrderList from './subViews/ViewOrderList';

const ViewOrdersMainView = (props) => {
    const {viewOrderListProps, filterBarProps} = props;
    return (
        <div className='view-order'>
            <FilterBar {...filterBarProps} />
            <ViewOrderList {...viewOrderListProps} />
        </div>
    );
};

export default ViewOrdersMainView;
