import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import ShopReportCard from '../ShopReportCard';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ShopReportSales = (props) => {
    const {intl, salesCardList, selectedCard, handleSelectedSalesCard, compareWith, salesChartOptions, salesChartData} =
        props;

    return (
        <section className='shop-report__sales'>
            <h4 className='shop-report__title'>{intl.messages.salesDataByOrders}</h4>
            <p className='shop-report__subTitle'>{intl.messages.keyIndex}</p>

            <ScrollContainer className='sales-card-list'>
                {salesCardList.map((card, idx) => {
                    const shopReportCardProps = {
                        ...card,
                        label: intl.messages.compareWith + ' ' + compareWith,
                        isCurrency: idx === 0 || idx === 2
                    };

                    return (
                        <div className='card-list--item' key={idx} onClick={() => handleSelectedSalesCard(idx)}>
                            <ShopReportCard {...shopReportCardProps} isSelected={selectedCard === idx} />
                        </div>
                    );
                })}
            </ScrollContainer>

            <div className='sales-chart'>
                <p className='shop-report__subTitle'>{intl.messages.chart}</p>
                <Line options={salesChartOptions} data={salesChartData} />
            </div>
        </section>
    );
};

export default ShopReportSales;
