import axiosClient from './axiosClient';

const shippingUnitApi = {
	getShippingUnits: (params) => {
		const url = '/api/shippingUnit';
		return axiosClient.get(url, {params});
	},
	addShippingUnit: (data) => {
		const url = '/api/shippingUnit';
		return axiosClient.post(url, data);
	},
	editShippingUnit: (data) => {
		const {id} = data;
		const url = `/api/shippingUnit/${id}`;
		return axiosClient.put(url, data);
	},
	deleteShippingUnit: (id) => {
		const url = `/api/shippingUnit/${id}`;
		return axiosClient.delete(url);
	}
};

export default shippingUnitApi;
