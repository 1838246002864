import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { SliderRightArrow } from 'assets/images';
import React, { forwardRef } from 'react';

const AddProductSection = forwardRef((props, ref) => {
    const {
        title,
        subTitle = '',
        className = '',
        children,
        formActivated = false,
        isExpanded = false,
        firstSection = false,
        toggleExpand,
    } = props;

    const isSectionActivated = formActivated || firstSection;
    const isFilledAndExpanded = isSectionActivated && isExpanded;

    return (
        <Accordion className='add-product__section' expanded={isFilledAndExpanded} onChange={toggleExpand} ref={ref}>
            <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                expandIcon={isSectionActivated && <img src={SliderRightArrow} alt='' />}>
                <div className='section-header' aria-expanded={isFilledAndExpanded}>
                    <div>
                        <h4 className={isSectionActivated ? 'title-active' : ''}>{title}</h4>
                        {subTitle && <p className='m-0 mt-1'>{subTitle}</p>}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={`section-content ${className}`}>{children}</AccordionDetails>
        </Accordion>
    );
});

export default AddProductSection;
