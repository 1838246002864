import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './userManagementPropsProvider';
import UserManagementMainView from './template/UserManagementMainView.jsx';
import {
    addNewUser,
    deleteUser,
    getRoles,
    getUsers,
    updateUser,
    handleChangePage,
    handleChangePageSize,
    deleteUserRole,
    deleteRole,
    getPermissions,
    addRole,
    addPermissionsToUser,
    changeUserRole,
    addUserToRole,
    getRoleId,
    updateRole
} from './UserManagementSlice';
import {Toast} from 'utils/toast';
import {useSelector} from 'react-redux';
import {authSelector, userManagementSelector} from 'app/selectors';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import debounce from 'lodash.debounce';
import {calcTotalPage} from 'utils/calcTotalPage';
import {ErrorMark, edit} from 'assets/images';
import {getMyPermissions} from 'features/Auth/authSlice';

const pageSizeRange = [5, 10, 15, 20];
const debounceDelay = 1000;

const UserManagementContainer = (props) => {
    const {dispatch, history} = props;
    const auth = useSelector(authSelector);
    const {id} = auth.userLoginInfo;
    const intl = useIntl();
    //Tab
    const [tabValue, setTabValue] = useState(0);
    //ACCOUNT-------START
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [statusFilterData, setStatusFilterData] = useState('');
    const [searchFilterData, setSearchFilterData] = useState('');
    const [roleFilterData, setRoleFilterData] = useState('');
    const userManagement = useSelector(userManagementSelector);
    const {paginationData, permissionsData, roleDetail} = userManagement;
    const [totalPage, setTotalPage] = useState(0);
    const [modalContent, setModalContent] = useState({});
    const [userParams, setUserParams] = useState({
        Filters: '',
        Page: 1,
        PageSize: 10,
        role: ''
    });
    //Account - Modals
    const [isOpenModal, setOpenModal] = useState(false);
    const [isAddNewModal, setAddNewModal] = useState(false);
    const [userEdit, setUserEdit] = useState(null);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState('');
    //ACCOUNT-------END
    //CONFIGURATION
    const defaultRoleInputs = [
        {
            label: intl.messages.roleName,
            placeholder: intl.messages.enterRoleName,
            isRequired: true,
            value: '',
            name: 'name'
        },
        {
            label: intl.messages.description,
            placeholder: intl.messages.enterDescription,
            value: '',
            name: 'description'
        }
    ];
    const [isOpenUserRoleList, setIsOpenUserRoleList] = useState(false);
    const [isOpenAddUserRoleModal, setIsOpenAddUserRoleModal] = useState(false);
    const [isOpenRoleModal, setIsOpenRoleModal] = useState(false);
    const [isAddRoleModal, setIsAddRoleModal] = useState(true);
    const [roleInputs, setRoleInputs] = useState(defaultRoleInputs);
    const [searchRoleValue, setSearchRoleValue] = useState('');
    const [debounceSearchRoleValue, setDebounceSearchRoleValue] = useState('');
    const [checkedRoleData, setCheckedRoleData] = useState({});
    const [permissionTableData, setPermissionTableData] = useState({});
    const [openRoleId, setOpenRoleId] = useState({});
    const [searchUserRoleValue, setSearchUserRoleValue] = useState('');
    const [searchUserRoleResult, setSearchUserRoleResult] = useState([]);
    const handleSearchUserRoleValue = (value) => {
        setSearchUserRoleValue(value);
        debounceSearch(value);
    };

    //CONFIGURATION-------END

    const navigateTo = (path) => {
        history.push(path);
    };

    //Tab
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const setPage = (value) => {
        dispatch(handleChangePage({activePage: tabValue, page: value}));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({activePage: tabValue, pageSize: value}));
    };

    //Account Tab
    const getUserList = async () => {
        const response = await dispatch(getUsers(userParams));
        const {payload} = response;
        const {success, data} = payload;
        if (success) {
            const {collection, total, pagesize} = data;
            setUserList(collection);
            setTotalPage(calcTotalPage(total, pagesize));
        }
    };
    const getRoleList = async () => {
        const response = await dispatch(getRoles());
        const {payload} = response;
        const {success, data} = payload;
        if (success) {
            const newRoleList = [];
            data.collection.forEach((el) => {
                newRoleList.push({
                    ...el,
                    checked: false
                });
            });
            setRoleList(newRoleList);
        }
    };
    const handleChangeStatus = (value) => {
        let statusFilterDataStr = '';
        if (value === 'ACTIVE') {
            statusFilterDataStr = 'disabled==false';
        } else if (value === 'UN_ACTIVE') {
            statusFilterDataStr = 'disabled==true';
        } else {
            statusFilterDataStr = '';
        }
        setStatusFilterData(statusFilterDataStr);
    };
    const handleSearch = (value) => {
        let searchFilterDataStr = '';
        if (value) {
            searchFilterDataStr = `full_name|email|phone@=*${value}`;
        }
        setSearchFilterData(searchFilterDataStr);
    };
    const handleOpenModal = (isAddNew) => {
        setAddNewModal(isAddNew);
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleSubmitAddNewForm = async (value) => {
        const {disabled, email, role, full_name, isSendToMail, password, phone} = value;
        const body = {
            full_name: full_name,
            email: email,
            password: password,
            phone: phone,
            disabled: disabled
        };

        if (isAddNewModal) {
            const response = await dispatch(addNewUser({roleId: role, isSendToMail: isSendToMail, body: body}));
            if (response) {
                const {message, success} = response.payload;
                Toast(success, message);
                if (success) {
                    getUserList();
                }
            } else {
                Toast(false, ' ');
            }
        } else {
            //Update user data
            const {id, extend_role_user} = userEdit;
            const response = await dispatch(updateUser({id: id, body: body})).unwrap();
            const {message, success} = response;
            Toast(success, message);

            if (extend_role_user.length) {
                //Update user role
                const data = {
                    userID: id,
                    roleID: extend_role_user[0].role_id,
                    newRoleID: role
                };
                const res = await dispatch(changeUserRole(data)).unwrap();
                const {success} = res;
                if (!success) {
                    Toast(res.success, res.message);
                }
            } else {
                //Add new role for user
                const data = {
                    user_id: id,
                    role_id: role
                };
                const res = await dispatch(addUserToRole(data)).unwrap();
                const {success} = res;
                if (!success) {
                    Toast(res.success, res.message);
                }
            }

            if (success) {
                getUserList();
            }
        }
        setOpenModal(false);
    };
    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(intl.messages.confirm);
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal('');
    };
    const handleConfirmDeleteAccount = async () => {
        const {id} = userEdit;
        const response = await dispatch(deleteUser({id: id}));
        const {data, success} = response.payload;
        Toast(success, 'Không xóa được tài khoản, vì có ràng buộc Vai trò');
        if (success) {
            getUserList();
        }
        setOpenDeleteModal('');
    };

    // Configuration

    const handleChecked = ({type, payload, value}) => {
        const newCheckedRoleData = {...checkedRoleData};
        if (type === 'item') {
            const isAdmin = permissionsData[0].id === payload;

            if (isAdmin) {
                permissionsData?.forEach((data) => {
                    Object.assign(newCheckedRoleData, {[data.id]: value});
                });
            } else {
                Object.assign(newCheckedRoleData, {[payload]: value});
            }
        } else {
            permissionsData?.forEach((data) => {
                if (data.group_name === payload) {
                    Object.assign(newCheckedRoleData, {[data.id]: value});
                }
            });
        }

        setCheckedRoleData(newCheckedRoleData);
    };

    const handleChangeRoleInput = (name, value) => {
        const newRoleInputs = roleInputs.map((input) => {
            if (input.name === name) {
                input.value = value;
            }

            return input;
        });

        setRoleInputs(newRoleInputs);
    };
    const handleSearchRole = (e) => {
        const value = e.target.value;
        setSearchRoleValue(value);
        HandleDebounceSearchRoleValue(value);
    };
    const HandleDebounceSearchRoleValue = useCallback(
        debounce((value) => {
            setDebounceSearchRoleValue(value);
        }, debounceDelay),
        []
    );

    const openUserRoleModal = async (id) => {
        await handleUpdateRoleList(id);
        setOpenRoleId({id});
        setIsOpenUserRoleList(true);
    };

    const closeUserRoleModal = () => {
        setIsOpenUserRoleList(false);
    };

    const openAddUserRoleModal = (role) => {
        setIsOpenAddUserRoleModal(true);
        setOpenRoleId(role);
    };

    const closeAddUserRoleModal = () => {
        setIsOpenAddUserRoleModal(false);
        setOpenRoleId({});
    };

    const openRoleModal = async (isAddNew = true, id = '') => {
        if (!isAddNew) {
            await handleUpdateRoleList(id);
            setOpenRoleId({id});
        }

        setIsAddRoleModal(isAddNew);
        setIsOpenRoleModal(true);
    };

    const closeRoleModal = () => {
        setIsOpenRoleModal(false);
        setCheckedRoleData({});
        setRoleInputs(defaultRoleInputs);
    };

    const handleUpdateRoleList = async (id) => {
        await dispatch(getRoleId(id ? id : openRoleId.id));
    };

    const handleConfirmRoleModal = async () => {
        const permissions = Object.keys(checkedRoleData).filter((key) => checkedRoleData[key]);
        const data = {};
        roleInputs.forEach((input) => {
            Object.assign(data, {[input.name]: input.value});
        });

        try {
            if (!isAddRoleModal) {
                const updateRoleRes = await dispatch(updateRole({id: openRoleId.id, data})).unwrap();
                const {success, data: updateRoleData} = updateRoleRes;

                if (success) {
                    if (permissions.length > 0) {
                        await dispatch(addPermissionsToUser({id: updateRoleData.id, permissions}));
                    }
                }
            } else {
                const addRoleRes = await dispatch(addRole(data)).unwrap();
                const {success, data: addRoleData} = addRoleRes;

                if (success) {
                    if (permissions.length > 0) {
                        await dispatch(addPermissionsToUser({id: addRoleData.id, permissions}));
                    }
                }
            }

            await getRoleList();
            closeRoleModal();
            Toast(true, intl.messages.actionSuccess);
        } catch (e) {
            Toast(false, intl.messages.errorHappen);
        }
    };

    const handleConfigurationAction = ({type, payload}) => {
        const {userId, name, privilege, roleId} = payload;
        const modalPopupState = {
            title: intl.messages.confirm,
            closeText: intl.messages.cancel,
            confirmText: intl.messages.delete,
            width: 'sm',
            setModalContent
        };
        const boldifyText = (text) => <span className='fw-500'>{text}</span>;

        if (type === 'deleteUserRole') {
            Object.assign(modalPopupState, {
                content: (
                    <div className='d-flex justify-content-center align-items-center gap-2 modal-delete'>
                        <span>
                            {intl.messages.confirmDelete} "{boldifyText(name)}" {intl.messages.outOfPrivilege} "
                            {boldifyText(privilege)}"?
                        </span>
                    </div>
                ),
                onConfirm: async () => {
                    const deleteRes = await dispatch(deleteUserRole({userId, roleId})).unwrap();
                    await handleUpdateRoleList();
                    await getRoleList();

                    const {success} = deleteRes;

                    if (success) {
                        Toast(success, intl.messages.deleteSuccess);
                    } else {
                        Toast(success, intl.messages.deleteFailed);
                    }
                }
            });
        } else if (type === 'deleteRole') {
            Object.assign(modalPopupState, {
                content: (
                    <div className='d-flex justify-content-center align-items-center gap-2 modal-delete'>
                        <span>
                            {intl.messages.confirmDeletePrivilege} "{boldifyText(privilege)}"?
                        </span>
                    </div>
                ),
                onConfirm: async () => {
                    const deleteRes = await dispatch(deleteRole(roleId)).unwrap();
                    await getRoleList();
                    const {success} = deleteRes;

                    if (success) {
                        Toast(success, intl.messages.deleteSuccess);
                    } else {
                        Toast(success, intl.messages.deleteFailed);
                    }
                }
            });
        }

        setModalContent(modalPopupState);
    };

    const handleDebounceSearchValue = async (value) => {
        if (value.length > 2) {
            const params = {
                Filters: `full_name|email@=*${value}`,
                Page: 1,
                PageSize: 10
            };
            const dataSearchRes = await dispatch(getUsers(params)).unwrap();
            const {success, data} = dataSearchRes;

            if (success) {
                const {collection} = data;
                const newSearchResult = collection.map((result) => {
                    const {full_name, email, phone, extend_role_user, id} = result;
                    const displayName = full_name || email || phone;
                    let value = displayName;

                    if (extend_role_user.length > 0) {
                        const {role_id, role_name} = extend_role_user[0];
                        const isAlreadyAdded = role_id === openRoleId.id;

                        if (!isAlreadyAdded) {
                            value = (
                                <span>
                                    {displayName} ({intl.messages.currentRole} "
                                    <span className='fw-500'>{role_name}</span>")
                                </span>
                            );
                        }

                        return {
                            value,
                            isAlreadyAdded,
                            roleId: role_id,
                            userId: id,
                            username: displayName,
                            currentRole: role_name,
                            newRole: openRoleId.name,
                            isHavingRole: role_name.length > 0
                        };
                    }

                    return {
                        value,
                        isAlreadyAdded: false,
                        roleId: null,
                        userId: id,
                        username: displayName,
                        currentRole: '',
                        newRole: openRoleId.name,
                        isHavingRole: false
                    };
                });

                setSearchUserRoleResult(newSearchResult);
            }
        }
    };

    const handleAddUserToRole = async ({isHavingRole, roleId, userId, username, currentRole, newRole}) => {
        try {
            if (isHavingRole) {
                const boldifyText = (text) => <span className='fw-500'>{text}</span>;
                const data = {
                    userID: userId,
                    roleID: roleId,
                    newRoleID: openRoleId.id
                };
                const modalPopupState = {
                    title: intl.messages.adjustRole,
                    closeText: intl.messages.cancel,
                    confirmText: intl.messages.confirm,
                    isDelete: false,
                    width: 'sm',
                    onClose: () => {
                        setTimeout(() => setModalContent({}), 100);
                    },
                    onConfirm: async () => {
                        const res = await dispatch(changeUserRole(data)).unwrap();
                        const {success, message} = res;
                        Toast(success, message);

                        if (success) {
                            handleDebounceSearchValue(searchUserRoleValue);
                            await handleUpdateRoleList();
                            await getRoleList();

                            if (userId === id) {
                                dispatch(getMyPermissions());
                            }
                        }
                    },
                    content: (
                        <span className='d-flex align-items-center gap-2'>
                            <ErrorMark className='svg-info' />

                            <span className='d-flex flex-column'>
                                <span>
                                    {intl.messages.user} "{boldifyText(username)}" {intl.messages.currentRole} "
                                    {boldifyText(currentRole)}"
                                </span>
                                <span>
                                    {intl.messages.changeRoleConfirm} "{boldifyText(newRole)}"
                                </span>
                            </span>
                        </span>
                    )
                };

                setModalContent(modalPopupState);
            } else {
                const data = {
                    id: userId,
                    user_id: userId,
                    role_id: openRoleId.id
                };
                const res = await dispatch(addUserToRole(data)).unwrap();
                const {success} = res;

                if (success) {
                    Toast(success, intl.messages.addSuccess);
                    handleDebounceSearchValue(searchUserRoleValue);
                    await handleUpdateRoleList();
                    await getRoleList();

                    if (userId === id) {
                        dispatch(getMyPermissions());
                    }
                }
            }
        } catch (e) {
            Toast(false, intl.messages.errorHappen);
        }
    };

    const debounceSearch = useCallback(debounce(handleDebounceSearchValue, debounceDelay), [openRoleId]);

    useEffect(() => {
        if (!isAddRoleModal) {
            const {name, description, extend_permission} = roleDetail;
            const permissions = {};

            const newRoleInputs = roleInputs.map((input) => {
                if (input.name === 'name') input.value = name;
                else input.value = description;
                return input;
            });
            extend_permission.forEach((per) => {
                Object.assign(permissions, {[per.id]: true});
            });

            setRoleInputs(newRoleInputs);
            setCheckedRoleData(permissions);
        }
    }, [roleDetail, isAddRoleModal]);

    useEffect(() => {
        if (!roleList.length) {
            getRoleList();
        }
        getUserList();
    }, [userParams]);

    useEffect(() => {
        dispatch(getPermissions());
    }, []);

    useLayoutEffect(() => {
        let isHaveChecked = roleList.some((el) => el.checked === true);
        let roleFilterDataStr = '';
        if (isHaveChecked) {
            let roleNameList = [];
            roleList
                .filter((el) => el.checked === true)
                .forEach((el) => {
                    roleNameList.push(el.name);
                });
            if (roleNameList.length) {
                roleFilterDataStr = `${roleNameList.join(',')}`;
            }
        }
        setRoleFilterData(roleFilterDataStr);
    }, [roleList]);

    useLayoutEffect(() => {
        const newUserParams = {...userParams};
        newUserParams.PageSize = paginationData[tabValue].pageSize;
        newUserParams.Page = paginationData[tabValue].page;
        setUserParams(newUserParams);
    }, [paginationData]);

    useLayoutEffect(() => {
        const newUserParams = {...userParams};
        newUserParams.Filters = [searchFilterData, statusFilterData].join(',');
        newUserParams.role = roleFilterData;
        setUserParams(newUserParams);
    }, [statusFilterData, searchFilterData, roleFilterData]);

    useLayoutEffect(() => {
        const tempPermissionData = {};

        permissionsData
            ?.filter((permission) => permission.name?.toLowerCase().includes(debounceSearchRoleValue.toLowerCase()))
            .forEach((permission) => {
                const {group_name, id, name, description} = permission;
                const isAdmin = group_name === '';
                const isCheckboxChecked =
                    (isAdmin &&
                        Object.values(checkedRoleData).filter((value) => value).length === permissionsData.length) ||
                    (!isAdmin && checkedRoleData[id]) ||
                    false;

                const displayPermission = {
                    order: 1,
                    name,
                    description,
                    checkbox: (
                        <CustomCheckbox
                            checked={isCheckboxChecked}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(e) => handleChecked({type: 'item', payload: id, value: e.target.checked})}
                        />
                    ),
                    id
                };
                if (tempPermissionData.hasOwnProperty(group_name)) {
                    displayPermission.order = tempPermissionData[group_name].length + 1;
                    tempPermissionData[group_name].push(displayPermission);
                } else {
                    if (group_name === '') {
                        Object.assign(tempPermissionData, {
                            [permission.name]: [displayPermission]
                        });
                    } else {
                        Object.assign(tempPermissionData, {
                            [group_name]: [displayPermission]
                        });
                    }
                }
            });

        setPermissionTableData(tempPermissionData);
    }, [permissionsData, checkedRoleData, debounceSearchRoleValue]);

    const userManagementContainerProps = {
        intl,
        dispatch,
        navigateTo,
        tabValue,
        userList,
        roleList,
        isOpenModal,
        isAddNewModal,
        userEdit,
        isOpenDeleteModal,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        setUserEdit,
        handleTabChange,
        handleChangePageSize,
        handleChangeStatus,
        handleSearch,
        setRoleList,
        handleOpenModal,
        handleCloseModal,
        handleSubmitAddNewForm,
        handleConfirmDeleteAccount,
        setPage,
        setPageSize,
        totalPage,
        pageSizeRange,
        userManagement,
        openUserRoleModal,
        closeUserRoleModal,
        isOpenUserRoleList,
        modalContent,
        handleConfigurationAction,
        isOpenAddUserRoleModal,
        openAddUserRoleModal,
        closeAddUserRoleModal,
        openRoleModal,
        closeRoleModal,
        isOpenRoleModal,
        isAddRoleModal,
        handleConfirmRoleModal,
        roleInputs,
        handleChangeRoleInput,
        searchRoleValue,
        handleSearchRole,
        handleChecked,
        checkedRoleData,
        permissionTableData,
        openRoleId,
        handleAddUserToRole,
        setSearchUserRoleValue,
        searchUserRoleResult,
        setSearchUserRoleResult,
        handleSearchUserRoleValue,
        searchUserRoleValue
    };

    return <UserManagementMainView {...propsProvider(userManagementContainerProps)} />;
};

export default UserManagementContainer;
