import ReviewFormModal from '../../MyPurchase/template/subViews/ReviewFormModal';
import CancelReasonModal from './subViews/CancelReasonModal';
import OrderInformation from './subViews/OrderInformation';
import PaymentMethod from './subViews/PaymentMethod';
import ProductList from './subViews/ProductList';
import TransportInformation from './subViews/TransportInformation';
import YourBill from './subViews/YourBill';

export default function PaymentMainView(props) {
    const {
        orderInformationProps,
        transportInformationProps,
        paymentMethodProps,
        productListProps,
        yourBillProps,
        cancelReasonModalProps,
        reviewFormModalProps
    } = props;

    return (
        <>
            <CancelReasonModal {...cancelReasonModalProps} />
            <ReviewFormModal {...reviewFormModalProps} />
            <div className='payment-wrapper'>
                <div className='payment-wrapper__left-side'>
                    <OrderInformation {...orderInformationProps} />
                    <TransportInformation {...transportInformationProps} />
                    <ProductList {...productListProps} />
                </div>
                <div className='payment-wrapper__right-side'>
                    <PaymentMethod {...paymentMethodProps} />
                    <YourBill {...yourBillProps} />
                </div>
            </div>
        </>
    );
}
