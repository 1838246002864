import React from 'react';
import { DownArrow } from 'assets/images';
import { pagePath } from 'configuration/routeConfig';
import { useHistory } from 'react-router-dom';

const BreadCrumb = (props) => {
    const history = useHistory();

    const navigateTo = (path) => {
        history.push(path);
    };

    return (
        <div className='payment-bread-crumb d-flex align-items-center' style={{ fontSize: 14 }}>
            <span style={{ color: '#808080', cursor: 'pointer' }} onClick={() => navigateTo(pagePath.cartUrl)}>
                GIỎ HÀNG &nbsp;
            </span>
            <img
                src={DownArrow}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain',
                    filter: 'invert(93%) sepia(6%) saturate(289%) hue-rotate(171deg) brightness(90%) contrast(82%)',
                    transform: 'rotate(-90deg)'
                }}
                alt='right-arrow'
            />
            <span>&nbsp; THANH TOÁN</span>
        </div>
    );
};

export default BreadCrumb;
