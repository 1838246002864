import React from 'react';
import i18nLabels from './chatBox.i18n';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch} from 'react-redux';
import ChatBoxContainer from './ChatBoxContainer';
import {useHistory} from 'react-router-dom';

export default function ChatBox(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const chatBoxProps = {
        ...props,
        i18nLabels,
        history,
        dispatch
    };

    return <DecorateContainer {...chatBoxProps} childComponent={ChatBoxContainer} />;
}
