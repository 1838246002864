import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { forwardRef } from 'react';
import AddProductSection from '../AddProductSection/AddProductSection';
import AdvanceForm from './AdvanceForm';
import BasicForm from './BasicForm';


const SalesInformationSection = forwardRef((props, ref) => {
    const {
        intl,
        salesInformationType,
        handleRadioSalesChange,
        basicFormProps,
        advancedFormProps,
        formActivated,
        isExpanded,
        toggleExpand,
    } = props;

    const addProductSectionProps = {
        title: intl.messages.progressOrderInformation,
        className: 'sales-information',
        formActivated,
        isExpanded,
        toggleExpand,
    };

    return (
        <AddProductSection {...addProductSectionProps} ref={ref}>
            <RadioGroup
                row
                name='controlled-sales-information'
                value={salesInformationType}
                onChange={handleRadioSalesChange}>
                <FormControlLabel value={'basic'} control={<Radio disableRipple />} label={intl.messages.basic} />
                <FormControlLabel value={'advance'} control={<Radio disableRipple />} label={intl.messages.advance} />
            </RadioGroup>

            {salesInformationType === 'basic' ? (
                <BasicForm {...basicFormProps} />
            ) : (
                <AdvanceForm {...advancedFormProps} />
            )}
        </AddProductSection>
    );
});

export default SalesInformationSection;
