export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
*/
    const {
        intl,
        dispatch,
        navigateTo,
        firestore,
        userLoginInfo,
        isAdmin,
        chatRooms,
        fetchChatRoomList,
        searchValue,
        setSearchValue,
        handleSearch,
        chatBoxParams,
        handleFilter,
        handleMarkAsRead,
        handlePinChat,
        unreadCount
    } = props;

    return {
        intl,
        dispatch,
        navigateTo,
        unreadCount,
        chatBoxContainerProps: {
            intl,
            navigateTo,
            dispatch,
            firestore,
            userLoginInfo,
            chatRooms,
            fetchChatRoomList,
            searchValue,
            setSearchValue,
            handleSearch,
            chatBoxParams,
            handleFilter,
            handleMarkAsRead,
            handlePinChat,
            isAdmin,
        }
    };
}
