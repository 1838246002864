import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import einvoiceApi from 'api/einvoiceApi';
import orderApi from 'api/orderApi';
import productReviewApi from 'api/productReviewApi';
import serviceRequest from 'app/serviceRequest';
import Config from 'configuration';

const initialState = () => {
    const myOrder = sessionStorage.getItem(Config.storageKey.myOrder);
    if (myOrder) {
        return {...JSON.parse(myOrder)};
    }

    return {
        myOrderList: {},
        activePurchaseListTab: 0
    };
};

export const getMyOrders = createAsyncThunk('myPurchase/getMyOrders', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getMyOrders,
        payload: data
    });
});

export const postProductReview = createAsyncThunk('myPurchase/postProductReview', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productReviewApi.postProductReview,
        payload: data
    });
});

export const postProductReviewAttachment = createAsyncThunk(
    'myPurchase/postProductReviewAttachment',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: productReviewApi.postProductReviewAttachment,
            payload: data
        });
    }
);

export const uploadProductReviewAttachment = createAsyncThunk(
    'myPurchase/uploadProductReviewAttachment',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: productReviewApi.uploadProductReviewAttachment,
            payload: data
        });
    }
);

export const getProductReviewByIdAndOrderProductId = createAsyncThunk(
    'myPurchase/getProductReviewByIdAndOrderProductId',
    async (params, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: productReviewApi.getProductReviewByIdAndOrderProductId,
            payload: params
        });
    }
);

export const getInvoice = createAsyncThunk('myPurchase/getInvoice', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: einvoiceApi.getInvoice,
        payload: params
    });
});

export const publishInvoice = createAsyncThunk('myPurchase/publishInvoice', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: einvoiceApi.publishInvoice,
        payload: params
    });
});

export const sendEmail = createAsyncThunk('myPurchase/sendEmail', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: einvoiceApi.sendEmail,
        payload: params
    });
});

const myPurchase = createSlice({
    name: 'myPurchase',
    initialState,
    reducers: {
        changeActiveTab: (state, action) => {
            state.activePurchaseListTab = action.payload;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getMyOrders.fulfilled, (state, action) => {
            state.myOrderList = action.payload?.data;
            sessionStorage.setItem(Config.storageKey.myOrder, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = myPurchase;
export const {changeActiveTab} = actions;
export default reducer;
