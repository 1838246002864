import {calcTotalPage} from 'utils/calcTotalPage';

export default function propsProvider(props) {
    const {navigateTo, intl, notification, handlePageSizeChange, handlePageChange, handleReadAll, history, page} =
        props;

    const {notificationDetails} = notification;
    const {collection = [], pagesize, total} = notificationDetails;
    const pageSizeRange = [5, 10, 15, 20];

    return {
        navigateTo,
        intl,
        notifications: collection,
        notificationListProps: {
            intl,
            notifications: collection,
            pageSizeRange,
            totalPage: calcTotalPage(total, pagesize),
            currentPage: page,
            setPage: handlePageChange,
            setPageSize: handlePageSizeChange,
            pageSize: pagesize,
            handleReadAll,
            history
        }
    };
}
