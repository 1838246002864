import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import CustomButton from 'components/CustomButton/CustomButton';
import './orderInformation.scss';
import {DeleteFile, Favorite, ReturnRefund, ShoppingBag, Tick} from 'assets/images';
import {orderActions} from 'features/UserProfile/template/subViews/MyPurchase/MyPurchaseContainer';

const OrderInformation = (props) => {
    const {intl, orderInfo, currentStep, handleOrderStep, cancelDescription, products, isOrderReviewed, isMobile} =
        props;

    const orderSteps = [
        {
            name: 'Chờ xác nhận',
            code: 'Unconfirm',
            actions: [
                {
                    key: 'cancel',
                    text: intl.messages.cancelText,
                    icon: <DeleteFile className='cancel-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.cancel
                }
            ]
        },
        {
            name: 'Đã giao',
            code: 'Delivered',
            actions: [
                {
                    key: 'return',
                    text: intl.messages.returnRefund,
                    icon: <ReturnRefund />,
                    action: handleOrderStep,
                    actionType: orderActions.refund
                },
                {
                    key: 'confirmReceived',
                    text: intl.messages.receivedConfirm,
                    icon: <Tick className='tick-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.confirmReceived
                }
            ]
        },
        {
            name: 'Hoàn thành',
            code: 'Done',
            actions: [
                {
                    key: !isOrderReviewed ? 'review' : 'reviewDetail',
                    text: !isOrderReviewed ? intl.messages.review : intl.messages.reviewDetail,
                    icon: <Favorite />,
                    action: handleOrderStep,
                    actionType: !isOrderReviewed ? orderActions.review : orderActions.reviewDetail
                }
            ]
        },
        {
            name: 'Đã hủy',
            code: 'Cancel',
            actions: [
                {
                    key: 'buyAgain',
                    text: intl.messages.buyAgain,
                    icon: <ShoppingBag className='shopping-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.buyAgain
                }
            ]
        },
        {
            name: 'Hoàn tiền',
            code: 'Refund',
            actions: [
                {
                    key: 'detailReturnRefund',
                    text: intl.messages.detailReturnRefund,
                    action: handleOrderStep,
                    actionType: orderActions.refund
                }
            ]
        }
    ];

    const variantOfButton = {
        return: 'half-contained',
        cancel: 'half-contained',
        confirmReceived: 'contained'
    };

    const foundStepMethod = ({name, code}) => {
        for (let i = 0; i < orderSteps.length; i++) {
            if (orderSteps[i].name === name && name !== 'Chờ lấy hàng') {
                return orderSteps[i];
            } else {
                if (code && orderSteps[i].code === code) return orderSteps[i];
            }
        }
        return null;
    };
    const foundStep = foundStepMethod(currentStep);

    return (
        <div className='payment-transport-information'>
            <div className='d-flex justify-content-between align-items-center'>
                <SectionTitle paled={false}>{intl.messages.orderInformation}</SectionTitle>
            </div>

            <div className='row'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.orderCode}</span>
                </div>
                <div className='col-8 col-sm-7'>
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}>
                        #{orderInfo?.orderCode}
                    </span>
                </div>
            </div>
            <div className='row d-flex align-items-center mt-2'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.processStatus}</span>
                </div>
                <div
                    className={`${
                        ['Delivery', 'Delivered'].includes(orderInfo?.statusCode) ? 'col-sm-2' : 'col-sm-5'
                    } col-8 d-flex justify-content-start align-items-center`}>
                    <div
                        className='d-flex justify-content-center align-items-center'
                        style={{
                            width: '100px',
                            height: '36px',
                            fontSize: 13,
                            fontWeight: 500,
                            borderRadius: '4px',
                            color: `${orderInfo?.statusTextColor}`,
                            border: `solid 1px ${orderInfo?.statusTextColor}`,
                            backgroundColor: `${orderInfo?.statusColor}`
                        }}>
                        {orderInfo?.statusName}
                    </div>
                </div>
                <div
                    className={`actions-button ${
                        ['Delivery', 'Delivered'].includes(orderInfo?.statusCode) ? 'col-sm-7' : 'col-sm-4'
                    } d-flex mt-2 justify-content-end align-items-center gap-2`}>
                    {foundStep &&
                        foundStep?.actions &&
                        foundStep?.actions.map((action) => (
                            <CustomButton
                                key={action.key}
                                size='md'
                                fullwidth={isMobile}
                                variant={variantOfButton[action.key]}
                                color={['return', 'cancel'].includes(action.key) ? 'danger' : 'primary'}
                                onClick={() => action.action(action.actionType, {products})}>
                                {action.icon}
                                {action.text}
                            </CustomButton>
                        ))}
                </div>
            </div>
            {orderInfo?.statusCode === 'Cancel' && (
                <div className='row mt-2 d-flex align-items-center'>
                    <div className='col-4 col-sm-3 d-flex flex-column'>
                        <span style={{color: '#808080', fontSize: 15}}>{intl.messages.cancelReasonLabel}</span>
                        <span style={{color: '#808080', fontSize: 10, fontStyle: 'italic'}}>
                            {intl.messages.fromSellerOrBuyer}
                        </span>
                    </div>
                    <div className='col-8 col-sm-9'>
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500
                            }}>
                            {cancelDescription}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderInformation;
