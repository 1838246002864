export default function propsProvider(props) {
    const {
        intl,
        navigateTo,
        myPurchaseListTabs,
        activePurchaseListTab,
        changePurchaseListTab,
        setPage,
        setPageSize,
        myPurchaseList,
        totalPage,
        myPurchaseParams,
        handleOrderStep,
        isOpenReviewModal,
        closeReviewModal,
        handleReviewOrder,
        handleUploadImages,
        reviewValues,
        setReviewValues,
        isViewDetail,
        searchBy,
        setSearchBy,
        searchValue,
        setSearchValue,
        handleSearch,
        isOpenReasonModal,
        closeReasonModal,
        handleCancelOrder,
        reasonValue,
        setReasonValue
    } = props;

    const statusDescriptions = {
        Unconfirm: 'Đơn hàng đang chờ người bán xác nhận',
        PackingPending: 'Đơn hàng đang chờ nhà vận chuyển',
        DeliveryPending: 'Đơn hàng đang chờ nhà vận chuyển',
        Delivery: 'Đơn hàng đang trên đường giao tới bạn',
        Delivered: 'Đơn hàng được người bán xác nhận giao thành công',
        Done: 'Đơn hàng đã được giao thành công',
        Refund: 'Yêu cầu đã gửi',
        SHOP_CANCEL: 'Đã hủy bởi shop',
        USER_CANCEL: 'Đã hủy bởi bạn'
    };

    const myPurchaseListData =
        myPurchaseList &&
        myPurchaseList.map((order) => {
            const {
                order_information,
                extend_order_product,
                total_price,
                user_id,
                is_reviewed,
                invoice_id,
                sub_step_code
            } = order;
            return {
                orderInfo: {
                    orderId: order_information?.order_id,
                    orderCodeNumber: order_information?.order_code,
                    orderStatusCode: order_information?.extend_step?.code,
                    orderStatus: order_information?.extend_step?.name,
                    orderStatusDescription:
                        order_information?.extend_step?.code !== 'Cancel'
                            ? statusDescriptions[order_information?.extend_step?.code.replace('-', '')]
                            : statusDescriptions[sub_step_code],
                    orderTotalPrice: total_price,
                    isOrderReviewed: is_reviewed,
                    invoice_id
                },
                products:
                    extend_order_product &&
                    extend_order_product.map((product) => ({
                        userId: user_id,
                        productId: product.product_id,
                        subTypeId: product.product_sub_type_id,
                        orderProductId: product.order_product_id,
                        productName: product.product_name,
                        productPrice: product.product_price,
                        productQuantity: product.quantity,
                        productImg: product.product_default_image,
                        productDescription: product.product_type_value_text,
                        productCreator: product?.created_by
                    }))
            };
        });

    return {
        intl,
        navigateTo,
        myPurchaseListTabs,
        activePurchaseListTab,
        changePurchaseListTab,
        searchBy,
        setSearchBy,
        searchValue,
        setSearchValue,
        handleSearch,

        myPurchaseListProps: {
            intl,
            navigateTo,
            page: myPurchaseParams.Page,
            pageSize: myPurchaseParams.PageSize,
            setPage,
            setPageSize,
            totalPage,
            myPurchaseList: myPurchaseListData,
            handleOrderStep
        },
        reviewFormModalProps: {
            intl,
            isOpenReviewModal,
            closeReviewModal,
            handleReviewOrder,
            handleUploadImages,
            reviewValues,
            setReviewValues,
            isViewDetail
        },
        reasonModalProps: {
            intl,
            isOpenReasonModal,
            closeReasonModal,
            handleCancelOrder,
            reasonValue,
            setReasonValue
        }
    };
}
