const i18nLabels = {
    vi: {
        shopInformationTitle: 'Thông tin cửa hàng',
        shopInformationSubtitle: 'Xem và cập nhật thông tin cửa hàng',
        shopLogoLable: 'Logo gian hàng',
        formatImage: 'Định dạng tệp PNG, JPEG',
        imageRequirement: 'Kích thước: tối đa 2MB',
        businessRegisNumber: 'Số ĐKKD',
        issueDate: 'Ngày cấp',
        taxCode: 'Mã số thuế',
        phoneNumberLabel: 'Số điện thoại liên hệ',
        emailLabel: 'Email liên hệ',
        addressLabel: 'Địa chỉ liên hệ',
        shopDescriptionLabel: 'Mô tả gian hàng',
        addressTitle: 'Địa chỉ',
        addressSubtitle: 'Quản lí việc vận chuyển và địa chỉ giao hàng của bạn',
        shopAddress: 'Địa chỉ cửa hàng',
        fullnameText: 'Họ và tên',
        phoneText: 'Số điện thoại',
        addressText: 'Địa chỉ',
        warehouseAddress: 'Địa chỉ kho hàng',
        addNewAddressText: 'Thêm mới địa chỉ',
        overSize: 'Tệp vượt quá giới hạn dung lượng cho phép',
        deleteAddress: 'Bạn có chắc chắn xóa địa chỉ này?',
        delete: 'Xóa',
        cancelButton: 'Hủy'
    },
    en: {
        shopInformationTitle: 'Shop Information',
        shopInformationSubtitle: 'View and update shop information',
        shopLogoLable: 'Shop Logo',
        formatImage: 'File format: PNG, JPEG',
        imageRequirement: 'Size: maximum 2MB',
        businessRegisNumber: 'Business Registration Number',
        issueDate: 'Issue Date',
        taxCode: 'Tax Code',
        phoneNumberLabel: 'Contact Phone Number',
        emailLabel: 'Contact Email',
        addressLabel: 'Contact Address',
        shopDescriptionLabel: 'Shop Description',
        addressTitle: 'Address',
        addressSubtitle: 'Manage your shipping and delivery addresses',
        shopAddress: 'Shop Address',
        fullnameText: 'Full Name',
        phoneText: 'Phone Number',
        addressText: 'Address',
        warehouseAddress: 'Warehouse Address',
        addNewAddressText: 'Add New Address',
        overSize: 'File exceeds size limit',
        deleteAddress: 'Are you sure you want to delete this address?',
        delete: 'Delete',
        cancelButton: 'Cancel'
    }
};

export default i18nLabels;
