import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {CloseGray, SearchIcon, Tick} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React, {useEffect} from 'react';

const AddUserRoleModal = (props) => {
    const {
        intl,
        open,
        doClose,
        handleAddUserToRole,
        setSearchUserRoleValue,
        searchUserRoleResult,
        setSearchUserRoleResult,
        handleSearchUserRoleValue,
        searchUserRoleValue
    } = props;

    useEffect(() => {
        if (!open) {
            const timeout = setTimeout(() => {
                setSearchUserRoleValue('');
                setSearchUserRoleResult([]);
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [open]);

    return (
        <Dialog
            maxWidth='md'
            fullWidth={true}
            open={open}
            onClose={doClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className='dialog dialog-user-role'>
            <DialogTitle className='dialog-title' id='alert-dialog-title'>
                <span>{intl.messages.addUser}</span>
                <button className='dialog-title__close'>
                    <CloseGray onClick={doClose} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content'>
                <CustomInput
                    value={searchUserRoleValue}
                    onChange={(e) => handleSearchUserRoleValue(e.target.value)}
                    starticon={<SearchIcon />}
                    placeholder={intl.messages.search}
                />

                {searchUserRoleResult?.length > 0 && (
                    <div className='dialog-content__result'>
                        {searchUserRoleResult.map((result, idx) => {
                            const {
                                value,
                                isAlreadyAdded,
                                roleId,
                                userId,
                                isHavingRole,
                                username,
                                currentRole,
                                newRole
                            } = result;

                            return (
                                <div className='result-data' key={idx}>
                                    <div className='result-data__name'>{value}</div>
                                    {isAlreadyAdded ? (
                                        <div className='d-flex align-items-center gap-2 result-data__added'>
                                            <Tick />
                                            {intl.messages.added}
                                        </div>
                                    ) : (
                                        <div
                                            className='result-data__add'
                                            onClick={() =>
                                                handleAddUserToRole({
                                                    isHavingRole,
                                                    roleId,
                                                    userId,
                                                    username,
                                                    currentRole,
                                                    newRole
                                                })
                                            }>
                                            + {intl.messages.add}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <CustomButton size='md' variant='text' onClick={doClose}>
                    {intl.messages.close}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserRoleModal;
