import {BackArrow, ShieldQuestion, BoyWorkingComputer} from 'assets/images';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import {FormattedNumber} from 'react-intl';
import Config from 'configuration';
import ReviewDetailRefund from './reviewDetailRefund';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const HeaderRefund = (props) => {
    const {intl, detailRefund, navigateTo, orderProducts, handleSelectItem, justSubmitSuccessfully, reviewDetailProps} =
        props;
    const [windowSize] = useDeviceSize();
    const isMobile = windowSize.width < 576;

    return (
        <div className='refund-header'>
            <div className='refund-header_title d-flex align-items-center'>
                <BackArrow alt='back' className='icon-hover d-none d-sm-inline' onClick={navigateTo} />
                <span className='refund-section-title'>{isMobile ? intl.messages.order : intl.messages.return}</span>
            </div>
            {!detailRefund && (
                <div className='refund-header_hint'>
                    <span className='fs-13'>
                        {intl.messages.hintProduct} <ShieldQuestion />
                    </span>
                </div>
            )}
            {detailRefund && (
                //status and title product when refunded
                <div>
                    <span>{intl.messages.tltStatusRefund}</span>
                    <span className='status-detail'>{detailRefund?.order_refund_status?.status_name || '--'}</span>
                </div>
            )}
            {justSubmitSuccessfully ? (
                <div className='refund-lounge'>
                    <BoyWorkingComputer />
                    <div>{intl.messages.loungeRefund}</div>
                </div>
            ) : (
                <div className='refund-header-card mt-3'>
                    {(!detailRefund ? orderProducts : detailRefund.extend_order_product)?.map((item, index) => (
                        <div className='refund-header-card-body' key={index}>
                            <div className='body-detail'>
                                {item.isChecked !== undefined && !detailRefund && (
                                    <CustomCheckbox
                                        disableRipple
                                        color='primary'
                                        key={index}
                                        checked={item.isChecked}
                                        onChange={(e) => handleSelectItem(e, item)}
                                        inputProps={{
                                            'aria-label': 'select all data'
                                        }}
                                    />
                                )}
                                {detailRefund && <div className='ml-4'></div>}
                                <div className='d-flex align-items-start'>
                                    <div className='img-product'>
                                        <img
                                            src={`${Config.apiConfig.endPoint}${item.product_default_image}`}
                                            alt='product'
                                        />
                                    </div>
                                    <div>
                                        <div className='product-name'>{item.product_name}</div>
                                        <div className='body-detail ellipsis'>
                                            <span className='product-sub'>{item.product_type_value_text}</span>
                                        </div>
                                        <div className='body-detail ellipsis'>
                                            <span className='product-sub'>
                                                {intl.messages.quantity}
                                                {item.quantity}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='body-price'>
                                <FormattedNumber style='currency' currency='VND' value={item.product_price} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {detailRefund && <ReviewDetailRefund {...reviewDetailProps} />}
        </div>
    );
};

export default HeaderRefund;
