import FlashDealCard from 'components/FlashDealCard/FlashDealCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import SwiperList from 'components/SwiperList/SwiperList';
import {upDateProductTypes} from 'features/Admin/template/subViews/Marketing/FlashDeal/FlashDealSlice';
import {updateProduct} from 'features/Admin/template/subViews/Product/ViewProducts/ViewProductsSlice';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

const SwiperFlashDeal = (props) => {
    const {flashDealList = [], navigateTo, intl, paleTitle = false} = props;

    const [currentTime, setCurrentTime] = useState(new Date());
    const [displayFlashDealList, setDisplayFlashDealList] = useState([]);
    const dispatch = useDispatch();
    const flagRef = useRef({}); // create a mutable ref object with an initial value of false

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const breakpoints = {
        1200: {
            slidesPerView: 6,
            slidesPerGroup: 6
        },
        992: {
            slidesPerView: 5,
            slidesPerGroup: 5
        },
        576: {
            slidesPerView: 3,
            slidesPerGroup: 3
        }
    };
    const swiperList = displayFlashDealList?.map((item) => {
        return <FlashDealCard item={item} key={item.id} navigateTo={navigateTo} intl={intl} />;
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (flashDealList.length) {
                const list = [];
                const today = moment();
                flashDealList?.forEach((item) => {
                    const endDealTime = moment(item.end_deal);
                    const startDealTime = moment(item.start_deal);
                    if (currentTime > startDealTime && currentTime < endDealTime) {
                        const data = JSON.parse(item.product_list);
                        data.forEach((e) => {
                            if (e.extend_product_type.length) {
                                // console.log("e.extend_product_type", e.extend_product_type )
                                const deal = e.extend_product_type.reduce((prev, curr) => {
                                    return prev.price < curr.price ? prev : curr;
                                });
                                // console.log("deal", deal);
                                const productDate = {
                                    ...deal,
                                    default_image: e.default_image,
                                    product_name: e.name,
                                    product_id: e.id
                                };
                                list.push(productDate);
                            } else {
                                list.push(e);
                                const data = {...e, discount: e.discount_price};
                                if (!flagRef.current[e.id]) {
                                    flagRef.current[e.id] = true; // set the flag to true
                                    dispatch(updateProduct(data)).unwrap();
                                }
                            }
                        });
                        // console.log("list", list);
                    } else if (today.isAfter(endDealTime)) {
                        const data = JSON.parse(item.product_list);
                        data.forEach((e) => {
                            if (e.extend_product_type.length) {
                                e.extend_product_type.forEach((item) => {
                                    const data = {
                                        id: item.id,
                                        body: {
                                            ...item,
                                            product_id: e.id,
                                            quantity: item.sale_quantity,
                                            price: item.origin_price
                                        }
                                    };
                                    if (!flagRef.current[e.id]) {
                                        flagRef.current[e.id] = true; // set the flag to true
                                        dispatch(upDateProductTypes(data)).unwrap();
                                    }
                                });
                            } else {
                                const data = {...e, discount: 0};
                                if (!flagRef.current[e.id]) {
                                    flagRef.current[e.id] = true; // set the flag to true
                                    dispatch(updateProduct(data)).unwrap();
                                }
                            }
                        });
                    }
                });
                setDisplayFlashDealList(list);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [currentTime, dispatch, flashDealList]);

    return (
        <div className='flashdeal'>
            <SectionTitle paled={paleTitle}>{intl.messages.flashDeal}</SectionTitle>
            <div className='flashdeal-list m-0 p-0'>
                <SwiperList
                    list={swiperList}
                    slidesPerView={2}
                    slidesPerGroup={2}
                    rows={1}
                    spaceBetween={16}
                    breakpoints={breakpoints}
                />
            </div>
        </div>
    );
};

export default SwiperFlashDeal;
