import TripleGears from './png/tripleGears.png';
import FemaleWorker from './png/femaleWorker.png';
import GooglePlay from './png/google-play.png';
import LogoFooter from './png/Logo_Footer_desktop.png';
import Avatar from './png/avatar.png';
import AppStore from './png/app-store.png';
import sapatcoLogo from './png/sapatco-logo.png';
import ornament from './png/ornament.png';
import eyes from './png/eyes-outline.png';
import phoneLogo from './png/container.png';
import google from './png/google.png';
import facebook from './png/facebook.png';
import ornament1 from './png/ornament(1).png';
import oval from './png/oval.png';
import ornamentMobile from './png/ornament-mobile.png';
import ovalMobile from './png/oval-mobile.png';
import phoneNumber from './png/phonenumber.png';
import group35 from './png/group-35.png';
import frame2745 from './png/frame-2745.png';
import productImg from './png/product-img.png';
import eyeSee from './png/eye-see.png';
import ornament2x from './png/ornament@2x.png';
import noFeedback from './png/bg-1@2x.png';
import ornament_setPw from './png/ornament-setPw.png';
import ornament_setPw2x from './png/ornament-setPw@2x.png';
import frame2746 from './png/Frame-2746.png';
import HDLogo from './png/logo-haidanggroup.png';
import defaultBuilding from './png/default-building.png';
import SliderLeftArrow from './png/left arrow.png';
import noAddress from './png/noAddress.png';
import plus from './png/Plus.png';
import edit from './png/edit.png';
import dublicate from './png/dublicate.png';
import deleteIcon from './png/delete.png';
import favCart from './png/cart.png';
import HaidangLogo from './png/HaidangLogo.png';
import infoIcon from './png/infoIcon.png';
import copyIcon from './png/content_copy.png';
import noneVoucher from './png/Group.png';

import errorPage from './png/errorPage.png';
import emptyFavProd from './png/empty-fav-prod.png';
import NotiDefault from './png/noti-default.png';

import mail from './svg/mail.svg';
import phone from './svg/phone.svg';
import navigation_map from './svg/navigation_map.svg';
import ChevronRight from './svg/chevron-right.svg';
import Facebook from './svg/facebook.svg';
import Gear from './svg/gear.svg';
import Instagram from './svg/instagram.svg';
import Linkedin from './svg/linkedin.svg';
import Maintenance from './svg/maintenance.svg';
import Twitter from './svg/twitter.svg';
import Youtube from './svg/youtube.svg';
import searchDocIcon from './svg/icon-doc-search.svg';
import TagIcon from './svg/tag.svg';
import TagIcon2 from './svg/tag2.svg';
import {ReactComponent as SearchIcon} from './svg/searchIcon.svg';
import {ReactComponent as SearchBlue} from './svg/SearchBlue.svg';
import {ReactComponent as Cart} from './svg/Cart-Outline.svg';
import {ReactComponent as Approve} from './svg/Approve.svg';
import cartBlue from './svg/Cart-Outline-blue.svg';
import {ReactComponent as Delivery} from './svg/Delivery-Outline.svg';
import {ReactComponent as RightArrow} from './svg/right arrow.svg';
import ratingStar from './svg/rating-star.svg';
import ratingStarInactive from './svg/rating-star-inactive.svg';
import ratingStarOutline from './svg/rating-star-outline.svg';
import filter from './svg/filter_list.svg';
import filterInactive from './svg/filter_list_inactive.svg';
import close from './svg/Close.svg';
import {ReactComponent as CloseCircle} from './svg/CloseCircle.svg';
import LightBulb from './svg/light-bulb.svg';
import DownArrow from './svg/down-arrow.svg';
import ImageIcon from './svg/image.svg';
import CommentIcon from './svg/comment.svg';
import RedHeart from './svg/red-heart.svg';
import GrayHeart from './svg/gray-heart.svg';
import {ReactComponent as ErrorMark} from './svg/Exclamation Mark.svg';
import {ReactComponent as ExclamationMark} from './svg/Exclamation Mark1.svg';
import location from './svg/location.svg';
import likeOutline from './svg/Like-Outline.svg';
import Share from './svg/Share.svg';
import SliderRightArrow from './svg/right arrow.svg';
import otp from './svg/otp.svg';
import UpArrow from './svg/up-arrow.svg';
import emptyCart from './svg/emptyCart.svg';
import addActive from './svg/add-active.svg';
import addDeactive from './svg/add-deactive.svg';
import minusActive from './svg/minus-acitve.svg';
import minusDeactive from './svg/minus-deactive.svg';
import deleteRedBtn from './svg/delete-red-oval.svg';
import deleteGrayBtn from './svg/delete-gray-oval.svg';
import thankYou from './svg/thankYou.svg';
import Reload from './svg/Reload.svg';
import LeftArrow from './svg/left arrow.svg';
import AddAccount from './svg/add account.svg';

import emptyCode from './svg/emptyCode.svg';
import haidangLoader from './gif/loading-spiner.jpg';
import {ReactComponent as Shipper} from './svg/shipper.svg';
import notes from './svg/notes.svg';
import {ReactComponent as Edit} from './svg/Edit.svg';
import EditOutline from './svg/Edit-Outline.svg';
import EditDark from './svg/EditDark.svg';
import {ReactComponent as EditGray} from './svg/EditGray.svg';
import save from './svg/save.svg';
import Cancel from './svg/Cancel.svg';
import couponFrame from './svg/couponFrame.svg';
import {ReactComponent as Trash} from './svg/Trash.svg';
import {ReactComponent as Archive} from './svg/Archive.svg';
import {ReactComponent as Plus} from './svg/Plus.svg';
import ATMCard from './svg/atm-card.svg';
import COD from './svg/COD.svg';
import upperArrow from './svg/upper-arrow.svg';
import moreInformation from './svg/information.svg';
import {ReactComponent as Bell} from './svg/Bell.svg';
import {ReactComponent as Profile} from './svg/profile.svg';
import {ReactComponent as Connection} from './svg/Connection.svg';
import {ReactComponent as Logout} from './svg/Logout.svg';
import EmptyNoti from './svg/emptyNoti.svg';
import {ReactComponent as CardHeart} from './svg/card heart.svg';
import {ReactComponent as DocumentImg} from './svg/document.svg';
import {ReactComponent as Help} from './svg/Help.svg';
import {ReactComponent as MoneyWithdrawal} from './svg/money withdrawal.svg';
import {ReactComponent as Screen} from './svg/screen.svg';
import {ReactComponent as Setting} from './svg/Setting.svg';
import {ReactComponent as ShoppingBag} from './svg/shopping bag.svg';
import {ReactComponent as ShowChart} from './svg/show chart.svg';
import {ReactComponent as Marketing} from './svg/presentation board.svg';
import downArrow from './svg/down arrow.svg';
import {ReactComponent as UpArrowSvg} from './svg/up arrow.svg';
import {ReactComponent as DownArrowSvg} from './svg/down_arrow.svg';
import BreadcrumbArrow from './svg/BreadcrumbArrow.svg';
import WhitePlus from './svg/PlusWhite.svg';
import threeDot from './svg/threeDot.svg';
import VoucherChip from './svg/chips.svg';
import {ReactComponent as VoucherAble} from './svg/voucherAble.svg';
import {ReactComponent as VoucherDisabled} from './svg/voucherDisabled.svg';
import {ReactComponent as VoucherApplied} from './svg/voucherApplied.svg';
import {ReactComponent as Chat} from './svg/chat.svg';
import {ReactComponent as ApproveFile} from './svg/approveFile.svg';
import {ReactComponent as Bill} from './svg/bill.svg';
import {ReactComponent as DeleteFile} from './svg/deleteFile.svg';
import {ReactComponent as CloseGray} from './svg/CloseGray.svg';
import {ReactComponent as InfoMark} from './svg/infoMark.svg';
import {ReactComponent as AddImage} from './svg/add image.svg';
import {ReactComponent as AddVideo} from './svg/camera reel.svg';
import {ReactComponent as Voucher} from './svg/voucher.svg';
import {ReactComponent as UserOutline} from './svg/User-Outline.svg';
import {ReactComponent as Clipboard} from './svg/clipboard.svg';
import {ReactComponent as Repeat} from './svg/repeat.svg';
import {ReactComponent as Parcel} from './svg/Parcel.svg';
import {ReactComponent as ShippingTruck} from './svg/shipping truck.svg';
import {ReactComponent as CameraChange} from './svg/camera change.svg';
import {ReactComponent as Printer} from './svg/printer.svg';
import {ReactComponent as AddUser} from './svg/addUser.svg';
import {ReactComponent as Tick} from './svg/tick.svg';
import {ReactComponent as FacebookShare} from './svg/facebookShare.svg';
import {ReactComponent as SkypeShare} from './svg/skypeShare.svg';
import {ReactComponent as MessengerShare} from './svg/messengerShare.svg';
import {ReactComponent as Copy} from './svg/Copy.svg';
import {ReactComponent as CloudUpload} from './svg/CloudUpload.svg';
import {ReactComponent as Download} from './svg/download.svg';
import {ReactComponent as DoubleUpChevron} from './svg/up chevron.svg';
import {ReactComponent as DoubleDownChevron} from './svg/down chevron.svg';
import {ReactComponent as SendMessage} from './svg/navigation arrow.svg';
import {ReactComponent as HappyFace} from './svg/happy-face.svg';
import {ReactComponent as Video} from './svg/video.svg';
import {ReactComponent as Image} from './svg/image.svg';
import {ReactComponent as Menu} from './svg/Menu.svg';
import {ReactComponent as Reply} from './svg/reply.svg';
import {ReactComponent as Pin} from './svg/pin.svg';
import {ReactComponent as MarkAsRead} from './svg/mark-as-read.svg';
import {ReactComponent as MarkAsUnread} from './svg/mark-as-unread.svg';
import {ReactComponent as ReturnRefund} from './svg/turn up left.svg';
import {ReactComponent as Favorite} from './svg/Favorite.svg';
import {ReactComponent as Heart} from './svg/heart.svg';
import {ReactComponent as Expand} from './svg/expand.svg';
import {ReactComponent as ShieldQuestion} from './svg/shield-question.svg';
import {ReactComponent as BackArrow} from './svg/backArrow.svg';
import {ReactComponent as ApproveTick} from './svg/ApproveTick.svg';
import {ReactComponent as ChatBlue} from './svg/chatBlue.svg';
import {ReactComponent as BoyWorkingComputer} from './svg/BoyWorking.svg';
import {ReactComponent as Translate} from './svg/translate.svg';
import {ReactComponent as OrderConfirmed} from './svg/orderConfirmed.svg';
import emptyBox from './gif/empty_box.gif';

export {
    errorPage,
    TripleGears,
    FemaleWorker,
    GooglePlay,
    LogoFooter,
    Avatar,
    AppStore,
    ChevronRight,
    Facebook,
    Gear,
    Instagram,
    Linkedin,
    Maintenance,
    Twitter,
    Youtube,
    sapatcoLogo,
    searchDocIcon,
    productImg,
    TagIcon,
    TagIcon2,
    SearchIcon,
    Cart,
    cartBlue,
    Delivery,
    mail,
    phone,
    navigation_map,
    LightBulb,
    DownArrow,
    ornament,
    eyes,
    phoneLogo,
    google,
    facebook,
    ornament1,
    oval,
    ornamentMobile,
    ovalMobile,
    phoneNumber,
    group35,
    frame2745,
    RightArrow,
    ratingStar,
    ratingStarInactive,
    eyeSee,
    ornament2x,
    filter,
    filterInactive,
    close,
    ImageIcon,
    CommentIcon,
    ratingStarOutline,
    noFeedback,
    RedHeart,
    GrayHeart,
    ErrorMark,
    location,
    ornament_setPw,
    ornament_setPw2x,
    likeOutline,
    Share,
    frame2746,
    SliderLeftArrow,
    SliderRightArrow,
    otp,
    HDLogo,
    defaultBuilding,
    UpArrow,
    emptyCart,
    haidangLoader,
    addActive,
    addDeactive,
    deleteGrayBtn,
    deleteRedBtn,
    minusActive,
    minusDeactive,
    thankYou,
    Reload,
    emptyCode,
    Shipper,
    notes,
    Edit,
    EditOutline,
    save,
    Cancel,
    couponFrame,
    Trash,
    Plus,
    COD,
    ATMCard,
    Approve,
    upperArrow,
    moreInformation,
    Bell,
    EditDark,
    Profile,
    Connection,
    Logout,
    EmptyNoti,
    CardHeart,
    Help,
    Setting,
    downArrow,
    BreadcrumbArrow,
    WhitePlus,
    threeDot,
    EditGray,
    Archive,
    emptyBox,
    VoucherChip,
    Chat,
    Bill,
    ApproveFile,
    DeleteFile,
    CloseGray,
    InfoMark,
    AddImage,
    AddVideo,
    Voucher,
    UserOutline,
    Clipboard,
    DocumentImg,
    Marketing,
    MoneyWithdrawal,
    Screen,
    ShoppingBag,
    ShowChart,
    Repeat,
    Parcel,
    ShippingTruck,
    SearchBlue,
    LeftArrow,
    CameraChange,
    AddAccount,
    noAddress,
    plus,
    Printer,
    VoucherAble,
    VoucherApplied,
    VoucherDisabled,
    CloseCircle,
    ExclamationMark,
    AddUser,
    Tick,
    FacebookShare,
    SkypeShare,
    Copy,
    MessengerShare,
    CloudUpload,
    UpArrowSvg,
    DownArrowSvg,
    infoIcon,
    copyIcon,
    noneVoucher,
    Download,
    edit,
    dublicate,
    deleteIcon,
    DoubleDownChevron,
    DoubleUpChevron,
    SendMessage,
    HappyFace,
    Video,
    Image,
    Menu,
    Reply,
    Pin,
    MarkAsRead,
    MarkAsUnread,
    ReturnRefund,
    Favorite,
    favCart,
    HaidangLogo,
    Heart,
    Expand,
    ShieldQuestion,
    BackArrow,
    ApproveTick,
    ChatBlue,
    BoyWorkingComputer,
    emptyFavProd,
    Translate,
    NotiDefault,
    OrderConfirmed
};
