import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Toast} from 'utils/toast';
import propsProvider from './paymentPropsProvider';
import {
    addInvoice,
    addUserAddress,
    createInvoiceToken,
    createOrderGHN,
    deleteUserAddress,
    getAllDistrictByProvinceGHN,
    getAllProvinceGHN,
    getAllWardByDistrictGHN,
    getAvailableServices,
    getMomoPaymentUrl,
    getPaymentAddress,
    getPaymentCart,
    getPaymentMethodData,
    getShippingUnit,
    getUserAddresses,
    getVNPayPaymentUrl,
    getVoucherList,
    getVoucher,
    paymentCartOrder,
    updateUserAddress
} from './paymentSlice';
import PaymentMainView from './template/PaymentMainView';
import AddressListModal from './template/subViews/AddressListModal/AddressListModal';
import {useForm} from 'react-hook-form';
import {defaultValues} from './template/subViews/VoucherModal/VoucherModal';
import {useSelector} from 'react-redux';
import {authSelector} from 'app/selectors';
import {useHistory} from 'react-router-dom';
import {eInvoiceSerial} from 'api/einvoiceApi';
import {
    addNotificationWithStatus,
    sendPushNotification
} from 'features/Admin/template/subViews/ShopSetting/NotificationSetting/NotificationSettingSlice';
import {getUserDetail} from 'features/UserProfile/UserProfileSlice';
import {getEndOfDate} from 'utils/getEndOfDate';
import moment from 'moment';

export default function PaymentContainer(props) {
    const intl = useIntl();
    const {dispatch, payment} = props;
    const history = useHistory();
    const [isOpenAddressFormModal, setOpenAddressFormModal] = useState(false);
    const [addressListModalContent, setAddressListModalContent] = useState({});
    const [isOpenDeleteAddressModal, setOpenDeleteAddressModal] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [paymentMethod, setPaymentMethod] = useState({});
    const [addressModalType, setAddressModalType] = useState('ADD');
    const [selectedAddressObj, setSelectedAddressObj] = useState({});
    const {paymentProducts, defaultAddress, voucherList} = payment;
    const [paymentProductsData, setPaymentProductsData] = useState([]);
    const [selectedShippingUnit, setSelectedShippingUnit] = useState({});
    const [shippingFee, setShippingFee] = useState(0);
    const [note, setNote] = useState('');
    const [toDistrictId, setToDistrictId] = useState([]);
    const auth = useSelector(authSelector);
    const [isOpenAddressListModal, setIsOpenAddressListModal] = useState(false);

    //Voucher modal props;
    const {control, watch, handleSubmit, reset} = useForm({defaultValues});
    const watchAllFields = watch();
    const isApplyDisabled = watchAllFields.coupon.length <= 0;
    const voucherDropdownRef = useRef(null);
    const [appliedVoucher, setAppliedVoucher] = useState(null);
    const [appliedVoucherValue, setAppliedVoucherValue] = useState(null);
    const [appliedVoucherIdList, setAppliedVoucherIdList] = useState([]);
    const [voucherDropdownDataState, setVoucherDropdownDataState] = useState('closed');

    const openVoucherDropdown = async () => {
        setVoucherDropdownDataState('open');
        const params = {
            Sorts: '-expiry',
            Filters: `status==e22cbf21-506d-4d40-a37a-3e648f54d563,expiry>=${getEndOfDate(moment(new Date())).format(
                'YYYY/MM/DD'
            )}`
        };
        await dispatch(getVoucherList(params));
    };

    const closeVoucherDropdown = () => {
        setVoucherDropdownDataState('closing');

        voucherDropdownRef.current?.addEventListener('animationend', () => setVoucherDropdownDataState('closed'), {
            once: true
        });
    };

    useEffect(() => {
        if (paymentProducts) {
            const productBrandId = new Map();
            const paymentProductList = [];
            paymentProducts.forEach((product) => {
                const {brand_id, brand_name, extend_product, extend_brand_voucher, cart_id} = product;
                if (extend_brand_voucher.length > 0) {
                    setAppliedVoucher(extend_brand_voucher[0]);
                }
                if (productBrandId.has(brand_id)) {
                    const foundBrandStore = paymentProductList.find((el) => el.brand_id === brand_id);
                    const {productList, cartProductIDList} = foundBrandStore;
                    const newProductList = [...productList, extend_product];
                    const newCartProductIDList = [...cartProductIDList, cart_id];
                    foundBrandStore['productList'] = newProductList;
                    foundBrandStore['cartProductIDList'] = newCartProductIDList;

                    if (extend_brand_voucher.length > 0) {
                        foundBrandStore['extend_brand_voucher'] = extend_brand_voucher;
                    }
                } else {
                    productBrandId.set(brand_id, brand_name);
                    const newBrandStore = {
                        brand_id,
                        brand_name,
                        productList: [extend_product],
                        cartProductIDList: [cart_id],
                        extend_brand_voucher
                    };
                    return paymentProductList.push(newBrandStore);
                }
            });
            setPaymentProductsData(paymentProductList);
        }
    }, [paymentProducts, dispatch]);

    useEffect(() => {
        if (appliedVoucher && paymentProductsData) {
            const {value, value_percentage} = appliedVoucher;
            paymentProductsData.forEach((stall) => {
                let productVoucherPrice = 0;
                const {productList} = stall;
                productList.forEach((product) => {
                    if (appliedVoucher.category.includes(product?.product_category_id)) {
                        productVoucherPrice += product?.total;
                    }
                });
                setAppliedVoucherValue(Math.min(value, (value_percentage / 100) * productVoucherPrice));
            });

            const appliedVoucherIdLst = [];
            paymentProductsData.forEach((el) => {
                const {extend_brand_voucher} = el;
                extend_brand_voucher?.forEach((voucher) => {
                    appliedVoucherIdLst.push(voucher?.id);
                });
            });
            setAppliedVoucherIdList(appliedVoucherIdLst);

        } else {
            setAppliedVoucherValue(null);
        }
    }, [appliedVoucher, paymentProductsData]);

    const navigateTo = (path, isReplace = false) => {
        if (isReplace) history.replace(path);
        else history.push(path);
    };

    const closeAddressFormModal = () => {
        setOpenAddressFormModal(false);
    };
    const onOpenAddressFormModal = (address) => {
        if (address) {
            const {id} = address;
            setAddressModalType('UPDATE');
            setSelectedAddressId(id);
            setSelectedAddressObj(address);
        } else {
            setAddressModalType('ADD');
        }
        setOpenAddressFormModal(true);
    };
    const onSubmitAddressFormModal = async (data, e) => {
        e.preventDefault();
        if (data) {
            const payload = {
                province_code: data.city_obj?.code,
                district_code: data.district_obj?.code,
                ward_code: data.ward_obj?.code,
                address: data.house_address,
                fullname: data.receive_name,
                phone: data.receive_phone,
                is_default: addressModalType === 'ADD' ? true : data.is_set_default
            };

            const res = await dispatch(
                addressModalType === 'ADD'
                    ? addUserAddress(payload)
                    : updateUserAddress({id: selectedAddressId, ...payload})
            ).unwrap();
            const {success} = res;
            if (success) {
                await dispatch(getUserAddresses());
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thành công`);
            } else {
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thất bại!`);
            }

            closeAddressFormModal();
        }
    };

    const onOpenAddressListModal = () => {
        setIsOpenAddressListModal(true);
        const addressListModalProps = {
            intl,
            userAddressList: payment && payment.userAddresses,
            onOpenAddressFormModal,
            onOpenDeleteAddressModal
        };
        setAddressListModalContent({
            title: 'Danh sách địa chỉ',
            content: <AddressListModal {...addressListModalProps} />,
            closeText: 'Đóng',
            isDelete: false
        });
    };

    const closeDeleteAddressModal = () => {
        setOpenDeleteAddressModal(false);
    };
    const onOpenDeleteAddressModal = (id) => {
        setSelectedAddressId(id);
        setOpenDeleteAddressModal(true);
    };

    const onDeleteUserAddress = async () => {
        const res = await dispatch(deleteUserAddress(selectedAddressId)).unwrap();
        const {success} = res;
        if (success) {
            Toast(success, 'Xóa địa chỉ thành công');
            await dispatch(getUserAddresses());
        } else Toast(success, 'Xóa địa chỉ thất bại!');
        closeDeleteAddressModal();
    };

    const onSaveDefaultAddress = async (addressId) => {
        const res = await dispatch(
            updateUserAddress({
                id: addressId,
                is_default: true
            })
        ).unwrap();
        const {success} = res;
        if (success) {
            Toast(success, 'Cập nhật địa chỉ mặc định thành công');
            await dispatch(getUserAddresses());
        } else Toast(success, 'Cập nhật địa chỉ mặc định thất bại!');
    };

    const fetchProvinceListGHN = async () => {
        const res = await dispatch(getAllProvinceGHN()).unwrap();
        if (res && res.status === 200) {
            const {data} = res;
            const provinceId = data.data.find((el) => el.ProvinceName === defaultAddress.province).ProvinceID;

            const response = await dispatch(getAllDistrictByProvinceGHN({province_id: provinceId})).unwrap();
            if (response && response.status === 200) {
                const {data} = response;
                const districtId = data.data.find((el) => el.DistrictName === defaultAddress.district).DistrictID;
                setToDistrictId(districtId);
            }
        }
    };

    const onPaymentOrder = async (data, invoiceTypeValue) => {
        const exportInvoice = async () => {
            if (data) {
                const {companyAddress, companyName, emailInvoice, note, taxNumber} = data;
                const {full_name, phone} = auth.userLoginInfo;

                const invoiceCustomer =
                    invoiceTypeValue === 0
                        ? {
                              fullName: companyName,
                              address: companyAddress,
                              phoneNumber: phone,
                              email: emailInvoice
                          }
                        : {
                              taxNumber,
                              companyName,
                              fullName: full_name,
                              address: companyAddress,
                              phoneNumber: phone,
                              email: emailInvoice
                          };

                for (const paymentData of paymentProductsData) {
                    const {productList, extend_brand_voucher} = paymentData;

                    const eInvoiceItems = productList.map((product) => {
                        const {name, price, quantity} = product;

                        return {
                            productName: name,
                            quantity,
                            price,
                            free: false,
                            discount: false,
                            currencyType: 'VND'
                        };
                    });

                    if (extend_brand_voucher.length > 0) {
                        extend_brand_voucher.forEach((voucher) => {
                            const {name, value} = voucher;
                            eInvoiceItems.push({
                                productName: name,
                                quantity: 1,
                                price: value,
                                free: false,
                                discount: true,
                                currencyType: 'VND'
                            });
                        });
                    }

                    const invoiceData = {
                        serial: eInvoiceSerial,
                        arisingDate: new Date(),
                        vatRate: 0,
                        status: 1,
                        paymentMethod: paymentMethod.code === 'COD' ? 1 : 2,
                        currencyType: 0,
                        note,
                        discountType: 0,
                        eInvoiceItems,
                        invoiceCustomer
                    };

                    const res = await dispatch(addInvoice(invoiceData)).unwrap();
                    const {code, data} = res;
                    if (code === 200) {
                        const {InvoiceId} = data;
                        return InvoiceId;
                    } else return null;
                }
            }
        };

        // api error, temporarily commented out
        // const availableServiceRes = await dispatch(
        //     getAvailableServices({
        //         shop_id: 123853,
        //         from_district: 1455, //Haidang's district_id (GHN)
        //         to_district: toDistrictId
        //     })
        // ).unwrap();
        // const {status} = availableServiceRes;
        if (true) {
            // const GHNBody = {
            //     to_name: defaultAddress.fullname,
            //     to_phone: defaultAddress.phone,
            //     to_address: defaultAddress.address,
            //     to_ward_name: defaultAddress.ward,
            //     to_district_name: defaultAddress.district,
            //     to_province_name: defaultAddress.province,
            //     weight: 5,
            //     length: 5,
            //     width: 5,
            //     height: 5,
            //     service_type_id: 2,
            //     service_id: availableServiceRes?.data?.data[0].service_id,
            //     payment_type_id: 1,
            //     required_note: 'KHONGCHOXEMHANG',
            //     items: [
            //         {
            //             name: 'Áo Polo',
            //             code: 'Polo123',
            //             quantity: 1,
            //             price: 200000,
            //             length: 2,
            //             width: 2,
            //             height: 2,
            //             category: {
            //                 level1: 'Áo'
            //             }
            //         }
            //     ]
            // };
            // const response = await dispatch(createOrderGHN(GHNBody)).unwrap();
            if (true) {
                // const {data} = response;
                // const {order_code, expected_delivery_time, total_fee} = data.data;
                const invoiceId = await exportInvoice();
                if (defaultAddress && paymentProductsData && selectedShippingUnit) {
                    const {cartProductIDList} = paymentProductsData[0];
                    const paymentParams = {
                        userAddressId: defaultAddress.id,
                        shippingUnitId: selectedShippingUnit.id,
                        paymentMethodId: paymentMethod.id,
                        note: note,
                        is_COD: paymentMethod.code === 'COD',
                        invoiceId,
                        shippingFee: shippingFee, //Tổng phí ship
                        voucherIdList: appliedVoucherIdList.length ? appliedVoucherIdList.join(',') : ''
                        // billOfLandingCode: order_code, //Mã vận đơn
                        // expectedDeliveryTime: expected_delivery_time, //Ngày giao dự kiến
                    };

                    const res = await dispatch(paymentCartOrder({paymentParams, data: cartProductIDList})).unwrap();
                    const {success} = res;
                    if (success) {
                        const {id, user_id, code, total_price} = res.data;
                        const sendNoti = async () => {
                            let notificationParams = {
                                approvedStatusCode: 'SENT'
                            };
                            const res = await dispatch(
                                addNotificationWithStatus({
                                    notificationParams,
                                    data: {
                                        summary: `${intl.messages.updateOrderStatus} ${code}`,
                                        details: intl.messages.notiOrderDetails,
                                        user_id,
                                        reference_link: `${pagePath.userMyPurchaseUrl}/${id}`
                                    }
                                })
                            ).unwrap();
                            if (res?.success) {
                                const userRes = await dispatch(getUserDetail({id: user_id})).unwrap();
                                if (userRes?.success) {
                                    await dispatch(
                                        sendPushNotification({
                                            id: res?.data?.id,
                                            fcmClientToken: userRes?.data?.fcm_client_token || ''
                                        })
                                    ).unwrap();
                                }
                            }
                        };
                        localStorage.setItem('paymentMethod', paymentMethod.code);
                        const orderInfo = {
                            orderId: id
                        };
                        switch (paymentMethod.code) {
                            case 'COD':
                                await sendNoti();
                                return navigateTo(pagePath.thankYouUrl);
                            case 'VNPAY': {
                                const res = await dispatch(
                                    getVNPayPaymentUrl({
                                        ...orderInfo,
                                        totalAmount: total_price,
                                        returnUrl: Config.urlConfig.endPoint
                                    })
                                ).unwrap();
                                const {success} = res;
                                if (success) {
                                    const {paymentUrl} = res.data;
                                    await sendNoti();
                                    window.location.replace(paymentUrl)
                                    // navigateTo(paymentUrl);
                                }
                                return;
                            }
                            case 'MOMO': {
                                const res = await dispatch(
                                    getMomoPaymentUrl({
                                        ...orderInfo,
                                        amount: total_price,
                                        returnUrl: Config.urlConfig.endPoint,
                                        notifyurl: Config.urlConfig.endPoint
                                    })
                                ).unwrap();
                                const {success} = res;
                                if (success) {
                                    const {paymentUrl} = res.payInfo;
                                    await sendNoti();
                                    window.location.replace(paymentUrl)
                                    //navigateTo(paymentUrl);
                                }
                                return;
                            }
                            default:
                                return;
                        }
                    }
                } else if(!defaultAddress) {
                    Toast(false, intl.messages.addAddressWarningMessage);
                    return;
                }
            } else {
                // Toast(false, response.message_display);
            }
        } else {
            // Toast(false, availableServiceRes.statusText);
        }
    };

    const fetchPaymentMethodData = async () => {
        const res = await dispatch(getPaymentMethodData()).unwrap();
        const {success} = res;
        if (success) {
            const {collection} = res.data;
            const codMethod = collection && collection.find((el) => el.code === 'COD');
            setPaymentMethod({
                id: codMethod?.id,
                code: codMethod?.code
            });
        }
    };

    useEffect(() => {
        if(isOpenAddressListModal) {
            onOpenAddressListModal();
        }
    }, [payment.userAddresses])

    useEffect(() => {
        dispatch(getPaymentAddress());
        fetchProvinceListGHN();
        fetchPaymentMethodData();
        dispatch(getUserAddresses());
        dispatch(getPaymentCart());
        dispatch(getShippingUnit({Filters: 'is_active==true'}));
    }, []);

    const paymentContainerProps = {
        ...props,
        intl,
        navigateTo,
        payment,
        dispatch,
        isOpenAddressFormModal,
        closeAddressFormModal,
        onOpenAddressFormModal,
        onSubmitAddressFormModal,
        addressListModalContent,
        onOpenAddressListModal,
        paymentMethod,
        setPaymentMethod,
        onDeleteUserAddress,
        onSaveDefaultAddress,
        isOpenDeleteAddressModal,
        closeDeleteAddressModal,
        onOpenDeleteAddressModal,
        addressModalType,
        selectedAddressObj,
        paymentProductsData,
        onPaymentOrder,
        selectedShippingUnit,
        setSelectedShippingUnit,
        note,
        setNote,
        shippingFee,
        setShippingFee,

        //voucher props
        voucherDropdownDataState,
        voucherDropdownRef,
        openVoucherDropdown,
        closeVoucherDropdown,
        control,
        handleSubmit,
        isApplyDisabled,
        reset,
        voucherList,
        setAppliedVoucher,
        appliedVoucher,
        appliedVoucherValue
    };

    return <PaymentMainView {...propsProvider(paymentContainerProps)} />;
}
