import {adminDesignBannerSelector} from 'app/selectors';
import Config from 'configuration';
import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import propsProvider from './designBannerPropsProvider';
import {getDesignBanners, addDesignBanners, sortOrderBanners, deleteDesignBanners} from './designBannerSlice';
import DesignBannerMainView from './template/DesignBannerMainView';
import {finishLoading, startLoading} from '../../../../../../app/loaderSlice';

const DesignBannerContainer = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [isChangeBannerList, setIsChangeBannerList] = useState(false);
    const [deletedFilesId, setDeletedFilesId] = useState([]);
    const [bannerFiles, setBannerFiles] = useState([]);
    const [defaultBannerFiles, setDefaultBannerFiles] = useState([]);
    const adminDesignBanner = useSelector(adminDesignBannerSelector);

    const {currentBanners} = adminDesignBanner;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleUploadImages = async (e) => {
        const files = e.target.files;
        const previewImageUrls = [];
        // Max 2MB
        const maxCapacity = 2 * 1024 * 1024;
        let isOverSize = false;

        for (const file of files) {
            const {size} = file;

            if (size > maxCapacity) {
                isOverSize = true;
                break;
            }
            const fileUrl = await getBase64(file);
            const result = {image: fileUrl, file};
            previewImageUrls.push(result);
        }

        const newPreviewImageUrls = [...bannerFiles, ...previewImageUrls];

        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else if (newPreviewImageUrls.length > 6) {
            Toast(false, intl.messages.overLimit);
        } else {
            setBannerFiles(newPreviewImageUrls);
            setIsChangeBannerList(true);
        }
        e.target.value = null;
    };

    const handleDeleteImage = (id) => {
        if (checkActionSection(id)) {
            const newBannerFiles = bannerFiles.filter((data, idx) => {
                if (data.id && id === idx) {
                    setDeletedFilesId((prevIds) => [...prevIds, data.id]);
                }

                return idx !== id;
            });
            setBannerFiles(newBannerFiles);
        } else {
            const newBannerFiles = [...bannerFiles];
            newBannerFiles.splice(id, 1);
            setBannerFiles(newBannerFiles);
        }
        setIsChangeBannerList(true);
    };
    const handleDecreaseOrder = (id) => {
        const newBannerFiles = [...bannerFiles];
        const [itemToMove] = newBannerFiles.splice(id, 1);
        newBannerFiles.splice(id + 1, 0, itemToMove);
        setBannerFiles(newBannerFiles);
        setIsChangeBannerList(true);
    };
    const handleIncreaseOrder = (id) => {
        if (id !== 0) {
            const newBannerFiles = [...bannerFiles];
            const [itemToMove] = newBannerFiles.splice(id, 1);
            newBannerFiles.splice(id - 1, 0, itemToMove);
            setBannerFiles(newBannerFiles);
            setIsChangeBannerList(true);
        }
    };
    const handleAfterSubmit = async (currentBannersUrl) => {
        handleIncreaseOrder(bannerFiles.length);
        setIsChangeBannerList(false);
        dispatch(startLoading());
        await delay(1000);
        setBannerFiles([...currentBannersUrl]);
        dispatch(finishLoading());
    };

    const checkActionSection = (id) => {
        const section = bannerFiles.find((_, idx) => idx === id);
        if (section && section.url) {
            return true;
        } else {
            return false;
        }
    };

    const handleCancel = () => {
        setBannerFiles(defaultBannerFiles);
        setIsChangeBannerList(false);
        setDeletedFilesId([]);
    };
    const handleSave = async () => {
        for (const deleteItem of deletedFilesId) {
            await dispatch(deleteDesignBanners(deleteItem));
        }
        const fileFormData = new FormData();
        const sortOrder = [];
        for (const [index, file] of bannerFiles.entries()) {
            if (file.id) {
                sortOrder.push(file.id);
            } else {
                const fileData = file.file;
                fileFormData.append('file', fileData);
                const bannerResponse = await dispatch(addDesignBanners(fileFormData)).unwrap();
                const {success} = bannerResponse;
                if (success) {
                    const {data} = bannerResponse;
                    sortOrder.push(data?.id);
                    setBannerFiles(defaultBannerFiles);
                } else {
                    Toast(success, 'Tải banner thất bại');
                }
                fileFormData.delete('file');
            }
        }
        await dispatch(sortOrderBanners(sortOrder));
        await dispatch(getDesignBanners());
    };

    useEffect(() => {
        dispatch(getDesignBanners());
    }, []);

    useEffect(() => {
        if (currentBanners && currentBanners.length > 0) {
            const currentBannersUrl = currentBanners.map((banner) => {
                return {
                    ...banner,
                    url: Config.apiConfig.endPoint + banner.image
                };
            });
            setBannerFiles([...currentBannersUrl]);
            setDefaultBannerFiles([...currentBannersUrl]);
            handleAfterSubmit(currentBannersUrl);
        }
    }, [currentBanners]);

    const designBannerContainerProps = {
        intl,
        bannerFiles,
        handleUploadImages,
        handleDeleteImage,
        handleDecreaseOrder,
        handleIncreaseOrder,
        handleCancel,
        handleSave,
        isChangeBannerList
    };

    return <DesignBannerMainView {...propsProvider(designBannerContainerProps)} />;
};

export default DesignBannerContainer;
