import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';

const PaymentMethod = (props) => {
    const { intl, paymentMethodInfo } = props;

    return (
        <div className='payment-methods'>
            <SectionTitle paled={false}>{intl.messages.paymentMethodTitle}</SectionTitle>
            <div className='d-flex align-items-center'>
                <img src={paymentMethodInfo?.methodIcon} alt={'payment'} style={{ marginRight: '4px' }} />
                <label htmlFor='receive' style={{ fontSize: 13 }}>
                    {paymentMethodInfo?.methodName}
                </label>
            </div>
        </div>
    );
};

export default PaymentMethod;
