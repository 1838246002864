const i18nLabels = {
    vi: {
        successLoginLabel: 'Đăng nhập thành công',
        successRegisterLabel: 'Đăng ký thành công',
        requiredLabel: '*',
        accountLabel: 'Tài khoản',
        accountInputLabel: 'Email, số điện thoại...',
        passwordLabel: 'Mật khẩu',
        passwordInputLabel: 'Vui lòng nhập mật khẩu của bạn',
        repasswordLabel: 'Xác nhận lại Mật khẩu',
        repasswordInputLabel: 'Vui lòng nhập lại mật khẩu mới',
        forgetPwLabel: 'Quên mật khẩu',
        loginLabel: 'Đăng nhập',
        signupLabel: 'Đăng ký',
        registerInfoLabel: 'Hướng dẫn kích hoạt tài khoản đã được gửi đến Email',
        nameLabel: 'Họ và tên: ',
        nameInputLabel: 'Nhập họ và tên',
        otpLabel: 'OTP',
        googleLabel: 'Google',
        phoneLabel: 'Số điện thoại',
        phoneInputLabel: 'Nhập số điện thoại',
        typeofLabel: 'Loại tài khoản',
        personalLabel: 'Cá nhân',
        companyLabel: 'Công ty',
        companyNameInputLabel: 'Nhập tên công ty',
        companyNameLabel: 'Tên công ty: ',
        emailRegisterLabel: 'Email: ',
        emailRegisterInputLabel: 'Nhập email của bạn',
        invalidEmail: 'Địa chỉ email không hợp lệ',
        invalidPw: 'Mật khẩu phải có ít nhất 3 ký tự',
        registerButton: 'Đăng ký',
        agreeRegisterLabel: 'Khi bạn nhấn Đăng ký, bạn đã đồng ý thực hiện mọi giao dịch mua bán theo',
        policyLabel: 'điều kiện sử dụng và chính sách của Sapatco',
        registerSuccessLabel: 'Đăng ký thành công',
        registerSuccessLabel1: 'Hướng dẫn kích hoạt tài khoản đã được gửi đến Email',
        registerSuccessLabel2: 'của bạn. Vui lòng kiểm tra Email để hoàn tất đăng ký.',
        otpTextLabel: 'Vui lòng nhập mã xác thực vừa vào số điện thoại của bạn',
        saveIdLabel: 'Lưu tài khoản',
        noAccountLabel: 'Chưa có tài khoản?',
        haveAccountLabel: 'Đã có tài khoản?',
        or: 'Hoặc',
        errorMessage: 'Đã xảy ra sự cố. Vui lòng thử lại sau giây lát',
        loginErrorMessage: 'Đăng nhập thất bại',
        signupErrorMessage: 'Đăng ký thất bại',
        checkEmail: 'Vui lòng kiểm tra email của bạn!',
        checkPhone: 'Vui lòng kiểm tra điện thoại của bạn!',
        validationError: 'Các trường không được chứa khoảng trắng, vui lòng nhập lại',
        codeExpireIn: 'Mã có hiệu lực trong',
        resend: 'Gửi lại',
        notGetCode: 'Không nhận được mã',
        enterPhone: 'Nhập số điện thoại',
        phoneError: 'Số điện thoại bạn nhập không hợp lệ, vui lòng nhập lại !',
        send: 'Gửi',
        tryOtherMethods: 'Đăng nhập bằng phương thức khác',
        newAccount: 'Tạo tài khoản mới',
        sendEmailPassword: 'Chúng tôi sẽ gửi link khôi phục mật khẩu vào hòm thư của bạn',
        enterEmail: 'bạn vui lòng nhập email bên dưới',
        cancel: 'Hủy',
        haveSentLink: 'Đã gửi link khôi phục',
        setPasswordTitle: 'Thiết lập mật khẩu mới',
        newPassword: 'Mật khẩu mới',
        newPasswordLabel: 'Vui lòng nhập mật khẩu mới',
        repasswordError: 'Mật khẩu không khớp',
        complete: 'Hoàn thành'
    },
    en: {
        successLoginLabel: 'Login successful',
        successRegisterLabel: 'Registration successful',
        requiredLabel: '*',
        accountLabel: 'Account',
        accountInputLabel: 'Email, phone number...',
        passwordLabel: 'Password',
        passwordInputLabel: 'Please enter your password',
        repasswordLabel: 'Confirm your password',
        repasswordInputLabel: 'Please confirm your password',
        signupLabel: 'Sign up',
        forgetPwLabel: 'Forgot password?',
        loginLabel: 'Log in',
        registerInfoLabel: 'Activation instructions have been sent to your Email',
        nameLabel: 'Full name: ',
        nameInputLabel: 'Enter your full name',
        otpLabel: 'OTP',
        googleLabel: 'Google',
        phoneLabel: 'Phone number',
        phoneInputLabel: 'Enter your phone number',
        typeofLabel: 'Account type',
        personalLabel: 'Personal',
        companyLabel: 'Company',
        companyNameInputLabel: 'Enter company name',
        companyNameLabel: 'Company name: ',
        emailRegisterLabel: 'Email: ',
        emailRegisterInputLabel: 'Enter your email',
        invalidEmail: 'Invalid email format',
        invalidPw: 'Password must be at least 3 characters',
        registerButton: 'Register',
        agreeRegisterLabel:
            'By clicking Register, you agree to conduct all buying and selling transactions according to',
        policyLabel: 'the terms of use and policies of Sapatco',
        registerSuccessLabel: 'Registration successful',
        registerSuccessLabel1: 'Activation instructions have been sent to your Email',
        registerSuccessLabel2: 'Please check your Email to complete the registration.',
        otpTextLabel: 'Please enter the verification code you just received on your phone number',
        saveIdLabel: 'Remember me',
        noAccountLabel: "Don't have an account?",
        haveAccountLabel: 'Already have an account?',
        or: 'Or',
        errorMessage: 'An error has occurred. Please try again later',
        loginErrorMessage: 'Login failed',
        signupErrorMessage: 'Registration failed',
        checkEmail: 'Please check your Email!',
        checkPhone: 'Please check your phone!',
        validationError: 'Fields must not contain spaces, please re-enter',
        codeExpireIn: 'Code expires in',
        resend: 'Resend',
        notGetCode: 'Did not receive the code',
        enterPhone: 'Enter your phone number',
        phoneError: 'The phone number you entered is invalid, please try again!',
        send: 'Send',
        tryOtherMethods: 'Sign in with other methods',
        newAccount: 'Create new account',
        sendEmailPassword: 'We will send a password recovery link to your email inbox',
        enterEmail: 'Please enter your email below',
        cancel: 'Cancel',
        haveSentLink: 'Password reset link sent',
        setPasswordTitle: 'Set New Password',
        newPassword: 'New Password',
        newPasswordLabel: 'Please enter a new password',
        repasswordError: 'Password does not match',
        complete: 'Complete'
    }
};

export default i18nLabels;
