import { emptyCart } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import { pagePath } from 'configuration/routeConfig';
import React from 'react';

const EmptyCart = (props) => {
    const { intl, navigateTo } = props;

    return (
        <div className='cart-empty'>
            <div className='cart-empty__img'>
                <img src={emptyCart} alt='empty-cart' />
            </div>
            <p className='cart-empty__desc my-4'>{intl.messages.emptyCart}</p>
            <CustomButton onClick={() => navigateTo(pagePath.rootUrl)} fullwidth>{intl.messages.goShopping}</CustomButton>
        </div>
    );
};

export default EmptyCart;
