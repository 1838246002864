import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {emptyBox} from 'assets/images';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import CustomTableRow from 'components/CustomTableRow/CustomTableRow';
import CustomPagination from 'components/Pagination/Pagination';
import React from 'react';

const TableProductList = (props) => {
    const {
        intl,
        pageSizeRange,
        headCells = [],
        transformTableData = [],
        totalPage,
        currentPage,
        pageSize = 5,
        setPage,
        setPageSize,
        checkedData = [],
        handleSelectAllClick,
        handleSelectRow
    } = props;
    const isDataEmpty = currentPage <= 1 && transformTableData.length === 0;

    return (
        <TableContainer className='tabs-detail__table'>
            <Table aria-labelledby='tableTitle'>
                <CustomTableHead
                    handleCheckAll={handleSelectAllClick}
                    headCells={headCells}
                    withCheckbox
                    selectedRows={checkedData.length}
                    rowCount={transformTableData.length}
                />
                <TableBody>
                    {!isDataEmpty ? (
                        transformTableData.map((row, key) => {
                            const isItemSelected = checkedData.includes(row.id);

                            return (
                                <CustomTableRow
                                    key={key}
                                    intl={intl}
                                    dataRow={row}
                                    withReadmore
                                    selected={isItemSelected}
                                    handleSelectRow={handleSelectRow}
                                />
                            );
                        })
                    ) : (
                        <TableRow className='table-row--empty'>
                            <TableCell colSpan={headCells.length + 1}>
                                <img src={emptyBox} alt='empty data' />
                                <span>{intl.messages.noDataFound}</span>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {!isDataEmpty && (
                <div className='table-pagination'>
                    <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={currentPage} />
                    <CustomPageSize
                        label={intl.messages.rowsPerPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageSizeRange={pageSizeRange}
                        id={'temp'}
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default TableProductList;
