import { FormControl, FormHelperText } from '@mui/material';
import { Box } from '@mui/system';
import { CloseGray } from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import AddProductSection from '../AddProductSection/AddProductSection';
import PriceAccordion from './PriceAccordion';

const DeliveryInformationSection = forwardRef((props, ref) => {
    const { intl, deliveryPrice, formActivated, control, errors, isExpanded, toggleExpand } = props;

    const addProductSectionProps = {
        title: intl.messages.sectionDeliveryInformation,
        formActivated,
        isExpanded,
        toggleExpand
    };

    const deliverySize = [
        { label: intl.messages.width, name: 'width' },
        { label: intl.messages.length, name: 'length' },
        { label: intl.messages.height, name: 'height' }
    ];

    const deliveryRules = {
        max: { value: 200000, message: intl.messages.deliveryInputError },
        min: { value: 0, message: intl.messages.deliveryInputError }
    };

    return (
        <AddProductSection {...addProductSectionProps} ref={ref}>
            <div className='mt-2'>
                <div className='delivery-information__weight'>
                    <Controller
                        name='weight'
                        control={control}
                        rules={{ required: { value: true, message: intl.messages.requiredField }, ...deliveryRules }}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <CustomInput
                                    {...field}
                                    className='validating-input'
                                    type='number'
                                    endicon='gr'
                                    isGapEndIcon
                                    valid={!errors['weight']}
                                    label={
                                        <span>
                                            {intl.messages.weightAfterPackage} <span className='text-required'>*</span>
                                        </span>
                                    }
                                />
                                {!!errors['weight'] && (
                                    <FormHelperText error={!!errors['weight']}>
                                        {errors['weight'].message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </div>

                <div className='delivery-information__size'>
                    <div className='size-inputs'>
                        {deliverySize.map((size, idx) => (
                            <React.Fragment key={idx}>
                                <Box className='size-input'>
                                    <Controller
                                        control={control}
                                        name={size.name}
                                        rules={deliveryRules}
                                        render={({ field }) => (
                                            <FormControl fullWidth>
                                                <CustomInput
                                                    {...field}
                                                    {...size}
                                                    valid={!errors[size.name]}
                                                    endicon='cm'
                                                    type='number'
                                                />
                                                {!!errors[size.name] && (
                                                    <FormHelperText
                                                        error={!!errors[size.name]}
                                                        sx={{ whiteSpace: 'nowrap' }}>
                                                        {errors[size.name].message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                                {idx < 2 && <CloseGray className='size-cross' />}
                            </React.Fragment>
                        ))}
                    </div>
                    <p className='m-0 mt-2'>
                        <span className='size-note'>{intl.messages.note} </span>
                        <span>{intl.messages.packageSize}</span>
                    </p>
                </div>

                <div className='delivery-information__price mb-2'>
                    <p className='m-0 mb-1'>{intl.messages.deliveryPrice}</p>

                    {deliveryPrice.map((price) => (
                        <PriceAccordion price={price} key={price.label} control={control} name={price.name} />
                    ))}
                </div>
            </div>
        </AddProductSection>
    );
});

export default DeliveryInformationSection;
