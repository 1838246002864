import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import usersApi from 'api/usersApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {};
};

export const changeUserPassword = createAsyncThunk('user/changePassword', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.changePassword,
        payload: data
    });
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.updateUser,
        payload: data
    });
});

export const uploadAvatar = createAsyncThunk('user/uploadAvatar', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.upLoadAvatar,
        payload: data
    });
});

export const genOtpToChangeEmail = createAsyncThunk('user/genOtpToChangeEmail', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.genOtpToChangeEmail,
        payload: data
    });
});

export const confirmOtpToChangeEmail = createAsyncThunk('user/confirmOtpToChangeEmail', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.confirmOtpToChangeEmail,
        payload: data
    });
});

export const checkPhoneIsHave = createAsyncThunk('user/checkPhoneIsHave', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.checkPhoneIsHave,
        payload: data
    });
});

export const changePhone = createAsyncThunk('user/changePhone', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.changePhone,
        payload: data
    });
});

export const getMyInfo = createAsyncThunk('auth/getMyInfo', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.getMyInfo
    });
});

export const getUserDetail = createAsyncThunk('user/getUserDetail', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.getUserDetail,
        payload: data
    });
});

const userProfile = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getMyInfo.fulfilled, (state, action) => {
            const userLoginInfo = action.payload?.user;
            const authJsonValue = sessionStorage.getItem(Config.storageKey.auth);
            const authValue = JSON.parse(authJsonValue);
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify({...authValue, userLoginInfo}));
        });
    }
});

const {reducer} = userProfile;
export default reducer;
