import {CloudUpload, DownArrowSvg, Trash, UpArrowSvg} from 'assets/images';
import ActionBar from 'components/ActionBar/ActionBar';
import CustomButton from 'components/CustomButton/CustomButton';
import React, {useState, useEffect} from 'react';
import {useTransition, animated} from 'react-spring';

const DesignBannerMainView = (props) => {
    const {
        intl,
        imageRequirement = [],
        videoRequirement = [],
        bannerFiles = [],
        handleUploadImages,
        handleDeleteImage,
        handleDecreaseOrder,
        handleIncreaseOrder,
        actionBarControlButtons,
        isChangeBannerList
    } = props;
    const [rows, set] = useState(bannerFiles);
    const height = 20;
    const transitions = useTransition(
        rows.map((bannerFiles, i) => ({...bannerFiles, y: i * height})),
        {
            from: {opacity: 0},
            leave: {height: 0, opacity: 0},
            enter: ({y}) => ({y, opacity: 1}),
            update: ({y}) => ({y}),
            key: (item) => item?.id
        }
    );

    useEffect(() => {
        set(bannerFiles);
    }, [bannerFiles]);

    const maxBannersAllowed = 6;
    const accept = ['.jpg', '.png', '.gif', '.jpeg'];

    return (
        <div className='design-banner--wrapper'>
            <div className='design-banner'>
                <div className='design-banner__header'>
                    <h4>{intl.messages.designBannerTitle}</h4>
                    <span>{intl.messages.designBannerSubTitle}</span>
                </div>

                <div className='design-banner__body'>
                    <p>{intl.messages.uploadImage}</p>
                    {transitions(({y, ...rest}, bannerItem, {key}, idx) => (
                        <animated.div
                            key={key}
                            style={{
                                transform: y.to((y) => `translate3d(0,${y}px,0)`),
                                ...rest
                            }}>
                            <section className='banner-wrapper' key={idx}>
                                <div className='banner-wrapper__control'>
                                    <UpArrowSvg onClick={() => handleIncreaseOrder(idx)} />
                                    <DownArrowSvg onClick={() => handleDecreaseOrder(idx)} />
                                </div>

                                <div className='banner-wrapper__content'>
                                    {
                                        <img
                                            src={bannerItem.url ? bannerItem.url : bannerItem.image}
                                            alt={`banner-${idx}`}
                                        />
                                    }
                                </div>

                                <div className='banner-wrapper__delete' onClick={() => handleDeleteImage(idx)}>
                                    <Trash />
                                </div>
                            </section>
                        </animated.div>
                    ))}

                    {bannerFiles.length < maxBannersAllowed && (
                        <div className='banner-wrapper__content pt-8percent'>
                            <div className='content-upload'>
                                <input
                                    id='files'
                                    type='file'
                                    multiple={true}
                                    accept={accept.join(', ')}
                                    className='input-hold'
                                    onChange={handleUploadImages}
                                />

                                <div className='input-frame'>
                                    <CloudUpload />
                                    <span>{intl.messages.uploadImageShort}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {bannerFiles.length < maxBannersAllowed && (
                        <div className='upload-button'>
                            <CustomButton variant='outlined'>{`${intl.messages.addImageButtonLabel} (${bannerFiles.length}/${maxBannersAllowed})`}</CustomButton>
                        </div>
                    )}
                </div>

                <div className='design-banner__footer'>
                    <div className='footer-image'>
                        <p className='mb-1'>{intl.messages.image}:</p>
                        <ul>
                            {imageRequirement.map((item) => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    </div>

                    <div className='footer-video'>
                        <p className='mb-1'>{intl.messages.video}:</p>
                        <ul>
                            {videoRequirement.map((item) => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {isChangeBannerList && <ActionBar actionBarControlButtons={actionBarControlButtons} />}
        </div>
    );
};

export default DesignBannerMainView;
