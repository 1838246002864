import React, {useLayoutEffect, useState} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

const CategoryInput = ({isEdit, selectCategory, displayCategory, addChildCategory, setAddChildren, setIsEdit}) => {
    const [name, setName] = useState('');

    useLayoutEffect(() => {
        if (isEdit) {
            setName(displayCategory.name);
        }
    }, [displayCategory.name, isEdit]);

    return (
        <div className='child_input'>
            <input
                key={selectCategory.id}
                type='text'
                maxLength={255}
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />
            <div className={`child_input_action`}>
                <SaveIcon
                    style={{transform: 'scale(0.8)'}}
                    onClick={async () => {
                        await addChildCategory(
                            {
                                name,
                                parent_id: displayCategory.id,
                                level: displayCategory.level,
                                parentAttributes: displayCategory.attributes,
                                id: displayCategory.id
                            },
                            !isEdit
                        );
                        setIsEdit(false);
                        setAddChildren(false);
                    }}
                />
                <ClearIcon
                    style={{transform: 'scale(0.8)'}}
                    onClick={() => {
                        if (isEdit) setIsEdit(false);
                        else setAddChildren(false);
                    }}
                />
            </div>
        </div>
    );
};

export default CategoryInput;
