import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const productTypesApi = {
    uploadImage: (data) => {
        const {id, file} = data;

        const url = `/api/productTypes/${id}/uploadImage`;

        return axiosFormData.put(url, file);
    },
    updateProductType: (data) => {
        const {id, body} = data;

        const url = `/api/productTypesValues/${id}`;

        return axiosClient.put(url, body);
    }
};

export default productTypesApi;
