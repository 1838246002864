import React, {useEffect, useState} from 'react';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import {Checkbox} from '@mui/material';
import ProductRow from './ProductRow';
import CustomPagination from 'components/Pagination/Pagination';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import {calcTotalPage} from 'utils/calcTotalPage';
import {getVoucherProducts, resetProductList} from '../../../../VoucherSlice';

const ConditionCategoryList = (props) => {
    const {
        intl,
        categoryColumns,
        setValue,
        productList,
        selectedProducts = [],
        selectedCategoryList = [],
        dispatch,
        unsetProduct,
        setUnsetProduct
    } = props;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const pageSizeRange = [5, 10, 15, 20];
    const {collection, total} = productList || {collection: [], total: 0};

    const handleCheckRow = (id, value) => {
        if (value) {
            setValue('product', [...selectedProducts, id]);
        } else {
            setValue(
                'product',
                selectedProducts.filter((product) => product !== id)
            );
        }
    };

    const handleCheckAll = (value) => {
        if (value) {
            setValue(
                'product',
                collection.map((product) => product.id)
            );
        } else {
            setValue('product', []);
        }
    };

    const handlePageSizeChange = (value) => {
        setPage(1);
        setPageSize(value);
    };

    const isDataEmpty = collection ? collection.length === 0 : true;

    useEffect(() => {
        try {
            const controller = new AbortController();
            const {signal} = controller;
            const fetchData = async () => {
                if (selectedCategoryList.length > 0) {
                    const data = {
                        Filters: `product_category_id==${selectedCategoryList.join('|')}`,
                        Sorts: '-created_at',
                        Page: page,
                        pageSize: pageSize
                    };

                    await dispatch(getVoucherProducts({data, signal})).unwrap();
                    if (unsetProduct) {
                        setUnsetProduct(false);
                    } else {
                        setValue('product', []);
                    }
                } else {
                    dispatch(resetProductList());
                }
            };

            fetchData();

            return () => controller.abort();
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategoryList, page, pageSize]);

    return (
        <div className='condition-categories'>
            <h5>{intl.messages.productList}</h5>

            <div className='category-table' role='table'>
                <div className='category-table__header'>
                    {categoryColumns.map((headCell, idx) => (
                        <div
                            className={`data-col data-col-${idx} ${headCell.className ? headCell.className : ''}`}
                            key={headCell.id}>
                            {idx === 0 ? (
                                <Checkbox
                                    className='header-checkbox'
                                    disableRipple
                                    checked={selectedProducts.length === collection?.length}
                                    indeterminate={
                                        selectedProducts.length < collection?.length && selectedProducts.length > 0
                                    }
                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                />
                            ) : (
                                headCell.label
                            )}
                        </div>
                    ))}
                </div>

                <div className='category-table__body'>
                    {!isDataEmpty ? (
                        <div className='category-table__body--content'>
                            {collection?.map((product) => (
                                <ProductRow
                                    key={product.id}
                                    data={product}
                                    handleCheckRow={handleCheckRow}
                                    selectedProducts={selectedProducts}
                                    intl={intl}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyTableData label={intl.messages.noDataFound} />
                    )}
                    {!isDataEmpty && (
                        <div className='table-pagination'>
                            <CustomPagination
                                setPage={setPage}
                                totalPage={calcTotalPage(total, pageSize)}
                                currentPage={page}
                            />
                            <CustomPageSize
                                label={intl.messages.rowsPerPage}
                                pageSize={pageSize}
                                setPageSize={handlePageSizeChange}
                                pageSizeRange={pageSizeRange}
                                id={'temp'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConditionCategoryList;
