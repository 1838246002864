import {Controller, useForm} from 'react-hook-form';
import React from 'react';
import {Repeat, SearchBlue} from 'assets/images';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import GroupInput from 'components/GroupInput/GroupInput';

const defaultFilterValues = {
    notificationStatus: 'all',
    fromDate: '',
    toDate: ''
};

const NotificationFilter = (props) => {
    const {intl, submitFilterNotification, setFilterData} = props;
    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultFilterValues
    });

    const groupDateInputs = [
        {
            name: 'fromDate',
            placeholder: intl.messages.fromDate
        },
        {
            name: 'toDate',
            placeholder: intl.messages.toDate
        }
    ];
    const statusNotificationSelectData = [
        {value: 'Tất cả trạng thái', id: 'all'},
        {value: 'Đã lên lịch', id: '7ce2ca14-c4a8-484b-bc7d-93a139ed1ebd'},
        {value: 'Đã gửi', id: '1609a852-d96b-4c84-8022-7ba437e6ed4b'}
    ];

    const handleReset = () => {
        reset(defaultFilterValues);
        setFilterData(['reference_link==created']);
    };

    return (
        <div className='notification-setting__filter mb-3'>
            <h4 className='mb-3'>{intl.messages.notificationSetting}</h4>
            <form action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterNotification)}>
                <div className='row m-0'>
                    <div className='col-5 p-0 pr-2'>
                        <GroupInput
                            type='date'
                            control={control}
                            label={intl.messages.orderDate}
                            groupInputData={groupDateInputs}
                        />
                    </div>
                    <div className='col-4 p-0'>
                        <Controller
                            name='notificationStatus'
                            control={control}
                            render={({field}) => (
                                <CustomSelect
                                    {...field}
                                    noPlaceholder
                                    label={intl.messages.notificationStatus}
                                    options={statusNotificationSelectData}
                                />
                            )}
                        />
                    </div>
                    <div className='col-3 p-0 d-flex gap-2 justify-content-end'>
                        <CustomButton className='p-2' type='submit' variant='half-contained'>
                            <SearchBlue className='me-1' />
                            {intl.messages.search}
                        </CustomButton>
                        <CustomButton className='p-2' type='reset' variant='text' onClick={handleReset}>
                            <Repeat className='me-1' />
                            {intl.messages.reInput}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NotificationFilter;
