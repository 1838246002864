import axiosClient from './axiosClient';

const voucherApi = {
    getVoucherList: (params) => {
        const url = `/api/vouchers`;
        return axiosClient.get(url, { params });
    },

    getVoucher: (id) => {
        const url = `/api/vouchers/${id}`;
        return axiosClient.get(url);
    },

    createVoucher: (data) => {
        const url = '/api/vouchers/addNew';
        return axiosClient.post(url, data);
    },

    editVoucher: ({ id, data }) => {
        const url = `/api/vouchers/${id}`;
        return axiosClient.put(url, data);
    },

    deleteVoucher: (id) => {
        const url = `/api/vouchers/${id}`;
        return axiosClient.delete(url);
    },

    getPaymentMethod: (params) => {
        const url = '/api/proceedSubSteps';
        return axiosClient.get(url, { params });
    },

    applyVoucher: (id) => {
        const url = `/api/vouchers/applyVoucher/${id}`;
        return axiosClient.put(url);
    },

    cancelVoucher: (id) => {
        const url = `/api/vouchers/cancelVoucher/${id}`;
        return axiosClient.put(url);
    }
};

export default voucherApi;
