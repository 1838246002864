import { marketingListVouchers } from 'app/selectors';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import propsProvider from './flashDealPropsProvider';
import FlashDealMainView from './template/FlashDealMainView';

const FlashDealContainer = (props) => {
    const { dispatch, history } = props;
    const intl = useIntl();
    // const marketingListVouchers = useSelector(marketingListVouchers);
    // const {voucherList, paginationData} = marketingListVouchers;
    const [isOpenModal, setOpenModal] = useState(false);
    const [isAddNewModal, setAddNewModal] = useState(false);
    const [filterData, setFilterData] = useState();
    const [isAddNew, setIsAddNew] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [flashDealFilter, setFlashDealFilter] = useState('');
    const [flashDealEditData, setFlashDealEditData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isDublicate, setDublicate] = useState(false);
    const [startDealFilter, setStartDealFilter] = useState('');
    const [endtDealFilter, setEndDealFilter] = useState('');
    const [errorDate, setErrorDate] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);

    const handleOpenModal = (id) => {
        if (id) {
            setAddNewModal(false);
        }
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleAddNew = () => {
        setIsAddNew(true);
    };

    const handleAddNewModal = () => {
        setAddNewModal(!isAddNewModal);
    };

    const handleSubmitFormModal = async () => {
        // console.log('submit');
    };

    const submitFilterProduct = async (data) => {
        var filter = ``
        if (data?.fromDate !== null && data?.fromDate !== "") filter += `start_deal>=${data?.fromDate?.$d?.toISOString()},`
        if (data?.toDate !== null && data?.toDate !== "") filter += `end_deal<=${data?.toDate?.$d?.toISOString()},`
        if (data?.status) filter += `status@=${data?.status}`
        setFlashDealFilter(filter);
    };

    useEffect(() => {
        if (history.location.pathname === '/admin/flash-deal/add-new') {
            setIsAddNew(true);
        }

        const pt = new RegExp('/admin/flash-deal/dublicate/\\w+');
        const res = pt.test(history.location.pathname);
        if (res) {
            setDublicate(true);
        }
        const pattern = new RegExp('/admin/flash-deal/edit/\\w+');
        const result = pattern.test(history.location.pathname);
        if (result) {
            setIsEdit(true);
        }
    }, []);

    const flashDealContainerProps = {
        intl,
        dispatch,
        isOpenModal,
        isAddNewModal,
        handleOpenModal,
        handleCloseModal,
        handleSubmitFormModal,
        submitFilterProduct,
        setFilterData,
        isAddNew,
        setIsAddNew,
        handleAddNew,
        handleAddNewModal,
        setAddNewModal,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        flashDealFilter,
        setFlashDealFilter,
        flashDealEditData,
        setFlashDealEditData,
        isEdit,
        setIsEdit,
        isDublicate,
        setDublicate,
        startDealFilter,
        endtDealFilter,
        errorDate,
        setErrorDate,
        errorEndDate,
        setErrorEndDate
    };

    return <FlashDealMainView {...propsProvider(flashDealContainerProps)} />;
};

export default FlashDealContainer;
