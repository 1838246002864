import IconButton from '@mui/material/IconButton';
import StatusBox from 'components/StatusBox';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import dayjs from 'dayjs';

export default function propsProvider(props) {
    const {
        voucherList,
        setEditVoucher,
        intl,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        isOpen,
        setIsOpen,
        step,
        setStep,
        editVoucher,
        categoryList,
        paymentMethodList,
        shippingUnitList,
        control,
        watch,
        setValue,
        statusList,
        deleteVoucher,
        page,
        pageSize,
        reset,
        setPage,
        setPageSize,
        pageSizeRange,
        total,
        filterData,
        userLoginInfo,
        params
    } = props;
    const dateFormat = 'DD/MM/YYYY';

    // VoucherList Props
    const voucherColumns = [
        {
            id: 'index',
            label: intl.messages.index,
            align: 'center',
            padding: 'checkbox',
            width: '3%'
        },
        {
            id: 'name',
            label: intl.messages.voucherName,
            align: 'left',
            padding: 'normal',
            width: '25%'
        },
        {
            id: 'usage',
            label: intl.messages.usage,
            align: 'center',
            padding: 'normal',
            width: '17%'
        },
        {
            id: 'expiration',
            label: intl.messages.expiration,
            align: 'center',
            padding: 'normal',
            width: '22%'
        },
        {
            id: 'status',
            label: intl.messages.status,
            align: 'center',
            padding: 'normal',
            width: '16%'
        },
        {
            id: 'createdAt',
            label: intl.messages.createdAt,
            align: 'center',
            padding: 'normal',
            width: '11%'
        },
        {
            id: 'action',
            align: 'left',
            padding: 'normal',
            width: '7%'
        }
    ];
    const voucherListData = voucherList.map((row, index) => ({
        index: index + 1,
        name: row.name,
        usage: `${row.used_quantity}/${row.quantity}`,
        expiration: `${dayjs(row.apply_date).format(dateFormat)} - ${dayjs(row.expiry).format(dateFormat)}`,
        status: (
            <StatusBox
                status={row.extend_status}
                style={{
                    backgroundColor: row.extend_status.bgcolor,
                    color: row.extend_status.color,
                    border: `1px solid ${
                        row.extend_status.borderColor ? row.extend_status.borderColor : row.extend_status.color
                    }`,
                    borderRadius: '4px',
                    padding: '4px 4px',
                    textAlign: 'center',
                    fontSize: '13px',
                    whiteSpace: 'nowrap',
                    width: '100px'
                }}
            />
        ),
        createdAt: dayjs(row.created_at).format(dateFormat),
        action: (
            <>
                <IconButton
                    aria-label='edit'
                    onClick={() => {
                        setEditVoucher({
                            id: row.id,
                            // Step 1
                            name: row.name,
                            code: row.code,
                            quantity: row.quantity,
                            startDate: dayjs(row.apply_date),
                            endDate: dayjs(row.expiry),
                            createdAt: dayjs(),
                            status: row.status,
                            // Step 2
                            category: row.category,
                            shippingUnit: row.shipping_unit,
                            paymentMethod: row.payment_method,
                            product: row.product || [],
                            // Step 3
                            minVal: row.min_order_apply,
                            percentage: row.value_percentage === null ? '' : row.value_percentage,
                            maxVal: row.value,
                            otherInfo: row.other_info
                        });
                    }}>
                    <BorderColorOutlinedIcon fontSize='small' style={{color: '#c3c9d0'}} />
                </IconButton>
                <IconButton
                    aria-label='del'
                    onClick={() => {
                        setIsOpenDeleteModal({
                            title: intl.messages.deleteTitle,
                            id: row.id
                        });
                    }}>
                    <DeleteOutlinedIcon fontSize='small' style={{color: '#c3c9d0'}} />
                </IconButton>
            </>
        )
    }));
    // End VoucherList Props

    // Voucher Form Props
    const categoryColumns = [
        {
            id: 'select',
            label: ''
        },
        {
            id: 'product',
            label: intl.messages.product
        },
        {
            id: 'type',
            label: intl.messages.type
        },
        {
            id: 'price',
            label: intl.messages.price
        },
        {
            id: 'storage',
            label: intl.messages.storage
        }
    ];

    return {
        voucherListProps: {
            tableSectionProps: {
                page,
                pageSize,
                setPage,
                setPageSize,
                pageSizeRange,
                total,
                voucherColumns,
                voucherListData,
                intl,
                setIsOpen,
                isOpenDeleteModal,
                setIsOpenDeleteModal,
                deleteVoucher
            },
            filterSectionProps: {control, watch, setValue, reset, intl, filterData, statusList}
        },
        voucherFormProps: {
            isOpen,
            setIsOpen,
            step,
            setStep,
            intl,
            statusList,
            setEditVoucher,
            editVoucher,
            categoryList,
            paymentMethodList,
            shippingUnitList,
            categoryColumns,
            userLoginInfo,
            params,
            voucherList
        }
    };
}
