import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import NotificationBlock from 'components/NotificationBlock/NotificationBlock';
import CustomPagination from 'components/Pagination/Pagination';
import moment from 'moment';
import React from 'react';

const NotificationList = (props) => {
    const {
        intl,
        notifications = [],
        history,
        setPage,
        totalPage,
        currentPage,
        pageSizeRange,
        pageSize,
        setPageSize,
        handleReadAll,
    } = props;

    const navigateTo = (path) => {
        history.push(path);
    };

    return (
        <div className='notification-list'>
            <div className='notification-list__header'>
                <span onClick={handleReadAll}>{intl.messages.markAllAsRead}</span>
            </div>

            {notifications
                .slice()
                .sort((x, y) => {
                    return moment(x.created_at) < moment(y.created_at) ? 1 : -1;
                })
                .map((noti, idx) => (
                    <NotificationBlock
                        noti={noti}
                        key={`${noti.summary}-${idx}`}
                        size='md'
                        navigateTo={navigateTo}
                        btnLabel={intl.messages.readDetail}
                    />
                ))}

            <div className='notification-list__footer'>
                <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={currentPage} />
                <CustomPageSize
                    label={intl.messages.pagesize}
                    pageSizeRange={pageSizeRange}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default NotificationList;
