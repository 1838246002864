import { configureStore } from '@reduxjs/toolkit';
import productListReducer from 'features/PLP/productListSlice';
import productDetailsReducer from 'features/PDP/productDetailsSlice';
import authReducer from 'features/Auth/authSlice';
import signupReducer from 'features/Signup/signupSlice';
import footerReducer from 'components/Footer/footerSlice';
import headerReducer from 'components/Header/headerSlice';
import homeReducer from 'features/Home/homeSlice';
import setPasswordReducer from 'features/SetPassword/setPasswordSlice';
import cartReducer from 'features/Cart/cartSlice';
import notificationReducer from 'features/Notification/notificationSlice';
import paymentReducer from 'features/Payment/paymentSlice';
import ecomReducer from './ecomSlice';
import loaderReducer from './loaderSlice';
import adminReducer from 'features/Admin/AdminSlice';
import adminViewProductsReducer from 'features/Admin/template/subViews/Product/ViewProducts/ViewProductsSlice';
import adminViewOrdersReducer from 'features/Admin/template/subViews/Order/ViewOrders/ViewOrdersSlice';
import adminOrderDetailReducer from 'features/Admin/template/subViews/Order/OrderDetail/OrderDetailSlice';
import adminAddProductReducer from 'features/Admin/template/subViews/Product/AddProduct/AddProductSlice';
import adminConfigCategoriesReducer from 'features/Admin/template/subViews/ShopSetting/CategoryManagement/ConfigCategoriesSlice';
import userProfileReducer from 'features/UserProfile/UserProfileSlice';
import adminShopInformationReducer from 'features/Admin/template/subViews/ShopSetting/ShopInformation/ShopInformationSlice';
import addressListReducer from 'features/UserProfile/template/subViews/AddressList/addressListSlice';
import userVoucherReducer from 'features/UserProfile/template/subViews/UserVoucher/UserVoucherSlice';
import userLikeListReducer from 'features/UserProfile/template/subViews/MyLikeProduct/MyLikeProductSlice';
import userManagementReducer from 'features/Admin/template/subViews/ShopSetting/UserManagement/UserManagementSlice';
import notificationSettingReducer from 'features/Admin/template/subViews/ShopSetting/NotificationSetting/NotificationSettingSlice';
import designBannerReducer from "features/Admin/template/subViews/Design/DesignBanner/designBannerSlice";
import adminMarketingVoucherReducer from 'features/Admin/template/subViews/Marketing/Voucher/VoucherSlice';
import shopReportReducer from 'features/Admin/template/subViews/ShopReport/shopReportSlice';
import myPurchaseReducer from 'features/UserProfile/template/subViews/MyPurchase/myPurchaseSlice';

const rootReducer = {
    home: homeReducer,
    productList: productListReducer,
    productDetails: productDetailsReducer,
    auth: authReducer,
    signup: signupReducer,
    footer: footerReducer,
    header: headerReducer,
    setPassword: setPasswordReducer,
    ecom: ecomReducer,
    cart: cartReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    loader: loaderReducer,
    admin: adminReducer,
    adminViewProducts: adminViewProductsReducer,
    adminViewOrders: adminViewOrdersReducer,
    adminOrderDetail: adminOrderDetailReducer,
    adminAddProduct: adminAddProductReducer,
    adminConfigCategories: adminConfigCategoriesReducer,
    userProfile: userProfileReducer,
    myPurchase: myPurchaseReducer,
    adminShopInformation: adminShopInformationReducer,
    addressList: addressListReducer,
    userManagement: userManagementReducer,
    userVoucherList: userVoucherReducer,
    likeProductList: userLikeListReducer,
    notificationSetting: notificationSettingReducer,
    adminDesignBanner: designBannerReducer,
    adminMarketingVoucher: adminMarketingVoucherReducer,
    shopReport: shopReportReducer,
};
const store = configureStore({ reducer: rootReducer });
export default store;
