import {DatePicker, TimePicker} from 'antd';
import React, {Fragment, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import moment from 'moment';
import dayjs from 'dayjs';

const GroupInputDateTime = (props) => {
    const dateFormat = 'DD/MM/YYYY';
    const timeFormat = 'HH:mm';

    const {label, control, groupInputData = [], disabledHours, onChangeDate, onChangeTime, fromToday = false} = props;
    const [borderStatus, setBorderStatus] = useState('none');

    const [dateValue, setDateValue] = useState();
    const [timeValue, setTimeValue] = useState();

    const mouseOverGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('hover');
        }
    };
    const mouseOutGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('none');
        }
    };

    const focusGroupInput = () => setBorderStatus('focus');
    const blurGroupInput = () => setBorderStatus('none');

    const disabledDateTime = () => ({
        disabledHours: () => disabledHours
    });

    const setOnChangeDate = (event) => {
        if (event) {
            onChangeDate(moment(new Date(event)).format('YYYY/MM/DD'));
            setDateValue(dayjs(moment(new Date(event)).format('YYYY/MM/DD')));
        }
    };
    const setOnChangeTime = (event) => {
        if (event) {
            onChangeTime(event?.format('HH:mm'));
            setTimeValue(dayjs(event, 'HH:mm'));
        }
    };

    const disabledFromToDay = (current) => {
        if (fromToday) {
            return current && current < dayjs().add(-1, 'day');
        }
    };

    useEffect(() => {
        if (groupInputData.length) {
            groupInputData.map((e) => {
                if (e.type === 'time') {
                    e.defaultValue && setTimeValue(dayjs(e.defaultValue, 'HH:mm'));
                    e.defaultValue && onChangeTime(e.defaultValue);
                }
                if (e.type === 'date') {
                    e.defaultValue && setDateValue(dayjs(e.defaultValue));
                    e.defaultValue && onChangeDate(moment(new Date(e.defaultValue)).format('YYYY/MM/DD'));
                }
            });
        }
    }, []);

    return (
        <div className='group-input' onMouseEnter={mouseOverGroupInput} onMouseLeave={mouseOutGroupInput}>
            <fieldset className={borderStatus}>
                <legend>
                    <span>{label}</span>
                </legend>
                <div className='group-input__container'>
                    {groupInputData.map((inputData, idx) => (
                        <Fragment key={inputData.name}>
                            <Controller
                                control={control}
                                name={inputData.name}
                                render={({field: {onBlur, onChange, ...restField}}) => {
                                    const blurHandle = (e) => {
                                        blurGroupInput();
                                        onBlur(e);
                                    };
                                    const baseInputProps = {
                                        className: 'input-item',
                                        onClick: focusGroupInput,
                                        onBlur: blurHandle
                                    };

                                    return inputData.type === 'date' ? (
                                        <DatePicker
                                            {...restField}
                                            {...baseInputProps}
                                            placeholder={inputData.placeholder}
                                            value={dateValue}
                                            // defaultValue={inputData.defaultValue && dayjs(inputData.defaultValue)}
                                            format={dateFormat}
                                            onChange={(e) => {
                                                setOnChangeDate(e);
                                                onChange(e);
                                            }}
                                            disabledDate={disabledFromToDay}
                                        />
                                    ) : (
                                        <TimePicker
                                            {...restField}
                                            {...baseInputProps}
                                            placeholder={inputData.placeholder}
                                            value={timeValue}
                                            // defaultValue={
                                            //     inputData.defaultValue && dayjs(inputData.defaultValue, 'HH:mm')
                                            // }
                                            format={timeFormat}
                                            disabledTime={disabledDateTime}
                                            onChange={(e) => {
                                                setOnChangeTime(e);
                                                onChange(e);
                                            }}
                                        />
                                    );
                                }}
                            />
                            {idx < groupInputData.length - 1 && <div className='input-divider'></div>}
                        </Fragment>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};

export default GroupInputDateTime;
