import React, {useMemo, useState} from 'react';
import {Checkbox} from '@mui/material';
import Config from 'configuration';
import {FormattedNumber} from 'react-intl';

const ProductRow = (props) => {
    const {data, handleCheckRow, selectedProducts, intl} = props;
    const {id, default_image, name, extend_category, extend_product_type, extend_type, price, quantity} = data;
    const [expanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!expanded);

    const displayProductRow = useMemo(() => {
        const getProductTypes = expanded ? extend_product_type : extend_product_type.slice(0, 2);
        const result = {
            type: [],
            price: [],
            quantity: []
        };

        if (extend_product_type.length === 0) {
            result.type.push(null);
            result.price.push(price);
            result.quantity.push(quantity);
        } else {
            if (extend_product_type.length > 0 && extend_type.length > 0) {
                getProductTypes.forEach((ptype) => {
                    const {type_id, price, quantity} = ptype;
                    const typeName = Array(type_id.length).fill('');

                    type_id.forEach((id) => {
                        const type = extend_type.find((etype) => etype.id === id);

                        if (type) {
                            typeName[type.sort_order] = type.name;
                        }
                    });

                    result.type.push(typeName.join(' '));
                    result.price.push(price);
                    result.quantity.push(quantity);
                });
            }
        }

        return result;
    }, [expanded]);

    const isDataOverflowed = extend_product_type.length > 2;

    return (
        <div className='voucher-product-row'>
            <div className='data-col data-col-0'>
                <Checkbox
                    checked={selectedProducts.includes(id)}
                    onChange={(e) => handleCheckRow(id, e.target.checked)}
                    disableRipple
                />
            </div>
            <div className='data-col data-col-1'>
                <div className='cell-name d-flex gap-2'>
                    <img src={`${Config.apiConfig.endPoint}${default_image}`} alt='' />
                    <div className='cell-name__detail d-flex flex-column gap-2'>
                        <p>{name}</p>
                        <span>{extend_category.name}</span>
                    </div>
                </div>
            </div>

            {Object.entries(displayProductRow).map(([key, value], idx) => (
                <div key={idx} className={`data-col data-col-${idx + 2}`}>
                    <div className='d-flex flex-column gap-3' style={{flex: 1}}>
                        {value.map((data, i) => (
                            <span className='cell-row' key={i}>
                                {data ? (
                                    idx === 1 ? (
                                        <FormattedNumber value={data} currency='vnd' style='currency' />
                                    ) : (
                                        data
                                    )
                                ) : (
                                    '--'
                                )}
                            </span>
                        ))}
                        {isDataOverflowed && idx === 0 && (
                            <div className='cell-expand' onClick={toggleExpand}>
                                {expanded ? intl.messages.readLess : intl.messages.readMore}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductRow;
