import {Download} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';
import {MenuItem, MenuList} from '@mui/material';
import {Calendar} from 'react-calendar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {capitalizeFirstLetter} from 'utils/formatString';
import SelectPopover from 'components/SelectPopover/SelectPopover';

const ShopReportTime = (props) => {
    const {
        intl,
        filterValue,
        handleFilterValue,
        timeSelectOptions,
        handleMouseEnterFilter,
        selectedFilterIndex,
        hoverFilterIndex,
        filterTimePicker,
        dateValue,
        handleDateValueChange
    } = props;

    return (
        <section className='shop-report__time'>
            <div className='d-flex align-items-center gap-2'>
                <SelectPopover
                    popoverId='time-filter'
                    popoverProps={{className: 'time-menu'}}
                    label={intl.messages.time}
                    displayValue={
                        <div className='select-value'>
                            <span className='select-value--bold'>
                                {capitalizeFirstLetter(timeSelectOptions[selectedFilterIndex].value)}:{' '}
                            </span>
                            <span>{filterValue}</span>
                        </div>
                    }>
                    <MenuList>
                        {timeSelectOptions.map((opt, idx) => (
                            <MenuItem
                                key={opt.id}
                                onMouseEnter={() => handleMouseEnterFilter(idx)}
                                aria-selected={selectedFilterIndex === idx}
                                className='d-flex align-items-center justify-content-between'>
                                <span>{opt.value}</span>

                                {idx > 3 && <KeyboardArrowRightIcon />}
                            </MenuItem>
                        ))}
                    </MenuList>

                    <div className='time-picker'>
                        {hoverFilterIndex < 4 ? (
                            <div
                                aria-selected={selectedFilterIndex === hoverFilterIndex}
                                className='time-picker--single'
                                onClick={() =>
                                    handleFilterValue(hoverFilterIndex, filterTimePicker[hoverFilterIndex].value)
                                }>
                                {filterTimePicker[hoverFilterIndex].value}
                            </div>
                        ) : (
                            <Calendar
                                maxDate={new Date()}
                                minDetail={'decade'}
                                view={timeSelectOptions[hoverFilterIndex].type}
                                locale='vi'
                                nextLabel={<KeyboardArrowRightIcon />}
                                next2Label={<KeyboardDoubleArrowRightIcon />}
                                prevLabel={<KeyboardArrowLeftIcon />}
                                prev2Label={<KeyboardDoubleArrowLeftIcon />}
                                value={dateValue}
                                onChange={(e) =>
                                    handleDateValueChange(e, hoverFilterIndex, hoverFilterIndex === 5 ? 'week' : 'date')
                                }
                                onClickMonth={(e) => handleDateValueChange(e, hoverFilterIndex, 'month')}
                                onClickYear={(e) => handleDateValueChange(e, hoverFilterIndex, 'year')}
                                className={`${hoverFilterIndex === 5 ? 'calendar-week' : ''}`}
                                showWeekNumbers={hoverFilterIndex === 5}
                            />
                        )}
                    </div>
                </SelectPopover>
                <CustomButton variant='half-contained'>
                    <Download />
                    {intl.messages.downloadData}
                </CustomButton>
            </div>
        </section>
    );
};

export default ShopReportTime;
