import React, { useLayoutEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';
import CategoryActionRow from './CategoryActionRow';
import CategoryCard from './CategoryCard';
import CustomButton from 'components/CustomButton/CustomButton';

const CategoryList = (props) => {
    const {
        categoryList,
        intl,
        setShowAction,
        setRows,
        setSelectCategory,
        selectCategory,
        addChildCategory,
        updateCategory,
        deleteCategory,
        showAction,
        addRows,
        rows,
        removeRow,
        updateRow,
        isFormChanged,
        setIsFormChanged
    } = props;

    const [isCategoryInherited, setIsCategoryInherited] = useState(false);

    useLayoutEffect(() => {
        setIsCategoryInherited(selectCategory.isInherited);
    }, [selectCategory]);

    return (
        <div className='cate-container row m-0 gap-2'>
            <div className='col-md-3 category_list p-0'>
                {categoryList.map((category) => (
                    <CategoryCard
                        intl={intl}
                        key={category.id}
                        className='category_list_card'
                        category={category}
                        setShowAction={setShowAction}
                        setRows={setRows}
                        setSelectCategory={setSelectCategory}
                        selectCategory={selectCategory}
                        addChildCategory={addChildCategory}
                        updateCategory={updateCategory}
                        deleteCategory={deleteCategory}
                        setIsFormChanged={setIsFormChanged}
                    />
                ))}
            </div>
            {showAction && (
                <div className='col category_action p-0'>
                    <div className='category_action_header'>
                        <span
                            className='category_action_header_title'>{intl.messages.editAttribute}</span>
                        <CustomButton className='category_action_header_button' onClick={addRows} variant='text'>
                            <AddIcon />
                            {intl.messages.addAttribute}
                        </CustomButton>
                    </div>
                    <div className='category_action_main'>
                        {selectCategory.parentAttributes && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disableRipple
                                        sx={{ paddingRight: '4px', transform: 'scale(0.9)' }}
                                        checked={isCategoryInherited}
                                        onChange={(e) => {
                                            setIsFormChanged(true);
                                            setIsCategoryInherited(e.target.checked);
                                            if (e.target.checked) {
                                                let data = selectCategory.parentAttributes.map((item) => {
                                                    item.disabled = true;
                                                    return item;
                                                });
                                                setRows((prevRows) => [...data, ...prevRows]);
                                            } else {
                                                setRows((prevRows) => prevRows.filter((item) => !item.disabled));
                                            }
                                        }}
                                    />
                                }
                                label={intl.messages.inheritFromParent}
                            />
                        )}

                        {rows.length > 0 && (
                            <CategoryActionRow rows={rows} removeRow={removeRow} updateRow={updateRow} intl={intl} />
                        )}
                    </div>
                    {isFormChanged && (
                        <div className='category_action_footer'>
                            <div className='category_action_footer_button_group'>
                                <CustomButton
                                    variant='text'
                                    onClick={() => {
                                        setIsFormChanged(false);
                                        setShowAction(false);
                                    }}>
                                    {intl.messages.cancel}
                                </CustomButton>
                                <CustomButton
                                    onClick={() => {
                                        updateCategory(rows);
                                    }}>
                                    {intl.messages.save}
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CategoryList;
