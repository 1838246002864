import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './ChatBoxPropsProvider';
import ChatBoxMainView from './template/ChatBoxMainView';
import {firestore} from 'firebase-setup/firebase';
import {useSelector} from 'react-redux';
import {authSelector} from 'app/selectors';
import {getAllMyChatRooms, markAsRead, pinChat} from './chatBoxSlice';

const ChatBoxContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();
    const auth = useSelector(authSelector);
    const {userLoginInfo, permission} = auth;
    const [chatRooms, setChatRooms] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [chatBoxParams, setChatBoxParams] = useState({
        fullName: '',
        is_all: true,
        is_pinned: false,
        is_read: false,
        is_unread: false
    });
    const [unreadCount, setUnreadCount] = useState(0);
    const isAdmin = permission && (permission.includes('SYS_ADMIN') || permission.includes('Chat'));

    const navigateTo = async (path) => {
        if (path) {
            history.push(path);
        }
    };

    const handleSearch = () => {
        setChatBoxParams((prev) => ({
            ...prev,
            fullName: searchValue
        }));
    };

    const handleFilter = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        const newParams = {...chatBoxParams};
        if (name === 'is_all') {
            if (!checked) {
                if (newParams.is_pinned || newParams.is_read || newParams.is_unread) newParams.is_all = checked;
                else delete newParams.is_all;
            } else {
                newParams.is_all = checked;
            }
        } else {
            if(!checked) {
                let activeParamsCount = 0;
                if(newParams.is_pinned) activeParamsCount++;
                if(newParams.is_read) activeParamsCount++;
                if(newParams.is_unread) activeParamsCount++;
                if(!newParams.is_all) {
                    if(activeParamsCount === 1) {
                        delete newParams.is_all;
                        // newParams.is_all = true;
                    }
                }
                newParams[name] = checked
            } else {
                if(newParams.is_all === undefined) newParams.is_all = false;
                newParams[name] = checked;
            }
        }
        setChatBoxParams(newParams);
    };

    const handleMarkAsRead = async (id, value) => {
        const res = await dispatch(
            markAsRead({
                chatRoomId: id,
                isRead: !value
            })
        ).unwrap();
        const {success} = res;
        if (success) fetchChatRoomList();
    };

    const handlePinChat = async (id, value) => {
        const res = await dispatch(
            pinChat({
                chatRoomId: id,
                isPinned: !value
            })
        ).unwrap();
        const {success} = res;
        if (success) fetchChatRoomList();
    };

    const fetchChatRoomList = async () => {
        const res = await dispatch(getAllMyChatRooms(chatBoxParams)).unwrap();
        const {success} = res;
        if (success) {
            setChatRooms(res?.collection);
            let count = 0;
            res?.collection?.forEach((item) => {
                const {unRead_count} = item;
                if (unRead_count > 0) count++;
            });
            setUnreadCount(count); 
        }
    };

    useEffect(() => {
        if (permission && permission.includes('SYS_ADMIN')) fetchChatRoomList();
    }, [chatBoxParams, permission]);

    const chatBoxProps = {
        ...props,
        intl,
        navigateTo,
        dispatch,
        firestore,
        userLoginInfo,
        isAdmin,
        chatRooms,
        fetchChatRoomList,
        searchValue,
        setSearchValue,
        handleSearch,
        chatBoxParams,
        handleFilter,
        handleMarkAsRead,
        handlePinChat,
        unreadCount
    };

    return <ChatBoxMainView {...propsProvider(chatBoxProps)} />;
};

export default ChatBoxContainer;
