import React from 'react';
import {oval, ornamentMobile, ovalMobile, frame2746, ornament_setPw2x, ErrorMark} from 'assets/images';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import {Controller, useForm} from 'react-hook-form';
import {FormControl, FormHelperText} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import CustomButton from 'components/CustomButton/CustomButton';
import {emailInputValidator} from 'utils/validator';
import {useIntl} from 'react-intl';

const ForgetPassword = ({handleInvalidSubmit, handleForgetPwValid, navigateTo, isSubmitted}) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm({
        defaultValues: {
            email: ''
        }
    });
    const watchAllFields = watch();
    const checkButtonDisabled = Object.values(watchAllFields).some((field) => field.length === 0);
    const intl = useIntl();

    return (
        <AuthLayout src1={ornament_setPw2x} src2={frame2746} src3={oval} src4={ornamentMobile} src5={ovalMobile}>
            <AuthModal>
                <div className='forget-password'>
                    <div className='text-center forget-password-header'>
                        <h2>{intl.messages.forgetPwLabel}</h2>
                        {!isSubmitted ? (
                            <p>
                                {intl.messages.sendEmailPassword},
                                <br /> {intl.messages.enterEmail}:
                            </p>
                        ) : (
                            <p>{intl.messages.checkEmail}!</p>
                        )}
                    </div>
                    <form
                        method='post'
                        action='#'
                        className='w-100'
                        onSubmit={handleSubmit(handleForgetPwValid, handleInvalidSubmit)}
                        noValidate>
                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='email'
                                control={control}
                                rules={{
                                    validate: (value) => emailInputValidator(value) || `${intl.messages.invalidEmail}!`
                                }}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label='Email'
                                        placeholder={intl.messages.emailRegisterInputLabel}
                                        valid={!errors['email']}
                                        endicon={!errors['email'] ? null : <ErrorMark />}
                                        disabled={isSubmitted}
                                    />
                                )}
                            />
                            {errors['email'] && <FormHelperText error>{errors['email'].message}</FormHelperText>}
                        </FormControl>

                        <div className='w-100 mb-2'>
                            {!isSubmitted ? (
                                <>
                                    <CustomButton type='submit' disabled={checkButtonDisabled} fullwidth>
                                        {intl.messages.send}
                                    </CustomButton>
                                    <button className='cancel-btn' onClick={() => navigateTo(pagePath.loginUrl)}>
                                        {intl.messages.cancel}
                                    </button>
                                </>
                            ) : (
                                <CustomButton type='submit' disabled={true} fullwidth>
                                    {intl.messages.haveSentLink}
                                </CustomButton>
                            )}
                        </div>
                    </form>
                </div>
            </AuthModal>
        </AuthLayout>
    );
};
export default ForgetPassword;
