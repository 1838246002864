import React from 'react';
import {reFormatNumber} from 'utils/reFormatNumber';
import ValidatingInput from '../ValidatingInput/ValidatingInput';

const BasicForm = ({intl, control, errors, getValues}) => {
    const basicSalesInformationInputs = [
        {
            name: 'unitPrice',
            label: intl.messages.price,
            isPrice: true,
            rules: {
                required: {
                    value: true,
                    message: intl.messages.requiredField
                },
                validate: (value) => reFormatNumber(value) >= 1000
            }
        },
        {
            name: 'promotionalPrice',
            label: intl.messages.promotionalPrice,
            isPrice: true,
            rules: {
                validate: {
                    greaterThan1000: (value) => {
                        const curValue = reFormatNumber(value);
                        return curValue ? curValue >= 1000 : true;
                    },

                    lessThanUnitPrice: (value) => {
                        const curValue = reFormatNumber(value);
                        const unitPriceValue = reFormatNumber(getValues('unitPrice'));

                        return (curValue ? curValue <= unitPriceValue : true) || intl.messages.lessThanUnitPrice;
                    }
                }
            }
        },
        {
            name: 'quantity',
            label: intl.messages.storage,
            isPrice: false,
            type: 'number',
            rules: {
                required: {
                    value: true,
                    message: intl.messages.requiredField
                }
            }
        }
    ];

    return (
        <div className='d-flex gap-2'>
            {basicSalesInformationInputs.map((input) => (
                <ValidatingInput
                    control={control}
                    inputValue={input}
                    key={input.name}
                    helperText={input.isPrice ? intl.messages.helperTextPrice : ''}
                    errors={errors}
                />
            ))}
        </div>
    );
};

export default BasicForm;
