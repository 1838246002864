import {Dialog, DialogActions, DialogContent, DialogTitle, Drawer} from '@mui/material';
import {AddImage, RightArrow, ShieldQuestion} from 'assets/images';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import {close} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';

const ReasonRefund = (props) => {
    const {
        intl,
        lstReason,
        handleSelectReason,
        handleSubmitReason,
        isOpenReasonModal,
        openReasonModal,
        closeReasonModal,
        reasonRefund,
        handleUpload,
        handleClick,
        fileInputRef,
        productImages,
        handleDeleteProductImage
    } = props;

    const modalTitle = (
        <>
            <span>{intl.messages.reason}</span>
            <button className='dialog-title__close'>
                <img
                    src={close}
                    alt='close-icon'
                    onClick={(e) => {
                        e.preventDefault();
                        closeReasonModal();
                    }}
                />
            </button>
        </>
    );

    const modalContent = (
        <>
            {lstReason.map((item) => (
                <div className='refund-content-reason' key={item.index}>
                    <CustomCheckbox
                        disableRipple
                        color='primary'
                        id={item.code}
                        checked={item.checked}
                        onChange={(e) => handleSelectReason(e, item)}
                        inputProps={{
                            'aria-label': 'select all data'
                        }}
                    />
                    <label htmlFor={item.code}>{item.reason}</label>
                </div>
            ))}
        </>
    );

    const modalActions = (
        <>
            <CustomButton className='d-block d-sm-none' variant='text' onClick={closeReasonModal}>
                {intl.messages.cancel}
            </CustomButton>
            <CustomButton variant='contained' onClick={handleSubmitReason}>
                {intl.messages.btnModalSave}
            </CustomButton>
        </>
    );

    return (
        <>
            <div className='refund-reason'>
                <div className='refund-reason-title'>
                    <div>
                        <div className='refund-section-title'>{intl.messages.reason}</div>
                        <div className='fs-13'>{reasonRefund ? reasonRefund : intl.messages.pldReason}</div>
                    </div>
                    <div className='btn-reason' onClick={openReasonModal}>
                        <span>{intl.messages.btnReason}</span>
                        <RightArrow />
                    </div>
                </div>
                <div className='refund-reason-hint'>
                    <ShieldQuestion />
                    <span>{intl.messages.attachmentHint}</span>
                </div>
                <div className='refund-reason-attachment-wrapper'>
                    {productImages.map((image, index) => (
                        <PreviewImage
                            key={index}
                            image={image.url}
                            onDeleteImage={handleDeleteProductImage.bind(this, index)}
                            height='112px'
                            width='112px'
                            className='mr-2'
                        />
                    ))}
                    {productImages.length < 6 && (
                        <div className='reason-input' onClick={handleClick}>
                            <AddImage />
                            <span>{intl.messages.addImage}</span>
                            <input
                                ref={fileInputRef}
                                className='input-file'
                                type='file'
                                id='imageInput'
                                accept='image/*,video/*'
                                onChange={(e) => handleUpload(e)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={isOpenReasonModal}
                onClose={closeReasonModal}
                className='dialog dialog-refund d-none d-sm-block'>
                <DialogTitle className='dialog-title' id='reason-modal'>
                    {modalTitle}
                </DialogTitle>
                <DialogContent className='dialog-content dialog-refund-content'>{modalContent}</DialogContent>
                <DialogActions>{modalActions}</DialogActions>
            </Dialog>
            <Drawer
                anchor='bottom'
                open={isOpenReasonModal}
                onClose={closeReasonModal}
                className='fixed-card__drawer d-block d-sm-none'>
                <div className='drawer-modal update-modal'>
                    <div className='drawer-modal__header'>{modalTitle}</div>

                    <div className='drawer-modal__body refund-body'>{modalContent}</div>

                    <div className='drawer-modal__footer d-flex justify-content-end'>
                        <div className='d-flex align-items-center gap-2'>{modalActions}</div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default ReasonRefund;
