import {DatePicker} from 'antd';
import React, {Fragment, useState} from 'react';
import {Controller} from 'react-hook-form';

const GroupInput = (props) => {
    const dateFormat = 'DD/MM/YYYY';

    const {label, control, groupInputData = [], type = 'number', disabledDate = null} = props;
    const [borderStatus, setBorderStatus] = useState('none');

    const mouseOverGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('hover');
        }
    };
    const mouseOutGroupInput = () => {
        if (borderStatus !== 'focus') {
            setBorderStatus('none');
        }
    };

    const focusGroupInput = () => setBorderStatus('focus');
    const blurGroupInput = () => setBorderStatus('none');

    return (
        <div className='group-input' onMouseEnter={mouseOverGroupInput} onMouseLeave={mouseOutGroupInput}>
            <fieldset className={borderStatus}>
                <legend>
                    <span>{label}</span>
                </legend>
                <div className='group-input__container'>
                    {groupInputData.map((inputData, idx) => (
                        <Fragment key={inputData.name}>
                            <Controller
                                control={control}
                                name={inputData.name}
                                render={({field: {onBlur, ...restField}}) => {
                                    const blurHandle = (e) => {
                                        blurGroupInput();
                                        onBlur(e);
                                    };
                                    const baseInputProps = {
                                        className: 'input-item',
                                        onClick: focusGroupInput,
                                        onBlur: blurHandle
                                    };

                                    return type === 'date' ? (
                                        <DatePicker
                                            {...restField}
                                            {...baseInputProps}
                                            placeholder={inputData.placeholder}
                                            format={dateFormat}
                                            disabledDate={disabledDate}
                                        />
                                    ) : (
                                        <input {...restField} {...inputData} {...baseInputProps} />
                                    );
                                }}
                            />
                            {idx < groupInputData.length - 1 && <div className='input-divider'></div>}
                        </Fragment>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};

export default GroupInput;
