import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import { useDispatch } from 'react-redux';
import ViewProductsContainer from './ViewProductsContainer';
import i18nLabels from './ViewProduct.i18n';

const ViewProducts = (props) => {
    const dispatch = useDispatch();

    const viewProductProps = {
        ...props,
        dispatch,
        i18nLabels
    };

    return <DecorateContainer {...viewProductProps} childComponent={ViewProductsContainer} />;
};

export default ViewProducts;
