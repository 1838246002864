import {Tooltip} from '@mui/material';
import {InfoMark, SliderRightArrow} from 'assets/images';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import React, {useState} from 'react';
import {Controller} from 'react-hook-form';

const PriceAccordion = ({price, control}) => {
    const {label, value, items, tooltipLabel, name} = price;
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className='price-accordion'>
            <div className='price-accordion__header'>
                <div className='header-checkbox'>
                    <Controller
                        name={name}
                        control={control}
                        render={({field: {value, ...restField}}) => (
                            <CustomCheckbox {...restField} checked={value} disableRipple />
                        )}
                    />
                    <span>{label}</span>
                </div>

                <div className='header-control'>
                    <span>
                        {value}{' '}
                        <Tooltip arrow title={tooltipLabel}>
                            <InfoMark />
                        </Tooltip>
                    </span>
                    <div className='header-control__toggle' onClick={toggleExpand} aria-expanded={isExpanded}>
                        <img src={SliderRightArrow} alt='toggle expand' />
                    </div>
                </div>
            </div>

            {items && (
                <div className='price-accordion__content' aria-expanded={isExpanded}>
                    {items.map((item) => (
                        <div className='content-item' key={item}>
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PriceAccordion;
