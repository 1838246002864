import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import productApi from 'api/productApi';
import serviceRequest from 'app/serviceRequest';
import categoryApi from 'api/categoryApi';
import cartProductApi from 'api/cartProductApi';

const initialState = () => {
    return {
        likeProductList: [],
        categoryList: []
    };
};

export const getCategoryList = createAsyncThunk('likeProductList/getCatergoryList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const getlikeProductList = createAsyncThunk('likeProductList/getlikeProductList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getLikeProduct,
        payload: data
    });
});
export const deleteCartProducts = createAsyncThunk('likeProductList/deleteProducts', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.deleteCartProducts,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

export const deleteMyLikeProducts = createAsyncThunk('likeProductList/deleteMyLikeProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.deleteFavCartProducts1,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

const likeProductList = createSlice({
    name: 'likeProductList',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(getlikeProductList.fulfilled, (state, action) => {
                state.likeProductList = action.payload?.data;
            })
            .addCase(getCategoryList.fulfilled, (state, action) => {
                state.categoryList = action.payload?.data;
            });
    }
});

const {reducer} = likeProductList;
export default reducer;
