export default function propsProvider(props) {
	const { 
		intl,
		navigateTo, 
		enableAutoChat,
		enableQuickChat,
		contentAutoChat,
		handleChangeAutoChat,
		handleChangeQuickChat,
		handleSubmitAutoChat,
		handleCloseAutoChat,
		tableChat,
		editQuickChat,
		updateQuickChat,
        cancelQuickChat,
        deleteQuickChat,
		handleAddNewRow,
		editAutoChat
	} = props;

	const transformTableData = []

	tableChat?.forEach(element => {
		if(element.type=='fast'){
			transformTableData.push(element)
		}
	})

	const headCells = [
		{
			id: intl.messages.number,
			align: 'center',
			label: intl.messages.number,
			disablePadding: true,
		},
		{
			id: intl.messages.content,
			align: 'left',
			disablePadding: true,
			label: intl.messages.content
		}
	]

	return { 
		tableQuickChatListProps:{
			navigateTo,
			headCells,
			transformTableData,
			editQuickChat,
			updateQuickChat,
			cancelQuickChat,
			deleteQuickChat,
			handleAddNewRow,
			intl
		},
		intl,
		enableAutoChat,
		enableQuickChat,
		contentAutoChat,
		handleChangeAutoChat,
		handleChangeQuickChat,
		handleSubmitAutoChat,
		handleCloseAutoChat,
		editAutoChat
	 };
}
