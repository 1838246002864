import {getMyInfo} from 'features/Auth/authSlice';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './homePropsProvider';
import {getBanners, getCategories, getHotDeals, getRecommendList} from './homeSlice';
import HomeMainView from './template/HomeMainView';
import {createInvoiceToken} from 'features/Payment/paymentSlice';
import {storageKey} from 'configuration/storageKey';

export default function HomeContainer(props) {
    /*-----TODO-----
- To declare state, props, func
- Call API
- To setup data for views
*/
    const intl = useIntl();
    const {dispatch, home, i18nLabels, auth, history} = props;
    const [currentRecommendList, setCurrentRecommendList] = useState([]);
    const {isLogin} = auth;

    const navigateTo = (path) => {
        history.push(path);
    };

    useEffect(() => {
        dispatch(getRecommendList());
        // dispatch(getHotDeals());
        dispatch(getCategories());
        dispatch(getBanners());
    }, []);

    useEffect(() => {
        if (isLogin) {
            dispatch(getMyInfo());

            const eInvoiceToken = sessionStorage.getItem(storageKey.eInvoice);
            const getInvoiceToken = async () => {
                await dispatch(createInvoiceToken());
            };

            if (!eInvoiceToken) {
                getInvoiceToken();
            }
        }
    }, [isLogin]);

    useEffect(() => {
        if (home.recommendDetails?.length > 0) {
            setCurrentRecommendList(home.recommendDetails.slice(0, 16));
        }
    }, [home.recommendDetails]);

    const homeContainerProps = {
        ...props,
        intl,
        navigateTo,
        home,
        dispatch,
        i18nLabels,
        auth,
        currentRecommendList,
        setCurrentRecommendList
    };

    return <HomeMainView {...propsProvider(homeContainerProps)} />;
}
