import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './categoryManagementPropsProvider';
import CategoryManagementMainView from './template/CategoryManagementMainView';
import {useSelector} from 'react-redux';
import {adminConfigCategoriesSelector} from 'app/selectors';
import {getCategories, addCategory, updateCategoryById, deleteCategoryById} from './ConfigCategoriesSlice';
import {Toast} from 'utils/toast';
import Events from 'utils/Events';
import {eventsKey} from 'configuration/storageKey';

const CategoryManagementContainer = (props) => {
    const {dispatch, history} = props;
    const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
    const intl = useIntl();
    const adminConfigCategories = useSelector(adminConfigCategoriesSelector);
    const {categories, newCategory} = adminConfigCategories;

    const [isFormChanged, setIsFormChanged] = useState(false);
    const [rows, setRows] = useState([]);
    const [showAction, setShowAction] = useState(false);
    const [selectCategory, setSelectCategory] = useState({});

    const addRows = (e) => {
        if (e.clientX !== 0) {
            const data = {
                name: '',
                required: false,
                disabled: false
            };
            setRows((prevRows) => [...prevRows, data]);
            setIsFormChanged(true);
        }
    };
    const removeRow = (index) => {
        const dataRow = [...rows];
        dataRow.splice(index, 1);
        setRows(dataRow);
        setIsFormChanged(true);
    };

    const updateRow = (value, field, index) => {
        let dataRows = [...rows];
        dataRows[index][field] = value;
        setRows(dataRows);
        setIsFormChanged(true);
    };

    const addChildCategory = async (data, isAdd = true) => {
        try {
            if (isAdd) {
                const res = await dispatch(addCategory({name: data.name, parent_id: data.parent_id})).unwrap();
                if (!res.success) {
                    Toast(false, intl.messages.actionFailure);
                    return;
                }
                Toast(true, intl.messages.actionSuccess);

                updateGetCategory();
            } else {
                const res = await dispatch(updateCategoryById({data: {name: data.name}, id: data.id})).unwrap();
                if (!res.success) {
                    Toast(false, intl.messages.actionFailure);
                    return;
                }
                Toast(true, intl.messages.actionSuccess);

                updateGetCategory();
            }
        } catch (e) {
            Toast(false, intl.messages.actionFailure);
        }
    };

    const updateCategoryRecursive = async (data, category) => {
        const params = {attributes: data.map((row) => JSON.stringify(row))};
        await dispatch(updateCategoryById({id: category.id, data: params})).unwrap();

        if (category.children) {
            for (const child of category.children) {
                if (child.isInherited) {
                    const inheritedAttributes = data.map((attr) => {
                        return {
                            ...attr,
                            disabled: true
                        };
                    });
                    const newData = [...inheritedAttributes];
                    child.attributes.forEach((attr) => {
                        if (!attr.disabled) {
                            newData.push(attr);
                        }
                    });

                    await updateCategoryRecursive(newData, child);
                }
            }
        }
    };

    const updateCategory = async (data) => {
        try {
            await updateCategoryRecursive(data, selectCategory);
            Toast(true, intl.messages.editSuccess);
            updateGetCategory();
        } catch (err) {
            Toast(false, intl.messages.editCategoryError);
        }
    };

    const updateGetCategory = () => {
        Events.emit(eventsKey.updateCategoryList);
    };

    const deleteCategory = async ({payload}) => {
        let res = await dispatch(deleteCategoryById(payload[0])).unwrap();
        if (!res.success) {
            Toast(false, intl.messages.deleteError);
            return false;
        }

        updateGetCategory();
        Toast(true, intl.messages.deleteSuccess);
        return true;
    };

    useEffect(() => {
        const updateCategoryHandle = Events.addListener(eventsKey.updateCategoryList, async () => {
            dispatch(getCategories());
        });

        updateGetCategory();

        return () => updateCategoryHandle.remove();
    }, []);

    const navigateTo = (path) => {
        history.push(path);
    };

    const addCategoryHandler = () => {
        setIsOpenCategoryModal(true);
    };

    const closeCategoryModal = () => setIsOpenCategoryModal(false);

    const categoryManagementContainerProps = {
        dispatch,
        navigateTo,
        intl,
        isOpenCategoryModal,
        addCategoryHandler,
        categories,
        newCategory,
        addCategory,
        closeCategoryModal,
        setShowAction,
        setRows,
        setSelectCategory,
        selectCategory,
        addChildCategory,
        updateCategory,
        deleteCategory,
        showAction,
        addRows,
        rows,
        removeRow,
        updateRow,
        updateGetCategory,
        isFormChanged,
        setIsFormChanged
    };

    return <CategoryManagementMainView {...propsProvider(categoryManagementContainerProps)} />;
};

export default CategoryManagementContainer;
