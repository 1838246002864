import Box from '@mui/material/Box';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import TabPanel from 'components/TabPanel/TabPanel';
import { useState } from 'react';
import AccountSettingMainView from './subViews/AccountSetting/template/AccountSettingMainView';
import PasswordSetting from './subViews/PasswordSetting/PasswordSetting';

const ProfileMainView = (props) => {
    const { passwordSettingProps, accountSettingProps, intl } = props;

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='profile'>
            <div className='profile-title'>
                <h3 className='title'>{intl.messages.myProfile}</h3>
                <span className='sub-title'>{intl.messages.informationManagement}</span>
            </div>

            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                <Tab label={intl.messages.accountSetting} />
                <Tab label={intl.messages.passwordSetting} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <AccountSettingMainView {...accountSettingProps} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PasswordSetting {...passwordSettingProps} />
            </TabPanel>

            <div id='captcha-container'></div>
        </div>
    );
};

export default ProfileMainView;
