import React from 'react';
import Banner from './subViews/Banner/Banner';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import Category from './subViews/Category/Category';
import SwiperFlashDeal from 'components/SwiperFlashDeal/SwiperFlashDeal';
import ExpandProductList from 'components/ExpandProductList/ExpandProductList';

export default function HomeMainView(props) {
    const { bannerProps, hotDealProps, categoryProps, recommendProps } = props;

    return (
        <div className='home'>
            <WrapperContainer bodyContent>
                <Banner {...bannerProps} />

                <div className='home-wrapper'>
                    <Category {...categoryProps} />
                    {/* {hotDealProps.flashDealList?.length !== 0 && <SwiperFlashDeal {...hotDealProps} />} */}
                    <ExpandProductList {...recommendProps} />

                </div>
            </WrapperContainer>
        </div>
    );
}
