const i18nLabels = {
    vi: {
        searchFilterLabel: 'Bộ lọc tìm kiếm',
        allSubCategories: "Tất cả danh mục",
        subSubCategories: 'Theo danh mục',
        region: 'Khu vực',
        priceRange: 'Khoảng giá',
        rating: 'Đánh giá',
        readMore: 'Xem thêm',
        readLess: 'Rút gọn',
        from: 'Từ',
        to: 'Đến',
        enterPrice: "Nhập giá",
        apply: "Áp dụng",
        reset: "Xóa tất cả",
        filterTool: "Bộ lọc",
        overOneMil: "Trên 1 triệu"
    },
    en: {
        searchFilterLabel: "Search Filter",
        allSubCategories: "All Categories",
        subSubCategories: "By Category",
        region: "Region",
        priceRange: "Price Range",
        rating: "Rating",
        readMore: "Read more",
        readLess: "Read less",
        from: "From",
        to: "To",
        enterPrice: "Enter price",
        apply: "Apply",
        reset: "Reset all",
        filterTool: "Filter",
        overOneMil: "Over 1 million"
    }
};

export default i18nLabels;
