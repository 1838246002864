import {Checkbox} from '@mui/material';
import {deleteGrayBtn} from 'assets/images';
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber';
import Config from 'configuration';
import {updateCartProducts} from 'features/Cart/cartSlice';
import debounce from 'lodash.debounce';
import React, {useCallback, useLayoutEffect} from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {FormattedNumber} from 'react-intl';
import Events from 'utils/Events';

const ProductBlock = (props) => {
    const {product, stallId, dispatch, intl, deleteItemsHandler, checkItemsHandler, goToPdp} = props;
    const {
        cartProductId,
        name: productName,
        price,
        quantityInCart,
        quantityInStorage,
        origin_price,
        product_type_text,
        isChecked,
        image,
        productId,
        is_hidden,
        is_delete
    } = product;
    const [demandQuantity, setDemandQuantity] = useState(quantityInCart);
    const quantityRef = useRef(null);

    const isQuantityLowerThan10 = quantityInStorage <= 10;
    const debounceDelay = 500;

    const {isDisabled, message} = [
        {isDisabled: is_delete, message: intl.messages.notExist},
        {isDisabled: is_hidden, message: intl.messages.hidden},
        {isDisabled: quantityInStorage <= 0, message: intl.messages.soldOut}
    ].filter((data) => data.isDisabled)[0] || {isDisabled: false, message: ''};

    const debounceUpdate = useCallback(
        debounce(() => {
            const value = quantityRef.current.childNodes[1].value;
            dispatch(updateCartProducts({quantity: value, id: cartProductId})).then(() => {
                Events.emit(Config.eventsKey.updateCart);
            });
        }, debounceDelay),
        []
    );

    const decreaseQuantity = () => {
        setDemandQuantity(Math.max(demandQuantity - 1, 1));
        debounceUpdate();
    };
    const increaseQuantity = () => {
        setDemandQuantity(Math.min(demandQuantity + 1, quantityInStorage));
        debounceUpdate();
    };
    const changeQuantity = (e) => {
        setDemandQuantity(Number(e.target.value));
    };
    const blurQuantity = () => {
        if (quantityRef.current) {
            const value = quantityRef.current.childNodes[1].value;
            setDemandQuantity(value > quantityInStorage ? quantityInStorage : value);
            debounceUpdate();
        }
    };

    useLayoutEffect(() => {
        if (quantityInCart) setDemandQuantity(Math.min(quantityInCart, quantityInStorage));
    }, [quantityInCart, quantityInStorage]);

    return (
        <div
            className={`cart-detail__product ${isChecked ? 'product-checked' : ''} ${
                isDisabled ? 'product-disabled' : ''
            }`}>
            <Checkbox
                name={productName}
                id={stallId}
                value={stallId}
                checked={isDisabled ? false : isChecked}
                disabled={isDisabled}
                onChange={() => checkItemsHandler({type: 'product', payload: cartProductId})}
            />
            <div className='product-block'>
                <div className='product-block__detail' onClick={() => goToPdp(productId)}>
                    <div className='product-detail__img'>
                        <img src={image} alt={productName} />
                    </div>
                    <div className='product-info'>
                        <div className='product-info__primary'>
                            <p className='m-0'>{productName}</p>
                            <small>{product_type_text}</small>
                        </div>
                        <div className='product-info__price'>
                            <p className='current-price'>
                                <FormattedNumber style='currency' currency='VND' value={price} />
                            </p>
                            {origin_price !== price && (
                                <p className='origin-price'>
                                    <FormattedNumber style='currency' currency='VND' value={origin_price} />
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className='product-block__quantity'>
                    <CustomInputNumber
                        quantity={demandQuantity}
                        decrementHandle={decreaseQuantity}
                        incrementHandle={increaseQuantity}
                        handleQuantityChange={changeQuantity}
                        handleQuantityBlur={blurQuantity}
                        disabled={isDisabled}
                        maxValue={quantityInStorage}
                        allowNegative={false}
                        ref={quantityRef}
                    />

                    {(isQuantityLowerThan10 || isDisabled) && (
                        <div className='quantity-noti'>
                            {!isDisabled
                                ? `${intl.messages.left} ${quantityInStorage} ${intl.messages.product}`
                                : message}
                        </div>
                    )}
                </div>

                <div className='product-block__price'>
                    <span>
                        <FormattedNumber style='currency' currency='VND' value={price * quantityInCart || 0} />
                    </span>
                </div>
            </div>

            <button
                className='product-delete'
                onClick={() => deleteItemsHandler({type: 'product', payload: cartProductId})}>
                <img src={deleteGrayBtn} alt='delete product' />
            </button>
        </div>
    );
};

export default ProductBlock;
