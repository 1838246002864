import axiosClient from './axiosClient';

const addressApi = {
    getAddressList: () => {
        const url = `/api/address`;
        return axiosClient.get(url);
    },
    addUserAddress: (data) => {
        const url = '/api/userAddresses';
        return axiosClient.post(url, data);
    },
    getUserAddresses: () => {
        const url = `/api/userAddresses/myAddress`;
        return axiosClient.get(url);
    },
    getUserAddressById: (addressId) => {
        const url = `/api/userAddresses/${addressId}`;
        return axiosClient.get(url);
    },
    updateUserAddress: (data) => {
        const { id } = data;
        const url = `/api/userAddresses/${id}`;
        return axiosClient.put(url, data);
    },
    deleteUserAddress: (addressId) => {
        const url = `/api/userAddresses/${addressId}`;
        return axiosClient.delete(url);
    }
};

export default addressApi;
