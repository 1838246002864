import { pagePath } from 'configuration/routeConfig';
import OrderDetail from './template/subViews/Order/OrderDetail';
import ViewOrders from './template/subViews/Order/ViewOrders';
import AddProduct from './template/subViews/Product/AddProduct';
import ViewProducts from './template/subViews/Product/ViewProducts';
import ShopInformation from './template/subViews/ShopSetting/ShopInformation';
import CategoryManagement from './template/subViews/ShopSetting/CategoryManagement';
import UserManagement from './template/subViews/ShopSetting/UserManagement';
import ChatSetting from './template/subViews/ShopSetting/ChatSetting';
import DeliverySetting from './template/subViews/ShopSetting/DeliverySetting';
import DesignBanner from './template/subViews/Design/DesignBanner';
import DesignCategory from './template/subViews/Design/DesignCategory';
import Voucher from './template/subViews/Marketing/Voucher';
import NotificationSetting from './template/subViews/ShopSetting/NotificationSetting';
import ShopReport from './template/subViews/ShopReport';
import MarketingFlashDeal from './template/subViews/Marketing/FlashDeal';

export default function propsProvider(props) {
    const { navigateTo, breadcrumbs, adminSidebar, toggleAccordion } = props;

    const adminRouteList = [
        { path: pagePath.adminProductManagementUrl, component: <ViewProducts /> },
        { path: pagePath.adminAddProductManagementUrl, component: <AddProduct /> },
        { path: pagePath.adminEditProductManagementUrl, component: <AddProduct /> },
        { path: pagePath.adminOrderManagementUrl, component: <ViewOrders /> },
        { path: pagePath.adminOrderDetailUrl, component: <OrderDetail /> },
        { path: pagePath.adminUserManagementUrl, component: <UserManagement /> },
        { path: pagePath.adminShopInformationUrl, component: <ShopInformation /> },
        { path: pagePath.adminCategoryManagementUrl, component: <CategoryManagement /> },
        { path: pagePath.adminDeliverySettingUrl, component: <DeliverySetting /> },
        { path: pagePath.adminUserManagementUrl, component: <UserManagement /> },
        { path: pagePath.adminDesignBannerUrl, component: <DesignBanner /> },
        { path: pagePath.adminDesignCategoryUrl, component: <DesignCategory /> },
        { path: pagePath.adminNotificationSettingUrl, component: <NotificationSetting /> },
        { path: pagePath.adminChatSettingUrl, component: <ChatSetting /> },
        { path: pagePath.adminMarketingVoucherUrl, component: <Voucher /> },
        { path: pagePath.adminStatisticalDataUrl, component: <ShopReport /> },
        {path: pagePath.adminFlashDealUrl, component: <MarketingFlashDeal />},
        {path: pagePath.adminFlashDealAddNewUrl, component: <MarketingFlashDeal />},
        {path: pagePath.adminFlashDealEdit, component: <MarketingFlashDeal />},
        {path: pagePath.adminFlashDealDublicateUrl, component: <MarketingFlashDeal />}
    ];

    const redirectAdminRouteList =
        adminSidebar.length > 0
            ? [
                {
                    from: pagePath.adminUrl,
                    to: adminSidebar[0].to
                        ? adminSidebar[0].to
                        : adminSidebar[0].items && adminSidebar[0].items[0].to
                            ? adminSidebar[0].items[0].to
                            : pagePath.adminUrl
                }
            ]
            : [];

    return {
        adminRouteList,
        redirectAdminRouteList,
        breadcrumbProps: {
            navigateTo,
            breadcrumbs
        },
        adminSidebarProps: {
            sidebarItems: adminSidebar,
            navigateTo,
            toggleAccordion
        }
    };
}
