import {Archive, EditGray, Trash} from 'assets/images';
import {calcTotalPage} from 'utils/calcTotalPage';

export default function propsProvider(props) {
    const {
        navigateTo,
        intl,
        activeProductListTab,
        changeProductListTab,
        addProductTool,
        handleAddProductToolChange,
        pageSizeRange,
        adminProductList,
        paginationData,
        setPage,
        setPageSize,
        checkedData,
        handleSelectAllClick,
        handleSelectRow,
        modalContent,
        handleProductItemAction,
        submitFilterProduct,
        categories,
        setFilterData
    } = props;

    // Setup table data
    const productTypeDataCount = 6;
    const productListTabs = [
        {
            title: intl.messages.all
        },
        {
            title: intl.messages.onSold
        },
        {
            title: intl.messages.outOfStock,
            count: adminProductList?.total_out_of_stock
        },
        {
            title: intl.messages.hidden,
            count: adminProductList?.total_hidden
        },
        {
            title: intl.messages.draft
        }
    ];
    const headCells = [
        {
            id: intl.messages.product,
            align: 'left',
            padding: 'none',
            label: intl.messages.product
        },
        {
            id: intl.messages.SKUtype,
            align: 'left',
            padding: 'normal',
            label: intl.messages.SKUtype
        },
        {
            id: intl.messages.classify,
            align: 'left',
            padding: 'normal',
            label: intl.messages.classify
        },
        {
            id: intl.messages.price,
            align: 'right',
            padding: 'normal',
            label: intl.messages.price
        },
        {
            id: intl.messages.storage,
            align: 'right',
            padding: 'normal',
            label: intl.messages.storage
        },
        {
            id: intl.messages.sold,
            align: 'right',
            padding: 'normal',
            label: intl.messages.sold
        },
        {
            id: intl.messages.action,
            align: 'right',
            padding: 'normal',
            label: ''
        }
    ];
    const {page, pageSize} = paginationData[activeProductListTab];
    const tableData = adminProductList?.collection;
    const transformTableData = [];

    tableData?.forEach((data) => {
        const columnsData = Array(productTypeDataCount);
        for (var i = 0; i < columnsData.length; i++) {
            columnsData[i] = [];
        }

        const {extend_product_type, price, quantity, is_published, is_hidden, extend_type} = data;
        const importedData = {...data};
        const tags = [];
        let tempTableData = {};

        const tableRowActions = [
            {
                title: intl.messages.updateProduct,
                icon: <EditGray />,
                onClick: handleProductItemAction,
                actionType: 'update'
            },
            {
                title: intl.messages.deleteProduct,
                icon: <Trash />,
                onClick: handleProductItemAction,
                actionType: 'delete'
            }
        ];

        if (extend_product_type.length > 0) {
            extend_product_type.forEach((productType) => {
                const {price, quantity, sold, sku, type_id} = productType;

                let types = [];

                type_id.forEach((typeId) => {
                    const targetType = extend_type.find((type) => type.id === typeId);

                    if (targetType) {
                        types.push(targetType);
                    }
                });

                types = types.sort((type1, type2) => type2.sort_order - type1.sort_order).map((type) => type.name);

                columnsData[0].push(sku ? sku : '--');
                columnsData[1].push(types.join(' - '));
                columnsData[2].push(price);
                columnsData[3].push(quantity);
                columnsData[4].push(sold ? sold : '');
            });

            tempTableData = {...importedData, columnsData, tags};
        } else {
            tempTableData = {
                ...importedData,
                columnsData: [['--'], ['--'], [price], [quantity], [data.sold]],
                tags
            };
        }

        if (is_hidden) {
            tags.push({type: 'hidden', label: intl.messages.hidden});
            tableRowActions.unshift({
                title: intl.messages.show,
                icon: <Archive />,
                onClick: handleProductItemAction,
                actionType: 'show'
            });
        } else if (!is_published) {
            tags.push({type: 'draft', label: intl.messages.draft});
        } else {
            tableRowActions.push({
                title: intl.messages.hideProduct,
                icon: <Archive />,
                onClick: handleProductItemAction,
                actionType: 'hide'
            });
        }

        tempTableData.columnsData[5] = tableRowActions;

        transformTableData.push(tempTableData);
    });

    // Setup action bar buttons
    const deleteProducts = [],
        hideProducts = [],
        showProducts = [];
    checkedData.forEach((id) => {
        const product = adminProductList?.collection.find((item) => item.id === id);

        if (product) {
            const {is_published, is_hidden} = product;

            if (is_published) {
                if (is_hidden) {
                    showProducts.push(id);
                } else {
                    hideProducts.push(id);
                }
            }

            deleteProducts.push(id);
        }
    });

    const actionBarControlButtons = [
        {
            label: intl.messages.deleteProduct + ` (${deleteProducts.length})`,
            variant: 'text',
            color: 'danger',
            onClick: () => handleProductItemAction({type: 'delete', payload: deleteProducts})
        }
    ];

    if (activeProductListTab !== 4) {
        if (activeProductListTab !== 3) {
            actionBarControlButtons.push({
                label: intl.messages.hideProduct + ` (${hideProducts.length})`,
                variant: 'half-contained',
                color: 'primary',
                onClick: () => handleProductItemAction({type: 'hide', payload: hideProducts})
            });
        }

        if (activeProductListTab !== 2) {
            actionBarControlButtons.push({
                label: intl.messages.show + ` (${showProducts.length})`,
                variant: 'half-contained',
                color: 'primary',
                onClick: () => handleProductItemAction({type: 'show', payload: showProducts})
            });
        }
    }

    return {
        viewProductListProps: {
            intl,
            navigateTo,
            productListTabs,
            activeProductListTab,
            changeProductListTab,
            totalRows: adminProductList?.total,
            addProductTool,
            handleAddProductToolChange,
            tableProductListProps: {
                intl,
                pageSizeRange,
                headCells,
                transformTableData,
                totalPage: calcTotalPage(adminProductList?.total, pageSize),
                currentPage: page,
                pageSize,
                setPage,
                setPageSize,
                checkedData,
                handleSelectAllClick,
                handleSelectRow
            }
        },
        actionBarProps: {
            intl,
            handleSelectAllClick,
            actionBarControlButtons,
            selectedRows: checkedData.length,
            rowCount: transformTableData.length
        },
        filterProductListProps: {
            intl,
            submitFilterProduct,
            categories,
            setFilterData
        },
        modalContent,
        isShowActionBar: checkedData.length > 0
    };
}
