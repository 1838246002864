import React from 'react';

const CustomButton = (props) => {
    const {
        children,
        color = 'primary',
        variant = 'contained',
        disabled = false,
        className = '',
        fullwidth = false,
        size = "lg",
        ...btnProps
    } = props;

    return (
        <button
            className={`custom-btn custom-btn__${variant} custom-btn__${size} custom-btn--${color} ${fullwidth ? 'w-100' : ''} ${disabled ? 'custom-btn--disabled' : ''
                } ${className}`}
            {...btnProps}
            disabled={disabled}>
            {children}
        </button>
    );
};

export default CustomButton;
