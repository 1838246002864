import React, {useEffect, useState} from 'react';
import {pagePath} from 'configuration/routeConfig';
import {ornament1, oval, ornamentMobile, ovalMobile, ornament2x} from 'assets/images';
import {Toast} from 'utils/toast';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import CustomOtpInput from 'components/CustomOtpInput/CustomOtpInput';
import CustomButton from 'components/CustomButton/CustomButton';
import registerApi from 'api/registerApi';
import Config from 'configuration';
import {handleSetRegisterId} from 'features/Signup/signupSlice';

const SignupByEmailOtp = (props) => {
    const {dispatch, navigateTo, userSignupInfo} = props;
    const [enteredOTP, setEnteredOTP] = useState('');
    const [timer, setTimer] = useState(60);
    const handleOTPChange = (otp) => setEnteredOTP(otp);
    const disabledButton = enteredOTP.length !== 6 || timer <= 0;

    if (Object.keys(userSignupInfo).length === 0) {
        navigateTo(pagePath.signUpUrl);
    }

    const handleRegister = async () => {
        const registerUserId = localStorage.getItem(Config.storageKey.registerId);
        const res = await registerApi.registerUserOTPConfirm({
            register_user_id: registerUserId,
            otp: enteredOTP
        });
        const {success, message} = res;
        if (success) {
            Toast(success, message);
            navigateTo(pagePath.loginUrl);
        } else {
            Toast(success, message);
        }
    };

    const handleResendOtp = async () => {
        const res = await registerApi.registerUserByEmail(userSignupInfo);
        const {success} = res;

        if (success) {
            setTimer(60);
            dispatch(handleSetRegisterId(res?.data?.id));
            Toast(success, 'Vui lòng kiểm tra email của bạn!');
        } else {
            Toast(success, res?.message);
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const timerInterval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [timer]);

    return (
        <AuthLayout src1={ornament2x} src2={ornament1} src3={oval} src4={ornamentMobile} src5={ovalMobile}>
            <div className='login-right auth-otp'>
                <AuthModal>
                    <div className='login-header'>
                        <h2 className='mb-2 text-center'>Xác thực email đăng ký</h2>
                        <div className='m-0 text-center'>
                            Một mã xác thực vừa gửi về email của bạn,
                            <br /> vui lòng kiểm tra email!
                        </div>
                    </div>

                    <div className='otp-phone'>
                        <CustomOtpInput
                            value={enteredOTP}
                            onChange={handleOTPChange}
                            numInputs={6}
                            isInputNum
                            isDisabled={timer <= 0}
                        />

                        <p className='text-center my-3'>
                            {timer > 0 ? (
                                <span>
                                    Mã có hiệu lực trong:{' '}
                                    <span className='otp-variant'>00:{timer >= 10 ? timer : `0${timer}`}</span>
                                </span>
                            ) : (
                                <span>
                                    Không nhận được mail ?{' '}
                                    <span className='otp-variant cursor-pointer' onClick={() => handleResendOtp()}>
                                        Gửi lại
                                    </span>
                                </span>
                            )}
                        </p>

                        <div className='w-100'>
                            <CustomButton type='submit' onClick={handleRegister} disabled={disabledButton} fullwidth>
                                Xác nhận
                            </CustomButton>
                        </div>

                        <div className='other-option d-flex flex-column justify-content-center align-items-center gap-3 mt-4'>
                            <span onClick={() => navigateTo(pagePath.signUpUrl)}>Trở về</span>
                            <span onClick={() => navigateTo(pagePath.authUrl)}>Đăng nhập bằng phương thức khác</span>
                        </div>
                    </div>
                </AuthModal>
                <div id='captcha-container-resend'></div>
            </div>
        </AuthLayout>
    );
};

export default SignupByEmailOtp;
