import React, {useRef, useState} from 'react';
import {DocumentImg, HappyFace, Image, MarkAsUnread, SendMessage, ShoppingBag, Video, ornament} from 'assets/images';
import MessageBox from '../MessageBox/MessageBox';
import CustomInput from 'components/CustomInput/CustomInput';
import EmojiPicker from 'emoji-picker-react';
import FsLightbox from 'fslightbox-react';
import {useOutsideAlerter} from 'utils/hooks/useOutsideAlerter';
import ProductListModal from '../ProductListModal/ProductListModal';
import OrderListModal from '../OrderListModal/OrderListModal';
import Config from 'configuration';

const MessageList = (props) => {
    const {
        navigateTo,
        dispatch,
        scrollRef,
        fileRef,
        messageContent,
        handleSendMessage,
        textValue,
        setTextValue,
        emojiPickerVisible,
        setEmojiPickerVisible,
        handleSendImage,
        lightBoxToggler,
        toggleLightBox,
        index,
        setIndex,
        handleSendProduct,
        handleSendOrder,
        isAdmin,
        chatRoomInfo,
        handleMarkAsRead
    } = props;
    const emojiRef = useRef(null);
    const productListRef = useRef(null);
    const orderListRef = useRef(null);
    let indexOfImage = 0;
    const [productListVisible, setProductListVisible] = useState(false);
    const [orderListVisible, setOrderListVisible] = useState(false);

    const onEmojiClick = (emojiData, event) => {
        setTextValue((prev) => prev + emojiData.emoji);
        setEmojiPickerVisible(false);
    };

    const onCloseProductList = () => {
        setProductListVisible(false);
    };

    const onCloseOrderList = () => {
        setOrderListVisible(false);
    };

    useOutsideAlerter(emojiRef, () => setEmojiPickerVisible(false));
    useOutsideAlerter(productListRef, () => setProductListVisible(false));
    useOutsideAlerter(orderListRef, () => setOrderListVisible(false));

    const imgUrls =
        messageContent &&
        messageContent.map((el) => (el.type === 'image' ? el.content : null)).filter((el) => el !== null);

    const productListModalProps = {
        dispatch,
        handleClose: onCloseProductList,
        handleSendProduct
    };

    const orderListModalProps = {
        dispatch,
        navigateTo,
        handleClose: onCloseOrderList,
        handleSendOrder,
        isAdmin,
        chatRoomInfo
    };

    return (
        <>
            <div className='left-side'>
                {isAdmin && <div className='user-bar'>
                    <div className='user-bar__container'>
                        <div className='user-info'>
                            <img
                                className='user-avatar'
                                src={
                                    chatRoomInfo?.receiver?.avatar
                                        ? `${Config.apiConfig.endPoint}${chatRoomInfo?.receiver?.avatar}`
                                        : ornament
                                }
                                alt='user'
                            />
                            <span className='user-fullname'>{chatRoomInfo?.receiver?.full_name || 'Anonymous'}</span>
                        </div>
                        <MarkAsUnread className='unread-icon' onClick={() => handleMarkAsRead(chatRoomInfo?.id, true)} />
                    </div>
                </div>}
                <div className='message-list'>
                    {messageContent.map((props, i) => {
                        if (props.type === 'image') {
                            return (
                                <MessageBox
                                    key={i}
                                    {...props}
                                    toggleLightBox={toggleLightBox}
                                    indexOfImage={indexOfImage++}
                                    setIndex={setIndex}
                                />
                            );
                        }
                        return (
                            <MessageBox
                                key={i}
                                {...props}
                                toggleLightBox={toggleLightBox}
                                dispatch={dispatch}
                                isAdmin={isAdmin}
                            />
                        );
                    })}
                    <div id='scroll-to-bottom' ref={scrollRef}></div>
                </div>
                <div className='message-editor'>
                    <CustomInput
                        value={textValue}
                        onChange={(e) => setTextValue(e.target.value)}
                        type='textarea'
                        placeholder='Nhập nội dung tin nhắn tại đây...'
                        endicon={
                            <SendMessage
                                onClick={handleSendMessage}
                                className={`send-icon send-icon__${textValue.length > 0 ? 'ready' : ''}`}
                            />
                        }
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                handleSendMessage();
                            }
                        }}
                    />
                    <div className='chatbox-actions'>
                        <HappyFace
                            className={`button-icon button-icon__${emojiPickerVisible ? 'active' : ''}`}
                            onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                        />
                        {emojiPickerVisible && (
                            <div className='emoji-container' ref={emojiRef}>
                                <EmojiPicker height={400} width={320} onEmojiClick={onEmojiClick} />
                            </div>
                        )}
                        <Image className='button-icon' onClick={() => fileRef.current.click()} />
                        <input
                            ref={fileRef}
                            type='file'
                            multiple={true}
                            name='image-message'
                            id='image-message'
                            style={{display: 'none'}}
                            accept={['.png', '.jpeg', '.jpg']}
                            onChange={handleSendImage}
                        />
                        {/* <Video className='cursor-pointer' /> */}
                        <ShoppingBag
                            className={`button-icon button-icon__${productListVisible ? 'active' : ''}`}
                            onClick={() => setProductListVisible(!productListVisible)}
                        />
                        {productListVisible && (
                            <div className='product-list-container' ref={productListRef}>
                                <ProductListModal {...productListModalProps} />
                            </div>
                        )}
                        <DocumentImg
                            className={`button-icon button-icon__${orderListVisible ? 'active' : ''}`}
                            onClick={() => setOrderListVisible(!orderListVisible)}
                        />
                        {orderListVisible && (
                            <div className='order-list-container' ref={orderListRef}>
                                <OrderListModal {...orderListModalProps} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <FsLightbox toggler={lightBoxToggler} sources={imgUrls} slide={index + 1} />
        </>
    );
};

export default MessageList;
