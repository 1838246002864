import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import CustomButton from 'components/CustomButton/CustomButton';
import {ApproveFile, DeleteFile, Parcel, ShippingTruck, Tick, CloseGray, Approve, CloseCircle} from 'assets/images';
import './orderInformation.scss';
import moment from 'moment';
import {orderActions} from '../../../OrderDetailContainer';

const OrderInformation = (props) => {
    const {
        intl,
        orderInfo,
        currentStep,
        handleOrderStep,
        countDown,
        cancelDescription,
        openRefundModal,
        subStepName,
        subStepCode,
        cancelerAdminName
    } = props;

    const orderSteps = [
        {
            name: 'Chờ xác nhận',
            code: 'Unconfirm',
            actions: [
                {
                    key: 'cancel',
                    text: intl.messages.cancelText,
                    icon: <DeleteFile className='cancel-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.cancel
                },
                {
                    key: 'confirm',
                    text: intl.messages.confirmText,
                    icon: <ApproveFile className='confirm-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.confirmOrder
                }
            ]
        },
        {
            name: 'Chờ lấy hàng',
            code: 'Packing-Pending',
            actions: [
                {
                    key: 'packing',
                    text: intl.messages.packingText,
                    icon: <Parcel />,
                    action: handleOrderStep,
                    actionType: orderActions.prepare
                }
            ]
        },
        {
            name: 'Chờ lấy hàng',
            code: 'Delivery-Pending',
            actions: [
                {
                    key: 'deliver',
                    text: intl.messages.deliverText,
                    icon: <ShippingTruck />,
                    action: handleOrderStep,
                    actionType: orderActions.handOver
                }
            ]
        },
        {
            name: 'Đang giao',
            code: 'Delivery',
            actions: [
                {
                    key: 'unsuccess',
                    text: intl.messages.unsuccessfullyDelivered,
                    icon: <CloseGray className='close-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.cancel
                },
                {
                    key: 'success',
                    text: intl.messages.successfullyDelivered,
                    icon: <Tick className='tick-icon' />,
                    action: handleOrderStep,
                    actionType: orderActions.confirmDelivered
                }
            ]
        },
        {
            name: 'Hoàn tiền',
            code: 'Refund',
            actions: !['REFUND_ACCEPTED', 'REFUND_DENIED'].includes(subStepCode)
                ? [
                      {
                          key: 'reject',
                          text: intl.messages.rejectAction,
                          icon: <CloseCircle className='close-circle-icon' />,
                          action: handleOrderStep,
                          actionType: orderActions.reject
                      },
                      {
                          key: 'approve',
                          text: intl.messages.acceptAction,
                          icon: <Approve className='approve-icon' />,
                          action: handleOrderStep,
                          actionType: orderActions.accept
                      }
                  ]
                : []
        }
    ];

    const variantOfButton = {
        cancel: 'text',
        unsuccess: 'half-contained',
        approve: 'half-contained',
        reject: 'half-contained',
        confirm: 'contained',
        packing: 'contained',
        deliver: 'contained',
        success: 'contained'
    };

    const foundStepMethod = ({name, code}) => {
        for (let i = 0; i < orderSteps.length; i++) {
            if (orderSteps[i].name === name && name !== 'Chờ lấy hàng') {
                return orderSteps[i];
            } else {
                if (code && orderSteps[i].code === code) return orderSteps[i];
            }
        }
        return null;
    };
    const foundStep = foundStepMethod(currentStep);

    const displayDescription = (statusCode) => {
        switch (statusCode) {
            case 'Unconfirm': {
                return `${Math.trunc(moment.duration(countDown, 'milliseconds').asHours())
                    .toString()
                    .padStart(2, '0')}:${moment
                    .duration(countDown, 'milliseconds')
                    .minutes()
                    .toString()
                    .padStart(2, '0')}:${moment
                    .duration(countDown, 'milliseconds')
                    .seconds()
                    .toString()
                    .padStart(2, '0')}`;
            }
            case 'Refund': {
                if (['REFUND_ACCEPTED', 'REFUND_DENIED'].includes(subStepCode)) return subStepName;
                else return 'Chờ duyệt';
            }
            case 'Cancel': {
                if (['SHOP_CANCEL', 'USER_CANCEL'].includes(subStepCode)) {
                    if (subStepCode === 'SHOP_CANCEL') return `Hủy bởi ${cancelerAdminName}`;
                    else return 'Đã hủy bởi người mua';
                } else {
                    return 'Không có dữ liệu';
                }
            }
            default: {
                return orderInfo?.statusDescription;
            }
        }
    };

    return (
        <div className='payment-transport-information'>
            <div className='d-flex justify-content-between align-items-center'>
                <SectionTitle paled={false}>{intl.messages.orderInformation}</SectionTitle>
            </div>

            <div className='row'>
                <div className='col-4 col-md-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.orderCode}</span>
                </div>
                <div className='col-8 col-md-7'>
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}>
                        #{orderInfo?.orderCode}
                    </span>
                </div>
            </div>
            <div className='row d-flex align-items-center mt-2'>
                <div className='col-4 col-md-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.processStatus}</span>
                </div>
                <div
                    className={`${
                        orderInfo?.statusCode === 'Delivery' ? 'col-md-2' : 'col-md-5'
                    } col-8 d-flex justify-content-start align-items-center`}>
                    <div
                        className='d-flex justify-content-center align-items-center'
                        style={{
                            width: '100px',
                            height: '36px',
                            fontSize: 13,
                            fontWeight: 500,
                            borderRadius: '4px',
                            color: `${orderInfo?.statusTextColor}`,
                            border: `solid 1px ${orderInfo?.statusTextColor}`,
                            backgroundColor: `${orderInfo?.statusColor}`
                        }}>
                        {orderInfo?.statusName}
                    </div>
                    <span style={{marginLeft: '16px', fontSize: '13px', color: '#808080'}}>
                        {displayDescription(orderInfo?.statusCode)}
                    </span>
                    {orderInfo?.statusName === 'Hoàn tiền' ? (
                        <span
                            style={{
                                marginLeft: '16px',
                                fontSize: '13px',
                                color: '#4568f2',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                openRefundModal();
                            }}>
                            {intl.messages.refundDetailView}
                        </span>
                    ) : null}
                </div>
                <div
                    className={`actions-button ${
                        orderInfo?.statusCode === 'Delivery' ? 'col-md-7' : 'col-md-4'
                    } mt-2 d-flex justify-content-end align-items-center gap-2`}>
                    {foundStep &&
                        foundStep?.actions &&
                        foundStep?.actions.map((action) => (
                            <CustomButton
                                key={action.key}
                                size='md'
                                variant={variantOfButton[action.key]}
                                color={['cancel', 'unsuccess', 'reject'].includes(action.key) ? 'danger' : 'primary'}
                                onClick={() => action.action(action.actionType)}>
                                {action.icon}
                                {action.text}
                            </CustomButton>
                        ))}
                </div>
            </div>
            {orderInfo?.statusCode === 'Cancel' && (
                <div className='row mt-2 d-flex align-items-center'>
                    <div className='col-4 col-sm-3 d-flex flex-column'>
                        <span style={{color: '#808080', fontSize: 15}}>{intl.messages.cancelReasonLabel}</span>
                        <span style={{color: '#808080', fontSize: 10, fontStyle: 'italic'}}>
                            {intl.messages.fromSellerOrBuyer}
                        </span>
                    </div>
                    <div className='col-8 col-sm-9'>
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500
                            }}>
                            {cancelDescription}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderInformation;
