import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import React from 'react';

const ExpandProductList = (props) => {
    const {
        recommendList = [],
        navigateTo,
        intl,
        currentRecommendList,
        setCurrentRecommendList,
        paleTitle = false
    } = props;
    const length = recommendList.length;

    const handleLoadMore = () => {
        const currentLength = currentRecommendList.length;
        if (currentLength < length) {
            const tmp = recommendList.slice(currentLength, currentLength + 8);
            setCurrentRecommendList((prevList) => [...prevList, ...tmp]);
        } else {
            return;
        }
    };

    return (
        <div className='recommend'>
            <SectionTitle paled={paleTitle}>{intl.messages.recommendProduct}</SectionTitle>

            <div className='recommend-list'>
                {(currentRecommendList || []).map((item) => (
                    <ProductCard item={item} key={item.id} navigateTo={navigateTo} />
                ))}
            </div>

            {currentRecommendList.length < length && (
                <div className='load-more-button' onClick={handleLoadMore}>
                    {intl.messages.readMore}
                </div>
            )}
        </div>
    );
};

export default ExpandProductList;
