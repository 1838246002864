import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import {Repeat, SearchBlue, SearchIcon} from 'assets/images';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import GroupInput from 'components/GroupInput/GroupInput';

const defaultFilterValues = {
    searchOrderValue: '',
    searchProductValue: '',
    orderer: '',
    fromDate: '',
    toDate: '',
    deliveryAgent: ''
};

const FilterBar = (props) => {
    const {intl, submitFilterProduct, setFilterData, shippingUnits = []} = props;
    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultFilterValues
    });

    const groupDateInputs = [
        {
            name: 'fromDate',
            placeholder: intl.messages.fromDate
        },
        {
            name: 'toDate',
            placeholder: intl.messages.toDate
        }
    ];
    const shippingUnitsSelectData = shippingUnits.map((shippingUnit) => {
        return {
            id: shippingUnit.name,
            value: shippingUnit.name
        };
    });

    const handleReset = () => {
        setFilterData({Filters: [], productName: ''});
        reset(defaultFilterValues);
    };

    return (
        <div className='view-order__filter mb-3'>
            <form action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterProduct)}>
                <div className='row m-0 mb-4 gap-10px'>
                    <div className='col p-0 d-flex'>
                        <div className='filter-by'>
                            <div className='filter-by__value'>{intl.messages.orderId}</div>
                        </div>
                        <div className='search-by'>
                            <Controller
                                name='searchOrderValue'
                                control={control}
                                render={({field}) => {
                                    return (
                                        <CustomInput
                                            {...field}
                                            type='number'
                                            starticon={<SearchIcon />}
                                            inputClassName='filter-search'
                                            placeholder={intl.messages.searchOrder}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className='col p-0 d-flex'>
                        <div className='filter-by'>
                            <div className='filter-by__value'>{intl.messages.productName}</div>
                        </div>
                        <div className='search-by'>
                            <Controller
                                name='searchProductValue'
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        starticon={<SearchIcon />}
                                        inputClassName='filter-search'
                                        placeholder={intl.messages.searchProduct}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className='row m-0 mb-4 gap-10px'>
                    <div className='col p-0'>
                        <Controller
                            name='orderer'
                            control={control}
                            render={({field}) => (
                                <CustomInput
                                    {...field}
                                    label={intl.messages.orderer}
                                    placeholder={intl.messages.enterOrdererName}
                                />
                            )}
                        />
                    </div>
                    <div className='col p-0'>
                        <GroupInput
                            type='date'
                            control={control}
                            label={intl.messages.orderDate}
                            groupInputData={groupDateInputs}
                        />
                    </div>
                </div>

                <div className='row m-0 gap-10px'>
                    <div className='col p-0'>
                        <Controller
                            name='deliveryAgent'
                            control={control}
                            render={({field}) => (
                                <CustomSelect
                                    {...field}
                                    label={intl.messages.deliveryAgent}
                                    placeholder={intl.messages.enterDeliveryAgent}
                                    options={shippingUnitsSelectData}
                                />
                            )}
                        />
                    </div>
                    <div className='col p-0 d-flex gap-2'>
                        <CustomButton className='p-2' type='submit' variant='half-contained'>
                            <SearchBlue className='me-1' />
                            {intl.messages.search}
                        </CustomButton>
                        <CustomButton className='p-2' type='reset' variant='text' onClick={handleReset}>
                            <Repeat className='me-1' />
                            {intl.messages.reInput}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterBar;
