import React from 'react';
import {close} from 'assets/images';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Rating} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import InputFiles from 'features/Admin/template/subViews/Product/AddProduct/template/subViews/InputFiles/InputFiles';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import Config from 'configuration';

const ratingLabels = {
    1: 'Rất tệ',
    2: 'Tệ',
    3: 'Tạm ổn',
    4: 'Tốt',
    5: 'Tuyệt vời'
};

const ReviewFormModal = (props) => {
    const {
        intl,
        isOpenReviewModal,
        closeReviewModal,
        handleReviewOrder,
        handleUploadImages,
        reviewValues,
        setReviewValues,
        isViewDetail
    } = props;
    const maxImagesAllowed = 9;
    const buttonDisabled = reviewValues && reviewValues.some((el) => el.rating === 0 || el.rating === null);

    const deleteImage = (id, productIndex) => {
        const newReviewValues = [...reviewValues].map((el, idx) => {
            if (idx === productIndex) {
                const newDetailImages = [];
                el?.detailImages.forEach((data, idx) => {
                    if (id !== idx) {
                        newDetailImages.push(data);
                    }
                });
                el['detailImages'] = newDetailImages;
                return el;
            }
            return el;
        });

        setReviewValues(newReviewValues);
    };

    const handleChange = (value, index, fieldName) => {
        const newReviewValues = [...reviewValues].map((el, idx) => {
            if (idx === index) {
                el[fieldName] = value;
                return el;
            }
            return el;
        });
        setReviewValues(newReviewValues);
    };

    return (
        <>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={isOpenReviewModal}
                onClose={closeReviewModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <DialogTitle className='dialog-title' id='voucher-modal'>
                    <span>{isViewDetail ? intl.messages.detailProductReview : intl.messages.productReview}</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                closeReviewModal();
                            }}
                        />
                    </button>
                </DialogTitle>
                <form
                    method='POST'
                    action='#'
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleReviewOrder();
                    }}>
                    <DialogContent className='dialog-content'>
                        {reviewValues &&
                            reviewValues.map((product, idx) => (
                                <div
                                    className='review-wrapper'
                                    key={idx}
                                    style={{marginTop: `${idx % 2 !== 0 ? '8px' : 0}`}}>
                                    <div className='d-flex align-items-center gap-3'>
                                        <img src={`${Config.apiConfig.endPoint}${product?.productImg}`} alt='product' />
                                        <div className='product-info'>
                                            <p className='product-info__name'>{product?.productName}</p>
                                            <p className='product-info__description'>{product?.productDescription}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center gap-3 mb-2'>
                                        <span className='product-quality__label'>{intl.messages.productQuality}</span>
                                        <Rating
                                            key={idx}
                                            disabled={isViewDetail}
                                            value={product?.rating ? parseInt(product?.rating) : 0}
                                            onChange={(e, newValue) => {
                                                handleChange(newValue, idx, 'rating');
                                            }}
                                            onChangeActive={(e, newHover) => {
                                                handleChange(newHover, idx, 'ratingHover');
                                            }}
                                        />
                                        {product?.rating !== null && (
                                            <span>
                                                {
                                                    ratingLabels[
                                                        product?.ratingHover !== -1
                                                            ? product?.ratingHover
                                                            : product?.rating
                                                    ]
                                                }
                                            </span>
                                        )}
                                    </div>

                                    <CustomInput
                                        key={idx}
                                        disabled={isViewDetail}
                                        value={product?.comment}
                                        onChange={(e) => {
                                            handleChange(e.target.value, idx, 'comment');
                                        }}
                                        type='textarea'
                                        placeholder={intl.messages.reviewCommentPlaceholder}
                                    />

                                    {!isViewDetail ? (
                                        <div className='d-flex align-items-center flex-wrap gap-3 mt-2'>
                                            {product?.detailImages.map((image, index) => (
                                                <PreviewImage
                                                    key={index}
                                                    image={image.url}
                                                    onDeleteImage={deleteImage.bind(this, index, idx)}
                                                />
                                            ))}
                                            {product?.detailImages.length < maxImagesAllowed && (
                                                <InputFiles
                                                    type='image'
                                                    maxLength={maxImagesAllowed}
                                                    count={product?.detailImages.length}
                                                    accept={['.png', '.jpeg', '.jpg']}
                                                    handleUpload={(e) => handleUploadImages(e, idx)}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className='d-flex align-items-center flex-wrap gap-3 mt-2'>
                                            {product?.detailImages.map((image, index) => (
                                                <img
                                                    key={index}
                                                    style={{
                                                        width: '64px',
                                                        height: '64px',
                                                        objectFit: 'cover',
                                                        borderRadius: '8px'
                                                    }}
                                                    src={`${Config.apiConfig.endPoint}${image?.physical_path}`}
                                                    alt={`product_${image?.id}`}
                                                />
                                            ))}
                                        </div>
                                    )}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={idx}
                                                disabled={isViewDetail}
                                                checked={product?.is_name_display}
                                                onChange={(e) => {
                                                    handleChange(e.target.checked, idx, 'is_name_display');
                                                }}
                                            />
                                        }
                                        label={intl.messages.usernameDisplay}
                                    />
                                </div>
                            ))}
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            variant='text'
                            className='header-account__signup'
                            onClick={(e) => {
                                e.preventDefault();
                                closeReviewModal();
                            }}>
                            {isViewDetail ? intl.messages.closeButton : intl.messages.backButton}
                        </CustomButton>
                        {!isViewDetail && (
                            <CustomButton variant='contained' type='submit' disabled={buttonDisabled}>
                                {intl.messages.completeButton}
                            </CustomButton>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default ReviewFormModal;
