import CustomButton from 'components/CustomButton/CustomButton';
import Config from 'configuration';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import {formatQuantity} from 'utils/formatQuantity';
import {orderActions} from '../../MyPurchaseContainer';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const CardOrder = (props) => {
    const {intl, navigateTo, order, products, handleOrderStep} = props;
    const {orderId, orderStatusCode, isOrderReviewed, invoice_id, orderCodeNumber} = order;
    const [windowSize] = useDeviceSize();

    const orderSteps = [
        {
            code: 'Unconfirm',
            actions: [
                {
                    key: 'cancel',
                    text: intl.messages.cancelOrder,
                    action: handleOrderStep,
                    actionType: orderActions.cancel,
                    variant: 'half-contained'
                }
            ]
        },
        {
            code: 'Delivered',
            actions: [
                {
                    key: 'received',
                    text: intl.messages.receivedConfirm,
                    action: handleOrderStep,
                    actionType: orderActions.confirmReceived,
                    variant: 'contained'
                },
                {
                    key: 'return',
                    text: intl.messages.return,
                    action: handleOrderStep,
                    actionType: orderActions.refund,
                    variant: 'half-contained'
                }
            ]
        },
        {
            code: 'Done',
            actions: [
                {
                    key: !isOrderReviewed ? 'review' : 'reviewDetail',
                    text: !isOrderReviewed ? intl.messages.review : intl.messages.reviewDetail,
                    action: handleOrderStep,
                    actionType: !isOrderReviewed ? orderActions.review : orderActions.reviewDetail,
                    variant: 'contained'
                }
            ]
        },
        {
            code: 'Cancel',
            actions: [
                {
                    key: 'buyAgain',
                    text: intl.messages.buyAgain,
                    action: handleOrderStep,
                    actionType: orderActions.buyAgain,
                    variant: 'contained'
                }
            ]
        },
        {
            code: 'Refund',
            actions: [
                {
                    key: 'refundDetail',
                    text: intl.messages.refundDetail,
                    action: handleOrderStep,
                    actionType: orderActions.refund,
                    variant: 'contained'
                }
            ]
        }
    ];

    const foundStep = orderSteps.find((el) => el.code === orderStatusCode);

    return (
        <div className='card-order'>
            <div className='card-order__header'>
                <div className='order-code' onClick={() => navigateTo(`${pagePath.userMyPurchaseUrl}/${orderId}`)}>
                    {order.orderCodeNumber}
                </div>
                <div className={`order-status order-status__${orderStatusCode}`}>{order.orderStatus}</div>
            </div>
            <div className='card-order__body'>
                {products.map((item, idx) => (
                    <div className='product' key={idx}>
                        <div className='product__leftside'>
                            <div className='product-image'>
                                <img src={`${Config.apiConfig.endPoint}${item.productImg}`} alt='product' />
                            </div>
                            <div className='product-info'>
                                <p className='product-info__name'>{item.productName}</p>
                                <p className='product-info__description'>{item.productDescription}</p>
                                <p className='product-info__quantity'>
                                    Số lượng: {formatQuantity(item.productQuantity)}
                                </p>
                            </div>
                        </div>
                        <div className='product__rightside'>
                            <p className='product-price'>
                                <FormattedNumber style='currency' currency='VND' value={item.productPrice} />
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className='card-order__footer'>
                <div className='order-description'>{order.orderStatusDescription}</div>
                <div className='order-price'>
                    <span>{intl.messages.totalPrice}:</span>
                    <span className='order-price__value'>
                        <FormattedNumber style='currency' currency='VND' value={order.orderTotalPrice} />
                    </span>
                </div>
            </div>
            {windowSize.width <= 576 ? (
                <div className='card-order__bonus'>
                    <div className=''></div>
                    <div className='action-buttons d-flex justify-content-end align-items-center gap-2'>
                        <CustomButton
                            size='md'
                            variant='outlined'
                            onClick={(e) => {
                                e.preventDefault();
                                navigateTo(`${pagePath.userMyPurchaseUrl}/${orderId}`);
                            }}>
                            Chi tiết đơn hàng
                        </CustomButton>
                    </div>
                </div>
            ) : (
                ['Unconfirm', 'Delivered', 'Done', 'Cancel', 'Refund'].includes(orderStatusCode) && (
                    <div className='card-order__bonus'>
                        <div className=''></div>
                        <div className='action-buttons d-flex justify-content-end align-items-center gap-2'>
                            {foundStep &&
                                foundStep.actions.map((action) => (
                                    <CustomButton
                                        key={action.key}
                                        size='md'
                                        variant={action.variant}
                                        color={action.key === 'cancel' ? 'danger' : 'primary'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            action.action(action.actionType, {
                                                orderId,
                                                products,
                                                invoice_id,
                                                orderCodeNumber
                                            });
                                        }}>
                                        {action.text}
                                    </CustomButton>
                                ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default CardOrder;
