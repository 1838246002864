import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Approve } from 'assets/images';

const CategorySelectList = ({
    data = [],
    isFirstSection = false,
    parentPass = 0,
    value,
    onChange,
    handleSelectValue,
    setAttributes,
    multiple = false
}) => {
    const [showNested, setShowNested] = useState({});

    // handle show/hide functionality
    const toggleNested = (id) => {
        setShowNested({ ...showNested, [id]: !showNested[id] });
    };

    const categoryStyle = isFirstSection
        ? {}
        : {
            position: 'relative',
            left: `${20 * parentPass}px`
        };

    return (
        <>
            {data.map((category) => {
                return (
                    <div key={category.id}>
                        {category.children && (
                            <div
                                className='category-item category-item--parent'
                                aria-expanded={showNested[category.id]}
                                onClick={() => toggleNested(category.id)}>
                                <span style={categoryStyle}>{category.name}</span>
                                <KeyboardArrowDownIcon className='category-arrow' />
                            </div>
                        )}

                        {!category.children && (
                            <div
                                className='category-item category-item--children'
                                onClick={() => {
                                    const newValue = multiple ? [...value] : category.id;

                                    if (multiple) {
                                        const index = newValue.findIndex((id) => id === category.id);

                                        if (index === -1) {
                                            newValue.push(category.id);
                                        } else {
                                            newValue.splice(index, 1);
                                        }
                                    }
                                    onChange(newValue);

                                    if (handleSelectValue) handleSelectValue(category.parentNames);
                                    if (setAttributes) setAttributes(category.attributes);
                                }}>
                                <span style={categoryStyle}>{category.name}</span>
                                {(multiple ? value.includes(category.id) : category.id === value) && (
                                    <Approve />
                                )}
                            </div>
                        )}

                        <div style={{ display: !showNested[category.id] && 'none' }}>
                            {category.children && (
                                <CategorySelectList
                                    data={category.children}
                                    parentPass={parentPass + 1}
                                    value={value}
                                    onChange={onChange}
                                    handleSelectValue={handleSelectValue}
                                    setAttributes={setAttributes}
                                    multiple={multiple}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CategorySelectList;
