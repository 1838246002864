import {Tick} from 'assets/images';
import ErrorIcon from '@mui/icons-material/Error';
import React, {Fragment} from 'react';

const AddProductProgress = (props) => {
    const {sectionState} = props;

    return (
        <div className='add-product-progress'>
            {sectionState.map((section, idx) => {
                const {status, label} = section;

                return (
                    <Fragment key={idx}>
                        <div className='d-flex align-items-center gap-2'>
                            <div className={`progress-state progress-state--${status}`}>
                                {status === 'active' ? (
                                    <div className='state-active'></div>
                                ) : status === 'error' ? (
                                    <ErrorIcon />
                                ) : status === 'done' ? (
                                    <Tick />
                                ) : null}

                                {idx < sectionState.length - 1 && <div className='progress-state__connector'></div>}
                            </div>
                            <span data-touched={status !== 'inactive'}>{label}</span>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default AddProductProgress;
