import React from "react";
import FilterSection from "./FilterSection";
import TableSection from "./TableSection";

const index = (props) => {
    const { tableSectionProps, filterSectionProps } = props;
    return (
        <main className="voucher-setting">
            <FilterSection {...filterSectionProps} />
            <TableSection {...tableSectionProps} />
        </main>
    );
};

export default index;