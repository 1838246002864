import {AddImage, Trash} from 'assets/images';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import {deleteCategoriesImage, uploadCategoryImage} from '../../designCategorySlice';
import Config from 'configuration';

const accept = ['.jpg', '.png', '.gif', '.jpeg'];
const ItemCard = ({data, save, setSave, intl}) => {
    const [uploadImg, setUploadImg] = useState();
    const dispatch = useDispatch();
    const handleUploadImage = async (e) => {
        const files = e.target.files;
        // Max 2MB
        const maxCapacity = 2 * 1024 * 1024;
        let isOverSize = false;
        for (const file of files) {
            const {size} = file;

            if (size > maxCapacity) {
                isOverSize = true;
                Toast(false, intl.messages.overSize);
                return;
            }
            const fileUrl = await getBase64(file);
            const result = {image: fileUrl, file};
            setUploadImg(result);
        }
    };
    const handleDelete = () => {
        setUploadImg(null);
        data.image = null;
    };
    const uploadeImage = async () => {
        const fileFormData = new FormData();
        const file = uploadImg.file;
        fileFormData.append('file', file);
        const res = await dispatch(uploadCategoryImage({id: data.id, file: fileFormData}));
        if (res.payload.success) {
            setSave(false);
        }
    };
    const deleteImage = async () => {
        const res = await dispatch(deleteCategoriesImage(data));
    };
    useEffect(() => {
        if (save) {
            uploadeImage();
            if (data.image === null && uploadImg === null) {
                deleteImage();
            }
        }
    }, [save]);
    return (
        <div className='item-container'>
            <div className='col-img'>
                <div className='img-upload'>
                    {uploadImg || data?.image ? (
                        <>
                            {uploadImg ? (
                                <>
                                    <img src={uploadImg.image} className='img-view' />
                                    <Trash className='middle' onClick={handleDelete} />
                                </>
                            ) : (
                                <>
                                    <img src={`${Config.apiConfig.endPoint}${data.image}`} className='img-view' />
                                    <Trash className='middle' onClick={handleDelete} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <input
                                id='file'
                                type={'file'}
                                accept={accept.join(', ')}
                                className='input-hold'
                                onChange={handleUploadImage}
                            />
                            <div className='add-img'>
                                <AddImage />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className='col-categories'>{data?.name}</div>
        </div>
    );
};

export default ItemCard;
