import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import shippingUnitApi from 'api/shippingUnit';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {};
};

export const getShippingUnits = createAsyncThunk('adminDeliverySetting/getShippingUnits', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.getShippingUnits,
        payload: params
    });
});

export const addShippingUnit = createAsyncThunk('adminDeliverySetting/addShippingUnit', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.addShippingUnit,
        payload: data
    });
});

export const editShippingUnit = createAsyncThunk('adminDeliverySetting/editShippingUnit', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.editShippingUnit,
        payload: data
    });
});

export const deleteShippingUnit = createAsyncThunk('adminDeliverySetting/deleteShippingUnit', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.deleteShippingUnit,
        payload: id
    });
});

const deliverySetting = createSlice({
    name: 'deliverySetting',
    initialState,
    reducers: {},
    extraReducers: (builders) => {}
});

const { reducer, actions } = deliverySetting;
export const {} = actions;
export default reducer;
