import { addActive, addDeactive, minusActive, minusDeactive } from 'assets/images';
import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const CustomInputNumber = forwardRef((props, ref) => {
    const {
        quantity = 1,
        decrementHandle,
        incrementHandle,
        handleQuantityChange,
        handleQuantityBlur,
        disabled = false,
        maxValue = 100,
        minValue = 1,
        allowNegative = true
    } = props;

    const isIncrementActive = !disabled && quantity < maxValue;
    const isDecrementActive = !disabled && quantity > minValue;

    return (
        <div className='custom-input-number' ref={ref}>
            <button
                className='control-btn control-decrease'
                onClick={() => {
                    if (isDecrementActive) decrementHandle();
                }}
                disabled={!isDecrementActive}>
                <img src={isDecrementActive ? minusActive : minusDeactive} alt='decrease by 1' />
            </button>
            <NumericFormat
                className='control-quantity'
                value={quantity}
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                type='number'
                max={maxValue}
                min={minValue}
                allowNegative={allowNegative}
                disabled={disabled}
            />
            <button
                className='control-btn control-increase'
                onClick={() => {
                    if (isIncrementActive) incrementHandle();
                }}
                disabled={!isIncrementActive}>
                <img src={isIncrementActive ? addActive : addDeactive} alt='increase by 1' />
            </button>
        </div>
    );
});

export default CustomInputNumber;
