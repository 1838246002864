import React, { useEffect } from 'react';

import {authSelector, productDetailsSelector, userLikeListSelector} from 'app/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import i18nLabels from './productDetails.i18n';

import ProductDetailsContainer from './ProductDetailsContainer';
import DecorateContainer from 'components/DecorateContainer';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function ProductDetails(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {productId} = useParams();
    const auth = useSelector(authSelector);
    const productDetails = useSelector(productDetailsSelector);

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Product detail');
    }, [])

    const productDetailsProps = {
        ...props,
        i18nLabels,
        dispatch,
        auth,
        history,
        productId,
        productDetails
    };

    return <DecorateContainer {...productDetailsProps} childComponent={ProductDetailsContainer} />;
}
