import {Plus, WhitePlus} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';
import FilterBar from './subViews/FilterBar';
import FilterBarAddNew from './subViews/FilterBarAddNew';
import {FlashDeal} from './subViews/FlashDeal';
import {FlashDealAddNew} from './subViews/FlashDealAddNew';
// import ShippingFormModal from './subViews/ShippingFormModal';
// import ShippingUnitsTable from './subViews/ShippingUnitsTable';

const FlashDealMainView = (props) => {
    const {
        intl,
        shippingUnitsTableProps,
        shippingFormModalProps,
        handleOpenModal,
        filterBarProps,
        isAddNew,
        handleAddNew,
        handleAddNewModal,
        setAddNewModal,
        isAddNewModal,
        isEdit,
        setIsEdit,
        isDublicate
    } = props;

    return (
        <div className='marketing-setting'>
            {/* <ShippingFormModal {...shippingFormModalProps} />
            <div className="delivery-setting__header">
                <h2 className='delivery-setting__header-title'>{intl.messages.shippingUnitList}</h2>
                <CustomButton size='md' onClick={() => handleOpenModal()} >
                    <img src={WhitePlus} alt="plus" />
                    {intl.messages.addNew}
                </CustomButton>
            </div>
            <ShippingUnitsTable {...shippingUnitsTableProps}/> */}
            {!isAddNew && !isEdit && !isDublicate ? (
                <>
                    <FilterBar {...filterBarProps} />
                    <FlashDeal {...props}></FlashDeal>
                </>
            ) : (
                <>
                    <FilterBarAddNew {...props} />
                    <FlashDealAddNew {...props} />
                </>
            )}
            {/* <FilterBar {...filterBarProps} />
            <FlashDeal {...props}></FlashDeal> */}
        </div>
    );
};

export default FlashDealMainView;
