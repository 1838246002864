import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import MyLikeProductContainer from './MyLikeProductContainer';
import i18nLabels from './myLikeProduct.i18n';
import {authSelector, userLikeListSelector} from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';

const UserVoucher = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLikeList = useSelector(userLikeListSelector);
    const auth = useSelector(authSelector);
    const myLikeProductProps = {
        ...props,
        dispatch,
        history,
        i18nLabels,
        userLikeList,
        auth,
    };
    return <DecorateContainer childComponent={MyLikeProductContainer} {...myLikeProductProps} />;
};

export default UserVoucher;
