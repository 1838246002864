import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const productApi = {
    getProducts: ({data, signal}) => {
        const url = '/api/products';
        return axiosClient.get(url, {params: data, signal});
    },

    getRecommendList: () => {
        const url = '/api/products/recommendProductList';
        return axiosClient.get(url);
    },

    getProductDetails: (id) => {
        const url = '/api/products';
        return axiosClient.get(`${url}/${id}`);
    },

    deleteProduct: (id) => {
        const url = '/api/products';
        return axiosClient.delete(`${url}/${id}`);
    },

    updateProduct: (data) => {
        const {id} = data;
        const url = '/api/products';
        return axiosClient.put(`${url}/${id}`, data);
    },

    updateAllDetailProduct: (data) => {
        const {id, formData} = data;
        const url = '/api/products/update';
        return axiosFormData.put(`${url}/${id}`, formData);
    },

    addNewProduct: (data) => {
        const url = 'api/products/addNew';

        return axiosFormData.post(url, data);
    },

    uploadProductImages: (data) => {
        const {id, file, params} = data;

        const url = `/api/products/${id}/images`;
        return axiosFormData.put(url, file, {params});
    },

    deleteProductImage: (data) => {
        const url = `/api/products/${data}/product`;
        return axiosFormData.delete(url);
    },

    getSubProductDetails: (id) => {
        const url = '/api/subProduct';
        return axiosClient.get(`${url}/${id}/product`);
    },
    getLikeProduct: (data) => {
        const url = '/api/myWishListProducts';
        return axiosClient.get(url, {...data});
    }
};

export default productApi;
