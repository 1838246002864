import PreviewImage from 'components/PreviewImage/PreviewImage';
import { FormattedNumber } from 'react-intl';

const ReviewDetailRefund = (props) => {
    const { intl, detailRefund } = props;

    return (
        <div className='review-detail'>
            {detailRefund && (
                <div>
                    <div className='review-detail__reason'>
                        <div className='refund-section-title mt-3'>{intl.messages.reasonLabel}</div>
                        <div>
                            <div className='content'>{detailRefund.reason || ''}</div>
                            <div className='attachment'>
                                {detailRefund.attachments?.map((item, index) => (
                                    <PreviewImage
                                        key={index}
                                        image={item}
                                        height='112px'
                                        width='112px'
                                        className='mr-2'
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='review-detail__note'>
                        <div className='refund-section-title'>{intl.messages.note}</div>
                        <div className='content'>{detailRefund.note}</div>
                    </div>
                    <hr />
                    <div className='review-detail__amount'>
                        <div className='refund-section-title'>{intl.messages.refundAmount}</div>
                        <div className='content'>
                            <FormattedNumber style='currency' currency='VND' value={detailRefund.money_refund} />
                        </div>
                    </div>
                    <hr />
                    <div className='review-detail__email'>
                        <div className='refund-section-title'>{intl.messages.yourEmail}</div>
                        <div className='content'>{detailRefund.send_email || '--'}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewDetailRefund;
