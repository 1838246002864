import {Breadcrumbs} from '@mui/material';
import React from 'react';

const CustomBreadcrumb = (props) => {
    const {breadcrumbs = [], label = 'breadcrumb', separator = '/', navigateTo} = props;

    return (
        <Breadcrumbs className='custom-breadcrumb' aria-label={label} separator={separator}>
            {breadcrumbs.map((page, idx) => (
                <div
                    className={`breadcrumb-item ${idx === breadcrumbs.length - 1 ? 'last-item' : ''}`}
                    key={page.title}
                    onClick={() => {
                        if (page.to) {
                            navigateTo(page.to);
                        }
                    }}>
                    {page.title}
                </div>
            ))}
        </Breadcrumbs>
    );
};

export default CustomBreadcrumb;
