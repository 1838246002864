import React from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import GroupInput from 'components/GroupInput/GroupInput';
import {Controller} from 'react-hook-form';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import {DatePicker} from 'antd';
import moment from 'moment';

const BasicInfo = (props) => {
    const {control, intl, statusList, userLoginInfo} = props;
    const expirationGroupInput = [
        {
            name: 'startDate',
            placeholder: intl.messages.applyFrom
        },
        {
            name: 'endDate',
            placeholder: intl.messages.expiryDate
        }
    ];

    return (
        <div className='basicInfo'>
            <div className='basicInfo-input'>
                <Controller
                    name='name'
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <CustomInput
                            label={intl.messages.voucherName}
                            placeholder={intl.messages.voucherNamePlaceholder}
                            isRequired={true}
                            {...field}
                        />
                    )}
                />
            </div>

            <div className='basicInfo-input'>
                <Controller
                    name='code'
                    control={control}
                    render={({field}) => (
                        <CustomInput
                            label={intl.messages.voucherCode}
                            placeholder={intl.messages.voucherCodePlaceholder}
                            isRequired={true}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className='basicInfo-input'>
                <Controller
                    name='quantity'
                    control={control}
                    render={({field}) => (
                        <CustomInput
                            type={'number'}
                            label={intl.messages.quantity}
                            placeholder={intl.messages.quantityPlaceholder}
                            isRequired={true}
                            {...field}
                        />
                    )}
                />
            </div>

            <div className='basicInfo-input'>
                <GroupInput
                    type='date'
                    control={control}
                    label={
                        <>
                            {intl.messages.expiration}
                            <span style={{color: '#de4841'}}>*</span>
                        </>
                    }
                    groupInputData={expirationGroupInput}
                    disabledDate={(current) => current.isBefore(moment())}
                />
            </div>
            <div className='basicInfo-input'>
                <Controller
                    name='status'
                    control={control}
                    render={({field}) => (
                        <CustomSelect
                            label={intl.messages.status}
                            placeholder={intl.messages.statusPlaceholder}
                            options={statusList.map((item) => ({id: item.id, value: item.name}))}
                            {...field}
                        />
                    )}
                />
            </div>

            <div className='basicInfo-input voucher__list__filter__activeDate'>
                <fieldset>
                    <legend>
                        <span style={{color: 'rgba(0, 0, 0, 0.38)'}} className='date-label'>
                            {intl.messages.createdAt}
                        </span>
                    </legend>
                    <Controller
                        name='createdAt'
                        control={control}
                        render={({field: {onBlur, ...restField}}) => {
                            const blurHandle = (e) => {
                                onBlur(e);
                            };
                            const baseInputProps = {
                                className: 'voucher__list__filter__activeDate__picker',
                                onBlur: blurHandle
                            };
                            return (
                                <DatePicker
                                    disabled
                                    placeholder={intl.messages.activeDatePlaceholder}
                                    bordered={false}
                                    size='large'
                                    {...restField}
                                    {...baseInputProps}
                                />
                            );
                        }}
                    />
                </fieldset>
            </div>
            <div className='basicInfo-input'>
                <CustomInput label={intl.messages.creator} defaultValue={userLoginInfo.full_name} disabled />
            </div>
        </div>
    );
};

export default BasicInfo;
