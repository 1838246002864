import {pagePath} from 'configuration/routeConfig';
import AddressList from './template/subViews/AddressList';
import Profile from './template/subViews/Profile';
import Notification from 'features/Notification';
import UserVoucher from './template/subViews/UserVoucher';
import MyPurchase from './template/subViews/MyPurchase';
import MyDetailPurchase from './template/subViews/MyDetailPurchase';
import MyLikeProduct from './template/subViews/MyLikeProduct';
import MyRefund from './template/subViews/Refund';
import MobileLayout from './template/subViews/MobileLayout/MobileLayout';

export default function propsProvider(props) {
    const {intl, dispatch, sidebarItems, toggleAccordion, navigateTo} = props;

    const userRouteList = [
        {path: pagePath.userUrl, component: MobileLayout},
        {path: pagePath.userProfileUrl, component: Profile},
        {path: pagePath.userNotiUrl, component: Notification},
        {path: pagePath.userNotiOrderUrl, component: Notification},
        {path: pagePath.userNotiPromotionUrl, component: Notification},
        {path: pagePath.userNotiOtherUrl, component: Notification},
        {path: pagePath.userAddressUrl, component: AddressList},
        {path: pagePath.userMyPurchaseUrl, component: MyPurchase},
        {path: pagePath.userMyDetailPurchaseUrl, component: MyDetailPurchase},
        {path: pagePath.userVoucherUrl, component: UserVoucher},
        {path: pagePath.userMyLikeProductUrl, component: MyLikeProduct},
        {path: pagePath.userRefundUrl, component: MyRefund}
    ];

    return {
        intl,
        dispatch,
        userRouteList,
        userSidebarProps: {
            sidebarItems,
            navigateTo,
            toggleAccordion
        }
    };
}
