import {authSelector, productListSelector} from 'app/selectors';
import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ProductListContainer from './ProductListContainer';
import i18nLabels from './productList.i18n';
import {useHistory} from 'react-router-dom';
import DecorateContainer from 'components/DecorateContainer';
import {useQuery} from 'utils/hooks/useQuery';
import {paramsKey} from 'configuration/storageKey';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function ProductList(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const productList = useSelector(productListSelector);
    const auth = useSelector(authSelector);

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Product list');
    }, []);

    const productListProps = {
        ...props,
        i18nLabels,
        dispatch,
        productList,
        auth,
        history,
        searchValue: query.get(paramsKey.searchBy),
        searchCate: query.get(paramsKey.categoryNameId)
    };
    return <DecorateContainer {...productListProps} childComponent={ProductListContainer} />;
}
