import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Toast} from 'utils/toast';
import propsProvider from './ShopInformationPropsProvider';
import {addBrandAddress, deleteBrandAddress, getAddressList, getBrandAddresses, getBrandById, updateBrand, updateBrandAddress, uploadBrandLogo} from './ShopInformationSlice';
import ShopInformationMainView from './template/ShopInformationMainView';

const ShopInformationContainer = (props) => {
    const {dispatch, adminShopInformation} = props;
    const intl = useIntl();
    const [isEdit, setIsEdit] = useState(false);
    const [brandLogo, setBrandLogo] = useState(null);
    const [isOpenAddressFormModal, setOpenAddressFormModal] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [addressModalType, setAddressModalType] = useState('ADD');
    const [selectedAddressObj, setSelectedAddressObj] = useState({});
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const handleSubmitShopInfo = async (data) => {
        const id = '9864b99e-3176-4d79-a67b-961b61125609';
        const payload = {
            business_registration_number: data.businessRegisNumber,
            registration_date: data.issueDate,
            tax_code: data.taxCode,
            phone: data.phoneNumber,
            email: data.email,
            location: data.address,
            description: data.shopDescription
        };
        const res = await dispatch(updateBrand({id, payload})).unwrap();
        const {success} = res;
        if (success) {
            dispatch(getBrandById(id));
            setIsEdit(false);
            Toast(success, 'Chỉnh sửa thông tin thành công!');
        } else {
            Toast(success, 'Chỉnh sửa thông tin thất bại!');
        }
    };

    const handleUploadLogo = async (e) => {
        const file = e.target.files[0];
        const {size} = file;

        if (size > 2 * 1024 * 1024) {
            Toast(false, intl.messages.overSize);
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        const res = await dispatch(
            uploadBrandLogo({id: '9864b99e-3176-4d79-a67b-961b61125609', file: formData})
        ).unwrap();
        const {success} = res;
        if (success) {
            fetchBrandInfo();
            Toast(success, 'Chỉnh sửa logo thành công');
        } else {
            Toast(success, 'Chỉnh sửa logo thất bại');
        }
        e.target.value = null;
    };

    const fetchBrandInfo = async () => {
        const res = await dispatch(getBrandById('9864b99e-3176-4d79-a67b-961b61125609')).unwrap();
        const {success} = res;
        if (success) {
            setBrandLogo(res?.data?.logo);
        }
    };

    const onOpenDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setSelectedAddressId(id);
    };
    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleDeleteAddress = async () => {
        const res = await dispatch(deleteBrandAddress(selectedAddressId)).unwrap();
        const {success} = res;

        if (success) {
            fetchBrandAddresses();
            Toast(success, 'Xóa địa chỉ thành công');
        } else {
            Toast(success, 'Xóa địa chỉ thất bại');
        }
        closeDeleteModal();
    };

    const closeAddressFormModal = () => {
        setOpenAddressFormModal(false);
    };
    const onOpenAddressFormModal = (address) => {
        if (address) {
            const {id} = address;
            setAddressModalType('UPDATE');
            setSelectedAddressId(id);
            setSelectedAddressObj(address);
        } else {
            setAddressModalType('ADD');
        }
        setOpenAddressFormModal(true);
    };
    const onSubmitAddressFormModal = async (data, e) => {
        e.preventDefault();
        if (data) {
            const payload = {
                brand_id: '9864b99e-3176-4d79-a67b-961b61125609',
                province_code: data.city_obj?.code,
                district_code: data.district_obj?.code,
                ward_code: data.ward_obj?.code,
                address: data.house_address,
                representative_name: data.receive_name,
                representative_phone: data.receive_phone,
                is_default: data.is_set_default,
                is_pickup: data.is_set_pickup,
                is_return: data.is_set_return
            };

            const res = await dispatch(
                addressModalType === 'ADD'
                    ? addBrandAddress(payload)
                    : updateBrandAddress({id: selectedAddressId, ...payload})
            ).unwrap();
            const {success} = res;
            if (success) {
                fetchBrandAddresses();
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thành công`);
            } else {
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thất bại!`);
            }

            closeAddressFormModal();
        }
    };

    const fetchBrandAddresses = async() => {
        await dispatch(getBrandAddresses({
            Sorts: '-created_at'
        }))
    }

    useEffect(() => {
        fetchBrandInfo();
        dispatch(getAddressList());
        fetchBrandAddresses();
    }, []);

    const shopInformationContainerProps = {
        ...props,
        dispatch,
        intl,
        adminShopInformation,
        handleSubmitShopInfo,
        isEdit,
        setIsEdit,
        brandLogo,
        handleUploadLogo,
        isOpenAddressFormModal,
        closeAddressFormModal,
        onOpenAddressFormModal,
        onSubmitAddressFormModal,
        addressModalType,
        selectedAddressObj,
        isOpenDeleteModal,
        onOpenDeleteModal,
        closeDeleteModal,
        handleDeleteAddress
    };

    return <ShopInformationMainView {...propsProvider(shopInformationContainerProps)} />;
};

export default ShopInformationContainer;
