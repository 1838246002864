import SectionTitle from 'components/SectionTitle/SectionTitle';
import React from 'react';

const TransportInformation = (props) => {
    const {transportInfoProps, addressFormModalProps, addressListModalProps} = props;
    const {intl, transportInfoData} = transportInfoProps;

    return (
        <div className='payment-transport-information'>
            {transportInfoData ? (
                <div className='d-flex justify-content-between align-items-center'>
                    <SectionTitle paled={false}>{'Thông tin vận chuyển'}</SectionTitle>
                    <div>
                        <span
                            style={{color: '#4568f2', fontSize: 15, cursor: 'pointer'}}
                            onClick={() => addressListModalProps.onOpenAddressListModal()}>
                            Thay đổi
                        </span>
                    </div>
                </div>
            ) : (
                <div>
                    <SectionTitle paled={false}>{'Thông tin vận chuyển'}</SectionTitle>
                </div>
            )}

            <div className='row justify-content-between'>
                <div className={`${transportInfoData ? 'col' : 'col-9'}`}>
                    {transportInfoData ? (
                        <div>
                            <div className='row'>
                                <div className='col-4 col-sm-3'>
                                    <span style={{color: '#808080', fontSize: 15}}>Người nhận</span>
                                </div>
                                <div className='col-8 col-sm-9'>
                                    <div className='d-flex align-items-center'>
                                        <span
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 500
                                            }}>
                                            {transportInfoData.fullname} &nbsp;
                                        </span>
                                        <div
                                            style={{
                                                width: 4,
                                                height: 4,
                                                backgroundColor: '#c3c9d0',
                                                borderRadius: '50%'
                                            }}></div>
                                        <span
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 500
                                            }}>
                                            &nbsp; {transportInfoData.phone}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 col-sm-3'>
                                    <span style={{color: '#808080', fontSize: 15}}>Địa chỉ nhận hàng</span>
                                </div>
                                <div className='col-8 col-sm-9'>
                                    <span
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 500
                                        }}>
                                        {transportInfoData.full_address}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <span style={{color: '#333333', fontSize: 15}}>
                            <i>Chưa có thông tin vận chuyển</i>
                        </span>
                    )}
                </div>
                {!transportInfoData && (
                    <div className='col-3' style={{textAlign: 'right'}}>
                        <span
                            style={{color: '#4568f2', fontSize: 15, cursor: 'pointer'}}
                            onClick={() => addressFormModalProps.onOpenAddressFormModal()}>
                            Thêm mới địa chỉ
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransportInformation;
