import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Toast} from 'utils/toast';
import propsProvider from './addressListPropsProvider';
import {
    addUserAddress,
    deleteUserAddress,
    getAddressList,
    getUserAddressList,
    updateUserAddress
} from './addressListSlice';
import AddressListMainView from './template/AddressListMainView';

const AddressListContainer = (props) => {
    const {dispatch, history, addressList} = props;
    const intl = useIntl();

    const [isOpenAddressFormModal, setOpenAddressFormModal] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [addressModalType, setAddressModalType] = useState('ADD');
    const [selectedAddressObj, setSelectedAddressObj] = useState({});
    const [modalDeleteContent, setModalDeleteContent] = useState(null);

    const navigateTo = (path) => {
        history.push(path);
    };

    const onOpenDeleteModal = (id) => {
        setModalDeleteContent({
            content: intl.messages.deleteAddress,
            onConfirm: () => handleDeleteAddress(id),
            title: intl.messages.confirmText,
            closeText: intl.messages.accountSettingUpdateModalCancelButton,
            confirmText: intl.messages.delete,
            onClose: () => setModalDeleteContent(null)
        });
    };

    const handleDeleteAddress = async (id) => {
        const res = await dispatch(deleteUserAddress(id)).unwrap();
        const {success} = res;

        if (success) {
            fetchUserAddressList();
            Toast(success, 'Xóa địa chỉ thành công');
        } else {
            Toast(success, 'Xóa địa chỉ thất bại');
        }
        setModalDeleteContent(null);
    };

    const closeAddressFormModal = () => {
        setOpenAddressFormModal(false);
    };
    const onOpenAddressFormModal = (address) => {
        if (address) {
            const {id} = address;
            setAddressModalType('UPDATE');
            setSelectedAddressId(id);
            setSelectedAddressObj(address);
        } else {
            setAddressModalType('ADD');
        }
        setOpenAddressFormModal(true);
    };
    const onSubmitAddressFormModal = async (data, e) => {
        e.preventDefault();
        if (data) {
            const payload = {
                province_code: data.city_obj?.code,
                district_code: data.district_obj?.code,
                ward_code: data.ward_obj?.code,
                address: data.house_address,
                fullname: data.receive_name,
                phone: data.receive_phone,
                is_default: addressModalType === 'ADD' ? true : data.is_set_default
            };

            const res = await dispatch(
                addressModalType === 'ADD'
                    ? addUserAddress(payload)
                    : updateUserAddress({id: selectedAddressId, ...payload})
            ).unwrap();
            const {success} = res;
            if (success) {
                fetchUserAddressList();
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thành công`);
            } else {
                Toast(success, `${addressModalType === 'ADD' ? 'Thêm' : 'Chỉnh sửa'} địa chỉ thất bại!`);
            }

            closeAddressFormModal();
        }
    };

    const fetchUserAddressList = async () => {
        await dispatch(getUserAddressList({Sorts: '-created_at'}));
    };

    useEffect(() => {
        fetchUserAddressList();
        dispatch(getAddressList({Sorts: 'name'}));
    }, []);

    const addressListContainerProps = {
        intl,
        navigateTo,
        isOpenAddressFormModal,
        closeAddressFormModal,
        onOpenAddressFormModal,
        onSubmitAddressFormModal,
        addressList,
        addressModalType,
        selectedAddressObj,
        onOpenDeleteModal,
        handleDeleteAddress,
        modalDeleteContent
    };

    return <AddressListMainView {...propsProvider(addressListContainerProps)} />;
};

export default AddressListContainer;
