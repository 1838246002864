import React from 'react';

const StatusBox = (props) => {
    let {
        status,
        borderColor = null,
        style = {
            backgroundColor: status.bgcolor,
            color: status.color,
            border: `1px solid ${borderColor ? borderColor : status.color}`,
            borderRadius: '4px',
            padding: '4px 8px',
            textAlign: 'center',
            fontSize: '13px',
            whiteSpace: 'nowrap',
            width: '100px'
        }
    } = props;

    return (
        <div className='status-box' style={style}>
            {status.name}
        </div>
    );
};

export default StatusBox;
