import {Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React, {useCallback, useLayoutEffect} from 'react';
import {Controller} from 'react-hook-form';
import {close, emptyCode, VoucherAble, VoucherApplied, VoucherDisabled} from 'assets/images';
import {useState} from 'react';
import moment from 'moment';
import {moreInformation} from 'assets/images';
import {getVoucher} from 'features/Cart/cartSlice';

export const defaultValues = {
    coupon: ''
};

const VoucherModal = (props) => {
    const {
        intl,
        dispatch,
        isOpenVoucherModal,
        closeVoucherModal,
        control,
        isApplyDisabled,
        handleSubmit,
        voucherList = [],
        reset,
        cartProductItems = [],
        totalPrice,
        setAppliedVoucher,
        appliedVoucher
    } = props;

    const [tempVoucherList, setTempVoucherList] = useState([]);
    const [couponStatus, setCouponStatus] = useState({});

    const getVoucherCode = async (data, event) => {
        const {coupon} = data;

        const responseData = await dispatch(getVoucher(coupon)).unwrap();
        event.target?.classList.add('wasvalidated');

        const {success, data: voucherData} = responseData;

        if (success) {
            const isError = handleCheckVoucherError(voucherData);

            if (isError) {
                setCouponStatus({success: false, message: intl.messages.couponCodeFail});
            } else {
                setAppliedVoucher(voucherData);
                setCouponStatus({success: true});
            }
        } else {
            setCouponStatus(responseData);
        }
    };
    const handleSetVoucher = async (voucherCode = '') => {
        const newVoucherList = [];

        for (const tempVoucher of tempVoucherList) {
            const isSameVoucherId = tempVoucher.id === voucherCode;
            if (isSameVoucherId) {
                if (tempVoucher.isCurrentlyApplied) {
                    setAppliedVoucher(null);
                    tempVoucher.isCurrentlyApplied = false;
                } else {
                    setAppliedVoucher(tempVoucher);
                    tempVoucher.isCurrentlyApplied = true;
                }
            } else {
                tempVoucher.isCurrentlyApplied = isSameVoucherId;
            }

            newVoucherList.push(tempVoucher);
        }

        setTempVoucherList(newVoucherList);
    };
    const handleCheckVoucherError = useCallback(
        (voucher) => {
            if (voucher) {
                const {expiry, used_quantity, quantity, min_order_apply, category} = voucher;
                const isExpired = new Date(expiry) < new Date();
                const isFull = used_quantity === quantity;
                let categoryProductPrice = 0;
                let isCategoryIncluded = false;

                cartProductItems.items?.forEach((value) => {
                    if (value.category_ids.some((id) => category.includes(id))) {
                        isCategoryIncluded = true;

                        if (value.isChecked) {
                            categoryProductPrice += value.price * value.quantityInCart;
                        }
                    }
                });

                const isNotPassMinValue = categoryProductPrice < min_order_apply;

                const isError = isExpired || isFull || isNotPassMinValue || !isCategoryIncluded;
                return isError;
            } else return false;
        },
        [cartProductItems.items]
    );

    useLayoutEffect(() => {
        const newVoucherList = voucherList.map((voucher) => {
            const isError = handleCheckVoucherError(voucher);

            return {
                ...voucher,
                isCurrentlyApplied: appliedVoucher?.code === voucher.code,
                isDisabled: isError,
                voucherMessage: isError ? intl.messages.couponCodeFail : null
            };
        });

        setTempVoucherList(newVoucherList);
    }, [voucherList, appliedVoucher, handleCheckVoucherError]);

    useLayoutEffect(() => {
        if (!isOpenVoucherModal) {
            const timeout = setTimeout(() => {
                reset(defaultValues);
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [isOpenVoucherModal]);

    useLayoutEffect(() => {
        const isError = handleCheckVoucherError(appliedVoucher);

        if (isError) setAppliedVoucher(null);
    }, [handleCheckVoucherError]);

    const voucherHeader = (
        <>
            <span>{intl.messages.voucherCode}</span>
            <button className='dialog-title__close'>
                <img src={close} alt='' onClick={closeVoucherModal} />
            </button>
        </>
    );
    const voucherBody = (
        <div className='voucher-modal'>
            <p className='voucher-modal__title mb-4'>
                <span>{intl.messages.voucherModalTitle}</span>
            </p>

            <form
                noValidate
                action='#'
                autoComplete='off'
                onSubmit={handleSubmit(getVoucherCode)}
                className='voucher-holder'>
                <FormControl className='voucher-holder__input'>
                    <Controller
                        name='coupon'
                        control={control}
                        render={({field}) => (
                            <CustomInput
                                {...field}
                                label={intl.messages.couponCode}
                                placeholder={intl.messages.voucherModalPlaceholder}
                                valid={couponStatus.success}
                            />
                        )}
                    />
                </FormControl>
                <CustomButton
                    size='md'
                    variant='text'
                    className='voucher-holder__apply'
                    type='submit'
                    disabled={isApplyDisabled}>
                    {intl.messages.apply}
                </CustomButton>
            </form>
            {!couponStatus.success && (
                <FormHelperText error className='voucher-error__text'>
                    {couponStatus.message}
                </FormHelperText>
            )}

            <div className='voucher-container'>
                {tempVoucherList.length > 0 ? (
                    tempVoucherList.map((voucher) => (
                        <div className='voucher-detail' key={voucher.id}>
                            {voucher.isDisabled ? (
                                <VoucherDisabled />
                            ) : voucher.isCurrentlyApplied ? (
                                <VoucherApplied />
                            ) : (
                                <VoucherAble />
                            )}

                            <img src={moreInformation} alt='' className='more-information' />

                            <div className='voucher-content'>
                                <h4>{voucher.code}</h4>
                                <span>
                                    {intl.messages.expiredDate}: {moment(voucher.expiry).format('DD/MM/YYYY')}
                                </span>
                                {voucher.isDisabled ? (
                                    <span className='voucher-disabled'>
                                        {voucher.voucherMessage ? voucher.voucherMessage : intl.messages.notCondition}
                                    </span>
                                ) : (
                                    <div className='voucher-control'>
                                        <CustomButton
                                            size='sm'
                                            variant='outlined'
                                            fullwidth
                                            color={voucher.isCurrentlyApplied ? 'warning' : 'primary'}
                                            onClick={() => handleSetVoucher(voucher.id)}>
                                            {voucher.isCurrentlyApplied
                                                ? intl.messages.cancelApply
                                                : intl.messages.apply}
                                        </CustomButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='voucher-container--empty'>
                        <img src={emptyCode} alt='no coupon available' />
                        <p className='m-0'>
                            <span>{intl.messages.noCoupon} </span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
    const voucherFooter = (
        <CustomButton size='md' variant='text' onClick={closeVoucherModal}>
            {intl.messages.close}
        </CustomButton>
    );

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                fullWidth={true}
                open={isOpenVoucherModal}
                onClose={closeVoucherModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog dialog-voucherModal d-none d-sm-block'>
                <DialogTitle className='dialog-title' id='voucher-modal'>
                    {voucherHeader}
                </DialogTitle>
                <DialogContent className='dialog-content'>{voucherBody}</DialogContent>
                <DialogActions className='dialog-actions'>{voucherFooter}</DialogActions>
            </Dialog>

            <Drawer
                anchor='bottom'
                open={isOpenVoucherModal}
                onClose={closeVoucherModal}
                className='fixed-card__drawer d-block d-sm-none'>
                <div className='drawer-modal'>
                    <div className='drawer-modal__header'>{voucherHeader}</div>

                    <div className='drawer-modal__body'>{voucherBody}</div>

                    <div className='drawer-modal__footer d-flex justify-content-end'>
                        <div className='voucher-footer__wrapper'>{voucherFooter}</div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default VoucherModal;
