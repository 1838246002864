import SelectPopover from 'components/SelectPopover/SelectPopover';
import React, {forwardRef, useCallback, useEffect, useState} from 'react';
import CategorySelectList from './CategorySelectList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {CloseCircle} from 'assets/images';
import ScrollContainer from 'react-indiana-drag-scroll';

const CategorySelect = forwardRef((props, ref) => {
    const {
        categories,
        placeholder,
        label,
        value,
        onChange,
        setAttributes,
        multiple = false,
        isRequired = false,
        removeCategory
    } = props;
    const [displayValue, setDisplayValue] = useState(placeholder);
    const [transformCategories, setTransformCategories] = useState([]);

    const handleSelectValue = (displayValue) => setDisplayValue(displayValue);

    const selectPopover = {
        isRequired,
        label,
        popoverId: 'category-select',
        popoverProps: {className: 'category-menuList'},
        displayValue: (
            <ScrollContainer className='select-value'>
                {multiple ? (
                    <div className='d-flex align-items-center gap-2'>
                        {Array.isArray(displayValue) && displayValue.length > 0
                            ? displayValue.map((data) => (
                                  <div key={data.id} className='select-value__chip'>
                                      {data.value}
                                      <CloseCircle
                                          onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              removeCategory(data.id);
                                          }}
                                      />
                                  </div>
                              ))
                            : placeholder}
                    </div>
                ) : Array.isArray(displayValue) ? (
                    displayValue.map((value, idx) => (
                        <React.Fragment key={idx}>
                            <span>{value}</span>
                            {idx < displayValue.length - 1 && <KeyboardArrowRightIcon className='right-arrow' />}
                        </React.Fragment>
                    ))
                ) : (
                    displayValue
                )}
            </ScrollContainer>
        )
    };

    const categorySelectListProps = {
        data: transformCategories,
        isFirstSection: true,
        value,
        onChange,
        handleSelectValue,
        setAttributes,
        multiple
    };

    const recursiveTransformCategory = useCallback(
        (categoryData, selectedCategories = [], parentCategory = []) => {
            const parentNames = parentCategory.concat(categoryData.name);
            const includeParentFieldCategory = {
                ...categoryData,
                attributes: categoryData.attributes?.map((attr) => {
                    const attrValue = JSON.parse(attr);
                    return attrValue;
                }),
                parentNames
            };

            if (multiple) {
                if (value.includes(categoryData.id)) {
                    selectedCategories.push({id: categoryData.id, value: categoryData.name});
                }
            } else {
                if (value === categoryData.id) {
                    setDisplayValue(parentNames);
                }
            }

            if (!categoryData.children) {
                return includeParentFieldCategory;
            } else {
                const newChildren = categoryData.children.map((child) =>
                    recursiveTransformCategory(child, selectedCategories, parentNames)
                );

                return {
                    ...includeParentFieldCategory,
                    children: newChildren
                };
            }
        },
        [value, multiple]
    );

    useEffect(() => {
        if (categories) {
            const selectedCategories = [];
            const newCategories = categories.map((cat) => recursiveTransformCategory(cat, selectedCategories));

            if (multiple) {
                setDisplayValue(selectedCategories);
            }
            setTransformCategories(newCategories);
        }
    }, [categories, recursiveTransformCategory]);

    return (
        <SelectPopover {...selectPopover} ref={ref}>
            <CategorySelectList {...categorySelectListProps} />
        </SelectPopover>
    );
});

export default CategorySelect;
