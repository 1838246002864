import { storageKey } from 'configuration/storageKey';
import axiosClient from './axiosClient';
import axios from 'axios';

const eInvoiceBaseUrl = 'https://0123456789.ehoadon.net';
export const eInvoiceSerial = '2C23TYN';

const einvoiceApi = {
    createToken: (data) => {
        const url = '/api/token/create';
        return axios.post(url, data, { baseURL: eInvoiceBaseUrl, headers: { 'Content-type': 'application/json' } });
    },

    addInvoice: (data) => {
        const eInvoiceToken = sessionStorage.getItem(storageKey.eInvoice);
        const url = '/api/EInvoiceOnline/AddEInvoice';
        return axiosClient.post(url, data, {
            baseURL: eInvoiceBaseUrl,
            headers: {
                Authorization: `Bearer ${eInvoiceToken}`
            }
        });
    },

    getInvoice: (id) => {
        const eInvoiceToken = sessionStorage.getItem(storageKey.eInvoice);
        const url = '/api/EInvoiceOnline/GetInvoiceById';
        return axiosClient.get(`${url}/${id}`, {
            baseURL: eInvoiceBaseUrl,
            headers: {
                Authorization: `Bearer ${eInvoiceToken}`
            }
        });
    },

    publishInvoice: (data) => {
        const eInvoiceToken = sessionStorage.getItem(storageKey.eInvoice);
        const url = '/api/EInvoiceOnline/PublishInvoice';
        return axiosClient.post(url, data, {
            baseURL: eInvoiceBaseUrl,
            headers: {
                Authorization: `Bearer ${eInvoiceToken}`
            }
        });
    },

    sendEmail: (data) => {
        const eInvoiceToken = sessionStorage.getItem(storageKey.eInvoice);
        const url = '/EInvoice/SendNotificationEInvoice';
        return axiosClient.post(url, data, {
            baseURL: eInvoiceBaseUrl,
            headers: {
                Authorization: `Bearer ${eInvoiceToken}`
            }
        });
    }
};

export default einvoiceApi;
