import { Checkbox, FormControlLabel } from '@mui/material';
import { RightArrow, rightArrowBlue } from 'assets/images';
import React from 'react';

const SearchFilterExpand = (props) => {
    const { label, data, intl, checkedState, handleCheckedChange } = props;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const dataDisplay = isExpanded ? data : data.slice(0, 4);

    return (
        <section className='d-flex flex-column w-100'>
            {label && label !== "subCategory" && <p>{intl.messages[label]}</p>}

            <div className="search-filter__expand">
                {dataDisplay.map((category, idx) => (
                    <FormControlLabel
                        key={category.id}
                        label={category.name}
                        control={
                            <Checkbox
                                name={category.name}
                                id={category.id}
                                value={category.id}
                                checked={checkedState[idx] || false}
                                onChange={() => handleCheckedChange({ type: label, position: idx })}
                            />
                        }
                        className='filter-options__label'
                    />
                ))}
            </div>

            <div
                className='d-flex align-item-center justify-content-center gap-2 filter-options__expand'
                onClick={toggleExpanded}>
                {isExpanded ? intl.messages.readLess : intl.messages.readMore}
                <span>
                    <RightArrow />
                </span>
            </div>
        </section>
    );
};

export default SearchFilterExpand;
