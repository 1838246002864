import React from 'react';
import StallBlock from './StallBlock';

const DetailCart = (props) => {
    const {cartItems, ...stallProps} = props;
    const renderedStallBlock = [];

    if (cartItems.size > 0) {
        cartItems.forEach((value, key) => {
            renderedStallBlock.push(<StallBlock key={key} stall={value} {...stallProps} />);
        });
    }

    return <div className='cart-detail'>{renderedStallBlock}</div>;
};

export default DetailCart;
