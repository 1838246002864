// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-transport-information {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 24px 24px 0 rgba(0, 83, 125, 0.1);
  padding: 16px;
  margin-bottom: 16px;
}
.payment-transport-information .cancel-icon {
  width: 20px;
  height: 20px;
}
.payment-transport-information .cancel-icon path {
  fill: #de4841;
}
.payment-transport-information .confirm-icon {
  width: 20px;
  height: 20px;
}
.payment-transport-information .confirm-icon path {
  fill: #fff;
}
.payment-transport-information .close-icon {
  width: 20px;
  height: 20px;
}
.payment-transport-information .close-icon path {
  fill: #de4841;
}
.payment-transport-information .close-circle-icon {
  width: 20px;
  height: 20px;
}
.payment-transport-information .close-circle-icon path {
  fill: #de4841;
}
.payment-transport-information .tick-icon {
  width: 20px;
  height: 20px;
}
.payment-transport-information .tick-icon path {
  fill: #fff;
}
.payment-transport-information .approve-icon {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/features/Admin/template/subViews/Order/OrderDetail/template/subViews/OrderInformation/orderInformation.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,+CAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AACQ;EACI,aAAA;AACZ;AAGI;EACI,WAAA;EACA,YAAA;AADR;AAGQ;EACI,UAAA;AADZ;AAKI;EACI,WAAA;EACA,YAAA;AAHR;AAKQ;EACI,aAAA;AAHZ;AAOI;EACI,WAAA;EACA,YAAA;AALR;AAOQ;EACI,aAAA;AALZ;AASI;EACI,WAAA;EACA,YAAA;AAPR;AASQ;EACI,UAAA;AAPZ;AAWI;EACI,WAAA;EACA,YAAA;AATR","sourcesContent":[".payment-transport-information {\n    background-color: #fff;\n    border-radius: 4px;\n    box-shadow: 0 24px 24px 0 rgba(0, 83, 125, 0.1);\n    padding: 16px;\n    margin-bottom: 16px;\n\n    .cancel-icon {\n        width: 20px;\n        height: 20px;\n\n        path {\n            fill: #de4841;\n        }\n    }\n\n    .confirm-icon {\n        width: 20px;\n        height: 20px;\n\n        path {\n            fill: #fff;\n        }\n    }\n\n    .close-icon {\n        width: 20px;\n        height: 20px;\n\n        path {\n            fill: #de4841;\n        }\n    }\n\n    .close-circle-icon {\n        width: 20px;\n        height: 20px;\n\n        path {\n            fill: #de4841;\n        }\n    }\n\n    .tick-icon {\n        width: 20px;\n        height: 20px;\n\n        path {\n            fill: #fff;\n        }\n    }\n\n    .approve-icon {\n        width: 20px;\n        height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
