import {ClickAwayListener} from '@mui/base';
import React from 'react';
import {forwardRef} from 'react';

const CustomDropdown = forwardRef(({dropdownState = 'closed', children, className = '', closeDropdown, type}, ref) => {
    const clickAwayHandler = () => {
        if (dropdownState === 'open' && closeDropdown) {
            closeDropdown();
        }
    };

    return (
        <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={clickAwayHandler}>
            <div className={`custom-dropdown ${className}`} data-state={dropdownState} ref={ref}>
                {children}
            </div>
        </ClickAwayListener>
    );
});

export default CustomDropdown;
