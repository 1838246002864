import React from 'react';
import VoucherCard from './subViews/VoucherCard';
import CustomInput from 'components/CustomInput/CustomInput';
import {SearchIcon} from 'assets/images';
import VoucherModal from './subViews/VoucherModal';
import {noneVoucher} from 'assets/images';

const UserVoucherMainView = (props) => {
    const {
        onOpenVoucherModal,
        isOpenVoucherFormModal,
        selectedVoucherObj,
        closeVoucherModal,
        intl,
        handleSearch,
        displayList = []
    } = props;
    return (
        <>
            <VoucherModal
                isOpenVoucherFormModal={isOpenVoucherFormModal}
                closeVoucherModal={closeVoucherModal}
                voucherList={selectedVoucherObj}
                intl={intl}
            />
            <div className='voucher'>
                <div className='voucher-title'>
                    <h3 className='title'>{intl.messages.myVoucher}</h3>
                </div>
                {displayList.length > 0 ? (
                    <div>
                        <div className='voucher-search'>
                            <CustomInput
                                starticon={<SearchIcon />}
                                placeholder={intl.messages.enterVoucherCode}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                        <div className='voucher-body'>
                            {displayList.map((voucherList) => (
                                <VoucherCard 
                                    voucherList={voucherList}
                                    intl={intl}
                                    onOpenVoucherModal={onOpenVoucherModal}
                                    key={voucherList.id}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='none-voucher'>
                        <img
                            src={noneVoucher}
                            alt='none voucher'
                            className='d-flex'
                            style={{margin: 'auto', paddingTop: '48px'}}
                        />
                        <div
                            className='d-flex justify-content-center'
                            style={{marginTop: '16px', paddingBottom: '103px', fontStyle: 'italic'}}>
                            {intl.messages.noVoucher}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserVoucherMainView;
