import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    handleChangePage,
    handleChangePageSize,
    getVouchers,
    delVoucher,
    getCategories,
    getPaymentMethodList,
    getShippingList,
    getStatusList
} from './VoucherSlice';
import VoucherMainView from './template/VoucherMainView';
import { useSelector } from 'react-redux';
import { adminMarketingVoucherSelector, authSelector } from 'app/selectors';
import propsProvider from './VoucherPropsProvider';
import { useIntl } from 'react-intl';
import { Toast } from 'utils/toast';
import { getStartOfDate } from 'utils/getStartOfDate';
import { getEndOfDate } from 'utils/getEndOfDate';

const VoucherContainer = (props) => {
    const { dispatch } = props;
    const intl = useIntl();
    const adminMarketingVoucher = useSelector(adminMarketingVoucherSelector);
    const auth = useSelector(authSelector);
    const { userLoginInfo } = auth;
    const { voucherList, categoryList, paymentMethodList, shippingUnitList, statusList, paginationData, total } =
        adminMarketingVoucher;
    // Filter Section
    const { control, watch, setValue, reset } = useForm({
        defaultValues: {
            fromDate: null,
            toDate: null,
            activeDate: null,
            status: ''
        }
    });
    // Table Section
    const pageSizeRange = [5, 10, 15, 20];
    const { page, pageSize } = paginationData;
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(null);
    const [params, setParams] = useState({});

    const filterData = useCallback(
        (e) => {
            let filter = '';
            let filterForm = watch();
            if (filterForm.activeDate !== null)
                filter += `expiry>=${getStartOfDate(filterForm.activeDate).format()}, apply_date<=${getEndOfDate(
                    filterForm.activeDate
                ).format()},`;
            if (filterForm.toDate !== null) filter += `created_at<=${getEndOfDate(filterForm.toDate).format()},`;
            if (filterForm.fromDate !== null) filter += `created_at>=${getStartOfDate(filterForm.fromDate).format()},`;
            if (filterForm.status.length > 0) filter += `status==${filterForm.status}`;
            if (filter.length === 8) filter = '';
            setParams({ Filters: filter, Page: page, PageSize: pageSize });
        },
        [page, pageSize, watch]
    );

    const setPage = (value) => {
        dispatch(handleChangePage({ page: value }));
    };

    const setPageSize = (value) => {
        dispatch(handleChangePageSize({ pageSize: value }));
    };

    // Voucher Forms
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [editVoucher, setEditVoucher] = useState(null);

    const deleteVoucher = async () => {
        let res = await dispatch(delVoucher(isOpenDeleteModal.id));
        if (res.payload.success) {
            Toast(true, 'Xoá voucher thành công');
            setIsOpenDeleteModal(null);
            return dispatch(getVouchers(params));
        } else {
            Toast(false, 'Xoá voucher thất bại');
        }
    };

    useEffect(() => {
        filterData()
    }, [page, pageSize])

    useEffect(() => {
        dispatch(getVouchers(params));
    }, [params]);

    useEffect(() => {
        const paymentMethod = {
            Filters: 'type==payment'
        };

        dispatch(getCategories());
        dispatch(getShippingList());
        dispatch(getStatusList('VOUCHER'));
        dispatch(getPaymentMethodList(paymentMethod));
    }, []);

    const containerProps = {
        voucherList,
        setEditVoucher,
        intl,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        isOpen,
        setIsOpen,
        step,
        setStep,
        editVoucher,
        categoryList,
        paymentMethodList,
        shippingUnitList,
        control,
        watch,
        setValue,
        statusList,
        deleteVoucher,
        page,
        pageSize,
        reset,
        setPage,
        setPageSize,
        pageSizeRange,
        total,
        filterData,
        userLoginInfo,
        params
    };

    return <VoucherMainView {...propsProvider(containerProps)} />;
};

export default VoucherContainer;
