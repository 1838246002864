import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {close} from 'assets/images';
import Config from 'configuration';
import React, {useEffect} from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import {FormattedNumber} from 'react-intl';

const RefundViewModal = (props) => {
    const {
        intl,
        isOpenRefundModal,
        closeRefundModal,
        openRefundModal,
        productListProps,
        orderInformationProps,
        yourBillProps
    } = props;
    useEffect(()=>{
        console.log(productListProps)
    },[])
    return (
        <>
            <Dialog
                fullWidth={true}
                open={isOpenRefundModal}
                onClose={closeRefundModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <DialogTitle className='dialog-title'>
                    <span>{intl.messages.refundDetailModalTitle}</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                closeRefundModal();
                            }}
                        />
                    </button>
                </DialogTitle>
                <DialogContent className='dialog-content'>
                    <div className='refund-product'>
                        <h5>{intl.messages.productTitle}</h5>
                        {productListProps.productList
                            ? productListProps.productList.map((e) => (
                                      <div className='refund-product-item' key={e.productId}>
                                          <img src={`${Config.apiConfig.endPoint}${e.default_img}`} alt='' />
                                          <div className='item-info'>
                                              <h5>{e.name}</h5>
                                              <p>{e.type}</p>
                                              <p>{`Số lượng: ${e.quantity}`}</p>
                                          </div>
                                          <div className='item-price'>
                                              <FormattedNumber
                                                  style='currency'
                                                  currency='VND'
                                                  value={e.price}></FormattedNumber>
                                          </div>
                                      </div>
                              ))
                            : null}
                    </div>
                    <div className='reason-refund'>
                        <h5>{intl.messages.reason}</h5>
                        <div className='reason-content'>
                            <p>Hàng nhận được bị thiếu/ sai/ khác mô tả/ đã qua sử dụng</p>
                            <img src={`${Config.apiConfig.endPoint}`} />
                        </div>
                    </div>
                    <div className='reason-description'>
                        <h5>{intl.messages.reasonDescription}</h5>
                        <p>
                            {orderInformationProps.cancelDescription
                                ? orderInformationProps.cancelDescription
                                : 'Empty description'}
                        </p>
                    </div>
                    <div className='reason-email'>
                        <h5>Email</h5>
                        <p>{yourBillProps.billInfo?.user_email ? yourBillProps.billInfo?.user_email : 'Empty email'}</p>
                    </div>
                    <div className='reason-email'>
                        <h5>{intl.messages.refundTotal}</h5>
                        <p>
                            {yourBillProps.billInfo?.totalPrice ? (
                                <FormattedNumber
                                    style='currency'
                                    currency='VND'
                                    value={yourBillProps.billInfo?.totalPrice}></FormattedNumber>
                            ) : (
                                'Empty total price'
                            )}
                        </p>
                    </div>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                    <CustomButton
                        variant='text'
                        className='header-account__signup'
                        onClick={(e) => {
                            e.preventDefault();
                            closeRefundModal();
                        }}>
                        {intl.messages.closeText}
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RefundViewModal;
