import React from 'react';
import { BreadcrumbArrow } from 'assets/images';
import CustomBreadcrumb from 'components/CustomBreadcrumb/Breadcrumb';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import { Route, Switch, useLocation, matchPath, Redirect } from 'react-router-dom';
import { pagePath } from 'configuration/routeConfig';
import Sidebar from 'components/Sidebar/Sidebar';
import LayoutWithSidebar from 'components/LayoutWithSidebar/LayoutWithSidebar';

const AdminMainView = (props) => {
    const { breadcrumbProps, adminSidebarProps, adminRouteList, redirectAdminRouteList } = props;
    const location = useLocation();
    const { pathname } = location;
    const isSidebarRemoved =
        [pagePath.adminAddProductManagementUrl].includes(pathname) || [pagePath.adminOrderDetailUrl, pagePath.adminEditProductManagementUrl].some((path) => matchPath(pathname, { path }));

    return (
        <div className='admin'>
            <WrapperContainer bodyContent>
                <CustomBreadcrumb
                    {...breadcrumbProps}
                    label='admin page'
                    separator={<img src={BreadcrumbArrow} alt='' />}
                />

                <LayoutWithSidebar sidebar={!isSidebarRemoved && <Sidebar {...adminSidebarProps} />}>
                    <Switch>
                        {redirectAdminRouteList.map((redirect, idx) => (
                            <Redirect key={`redirect-${idx}`} exact from={redirect.from} to={redirect.to} />
                        ))}
                        {adminRouteList.map((route, idx) => (
                            <Route key={`route-${idx}`} exact path={route.path}>
                                {route.component}
                            </Route>
                        ))}
                    </Switch>
                </LayoutWithSidebar>
            </WrapperContainer>
        </div>
    );
};

export default AdminMainView;
