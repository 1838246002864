import React from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';
import {NumericFormat} from 'react-number-format';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const {onChange, ...other} = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

const Other = (props) => {
    const {control, intl, setValue} = props;

    return (
        <div className='other'>
            <div className='row mb-4'>
                <div className='col-md-3'>
                    <Controller
                        name='maxVal'
                        control={control}
                        render={({field}) => (
                            <CustomInput
                                label={intl.messages.maxVal}
                                placeholder={intl.messages.maxValPlaceholder}
                                isRequired={true}
                                inputComponentProps={NumericFormatCustom}
                                {...field}
                            />
                        )}
                    />
                </div>

                <div className='col-md-3'>
                    <Controller
                        name='percentage'
                        control={control}
                        render={({field}) => (
                            <CustomInput
                                label={intl.messages.percentage}
                                isRequired={true}
                                placeholder={intl.messages.percentPlaceholder}
                                type='number'
                                {...field}
                            />
                        )}
                    />
                </div>

                <div className='col-md-6'>
                    <Controller
                        name='minVal'
                        control={control}
                        render={({field}) => (
                            <CustomInput
                                label={intl.messages.minOrder}
                                placeholder={intl.messages.minOrderPlaceholder}
                                inputComponentProps={NumericFormatCustom}
                                {...field}
                            />
                        )}
                    />
                </div>
            </div>

            <div className='row mb-4'>
                <div className='col-12'>
                    <TextField
                        label={intl.messages.otherInfo}
                        multiline
                        style={{width: '100%'}}
                        onChange={(e) => {
                            setValue('otherInfo', e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Other;
