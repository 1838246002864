import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CloseGray } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React, { useState } from 'react';
import { Toast } from 'utils/toast';
import { addCategory } from '../ConfigCategoriesSlice';

const CategoryAddModal = (props) => {
	const { open, intl, doClose, dispatch, updateGetCategory } = props;
	const [categoryValue, setCategoryValue] = useState('');

	const doConfirm = async () => {
		const addCategoryResponse = await dispatch(addCategory({ name: categoryValue }));
		const { success } = addCategoryResponse.payload;

		if (success) {
			Toast(success, intl.messages.addSuccess);
			updateGetCategory();
			doClose();
		}
	};

	return (
		<Dialog
			maxWidth='xs'
			fullWidth={true}
			open={open}
			onClose={doClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			className='dialog'>
			<DialogTitle className='dialog-title' id='alert-dialog-title'>
				<span>{intl.messages.addCategory}</span>
				<button className='dialog-title__close'>
					<CloseGray onClick={doClose} />
				</button>
			</DialogTitle>
			<DialogContent className='dialog-content'>
				<div className='d-flex justify-content-center align-items-center w-100 mt-1'>
					<CustomInput
						value={categoryValue}
						onChange={(e) => setCategoryValue(e.target.value)}
						placeholder={intl.messages.enterCategory}
						label={
							<span>
								{intl.messages.categoryName} <span className='text-required'>*</span>
							</span>
						}
					/>
				</div>
			</DialogContent>
			<DialogActions className='dialog-actions'>
				<CustomButton size='md' variant='text' onClick={doClose}>
					{intl.messages.cancel}
				</CustomButton>
				<CustomButton size='md' onClick={doConfirm} disabled={categoryValue.length <= 0}>
					{intl.messages.complete}
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
};

export default CategoryAddModal;