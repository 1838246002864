const i18nLabels = {
    vi: {
        orderInformation: 'Thông tin đơn hàng',
        orderCode: 'Mã đơn hàng',
        processStatus: 'Tình trạng xử lý',
        deliveryInformation: 'Thông tin vận chuyển',
        receiverInfo: 'Người nhận',
        receiverAddress: 'Địa chỉ nhận hàng',
        deliveryCode: 'Mã vận đơn',
        deliveryProcess: 'Quá trình vận đơn',
        productTitle: 'Sản phẩm',
        shopVoucher: 'Giảm giá của shop',
        discountText: 'Giảm',
        shippingUnit: 'Đơn vị vận chuyển',
        note: 'Ghi chú',
        paymentMethodTitle: 'Phương thức thanh toán',
        totalText: 'Tổng cộng',
        productText: 'sản phẩm',
        billingTitle: 'Hóa đơn của bạn',
        billingDetailLabel: 'Chi tiết',
        billingCollapseLabel: 'Thu gọn',
        productPrice: 'Tiền sản phẩm',
        shippingFee: 'Phí vận chuyển',
        discountLabel: 'Giảm giá',
        revenueLabel: 'Doanh thu',
        cancelText: 'Hủy',
        confirmText: 'Xác nhận',
        packingText: 'Chuẩn bị hàng',
        deliverText: 'Bàn giao cho ĐVVC',
        unsuccessfullyDelivered: 'Giao không thành công',
        successfullyDelivered: 'Xác nhận đã giao',
        packingPendingDescription: 'Đã xác nhận',
        deliveryPendingDescription: 'Chờ bàn giao cho ĐVVC',
        deliveredDescription: 'Đã giao',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công',
        cancelOrderModalTitle: 'Hủy đơn hàng',
        reasonLabel: 'Lý do',
        reasonInputPlaceholder: 'Vui lòng nhập lý do hủy...',
        closeText: 'Đóng',
        cancelReasonLabel: 'Lý do hủy',
        fromSellerOrBuyer: 'Từ người bán / mua',
        refundDetailView: 'Chi tiết',
        refundDetailModalTitle: 'Chi tiết trả hàng/ hoàn tiền',
        reason: 'Lý do',
        reasonDescription: 'Chú thích',
        refundTotal: 'Số tiền hoàn lại',
        acceptAction: 'Đồng ý',
        rejectAction: 'Từ chối'
    },
    en: {
        orderInformation: 'Order Information',
        orderCode: 'Order Code',
        processStatus: 'Processing Status',
        deliveryInformation: 'Delivery Information',
        receiverInfo: 'Receiver',
        receiverAddress: 'Delivery Address',
        deliveryCode: 'Delivery Code',
        deliveryProcess: 'Delivery Process',
        productTitle: 'Product',
        shopVoucher: 'Shop Voucher',
        discountText: 'Discount',
        shippingUnit: 'Shipping Unit',
        note: 'Note',
        paymentMethodTitle: 'Payment Method',
        totalText: 'Total',
        productText: 'products',
        billingTitle: 'Your Invoice',
        billingDetailLabel: 'Details',
        billingCollapseLabel: 'Collapse',
        productPrice: 'Product Price',
        shippingFee: 'Shipping Fee',
        discountLabel: 'Discount',
        revenueLabel: 'Revenue',
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        packingText: 'Preparing for Shipment',
        deliverText: 'Handover to Delivery Service',
        unsuccessfullyDelivered: 'Unsuccessfully Delivered',
        successfullyDelivered: 'Successfully Delivered',
        packingPendingDescription: 'Confirmed',
        deliveryPendingDescription: 'Waiting to be handed over to the delivery service',
        deliveredDescription: 'Delivered',
        actionFail: 'Action Failed',
        actionSuccess: 'Action Successful',
        cancelOrderModalTitle: 'Cancel Order',
        reasonLabel: 'Reason',
        reasonInputPlaceholder: 'Please enter the cancellation reason...',
        closeText: 'Close',
        cancelReasonLabel: 'Cancellation Reason',
        fromSellerOrBuyer: 'From Seller / Buyer',
        refundDetailView: 'View',
        refundDetailModalTitle: 'Return/Refund Details',
        reason: 'Reason',
        reasonDescription: 'Description',
        refundTotal: 'Refund Total',
        acceptAction: 'Approve',
        rejectAction: 'Reject'
    }
};

export default i18nLabels;
