import {getOrderById, getProductById} from 'components/ChatBox/chatBoxSlice';
import React, {useEffect, useState} from 'react';
import ProductItem from '../ProductItem/ProductItem';
import OrderItem from '../OrderItem/OrderItem';

const MessageBox = (props) => {
    const {person, content, type, toggleLightBox, indexOfImage, setIndex, dispatch, isAdmin} = props;
    const [product, setProduct] = useState({});
    const [order, setOrder] = useState({});

    const fetchProductById = async (id) => {
        const res = await dispatch(getProductById(id)).unwrap();
        const {data} = res;
        setProduct({
            image: data?.default_image,
            name: data?.name,
            price: data?.price
        });
    };

    const fetchOrderById = async (id) => {
        const res = await dispatch(getOrderById(id)).unwrap();
        const {data} = res;
        const {extend_order_product, order_information, order_price_information} = data;
        setOrder({
            orderInfo: {
                orderId: order_information?.order_id,
                orderCodeNumber: order_information?.order_code,
                orderStatusCode: order_information?.extend_step?.code,
                orderStatus: order_information?.extend_step?.name,
                orderTotalPrice: order_price_information?.total_order_price
            },
            products:
                extend_order_product &&
                extend_order_product.map((product) => ({
                    productId: product.product_id,
                    productName: product.product_name,
                    productPrice: product.product_price,
                    productQuantity: product.quantity,
                    productImg: product.product_default_image,
                    productDescription: product.product_type_value_text
                }))
        });
    };

    useEffect(() => {
        if (type && type === 'product') {
            fetchProductById(content);
        } else if(type && type === 'order') {
            fetchOrderById(content);
        }
    }, [type]);

    return type === 'image' || type === 'text' ? (
        <div className={`message-box message-box__${person}`}>
            {type === 'image' ? (
                <img
                    style={{width: '100%', height: 'auto', objectFit: 'cover', cursor: 'pointer'}}
                    src={content}
                    alt={content}
                    onClick={() => {
                        setIndex(indexOfImage);
                        toggleLightBox();
                    }}
                />
            ) : (
                content
            )}
        </div>
    ) : (
        type === 'product' && product ? (
            <>
                <p className='tracking-title'>Bạn đang trao đổi với người {isAdmin ? 'mua' : 'bán'} về <b>sản phẩm</b> này</p>
                <ProductItem product={product} isMessageBox={true} />
            </>
        ) : (
            <>
                <p className='tracking-title'>Bạn đang trao đổi với người {isAdmin ? 'mua' : 'bán'} về <b>đơn hàng</b> này</p>
                <OrderItem order={order} isMessageBox={true} />
            </>
        )
    );
};

export default MessageBox;
