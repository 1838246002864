import React from 'react';

const ButtonIcon = (props) => {
    const { icon, quantity = 0, label = '', isActive = false, ...restProps } = props;

    return (
        <div className={`button-icon ${isActive ? 'button-icon--active' : ''}`} {...restProps}>
            {icon}
            {quantity > 0 && <span className='button-icon__quantity'>{quantity > 99 ? '99+' : quantity}</span>}
        </div>
    );
};

export default ButtonIcon;
