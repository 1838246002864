import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import {close} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CustomInput/CustomInput';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

const AddNewModal = (props) => {
    const {intl, isOpenModal, isAddNewModal, roleList, userEdit, handleCloseModal, handleSubmitAddNewForm} = props;
    const [userDefaultValue, setUserDefaultValue] = useState({
        full_name: '',
        email: '',
        role: '',
        phone: '',
        password: '',
        disabled: false,
        isSendToMail: true
    });
    const {control, handleSubmit, reset, watch} = useForm({defaultValues: userDefaultValue});
    const watchAllFields = watch();
    const checkButtonAddDisabled =
        watchAllFields.full_name.length === 0 ||
        watchAllFields.email.length === 0 ||
        watchAllFields.role.length === 0 ||
        watchAllFields.password.length === 0;
    const checkButtonEditDisabled = watchAllFields.role.length === 0;

    useEffect(() => {
        const newData = {...userDefaultValue};
        if (isAddNewModal) {
            newData.role = (roleList.length && roleList[0].id) || '';
            newData.full_name = '';
            newData.email = '';
            newData.phone = '';
            newData.password = '';
            newData.disabled = false;
        } else {
            if (userEdit) {
                newData.disabled = userEdit.disabled;
                newData.email = userEdit.email || '';
                newData.full_name = userEdit.full_name || '';
                newData.phone = userEdit.phone || '';
                if (userEdit.extend_role_user.length) {
                    const {extend_role_user} = userEdit;
                    newData.role = extend_role_user[0].role_id || '';
                } else {
                    newData.role = '';
                }
            }
        }
        setUserDefaultValue(newData);
        reset(newData);
    }, [roleList, userEdit, isOpenModal]);

    return (
        <Dialog maxWidth={'sm'} open={isOpenModal} fullWidth={true} className='dialog'>
            <form action='#' noValidate onSubmit={handleSubmit(handleSubmitAddNewForm)}>
                <DialogTitle className='dialog-title' id='add-new-account-modal'>
                    <span>{isAddNewModal ? intl.messages.addNewAccount : intl.messages.editAccount}</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                handleCloseModal();
                            }}
                        />
                    </button>
                </DialogTitle>

                <DialogContent className='dialog-content'>
                    <div className='row'>
                        <div className='col'>
                            <Controller
                                name={'full_name'}
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        label={
                                            <span>
                                                {intl.messages.fullName}
                                                <span style={{color: '#de4841'}}>&nbsp;*</span>
                                            </span>
                                        }
                                        placeholder={`${intl.messages.placeholder} nhập ${intl.messages.fullName}`}
                                        variant='outlined'
                                        fullWidth
                                        disabled={isAddNewModal ? false : true}
                                        {...field}
                                    />
                                )}></Controller>
                        </div>
                        <div className='col'>
                            <Controller
                                name={'email'}
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        label={
                                            <span>
                                                {intl.messages.email}
                                                <span style={{color: '#de4841'}}>&nbsp;*</span>
                                            </span>
                                        }
                                        placeholder={`${intl.messages.placeholder} nhập ${intl.messages.email}`}
                                        variant='outlined'
                                        fullWidth
                                        disabled={isAddNewModal ? false : true}
                                        {...field}
                                    />
                                )}></Controller>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: 16}}>
                        <div className='col'>
                            <Controller
                                name={'role'}
                                control={control}
                                rules={{required: true}}
                                render={({field: {value, onChange}}) => (
                                    <FormControl fullWidth>
                                        <InputLabel id='privilege'>
                                            <span>
                                                {intl.messages.privilege}
                                                <span style={{color: '#de4841'}}>&nbsp;*</span>
                                            </span>
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId='privilege'
                                            id='privilege'
                                            value={value}
                                            label={
                                                <span>
                                                    {intl.messages.privilege}
                                                    <span style={{color: '#de4841'}}>&nbsp;*</span>
                                                </span>
                                            }
                                            placeholder={`${intl.messages.placeholder} chọn ${intl.messages.privilege}`}
                                            sx={{height: 48}}
                                            onChange={onChange}>
                                            {roleList.map((el) => (
                                                <MenuItem key={el.id} value={el.id}>
                                                    {el.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}></Controller>
                        </div>
                        <div className='col'>
                            {isAddNewModal && (
                                <Controller
                                    name={'password'}
                                    control={control}
                                    render={({field}) => (
                                        <CustomInput
                                            label={
                                                <span>
                                                    {intl.messages.password}
                                                    <span style={{color: '#de4841'}}>&nbsp;*</span>
                                                </span>
                                            }
                                            placeholder={`${intl.messages.placeholder} nhập ${intl.messages.password}`}
                                            variant='outlined'
                                            fullWidth
                                            type='password'
                                            ishavingpwicon='true'
                                            autoComplete='off'
                                            {...field}
                                        />
                                    )}></Controller>
                            )}
                        </div>
                    </div>
                    <div className='row' style={{marginTop: 16}}>
                        <div className='col'>
                            <Controller
                                name={'phone'}
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        label={intl.messages.phone}
                                        placeholder={`${intl.messages.placeholder} nhập ${intl.messages.phone}`}
                                        variant='outlined'
                                        fullWidth
                                        disabled={isAddNewModal ? false : true}
                                        type={"number"}
                                        {...field}
                                    />
                                )}></Controller>
                        </div>
                        <div className='col'>
                            <Controller
                                name={'disabled'}
                                control={control}
                                render={({field: {value, onChange}}) => (
                                    <FormControl fullWidth>
                                        <InputLabel id='status'>{intl.messages.status}</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId='status'
                                            id='status'
                                            defaultValue={false}
                                            value={value}
                                            label={intl.messages.status}
                                            placeholder={`${intl.messages.placeholder} chọn ${intl.messages.status}`}
                                            sx={{height: 48}}
                                            onChange={onChange}>
                                            <MenuItem key={1} value={false}>
                                                {intl.messages.statusActive}
                                            </MenuItem>
                                            <MenuItem key={2} value={true}>
                                                {intl.messages.statusUnActive}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )}></Controller>
                        </div>
                    </div>
                    {isAddNewModal && (
                        <div className='row' style={{marginTop: 8}}>
                            <div className='col'>
                                <Controller
                                    name={'isSendToMail'}
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <CustomCheckbox color='primary' checked={value} onChange={onChange} />
                                    )}></Controller>
                                <span style={{color: '#333333', fontWeight: 500}}>
                                    {intl.messages.sendRequireToEmail}
                                </span>
                            </div>
                        </div>
                    )}
                </DialogContent>

                <DialogActions className='dialog-actions'>
                    <CustomButton
                        variant='text'
                        className='header-account__signup'
                        onClick={(e) => {
                            e.preventDefault();
                            handleCloseModal();
                        }}>
                        {intl.messages.close}
                    </CustomButton>
                    <CustomButton
                        variant='contained'
                        type='submit'
                        disabled={isAddNewModal ? checkButtonAddDisabled : checkButtonEditDisabled}>
                        {isAddNewModal ? intl.messages.add : intl.messages.save}
                    </CustomButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddNewModal;
