import React from 'react';
import {frame2745, group35, oval, ovalMobile, phoneNumber, ErrorMark, Approve} from 'assets/images';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import {pagePath} from 'configuration/routeConfig';
import {Controller, useForm} from 'react-hook-form';
import {validatePhone} from 'utils/validator';
import CustomInput from 'components/CustomInput/CustomInput';
import {FormControl, FormHelperText} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';

const AuthSMS = (props) => {
    const {handleSignInPhone, navigateTo, handleInvalidSubmit, intl} = props;

    const {
        control,
        watch,
        handleSubmit,
        getFieldState,
        formState: {errors, isValid}
    } = useForm({defaultValues: {phoneNumber: ''}});
    const watchAllFields = watch();
    const isTouched = getFieldState('phoneNumber').isTouched;
    const isPhoneError = !validatePhone(watchAllFields.phoneNumber);
    const isInputError = errors['phoneNumber'] ? !isValid : isTouched && isPhoneError;

    return (
        <AuthLayout src1={phoneNumber} src2={group35} src3={oval} src4={frame2745} src5={ovalMobile}>
            <div className='login-right'>
                <AuthModal>
                    <div className='login-header text-center mb-2'>
                        <h2 className='m-0'>{intl.messages.enterPhone}</h2>
                        {isInputError && <FormHelperText error>{intl.messages.phoneError}</FormHelperText>}
                    </div>
                    <form
                        noValidate
                        action='#'
                        autoComplete='off'
                        onSubmit={handleSubmit(handleSignInPhone, handleInvalidSubmit)}
                        className={`w-100 ${isTouched ? 'wasvalidated' : ''}`}>
                        <div className='login-sms'>
                            <FormControl className='w-100 mb-4'>
                                <Controller
                                    name='phoneNumber'
                                    control={control}
                                    rules={{
                                        validate: (value) => validatePhone(value)
                                    }}
                                    render={({field}) => (
                                        <CustomInput
                                            {...field}
                                            label='Số điện thoại'
                                            placeholder='Vui lòng nhập số điện thoại'
                                            type='number'
                                            valid={!isInputError}
                                            endicon={!isTouched ? null : !isInputError ? <Approve /> : <ErrorMark />}
                                        />
                                    )}
                                />
                            </FormControl>

                            <div id='captcha-container'></div>
                            <div className='w-100'>
                                <CustomButton type='submit' fullwidth>
                                    {intl.messages.send}
                                </CustomButton>
                            </div>

                            <div className='login-option-sms' onClick={() => navigateTo(pagePath.authUrl)}>
                                {intl.messages.tryOtherMethods}
                            </div>
                        </div>
                    </form>
                </AuthModal>
            </div>
        </AuthLayout>
    );
};

export default AuthSMS;
