import { toast } from 'react-toastify';


const positionToast = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
};

export const Toast = (isSuccess, message) => {
    if (isSuccess) toast.success(message, positionToast);
    if (!isSuccess) toast.error(message, positionToast);
};
