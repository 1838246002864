export default function propsProvider(props) {
    const {
        intl,
        bannerFiles,
        handleUploadImages,
        handleDeleteImage,
        handleDecreaseOrder,
        handleIncreaseOrder,
        handleCancel,
        handleSave,
        isChangeBannerList
    } = props;
    const imageRequirement = [intl.messages.imageCapacity, intl.messages.imageSize, intl.messages.imageFormat];
    const videoRequirement = [intl.messages.videoCapacity, intl.messages.videoSize, intl.messages.videoFormat];

    const actionBarControlButtons = [
        {label: intl.messages.cancel, variant: 'text', onClick: handleCancel},
        {label: intl.messages.save, onClick: handleSave}
    ];

    return {
        intl,
        imageRequirement,
        videoRequirement,
        bannerFiles,
        handleUploadImages,
        handleDeleteImage,
        handleDecreaseOrder,
        handleIncreaseOrder,
        actionBarControlButtons,
        isChangeBannerList
    };
}
