import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import forgetPasswordApi from 'api/usersApi';
import serviceRequest from 'app/serviceRequest';

export const forgetPasswordReq = createAsyncThunk('user/forget-password', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: forgetPasswordApi.forgotPassword,
        payload: data
    });
});

const forgetPassword = createSlice({
    name: 'forgetPassword',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {

    }
});

const { reducer } = forgetPassword;
export default reducer;
