import {Tooltip} from '@mui/material';
import {DoubleDownChevron, DoubleUpChevron, Help} from 'assets/images';
import React from 'react';
import {FormattedNumber} from 'react-intl';

const ShopReportCard = (props) => {
    const {title, value, rate = 0, label, isSelected = false, tooltipLabel, isCurrency = false} = props;
    const isDecreased = rate < 0;
    const formattedNumberProps = {value};

    if (isCurrency) {
        Object.assign(formattedNumberProps, {style: 'currency', currency: 'VND'});
    }

    return (
        <div className='shop-report__card' aria-selected={isSelected}>
            <div className='card-title'>
                <p className='m-0'>{title}</p>
                <Tooltip title={tooltipLabel} placement='top'>
                    <Help />
                </Tooltip>
            </div>

            <div className='card-body'>
                <p>
                    <FormattedNumber {...formattedNumberProps} />
                </p>
            </div>

            <div className='card-footer'>
                <span className='card-footer__label'>{label}</span>
                <span className={`card-footer__rate card-footer__rate--${isDecreased ? 'down' : 'up'}`}>
                    {isDecreased ? <DoubleDownChevron /> : <DoubleUpChevron />} {Math.round(rate)}%
                </span>
            </div>
        </div>
    );
};

export default ShopReportCard;
