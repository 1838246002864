import React, {useEffect, useLayoutEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {close} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';

export default function ModalPopup(props) {
    const {
        content,
        onClose,
        onConfirm,
        title,
        closeText,
        confirmText,
        type = 'basic',
        width = 'xs',
        isDelete = true,
        confirmBtnDisabled = false,
        setModalContent
    } = props;

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const closeModal = () => {
        toggle();
        if (setModalContent) {
            setTimeout(() => setModalContent({}), 100);
        }
    };

    const doClose = () => {
        if (onClose) onClose();
        closeModal();
    };

    const doConfirm = () => {
        if (onConfirm) onConfirm();
        closeModal();
    };

    useEffect(() => {
        if (title && content) {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, content]);

    return (
        <Dialog
            maxWidth={width}
            fullWidth={true}
            open={open}
            onClose={doClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className={`dialog dialog-${type}`}>
            <DialogTitle className='dialog-title' id='alert-dialog-title'>
                <span>{title}</span>
                <button className='dialog-title__close'>
                    <img src={close} alt='' onClick={doClose} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content'>{content}</DialogContent>
            <DialogActions className='dialog-actions'>
                <CustomButton size='md' variant='text' onClick={doClose}>
                    {closeText}
                </CustomButton>
                {onConfirm && (
                    <CustomButton
                        size='md'
                        color={isDelete ? 'danger' : 'primary'}
                        disabled={confirmBtnDisabled}
                        onClick={doConfirm}>
                        {confirmText}
                    </CustomButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
