import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';

const ControlButtonSection = (props) => {
    const {handleSubmitNotification, intl,parentCallback} = props;

    const sendData = (data) => {
        parentCallback(data);
    };

    return (
        <div className='add-notification-footer'>
            <div className='d-flex justify-content-end gap-2'>
                <CustomButton variant='text' onClick={() => sendData(true)}>
                    {intl.messages.addNotificationCancel}
                </CustomButton>
                <CustomButton  variant='contained' onClick={() => handleSubmitNotification()}>
                    {intl.messages.addNotificationSubmit}
                </CustomButton>
            </div>
        </div>
    );
};

export default ControlButtonSection;
