import PLP from 'features/PLP';
import PDP from 'features/PDP';
import Cart from 'features/Cart';
import NotFound from 'components/NotFound';
import Home from 'features/Home';
import Auth from 'features/Auth';
import Signup from 'features/Signup';
import SetPassword from 'features/SetPassword';
import ForgetPassword from 'features/ForgetPassword';
import ActivateCode from 'features/ActivateCode';
import Payment from 'features/Payment';
import ThankYou from 'features/ThankYou';
import Admin from 'features/Admin';
import UserProfile from 'features/UserProfile';

const plpUrl = '/productlist';
const pdpUrl = '/productdetails';
const cartUrl = '/cart';
const authUrl = '/buyer';
const loginUrl = '/buyer/login';
const loginOtpUrl = '/buyer/login/otp';
const signUpUrl = '/buyer/signup';
const signUpVerifyUrl = '/buyer/signup/otp-verify';
const setPasswordUrl = '/buyer/set-password';
const forgetPasswordUrl = '/buyer/forget-password';
const activateMailCodeUrl = '/activate';
const paymentUrl = '/payment';
const thankYouUrl = '/thank-you';
const rootUrl = '/';
const notFoundUrl = '/*';

const adminUrl = '/admin';
const adminProductManagementUrl = '/admin/product-management';
const adminAddProductManagementUrl = '/admin/product-management/create';
const adminEditProductManagementBaseUrl = '/admin/product-management/edit';
const adminEditProductManagementUrl = `${adminEditProductManagementBaseUrl}/:productId`;
const adminOrderManagementUrl = '/admin/order-management';
const adminOrderDetailBaseUrl = '/admin/order-detail';
const adminOrderDetailUrl = `${adminOrderDetailBaseUrl}/:orderId`;
const adminShopSettingsUrl = '/admin/shop-settings';
const adminShopInformationUrl = `${adminShopSettingsUrl}/shop-information`;
const adminCategoryManagementUrl = '/admin/category-management';
const adminChatSettingUrl = '/admin/chat-setting';
const adminNotificationSettingUrl = '/admin/notification-setting';
const adminUserManagementUrl = '/admin/user-management';
const adminDeliverySettingUrl = '/admin/delivery-setting';
const adminDesignBannerUrl = '/admin/design-banner';
const adminDesignCategoryUrl = '/admin/design-category';
const adminMarketingVoucherUrl = '/admin/marketing-voucher';
const adminStatisticalDataUrl = '/admin/statistical-data';

const adminFlashDealUrl = '/admin/flash-deal';
const adminFlashDealAddNewUrl = '/admin/flash-deal/add-new';
const adminFlashDealEdit = '/admin/flash-deal/edit/:flashdealId';
const adminFlashDealDublicateUrl = '/admin/flash-deal/dublicate/:flashdealId';

const userUrl = '/user';
const userProfileUrl = '/user/profile';
const userAddressUrl = '/user/address';
const userNotiUrl = '/user/notification';
const userNotiOrderUrl = '/user/notification/order';
const userNotiPromotionUrl = '/user/notification/promotion';
const userNotiOtherUrl = '/user/notification/other';
const userMyLikeProductUrl = '/user/my-favourite-product';

const userVoucherUrl = '/user/voucher';

const userMyPurchaseUrl = '/user/my-purchase';
const userRefundUrl = '/user/refund/:orderId';
const userMyDetailPurchaseUrl = '/user/my-purchase/:orderId';

const pdpUrlMatch = `${pdpUrl}/:productId`;

const testPushNoti = '/test-push';

export const redirectList = [{from: authUrl, to: loginUrl}];

// Keep "Not found page" the last one
export const routeList = [
    {path: plpUrl, component: PLP},
    {path: pdpUrlMatch, component: PDP},
    {path: loginUrl, component: Auth},
    {path: loginOtpUrl, component: Auth},
    {path: setPasswordUrl, component: SetPassword},
    {path: signUpUrl, component: Signup},
    {path: signUpVerifyUrl, component: Signup},
    {path: forgetPasswordUrl, component: ForgetPassword},
    {path: activateMailCodeUrl, component: ActivateCode},
    {path: cartUrl, component: Cart, isPrivate: true},
    {path: paymentUrl, component: Payment, isPrivate: true},
    {path: thankYouUrl, component: ThankYou, isPrivate: true},
    {path: userUrl, component: UserProfile, isPrivate: true},
    {path: adminUrl, component: Admin, isAdmin: true},
    {path: adminStatisticalDataUrl, component: Admin, isAdmin: true, allowedRoles: ['DATA_ANALYST']},
    {path: adminProductManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['PRODUCT_MANAGEMENT']},
    {path: adminAddProductManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['PRODUCT_MANAGEMENT']},
    {path: adminMarketingVoucherUrl, component: Admin, isAdmin: true},
    {path: adminFlashDealUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEAL']},
    {path: adminFlashDealAddNewUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEAL']},
    {path: adminFlashDealEdit, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEAL']},
    {path: adminFlashDealDublicateUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEAL']},
    {path: adminEditProductManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['PRODUCT_MANAGEMENT']},
    {path: adminOrderManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['ORDER_MANAGEMENT']},
    {path: adminOrderDetailUrl, component: Admin, isAdmin: true, allowedRoles: ['ORDER_MANAGEMENT']},
    {path: adminMarketingVoucherUrl, component: Admin, isAdmin: true, allowedRoles: ['VOUCHER']},
    {path: adminFlashDealEdit, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEALS']},
    {path: adminFlashDealUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEALS']},
    {path: adminFlashDealAddNewUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEALS']},
    {path: adminFlashDealDublicateUrl, component: Admin, isAdmin: true, allowedRoles: ['FLASH_DEALS']},
    {path: adminUserManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['USER_MANGEMENT']},
    {path: adminShopInformationUrl, component: Admin, isAdmin: true, allowedRoles: ['SHOP_INFORMATION']},
    {path: adminCategoryManagementUrl, component: Admin, isAdmin: true, allowedRoles: ['CATEGORY_MANAGEMENT']},
    {path: adminDeliverySettingUrl, component: Admin, isAdmin: true, allowedRoles: ['DELIVERY_MANAGEMENT']},
    {path: adminDesignBannerUrl, component: Admin, isAdmin: true, allowedRoles: ['BANNER_DESIGN']},
    {path: adminDesignCategoryUrl, component: Admin, isAdmin: true, allowedRoles: ['CATEGORY_DESIGN']},
    {path: adminNotificationSettingUrl, component: Admin, isAdmin: true, allowedRoles: ['NOTI_MANAGEMENT']},
    {path: adminChatSettingUrl, component: Admin, isAdmin: true, allowedRoles: ['CHAT_MANAGEMENT']},
    {path: userUrl, component: UserProfile, isPrivate: true},
    {path: userProfileUrl, component: UserProfile, isPrivate: true},
    {path: userNotiUrl, component: UserProfile, isPrivate: true},
    {path: userNotiOrderUrl, component: UserProfile, isPrivate: true},
    {path: userNotiPromotionUrl, component: UserProfile, isPrivate: true},
    {path: userNotiOtherUrl, component: UserProfile, isPrivate: true},
    {path: userAddressUrl, component: UserProfile, isPrivate: true},
    {path: userMyPurchaseUrl, component: UserProfile, isPrivate: true},
    {path: userMyDetailPurchaseUrl, component: UserProfile, isPrivate: true},
    {path: userVoucherUrl, component: UserProfile, isPrivate: true},
    {path: userMyLikeProductUrl, component: UserProfile, isPrivate: true},
    {path: userRefundUrl, component: UserProfile, isPrivate: true},
    {path: rootUrl, component: Home},
    {path: notFoundUrl, component: NotFound}
];

export const pagePath = {
    testPushNoti,
    plpUrl,
    pdpUrl,
    cartUrl,
    rootUrl,
    authUrl,
    signUpUrl,
    loginUrl,
    loginOtpUrl,
    setPasswordUrl,
    signUpVerifyUrl,
    forgetPasswordUrl,
    activateMailCodeUrl,
    thankYouUrl,
    paymentUrl,
    adminUrl,
    adminProductManagementUrl,
    adminAddProductManagementUrl,
    adminEditProductManagementUrl,
    adminOrderManagementUrl,
    adminOrderDetailBaseUrl,
    adminOrderDetailUrl,
    adminCategoryManagementUrl,
    adminStatisticalDataUrl,
    userUrl,
    userProfileUrl,
    userAddressUrl,
    userNotiUrl,
    userVoucherUrl,
    userMyLikeProductUrl,
    adminShopSettingsUrl,
    adminShopInformationUrl,
    notFoundUrl,
    adminChatSettingUrl,
    adminNotificationSettingUrl,
    adminUserManagementUrl,
    adminDeliverySettingUrl,
    adminEditProductManagementBaseUrl,
    adminDesignBannerUrl,
    adminDesignCategoryUrl,
    adminMarketingVoucherUrl,
    userMyPurchaseUrl,
    userMyDetailPurchaseUrl,
    adminFlashDealUrl,
    adminFlashDealAddNewUrl,
    adminFlashDealEdit,
    adminFlashDealDublicateUrl,
    userRefundUrl,
    userNotiOrderUrl,
    userNotiPromotionUrl,
    userNotiOtherUrl
};
