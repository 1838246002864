import {AddUser, EditGray, Trash} from 'assets/images';
import {calcTotalPage} from 'utils/calcTotalPage';
import {formatQuantity} from 'utils/formatQuantity';

export default function propsProvider(props) {
    const {
        intl,
        dispatch,
        navigateTo,
        handleTabChange,
        tabValue,
        userList,
        roleList,
        isOpenModal,
        isAddNewModal,
        userEdit,
        setUserEdit,
        isOpenDeleteModal,
        handleOpenDeleteModal,
        handleChangePageSize,
        handleChangePageIndex,
        handleChangeStatus,
        handleSearch,
        setRoleList,
        handleOpenModal,
        handleCloseModal,
        handleSubmitAddNewForm,
        handleCloseDeleteModal,
        handleConfirmDeleteAccount,
        setPage,
        setPageSize,
        pageSizeRange,
        userManagement,
        totalPage,
        openUserRoleModal,
        closeUserRoleModal,
        isOpenUserRoleList,
        modalContent,
        handleConfigurationAction,
        isOpenAddUserRoleModal,
        openAddUserRoleModal,
        closeAddUserRoleModal,
        openRoleModal,
        closeRoleModal,
        isOpenRoleModal,
        handleConfirmRoleModal,
        isAddRoleModal,
        roleInputs,
        handleChangeRoleInput,
        searchRoleValue,
        handleSearchRole,
        checkedRoleData,
        handleChecked,
        permissionTableData,
        handleAddUserToRole,
        setSearchUserRoleValue,
        searchUserRoleResult,
        setSearchUserRoleResult,
        handleSearchUserRoleValue,
        searchUserRoleValue
    } = props;

    const {rolesData, paginationData, roleDetail} = userManagement;
    const {page, pageSize} = paginationData[tabValue];

    const headCells = [
        {
            id: intl.messages.orderNumber,
            align: 'center',
            padding: 'none',
            label: intl.messages.orderNumber
        },
        {
            id: intl.messages.role,
            align: 'left',
            padding: 'normal',
            label: intl.messages.role
        },
        {
            id: intl.messages.description,
            align: 'left',
            padding: 'normal',
            label: intl.messages.description
        },
        {
            id: intl.messages.roleQuantity,
            align: 'center',
            padding: 'normal',
            label: intl.messages.roleQuantity
        },
        {
            padding: 'none',
            align: 'center'
        }
    ];
    const userRoleHeadCells = [
        {
            id: intl.messages.orderNumber,
            align: 'center',
            label: intl.messages.orderNumber,
            padding: 'checkbox'
        },
        {
            id: intl.messages.displayName,
            align: 'left',
            padding: 'normal',
            label: intl.messages.displayName
        },
        {
            id: intl.messages.email,
            align: 'left',
            padding: 'normal',
            label: intl.messages.email
        },
        {
            padding: 'none',
            align: 'center',
            label: ''
        }
    ];
    const roleHeadCells = [
        {
            id: intl.messages.orderNumber,
            label: intl.messages.orderNumber
        },
        {
            id: intl.messages.privilegeName,
            label: intl.messages.privilegeName
        },
        {
            id: intl.messages.description,
            label: intl.messages.description
        },
        {
            id: intl.messages.select,
            label: intl.messages.select,
            className: 'justify-content-center'
        }
    ];
    const tableData = roleList.map((role, idx) => {
        const {name, description, user_count, id} = role;
        return {
            order: {value: idx + 1, align: 'center'},
            privilege: {value: name, align: 'left'},
            desc: {value: description ? description : '--', align: 'left'},
            count: {
                value: formatQuantity(user_count),
                align: 'center',
                className: 'cell-count',
                onClick: () => openUserRoleModal(id)
            },
            actions: {
                value: (
                    <div className='d-flex justify-content-end gap-2'>
                        <AddUser onClick={() => openAddUserRoleModal({id, name})} />
                        <EditGray onClick={() => openRoleModal(false, id)} />
                        <Trash
                            onClick={() =>
                                handleConfigurationAction({
                                    type: 'deleteRole',
                                    payload: {roleId: id, privilege: name}
                                })
                            }
                        />
                    </div>
                ),
                align: 'right',
                className: 'cell-actions'
            }
        };
    });
    const userRoleTableData = roleDetail.user_list?.map((userList, idx) => {
        const {id: userId, full_name, email} = userList;
        const {id: roleId, name: privilege} = roleDetail;

        return {
            order: {value: idx + 1, align: 'center'},
            displayName: {value: full_name, align: 'left'},
            email: {value: email ? email : '--', align: 'left'},
            actions: {
                value: (
                    <div className='d-flex justify-content-end'>
                        <Trash
                            className='cell-actions__delete'
                            onClick={() =>
                                handleConfigurationAction({
                                    type: 'deleteUserRole',
                                    payload: {
                                        userId,
                                        roleId,
                                        privilege,
                                        name: full_name
                                    }
                                })
                            }
                        />
                    </div>
                ),
                align: 'right',
                className: 'cell-actions'
            }
        };
    });

    return {
        intl,
        dispatch,
        navigateTo,
        handleTabChange,
        tabValue,
        roleList,
        setRoleList,
        handleChangeStatus,
        handleSearch,
        handleOpenModal,
        openRoleModal,
        accountTabProps: {
            addNewModalProps: {
                intl,
                roleList,
                isOpenModal,
                isAddNewModal,
                userEdit,
                handleCloseModal,
                handleSubmitAddNewForm
            },
            intl,
            userList,
            pageSize,
            setUserEdit,
            isOpenDeleteModal,
            handleOpenDeleteModal,
            handleCloseDeleteModal,
            handleChangePageSize,
            handleChangePageIndex,
            handleOpenModal,
            handleConfirmDeleteAccount,
            totalPage,
            currentPage: page,
            setPageSize,
            setPage,
            paginationData
        },
        configurationTabProps: {
            headCells,
            intl,
            tableData,
            setPage,
            setPageSize,
            pageSizeRange,
            totalPage: calcTotalPage(rolesData?.total, pageSize),
            currentPage: page,
            pageSize,
            modalContent,
            handleConfigurationAction,
            userRoleModalProps: {
                intl,
                userRoleHeadCells,
                userRoleTableData,
                open: isOpenUserRoleList,
                doClose: closeUserRoleModal
            },
            addUserRoleModalProps: {
                intl,
                open: isOpenAddUserRoleModal,
                doClose: closeAddUserRoleModal,
                handleAddUserToRole,
                setSearchUserRoleValue,
                searchUserRoleResult,
                setSearchUserRoleResult,
                handleSearchUserRoleValue,
                searchUserRoleValue
            },
            roleModalProps: {
                intl,
                open: isOpenRoleModal,
                doClose: closeRoleModal,
                roleHeadCells,
                roleData: permissionTableData,
                title: isAddRoleModal ? intl.messages.addRole : intl.messages.editRole,
                actionButtonTitle: isAddRoleModal ? intl.messages.add : intl.messages.save,
                doConfirm: handleConfirmRoleModal,
                roleInputs,
                handleChangeRoleInput,
                searchRoleValue,
                handleSearchRole,
                checkedRoleData,
                handleChecked,
                roleDetail
            }
        }
    };
}
