import { cartSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './cart.i18n';
import CartContainer from './CartContainer';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function Cart(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const cart = useSelector(cartSelector);

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Cart page');
    }, [])

    const cartProps = {
        ...props,
        dispatch,
        cart,
        history,
        i18nLabels
    };
    return <DecorateContainer {...cartProps} childComponent={CartContainer} />;
}
