import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import orderApi from 'api/orderApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        orderDetail: {},
    };
};

export const getOrderById = createAsyncThunk('orderDetail/getOrderById', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrderById,
        payload: id
    });
});

export const proceedOrderStep = createAsyncThunk('orderDetail/proceedOrderStep', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.proceedStep,
        payload: params
    });
});

export const proceedOrderSubStep = createAsyncThunk('orderDetail/proceedOrderSubStep', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.proceedSubStep,
        payload: params
    });
});

const adminOrderDetail = createSlice({
    name: 'adminOrderDetail',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getOrderById.fulfilled, (state, action) => {
            state.orderDetail = action.payload.data;
        });
        builders.addCase(proceedOrderStep.fulfilled, (state, action) => {
        });
    }
});

const { reducer } = adminOrderDetail;
export default reducer;