import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        categories: []
    }
}

export const getCategories = createAsyncThunk('config/categories', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const getCategoryById = createAsyncThunk('config/categoryById', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategoryById,
        payload: id
    })
})

export const addCategory = createAsyncThunk('config/categories/add', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.postCategory,
        payload: data
    })
})

export const updateCategoryById = createAsyncThunk("config/categories/updateById", async (payload, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.updateCategoryById,
        payload: payload
    })
})

export const deleteCategoryById = createAsyncThunk("config/categories/deleteById", async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.deleteCategoryById,
        payload: id
    })
})

const adminConfigCategories = createSlice({
    name: "adminConfigCategories",
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload.data;
        });
        builders.addCase(addCategory.fulfilled, (state, action) => {
            // state.newCategory = action.payload.data
        })
    }
})

const { reducer, actions } = adminConfigCategories;
export default reducer;