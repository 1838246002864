import {Dropdown} from 'antd';
import {MarkAsRead, MarkAsUnread, Menu, ornament, Pin, Reply} from 'assets/images';
import Config from 'configuration';
import React, {useState} from 'react';

const CustomerBox = (props) => {
    const {handleClick, item, handlePinChat, handleMarkAsRead, chatRoom} = props;
    const [isOpenDropdown, setOpenDropDown] = useState(false);
    const actionItems = [
        {
            label: (
                <div
                    className='action-item'
                    onClick={(e) => {
                        e.stopPropagation();
                        handlePinChat(item?.id, item?.is_pinned);
                        setOpenDropDown(false);
                    }}>
                    <Pin />
                    <span className='action-label'>{item?.is_pinned ? 'Bỏ ghim' : 'Ghim tin nhắn'}</span>
                </div>
            ),
            key: 'ghim'
        },
        {
            label: (
                <div
                    className='action-item'
                    onClick={(e) => {
                        e.stopPropagation();
                        handleMarkAsRead(item?.id, item?.is_read);
                        setOpenDropDown(false);
                    }}>
                    {item?.is_read ? <MarkAsUnread /> : <MarkAsRead />}
                    <span className='action-label'>Đánh dấu {item?.is_read ? 'chưa đọc' : 'đã đọc'}</span>
                </div>
            ),
            key: 'mark'
        }
    ];

    return (
        <div className={`customer-box customer-box__${chatRoom === item?.id ? 'selected' : ''}`} onClick={handleClick}>
            <div className='customer-box__header'>
                <div className='user-info'>
                    <img
                        className='user-avatar'
                        src={
                            item?.receiver?.avatar ? `${Config.apiConfig.endPoint}${item?.receiver?.avatar}` : ornament
                        }
                        alt='user'
                    />
                    <span className='user-fullname'>{item?.receiver?.full_name || 'Anonymous'}</span>
                    {item?.is_pinned && (
                        <span className='pin-badge'>
                            <Pin className='pin-icon' />
                        </span>
                    )}
                </div>
                <div className='message-date'>23/12/2022</div>
            </div>
            <div className='customer-box__body'>
                <div className={`message-content message-content__${!item?.is_read ? 'unread' : ''}`}>
                    {item?.latest_message}
                </div>
                <div className='message-actions'>
                    {item?.unRead_count > 0 && (
                        <div className='unread-message-badge'>
                            {item?.unRead_count < 10 ? item?.unRead_count : '+9'}
                        </div>
                    )}
                    <Reply />
                    <Dropdown
                        menu={{items: actionItems}}
                        trigger={['click']}
                        open={isOpenDropdown}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        onOpenChange={() => setOpenDropDown(!isOpenDropdown)}>
                        <Menu
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className='cursor-pointer'
                        />
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default CustomerBox;
