export default function propsProvider(props) {
	const {
		intl,
		navigateTo,
		isOpenAddressFormModal,
		closeAddressFormModal,
		onOpenAddressFormModal,
		onSubmitAddressFormModal,
		addressList,
		addressModalType,
		selectedAddressObj,
		onOpenDeleteModal,
		modalDeleteContent
	} = props;

	return {
		intl,
		addressListData: addressList && addressList?.userAddressList,
		onOpenAddressFormModal,
		addressFormModalProps: {
			intl,
			isOpenAddressFormModal,
			closeAddressFormModal,
			onSubmitAddressFormModal,
			cityList: addressList && addressList.addressList?.collection,
			addressModalType,
			selectedAddressObj
		},
		onOpenDeleteModal,
		modalDeleteContent
	};
}
