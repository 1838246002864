import React from 'react';
import ShopReportProduct from './ShopReportProduct';
import ShopReportSales from './ShopReportSales';
import ShopReportTime from './ShopReportTime';
import ShopReportUser from './ShopReportUser';

const ShopReportMainView = (props) => {
    const {shopReportTimeProps, shopReportSalesProps, shopReportUserProps, shopReportProductProps} = props;

    return (
        <div className='shop-report'>
            <ShopReportTime {...shopReportTimeProps} />
            <ShopReportSales {...shopReportSalesProps} />
            <ShopReportUser {...shopReportUserProps} />
            <ShopReportProduct {...shopReportProductProps} />
        </div>
    );
};

export default ShopReportMainView;
