import React, {useEffect, useState} from 'react';
import {SearchIcon, close} from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import {getMyOrders, getOrdersByUserId} from 'components/ChatBox/chatBoxSlice';
import OrderItem from '../OrderItem/OrderItem';
import {calcTotalPage} from 'utils/calcTotalPage';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';

const OrderListModal = (props) => {
    const {navigateTo, dispatch, handleClose, handleSendOrder, isAdmin, chatRoomInfo} = props;
    const [searchValue, setSearchValue] = useState('');
    const [orderList, setOrderList] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadMore, setLoadMore] = useState(false);

    const handleSearch = async () => {
        setOrderList([]);
        fetchOrderList(true);
    };

    const fetchOrderList = async (isSearch = false, isLoadMore = false) => {
        setIsLoading(true);
        const res = await dispatch(
            !isAdmin
                ? getMyOrders({
                      Filters: `code@=${searchValue}`,
                      Sorts: '-created_at',
                      Page: !isSearch ? pageIndex : 1,
                      PageSize: 5
                  })
                : getOrdersByUserId({
                      data: {
                          Filters: `user_id==${chatRoomInfo?.receiver?.id},code@=${searchValue}`,
                          Sorts: '-created_at',
                          Page: !isSearch ? pageIndex : 1,
                          PageSize: 5
                      }
                  })
        ).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            const tmp = data?.collection?.map((order) => {
                const {order_information, extend_order_product, total_price} = order;
                return {
                    orderInfo: {
                        orderId: order_information?.order_id,
                        orderCodeNumber: order_information?.order_code,
                        orderStatusCode: order_information?.extend_step?.code,
                        orderStatus: order_information?.extend_step?.name,
                        orderTotalPrice: total_price
                    },
                    products:
                        extend_order_product &&
                        extend_order_product.map((product) => ({
                            productId: product.product_id,
                            productName: product.product_name,
                            productPrice: product.product_price,
                            productQuantity: product.quantity,
                            productImg: product.product_default_image,
                            productDescription: product.product_type_value_text
                        }))
                };
            });
            setOrderList((prev) => [...prev, ...tmp]);
            setTotalPage(calcTotalPage(data?.total, data?.pagesize));
        }
        setIsLoading(false);
        if (!isSearch) {
            setPageIndex((prev) => prev + 1);
        } else {
            setPageIndex(2);
        }
        if (isLoadMore) setLoadMore(false);
    };

    const handleLoadMore = async () => {
        setLoadMore(true);
        fetchOrderList(false, true);
    };

    useEffect(() => {
        fetchOrderList();
    }, []);

    return (
        <div className='modal-container'>
            <div className='modal-order-header'>
                <h5 className='modal-order-header__title'>Đơn hàng</h5>
                <button className='modal-order-header__close'>
                    <img src={close} alt='' onClick={handleClose} />
                </button>
            </div>
            <div className='modal-order-body'>
                <div className='search-bar'>
                    <CustomInput
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                handleSearch();
                            }
                        }}
                        type='text'
                        starticon={<SearchIcon />}
                        inputClassName='filter-search'
                        placeholder={'Tìm theo mã đơn hàng'}
                    />
                </div>

                <div className='order-list'>
                    {isLoading && !isLoadMore ? (
                        <div style={{textAlign: 'center'}}>Đang tải...</div>
                    ) : orderList.length > 0 ? (
                        orderList.map((order, index) => (
                            <OrderItem
                                key={index}
                                order={order}
                                navigateTo={navigateTo}
                                handleSendOrder={handleSendOrder}
                            />
                        ))
                    ) : (
                        <EmptyTableData label='Không có kết quả được tìm thấy!' />
                    )}
                    {pageIndex <= totalPage &&
                        (!isLoadMore ? (
                            <div className='load-more-button' onClick={handleLoadMore}>
                                Xem thêm
                            </div>
                        ) : (
                            <div style={{textAlign: 'center', color: '#4568f2'}}>Đang tải...</div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default OrderListModal;
