import React, { useLayoutEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CellPopover from 'components/CellPopover/CellPopover';
import LayersIcon from '@mui/icons-material/Layers';
import CategoryInput from './CategoryInput';

const CategoryCard = (props) => {
    const {
        className,
        category,
        setShowAction,
        deleteCategory,
        setRows,
        setSelectCategory,
        selectCategory,
        addChildCategory,
        updateCategory,
        intl,
        setIsFormChanged
    } = props;

    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [openChildren, setOpenChildren] = useState(false);
    const [addChildren, setAddChildren] = useState(false);
    const [displayCategory, setDisplayCategory] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const handleClickActionRow = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorPopoverEl(e.currentTarget);
    };

    const createChildCategory = () => {
        setOpenChildren(true);
        setAddChildren(true);
    };

    let thisCategoryOption = [
        {
            title: `${intl.messages.addCategoryLevel} ${category.level + 1}`,
            icon: <LayersIcon />,
            onClick: createChildCategory
        },
        {
            title: intl.messages.update,
            icon: <EditIcon />,
            onClick: () => {
                setIsEdit(true);
            }
        },
        {
            title: intl.messages.delete,
            icon: <DeleteOutlineIcon />,
            onClick: deleteCategory
        }
    ];

    useLayoutEffect(() => {
        if (category) {
            setDisplayCategory(category);
        }
    }, [category]);

    return (
        <>
            <div
                className={`${className} ${selectCategory ? selectCategory.id === category.id && 'selected_card' : ''}`}
                style={{ paddingLeft: `${displayCategory.level * 16 + 8}px` }}
                onClick={() => {
                    setShowAction(true);
                    if (selectCategory && selectCategory.id === displayCategory.id) {
                        setOpenChildren(!openChildren);
                    }
                    setRows(displayCategory.attributes ? displayCategory.attributes : []);
                    setSelectCategory(displayCategory);
                    setIsFormChanged(false);
                }}>
                {isEdit ? (
                    <CategoryInput
                        isEdit={isEdit}
                        selectCategory={selectCategory}
                        displayCategory={displayCategory}
                        updateCategory={updateCategory}
                        setDisplayCategory={setDisplayCategory}
                        setAddChildren={setAddChildren}
                        addChildCategory={addChildCategory}
                        setIsEdit={setIsEdit}
                        setOpenChildren={setOpenChildren}
                    />
                ) : (
                    <>
                        <span
                            className={`category_list_card_name ${!displayCategory.children ? 'card-children' : ''
                                }`}>
                            {displayCategory.children && (
                                <KeyboardArrowDownIcon
                                    className='category_list_card_arrow'
                                    aria-expanded={openChildren || addChildren}
                                />
                            )}
                            {displayCategory.name}
                        </span>
                        <div className='category_list_card_options'>
                            <MoreVertIcon
                                onClick={(e) => {
                                    handleClickActionRow(e);
                                }}
                            />
                            <CellPopover
                                tableRowActions={thisCategoryOption}
                                id={displayCategory.id}
                                open={!!anchorPopoverEl}
                                anchorEl={anchorPopoverEl}
                                setAnchorPopoverEl={setAnchorPopoverEl}
                                onClose={() => {
                                    setAnchorPopoverEl(null);
                                }}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left'
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            {!isEdit &&
                openChildren &&
                displayCategory.children != null &&
                displayCategory.children.map((child) => (
                    <CategoryCard
                        intl={intl}
                        key={child.id}
                        className={className}
                        category={child}
                        setShowAction={setShowAction}
                        setRows={setRows}
                        selectCategory={selectCategory}
                        setSelectCategory={setSelectCategory}
                        addChildCategory={addChildCategory}
                        updateCategory={updateCategory}
                        deleteCategory={deleteCategory}
                        setIsFormChanged={setIsFormChanged}
                    />
                ))}
            {addChildren && (
                <div className={`${className}`} style={{ paddingLeft: `${(displayCategory.level + 1) * 16 + 8}px` }}>
                    <CategoryInput
                        isEdit={isEdit}
                        selectCategory={selectCategory}
                        displayCategory={displayCategory}
                        updateCategory={updateCategory}
                        setDisplayCategory={setDisplayCategory}
                        setAddChildren={setAddChildren}
                        addChildCategory={addChildCategory}
                        setIsEdit={setIsEdit}
                        setOpenChildren={setOpenChildren}
                    />
                </div>
            )}
        </>
    );
};

export default CategoryCard;
