import {Edit, Plus, Trash} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';

const AddressSection = (props) => {
    const {intl, brandAddressList, onOpenAddressFormModal, onOpenDeleteModal} = props;

    return (
        <div className='address-section'>
            <h2 style={{fontSize: '23px', fontWeight: 500, color: '#000'}}>{intl.messages.addressTitle}</h2>
            <p style={{fontSize: '15px', color: '#808080'}}>{intl.messages.addressSubtitle}</p>

            <div className='shop-address' style={{marginTop: '24px'}}>
                <div className='shop-address__header d-flex justify-content-between align-items-center'>
                    <h5 style={{fontSize: '17px', fontWeight: 500, color: '#333', margin: 0}}>
                        {intl.messages.shopAddress}
                    </h5>
                    <CustomButton variant='text' size='sm' onClick={() => onOpenAddressFormModal()}>
                        <Plus style={{marginRight: '4px'}} />
                        {intl.messages.addNewAddressText}
                    </CustomButton>
                </div>
                <div style={{marginTop: '8px'}}>
                    {brandAddressList &&
                        brandAddressList.map((address, idx) => (
                            <div key={idx} style={{color: '#333', marginBottom: '24px'}}>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='d-flex align-items-center'>
                                            <span style={{flex: 2.5, fontSize: '13px'}}>
                                                {intl.messages.fullnameText}
                                            </span>
                                            <span style={{flex: 7.5, fontSize: '15px', fontWeight: 500}}>
                                                {address?.representative_name}
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <span style={{flex: 2.5, fontSize: '13px'}}>{intl.messages.phoneText}</span>
                                            <span style={{flex: 7.5, fontSize: '15px'}}>
                                                {address?.representative_phone}
                                            </span>
                                        </div>
                                        <div className='d-flex'>
                                            <span style={{flex: 2.5, fontSize: '13px'}}>
                                                {intl.messages.addressText}
                                            </span>
                                            <span style={{flex: 7.5, fontSize: '15px'}}>{address?.full_address}</span>
                                        </div>
                                    </div>
                                    <div className='col d-flex align-items-center justify-content-between'>
                                        <div className='address-types'>
                                            {address?.is_default && (
                                                <div className='address-type default'>Địa chỉ mặc định</div>
                                            )}
                                            {address?.is_pickup && (
                                                <div className='address-type pickup'>Địa chỉ lấy hàng</div>
                                            )}
                                            {address?.is_return && (
                                                <div className='address-type return'>Địa chỉ trả hàng</div>
                                            )}
                                        </div>
                                        <div className='address-actions'>
                                            <Edit
                                                className='edit-btn'
                                                onClick={() => onOpenAddressFormModal(address)}
                                            />
                                            {!address?.is_default && !address?.is_pickup && !address?.is_return && (
                                                <Trash
                                                    className='trash-btn'
                                                    onClick={() => onOpenDeleteModal(address?.id)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default AddressSection;
