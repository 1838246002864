import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import React, {useEffect} from 'react';
import {Repeat, SearchBlue, SearchIcon, WhitePlus} from 'assets/images';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import GroupInput from 'components/GroupInput/GroupInput';
import './voucherList.scss';
import {DateTimePicker, LocalizationProvider, StaticDateTimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {TextField} from '@mui/material';
import moment from 'moment';

const defaultFilterValues = {
    fromDate: '',
    toDate: '',
    fromEndDate: '',
    toEndDate: ''
};

const FilterBarAddNew = (props) => {
    const {
        intl,
        submitFilterProduct,
        setFilterData,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        isEdit,
        setIsEdit,
        flashDealEditData,
        isDublicate,
        errorDate,
        setErrorDate,
        errorEndDate,
        setErrorEndDate
    } = props;
    const date = new Date();
    // const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultFilterValues
    });

    useEffect(() => {
        if (!isEdit && !isDublicate) {
            setStartDate(date);
            setEndDate(date);
        } else {
            if (flashDealEditData) {
                flashDealEditData.start_deal ? setStartDate(flashDealEditData.start_deal) : setStartDate(date);
                flashDealEditData.end_deal ? setEndDate(flashDealEditData.end_deal) : setEndDate(date);
            }
        }
    }, [flashDealEditData]);
    // setStartDate(newValue.$d.toISOString())
    function dateIsValid(date) {
        return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    }
    return (
        <div className='view-order__filter mb-3'>
            <h1 className='flashDeal-header'>{intl.messages.basicInfo}</h1>
            <form action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterProduct)}>
                <div className='row m-0 gap-10px'>
                    <div className='col p-0'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                className='dateTimePicker'
                                label={intl.messages.startDate}
                                value={startDate}
                                onChange={(newValue) => {
                                    if (dateIsValid(newValue.$d)) {
                                        setStartDate(newValue.$d.toISOString());
                                        setErrorDate(false);
                                    } else {
                                        setErrorDate(true);
                                    }
                                }}
                                renderInput={(props) => <TextField {...props} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='col p-0'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                className='dateTimePicker'
                                label={intl.messages.endDate}
                                value={endDate}
                                onChange={(newValue) => {
                                    if (dateIsValid(newValue.$d) && (startDate < newValue.$d)) {
                                        setEndDate(newValue.$d.toISOString());
                                        setErrorEndDate(false);
                                    } else {
                                        setErrorEndDate(true);
                                    }
                                }}
                                renderInput={(props) => <TextField {...props} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterBarAddNew;
