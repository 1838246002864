import React, {useEffect, useState} from 'react';
import {SearchIcon, close} from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import ProductItem from '../ProductItem/ProductItem';
import {getAllProducts} from 'components/ChatBox/chatBoxSlice';
import {calcTotalPage} from 'utils/calcTotalPage';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';

const ProductListModal = (props) => {
    const {dispatch, handleClose, handleSendProduct} = props;
    const [searchValue, setSearchValue] = useState('');
    const [productList, setProductList] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadMore, setLoadMore] = useState(false);

    const handleSearch = () => {
        setProductList([]);
        fetchProductList(true);
    };

    const fetchProductList = async (isSearch = false, isLoadMore = false) => {
        setIsLoading(true);
        const res = await dispatch(
            getAllProducts({
                data: {Filters: `(name|keyword)@=*${searchValue}`, Page: !isSearch ? pageIndex : 1, PageSize: 5}
            })
        ).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            const tmp = data?.collection?.map((item) => ({
                id: item?.id,
                image: item?.default_image,
                name: item?.name,
                price: item?.price
            }));
            setProductList((prev) => [...prev, ...tmp]);
            setTotalPage(calcTotalPage(data?.total, data?.pagesize));
        }
        setIsLoading(false);
        if (!isSearch) {
            setPageIndex((prev) => prev + 1);
        } else {
            setPageIndex(2);
        }
        if (isLoadMore) setLoadMore(false);
    };

    const handleLoadMore = async () => {
        setLoadMore(true);
        fetchProductList(false, true);
    };

    useEffect(() => {
        fetchProductList();
    }, []);

    return (
        <div className='modal-container'>
            <div className='modal-product-header'>
                <h5 className='modal-product-header__title'>Sản phẩm của shop</h5>
                <button className='modal-product-header__close'>
                    <img src={close} alt='' onClick={handleClose} />
                </button>
            </div>
            <div className='modal-product-body'>
                <div className='search-bar'>
                    <CustomInput
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                handleSearch();
                            }
                        }}
                        type='text'
                        starticon={<SearchIcon />}
                        inputClassName='filter-search'
                        placeholder={'Tìm sản phẩm trong shop...'}
                    />
                </div>

                <div className='product-list'>
                    {isLoading && !isLoadMore ? (
                        <div style={{textAlign: 'center'}}>Đang tải...</div>
                    ) : productList.length > 0 ? (
                        productList.map((product, index) => (
                            <ProductItem key={index} product={product} handleSendProduct={handleSendProduct} />
                        ))
                    ) : (
                        <EmptyTableData label='Không có kết quả được tìm thấy!' />
                    )}
                    {pageIndex <= totalPage &&
                        (!isLoadMore ? (
                            <div className='load-more-button' onClick={handleLoadMore}>
                                Xem thêm
                            </div>
                        ) : (
                            <div style={{textAlign: 'center', color: '#4568f2'}}>Đang tải...</div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ProductListModal;
