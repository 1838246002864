/* eslint-disable react/style-prop-object */
import React, { useMemo, useRef, useState } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import { DownArrow } from 'assets/images';
import { FormattedNumber } from 'react-intl';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import { Controller, useForm } from 'react-hook-form';

const invoiceDefaulValues = {
    companyName: '',
    companyAddress: '',
    emailInvoice: '',
    taxNumber: '',
    note: ''
};

const YourBill = (props) => {
    const { paymentProductsData, onPaymentOrder, shippingFee, intl, appliedVoucherValue } = props;
    const [isExpandProductList, setExpandProductList] = useState(false);
    const [openInvoiceForm, setOpenInvoiceForm] = useState(false);
    const { control, watch, handleSubmit } = useForm({ defaultValues: invoiceDefaulValues });
    const requiredField = watch(['companyName', 'companyAddress', 'emailInvoice']);
    const formRef = useRef(null);
    const invoiceTypes = [
        {
            label: intl.messages.personal,
            value: 0
        },
        {
            label: intl.messages.company,
            value: 1
        }
    ];
    const [invoiceTypeValue, setInvoiceTypeValue] = useState(invoiceTypes[0].value);

    let billInformation = {};
    if (paymentProductsData) {
        paymentProductsData.map((item) => {
            const { productList } = item;
            const productsTotal =
                productList && productList.reduce((total, currentItem) => total + currentItem.total, 0);
            billInformation = {
                productsTotal,
                shippingFee: shippingFee,
                totalPrice: productsTotal + shippingFee - (appliedVoucherValue ?? 0) ?? 0
            };
            return billInformation;
        });
    }

    const invoiceInputs = useMemo(() => {
        const res =
            invoiceTypeValue === 0
                ? [
                    {
                        label: intl.messages.receiverName,
                        placeholder: intl.messages.receiverNamePlaceholder,
                        isRequired: true,
                        name: 'companyName',
                        rules: { required: { value: true, message: '' } }
                    },
                    {
                        label: intl.messages.receiverAddress,
                        placeholder: intl.messages.receiverAddressPlaceholder,
                        isRequired: true,
                        name: 'companyAddress'
                    }
                ]
                : [
                    {
                        label: intl.messages.companyName,
                        placeholder: intl.messages.companyNamePlaceholder,
                        isRequired: true,
                        name: 'companyName',
                        rules: { required: { value: true, message: '' } }
                    },
                    {
                        label: intl.messages.companyAddress,
                        placeholder: intl.messages.companyAddressPlaceholder,
                        isRequired: true,
                        name: 'companyAddress'
                    },
                    {
                        label: intl.messages.taxNumber,
                        placeholder: intl.messages.taxNumberPlaceholder,
                        isRequired: true,
                        name: 'taxNumber'
                    }
                ];
        return [
            ...res,
            {
                label: intl.messages.emailForEInvoice,
                placeholder: intl.messages.emailPlaceholder,
                isRequired: true,
                supportLabel: intl.messages.emailSupportLabel,
                name: 'emailInvoice'
            },
            {
                label: intl.messages.note,
                placeholder: intl.messages.notePlaceholder,
                type: 'textarea',
                name: 'note',
                minRows: 4
            }
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceTypeValue]);
    const isFormInvalid =
        openInvoiceForm &&
        (requiredField.some((value) => value.length === 0) ||
            (invoiceTypeValue !== invoiceTypes[0].value && watch('taxNumber').length === 0));

    const handleClick = () => {
        if (formRef && formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        } else {
            onPaymentOrder();
        }
    };

    const handleSubmitValid = (data) => {
        onPaymentOrder(data, invoiceTypeValue);
    };

    const handelSubmitInvalid = (_, e) => {
        e.target.classList.add('wasvalidated');
    };

    const onToggleExpandProductList = (isExpand) => {
        setExpandProductList(isExpand);
    };

    const toggleInvoiceForm = () => setOpenInvoiceForm(!openInvoiceForm);

    return (
        <div className='payment-invoice'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <SectionTitle paled={false}>{intl.messages.yourInvoice}</SectionTitle>
                </div>
                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                    <div onClick={() => onToggleExpandProductList(!isExpandProductList)}>
                        <span style={{ color: '#4568f2', fontSize: 13 }}>
                            {!isExpandProductList ? intl.messages.detail : intl.messages.shorten}{' '}
                        </span>
                        <img
                            src={DownArrow}
                            alt='detail-expand'
                            style={{
                                width: 20,
                                height: 20,
                                objectFit: 'contain',
                                filter: 'invert(40%) sepia(83%) saturate(4185%) hue-rotate(220deg) brightness(99%) contrast(92%)',
                                transform: isExpandProductList && 'rotate(180deg)'
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                {isExpandProductList &&
                    paymentProductsData &&
                    paymentProductsData.map((item) => {
                        const { productList } = item;
                        return (
                            <div
                                key={item?.brand_id}
                                className='product-list-expand'
                                style={{
                                    background: 'rgba(236, 240, 254, 0.7)',
                                    padding: 8,
                                    borderRadius: 4,
                                    marginBottom: 8
                                }}>
                                {productList &&
                                    productList.map((product) => (
                                        <div key={product.name} className='row' style={{ padding: '8px 0' }}>
                                            <div className='col-6'>
                                                <span style={{ color: '#333', fontSize: 15 }}>{product?.name}</span>
                                            </div>
                                            <div className='col-2'>
                                                <span style={{ color: '#333', fontSize: 15 }}>x{product?.quantity}</span>
                                            </div>
                                            <div className='col-4' style={{ textAlign: 'right' }}>
                                                <span style={{ color: '#333', fontSize: 15 }}>
                                                    <FormattedNumber
                                                        style='currency'
                                                        currency='VND'
                                                        value={product?.price}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        );
                    })}
                <div className='price-information'>
                    <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: 14 }}>
                        <div>
                            <span style={{ color: '#666', fontSize: 15 }}>{intl.messages.productPrice}</span>
                        </div>
                        <div>
                            <span style={{ color: '#333', fontSize: 17 }}>
                                <FormattedNumber
                                    style='currency'
                                    currency='VND'
                                    value={billInformation.productsTotal}
                                />
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: 14 }}>
                        <div>
                            <span style={{ color: '#666', fontSize: 15 }}>{intl.messages.deliveryFee}</span>
                        </div>
                        <div>
                            <span style={{ color: '#333', fontSize: 17 }}>
                                <FormattedNumber style='currency' currency='VND' value={billInformation.shippingFee} />
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: 14 }}>
                        <div>
                            <span style={{ color: '#666', fontSize: 15 }}>{intl.messages.discount}</span>
                        </div>
                        <div>
                            <span style={{ color: '#333', fontSize: 17 }}>
                                -
                                <FormattedNumber
                                    style='currency'
                                    currency='VND'
                                    value={appliedVoucherValue || 0}
                                />
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <span style={{ color: '#666', fontSize: 15, fontWeight: 500 }}>
                                {intl.messages.totalPrice}
                            </span>
                        </div>
                        <div>
                            <span style={{ color: '#f25c5c', fontSize: 23, fontWeight: 500 }}>
                                <FormattedNumber style='currency' currency='VND' value={billInformation.totalPrice} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {
                //comment checkbox
                // <div className='payment-invoice__container'>
                //     <FormControlLabel
                //         control={<Checkbox disableRipple onChange={toggleInvoiceForm} checked={openInvoiceForm} />}
                //         label={intl.messages.exportCompanyInvoice}
                //     />
    
                //     {openInvoiceForm && (
                //         <form
                //             noValidate
                //             className='invoice-detail'
                //             action='#'
                //             method='POST'
                //             ref={formRef}
                //             onSubmit={handleSubmit(handleSubmitValid, handelSubmitInvalid)}>
                //             <RadioGroup
                //                 aria-labelledby='invoice-form-type'
                //                 name='invoice-form-type-radio-buttons'
                //                 className='d-flex flex-row align-items-center justify-content-center'
                //                 value={invoiceTypeValue}
                //                 onChange={(e) => setInvoiceTypeValue(parseInt(e.target.value))}>
                //                 {invoiceTypes.map((type) => (
                //                     <FormControlLabel
                //                         value={type.value}
                //                         control={<Radio />}
                //                         label={type.label}
                //                         key={type.value}
                //                     />
                //                 ))}
                //             </RadioGroup>
                //             {invoiceInputs.map((input) => {
                //                 const { supportLabel, rules, ...validProps } = input;
    
                //                 return (
                //                     <Controller
                //                         rules={rules}
                //                         control={control}
                //                         name={input.name}
                //                         key={input.name}
                //                         render={({ field }) => (
                //                             <FormControl fullWidth>
                //                                 <CustomInput {...field} {...validProps} />
    
                //                                 {supportLabel && (
                //                                     <FormHelperText className='invoice-detail--helper'>
                //                                         {supportLabel}
                //                                     </FormHelperText>
                //                                 )}
                //                             </FormControl>
                //                         )}
                //                     />
                //                 );
                //             })}
                //         </form>
                //     )}
                // </div>
            }
            <div>
                <CustomButton className='order-button' onClick={handleClick} disabled={isFormInvalid}>
                    {intl.messages.orderAction}
                </CustomButton>
            </div>
        </div>
    );
};

export default YourBill;
