import React from 'react';
import GroupInput from 'components/GroupInput/GroupInput';
import {DatePicker} from 'antd';
import {Controller} from 'react-hook-form';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import {Repeat, SearchBlue} from 'assets/images';

const FilterSection = (props) => {
    const {control, intl, filterData, statusList, reset} = props;
    const groupDateInputs = [
        {
            name: 'fromDate',
            placeholder: intl.messages.fromDate
        },
        {
            name: 'toDate',
            placeholder: intl.messages.toDate
        }
    ];

    return (
        <div className='voucher__list__filter'>
            <div className='row mb-4'>
                <div className='col-md-6'>
                    <GroupInput
                        type='date'
                        control={control}
                        label={intl.messages.createDate}
                        groupInputData={groupDateInputs}
                    />
                </div>

                <div className='col-md-6 voucher__list__filter__activeDate'>
                    <fieldset>
                        <legend>
                            <span className='date-label'>{intl.messages.activeDate}</span>
                        </legend>
                        <div>
                            <Controller
                                name='activeDate'
                                control={control}
                                render={({field: {onBlur, ...restField}}) => {
                                    const blurHandle = (e) => {
                                        onBlur(e);
                                    };
                                    const baseInputProps = {
                                        className: 'voucher__list__filter__activeDate__picker',
                                        onBlur: blurHandle
                                    };
                                    return (
                                        <DatePicker
                                            placeholder={intl.messages.activeDatePlaceholder}
                                            bordered={false}
                                            size='large'
                                            {...restField}
                                            {...baseInputProps}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <Controller
                        name='status'
                        control={control}
                        render={({field}) => (
                            <CustomSelect
                                label={intl.messages.status}
                                placeholder={intl.messages.statusPlaceholder}
                                options={statusList.map((item) => ({id: item.id, value: item.name}))}
                                {...field}
                            />
                        )}
                    />
                </div>

                <div className='col-md-6 d-flex'>
                    <CustomButton variant='half-contained' style={{marginRight: '4px'}} onClick={filterData}>
                        <SearchBlue className='me-1' />
                        {intl.messages.search}
                    </CustomButton>
                    <CustomButton
                        type='reset'
                        variant='text'
                        onClick={() => {
                            reset({
                                fromDate: null,
                                toDate: null,
                                activeDate: null,
                                status: ''
                            });
                            filterData();
                        }}>
                        <Repeat className='me-1' />
                        {intl.messages.reset}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default FilterSection;
