import React, {useEffect, useState} from 'react';
import './voucherList.scss';
import {Plus, WhitePlus} from 'assets/images';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {deleteFlashDeal, getFlashDeals, updateFlashDeal} from '../../FlashDealSlice';
import {edit, dublicate, deleteIcon} from 'assets/images';
import moment from 'moment';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
export const FlashDeal = (props) => {
    const format = 'HH:mm DD/MM/YYYY';
    const {
        handleAddNew,
        isAddNew,
        intl,
        flashDealFilter,
        setFlashDealFilter,
        flashDealEditData,
        setFlashDealEditData,
        isEdit,
        setIsEdit,
        setIsAddNew,
        isDublicate,
        setDublicate,
        startDealFilter,
        endtDealFilter
    } = props;
    const [flashDealData, setFlashDealData] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const getFlashDeal = async () => {
        const controller = new AbortController();
        const {signal} = controller;
        const flashDealParam = {
            Filters: flashDealFilter,
            Page: 1,
            PageSize: 100
        };
        const res = await dispatch(
            getFlashDeals({
                data:
                    startDealFilter !== '' && endtDealFilter !== ''
                        ? {...flashDealParam, startDate: startDealFilter, endDate: endtDealFilter}
                        : flashDealParam,
                signal
            })
        ).unwrap();
        if (res.success) {
            const path = history.location.pathname;
            const id = path.substring(path.lastIndexOf('/') + 1);
            res.data.collection?.map((e) => {
                if (e.id === id) {
                    setFlashDealEditData(e);
                }
            });
            setFlashDealData(res.data.collection);
        }
    };
    const updateStatus = async (data) => {
        const today = moment();
        data.map(async (e) => {
            const startDate = moment(e.start_deal);
            const endDate = moment(e.end_deal);
            if (today.isBefore(startDate)) {
                await dispatch(updateFlashDeal({...e, status: 'Chờ hoạt động'})).unwrap();
            } else if (today.isAfter(endDate)) {
                await dispatch(updateFlashDeal({...e, status: 'Hết hạn'})).unwrap();
            } else {
                await dispatch(updateFlashDeal({...e, status: 'Hoạt động'})).unwrap();
            }
        });
    };
    const getTodayRangeStatus = (startDate, endDate) => {
        const today = moment();
        const startDate1 = moment(startDate);
        const endDate1 = moment(endDate);
        if (today.isBefore(startDate1)) {
            return <div className='wait-status'>Chờ hoạt động</div>;
        } else if (today.isAfter(endDate1)) {
            return <div className='expire-status'>Hết hạn</div>;
        } else {
            return <div className='active-status'>Hoạt động</div>;
        }
    };
    const handleCloseModal = () => {
        setOpenDeleteModal(false);
        setDeleteId('');
    };

    const handleDelete = async () => {
        const res = await dispatch(deleteFlashDeal(deleteId)).unwrap();
        if (res.success) {
            setDeleteId('');
            getFlashDeal();
            setOpenDeleteModal(false);
        }
    };
    useEffect(() => {
       dispatch(getFlashDeal) ;
    }, [startDealFilter, flashDealFilter, dispatch]);

    useEffect(() => {
        if (flashDealData) {
            updateStatus(flashDealData);
        }
    }, [flashDealData]);

    const DeleteModal = () => {
        return (
            <Dialog open={openDeleteModal} onClose={handleCloseModal}>
                <DialogTitle>{intl.messages.deleteFDtitle}</DialogTitle>
                <DialogContent>{intl.messages.delteFdConfirm}</DialogContent>
                <DialogActions>
                    <CustomButton
                        size='md'
                        variant='text'
                        onClick={(e) => {
                            handleCloseModal();
                        }}>
                        Hủy
                    </CustomButton>
                    <CustomButton color='danger' size='md' onClick={handleDelete}>
                        Xác nhận
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    };
    // useEffect(() => {
    //     if (isAddNew) {
    //         history.push('/admin/flash-deal/add-new');
    //     }
    // }, [isAddNew]);
    useEffect(() => {
        getFlashDeal();
    }, []);
    const goToAddNew = (e) => {
        e.preventDefault();
        setIsAddNew(true);
        history.push('/admin/flash-deal/add-new');
    };
    const handleEdit = (item) => {
        setFlashDealEditData(item);
        history.push(`/admin/flash-deal/edit/${item.id}`);
        setIsEdit(true);
    };
    const handleDublicate = (item) => {
        setFlashDealEditData(item);
        history.push(`/admin/flash-deal/dublicate/${item.id}`);
        setDublicate(true);
    };
    return (
        <>
            <div className='table-voucher-container'>
                <div className='table-voucher-header'>
                    <h1>Danh sách flash deals</h1>
                    <button onClick={(e) => goToAddNew(e)}>
                        <img src={WhitePlus} alt='plus' />
                        <p>Thêm mới</p>
                    </button>
                </div>
                <table cellPadding={'0'} cellSpacing={'0'} className='table-voucher-table'>
                    <tr className='table-voucher-table-header'>
                        <td>STT</td>
                        <td>Khung giờ</td>
                        <td>Số lượng sản phẩm</td>
                        <td>Trạng thái</td>
                        <td>Thao tác</td>
                    </tr>
                    {flashDealData?.map((item, idx) => (
                        <tr className='table-voucher-table-body'>
                            <td>{idx + 1}</td>
                            <td>
                                {moment(item.start_deal).format(format)} - {moment(item.end_deal).format(format)}
                            </td>
                            <td>{item.total_product}</td>
                            <td>{getTodayRangeStatus(item.start_deal, item.end_deal, item)}</td>
                            <td>
                                <img src={edit} onClick={() => handleEdit(item)}></img>
                                <img src={dublicate} onClick={() => handleDublicate(item)}></img>
                                <img
                                    src={deleteIcon}
                                    onClick={() => {
                                        setOpenDeleteModal(true);
                                        setDeleteId(item.id);
                                    }}></img>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
            <DeleteModal></DeleteModal>
        </>
    );
};
