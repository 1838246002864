import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
import usersApi from 'api/usersApi';

const initialAuth = () => {
    const auth = sessionStorage.getItem(Config.storageKey.auth);
    if (auth) {
        return { ...JSON.parse(auth) };
    }

    return {
        enteredPhone: '',
        userLoginInfo: {},
        isLogin: false,
        permission: []
    };
};
export const authenticate = createAsyncThunk('auth/authenticate', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.authenticate,
        payload: data
    });
});
export const validateToken = createAsyncThunk('auth/validateToken', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.validateToken,
        payload: data
    });
});
export const getMyInfo = createAsyncThunk('auth/getMyInfo', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.getMyInfo
    });
});
export const getMyPermissions = createAsyncThunk('auth/getMyPermissions', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.getMyPermission
    });
});

export const loginExternalUser = createAsyncThunk('auth/loginExternalUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.loginExternalUser,
        payload: data
    });
});
export const loginPhoneNumber = createAsyncThunk('auth/loginPhoneNumber', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.loginPhoneNumber,
        payload: data
    });
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: authApi.logout,
        options: { skipLoader: true }
    });
});

export const updateFCMClientToken = createAsyncThunk('user/updateFCMClientToken', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.updateFCMClientToken,
        payload: data,
        // options: {skipLoader: true}
    });
});

const auth = createSlice({
    name: 'auth',
    initialState: initialAuth(),
    reducers: {
        handleSuccessGoogleLogin: (state, action) => {
            state.token = action.payload;
            state.isLogin = true;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
        },
        handleSuccessPhoneLogin: (state, action) => {
            state.token = action.payload;
            state.isLogin = true;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
        },
        handleStoredPhone: (state, action) => {
            state.enteredPhone = action.payload;
        }
    },
    extraReducers: (buider) => {
        buider.addCase(authenticate.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.isLogin = action.payload.success;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
            sessionStorage.setItem("logout", false)
        });
        buider.addCase(validateToken.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.isLogin = action.payload.success;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
            sessionStorage.setItem("logout", false)
        });
        buider.addCase(getMyInfo.fulfilled, (state, action) => {
            state.userLoginInfo = action.payload?.user;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
        });
        buider.addCase(getMyPermissions.fulfilled, (state, action) => {
            state.permission = action.payload.data;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
        });
        buider.addCase(logout.fulfilled, () => {
            sessionStorage.removeItem(Config.storageKey.cart);
            sessionStorage.removeItem(Config.storageKey.auth);
            sessionStorage.setItem("logout", true)
            window.location.reload();
        });
    }
});

const { reducer } = auth;
export const { handleSuccessGoogleLogin, handleSuccessPhoneLogin, handleStoredPhone } = auth.actions;
export default reducer;
