import React from 'react';

import i18nLabels from './header.i18n';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch, useSelector} from 'react-redux';
import HeaderContainer from './HeaderContainer';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {authSelector, cartSelector, headerSelector, productListSelector} from 'app/selectors';

export default function Header(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(authSelector);
    const productList = useSelector(productListSelector);
    const cart = useSelector(cartSelector);
    const header = useSelector(headerSelector);
    const {pathname} = useLocation();

    if (pathname.includes('/buyer')) return null;
    const isInProductList = pathname === '/productlist';

    const headerProps = {
        ...props,
        i18nLabels,
        dispatch,
        history,
        auth,
        productList,
        isInProductList,
        cart,
        header
    };

    return <DecorateContainer {...headerProps} childComponent={HeaderContainer} />;
}
