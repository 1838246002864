import {authSelector} from 'app/selectors';
import {pagePath} from 'configuration/routeConfig';
import jwtDecode from 'jwt-decode';
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, useLocation} from 'react-router';
import {checkPermission} from 'utils/checkPermission';

const CustomRoute = ({isAdmin = false, isPrivate = false, allowedRoles = [], ...rest}) => {
    const auth = useSelector(authSelector);
    const {token, permission} = auth;
    const location = useLocation();

    return isAdmin ? (
        Array.isArray(permission) &&
        permission.length > 0 &&
        checkPermission(permission, allowedRoles) &&
        jwtDecode(token) ? (
            <Route {...rest} />
        ) : (
            <Redirect to={{pathname: pagePath.rootUrl, state: {from: location}}} />
        )
    ) : !isPrivate || (token && jwtDecode(token)) ? (
        <Route {...rest} />
    ) : (
        <Redirect to={{pathname: pagePath.rootUrl, state: {from: location}}} />
    );
};

export default CustomRoute;
