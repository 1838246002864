import React from 'react';
import {FormattedNumber} from 'react-intl';
import {cartBlue, CloseGray, Expand} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import Config from 'configuration';
import {formatQuantity} from 'utils/formatQuantity';
import {Drawer} from '@mui/material';
import SubProductDetails from '../ProductOverview/SubProductIDetails';

const AddToCart = (props) => {
    const {
        productInfo,
        addToCartHandler,
        quantityDemand,
        mainGroupTypeState,
        displayProductType,
        intl,
        showBuyModalPopup,
        showAddModalPopup,
        isBuyModal,
        closeModalPopup,
        isOpenPopup,
        setQuantityDemand,
        handleProductTypeChange,
        toggleLightbox,
        imgUrls
    } = props;
    const {name, id} = productInfo;
    const {quantity: quantitySupply, price, origin_price} = mainGroupTypeState;
    const subProductDetailsProps = {
        displayProductType,
        isOpen: isOpenPopup,
        quantityDemand,
        setQuantityDemand,
        quantitySupply,
        handleProductTypeChange,
        intl
    };

    return (
        <>
            <div className='wrapper-card d-none d-lg-flex'>
                <div className='add-to-cart'>
                    <div className='product-img'>
                        <img src={imgUrls[0]} alt='product' />
                    </div>
                    <div className='product-info'>
                        <div className='product-info-option'>
                            <h5 className='product-title'>{name}</h5>
                            {displayProductType.map((pType, pi) => (
                                <div className='d-flex align-items-center' key={pi}>
                                    <span className='sub-product-name'>{pType[0].group_name}</span>

                                    {pType.map(
                                        (type) =>
                                            type.isActive && (
                                                <span className='option-name' key={type.id}>
                                                    {type.name}
                                                </span>
                                            )
                                    )}
                                </div>
                            ))}

                            <div className='d-flex align-items-center'>
                                <span className='sub-product-name'>{intl.messages.quantity}</span>
                                <span className='option-name'>
                                    {quantitySupply !== 0 ? formatQuantity(quantityDemand) : 0}
                                </span>
                            </div>
                        </div>
                        <h3 className='product-price'>
                            <FormattedNumber style='currency' currency='VND' value={price * quantityDemand} />
                        </h3>
                    </div>
                    <div className='verticle-divider'></div>
                    <div className='action-buttons'>
                        <CustomButton
                            disabled={quantitySupply === 0}
                            onClick={() => addToCartHandler({id, type: 'buy', cartType: 'cart'})}
                            className='purchase-button'>
                            {intl.messages.buy}
                        </CustomButton>
                        <CustomButton
                            disabled={quantitySupply === 0}
                            onClick={() => addToCartHandler({id, type: 'add', cartType: 'cart'})}
                            variant='outlined'
                            className='addToCart-button'>
                            <img src={cartBlue} alt='cart' />
                            {intl.messages.addToCart}
                        </CustomButton>
                    </div>
                </div>
            </div>

            <div className='fixed-card d-flex d-lg-none'>
                <CustomButton onClick={showAddModalPopup} variant='text' className='fixed-card__add'>
                    <img src={cartBlue} alt='cart' />
                    {intl.messages.addToCart}
                </CustomButton>
                <CustomButton onClick={showBuyModalPopup} className='fixed-card__buy'>
                    {intl.messages.buy}
                </CustomButton>
                <Drawer anchor='bottom' open={isOpenPopup} onClose={closeModalPopup} className='fixed-card__drawer'>
                    <div className='drawer-modal'>
                        <div className='drawer-modal__header'>
                            <div className='header-title'>{intl.messages.selectProduct}</div>
                            <CloseGray onClick={closeModalPopup} />
                        </div>

                        <div className='drawer-modal__body'>
                            <div className='d-flex gap-3 p-3'>
                                <div className='body-image'>
                                    <img
                                        className='default-image'
                                        src={Config.apiConfig.endPoint + productInfo.default_image}
                                        alt=''
                                        onClick={toggleLightbox}
                                    />
                                    <div className='body-image__expand'>
                                        <Expand />
                                    </div>
                                </div>
                                <div className='body-detail'>
                                    <div className='body-detail__price'>
                                        <div className='price-container'>
                                            <span>₫</span>
                                            <FormattedNumber currency='VND' value={price} />
                                        </div>
                                        <div className='origin-price-container d-flex align-items-center'>
                                            <span>₫</span>
                                            <FormattedNumber currency='VND' value={origin_price} />
                                        </div>
                                        <div className='quantity-container d-block d-sm-none'>
                                            <span>{intl.messages.productLeft}</span>
                                            <span> {quantitySupply} </span>
                                            <span>{intl.messages.product}</span>
                                        </div>
                                    </div>
                                    <SubProductDetails className='d-none d-sm-block' {...subProductDetailsProps} />
                                </div>
                            </div>

                            <div className='body-sub d-sm-none d-block px-3'>
                                <SubProductDetails {...subProductDetailsProps} />
                            </div>
                        </div>

                        <div className='drawer-modal__footer'>
                            {isBuyModal ? (
                                <CustomButton onClick={() => addToCartHandler({id, type: 'buy', cartType: 'cart'})}>
                                    {intl.messages.buy}
                                </CustomButton>
                            ) : (
                                <CustomButton onClick={() => addToCartHandler({id, type: 'add', cartType: 'cart'})}>
                                    {intl.messages.addToCart}
                                </CustomButton>
                            )}
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default AddToCart;
