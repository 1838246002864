import CustomInput from 'components/CustomInput/CustomInput';
import {Controller} from 'react-hook-form';

const NoteRefund = (props) => {
    const {intl, control} = props;
    return (
        <div className='refund-note'>
            <div className='refund-note-title refund-section-title'>{intl.messages.note}</div>
            <div className='refund-note-hint'>{intl.messages.noteHint}</div>
            <div className='refund-note-textarea refund-section-content'>
                <Controller
                    name='note'
                    control={control}
                    render={({field: {onChange, ...moreField}}) => {
                        return (
                            <CustomInput
                                {...moreField}
                                type='textarea'
                                minRows={2}
                                placeholder={intl.messages.placeholderNote}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default NoteRefund;
