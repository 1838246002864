const i18nLabels = {
    vi: {
        SKUproduct: 'SKU sản phẩm',
        quantity: 'Số lượng',
        product: 'sản phẩm',
        productLeft: 'Còn',
        requestPrice: 'Yêu cầu báo giá',
        orderNow: 'Đặt hàng ngay',
        addToCart: 'Thêm vào giỏ hàng',
        saving: 'Tiết kiệm',
        overviewDescription: 'Mô tả tổng quan sản phẩm',
        zoomImage: 'Rê vào hình để phóng to',
        shop: "Cửa hàng",
        rating: "đánh giá",
        sold: "đã bán",
        follow: "Theo dõi",
        responseRate: "Tỉ lệ phản hồi",
        responseTime: "Thời gian phản hồi",
        brand: "Thương hiệu",
        features: "Thuộc tính",
        productCode: "Mã hàng hóa",
        origin: "Xuất sứ",
        warrantyPeriod: "Hạn bảo hành",
        productDetail: "Chi tiết sản phẩm",
        productDescription: "Mô tả sản phẩm",
        warrantyPolicy: "Chính sách bảo hành và hỗ trợ",
        readMore: "Xem thêm",
        readLess: "Rút gọn",
        updating: "Đang cập nhật",
        noPolicy: "Chưa có chính sách",
        noDescription: "Chưa có mô tả",
        flashDeal: "Sản phẩm tương tự 🧐",
        recommendProduct: "Có thể bạn cũng thích",
        favorite: "Yêu thích",
        alrFavorite: "Đã yêu thích",
        share: "Chia sẻ",
        selectProduct: "Chọn mua sản phẩm",
        buy: "Mua ngay",
    },
    en: {
        SKUproduct: "Product SKU",
        quantity: "Quantity",
        product: "Product",
        productLeft: "Left",
        requestPrice: "Request a Quote",
        orderNow: "Order Now",
        addToCart: "Add to Cart",
        saving: "Savings",
        overviewDescription: "Overview Description",
        zoomImage: "Hover over image to zoom",
        shop: "Shop",
        rating: "Rating",
        sold: "Sold",
        follow: "Follow",
        responseRate: "Response Rate",
        responseTime: "Response Time",
        brand: "Brand",
        features: "Features",
        productCode: "Product Code",
        origin: "Origin",
        warrantyPeriod: "Warranty Period",
        productDetail: "Product Details",
        productDescription: "Product Description",
        warrantyPolicy: "Warranty and Support Policy",
        readMore: "Read more",
        readLess: "Read less",
        updating: "Updating",
        noPolicy: "No policy available",
        noDescription: "No description available",
        flashDeal: "Similar Products 🧐",
        recommendProduct: "You may also like",
        favorite: "Favorite",
        alrFavorite: "Already favorite",
        share: "Share",
        selectProduct: "Select Product to Purchase",
        buy: "Buy Now"
    }
};

export default i18nLabels;
