import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import orderApi from 'api/orderApi';
import shippingUnitApi from 'api/shippingUnit';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        adminOrderList: [],
        paginationData: Array(7).fill({page: 1, pageSize: 10}),
        shippingUnits: []
    };
};

export const getAdminOrders = createAsyncThunk('orderList/getAdminOrders', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrders,
        payload: data
    });
});

export const getShippingUnits = createAsyncThunk('orderList/getShippingUnits', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.getShippingUnits,
        payload: data
    });
});

const adminViewOrders = createSlice({
    name: 'adminViewOrders',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(getAdminOrders.fulfilled, (state, action) => {
                state.adminOrderList = action.payload.data;
            })
            .addCase(getShippingUnits.fulfilled, (state, action) => {
                state.shippingUnits = action.payload.data.collection;
            });
    }
});

const {reducer, actions} = adminViewOrders;
export const {handleChangePage, handleChangePageSize} = actions;
export default reducer;
