import { TableContainer,Table, TableBody, TableRow, TableCell, TableHead, Button, Tooltip } from "@mui/material";
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import {emptyBox, Edit, Trash, save, Cancel} from 'assets/images';
import { useState } from "react";
import ModalPopup from 'components/ModalPopup/ModalPopup';

const TableQuickChat = (props)=>{
    const {
        intl,
        headCells=[],
        //checkedData = [],
        transformTableData = [],
        currentPage,
        editQuickChat,
        updateQuickChat,
        cancelQuickChat,
        deleteQuickChat,
        handleAddNewRow
    } = props;
    const isDataEmpty = currentPage <= 1 && transformTableData.length === 0;

    const [titleModal,setTitleModal]=useState('')
    const [itemDelete,setItemDelete]=useState();
    const openModal = (data) =>{
        setTitleModal(intl.messages.contentConfirm)
        setItemDelete(data)
    }
    const onsubmit=()=>{
        setTitleModal('')
        deleteQuickChat(itemDelete);
	}
    const onClose=()=>{
        setTitleModal('')
        setItemDelete({});
    }
    
    return(
        <div>
            <TableContainer className='tabs-detail__table'>
                <Table aria-labelledby='tableTitle'>
                    <TableHead className="custom-tableHead_head">
                        <TableRow>
                            {headCells.map((headCell,key)=>{
                                const {id, align, disablePadding, className = '', label} = headCell;
                                return (
                                    <TableCell
                                        key={`${id}-${key}`}
                                        align={align}
                                        padding={disablePadding ? 'none' : 'normal'}
                                        className={`custom-tableHead__cell ${className}`}>
                                        {label}
                                    </TableCell>
                                );
                            })}
                            <TableCell padding='checkbox' align="center" style={{width:'12%'}}>
                                <Button variant="text" style={{color:'#fff'}} onClick={handleAddNewRow}>{intl.messages.btnAdd}</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isDataEmpty ? (
                            transformTableData.map((row,key)=>{
                                //const isItemSelected = checkedData.includes(row.id);
                                return(
                                    <TableRow key={key}>
                                        <TableCell align="center">
                                            {key+1}
                                        </TableCell>
                                        <TableCell padding="none">
                                            {row.isEdit ? (
                                                <input className="input-edit" defaultValue={row.content} onChange={(event)=>{row = {...row,content:event.target.value}}}/>
                                            ): row.content}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.isEdit ? (
                                                <div className="d-flex justify-content-around">
                                                    <Tooltip title={intl.messages.tlpSave}>
                                                        <img className="cursorPointer" src={save} alt="save" onClick={()=>updateQuickChat(row,key)}></img>
                                                    </Tooltip>
                                                    <Tooltip title={intl.messages.tlpCancel}>
                                                        <img className="cursorPointer" src={Cancel} alt="cancel" onClick={()=>cancelQuickChat(row,key)}></img>
                                                    </Tooltip>
                                                </div>
                                            ) : (
                                                <div className="d-flex justify-content-around">
                                                    <Tooltip title={intl.messages.tlpEdit}>
                                                        <Edit className="cursorPointer" onClick={()=>editQuickChat(row)} />
                                                    </Tooltip>
                                                    <Tooltip title={intl.messages.tlpDelete}>
                                                        <Trash className="cursorPointer" onClick={()=>openModal(row)}/>
                                                    </Tooltip>
                                                </div>
                                            )
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow className='table-row--empty'>
                                <TableCell colSpan={headCells.length + 1}>
                                    <img src={emptyBox} alt='empty data' />
                                    <span>{intl.messages.noDataFound}</span>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalPopup 
				title={intl.messages.titleConfirm} 
				content={titleModal}
				confirmText={intl.messages.btnDelete} 
				closeText={intl.messages.btnCancel} 
				onConfirm={onsubmit}
                onClose={onClose}
			/>
        </div>
    )
}

export default TableQuickChat;