import Config from 'configuration';
import React from 'react';
import {FormattedNumber} from 'react-intl';

const ProductItem = (props) => {
    const {product, handleSendProduct, isMessageBox = false} = props;

    return (
        <div className={`product-item product-item__${isMessageBox ? 'messageBox' : ''}`}>
            <img className='product-img' src={`${Config.apiConfig.endPoint}${product?.image}`} alt='product' />
            <div className='product-info'>
                <div className='product-info__top'>
                    <p className='product-name'>{product?.name}</p>
                </div>
                <div className='product-info__bottom'>
                    <p className='product-price'>
                        <FormattedNumber style='currency' currency='VND' value={product?.price}></FormattedNumber>
                    </p>
                    {!isMessageBox && <span className='send-button' onClick={() => handleSendProduct(product?.id)}>Gửi</span>}
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
