import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {downArrow} from 'assets/images';
import React from 'react';

const Sidebar = (props) => {
    const {sidebarItems = [], navigateTo, toggleAccordion} = props;

    return (
        <div className='custom-sidebar'>
            {sidebarItems.map((nav) => {
                const {isExpanded, title, icon, items = [], to} = nav;
                const haveSubItems = items.length > 0;

                return (
                    <Accordion
                        key={title}
                        className='sidebar-accordion m-0'
                        expanded={isExpanded}
                        onChange={() => {
                            if (haveSubItems) toggleAccordion(title);
                        }}>
                        <AccordionSummary
                            aria-controls='panel1d-content'
                            id='panel1d-header'
                            className='sidebar-accordion__summary'
                            expandIcon={haveSubItems && <img src={downArrow} alt='' />}
                            onClick={() => {
                                if (to) {
                                    navigateTo(to);
                                }
                            }}>
                            <div className={`summary-content ${nav.isActive ? 'active' : ''}`}>
                                {icon}
                                <span>{title}</span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {items.map((item, idx) => (
                                <div
                                    className={`sidebar-subItem ${item.isActive ? 'active' : ''} ${
                                        item.isHidden ? 'd-none' : ''
                                    }`}
                                    key={idx}
                                    onClick={() => {
                                        navigateTo(item.to);
                                        if (item.onClickAction) item.onClickAction();
                                    }}>
                                    <span>{item.title}</span>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};

export default Sidebar;
