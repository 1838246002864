import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import React, { useRef } from 'react';
import { Repeat, SearchBlue, SearchIcon, WhitePlus } from 'assets/images';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import GroupInput from 'components/GroupInput/GroupInput';
import './voucherList.scss';

const defaultFilterValues = {
    searchOrderValue: '',
    searchProductValue: '',
    orderer: '',
    fromDate: '',
    toDate: '',
    deliveryAgent: ''
};

const FilterBar = (props) => {
    const { intl, submitFilterProduct, setFilterData, shippingUnits = [], setFlashDealFilter } = props;
    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultFilterValues
    });

    const ref = useRef(null)

    const groupDateInputs = [
        {
            name: 'fromDate',
            placeholder: intl.messages.fromDate
        },
        {
            name: 'toDate',
            placeholder: intl.messages.toDate
        }
    ];
    const shippingUnitsSelectData = [
        {
            id: 'Hoạt động',
            value: 'Hoạt động'
        },
        {
            id: 'Chờ hoạt động',
            value: 'Chờ hoạt động'
        },
        {
            id: 'Hết hạn',
            value: 'Hết hạn'
        }
    ];

    const handleReset = () => {
        setFilterData({ Filters: [], productName: '' });
        reset(defaultFilterValues);
        setFlashDealFilter('')

    };

    return (
        <div className='view-order__filter mb-3'>
            <h1 className='flashDeal-header'>{intl.messages.flashDealSetting}</h1>
            <form ref={ref} action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterProduct)}>
                <div className='row m-0 gap-10px'>
                    <div className='col p-0'>
                        <GroupInput
                            type='date'
                            control={control}
                            label={intl.messages.createDate}
                            groupInputData={groupDateInputs}
                        />
                    </div>
                    <div className='col p-0'>
                        <Controller
                            name='status'
                            control={control}
                            render={({ field }) => (
                                <CustomSelect
                                    {...field}
                                    label={intl.messages.status}
                                    placeholder={intl.messages.allStatus}
                                    options={shippingUnitsSelectData}
                                />
                            )}
                        />
                    </div>
                    <div className='col p-0 d-flex gap-3'>
                        <CustomButton className='p-2' type='submit' variant='half-contained'>
                            <SearchBlue className='me-1' />
                            {intl.messages.search}
                        </CustomButton>
                        <CustomButton className='add-button' type='reset' variant='text' onClick={handleReset}>
                            <img src={WhitePlus}></img>
                            {intl.messages.reInput}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterBar;
