import ActionBar from 'components/ActionBar/ActionBar';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import React from 'react';
import FilterProductList from './subViews/FilterProductList';
import ViewProductList from './subViews/ViewProductList';

const ViewProductsMainView = (props) => {
    const { viewProductListProps, filterProductListProps, actionBarProps, isShowActionBar, modalContent } = props;
    return (
        <div className='view-product'>
            <ModalPopup {...modalContent} />
            <FilterProductList {...filterProductListProps} />
            <ViewProductList {...viewProductListProps} />
            {isShowActionBar && <ActionBar {...actionBarProps} />}
        </div>
    );
};

export default ViewProductsMainView;
