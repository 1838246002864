import {noAddress, Plus, Edit, Trash} from 'assets/images';
import AddressFormModal from 'components/AddressFormModal/AddressFormModal';
import CustomButton from 'components/CustomButton/CustomButton';
import React, {Fragment} from 'react';
import CustomDrawer from 'components/CustomDrawer/CustomDrawer';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const AddressListMainView = (props) => {
    const {
        intl,
        addressListData = [],
        addressFormModalProps,
        onOpenAddressFormModal,
        modalDeleteContent,
        onOpenDeleteModal
    } = props;
    const isAddressListEmpty = addressListData.length === 0;
    const [windowDimensions] = useDeviceSize();
    console.log(modalDeleteContent);

    return (
        <div className='address-list'>
            <AddressFormModal {...addressFormModalProps} />
            {windowDimensions.width > 576 ? (
                <ModalPopup {...modalDeleteContent} />
            ) : (
                <CustomDrawer
                    contentClass='d-flex justify-content-center align-items-center border-0'
                    anchor='bottom'
                    {...modalDeleteContent}
                />
            )}

            <div className='address-list__body'>
                <h4>{intl.messages.myAddressList}</h4>
                <small>{intl.messages.addressList}</small>
                {isAddressListEmpty && (
                    <>
                        <div className='body-empty d-flex flex-column align-items-center gap-2'>
                            <img src={noAddress} alt='' />
                            <span>{intl.messages.noAddress}</span>
                        </div>
                        <CustomButton
                            variant='text'
                            className='address-list__add mb-4'
                            onClick={() => onOpenAddressFormModal()}>
                            <Plus />
                            {intl.messages.addNewAddress}
                        </CustomButton>
                    </>
                )}

                {!isAddressListEmpty && (
                    <>
                        {addressListData.map((address, idx) => (
                            <Fragment key={address?.id}>
                                <div className='address-detail'>
                                    <div className='address-detail-row'>
                                        <div className='d-flex align-items-center'>
                                            <span className='detail-label address-detail__fullname'>
                                                {address.fullname}
                                            </span>
                                            <span className={address.is_default ? 'default' : 'd-none'}>
                                                {intl.messages.default}
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center' style={{gap: '4px'}}>
                                            <Edit
                                                style={{height: '20px', width: '20px', cursor: 'pointer'}}
                                                onClick={() => {
                                                    onOpenAddressFormModal(address);
                                                }}
                                            />
                                            <Trash
                                                style={{height: '20px', width: '20px', cursor: 'pointer'}}
                                                className={address.is_default ? 'd-none' : ''}
                                                onClick={() => {
                                                    onOpenDeleteModal(address.id);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <span className='detail-label address-detail__delivery'>
                                            {intl.messages.deliveryAddress}
                                        </span>
                                        <span className='detail-value'>{address.full_address}</span>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span className='detail-label address-detail__phone'>
                                            {intl.messages.accountSettingPhone}
                                        </span>
                                        <span className='detail-value'>{address.phone}</span>
                                    </div>
                                </div>
                                {idx < addressListData.length - 1 && <div className='address-detail__divider'></div>}
                            </Fragment>
                        ))}

                        <CustomButton
                            variant='text'
                            className='address-list__add not-empty'
                            onClick={() => onOpenAddressFormModal()}>
                            <Plus />
                            {intl.messages.addAddressInformation}
                        </CustomButton>
                    </>
                )}
            </div>
        </div>
    );
};

export default AddressListMainView;
