import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import i18nLabels from './deliverySetting.i8n';
import DeliverySettingContainer from './DeliverySettingContainer';

const DeliverySetting = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const deliverySettingProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...deliverySettingProps} childComponent={DeliverySettingContainer} />;
};

export default DeliverySetting;
