import axiosClient from './axiosClient';

const shippingUnitApi = {
    getShippingUnit: () => {
        const url = '/api/shippingUnit';
        return axiosClient.get(url);
    }
};

export default shippingUnitApi;
