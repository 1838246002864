import React, {useState} from 'react';
import {filter, SearchIcon} from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import {Dropdown} from 'antd';
import {Checkbox, FormControlLabel} from '@mui/material';
import CustomerBox from '../CustomerBox/CustomerBox';

const CustomerList = (props) => {
    const {
        chatRooms,
        chatRoom,
        searchValue,
        setSearchValue,
        chatBoxParams,
        handleFilter,
        handleOpenChatRoom,
        handlePinChat,
        handleMarkAsRead,
        handleSearch
    } = props;
    const [isOpenDropdown, setOpenDropDown] = useState(false);

    const filterItems = [
        {
            label: (
                <FormControlLabel
                    control={
                        <Checkbox disableRipple checked={chatBoxParams.is_all} name='is_all' onChange={handleFilter} />
                    }
                    label='Tất cả tin nhắn'
                />
            ),
            key: `Tất cả tin nhắn`
        },
        {
            label: (
                <FormControlLabel
                    control={
                        <Checkbox
                            disableRipple
                            checked={chatBoxParams.is_pinned}
                            name='is_pinned'
                            onChange={handleFilter}
                        />
                    }
                    label='Tin nhắn ghim'
                />
            ),
            key: `Tin nhắn ghim`
        },
        {
            label: (
                <FormControlLabel
                    control={
                        <Checkbox
                            disableRipple
                            checked={chatBoxParams.is_unread}
                            name='is_unread'
                            onChange={handleFilter}
                        />
                    }
                    label='Tin nhắn chưa đọc'
                />
            ),
            key: `Tin nhắn chưa đọc`
        },
        {
            label: (
                <FormControlLabel
                    control={
                        <Checkbox
                            disableRipple
                            checked={chatBoxParams.is_read}
                            name='is_read'
                            onChange={handleFilter}
                        />
                    }
                    label='Tin nhắn đã đọc'
                />
            ),
            key: `Tin nhắn đã đọc`
        }
    ];

    return (
        <div className='right-side'>
            <div className='search-bar'>
                <CustomInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            handleSearch();
                        }
                    }}
                    type='text'
                    starticon={<SearchIcon />}
                    inputClassName='filter-search'
                    placeholder={'Tìm kiếm người dùng'}
                />
                <Dropdown
                    menu={{items: filterItems}}
                    trigger={['click']}
                    open={isOpenDropdown}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onOpenChange={() => setOpenDropDown(!isOpenDropdown)}>
                    <div className='filter-button' onClick={(e) => e.preventDefault()}>
                        <img src={filter} alt='filter' />
                    </div>
                </Dropdown>
            </div>
            <div className='customer-list'>
                {chatRooms &&
                    chatRooms.map((item) => {
                        const {receiver} = item;
                        return (
                            <CustomerBox
                                key={item.id}
                                item={item}
                                handleClick={() => handleOpenChatRoom(receiver?.id, item)}
                                handlePinChat={handlePinChat}
                                handleMarkAsRead={handleMarkAsRead}
                                chatRoom={chatRoom}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default CustomerList;
