import { TableCell, TableRow } from '@mui/material';
import { threeDot } from 'assets/images';
import CellPopover from 'components/CellPopover/CellPopover';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Config from 'configuration';
import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';

const CustomTableRow = (props) => {
    const { intl, dataRow, withReadmore = false, selected = false, handleSelectRow } = props;
    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const { sku, name, columnsData = [], default_image, id, tags, extend_category } = dataRow;
    const isDataOverflowed = columnsData[0]?.length > 2;

    const toggleExpand = () => setIsExpanded(!isExpanded);

    const closePopover = () => {
        setAnchorPopoverEl(null);
    };

    const handleClickActionRow = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setAnchorPopoverEl(e.currentTarget);
    };

    return (
        <TableRow className='table-row' tabIndex={-1} hover selected={selected}>
            <TableCell className='table-row--top pt-1' padding='checkbox'>
                <CustomCheckbox
                    checked={selected}
                    onChange={(e) => handleSelectRow(e, id)}
                    color='primary'
                    inputProps={{
                        'aria-labelledby': id
                    }}
                />
            </TableCell>

            <TableCell className='table-row--top table-row__head' component='th' id={id} scope='row' padding='none'>
                <div className='cell-name d-flex gap-2'>
                    <img src={`${Config.apiConfig.endPoint}${default_image}`} alt='' />
                    <div className='cell-name__detail d-flex flex-column gap-2'>
                        {tags.length > 0 && (
                            <div className='d-flex align-items-center gap-2'>
                                {tags.map((tag, idx) => (
                                    <span className={`cell-tag cell-tag--${tag.type}`} key={idx}>
                                        {tag.label}
                                    </span>
                                ))}
                            </div>
                        )}
                        <p>{name}</p>
                        <span>
                            {intl.messages.skuProduct} {sku}
                        </span>
                        <span>{extend_category.name}</span>
                    </div>
                </div>
            </TableCell>

            {columnsData?.map((rowExt, key) => {
                const displayData = isExpanded ? rowExt : rowExt.slice(0, 2);
                return key < columnsData.length - 1 ? (
                    <TableCell
                        className='table-cell'
                        key={key}
                        align={typeof rowExt[0] === 'number' ? 'right' : 'left'}>
                        {displayData.map((value, idx) => (
                            <div className='table-cell__value' key={idx}>
                                {key === 2 ? (
                                    <FormattedNumber style='currency' currency='VND' value={value} />
                                ) : key === 3 ? (
                                    value > 0 ? (
                                        value
                                    ) : (
                                        <span className='error-value'>{intl.messages.outOfStock}</span>
                                    )
                                ) : (
                                    value
                                )}
                            </div>
                        ))}
                        {isDataOverflowed && key === 1 && withReadmore && (
                            <div className='cell-expand' onClick={toggleExpand}>
                                {isExpanded ? intl.messages.readLess : intl.messages.readMore}
                            </div>
                        )}
                    </TableCell>
                ) : (
                    <TableCell
                        aria-describedby={name}
                        align='center'
                        className='table-row__action table-row--top'
                        key={key}>
                        <img src={threeDot} alt='' onClick={handleClickActionRow} />
                        <CellPopover
                            setAnchorPopoverEl={setAnchorPopoverEl}
                            tableRowActions={rowExt}
                            id={id}
                            open={!!anchorPopoverEl}
                            anchorEl={anchorPopoverEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right'
                            }}
                        />
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default CustomTableRow;
