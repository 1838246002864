import ExpandProductList from 'components/ExpandProductList/ExpandProductList';
import SwiperFlashDeal from 'components/SwiperFlashDeal/SwiperFlashDeal';
import React from 'react';
import AddToCart from './subViews/AddToCart/AddToCart';
import Feedback from './subViews/FeedbackSection/Feedback';
import ProductInformation from './subViews/ProductInformation/ProductInformation';
import ProductOverview from './subViews/ProductOverview/ProductOverview';
import ShareModal from './subViews/ShareModal/ShareModal';
import FsLightbox from 'fslightbox-react';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';

export default function ProductDetailsMainView(props) {
    const {
        productOverviewProps,
        productInformationProps,
        feedbackProps,
        recommendProps,
        flashDealProps,
        addToCartProps,
        shareModalProps,
        lightboxProps
    } = props;

    return (
        <div className='product-details'>
            <ShareModal {...shareModalProps} />
            <WrapperContainer bodyContent isPaddingOnMobile={false}>
                <div className='row m-0 flex-column gap-3'>
                    <section className='product-details__section col-12 product-overview'>
                        <ProductOverview {...productOverviewProps} />
                    </section>
                    <section className='product-details__section col-12 p-0'>
                        <ProductInformation {...productInformationProps} />
                    </section>
                    <section className='product-details__section col-12 p-0'>
                        <Feedback {...feedbackProps} />
                    </section>
                    {/* <section className='px-3 p-sm-0'>
                        <SwiperFlashDeal {...flashDealProps} />
                    </section> */}
                    <section className='px-3 p-sm-0'>
                        <ExpandProductList {...recommendProps} />
                    </section>
                </div>
                <AddToCart {...addToCartProps} />
                <FsLightbox {...lightboxProps} />
            </WrapperContainer>
        </div>
    );
}
