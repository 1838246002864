import React, { forwardRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';

const TextEditor = forwardRef((props, ref) => {
    const toolbarOptions = [
        'inline',
        'blockType',
        'fontSize',
        'fontFamily',
        'list',
        'textAlign',
        'colorPicker',
        'link',
        'embedded',
        'emoji',
        'history'
    ];

    return (
        <Editor
            {...props}

            editorRef={ref}
            wrapperClassName='wrapper-class'
            editorClassName='editor-class'
            toolbarClassName='toolbar-class'
            toolbar={{
                options: toolbarOptions,
                embedded: {
                    defaultSize: {
                        height: '300',
                        width: '90%'
                    }
                }
            }}
        />
    );
});

export default TextEditor;
