import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {SearchIcon} from 'assets/images';
import CustomInput from 'components/CustomInput/CustomInput';
import MyPurchaseList from './subViews/MyPurchaseList';
import ReviewFormModal from './subViews/ReviewFormModal';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CancelReasonModal from '../../MyDetailPurchase/template/subViews/CancelReasonModal';
import { useDeviceSize } from 'utils/hooks/useDeviceSize';

const MyPurchaseMainView = (props) => {
    const {
        intl,
        myPurchaseListTabs = [],
        activePurchaseListTab,
        changePurchaseListTab,
        myPurchaseListProps,
        reviewFormModalProps,
        searchValue,
        setSearchValue,
        handleSearch,
        searchBy,
        setSearchBy,
        reasonModalProps
    } = props;
    const [windowSize] = useDeviceSize();

    const selectOptions = [
        {
            value: 'Tên sản phẩm',
            id: 'name'
        },
        {
            value: 'Mã đơn hàng',
            id: 'code'
        }
    ];

    return (
        <div className='my-purchase-list'>
            <ReviewFormModal {...reviewFormModalProps} />
            <CancelReasonModal {...reasonModalProps} />
            {windowSize.width > 768 && <div className='purchase-list__header'>
                <h1>{intl.messages.myOrder}</h1>
            </div>}
            
            <div className='purchase-list__content'>
                <div className='tabs-list'>
                    <Tabs value={activePurchaseListTab} onChange={changePurchaseListTab} variant='scrollable'>
                        {myPurchaseListTabs.map((tab) => (
                            <Tab label={tab.label} key={tab.key} />
                        ))}
                    </Tabs>
                </div>

                <div className='d-flex align-items-center'>
                    <div className='filter-by'>
                        <CustomSelect
                            value={searchBy}
                            onChange={(e) => setSearchBy(e.target.value)}
                            options={selectOptions}
                            noPlaceholder
                            inputClassName='filter-select'
                        />
                    </div>
                    <div className='search-by'>
                        <CustomInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleSearch();
                                }
                            }}
                            starticon={<SearchIcon />}
                            inputClassName='filter-search'
                            placeholder={
                                searchBy === 'name'
                                    ? intl.messages.searchByProductNamePlaceholder
                                    : intl.messages.searchByCodePlaceholder
                            }
                        />
                    </div>
                </div>

                <div className='tabs-detail'>
                    <MyPurchaseList {...myPurchaseListProps} />
                </div>
            </div>
        </div>
    );
};

export default MyPurchaseMainView;
