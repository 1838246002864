import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import cartProductApi from 'api/cartProductApi';
import hotdealApi from 'api/hotdealApi';
import productApi from 'api/productApi';
import productReviewApi from 'api/productReviewApi';
import serviceRequest from 'app/serviceRequest';

export const getProductDetails = createAsyncThunk('product/details', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProductDetails,
        payload: params
    });
});

export const getProductReviews = createAsyncThunk('product/reviews', async ({id, params}) => {
    const res = await productReviewApi.getReviewsByProductId(id, params);
    return res;
});

export const getRecommendList = createAsyncThunk('product/recommendList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getRecommendList,
        payload: data
    });
});

export const getHotDeals = createAsyncThunk('home/hotdeals', async (params) => {
    const res = await hotdealApi.getHotDeals(params);
    return res;
});

export const getlikeProductList = createAsyncThunk('likeProductList/getlikeProductList', async (data, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: productApi.getLikeProduct,
	});
});

export const deleteCartProducts = createAsyncThunk('likeProductList/deleteProducts', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.deleteCartProducts,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

const productDetails = createSlice({
    name: 'productDetails',
    initialState: {
        productInfo: {},
        allProductReviews: {},
        productReviews: {},
        recommendDetails: [],
        hotDealDetails: [],
        userLikeList:[],

    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.productInfo = action.payload.data;
            })
            .addCase(getHotDeals.fulfilled, (state, action) => {
                state.hotDealDetails = action.payload.data;
            })
            .addCase(getProductReviews.fulfilled, (state, action) => {
                state.productReviews = action.payload;
            })
            .addCase(getRecommendList.fulfilled, (state, action) => {
                state.recommendDetails = action.payload.data;
            })
            .addCase(getlikeProductList.fulfilled,(state,action) =>{
                state.userLikeList = action.payload?.data?.collection;
            });
    }
});

const {reducer, actions} = productDetails;
export const {handleQuantity} = actions;
export default reducer;
