import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import CustomPagination from 'components/Pagination/Pagination';
import { capitalizeFirstLetter } from 'utils/formatString';
import CardOrder from './CardOrder';

const TableOrderList = (props) => {
    const {
        intl,
        pageSizeRange,
        headCells = [],
        transformTableData = [],
        totalPage,
        currentPage,
        pageSize,
        setPage,
        setPageSize,
        isHavingCheckbox = false,
        handleSelectAllClick,
        handleSelectRow,
        checkedData = []
    } = props;
    const isDataEmpty = currentPage <= 1 && transformTableData.length === 0;

    const selectedRows = checkedData.length;
    const rowCount = transformTableData.length;

    return (
        <div className='tabs-detail__table'>
            <header className='tabs-detail__table__header'>

                {headCells.map((cell, key) => {
                    const headCheckbox = isHavingCheckbox && key === 0;

                    return <div className={`tabs-detail__table__header__cell col_${cell.code} ${headCheckbox ? "col-checkbox" : ""}`} key={cell.label}>
                        {headCheckbox && (
                            <CustomCheckbox
                                disableRipple
                                isContrast
                                color='primary'
                                indeterminate={selectedRows > 0 && selectedRows < rowCount}
                                checked={rowCount > 0 && selectedRows === rowCount}
                                onChange={handleSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all data'
                                }}
                            />
                        )}
                        {capitalizeFirstLetter(cell.label)}
                    </div>;
                })}
                <div className='col_action'></div>
            </header>
            {!isDataEmpty ? (
                transformTableData.map((data, idx) => {
                    const { order, buyer, products, tableRowActions } = data;
                    const isItemSelected = checkedData.includes(order.id);

                    return <CardOrder
                        key={idx}
                        buyer={buyer}
                        order={order}
                        products={products}
                        intl={intl}
                        tableRowActions={tableRowActions}
                        noCheckbox={!isHavingCheckbox}
                        handleSelectRow={handleSelectRow}
                        checked={isItemSelected}
                    />;
                })
            ) : (
                <EmptyTableData label={intl.messages.noDataFound} />
            )}
            {!isDataEmpty && (
                <div className='table-pagination'>
                    <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={currentPage} />
                    <CustomPageSize
                        label={intl.messages.rowsPerPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageSizeRange={pageSizeRange}
                        id={'temp_order'}
                    />
                </div>
            )}
        </div>
    );
};

export default TableOrderList;
