import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import permissionApi from 'api/permissionApi';
import rolesApi from 'api/rolesApi';
import usersApi from 'api/usersApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        rolesData: {},
        roleDetail: {},
        permissionsData: [],
        paginationData: Array(2).fill({ page: 1, pageSize: 10 })
    };
};

export const getUsers = createAsyncThunk('user/getUsers', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.getUsers,
        payload: data
    });
});

export const getRoles = createAsyncThunk('user/roles', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.getRoles,
        payload: data
    });
});

export const getPermissions = createAsyncThunk('user/permissions', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: permissionApi.getPermissions,
    });
});

export const addNewUser = createAsyncThunk('user/addNewUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.addNewUser,
        payload: data
    });
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.updateUser,
        payload: data
    });
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.deleteUser,
        payload: data
    });
});

export const addRole = createAsyncThunk('user/addRole', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.addRoles,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const changeUserRole = createAsyncThunk('user/changeUserRole', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.changeUserRole,
        payload: data
    });
});

export const getRoleId = createAsyncThunk('user/roleId', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.getRoleId,
        payload: data
    });
});

export const updateRole = createAsyncThunk('user/updateRole', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.updateRoleId,
        payload: data
    });
});

export const addPermissionsToUser = createAsyncThunk('user/addPermissionsToUser', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.addPermissionsToUser,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const addUserToRole = createAsyncThunk('user/addUserToRole', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.addUserToRole,
        payload: data
    });
});

export const deleteRole = createAsyncThunk('user/deleteRole', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.deleteRole,
        payload: id
    });
});

export const deleteUserRole = createAsyncThunk('user/deleteUserOutOfRole', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: rolesApi.deleteUserOutOfRole,
        payload: data
    });
});

const userManagement = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const { activePage, page } = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const { activePage, pageSize } = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(getRoles.fulfilled, (state, action) => {
                state.rolesData = action.payload.data;
            })
            .addCase(getPermissions.fulfilled, (state, action) => {
                state.permissionsData = action.payload.data;
            })
            .addCase(getRoleId.fulfilled, (state, action) => {
                state.roleDetail = action.payload.data;
            });
    }
});

const { reducer, actions } = userManagement;
export const { handleChangePage, handleChangePageSize } = actions;
export default reducer;
