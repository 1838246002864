import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import homeApi from 'api/homeApi';
import hotdealApi from 'api/hotdealApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';

const initialProductList = () => {
    if (sessionStorage.getItem(Config.storageKey.home)) {
        return {...JSON.parse(sessionStorage.getItem(Config.storageKey.home))};
    }
    return {
        hotDealDetails: [],
        recommendDetails: [],
        categoryDetails: [],
        banners: []
    };
};

export const getCategories = createAsyncThunk('home/categories', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const getHotDeals = createAsyncThunk('home/hotdeals', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: hotdealApi.getFlashDeals,
        payload: params
    });
});

export const getBanners = createAsyncThunk('home/banners', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: homeApi.getBanners
    });
});

export const getRecommendList = createAsyncThunk('home/recommendList', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: homeApi.getRecommendList
    });
});

const home = createSlice({
    name: 'home',
    initialState: initialProductList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBanners.fulfilled, (state, action) => {
                state.banners = action.payload.data;
                sessionStorage.setItem(Config.storageKey.home, JSON.stringify(state));
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categoryDetails = action.payload.data;
                sessionStorage.setItem(Config.storageKey.home, JSON.stringify(state));
            })
            .addCase(getCategories.rejected, (state, action) => {})
            .addCase(getHotDeals.fulfilled, (state, action) => {
                state.hotDealDetails = action.payload.data.collection;
                sessionStorage.setItem(Config.storageKey.home, JSON.stringify(state));
            })
            .addCase(getHotDeals.rejected, (state, action) => {})
            .addCase(getRecommendList.fulfilled, (state, action) => {
                state.recommendDetails = action.payload.data;
                sessionStorage.setItem(Config.storageKey.home, JSON.stringify(state));
            })
            .addCase(getRecommendList.rejected, (state, action) => {});
    }
});

const {reducer, actions} = home;
export const {addProduct, removeProduct, updateProduct} = actions;
export default reducer;
