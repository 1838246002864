import { SearchIcon } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import CategoryAddModal from '../subViews/CategoryAddModal';
import CategoryList from '../subViews/CategoryList';

const CategoryManagementMainView = (props) => {
    const { dispatch, intl, categoryAddModalProps, addCategoryHandler, categoryListProps } = props;

    return (
        <div className='category-management'>
            <CategoryAddModal {...categoryAddModalProps} />
            <div className='category-management__header'>
                <h4>{intl.messages.categoryManagement}</h4>
                <p>{intl.messages.subCategoryManagement}</p>
            </div>

            <div className='category-management__body'>
                <h5>{intl.messages.categoryList}</h5>
                <div className='row m-0'>
                    <div className='col-10 p-0'>
                        <CustomInput
                            placeholder={intl.messages.searchCategory}
                            starticon={<SearchIcon />}
                            onChange={(e) => console.log(e.target.value)}
                        />
                    </div>
                    <div className='col p-0'>
                        <CustomButton className='ms-auto' onClick={addCategoryHandler}>
                            + {intl.messages.addCategory}
                        </CustomButton>
                    </div>
                </div>

                <div className='category-content'>
                    <CategoryList {...categoryListProps} />
                </div>
            </div>
        </div>
    );
};

export default CategoryManagementMainView;
