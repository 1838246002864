import React from 'react';
import { pagePath } from 'configuration/routeConfig';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import { errorPage, Reload } from 'assets/images';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
    const history = useHistory();

    return (
        <div className='error-page'>
            <WrapperContainer bodyContent>
                <div className='errorpage-content'>
                    <div className='errorpage-content__img'>
                        <img src={errorPage} alt='error' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div className='my-4'>
                            <h5 className='errorpage-noti'>OOPS!</h5>
                            <p className='errorpage-text'>Xin lỗi, chúng tôi không thể tìm thấy trang!</p>
                        </div>
                        <div className='action-buttons'>
                            <button className='goback-btn' onClick={() => history.push(pagePath.rootUrl)}>
                                Quay về trang chủ
                                <img src={Reload} alt="reload" />
                            </button>
                        </div>
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
};

export default NotFound;
