import { FormControlLabel } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import React from 'react';

const ActionBar = (props) => {
	const { intl, handleSelectAllClick, actionBarControlButtons = [], selectedRows = 0, rowCount = 0 } = props;

	return (
		<div className='action-bar'>
			{handleSelectAllClick && <FormControlLabel
				control={
					<CustomCheckbox
						onChange={handleSelectAllClick}
						color='primary'
						indeterminate={selectedRows > 0 && selectedRows < rowCount}
						checked={rowCount > 0 && selectedRows === rowCount}
						inputProps={{
							'aria-label': 'select all data'
						}}
					/>
				}
				label={intl.messages.selectAll}
			/>}

			<div className='action-bar__control'>
				{actionBarControlButtons.map((button) => (
					<CustomButton key={button.label} {...button}>
						{button.label}
					</CustomButton>
				))}
			</div>
		</div>
	);
};

export default ActionBar;