const i18nLabels = {
    vi: {
        progressBasicInformation: 'Thông tin cơ bản',
        progressDetailInformation: 'Thông tin chi tiết',
        progressOrderInformation: 'Thông tin bán hàng',
        progressDeliveryInformation: 'Vận chuyển',
        progressOtherInformation: 'Thông tin khác',
        otherInformationSectionTitle: 'Thông tin khác',
        otherInformationSectionIsPreOrder: 'Hàng cần đặt trước',
        otherInformationSectionYes: 'Có',
        otherInformationSectionNo: 'Không',
        otherInformationSectionOrderStatus: 'Tình trạng sản phẩm',
        otherInformationSectionOrderStatusNew: 'Mới',
        otherInformationSectionOrderStatusOld: 'Cũ',
        otherInformationSectionOrderSKU: 'SKU sản phẩm',
        otherInformationSectionResetButton: 'Quay lại từ đầu',
        backButton: 'Quay lại',
        controlButtonSectionCancel: 'Hủy',
        controlButtonSectionTemp: 'Nháp',
        controlButtonSectionSaveAndShow: 'Lưu & Hiển thị',
        basic: 'Cơ bản',
        advance: 'Nâng cao',
        price: 'Giá',
        promotionalPrice: 'Giá khuyến mãi',
        storage: 'Kho hàng',
        helperTextPrice: 'Nhập giá trị ít nhất 1.000đ',
        groupType: 'Nhóm phân loại',
        productType: 'Phân loại hàng',
        addGroupType: 'Thêm nhóm phân loại',
        digit: 'kí tự',
        groupTypeList: 'Danh sách phân loại',
        skuType: 'SKU phân loại',
        applyForAllGroupTypes: 'Áp dụng cho tất cả phân loại',
        unitPrice: 'Đơn giá (VNĐ)',
        groupTypePlaceholder: 'Ví dụ: màu sắc,...',
        productTypePlaceholder: 'Ví dụ: Trắng, Đỏ,...',
        requiredField: 'Trường này là bắt buộc',
        sectionDeliveryInformation: 'Thông tin vận chuyển',
        weightAfterPackage: 'Cân nặng (sau khi đóng gói)',
        note: 'Lưu ý:',
        packageSize: 'Kích thước đóng gói (Phí vận chuyển thực tế sẽ thay đổi nếu bạn nhập sai kích thước)',
        width: 'Chiều rộng',
        length: 'Chiều dài',
        height: 'Chiều cao',
        deliveryPrice: 'Phí vận chuyển',
        express: 'Hỏa tốc',
        fast: 'Nhanh',
        beDelivery: 'beDelivery (tối đa 20000g)',
        shopee: 'Shopee Xpress Instant(Tối đa 30000g)',
        grabExpress: 'GrabExpress(Tối đa 30000g)',
        tooltipPrice: 'Phí vận chuyển tạm tính (dựa trên khối lượng sản phẩm là 1000g, kích thước đóng gói 10x10x10)',
        back: 'Quay lại',
        reset: 'Quay lại từ đầu',
        confirm: 'Xác nhận',
        detailSub: 'Điền thông tin thuộc tính để tăng mức độ hiển thị cho sản phẩm',
        productName: 'Tên sản phẩm',
        categoryName: 'Ngành hàng',
        chooseCategory: 'Chọn ngành hàng',
        chooseBrand: 'Chọn Thương hiệu',
        productDescription: 'Mô tả sản phẩm',
        brand: 'Thương hiệu',
        material: 'Chất liệu',
        productCode: 'Mã hàng hóa',
        origin: 'Xuất xứ',
        enterCode: 'Nhập mã hàng hóa',
        formatImage: 'Định dạng tệp PNG, JPEG, JPG',
        imageRequirement: 'Kích thước: tối đa 5MB',
        formatVideo: 'Định dạng tệp: MP4',
        lengthVideo: 'Độ dài: 10s - 30s',
        sizeVideo: 'Kích thước: tối đa 30MB',
        overSize: 'Tệp vượt quá giới hạn dung lượng cho phép',
        overLimit: 'Số tệp vượt quá lượng cho phép',
        need: 'Tôi cần',
        prepareDate: 'ngày để chuẩn bị hàng (tối thiểu: 7 ngày - tối đa: 15 ngày)',
        lessThanUnitPrice: 'Giá khuyến mãi phải nhỏ hơn giá gốc',
        deliveryInputError: 'Giá trị nhập phải lớn hơn 0 và nhỏ hơn 200,000',
        imageError: 'Cần tải lên ít nhất 1 ảnh',
        actionSuccess: 'Thao tác thành công',
        actionFail: 'Đã có lỗi xảy ra vui lòng thử lại sau'
    },
    en: {
        progressBasicInformation: 'Basic Information',
        progressDetailInformation: 'Detail Information',
        progressOrderInformation: 'Order Information',
        progressDeliveryInformation: 'Delivery Information',
        progressOtherInformation: 'Other Information',
        otherInformationSectionTitle: 'Other Information',
        otherInformationSectionIsPreOrder: 'Pre-order',
        otherInformationSectionYes: 'Yes',
        otherInformationSectionNo: 'No',
        otherInformationSectionOrderStatus: 'Product Status',
        otherInformationSectionOrderStatusNew: 'New',
        otherInformationSectionOrderStatusOld: 'Old',
        otherInformationSectionOrderSKU: 'Product SKU',
        otherInformationSectionResetButton: 'Reset',
        backButton: 'Back',
        controlButtonSectionCancel: 'Cancel',
        controlButtonSectionTemp: 'Draft',
        controlButtonSectionSaveAndShow: 'Save & Show',
        basic: 'Basic',
        advance: 'Advance',
        price: 'Price',
        promotionalPrice: 'Promotional Price',
        storage: 'Storage',
        helperTextPrice: 'Enter a value of at least 1,000 VND',
        groupType: 'Group Type',
        productType: 'Product Type',
        addGroupType: 'Add Group Type',
        digit: 'digits',
        groupTypeList: 'Group Type List',
        skuType: 'SKU Type',
        applyForAllGroupTypes: 'Apply for All Group Types',
        unitPrice: 'Unit Price (VND)',
        groupTypePlaceholder: 'Example: Color,...',
        productTypePlaceholder: 'Example: White, Red,...',
        requiredField: 'This field is required',
        sectionDeliveryInformation: 'Delivery Information',
        weightAfterPackage: 'Weight (after packaging)',
        note: 'Note:',
        packageSize: 'Package Size (The actual shipping fee will change if you input the wrong size)',
        width: 'Width',
        length: 'Length',
        height: 'Height',
        deliveryPrice: 'Shipping Fee',
        express: 'Express',
        fast: 'Fast',
        beDelivery: 'beDelivery (maximum 20,000g)',
        shopee: 'Shopee Xpress Instant (maximum 30,000g)',
        grabExpress: 'GrabExpress (maximum 30,000g)',
        tooltipPrice: 'Estimated shipping fee (based on product weight of 1000g, package size of 10x10x10)',
        back: 'Back',
        reset: 'Reset',
        confirm: 'Confirm',
        detailSub: 'Provide attribute information to enhance the display of the product',
        productName: 'Product Name',
        categoryName: 'Category',
        chooseCategory: 'Choose Category',
        chooseBrand: 'Choose Brand',
        productDescription: 'Product Description',
        brand: 'Brand',
        material: 'Material',
        productCode: 'Product Code',
        origin: 'Origin',
        enterCode: 'Enter product code',
        formatImage: 'File format: PNG, JPEG, JPG',
        imageRequirement: 'Size: up to 5MB',
        formatVideo: 'File format: MP4',
        lengthVideo: 'Length: 10s - 30s',
        sizeVideo: 'Size: up to 30MB',
        overSize: 'File exceeds the allowed size limit',
        overLimit: 'Number of files exceeds',
        need: 'I need',
        prepareDate: 'days to prepare the product (minimum: 7 days - maximum: 15 days)',
        lessThanUnitPrice: 'Promotional price must be lower than the original price',
        deliveryInputError: 'Input value must be greater than 0 and less than 200,000',
        imageError: 'Please upload at least 1 image',
        actionSuccess: 'Action successful',
        actionFail: 'An error occurred. Please try again later'
    }
};

export default i18nLabels;
