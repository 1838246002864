const i18nLabels = {
    vi: {
        downloadData: 'Tải dữ liệu',
        time: 'Thời gian',
        today: 'Hôm nay',
        yesterday: 'hôm qua',
        inTheLast7Days: 'Trong 7 ngày qua',
        inTheLast30Days: 'Trong 30 ngày qua',
        byDate: 'Theo ngày',
        byWeek: 'Theo tuần',
        byMonth: 'Theo tháng',
        byYear: 'Theo năm',
        salesDataByOrders: 'Dữ liệu doanh số theo đơn hàng',
        keyIndex: 'Chỉ số quan trọng',
        sales: 'Doanh số',
        orders: 'Đơn hàng',
        salesPerOrder: 'Doanh số trên mỗi đơn hàng',
        cancelOrder: 'Đơn hủy',
        refundOrder: 'Đơn hoàn tiền',
        salesTooltip:
            'Tổng giá trị của các đơn đặt hàng đã giao thành công trong khoảng thời gian đã chọn, doanh số ghi nhận thời gian theo ngày đặt hàng của đơn hàng giao thành công',
        ordersTooltip: 'Tổng số đơn đặt hàng đã giao thành công trong khoảng thời gian đã chọn',
        salesPerOrderTooltip:
            'Giá trị trung bình của mỗi đơn hàng trong khoảng thời gian đã chọn, được tính bằng tổng doanh số chia tổng đơn hàng',
        cancelOrderTooltip: 'Tổng số đơn đặt hàng đã hủy bời tất cả lý do (bời người mua, người bán,...)',
        refundOrderTooltip: 'Tổng số đơn đặt hàng hoàn tiền thành công cho người mua',
        chart: 'Biểu đồ',
        compareWith: 'So với',
        last2Days: '2 ngày trước',
        last7Days: '7 ngày trước',
        last30Days: '30 ngày trước',
        lastWeek: 'tuần trước',
        lastMonth: 'tháng trước',
        lastYear: 'năm trước',
        userData: 'Dữ liệu người dùng',
        websiteAccessedChart: 'Biểu đồ lượt truy cập website',
        accessTimes: 'Lượt truy cập',
        buyerRate: 'Tỷ lệ người mua',
        currentBuyer: 'Người mua hiện tại',
        newBuyer: 'Người mua mới',
        totalNewUser: 'Tổng số người mua mới',
        potentialBuyer: 'Người mua tiềm năng',
        returnRateBuyer: 'Tỉ lệ quay lại của người mua',
        totalNewUserTooltip: 'Tổng số Người mua có đơn hàng đã giao thành công trong khoảng thời gian đã chọn',
        newBuyerTooltip:
            'Người mua mới là Người mua chưa có đơn hàng từ Shop trong vòng 12 tháng trước khoảng thời gian đã chọn',
        currentBuyerTooltip:
            'Người mua hiện tại là Người mua đã từng đặt và thanh toán ít nhất 1 đơn hàng từ Shop trong vòng 12 tháng trước khoảng thời gian đã chọn',
        potentialBuyerTooltip:
            'Khách hàng tiềm năng là người đã thích sản phẩm hoặc thêm sản phẩm vào giỏ hàng nhưng chưa đặt hàng',
        returnRateBuyerTooltip:
            'Tổng số khách hàng đã mua hàng thành công nhiều hơn một lần, chia cho tổng số khách hàng đã mua hàng trong khoảng thời gian đã chọn',
        productStatistic: 'Thống kê sản phẩm',
        bySales: 'Theo doanh số',
        pyProducts: 'Theo số sản phẩm',
        products: 'Số sản phẩm',
        byView: 'Theo lượt xem',
        views: 'Lượt xem',
        byExchangeRate: 'Theo tỷ lệ chuyển đổi',
        exchangeRate: 'Tỷ lệ chuyển đổi',
        category: 'Ngành hàng',
        allCategories: 'Tất cả ngành hàng',
        rank: 'Thứ hạng',
        productInformation: 'Thông tin sản phẩm',
        error: 'Đã có lỗi xảy ra',
        noDataFound: 'Không có kết quả được tìm thấy',
        to: "Tới"
    },
    en: {
        downloadData: 'Download Data',
        time: 'Time',
        today: 'Today',
        yesterday: 'Yesterday',
        inTheLast7Days: 'In the Last 7 Days',
        inTheLast30Days: 'In the Last 30 Days',
        byDate: 'By Date',
        byWeek: 'By Week',
        byMonth: 'By Month',
        byYear: 'By Year',
        salesDataByOrders: 'Sales Data by Orders',
        keyIndex: 'Key Index',
        sales: 'Sales',
        orders: 'Orders',
        salesPerOrder: 'Sales per Order',
        cancelOrder: 'Canceled Orders',
        refundOrder: 'Refunded Orders',
        salesTooltip:
            'The total value of successfully delivered orders within the selected time period, sales recorded based on the order date of successfully delivered orders',
        ordersTooltip: 'The total number of successfully delivered orders within the selected time period',
        salesPerOrderTooltip:
            'The average value of each order within the selected time period, calculated by dividing the total sales by the total number of orders',
        cancelOrderTooltip: 'The total number of orders canceled for all reasons (by buyers, sellers, etc.)',
        refundOrderTooltip: 'The total number of successfully refunded orders to buyers',
        chart: 'Chart',
        compareWith: 'Compare with',
        last2Days: 'Last 2 Days',
        last7Days: 'Last 7 Days',
        last30Days: 'Last 30 Days',
        lastWeek: 'Last Week',
        lastMonth: 'Last Month',
        lastYear: 'Last Year',
        userData: 'User Data',
        websiteAccessedChart: 'Website Access Chart',
        accessTimes: 'Access Times',
        buyerRate: 'Buyer Rate',
        currentBuyer: 'Current Buyers',
        newBuyer: 'New Buyers',
        totalNewUser: 'Total New Users',
        potentialBuyer: 'Potential Buyers',
        returnRateBuyer: 'Buyer Return Rate',
        totalNewUserTooltip:
            'The total number of buyers who have successfully delivered orders within the selected time period',
        newBuyerTooltip:
            'New buyers are buyers who have not placed an order from the Shop in the previous 12 months of the selected time period',
        currentBuyerTooltip:
            'Current buyers are buyers who have placed and paid for at least 1 order from the Shop in the previous 12 months of the selected time period',
        potentialBuyerTooltip:
            'Potential customers are those who have liked the product or added it to the cart but have not placed an order',
        returnRateBuyerTooltip:
            'The total number of customers who have made multiple successful purchases divided by the total number of customers who have made purchases within the selected time period',
        productStatistic: 'Product Statistics',
        bySales: 'By Sales',
        pyProducts: 'By Number of Products',
        products: 'Products',
        byView: 'By Views',
        views: 'Views',
        byExchangeRate: 'By Conversion Rate',
        exchangeRate: 'Conversion Rate',
        category: 'Category',
        allCategories: 'All Categories',
        rank: 'Rank',
        productInformation: 'Product Information',
        error: 'An error occurred',
        noDataFound: 'No data found',
        to: "To"
    }
};

export default i18nLabels;
