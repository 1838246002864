import {Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tab, Tabs} from '@mui/material';
import {AddAccount, SearchIcon} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import TabPanel from 'components/TabPanel/TabPanel';
import React, {useState} from 'react';
import Account from './subViews/Account/Account';
import Configuration from './subViews/Configuration/Configuration';

const UserManagementMainView = (props) => {
    const {
        intl,
        handleTabChange,
        tabValue,
        roleList,
        setRoleList,
        handleChangeStatus,
        handleSearch,
        handleOpenModal,
        accountTabProps,
        configurationTabProps,
        openRoleModal
    } = props;
    const statusList = [
        {
            value: 'ALL',
            name: intl.messages.statusAll
        },
        {
            value: 'ACTIVE',
            name: intl.messages.statusActive
        },
        {
            value: 'UN_ACTIVE',
            name: intl.messages.statusUnActive
        }
    ];
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('ALL');

    const onCheckPermission = (item, checked) => {
        const newRoleList = [...roleList];
        const index = roleList.indexOf(item);
        newRoleList[index].checked = checked;
        setRoleList(newRoleList);
    };

    return (
        <div className='user-management'>
            <div className='user-management-header d-flex align-items-center justify-content-between'>
                <div>
                    <h3 className='m-0 user-management-title'>{intl.messages.accountTitle}</h3>
                </div>
                {tabValue === 0 && (
                    <div>
                        <CustomInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleSearch(searchValue);
                                }
                            }}
                            starticon={<SearchIcon />}
                            placeholder={intl.messages.search}
                        />
                    </div>
                )}
            </div>

            <div className='user-management-body row'>
                <Box className='col-5'>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label={intl.messages.accountTab} />
                        <Tab label={intl.messages.configurationTab} />
                    </Tabs>
                </Box>

                <div className='col-7'>
                    {tabValue === 0 ? (
                        <div className='d-flex align-items-center justify-content-end'>
                            <div>
                                <FormControl className='align-items-center height-48'>
                                    <InputLabel id='demo-simple-select-label'>{intl.messages.privilege}</InputLabel>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={roleList}
                                        label={intl.messages.role}
                                        sx={{height: 48, width: 140}}
                                        multiple={true}
                                        renderValue={(permission) => {
                                            //permission is permissionList
                                            const render = [];
                                            permission
                                                .filter((el) => el.checked)
                                                .forEach((el) => {
                                                    render.push(el.name);
                                                });
                                            return render.join(', ');
                                        }}>
                                        {roleList.map((el) => (
                                            <MenuItem value={roleList} sx={{width: 220}} key={el.id}>
                                                <FormControlLabel
                                                    labelPlacement='start'
                                                    className='d-flex justify-content-between w-100 ml-0'
                                                    label={el.name}
                                                    control={
                                                        <Checkbox
                                                            checked={el.checked}
                                                            onChange={(e) => onCheckPermission(el, e.target.checked)}
                                                        />
                                                    }
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='ml-8'>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>{intl.messages.status}</InputLabel>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={status}
                                        label={intl.messages.status}
                                        sx={{height: 48, width: 157}}
                                        onChange={(e) => {
                                            handleChangeStatus(e.target.value);
                                            setStatus(e.target.value);
                                        }}>
                                        {statusList.map((el) => (
                                            <MenuItem value={el.value} key={el.value}>
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='ml-8'>
                                <CustomButton onClick={() => handleOpenModal(true)}>
                                    <img src={AddAccount} alt='add-account-icon' /> {intl.messages.addNew}
                                </CustomButton>
                            </div>
                        </div>
                    ) : (
                        <div className='d-flex justify-content-end mb-2'>
                            <CustomButton onClick={openRoleModal}>{intl.messages.addNewRole}</CustomButton>
                        </div>
                    )}
                </div>

                <TabPanel value={tabValue} index={0}>
                    <Account {...accountTabProps} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Configuration {...configurationTabProps} />
                </TabPanel>
            </div>
        </div>
    );
};

export default UserManagementMainView;
