import React, {useState} from 'react';
import {FormattedNumber} from 'react-intl';
import {Rating} from '@mui/material';
import {favCart, Heart, TagIcon2} from 'assets/images';
import ProductImageCarousel from 'components/ProductImageCarousel/ProductImageCarousel';
import {pagePath} from 'configuration/routeConfig';
import Config from 'configuration';
import ReactGA from 'react-ga';

import ButtonIcon from 'components/ButtonIcon';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

const ProductCard = (props) => {
    const {item, navigateTo, multipleSelect, onOpenDeleteModal, handleMultiChange} = props;

    const hasImgList = item.hasOwnProperty('extend_images') && item.extend_images.length > 1;
    const imageSourceList =
        hasImgList && item.extend_images
            ? item.extend_images.map((image) => `${Config.apiConfig.endPoint}${image.physical_path}`)
            : [];
    const isFavourite = item.hasOwnProperty('type') && item.type === 'wishlist';
    const goToPDP = () => {
        ReactGA.event({
            category: 'Product detail',
            action: 'view',
            label: item?.name,
            value: item?.price
        });
        if (isFavourite) {
            navigateTo(`${pagePath.pdpUrl}/${item.product_id}`);
        } else {
            const {id} = item;

            if (id) navigateTo(`${pagePath.pdpUrl}/${id}`);
        }
    };

    return (
        <div className='product-card' onClick={!isFavourite ? goToPDP : null}>
            <div className='product-card__media' onClick={goToPDP}>
                {!hasImgList ? (
                    <img
                        src={`${Config.apiConfig.endPoint}${
                            item?.default_image ? item?.default_image : item?.extend_product?.default_image
                        }`}
                        alt={`${item.id}`}
                    />
                ) : (
                    <ProductImageCarousel imageSourceList={imageSourceList} />
                )}
                {item.discount ? (
                    <>
                        <div className='item-tag'>
                            <img src={TagIcon2} alt='tag-icon' />
                            <p>-{item?.discount}%</p>
                        </div>
                    </>
                ) : null}
            </div>
            <div className='product-card__content'>
                <div className='product-card__top'>
                    <h6 className='item-title'>{!isFavourite ? item?.name : item?.extend_product.name}</h6>
                    <div className='item-price'>
                        <div className='item-sale-price'>
                            <FormattedNumber
                                style='currency'
                                currency='VND'
                                value={item?.price ? item?.price : item?.extend_product?.price}
                            />
                        </div>
                        {!isFavourite ? (
                            item?.origin_price !== item?.price && (
                                <div className='item-origin-price'>
                                    <FormattedNumber style='currency' currency='VND' value={item?.origin_price} />
                                </div>
                            )
                        ) : (
                            <div className='sold-quantity'>Đã bán {item?.sold}</div>
                        )}
                    </div>
                </div>
                <div className='product-card__bottom'>
                    {!isFavourite ? (
                        <div className='rating'>
                            <Rating name='read-only' value={item?.avg_rate ? item.avg_rate : 0} size='small' readOnly />
                            <p>({item?.user_rating_amount})</p>
                        </div>
                    ) : null}
                    {!isFavourite ? (
                        <div className='sold-quantity'>Đã bán {item?.sold}</div>
                    ) : (
                        <div className='fav-product'>
                            {!multipleSelect ? (
                                <div onClick={() => onOpenDeleteModal(item.product_id)}>
                                    <Heart />
                                    <span>Bỏ thích</span>
                                </div>
                            ) : (
                                <div className='checkbox-container'>
                                    <CustomCheckbox onChange={handleMultiChange} value={item.extend_product?.id} />
                                </div>
                            )}
                        </div>
                    )}
                    {!isFavourite ? null : (
                        <ButtonIcon icon={<img src={favCart} alt='fav-cart' />} onClick={goToPDP} label='cart' />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
