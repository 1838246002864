import ReactGA from 'react-ga';

const googleAnalyticsActions = {};

googleAnalyticsActions.initialize = async (key) => {
    ReactGA.initialize(key);
};

googleAnalyticsActions.trackingPageView = async (pageTitle) => {
    ReactGA.pageview(window.location.pathname + window.location.search, '', pageTitle);
};

export {googleAnalyticsActions};
