import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';
import ProgressBarWithLabel from 'components/ProgressBar/ProgressBar';
import {HaidangLogo} from 'assets/images';

const VoucherCard = (props) => {
    const {voucherList, intl, onOpenVoucherModal, haveButton = true, styleBottom} = props;
    const date = new Date(voucherList.expiry);
    const expireDay = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const {value_percentage, value, used_quantity, quantity, min_order_apply} = voucherList;
    const usedPercentage = Math.round(used_quantity / quantity) * 100;

    return (
        <div className='voucher-card'>
            <div className='d-flex flex-wrap align-items-center'>
                <div className='ticket overflow-hidden with-100'>
                    <div className='voucher-card-item position-relative'>
                        <div className='row gx-0 card-container'>
                            <div className='col-4 position-relative card-container-left'>
                                <div className='d-flex flex-column justify-content-center align-items-center p-3 h-100'>
                                    <img src={HaidangLogo} alt='hai dang' />
                                    <p className='mt-2'>{intl.messages.company}</p>
                                </div>
                                <div className='position-absolute divider-top'></div>
                                <div className='position-absolute divider-bottom'></div>
                            </div>
                            <div className='col-8 card-container-right'>
                                <div className='d-flex flex-column justify-content-between h-100'>
                                    <div className='top'>
                                        <div className='d-flex justify-content-between'>
                                            <p className='price'>
                                                {intl.messages.discount} {value_percentage}%
                                            </p>
                                        </div>
                                        <p className='text-secondary max-price'>
                                            {intl.messages.fromOrder}{' '}
                                            {min_order_apply ? Math.round(min_order_apply / 1000) : 0}k
                                        </p>
                                    </div>
                                    <div style={{...styleBottom}} className='bottom'>
                                        <div className='d-flex flex-column gap-2'>
                                            <ProgressBarWithLabel max={quantity} data={used_quantity} />
                                            <div className='d-flex flex-column gap-1'>
                                                {usedPercentage >= 70 && (
                                                    <span className='text-danger'>
                                                        {intl.messages.used} {usedPercentage}%
                                                    </span>
                                                )}
                                                <span className='text-expire'>
                                                    {intl.messages.expire}: {expireDay}
                                                </span>
                                            </div>
                                        </div>

                                        {haveButton && (
                                            <CustomButton
                                                size='sm'
                                                className='action-btn'
                                                onClick={() => {
                                                    onOpenVoucherModal(voucherList);
                                                }}>
                                                {intl.messages.actionBtn}
                                            </CustomButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='position-absolute border-item-left'></div>
                        <div className='position-absolute border-item-right'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherCard;
