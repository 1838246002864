import ChatBox from 'components/ChatBox';
import Footer from 'components/Footer';
import Header from 'components/Header';
import LoaderSpinner from 'components/Loader';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import {redirectList, routeList} from 'configuration/routeConfig';

import {Redirect, BrowserRouter as Router, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import ScrollToTop from 'utils/hooks/ScrollToTop';
import CustomRoute from './CustomRoute';
import {onMessageListener} from 'firebase-setup/firebase';
import {useDispatch} from 'react-redux';
import {getAllNotifications} from 'features/Notification/notificationSlice';
import {useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {Toast} from 'utils/toast';
import { googleAnalyticsActions } from 'utils/googleAnalytics';
const TRACKING_ID = "UA-158120945-1"; // OUR_TRACKING_ID

const Routers = () => {
    const dispatch = useDispatch();
    googleAnalyticsActions.initialize(TRACKING_ID);
    
    useEffect(() => {
        // Import firebase and initialize the app here
        const firebaseConfig = {
            apiKey: 'AIzaSyCEcMqn7YSFzgINK9PRl1aJZJbWI3EaGr4',
            authDomain: 'ecommerce-3574d.firebaseapp.com',
            projectId: 'ecommerce-3574d',
            storageBucket: 'ecommerce-3574d.appspot.com',
            messagingSenderId: '1058555325067',
            appId: '1:1058555325067:web:8c304e4f1cdc04dc93428e',
            measurementId: 'G-D87CN7Y97N'
        };
        firebase.initializeApp(firebaseConfig);

        // Retrieve firebase messaging
        const messaging = firebase.messaging();

        // Add an event listener to handle messages received while the app is in the foreground
        messaging.onMessage((payload) => {
            console.log('Received foreground message: ', payload);
            Toast(true, 'Bạn có thông báo mới');
            fetchAllNotification();
        });

        // Add an event listener to handle messages received while the app is in the background
        // This event listener should be registered in the service worker
        navigator.serviceWorker.addEventListener('message', (event) => {
            console.log('Received background message: ', event.data);
        });
    }, []);

    const fetchAllNotification = async () => {
        await dispatch(getAllNotifications()).unwrap();
    };

    return (
        <Router>
            <ScrollToTop />
            {/* <ChatBox /> */}
            <Header />
            <LoaderSpinner />
            <Switch>
                {redirectList.map((redirect, idx) => (
                    <Redirect key={`redirect-${idx}`} exact from={redirect.from} to={redirect.to} />
                ))}
                {routeList.map((route, idx) => (
                    <CustomRoute key={`route-${idx}`} exact {...route} />
                ))}
            </Switch>
            <Footer />
            <ToastContainer theme='colored' />
            <ScrollToTopButton />
        </Router>
    );
};

export default Routers;
