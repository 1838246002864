const i18nLabels = {
    vi: {
        cart: 'Giỏ hàng',
        product: 'sản phẩm',
        emptyCart: 'Hiện chưa có sản phẩm nào',
        goShopping: 'Tiếp tục mua sắm',
        selectAll: 'Chọn tất cả',
        deleteAll: 'Xóa tất cả',
        deselectAll: 'Bỏ chọn tất cả',
        soldOut: 'Hết hàng',
        left: 'Còn',
        cartSummary: 'Tóm tắt đơn hàng',
        totalPrice: 'Tiền sản phẩm',
        discount: 'Giảm giá',
        finalPrice: 'Tổng cộng',
        goPayment: 'Tiến hành thanh toán',
        shopVoucher: 'Giảm giá của shop',
        confirm: 'Xác nhận',
        close: 'đóng',
        delete: 'xóa',
        cancel: 'hủy',
        deleteAllProductsMessage: 'Bạn có chắc chắn xóa tất cả sản phẩm này',
        deleteThisProductMessage: 'Bạn có chắc chắn xóa sản phẩm này',
        voucherCode: 'Mã giảm giá',
        voucherModalTitle:
            'Bạn vui lòng nhập mã khuyến mãi hoặc chọn những mã khuyến mãi được áp dụng bên dưới (nếu có) để mua hàng tiết kiệm hơn nhé!',
        voucherModalPlaceholder: 'Nhập hoặc chọn mã khuyến mãi',
        noCoupon: 'Không có mã khuyến mãi rồi!',
        couponCode: 'Mã khuyến mãi',
        couponCodeExpired: 'Mã khuyến mãi hết hạn',
        couponCodeFull: 'Mã khuyến mãi hết lượt sử dụng',
        couponCodeMin: 'Đặt tối thiểu',
        couponCodeCategory: 'Không thỏa ngành hàng',
        couponCodeNotExist: 'Mã khuyến mãi không tồn tại',
        couponCodeFail: 'Chưa thỏa điều kiện',
        apply: 'Áp dụng',
        cancelApply: 'Hủy áp dụng',
        allCoupons: 'Tất cả khuyến mãi',
        voucher: 'voucher',
        expiredDate: 'HSD',
        save: 'Lưu',
        appliedCoupon: 'Mã khuyến mãi đã được áp dụng, bạn được giảm',
        thisOrder: 'cho đơn hàng này đó!',
        notCondition: 'Không đủ điều kiện',
        decrease: 'giảm',
        store: 'Cửa hàng',
        readmore: 'Xem thêm',
        shorten: 'Rút gọn',
        notExist: "Không tồn tại",
        hidden: 'Đã ẩn'
    },
    en: {
        cart: 'Cart',
        product: 'Product',
        emptyCart: 'There are no products in your cart yet',
        goShopping: 'Continue Shopping',
        selectAll: 'Select All',
        deleteAll: 'Delete All',
        deselectAll: 'Deselect All',
        soldOut: 'Sold Out',
        left: 'Left',
        cartSummary: 'Order Summary',
        totalPrice: 'Product Total',
        discount: 'Discount',
        finalPrice: 'Total Price',
        goPayment: 'Proceed to Payment',
        shopVoucher: 'Shop Voucher',
        confirm: 'Confirm',
        close: 'Close',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteAllProductsMessage: 'Are you sure you want to delete all these products?',
        deleteThisProductMessage: 'Are you sure you want to delete this product?',
        voucherCode: 'Voucher Code',
        voucherModalTitle:
            'Please enter the promotional code or select from the applicable codes below (if any) to save more on your purchase!',
        voucherModalPlaceholder: 'Enter or select a promotional code',
        noCoupon: 'No coupons available!',
        couponCode: 'Coupon Code',
        couponCodeExpired: 'Coupon Code Expired',
        couponCodeFull: 'Coupon Code Fully Redeemed',
        couponCodeMin: 'Minimum Order',
        couponCodeCategory: 'Not Applicable to Category',
        couponCodeNotExist: 'Coupon Code Does Not Exist',
        couponCodeFail: 'Not Meeting Conditions',
        apply: 'Apply',
        cancelApply: 'Cancel Apply',
        allCoupons: 'All Coupons',
        voucher: 'voucher',
        expiredDate: 'Expiration Date',
        save: 'Save',
        appliedCoupon: 'Coupon code applied, you will get a discount of',
        thisOrder: 'for this order!',
        notCondition: 'Not Meeting Conditions',
        decrease: 'decrease',
        store: 'Store',
        readmore: 'Read more',
        shorten: 'Shorten',
        notExist: "Does not exist",
        hidden: 'Hidden'
    }
};

export default i18nLabels;
