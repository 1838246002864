export default function propsProvider(props) {
    const {
        dispatch,
        intl,
        handleCancel,
        handleSave,
        salesInformationType,
        handleRadioSalesChange,
        handleSubmitInvalid,
        handleAddGroupType,
        handleDeleteGroupType,
        handleSubmitBasicInformation,
        categories,
        deliveryPrice,
        preOrderDate,
        setPreOrderDate,
        brands,
        productImages,
        handleDeleteProductImage,
        handleUploadProductImages,
        formActivated,
        handleSubmit,
        control,
        errors,
        watchAllFields,
        getValues,
        handleExpandSection,
        sectionState,
        setValue,
        applyAllHeadCells,
        advancedSalesInformationInputs,
        mainDataState,
        handleUploadProductTypeImage,
        handleGroupTypeInputChange,
        formRef,
        handleDeleteProductTypeImage,
        productTypeImages,
        clearErrors,
        isEdit,
        attributes, setAttributes
    } = props;

    const actionBarControlButtons = [
        { label: intl.messages.controlButtonSectionCancel, onClick: () => handleCancel(), variant: 'text' },
        {
            label: intl.messages.controlButtonSectionTemp,
            variant: 'text',
            type: 'button',
            disabled: !(mainDataState.length > 0 || salesInformationType === 'basic'),
            onClick: () => {
                setValue('is_published', false);
                formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }
        },
        {
            label: intl.messages.controlButtonSectionSaveAndShow,
            variant: 'contained',
            type: 'button',
            disabled: !(mainDataState.length > 0 || salesInformationType === 'basic'),
            onClick: () => {
                setValue('is_published', true);
                formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }
        }
    ];

    return {
        intl,
        dispatch,
        formActivated,
        handleSubmitInvalid,
        handleSave,
        actionBarControlButtons,
        handleSubmit,
        formRef,
        addProductProgressProps: {
            sectionState
        },
        basicInformationProps: {
            intl,
            handleSubmitBasicInformation,
            categories,
            productImages,
            handleDeleteProductImage,
            handleUploadProductImages,
            formActivated,
            control,
            errors,
            watchAllFields,
            isEdit,
            toggleExpand: () => handleExpandSection(0),
            isExpanded: sectionState[0].isExpanded,
            ref: sectionState[0].ref,
            setAttributes
        },
        detailInformationProps: {
            intl,
            brands,
            formActivated,
            watchAllFields,
            errors,
            control,
            toggleExpand: () => handleExpandSection(1),
            isExpanded: sectionState[1].isExpanded,
            ref: sectionState[1].ref,
            attributes
        },
        salesInformationProps: {
            intl,
            salesInformationType,
            handleRadioSalesChange,
            formActivated,
            toggleExpand: () => handleExpandSection(2),
            isExpanded: sectionState[2].isExpanded,
            ref: sectionState[2].ref,
            basicFormProps: {
                intl,
                control,
                errors,
                getValues
            },
            advancedFormProps: {
                intl,
                advancedSalesInformationInputs,
                handleAddGroupType,
                handleDeleteGroupType,
                applyAllHeadCells,
                errors,
                control,
                setValue,
                mainDataState,
                handleUploadProductTypeImage,
                handleGroupTypeInputChange,
                handleDeleteProductTypeImage,
                productTypeImages,
                clearErrors
            }
        },
        deliveryInformationProps: {
            intl,
            deliveryPrice,
            formActivated,
            control,
            errors,
            toggleExpand: () => handleExpandSection(3),
            isExpanded: sectionState[3].isExpanded,
            ref: sectionState[3].ref
        },
        otherInformationProps: {
            intl,
            preOrderDate,
            setPreOrderDate,
            formActivated,
            control,
            getValues,
            toggleExpand: () => handleExpandSection(4),
            isExpanded: sectionState[4].isExpanded,
            ref: sectionState[4].ref
        },
        controlButtonSectionProps: {
            handleCancel,
            handleSave,
            intl
        }
    };
}
