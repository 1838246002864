import {FormControl, FormHelperText} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import React, {forwardRef} from 'react';
import {Controller} from 'react-hook-form';
import AddProductSection from '../AddProductSection/AddProductSection';

const DetailInformationSection = forwardRef((props, ref) => {
    const {intl, brands, formActivated, watchAllFields, errors, control, isExpanded, toggleExpand, attributes} = props;

    const addProductSectionProps = {
        title: intl.messages.progressDetailInformation,
        subTitle: intl.messages.detailSub,
        formActivated,
        isExpanded,
        toggleExpand
    };
    const maxCodeLength = 120;
    const detailInformationInputs = [
        {
            label: intl.messages.brand,
            name: 'brand_id',
            rules: {required: {value: true, message: intl.messages.requiredField}},
            type: 'select',
            options: brands.map((option) => {
                return {
                    id: option.id,
                    value: option.name
                };
            })
        },
        {
            label: intl.messages.productCode,
            name: 'product_code',
            rules: {maxLength: maxCodeLength},
            placeholder: intl.messages.enterCode
        },
        {
            label: intl.messages.origin,
            name: 'origin'
        }
    ];

    if (attributes) {
        detailInformationInputs.push(
            ...attributes.map((attr) => {
                return {
                    label: attr.name,
                    name: 'features&=' + attr.name,
                    rules: attr.required ? {required: {value: true, message: intl.messages.requiredField}} : {}
                };
            })
        );
    }

    return (
        <AddProductSection {...addProductSectionProps} ref={ref}>
            <div className='row mt-2 detail-section'>
                {detailInformationInputs.map((inputValue, idx) => {
                    const {name, rules = {}, label, placeholder, type = 'text', options} = inputValue;
                    const currentValue = watchAllFields[name];
                    const labelContent = (
                        <span>
                            {label} {rules.required?.value ? <span className='text-required'>*</span> : ''}
                        </span>
                    );

                    const {maxLength} = rules;
                    const helperText = !!maxLength
                        ? `${currentValue.length}/${maxLength} ${intl.messages.digit}`
                        : null;

                    const isErrorOnInput = currentValue?.length > maxLength;

                    return (
                        <Controller
                            key={name}
                            control={control}
                            rules={rules}
                            name={name}
                            render={({field}) => (
                                <FormControl className={idx < 2 ? 'col-6' : 'col-6 mb-3'} fullWidth>
                                    {type === 'select' ? (
                                        <CustomSelect
                                            {...field}
                                            options={options}
                                            placeholder={intl.messages.chooseBrand}
                                            label={labelContent}
                                            valid={!errors[name]}
                                        />
                                    ) : (
                                        <CustomInput
                                            {...field}
                                            placeholder={placeholder}
                                            valid={!(errors[name] || isErrorOnInput)}
                                            label={labelContent}
                                            className='validate-input'
                                        />
                                    )}

                                    {(helperText || errors[name]?.message) && (
                                        <FormHelperText error={!!errors[name] || isErrorOnInput}>
                                            {errors[name]?.message ? errors[name].message : helperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    );
                })}
            </div>
        </AddProductSection>
    );
});

export default DetailInformationSection;
