import {Popover} from '@mui/material';
import React, {forwardRef} from 'react';

const CellPopover = forwardRef(({className = '', tableRowActions = [], id, setAnchorPopoverEl, ...props}, ref) => {
    return (
        <Popover ref={ref} PaperProps={{className: `cell-popover ${className}`}} {...props}>
            {tableRowActions.map((action) => (
                <div
                    className='cell-popover__action'
                    key={action.title}
                    onClick={() => {
                        setAnchorPopoverEl(null);
                        action.onClick({type: action.actionType, payload: [id]});
                    }}>
                    {action.icon}
                    <span>{action.title}</span>
                </div>
            ))}
        </Popover>
    );
});

export default CellPopover;
