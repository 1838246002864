import CustomButton from 'components/CustomButton/CustomButton';
import {EditOutline, Trash, Plus} from 'assets/images';

const AddressListModal = (props) => {
    const {
        userAddressList,
        onOpenAddressFormModal,
        onOpenDeleteAddressModal
    } = props;

    return (
        <>
            {userAddressList &&
                userAddressList.collection.map((address) => (
                    <div
                        key={address.id}
                        className='d-flex justify-content-between align-items-center'
                        style={{marginBottom: 16}}>
                        <div style={{width: '80%'}} className='d-flex align-items-center'>
                            <label style={{marginLeft: 8, fontSize: 13}}>
                                <div className='d-flex align-items-center'>
                                    <span style={{fontSize: 15, fontWeight: 500}}>{address.fullname} &nbsp;</span>
                                    <div
                                        style={{
                                            width: 4,
                                            height: 4,
                                            backgroundColor: '#c3c9d0',
                                            borderRadius: '50%'
                                        }}></div>
                                    <span style={{fontSize: 15, fontWeight: 500}}>&nbsp; {address.phone}</span>
                                </div>
                                <div>
                                    <span style={{fontSize: 15, color: '#808080'}}>{address.full_address}</span>
                                </div>
                                {address.is_default && (
                                    <div style={{marginTop: 8}}>
                                        <span
                                            style={{
                                                padding: 4,
                                                backgroundColor: '#ecf0fe',
                                                borderRadius: 4,
                                                fontSize: 12
                                            }}>
                                            Mặc định
                                        </span>
                                    </div>
                                )}
                            </label>
                        </div>

                        <div className='flex-shrink-0 d-flex justify-content-between align-items-center gap-2 gap-sm-3'>
                            <img
                                src={EditOutline}
                                alt='edit'
                                style={{cursor: 'pointer'}}
                                onClick={() => onOpenAddressFormModal(address)}
                            />
                            {!address.is_default && (
                                <Trash
                                    style={{cursor: 'pointer'}}
                                    onClick={() => onOpenDeleteAddressModal(address.id)}
                                />
                            )}
                        </div>
                    </div>
                ))}

            <CustomButton size='md' variant='text'>
                <div className='d-flex align-items-center' onClick={() => onOpenAddressFormModal()}>
                    <Plus className='me-1' />
                    <span>Thêm địa chỉ</span>
                </div>
            </CustomButton>
        </>
    );
};

export default AddressListModal;
