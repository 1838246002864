import { Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { forwardRef, useState } from 'react';

const SelectPopover = forwardRef((props, ref) => {
	const { label, displayValue, children, popoverProps, popoverId, isRequired = false } = props;
	const [borderStatus, setBorderStatus] = useState('none');
	const [anchorEl, setAnchorEl] = useState(null);

	const mouseOverGroupInput = () => {
		if (borderStatus !== 'focus') {
			setBorderStatus('hover');
		}
	};
	const mouseOutGroupInput = () => {
		if (borderStatus !== 'focus') {
			setBorderStatus('none');
		}
	};

	const handleOpenDateModal = (event) => {
		setAnchorEl(event.currentTarget);
		setBorderStatus('focus');
	};

	const handleCloseDateModal = () => {
		setAnchorEl(null);
		setBorderStatus('none');
	};

	return (
		<>
			<div
				ref={ref}
				className='select-popover'
				onMouseEnter={mouseOverGroupInput}
				onMouseLeave={mouseOutGroupInput}
				onClick={handleOpenDateModal}
				aria-describedby={popoverId}
				tabIndex='0'>
				<fieldset className={`${borderStatus} w-100`}>
					<legend>
						<span>{label} {isRequired && <span className='text-required'>*</span>}</span>
					</legend>
					<div className='select-popover__container'>
						{displayValue}
						<KeyboardArrowDownIcon className='popover-arrow' />
					</div>
				</fieldset>
			</div>
			<Popover
				id={popoverId}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleCloseDateModal}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={popoverProps}>
				{children}
			</Popover>
		</>
	);
});

export default SelectPopover;