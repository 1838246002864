import axiosClient from './axiosClient';

const rolesApi = {
    getRoles: () => {
        const url = '/api/roles';
        return axiosClient.get(url);
    },

    getRoleId: (id) => {
        const url = `/api/roles/${id}`;
        return axiosClient.get(url);
    },

    updateRoleId: ({ id, data }) => {
        const url = `/api/roles/${id}`;
        return axiosClient.put(url, data);
    },

    addRoles: (data) => {
        const url = '/api/roles';
        return axiosClient.post(url, data);
    },

    changeUserRole: (data) => {
        const url = '/api/roles/changeUserRoleToAnotherRole';
        return axiosClient.put(url, data, { params: data });
    },

    addPermissionsToUser: (data) => {
        const { id, permissions } = data;
        const url = `/api/roles/addPermissionsToRole/${id}`;
        return axiosClient.put(url, permissions);
    },

    addUserToRole: (data) => {
        const url = '/api/roles/addUserToRole/roleUser';
        return axiosClient.post(url, data);
    },

    deleteUserOutOfRole: (data) => {
        const url = '/api/role/removeUserOutRole';
        return axiosClient.delete(url, { params: data });
    },

    deleteRole: (id) => {
        const url = `/api/role/${id}`;
        return axiosClient.delete(url);
    }
};

export default rolesApi;
