import React, {useEffect, useState} from 'react';
import {
    Box,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from '@mui/material';
import {close} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import {Controller, useForm} from 'react-hook-form';

const defaultValues = {
    unit_name: '',
    transportForms: [],
    description: ''
};

const ShippingFormModal = (props) => {
    const {intl, isOpenModal, isAddNewModal, handleCloseModal, handleSubmitFormModal, selectedUnitObj} = props;

    const formOptions = [
        {
            code: 'FAST',
            name: `${intl.messages.fastShip}`
        },
        {
            code: 'NORMAL',
            name: `${intl.messages.normalShip}`
        },
        {
            code: 'ULTIMATE',
            name: `${intl.messages.ultimateShip}`
        },
        {
            code: 'SELF',
            name: `${intl.messages.selfShip}`
        }
    ];

    const {control, handleSubmit, reset, watch, setValue} = useForm({defaultValues: defaultValues});
    const watchAllFields = watch();
    const checkButtonDisabled = watchAllFields.unit_name?.length === 0 || watchAllFields.transportForms?.length === 0;

    useEffect(() => {
        reset();
    }, [isOpenModal])

    useEffect(() => {
        if(!isAddNewModal && selectedUnitObj) {
            setValue('unit_name', selectedUnitObj.name);
            setValue('transportForms', JSON.parse(selectedUnitObj.shipping_options) || []);
            setValue('description', selectedUnitObj.description || '');
        }
    }, [selectedUnitObj, isOpenModal, isAddNewModal])

    return (
        <Dialog maxWidth={'sm'} open={isOpenModal} fullWidth={true} className='dialog'>
            <form action='#' noValidate onSubmit={handleSubmit(handleSubmitFormModal)}>
                <DialogTitle className='dialog-title' id='add-new-account-modal'>
                    <span>{isAddNewModal ? intl.messages.addNew : intl.messages.edit}</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                handleCloseModal();
                            }}
                        />
                    </button>
                </DialogTitle>

                <DialogContent className='dialog-content'>
                    <div>
                        <Controller
                            name={'unit_name'}
                            control={control}
                            render={({field}) => (
                                <CustomInput
                                    id='outlined-basic'
                                    label={
                                        <span>
                                            {intl.messages.shippingUnit}
                                            <span style={{color: '#de4841'}}>&nbsp;*</span>
                                        </span>
                                    }
                                    placeholder={`Nhập tên ${intl.messages.shippingUnit}`}
                                    variant='outlined'
                                    fullWidth
                                    {...field}
                                />
                            )}></Controller>
                    </div>
                    <div style={{marginTop: 16}}>
                        <Controller
                            name={'transportForms'}
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label'>
                                        {intl.messages.transportForm} *
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        multiple
                                        value={value}
                                        label={
                                            <span>
                                                {intl.messages.transportForm}
                                                <span style={{color: '#de4841'}}>&nbsp;*</span>
                                            </span>
                                        }
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        sx={{height: 48}}
                                        onChange={onChange}>
                                        {formOptions.map((el) => (
                                            <MenuItem key={el.code} value={el.name}>
                                                <Checkbox checked={value.indexOf(el.name) > -1} />
                                                <ListItemText primary={el.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}></Controller>
                    </div>
                    <div style={{marginTop: 16}}>
                        <Controller
                            name={'description'}
                            control={control}
                            render={({field}) => (
                                <CustomInput
                                    id='outlined-basic'
                                    type='textarea'
                                    label={intl.messages.description}
                                    placeholder={`Nhập ${intl.messages.description}`}
                                    variant='outlined'
                                    fullWidth
                                    {...field}
                                />
                            )}></Controller>
                    </div>
                </DialogContent>

                <DialogActions className='dialog-actions'>
                    <CustomButton
                        variant='text'
                        className='header-account__signup'
                        onClick={(e) => {
                            e.preventDefault();
                            handleCloseModal();
                        }}>
                        {isAddNewModal ? intl.messages.close : intl.messages.cancel}
                    </CustomButton>
                    <CustomButton variant='contained' type='submit' disabled={checkButtonDisabled}>
                        {isAddNewModal ? intl.messages.add : intl.messages.save}
                    </CustomButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ShippingFormModal;
