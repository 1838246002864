import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import propsProvider from './chatSettingPropsProvider';
import ChatSettingMainView from './template/ChatSettingMainView';
import {getChatList,updateChat, deleteChat,addChatList,updateSwitch} from './ChatSettingSlice'
import { Toast } from 'utils/toast';

const ChatSettingContainer = (props) => {
	const { dispatch, history } = props;
	const intl = useIntl();
	const [enableAutoChat, setEnableAutoChat] = useState(false)
	const [enableQuickChat, setEnableQuickChat] = useState(false)
	const [contentAutoChat, setContentAutoChat] = useState('')
	const [tableChat,setTableChat]= useState([])
	// const adminChatSetting= useSelector(adminChatSettingSelector)
	// const { adminViewChat, paginationData, categories } = adminChatSetting;

	const navigateTo = (path) => {
		history.push(path);
	};

	const handleChangeAutoChat = async (event) =>{
		const data = {...tableChat.filter(item=>item.type==="auto")?.[0],is_enabled:event.target.checked}
		setEnableAutoChat(event.target.checked)
		dispatch(updateSwitch(data)).then(res=>{
			const {success} = res.payload;
			if(success){
				const newData = [...tableChat]
				const index = tableChat.filter(item=>item.id===data.id)
				newData.splice(index,1,data)
			}
		})
	}
	const handleCloseAutoChat = ()=>{
		setContentAutoChat({...contentAutoChat,isEdit:false,disabled:true})
	}
	const handleChangeQuickChat= async (event) =>{
		const data = {...tableChat.filter(item=>item.type==="fast"&&item.is_enabled!==null)?.[0],is_enabled:event.target.checked}
		setEnableQuickChat(event.target.checked)
		dispatch(updateSwitch(data)).then(res=>{
			const {success} = res.payload;
			if(success){
				const newData = [...tableChat]
				const index = tableChat.filter(item=>item.id===data.id)
				newData.splice(index,1,data)
			}
		})
	}
	const handleSubmitAutoChat = async (data) =>{
		if(!data){
			Toast(false,'Vui lòng không để trống nội dung')
			return
		}
		const tempData = {...contentAutoChat,content:data}
		if(!contentAutoChat.id){
			dispatch(addChatList(tempData)).then(res=>{
				const {success} = res.payload
				if(success){
					setContentAutoChat({...res.payload.data,isEdit:false,disabled:true})
				}
			})
		}else{
			dispatch(updateChat(tempData)).then(res=>{
				const {success} = res.payload
				if(success){
					setContentAutoChat({...res.payload.data,isEdit:false,disabled:true})
				}
			})
		}
	}

	const editQuickChat = async (data) => {
		const newTableChat = [...tableChat];
		const index = tableChat.findIndex(item=>item.id === data.id)
		newTableChat[index].isEdit = true;
		setTableChat(newTableChat)
	}
	const cancelQuickChat = async (data,key) => {
		const newTableChat = [...tableChat];
		const index = tableChat.findIndex(item=>item.id === data.id)
		if(data.id){
			console.log(data)
			newTableChat[index].isEdit = false;
			setTableChat(newTableChat)
		}else{
			console.log('no data',data)
			newTableChat.splice(key+1,1);
			setTableChat(newTableChat)
		}
	}
	const updateQuickChat = async (item,key) => {
		if(!item.content){
			Toast(false,'Vui lòng nhập nội dung')
			return
		}
		const tempTable = [...tableChat]
		if(item.id){
			const index = tableChat.findIndex(element=>element.id===item.id)
			dispatch(updateChat(item)).then(res=>{
				const {success} = res.payload;
				const {data} = res.payload;
				if(success){
					tempTable.splice(index,1,{...data,isEdit:false})
					setTableChat(tempTable)
					Toast(success,'Chỉnh sửa câu trả lời thành công!')
				}else{
					Toast(success,'Chỉnh sửa câu trả lời thất bại!')
				}
			})
		}else{
			dispatch(addChatList(item)).then(res=>{
				const {success} = res.payload;
				const {data} = res.payload;
				if(success){
					tempTable.splice(key+1,1,{...data,isEdit:false})
					setTableChat(tempTable)
					Toast(success,'Thêm câu trả lời thành công!')
				}else{
					Toast(success,'Thêm câu trả lời thất bại!')
				}
			})
		}
	}
	const deleteQuickChat = async (data) => {
		const tempTable = [...tableChat]
		const index = tableChat.findIndex(item=>item.id === data.id)

		dispatch(deleteChat(data)).then(res=>{
			const {success} = res.payload
			if(success){
				tempTable.splice(index,1)
				setTableChat(tempTable);
				Toast(success,'Xóa câu trả lời thành công!')
			}else{
				Toast(success,'Xóa câu trả lời thất bại!')
			}
			
		})
	}

	const handleAddNewRow = async () => {
		const oldValue = [...tableChat]
		oldValue.push({content:'',isEdit:true,type:'fast'})
		setTableChat(oldValue)
	}
	const editAutoChat = () =>{
		setContentAutoChat({...contentAutoChat,isEdit:true,disabled:false})
	}

	useEffect(()=>{
        const controller = new AbortController();
		const {signal} = controller
		const data = {
			Filters: '',
		}
		dispatch(getChatList({data,signal})).then(response=>{
			const data = response.payload.data?.collection;
			const newTableList=[]
			data.forEach(element => {
				newTableList.push({...element,isEdit:false})
			});
			setTableChat(newTableList);
			setContentAutoChat({...data.filter(res=>res.type==='auto')?.[0],isEdit:false,disabled:true})
			setEnableAutoChat(data.filter(res=>res.type==='auto')?.[0].is_enabled);
			setEnableQuickChat(data.filter(item=>item.type==='fast'&&item.is_enabled!==null)?.[0].is_enabled)
		});
		return () => controller.abort();
	},[])

	const chatSettingContainerProps = {
		...props,
		dispatch,
		navigateTo,
		intl,
		enableAutoChat,
		enableQuickChat,
		contentAutoChat,
		tableChat,
		handleChangeAutoChat,
		handleChangeQuickChat,
		handleSubmitAutoChat,
		editQuickChat,
		updateQuickChat,
        cancelQuickChat,
        deleteQuickChat,
		handleAddNewRow,
		handleCloseAutoChat,
		editAutoChat
	};
	return (
		<ChatSettingMainView {...propsProvider(chatSettingContainerProps)} />
	);
};

export default ChatSettingContainer;