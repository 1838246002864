export default function propsProvider(props) {
    const {
        dispatch,
        navigateTo,
        intl,
        modalContent,
        deleteItemsHandler,
        voucherHandler,
        cartItems,
        checkItemsHandler,
        isAllCheckedHandle,
        isOpenVoucherModal,
        closeVoucherModal,
        goToPdp,
        control,
        handleSubmit,
        isApplyDisabled,
        reset,
        goToPaymentPage,
        voucherList,
        appliedVoucher,
        setAppliedVoucher
    } = props;

    let checkedProduct = 0,
        totalPrice = 0,
        discount = 0,
        isCheckedAll = isAllCheckedHandle(cartItems);

    if (cartItems.size > 0) {
        cartItems.forEach((value, key) => {
            let stallTotalPrice = 0,
                isProductChecked = false,
                productVoucherPrice = 0;

            value.items.forEach((cartProduct) => {
                if (cartProduct.isChecked && cartProduct.quantityInStorage > 0) {
                    isProductChecked = true;
                    const productTotalPrice = cartProduct.price * cartProduct.quantityInCart;
                    checkedProduct += cartProduct.quantityInCart;
                    stallTotalPrice += productTotalPrice;

                    if (appliedVoucher) {
                        if (cartProduct.category_ids.some(id => appliedVoucher.category.includes(id))) {
                            productVoucherPrice += productTotalPrice;
                        }
                    }
                }
            });

            if (isProductChecked) {
                totalPrice += stallTotalPrice;

                if (appliedVoucher) {
                    const { value, value_percentage } = appliedVoucher;

                    discount += Math.min(value, value_percentage / 100 * productVoucherPrice);
                }

            }
        });
    }

    return {
        navigateTo,
        intl,
        cartItems,
        dispatch,
        modalContent,
        deleteItemsHandler,
        checkItemsHandler,
        isCheckedAll,
        emptyCartProps: {
            navigateTo,
            intl
        },
        detailCartProps: {
            cartItems,
            navigateTo,
            intl,
            dispatch,
            deleteItemsHandler,
            checkItemsHandler,
            voucherHandler,
            appliedVoucherValue: discount,
            goToPdp
        },
        cartSummaryProps: {
            intl,
            goToPaymentPage,
            cartSummaryData: {
                quantity: checkedProduct,
                discount,
                totalPrice
            }
        },
        voucherModalProps: {
            intl,
            dispatch,
            voucherList,
            isOpenVoucherModal,
            closeVoucherModal,
            control,
            handleSubmit,
            isApplyDisabled,
            reset,
            cartProductItems: cartItems.get("0"),
            totalPrice,
            setAppliedVoucher,
            appliedVoucher
        }
    };
}
