import React, {useEffect} from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Edit, Trash, mail, emptyBox} from 'assets/images';
import moment from 'moment';
import CustomPagination from 'components/Pagination/Pagination';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';

const NotificationTable = (props) => {
    const {
        intl,
        notificationList,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalPage,
        currentPage,
        pageSizeRange,
        selectedItem,
        setselectedItem,
        isEdit,
        setIsEdit,
        isDublicate,
        setIsDublicate,
        isDelete,
        setIsDelete,
        setDeleteId
    } = props;

    const headCells = [
        {
            code: '#',
            label: 'STT'
        },
        {
            code: 'name',
            label: 'Tên thông báo'
        },
        {
            code: 'date',
            label: 'Thời gian'
        },
        {
            code: 'status',
            label: 'Trạng thái'
        },
        {
            code: 'action',
            label: 'Thao tác'
        }
    ];

    const isNotAdd = props.parentCallback;
    const sendData = () => {
        isNotAdd(false);
    };
    const isDataEmpty = notificationList.collection?.length === 0 ? true : false;

    useEffect(() => {
        console.log(notificationList);
    }, []);

    return (
        <div className='notification-setting__table row'>
            <div className='row'>
                <h4 className='col p-0'>{intl.messages.notificationList}</h4>
                <div className='col p-0'>
                    <CustomButton className='ms-auto' onClick={sendData}>
                        + {intl.messages.addNotification}
                    </CustomButton>
                </div>
            </div>
            <div className='mt-3'>
                <TableContainer style={{borderRadius: 12, border: '1px solid #4568f2', paddingBottom: 16}}>
                    <Table>
                        <TableHead
                            style={{
                                background: '#293e91',
                                color: '#ffffff',
                                height: 42
                            }}>
                            <TableRow>
                                {headCells.map((el) => (
                                    <TableCell
                                        style={{
                                            color: '#ffffff',
                                            padding: el.code === '#' ? 4 : '0 8px 0 8px',
                                            textAlign: el.code === '#' ? 'center' : 'left'
                                        }}>
                                        {el.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isDataEmpty ? (
                                notificationList.collection?.map((el, index) => (
                                    <TableRow key={index} style={{height: 56}}>
                                        <TableCell style={{padding: 0, textAlign: 'center'}}>{index + 1}</TableCell>
                                        <TableCell style={{padding: '0 8px 0 8px'}}>{el.summary || '--'}</TableCell>
                                        <TableCell style={{padding: '0 8px 0 8px'}}>
                                            {el.sent_schedule
                                                ? moment.utc(new Date(el.sent_schedule)).format('DD/MM/YYYY')
                                                : '--'}
                                        </TableCell>
                                        <TableCell style={{padding: '0 8px 0 8px'}}>
                                            <div
                                                style={{
                                                    border: '1px solid',
                                                    borderRadius: 4,
                                                    textAlign: 'center',
                                                    padding: '8px 2px',
                                                    color: el.extend_status?.color,
                                                    backgroundColor: el.extend_status?.bgcolor
                                                }}>
                                                {el.extend_status?.name}
                                            </div>
                                        </TableCell>
                                        <TableCell style={{width: '15%'}}>
                                            <div className='d-flex gap-2'>
                                                {el.extend_status?.code === 'SCHEDULED' && (
                                                    <Edit
                                                        src={Edit}
                                                        alt='edit'
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                            setIsEdit(true);
                                                            setselectedItem(el);
                                                        }}
                                                    />
                                                )}
                                                <img
                                                    src={mail}
                                                    alt='mail'
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setIsDublicate(true);
                                                        setselectedItem(el);
                                                    }}
                                                />
                                                <Trash
                                                    alt='trash'
                                                    className='cursor-pointer'
                                                    onClick={() => setDeleteId(el.id)}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow className='table-row--empty'>
                                    <TableCell colSpan={headCells.length + 1}>
                                        <img src={emptyBox} alt='empty data' />
                                        <span>{intl.messages.noDataFound}</span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {!isDataEmpty && (
                        <div className='table-pagination'>
                            <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={currentPage} />
                            <CustomPageSize
                                label={intl.messages.rowsPerPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageSizeRange={pageSizeRange}
                                id={'temp'}
                            />
                        </div>
                    )}
                </TableContainer>
            </div>
        </div>
    );
};

export default NotificationTable;
