import React from 'react';
import {close} from 'assets/images';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';

const DeleteAddressModal = (props) => {
    const {intl, isOpenDeleteModal, closeDeleteModal, handleDeleteAddress} = props;
    return (
        <>
            <Dialog
                maxWidth={'xs'}
                fullWidth={true}
                open={isOpenDeleteModal}
                onClose={closeDeleteModal}
                aria-labelledby='voucher-modal'
                aria-describedby='apply-voucher-to-get-discount'
                className='dialog'>
                <DialogTitle className='dialog-title' id='voucher-modal'>
                    <span>Xác nhận</span>
                    <button className='dialog-title__close'>
                        <img
                            src={close}
                            alt='close-icon'
                            onClick={(e) => {
                                e.preventDefault();
                                closeDeleteModal();
                            }}
                        />
                    </button>
                </DialogTitle>
                <DialogContent className='dialog-content'>
                    <div>{intl.messages.deleteAddress}</div>
                </DialogContent>
                <DialogActions className='dialog-actions'>
                    <CustomButton
                        variant='text'
                        className='header-account__signup'
                        onClick={(e) => {
                            e.preventDefault();
                            closeDeleteModal();
                        }}>
                        {intl.messages.cancelButton}
                    </CustomButton>
                    <CustomButton variant='contained' color= 'danger' onClick = {(e) =>{
                        e.preventDefault();
                        handleDeleteAddress()
                    }}>
                        {intl.messages.delete}
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteAddressModal;
