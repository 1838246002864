import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Toast} from 'utils/toast';
import propsProvider from './MyLikeProductPropsContainer';
import {deleteCartProducts, deleteMyLikeProducts, getCategoryList, getlikeProductList} from './MyLikeProductSlice';
import MyLikeProductMainView from './template/MyLikeProductMainView';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const MyLikeProductContainer = (props) => {
    const {dispatch, history, userLikeList, auth} = props;
    const intl = useIntl();

    const defaultValues = {
        category: [],
        status: '',
        price: intl.messages.fromLowToHigh
    };

    const [multipleSelect, setMultipleSelect] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedProdId, setSelectedProdId] = useState('');
    const [displayList, setDisplayList] = useState([]);
    const [windowDimensions] = useDeviceSize();
    const favList = userLikeList.likeProductList?.collection;
    const {control, watch, setValue} = useForm({
        defaultValues
    });
    const selectedCategoryList = watch('category');
    const status = watch('status');
    const price = watch('price');

    const navigateTo = (path) => {
        history.push(path);
    };

    const removeCategory = (id) => {
        setValue(
            'category',
            selectedCategoryList.filter((category) => category !== id)
        );
    };

    const handleDeleteAddress = async (id) => {
        const res = await dispatch(deleteCartProducts(id)).unwrap();

        if (res) {
            dispatch(getlikeProductList());
            Toast(true, 'Xóa sản phẩm thành công');
            dispatch(closeDeleteModal());
        } else {
            Toast(false, 'Xóa sản phẩm thất bại');
        }
    };

    const handleLikeProduct = async (productId) => {
        const userId = auth.userLoginInfo?.id;
        const data = {
            productId: productId,
            userId: userId,
            type: 'wishlist'
        };
        const res = await dispatch(deleteMyLikeProducts(data)).unwrap();
        if (res) {
            dispatch(getlikeProductList());
            Toast(true, 'Xóa sản phẩm yêu thích thành công');
            closeDeleteModal();
        } else {
            Toast(false, 'Xóa sản phẩm yêu thích thất bại');
        }
    };

    const handleChange = useCallback(() => {
        let newList = [...favList];

        // sort by status
        if (status === intl.messages.newUpdate) {
            newList = newList.filter((item) => item.extend_product.modified_at !== null);
            newList = newList.sort(
                (item1, item2) =>
                    new Date(item1.extend_product.modified_at) > new Date(item2.extend_product.modified_at)
            );
        } else if (status === intl.messages.newProduct) {
            newList = newList.sort(
                (item1, item2) =>
                    new Date(item2.extend_product.created_at).getTime() -
                    new Date(item1.extend_product.created_at).getTime()
            );
        } else {
            newList = newList.sort(
                (item1, item2) =>
                    new Date(item1.extend_product.created_at).getTime() -
                    new Date(item2.extend_product.created_at).getTime()
            );
        }

        // sort by type

        if (price === intl.messages.fromLowToHigh) {
            newList = newList.sort((item1, item2) => item1.extend_product.price - item2.extend_product.price);
        } else {
            newList = newList.sort((item1, item2) => item2.extend_product.price - item1.extend_product.price);
        }
        setDisplayList(newList);
    }, [status, price, favList, intl]);

    const onOpenDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setSelectedProdId(id);
    };
    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    useLayoutEffect(() => {
        if (favList) {
            handleChange();
        }
    }, [favList, handleChange]);

    useEffect(() => {
        dispatch(getCategoryList());
    }, []);

    useEffect(() => {
        const params = {};
        const controller = new AbortController();
        const {signal} = controller;
        if (selectedCategoryList.length > 0) {
            Object.assign(params, {
                categoryIds: selectedCategoryList.join('|')
            });
        }

        dispatch(getlikeProductList({params, signal}));

        return () => controller.abort();
    }, [selectedCategoryList]);

    const MyLikeProductContainerProps = {
        dispatch,
        navigateTo,
        intl,
        userLikeList,
        multipleSelect,
        setMultipleSelect,
        onOpenDeleteModal,
        closeDeleteModal,
        selectedProdId,
        isOpenDeleteModal,
        displayList,
        handleDeleteAddress,
        removeCategory,
        selectedCategoryList,
        control,
        windowDimensions,
        handleLikeProduct
    };
    return <MyLikeProductMainView {...propsProvider(MyLikeProductContainerProps)} />;
};

export default MyLikeProductContainer;
