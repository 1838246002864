import React, { useEffect } from 'react';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './thankYou.i18n';
import ThankYouContainer from './ThankYouContainer';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function ThankYou(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Thank you page');
    }, [])

    const thankYouProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };
    return <DecorateContainer {...thankYouProps} childComponent={ThankYouContainer} />;
}
