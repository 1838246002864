import { addressListSelector } from 'app/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddressListContainer from './AddressListContainer';

const AddressList = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const addressList = useSelector(addressListSelector);

	const addressListProps = {
		...props,
		dispatch,
		history,
		addressList,
	};

	return (
		<AddressListContainer {...addressListProps} />
	);
};

export default AddressList;
