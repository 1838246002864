import { authSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AuthContainer from './AuthContainer';
import i18nLabels from './auth.i18n';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function Auth(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(authSelector);

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Login page');
    }, [])

    const authProps = {
        ...props,
        dispatch,
        auth,
        history,
        i18nLabels
    };
    return <DecorateContainer {...authProps} childComponent={AuthContainer} />;
}
