import React, {useState} from 'react';
import {styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Edit, Trash} from 'assets/images';
import CustomPagination from 'components/Pagination/Pagination';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import ModalPopup from 'components/ModalPopup/ModalPopup';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
    ({theme}) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#4568f2',
                    opacity: 1,
                    border: 0
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
            }
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500
            })
        }
    })
);

const ShippingUnitsTable = (props) => {
    const {
        intl,
        totalPage,
        page,
        pageSize,
        setPageSize,
        setPage,
        shippingUnitList,
        handleOpenModal,
        handleOpenDeleteModal,
        handleToggleUnitStatus
    } = props;

    const headCells = [
        {
            code: '#',
            label: intl.messages.orderNumber
        },
        {
            code: 'name',
            label: intl.messages.shippingUnit
        },
        {
            code: 'transportForm',
            label: intl.messages.transportForm
        },
        {
            code: 'status',
            label: intl.messages.activeLabel
        },
        {
            code: 'action',
            label: ''
        }
    ];

    return (
        <div className='account'>
            <TableContainer className='shipping-table-container'>
                <Table>
                    <TableHead className='shipping-table-container-head'>
                        <TableRow>
                            {headCells.map((el, idx) => (
                                <TableCell
                                    key={idx}
                                    className='cell'
                                    style={{
                                        padding: el.code === '#' ? 4 : '0 8px 0 8px',
                                        textAlign: el.code === '#' || el.code === 'status' ? 'center' : 'left'
                                    }}>
                                    {el.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className='shipping-table-container-body'>
                        {shippingUnitList &&
                            shippingUnitList.map((el, index) => {
                                const {name, shipping_options, is_active} = el;
                                const parsedOptions = JSON.parse(shipping_options);

                                return (
                                    <TableRow key={index} className='table-row'>
                                        <TableCell style={{padding: 0, textAlign: 'center'}} className='cell'>
                                            {(page - 1) * pageSize + index + 1}
                                        </TableCell>
                                        <TableCell className='cell'>
                                            <div className='ellipsis'>{name || '--'}</div>
                                        </TableCell>
                                        <TableCell className='cell'>
                                            <div className='ellipsis'>
                                                {(parsedOptions && parsedOptions.join(', ')) || '--'}
                                            </div>
                                        </TableCell>
                                        <TableCell className='cell'>
                                            <div className='status'>
                                                <IOSSwitch checked={is_active} onChange={(e) => handleToggleUnitStatus(el?.id, e.target.checked)} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='d-flex justify-content-center gap-3'>
                                                <Edit
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        handleOpenModal(el);
                                                    }}
                                                />
                                                <Trash
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        handleOpenDeleteModal(el);
                                                    }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <div className='d-flex justify-content-between align-items-center pagination'>
                    <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={page} />
                    <CustomPageSize
                        label={intl.messages.rowOnPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageSizeRange={[5, 10, 15]}
                        id={'temp'}
                    />
                </div>
            </TableContainer>
        </div>
    );
};

export default ShippingUnitsTable;
