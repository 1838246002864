import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const productReviewApi = {
    getReviewsByProductId: (productId, data) => {
        const url = `/api/productReview/productReview/${productId}/product`;
        return axiosClient.get(url, {params: data});
    },
    postProductReview: (data) => {
        const url = '/api/productReview/productReview';
        return axiosClient.post(url, data);
    },
    postProductReviewAttachment: (data) => {
        const url = '/api/productReviewAttachment/productReviewAttachment';
        return axiosClient.post(url, data);
    },
    uploadProductReviewAttachment: (data) => {
        const {id, file} = data;
        const url = `/api/productReviewAttachment/productReviewAttachment/${id}/uploadImage`;
        return axiosFormData.put(url, file);
    },
    getProductReviewByIdAndOrderProductId: (params) => {
        const {productId, orderProductId} = params;
        const url = `/api/productReview/productReview/${productId}/product/orderProduct/${orderProductId}`;
        return axiosClient.get(url, {params});
    }
};

export default productReviewApi;