import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import notificationApi from 'api/notificationApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        notificationList: [],
        basicInformation: {},
        detailInformation: {},
        paginationData: Array(5).fill({page: 1, pageSize: 10})
    };
};

export const getNotifications = createAsyncThunk('notification/getNotificationsFastData', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.getNotificationsFastData,
        payload: data
    });
});

export const addNotification = createAsyncThunk('notification/addNotification', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.addNotification,
        payload: data
    });
});

export const addNotificationWithStatus = createAsyncThunk(
    'notification/addNotificationWithStatus',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: notificationApi.addNotificationWithStatus,
            payload: data,
            options: {
                skipLoader: false
            }
        });
    }
);

export const updateNotificationWithStatus = createAsyncThunk(
    'notification/updateNotificationWithStatus',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: notificationApi.updateNotificationWithStatus,
            payload: data,
            options: {
                skipLoader: false
            }
        });
    }
);

export const sendPushNotification = createAsyncThunk(
    'notification/sendPushNotification',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: notificationApi.sendPushNotification,
            payload: data,
            options: {
                skipLoader: false
            }
        });
    }
);

export const uploadNotificationImage = createAsyncThunk('adminAddProduct/productImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.uploadNotificationImage,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const deleteNotifications = createAsyncThunk(
    'notification/deleteNotificationsFastData',
    async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: notificationApi.deleteNotification,
            payload: data
        });
    }
);

const notificationSetting = createSlice({
    name: 'notificationSetting',
    initialState,
    reducers: {
        storeBasicInformation: (state, action) => {
            state.basicInformation = action.payload;
        },
        storeDetailInformation: (state, action) => {
            state.detailInformation = action.payload;
        },
        handleChangePage: (state, action) => {
            const {activePage, page} = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const {activePage, pageSize} = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getNotifications.fulfilled, (state, action) => {
            state.notificationList = action.payload.data;
        });
    }
});

const {reducer, actions} = notificationSetting;
export const {storeBasicInformation, storeDetailInformation} = actions;
export default reducer;
