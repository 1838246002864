import React from 'react';
import {Controller} from 'react-hook-form';
import {MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText, Box, Chip} from '@mui/material';

import ConditionCategoryList from './ConditionCategoryList';
import CategorySelect from 'components/CategorySelect/CategorySelect';

const ConditionForm = (props) => {
    const {
        intl,
        control,
        categoryList,
        paymentMethodList,
        shippingUnitList,
        categoryColumns,
        watch,
        setValue,
        removeCategory,
        productList,
        selectedProducts,
        selectedCategoryList,
        dispatch,
        unsetProduct,
        setUnsetProduct
    } = props;
    const shippingUnitField = watch('shippingUnit');
    const paymentMethodField = watch('paymentMethod');
    const labelId = `category`;

    const conditionCategoryListProps = {
        intl,
        categoryList,
        categoryColumns,
        setValue,
        productList,
        selectedProducts,
        selectedCategoryList,
        dispatch,
        unsetProduct,
        setUnsetProduct
    };

    const categorySelectProps = {
        removeCategory,
        isRequired: true,
        multiple: true,
        categories: categoryList,
        placeholder: intl.messages.chooseCategory,
        label: intl.messages.category
    };

    return (
        <div className='condition-form'>
            <div className='condition-form__wrapper'>
                <div className='row mb-4'>
                    <Controller
                        control={control}
                        name='category'
                        render={({field}) => (
                            <FormControl fullWidth>
                                <CategorySelect {...field} {...categorySelectProps} />
                            </FormControl>
                        )}
                    />
                </div>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id={labelId}>
                                {intl.messages.applyShippingUnit} <span className='text-required'>*</span>
                            </InputLabel>
                            <Controller
                                name={'shippingUnit'}
                                control={control}
                                render={({field}) => (
                                    <Select
                                        labelId={labelId}
                                        label={
                                            <span>
                                                {intl.messages.applyShippingUnit}{' '}
                                                <span className='text-required'>*</span>
                                            </span>
                                        }
                                        multiple
                                        value={shippingUnitField}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={
                                                            shippingUnitList.filter((item) => item.id === value)[0].name
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(e) => {
                                            setValue('shippingUnit', e.target.value);
                                        }}
                                        {...field}>
                                        {shippingUnitList.map((unit) => (
                                            <MenuItem key={unit.id} value={unit.id}>
                                                <ListItemText primary={unit.name} />
                                                <Checkbox checked={shippingUnitField.indexOf(unit.id) > -1} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id={labelId}>
                                {intl.messages.applyPaymentMethod} <span className='text-required'>*</span>
                            </InputLabel>
                            <Controller
                                name={'paymentMethod'}
                                control={control}
                                render={({field}) => (
                                    <Select
                                        labelId={labelId}
                                        multiple
                                        label={
                                            <span>
                                                {intl.messages.applyPaymentMethod}{' '}
                                                <span className='text-required'>*</span>{' '}
                                            </span>
                                        }
                                        value={paymentMethodField}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={
                                                            paymentMethodList.filter((item) => item.id === value)[0]
                                                                .name
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(e) => {
                                            setValue('paymentMethod', e.target.value);
                                        }}
                                        {...field}>
                                        {paymentMethodList.map((method) => (
                                            <MenuItem key={method.id} value={method.id}>
                                                <ListItemText primary={method.name} />
                                                <Checkbox checked={paymentMethodField.indexOf(method.id) > -1} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </div>
                </div>
            </div>

            <ConditionCategoryList {...conditionCategoryListProps} />
        </div>
    );
};

export default ConditionForm;
