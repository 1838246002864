export default function propsProvider(props) {
    const {
        dispatch,
        navigateTo,
        intl,
        userLikeList,
        multipleSelect,
        setMultipleSelect,
        onOpenDeleteModal,
        closeDeleteModal,
        selectedProdId,
        isOpenDeleteModal,
        displayList,
        handleDeleteAddress,
        removeCategory,
        selectedCategoryList,
        control,
        windowDimensions,
        handleLikeProduct,
    } = props;

    return {
        intl,
        dispatch,
        userLikeList,
        navigateTo,
        multipleSelect,
        setMultipleSelect,
        onOpenDeleteModal,
        closeDeleteModal,
        selectedProdId,
        isOpenDeleteModal,
        displayList,
        handleDeleteAddress,
        removeCategory,
        selectedCategoryList,
        control,
        windowDimensions,
        handleLikeProduct
    };
}
