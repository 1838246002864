import { addressListSelector } from 'app/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MyPurchaseContainer from './MyPurchaseContainer';

const MyPurchase = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const addressList = useSelector(addressListSelector);

	const myPurchaseProps = {
		...props,
		dispatch,
		history,
		addressList,
	};

	return (
		<MyPurchaseContainer {...myPurchaseProps} />
	);
};

export default MyPurchase;
