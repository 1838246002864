import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Divider from '@mui/material/Divider';
import {Checkbox, FormControlLabel, IconButton} from '@mui/material';

const CategoryActionRow = (props) => {
    const {rows, removeRow, updateRow, intl} = props;

    return (
        <div className='category-detail__list'>
            {rows.map((row, index) => (
                <div className='category-row' key={index}>
                    <div className='custom-textfield'>
                        <span className='custom-textfield__label'>{intl.messages.attributeName}</span>
                        <input
                            className='input-text'
                            type='text'
                            placeholder='Thuộc tính'
                            value={row.name}
                            onChange={(e) => {
                                updateRow(e.target.value, 'name', index);
                            }}
                            disabled={row.disabled}
                        />

                        <div className='custom-textfield__option'>
                            <Divider orientation='vertical' flexItem className='divider' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row.required}
                                        onClick={(e) => updateRow(e.target.checked, 'required', index)}
                                        disabled={row.disabled}
                                    />
                                }
                                label={intl.messages.required}
                                className='required'
                            />
                        </div>
                    </div>
                    <IconButton
                        disableRipple
                        disabled={row.disabled}
                        aria-label='delete'
                        className='delete-icon'
                        onClick={() => removeRow(index)}
                        sx={{cursor: 'pointer', padding: 0}}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </div>
            ))}
        </div>
    );
};

export default CategoryActionRow;
