export default function propsProvider(props) {
    const {intl, auth, dispatch, voucher, onOpenVoucherModal, isOpenVoucherFormModal, selectedVoucherObj,closeVoucherModal,handleSearch,displayList} = props;
    
    return {
        intl,
        dispatch,
        voucher,
        onOpenVoucherModal,
        isOpenVoucherFormModal,
        selectedVoucherObj,
        closeVoucherModal,
        handleSearch,
        displayList
    };
}
