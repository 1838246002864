import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {calcTotalPage} from 'utils/calcTotalPage';
import {Toast} from 'utils/toast';
import propsProvider from './deliverySettingPropsProvider';
import {addShippingUnit, deleteShippingUnit, editShippingUnit, getShippingUnits} from './DeliverySettingSlice';
import DeliverySettingMainView from './template/DeliverySettingMainView.jsx';

const DeliverySettingContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();
    const [isOpenModal, setOpenModal] = useState(false);
    const [isAddNewModal, setAddNewModal] = useState(true);
    const [shippingUnitList, setShippingUnitList] = useState([]);
    const [selectedUnitObj, setSelectedUnitObj] = useState({});
    const [totalPage, setTotalPage] = useState(0);
    const [unitParams, setUnitParams] = useState({
        Filters: '',
        Page: 1,
        PageSize: 5
    });
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const setPage = (currentPage) => {
        setUnitParams((prev) => ({
            ...prev,
            Page: currentPage
        }));
    };

    const setPageSize = (pageSize) => {
        setUnitParams((prev) => ({
            ...prev,
            PageSize: pageSize,
            Page: 1
        }));
    };

    const handleOpenModal = (obj) => {
        if (obj) {
            setAddNewModal(false);
            setSelectedUnitObj(obj);
        } else {
            setAddNewModal(true);
        }
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenDeleteModal = (obj) => {
        setSelectedUnitObj(obj);
        setOpenDeleteModal(true);
    }
            
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const handleSubmitFormModal = async (data) => {
        const payload = {
            name: data.unit_name,
            shipping_options: JSON.stringify(data.transportForms),
            description: data.description
        };
        const res = await dispatch(
            isAddNewModal ? addShippingUnit(payload) : editShippingUnit({...payload, id: selectedUnitObj?.id})
        ).unwrap();
        const {success, message} = res;
        if (success) {
            Toast(success, message);
            fetchShippingUnitList();
        } else {
            Toast(success, message);
        }
        handleCloseModal();
    };

    const handleDeleteShippingUnit = async() => {
        if(selectedUnitObj) {
            const res = await dispatch(deleteShippingUnit(selectedUnitObj?.id)).unwrap();
            const {success, message} = res;
            if (success) {
                Toast(success, message);
                fetchShippingUnitList();
            } else {
                Toast(success, message);
            }
            handleCloseDeleteModal();
        }
    }

    const handleToggleUnitStatus = async(id, isActive) => {
        const payload = {
            id,
            is_active: isActive
        }
        const res = await dispatch(editShippingUnit(payload)).unwrap();
        const {success, message} = res;
        if(success) {
            const newList = shippingUnitList.map(el => {
                if(el.id === id) {
                    return {
                        ...el,
                        is_active: !el.is_active
                    }
                }
                return el
            })
            setShippingUnitList(newList);
            Toast(success, message);
        } else {
            Toast(success, message);
        }
    }

    const fetchShippingUnitList = async () => {
        const res = await dispatch(getShippingUnits(unitParams)).unwrap();
        const {success} = res;
        if (success) {
            const {data} = res;
            setShippingUnitList(data?.collection);
            setTotalPage(calcTotalPage(data?.total, data?.pagesize));
        }
    };

    useEffect(() => {
        fetchShippingUnitList();
    }, [unitParams]);

    const deliverySettingContainerProps = {
        intl,
        dispatch,
        isOpenModal,
        isAddNewModal,
        handleOpenModal,
        handleCloseModal,
        handleSubmitFormModal,
        shippingUnitList,
        unitParams,
        totalPage,
        setPage,
        setPageSize,
        selectedUnitObj,
        isOpenDeleteModal,
        handleDeleteShippingUnit,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        handleToggleUnitStatus
    };

    return <DeliverySettingMainView {...propsProvider(deliverySettingContainerProps)} />;
};

export default DeliverySettingContainer;
