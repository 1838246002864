export default function propsProvider(props) {
    const {
        intl,
        isOpenModal,
        isAddNewModal,
        handleOpenModal,
        handleCloseModal,
        handleSubmitFormModal,
        shippingUnitList,
        unitParams,
        totalPage,
        setPage,
        setPageSize,
        selectedUnitObj,
        isOpenDeleteModal,
        handleDeleteShippingUnit,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        handleToggleUnitStatus
    } = props;

    return {
        intl,
        handleOpenModal,
        shippingUnitsTableProps: {
            intl,
            shippingUnitList,
            page: unitParams.Page,
            pageSize: unitParams.PageSize,
            totalPage,
            setPage,
            setPageSize,
            handleOpenModal,
            handleOpenDeleteModal,
            handleToggleUnitStatus
        },
        shippingFormModalProps: {
            intl,
            isOpenModal,
            isAddNewModal,
            handleCloseModal,
            handleSubmitFormModal,
            selectedUnitObj
        },
        deleteUnitModalProps: {
            intl,
            isOpenDeleteModal,
            handleDeleteShippingUnit,
            handleCloseDeleteModal
        }
    };
}
