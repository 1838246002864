import axiosClient from './axiosClient';

const permissionApi = {
	getPermissions: () => {
		const url = '/api/permissions';

		return axiosClient.get(url);
	}
};

export default permissionApi;