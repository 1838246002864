import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import CustomButton from 'components/CustomButton/CustomButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import {Edit} from 'assets/images';
import UpdateEmailPhoneModal from './subViews/UpdateEmailPhoneModal';
import Config from 'configuration';
import moment from 'moment';

const AccountSettingMainView = (props) => {
    const {
        intl,
        auth,
        handleSubmitUpdateUserInfo,
        handleUploadAvatar,
        onOpenEmailModal,
        onOpenPhoneModal,
        updateModalProps
    } = props;
    const {userLoginInfo} = auth;
    const [avatar, setAvatar] = useState(null);
    const [accountSettingDefaultValue, setAccountSettingDefaultValue] = useState({
        full_name: '',
        birthday: '',
        gender: ''
    });
    const {control, handleSubmit, reset} = useForm({defaultValues: accountSettingDefaultValue});

    useEffect(() => {
        if (userLoginInfo) {
            const newData = {...accountSettingDefaultValue};
            newData.full_name = userLoginInfo.full_name || '';
            newData.birthday = userLoginInfo.birthday || moment().format('01/01/1990');
            newData.gender = userLoginInfo.gender || '';
            setAccountSettingDefaultValue(newData);
            reset(newData);
        }
    }, [reset]);

    return (
        <div className='account-setting'>
            <UpdateEmailPhoneModal {...updateModalProps} />

            <div className='account-setting-container'>
                <span className='title'>{intl.messages.accountSettingTitle}</span>
                <div className='row information-container flex-column flex-lg-row'>
                    <div className='col-12 col-lg-3'>
                        <div className='d-flex justify-content-center'>
                            <img
                                src={
                                    (avatar && URL.createObjectURL(avatar)) ||
                                    `${Config.apiConfig.endPoint}/${userLoginInfo?.avatar}`
                                }
                                alt={'img-avatar'}
                                className='information-avatar'
                            />
                        </div>
                        <div id='avatar'>
                            <label id='avatar-upload-label' htmlFor='profile_pic'>
                                {intl.messages.accountSettingUploadImageLable}
                            </label>
                            <input
                                style={{display: 'none'}}
                                type='file'
                                id='profile_pic'
                                name='profile_pic'
                                accept='.jpg, .jpeg, .png'
                                onChange={(e) => {
                                    setAvatar(e.target.files[0]);
                                    console.log(e.target.files[0]);
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-12 col-lg-9'>
                        <form action='#' noValidate onSubmit={handleSubmit(handleSubmitUpdateUserInfo)}>
                            <div className='row'>
                                <div className='col'>
                                    <Controller
                                        name='full_name'
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                id='outlined-basic'
                                                label={intl.messages.accountSettingUsername}
                                                placeholder={intl.messages.accountSettingUsernamePlaceholder}
                                                variant='outlined'
                                                fullWidth
                                                {...field}
                                                className='mb-24'
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <Controller
                                        name='birthday'
                                        control={control}
                                        render={({field: {value, onChange}}) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    inputFormat='DD/MM/YYYY'
                                                    label={intl.messages.accountSettingDOB}
                                                    value={value}
                                                    onChange={onChange}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </div>
                                <div className='col-6'>
                                    <Controller
                                        name='gender'
                                        control={control}
                                        render={({field: {value, onChange}}) => (
                                            <FormControl fullWidth>
                                                <InputLabel id='demo-simple-select-label'>
                                                    {intl.messages.accountSettingGender}
                                                </InputLabel>
                                                <Select
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={value}
                                                    label={intl.messages.accountSettingGender}
                                                    onChange={onChange}>
                                                    <MenuItem value={true}>
                                                        {intl.messages.accountSettingGenderMale}
                                                    </MenuItem>
                                                    <MenuItem value={false}>
                                                        {intl.messages.accountSettingGenderFemale}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end mt-24'>
                                <CustomButton
                                    variant='contained'
                                    type='submit'
                                    onClick={() => handleUploadAvatar(avatar)}>
                                    {intl.messages.accountSettingSaveButton}
                                </CustomButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='email-phone'>
                <span className='title'>{intl.messages.accountSettingPhoneAndEmail}</span>

                <div className='row form'>
                    <div className='col-12 col-lg-6'>
                        <CustomInput
                            id='outlined-basic'
                            label={intl.messages.accountSettingEmail2}
                            variant='outlined'
                            fullWidth
                            value={userLoginInfo?.email}
                            className='mb-24'
                            disabled={true}
                            endicon={<Edit className='cursor-pointer' onClick={() => onOpenEmailModal()} />}
                            valid={false}
                        />
                    </div>
                    <div className='col-12 col-lg-6'>
                        <CustomInput
                            id='outlined-basic'
                            label={intl.messages.accountSettingPhone}
                            variant='outlined'
                            fullWidth
                            value={userLoginInfo?.phone}
                            className='mb-24'
                            disabled={true}
                            endicon={<Edit className='cursor-pointer' onClick={() => onOpenPhoneModal()} />}
                            valid={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSettingMainView;
