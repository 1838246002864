import { adminAddProductSelector } from 'app/selectors';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getBase64 } from 'utils/getBase64';
import { reFormatNumber } from 'utils/reFormatNumber';
import { Toast } from 'utils/toast';
import propsProvider from './AddProductPropsProvider';
import {
    addNewProducts,
    deleteProductImage,
    getBrands,
    getCategories,
    getProductDetails,
    updateAdminProduct,
    uploadProductImage,
    uploadProductTypeImage
} from './AddProductSlice';
import AddProductMainView from './template/AddProductMainView';

const defaultValues = {
    images: [],
    name: '',
    product_category_id: '',
    description: EditorState.createEmpty(),
    brand_id: '',
    product_code: '',
    origin: '',
    unitPrice: '',
    promotionalPrice: '',
    quantity: '',
    weight: '',
    height: '',
    length: '',
    width: '',
    express: false,
    fast: false,
    condition: 'new',
    sku: '',
    require_preorder: 'false',
    is_published: false
};
const requiredFields = {
    images: 0,
    name: 0,
    product_category_id: 0,
    description: 0,
    brand_id: 1,
    unitPrice: 2,
    promotionalPrice: 2,
    quantity: 2,
    weight: 3
};

const maxGroupTypeValueLength = 14,
    maxTypeValueLength = 20;

const AddProductContainer = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const navigateTo = (path) => {
        history.push(path);
    };
    const intl = useIntl();
    const adminAddProduct = useSelector(adminAddProductSelector);
    const { productId } = useParams();
    const { categories, brands, productDetails } = adminAddProduct;

    const [attributes, setAttributes] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [formActivated, setFormActivated] = useState(false);
    const [preOrderDate, setPreOrderDate] = useState(7);
    const defaultTypeInput = {
        label: intl.messages.productType,
        maxLength: maxTypeValueLength,
        name: 'productType',
        placeholder: intl.messages.productTypePlaceholder,
        value: ''
    };
    const defaultGroupTypeInput = [
        {
            label: intl.messages.groupType,
            maxLength: maxGroupTypeValueLength,
            name: 'groupType',
            placeholder: intl.messages.groupTypePlaceholder,
            value: ''
        },
        { ...defaultTypeInput }
    ];
    const [groupTypeList, setGroupTypeList] = useState([defaultGroupTypeInput]);
    const [salesInformationType, setSalesInformationType] = useState('basic');
    const [deliveryPrice, setDeliveryPrice] = useState([
        {
            value: intl.messages.express,
            label: intl.messages.express,
            tooltipLabel: intl.messages.tooltipPrice,
            items: [intl.messages.beDelivery, intl.messages.shopee, intl.messages.grabExpress],
            name: 'express'
        },
        {
            value: intl.messages.fast,
            label: intl.messages.fast,
            tooltipLabel: intl.messages.tooltipPrice,
            name: 'fast'
        }
    ]);
    const [productImages, setProductImages] = useState([]);
    const [productTypeImages, setProductTypeImages] = useState([]);
    const [deletedSavedImages, setDeletedSavedImages] = useState([]);
    const [sectionState, setSectionState] = useState([
        { ref: useRef(null), isExpanded: true, label: intl.messages.progressBasicInformation, status: 'active' },
        { ref: useRef(null), isExpanded: false, label: intl.messages.progressDetailInformation, status: 'inactive' },
        { ref: useRef(null), isExpanded: false, label: intl.messages.progressOrderInformation, status: 'inactive' },
        { ref: useRef(null), isExpanded: false, label: intl.messages.progressDeliveryInformation, status: 'inactive' },
        { ref: useRef(null), isExpanded: false, label: intl.messages.progressOtherInformation, status: 'inactive' }
    ]);
    const formRef = useRef(null);

    const {
        formState: { errors, isSubmitted },
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        clearErrors
    } = useForm({ defaultValues });
    const watchAllFields = watch();

    const handleExpandSection = (index = -1, closeIndex = -1, openIndex = -1, isChangeStatus = true) => {
        const newSectionState = [...sectionState];

        if (index >= 0 && index < 5) {
            newSectionState[index].isExpanded = !newSectionState[index].isExpanded;

            if (!(isSubmitted || index === 0)) {
                newSectionState[index].status = newSectionState[index].isExpanded ? 'active' : 'inactive';
            }
        }

        if (closeIndex >= 0 && closeIndex < 5) {
            newSectionState[closeIndex].isExpanded = false;

            if (!(isSubmitted || closeIndex === 0)) {
                newSectionState[closeIndex].status = 'inactive';
            }
        }

        if (openIndex >= 0 && openIndex < 5) {
            newSectionState[openIndex].isExpanded = true;

            if (!isSubmitted && isChangeStatus) {
                newSectionState[openIndex].status = 'active';
            }
        }

        setSectionState(newSectionState);
    };

    const handleUploadProductImages = async (e) => {
        const files = e.target.files;
        const previewImageUrls = [];
        let isOverSize = false;

        for (const file of files) {
            const { size } = file;

            if (size > 5 * 1024 * 1024) {
                isOverSize = true;
                return;
            }
            const result = await getBase64(file);
            previewImageUrls.push({ url: result, file });
        }

        const newPreviewImageUrls = [...productImages, ...previewImageUrls];

        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else if (newPreviewImageUrls.length > 9) {
            Toast(false, intl.messages.overLimit);
        } else {
            setProductImages(newPreviewImageUrls);
        }
    };

    const handleUploadProductTypeImage = async (e, index) => {
        const file = e.target.files[0];
        const { size } = file;

        if (size > 5 * 1024 * 1024) {
            Toast(false, intl.messages.overSize);
        } else {
            const newProductTypeImages = [...productTypeImages];
            const url = await getBase64(file);
            newProductTypeImages[index] = { url, file };
            setProductTypeImages(newProductTypeImages);
        }
    };

    const handleDeleteProductImage = (id) => {
        const newDetailImages = [];
        productImages.forEach((data, idx) => {
            if (id !== idx) {
                newDetailImages.push(data);
            } else {
                if (data.id) {
                    setDeletedSavedImages((prevDeletedSavedImages) => [...prevDeletedSavedImages, data.id]);
                }
            }
        });

        setProductImages(newDetailImages);
        setValue(
            'images',
            newDetailImages.filter((image) => image.file).map((image) => image.file)
        );
    };

    const handleDeleteProductTypeImage = (index) => {
        const newProductTypeImages = [...productTypeImages];
        newProductTypeImages[index] = {};

        setProductTypeImages(newProductTypeImages);
    };

    const handleCancel = () => {
        navigateTo(pagePath.adminProductManagementUrl);
    };

    const handleSubmitBasicInformation = () => {
        setFormActivated(true);
        handleExpandSection(1, 0);

        const newSectionState = [...sectionState];
        newSectionState[0].status = 'done';
        setSectionState(newSectionState);
    };

    const handleSave = async (data) => {
        const {
            promotionalPrice,
            unitPrice,
            quantity,
            weight,
            express,
            fast,
            require_preorder,
            height,
            length,
            width,
            images,
            name,
            product_category_id,
            description,
            brand_id,
            origin,
            sku,
            condition,
            product_code,
            is_published,
            ...productTypeValue
        } = data;

        const group_name1 = applyAllHeadCells[0].label;
        const group_name2 = applyAllHeadCells[1].label;
        const product_type_value = [];
        const defaultTypeValue = {};
        const productTypeData = {};
        const featureList = [];

        const featuresKeys = Object.keys(productTypeValue);
        featuresKeys.forEach((key) => {
            if (key.includes('features')) {
                const [_, name] = key.split('&=');

                if (name) featureList.push({ name, value: productTypeValue[key] });
            }
        });


        mainDataState.forEach((dataState, i) => {
            const { items, title } = dataState;

            const origin_price_value = productTypeValue[items[1][0].name];
            const price_value = productTypeValue[items[2][0].name];
            const quantity = Number(productTypeValue[items[3][0].name]);

            const origin_price = reFormatNumber(origin_price_value);
            const price = price_value ? reFormatNumber(price_value) : origin_price;

            Object.assign(defaultTypeValue, {
                unitPrice: origin_price,
                promotionalPrice: price,
                quantity
            });

            if (items[0].length > 0) {
                items[0].forEach((itemData, idx) => {
                    const origin_price_value = productTypeValue[items[1][idx].name];
                    const price_value = productTypeValue[items[2][idx].name];
                    const quantity = Number(productTypeValue[items[3][idx].name]);

                    const origin_price = reFormatNumber(origin_price_value);
                    const price = price_value ? reFormatNumber(price_value) : origin_price;

                    const tempProductTypeValue = {
                        origin_price,
                        price,
                        quantity,
                        sku: productTypeValue[items[4][idx].name],
                        typeGroup: [
                            {
                                name: title,
                                group_name: group_name1
                            },
                            {
                                name: itemData,
                                group_name: group_name2
                            }
                        ]
                    };

                    product_type_value.push(tempProductTypeValue);
                });
            } else {
                const { unitPrice, promotionalPrice, quantity } = defaultTypeValue;

                const tempProductTypeValue = {
                    origin_price: unitPrice,
                    price: promotionalPrice,
                    quantity,
                    sku: productTypeValue[items[4][0].name],
                    typeGroup: [
                        {
                            name: title,
                            group_name: group_name1
                        }
                    ]
                };

                product_type_value.push(tempProductTypeValue);
            }

            productTypeData[group_name1 + title] = { imageFile: productTypeImages[i]?.file };
        });

        const defaultUnitPrice = product_type_value.length > 0 ? defaultTypeValue.unitPrice : unitPrice;
        const defaultPromotionalPrice =
            product_type_value.length > 0 ? defaultTypeValue.promotionalPrice : promotionalPrice;
        const defaultQuantity = product_type_value.length > 0 ? defaultTypeValue.quantity : quantity;

        const origin_price = typeof defaultUnitPrice === 'number' ? defaultUnitPrice : reFormatNumber(defaultUnitPrice);
        const price =
            typeof defaultPromotionalPrice === 'number'
                ? defaultPromotionalPrice
                : defaultPromotionalPrice
                    ? reFormatNumber(defaultPromotionalPrice)
                    : origin_price;

        const product = {
            origin_price,
            price,
            quantity: Number(defaultQuantity),
            is_published,
            is_hidden: false,
            require_preorder: require_preorder === 'false' ? false : true,
            name,
            product_category_id,
            description: draftToHtml(convertToRaw(description.getCurrentContent())),
            brand_id,
            features: featureList.length > 0 ? JSON.stringify({ categoryId: product_category_id, featureList }) : null,
            origin,
            sku,
            condition,
            product_code
        };

        const product_type = [];
        groupTypeList.forEach((groupType, index) => {
            const tempGroupType = groupType.slice(1, groupType.length - 1);
            const group_name = groupType[0].value;

            tempGroupType.forEach((type) => {
                if (type.value.length > 0) {
                    const tempProductType = {
                        name: type.value,
                        group_name,
                        sort_order: index
                    };

                    product_type.push(tempProductType);
                }
            });
        });

        const product_ship = {
            product_weight: Number(weight),
            shipping_options: `${express ? 'Hoả tốc,' : ''}${fast ? 'Nhanh,' : ''}`
                .trim()
                .split(',')
                .filter((value) => value !== '')
        };

        const formData = {
            product,
            product_type_value,
            product_type,
            product_ship
        };

        const formValue = new FormData();
        formValue.append('formData', JSON.stringify(formData));

        const productResponse = await dispatch(
            isEdit ? updateAdminProduct({ id: productId, formData: formValue }) : addNewProducts(formValue)
        ).unwrap();
        const { success, data: mainData, message } = productResponse;
        if (success) {
            const { id } = isEdit ? productDetails : mainData;

            for (const idx in productImages) {
                const fileFormData = new FormData();
                const file = productImages[idx].file;
                if (file) {
                    fileFormData.append('file', file);
                    await dispatch(
                        uploadProductImage({ id, file: fileFormData, params: { sortOrder: Number(idx) } })
                    ).unwrap();
                }
            }
            let success = false,
                data = {};
            if (isEdit) {
                for (const savedImageId of deletedSavedImages) {
                    await dispatch(deleteProductImage(savedImageId)).unwrap();
                }
                success = true;
            }
            //else {
            const detailProductResponse = await dispatch(getProductDetails(id)).unwrap();
            const { success: resSuccess, data: resData } = detailProductResponse;
            success = resSuccess;
            data = resData;
            //}

            if (success) {
                const { extend_type } = /* isEdit ? productDetails : */data;
                if (extend_type.length > 0) {
                    for (const type of extend_type) {
                        const { id: typeId, name, group_name } = type;
                        const targetProductType = productTypeData[group_name + name];

                        if (targetProductType && targetProductType.imageFile) {
                            const fileFormData = new FormData();
                            fileFormData.append('file', targetProductType.imageFile);
                            await dispatch(uploadProductTypeImage({ id: typeId, file: fileFormData })).unwrap();
                        }
                    }
                }
                Toast(true, intl.messages.actionSuccess);
                navigateTo(pagePath.adminProductManagementUrl);
            } else {
                Toast(success, intl.messages.actionFail);
            }
        } else {
            Toast(success, message);
        }
    };

    const handleSubmitInvalid = (error, event) => {
        if (isEdit) {
            if (error.images && productImages.length > 0) {
                delete error.images;
            }
        }

        event.target.classList.add('wasvalidated');
        const errorKeys = Object.keys(error);
        const errorsInSection = [];

        errorKeys.forEach((key) => {
            const getRequiredKey = key.split(' ')[0];
            const sectionError = requiredFields[getRequiredKey];
            if (!errorsInSection.includes(sectionError)) {
                errorsInSection.push(sectionError);
            }
        });

        const firstErrorSection = Math.min(...errorsInSection);
        const newSteps = sectionState.map((step, idx) => {
            if (errorsInSection.includes(idx)) {
                step.status = 'error';
            } else step.status = 'done';

            return step;
        });
        handleScrollToView(firstErrorSection);

        setSectionState(newSteps);
    };

    const handleRadioSalesChange = (event) => {
        setSalesInformationType(event.target.value);
    };

    const handleScrollToView = (index) => {
        sectionState[index]?.ref.current.scrollIntoView();
        handleExpandSection(-1, -1, index, false);
    };

    // ---------Advanced sales information section---------------
    const defaultApplyAllHeadCells = [
        {
            id: intl.messages.unitPrice,
            align: 'right',
            padding: 'normal',
            label: intl.messages.unitPrice,
            className: 'const-headCell'
        },
        {
            id: intl.messages.promotionalPrice,
            align: 'right',
            padding: 'normal',
            label: intl.messages.promotionalPrice,
            className: 'const-headCell'
        },
        {
            id: intl.messages.storage,
            align: 'right',
            padding: 'normal',
            label: intl.messages.storage,
            className: 'const-headCell'
        },
        {
            id: intl.messages.skuType,
            align: 'right',
            padding: 'normal',
            label: intl.messages.skuType,
            className: 'const-headCell'
        }
    ];
    const [mainDataState, setMainDataState] = useState([]);
    const [applyAllHeadCells, setApplyAllHeadCells] = useState(defaultApplyAllHeadCells);
    const [advancedSalesInformationInputs, setAdvancedSalesInformationInputs] = useState([]);

    const handleGroupTypeInputChange = (value, [group_idx, type_idx], name) => {
        const isGroupType = type_idx === 0;
        const typeListLength = groupTypeList[group_idx].length;
        const isLastTypeItem = type_idx === typeListLength - 1;
        const newGroupTypeList = groupTypeList.map((groupType, i) => {
            groupType.map((type, j) => {
                if (i === group_idx && j === type_idx) {
                    if (isGroupType && value.length <= maxGroupTypeValueLength) type.value = value;
                    else if (!isGroupType && value.length <= maxTypeValueLength) type.value = value;
                }

                return type;
            });

            if (isLastTypeItem && group_idx === i) groupType.push({ ...defaultTypeInput });

            return groupType;
        });

        setGroupTypeList(newGroupTypeList);
    };

    const handleAddGroupType = () => {
        if (groupTypeList.length < 2) {
            setGroupTypeList((prevItem) => [...prevItem, defaultGroupTypeInput]);
        }
    };

    const handleDeleteGroupType = (id) => {
        const newGroupTypeList = groupTypeList.filter((_, idx) => idx !== id);

        setGroupTypeList(newGroupTypeList);
    };

    useLayoutEffect(() => {
        const tempHeadCells = [];
        const tempAdvanceInputs = [];
        const tempMainDataState = [];
        const firstGroupTypeValue = [];
        const secondGroupTypeValue = [];
        const priceObj = {
            rules: { required: true, validate: (value) => reFormatNumber(value) >= 1000 },
            isPrice: true,
            placeholder: '0đ'
        };
        const promotionalPriceObj = {
            isPrice: true,
            placeholder: '0đ'
        };
        const storageObj = {
            rules: { required: true },
            type: 'number',
            placeholder: '0'
        };
        const skuObj = { placeholder: 'Nhập vào' };

        groupTypeList.forEach((groupType, gtype_idx) => {
            const tempInput = [];

            groupType.forEach((type, type_idx) => {
                const isGroupType = type_idx === 0;
                const advancedInputs = {
                    ...type,
                    label: isGroupType ? type.label + ` ${gtype_idx + 1}` : type.label,
                    name: isGroupType
                        ? type.name + ` ${gtype_idx + 1}`
                        : type.name + ` ${gtype_idx + 1} ${type_idx + 1}`,
                    novalidate: true
                };

                tempInput.push(advancedInputs);

                if (type.value !== '' && isGroupType) {
                    const addedHeadCell = {
                        id: type.value,
                        align: 'center',
                        padding: 'none',
                        label: type.value,
                        className: `var-headCell var-headCell--${gtype_idx}`
                    };

                    if (gtype_idx === 1) {
                        if (groupType.length > 1) {
                            if (groupType[1].value !== '') {
                                tempHeadCells.push(addedHeadCell);
                            }
                        }
                    } else {
                        tempHeadCells.push(addedHeadCell);
                    }
                }

                if (!isGroupType && type.value !== '') {
                    if (gtype_idx === 0) {
                        firstGroupTypeValue.push(type.value);
                    } else {
                        secondGroupTypeValue.push(type.value);
                    }
                }
            });

            tempAdvanceInputs.push(tempInput);
        });

        firstGroupTypeValue.forEach((grouptype1, idx1) => {
            let items = [];

            if (secondGroupTypeValue.length > 1) {
                const tempItems = [[], [], [], []];
                secondGroupTypeValue.forEach((_, idx2) => {
                    const distinguished_label = `${idx1} ${idx2}`;
                    const promotionalPriceRules = {
                        validate: {
                            greaterThan1000: (value) => {
                                const curValue = reFormatNumber(value);
                                return curValue ? curValue >= 1000 : true;
                            },

                            lessThanUnitPrice: (value) => {
                                const curValue = reFormatNumber(value);
                                const unitPriceValue = reFormatNumber(getValues(`unitPrice ${distinguished_label}`));

                                return (
                                    (curValue ? curValue <= unitPriceValue : true) || intl.messages.lessThanUnitPrice
                                );
                            }
                        }
                    };

                    tempItems[0].push({
                        name: `unitPrice ${distinguished_label}`,
                        ...priceObj
                    });
                    tempItems[1].push({
                        name: `promotionalPrice ${distinguished_label}`,
                        ...promotionalPriceObj,
                        rules: promotionalPriceRules
                    });
                    tempItems[2].push({ name: `quantity ${distinguished_label}`, ...storageObj });
                    tempItems[3].push({ name: `skuType ${distinguished_label}`, ...skuObj });
                });

                items = [secondGroupTypeValue, ...tempItems];
            } else {
                items = [
                    [{ name: `unitPrice ${idx1} 0`, ...priceObj }],
                    [
                        {
                            name: `promotionalPrice ${idx1} 0`,
                            ...promotionalPriceObj,
                            rules: {
                                validate: {
                                    greaterThan1000: (value) => {
                                        const curValue = reFormatNumber(value);
                                        return curValue ? curValue >= 1000 : true;
                                    },

                                    lessThanUnitPrice: (value) => {
                                        const curValue = reFormatNumber(value);
                                        const unitPriceValue = reFormatNumber(getValues(`unitPrice ${idx1} 0`));

                                        return (
                                            (curValue ? curValue <= unitPriceValue : true) ||
                                            intl.messages.lessThanUnitPrice
                                        );
                                    }
                                }
                            }
                        }
                    ],
                    [{ name: `quantity ${idx1} 0`, ...storageObj }],
                    [{ name: `skuType ${idx1} 0`, ...skuObj }]
                ];

                items.unshift(secondGroupTypeValue);
            }

            tempMainDataState.push({
                title: grouptype1,
                items
            });
        });

        setApplyAllHeadCells([...tempHeadCells, ...defaultApplyAllHeadCells]);
        setAdvancedSalesInformationInputs(tempAdvanceInputs);
        setMainDataState(tempMainDataState);
    }, [groupTypeList]);

    // ---------------------------END----------------------------

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getBrands());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (productId) {
            const fetchData = async () => {
                const res = await dispatch(getProductDetails(productId)).unwrap();
                const data = res.data;
                const { extend_images } = data;
                const transformImages = extend_images
                    .map((image) => {
                        return {
                            ...image,
                            url: Config.apiConfig.endPoint + image.physical_path
                        };
                    })
                    .sort((a, b) => a.sort_order - b.sort_order);

                setProductImages((prevImages) => [...transformImages, ...prevImages]);
                setIsEdit(true);
            };

            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    useEffect(() => {
        if (isEdit) {
            setFormActivated(true);
            const {
                name,
                product_category_id,
                description,
                brand_id,
                product_code,
                origin,
                features,
                origin_price,
                price,
                quantity,
                require_preorder,
                sku,
                condition,
                extend_product_ship,
                extend_type,
                extend_product_type
            } = productDetails;

            setValue('name', name);
            setValue('product_category_id', product_category_id);
            setValue('brand_id', brand_id);
            setValue('product_code', product_code);
            setValue('origin', origin);
            setValue('unitPrice', origin_price);
            setValue('promotionalPrice', price);
            setValue('quantity', quantity);
            setValue('require_preorder', require_preorder.toString());
            setValue('sku', sku);
            setValue('condition', condition);

            if (features && features.length > 0) {
                const { featureList } = JSON.parse(features);
                setAttributes(featureList);

                featureList.forEach((feat) => {
                    setValue('features&=' + feat.name, feat.value ? feat.value : '');
                });
            }

            const contentBlock = htmlToDraft(description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setValue('description', editorState);
            }

            if (extend_product_ship.length > 0) {
                const { product_weight, shipping_options } = extend_product_ship[0];
                setValue('weight', product_weight);

                shipping_options?.forEach((opt) => {
                    if (opt.length > 5) {
                        setValue('express', true);
                    } else {
                        setValue('fast', true);
                    }
                });
            }

            if (extend_type.length > 0) {
                setSalesInformationType('advance');
                const tempGroupTypeList = [];
                const ptypeImages = [];

                extend_type.forEach((type) => {
                    const { group_name, name, sort_order, id, physical_path } = type;

                    if (!tempGroupTypeList[sort_order]) {
                        tempGroupTypeList[sort_order] = [{ ...defaultGroupTypeInput[0], value: group_name, id }];
                    }

                    tempGroupTypeList[sort_order].push({ ...defaultTypeInput, value: name, id });

                    if (sort_order === 0) {
                        ptypeImages.push({ url: Config.apiConfig.endPoint + physical_path, file: null });
                    }
                });

                for (const list of tempGroupTypeList) {
                    list.push({ ...defaultTypeInput });
                }

                setGroupTypeList(tempGroupTypeList);
                setProductTypeImages(ptypeImages);

                extend_product_type.forEach((type) => {
                    const { origin_price, price, quantity, type_id, sku } = type;
                    const productTypes = [];

                    type_id.forEach((id) => {
                        const ptype = extend_type.find((etype) => etype.id === id);
                        productTypes.push(ptype);
                    });


                    const typeCount = productTypes.filter(x => x !== undefined).length;
                    if (typeCount === 0) return;
                    if (typeCount > 1) {
                        const idxs = [];
                        productTypes.forEach((elem) => {
                            const { id, sort_order } = elem;
                            const index = tempGroupTypeList[sort_order].slice(1).findIndex((t) => t.id === id);
                            idxs[sort_order] = index;
                        });

                        setValue(`unitPrice ${idxs.join(' ')}`, origin_price);
                        setValue(`promotionalPrice ${idxs.join(' ')}`, price);
                        setValue(`quantity ${idxs.join(' ')}`, quantity);
                        setValue(`skuType ${idxs.join(' ')}`, sku);
                    } else {
                        const ptype_idx = tempGroupTypeList[0].slice(1).findIndex((t) => t.id === productTypes[0].id);

                        setValue(`unitPrice ${ptype_idx} 0`, origin_price);
                        setValue(`promotionalPrice ${ptype_idx} 0`, price);
                        setValue(`quantity ${ptype_idx} 0`, quantity);
                        setValue(`skuType ${ptype_idx} 0`, sku);
                    }
                });
            }
        }
    }, [isEdit]);

    const addProductContainerProps = {
        ...props,
        dispatch,
        intl,
        handleCancel,
        handleSave,
        salesInformationType,
        handleRadioSalesChange,
        handleSubmitInvalid,
        handleAddGroupType,
        handleDeleteGroupType,
        handleSubmitBasicInformation,
        categories,
        deliveryPrice,
        preOrderDate,
        setPreOrderDate,
        brands,
        productImages,
        handleDeleteProductImage,
        handleUploadProductImages,
        formActivated,
        handleSubmit,
        control,
        errors,
        watchAllFields,
        getValues,
        handleExpandSection,
        sectionState,
        setValue,
        applyAllHeadCells,
        advancedSalesInformationInputs,
        mainDataState,
        handleUploadProductTypeImage,
        handleGroupTypeInputChange,
        formRef,
        handleDeleteProductTypeImage,
        productTypeImages,
        clearErrors,
        isEdit,
        attributes, setAttributes
    };

    return <AddProductMainView {...propsProvider(addProductContainerProps)} />;
};

export default AddProductContainer;
