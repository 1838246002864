const i18nLabels = {
    vi: {
        productList: 'Danh sách sản phẩm',
        addProduct: 'Thêm sản phẩm',
        all: 'Tất cả',
        onSold: 'Đang bán',
        outOfStock: 'Hết hàng',
        hidden: 'Đã ẩn',
        draft: 'Nháp',
        product: 'sản phẩm',
        multipleAddingTool: 'Công cụ xử lý hàng loạt',
        SKUtype: 'SKU phân loại',
        classify: 'Phân loại',
        price: 'Đơn giá (VNĐ)',
        storage: 'Kho hàng',
        sold: 'Đã bán',
        action: 'Thao tác',
        rowsPerPage: 'Số dòng trên trang',
        updateProduct: 'Cập nhật sản phẩm',
        deleteProduct: 'Xóa sản phẩm',
        hideProduct: 'Ẩn sản phẩm',
        noDataFound: 'Không có kết quả được tìm thấy',
        skuProduct: 'SKU Sản phẩm:',
        readMore: 'Xem thêm',
        readLess: 'Ẩn bớt',
        selectAll: 'Chọn tất cả',
        show: 'Hiển thị',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
        delete: 'Xóa',
        hide: 'ẩn',
        hideProductsConfirm: 'Bạn có chắc chắn ẩn sản phẩm này?',
        deleteProductsConfirm: 'Bạn có chắc chắn xóa sản phẩm này?',
        showProductsConfirm: 'Bạn có chắc chắn hiển thị sản phẩm này?',
        search: 'Tìm kiếm',
        reInput: 'Nhập lại',
        searchProduct: 'Tìm kiếm sản phẩm',
        productName: 'Tên sản phẩm',
        productCode: 'Mã sản phẩm',
        category: 'Ngành hàng',
        minValue: 'Tối thiểu',
        maxValue: 'Tối đa',
        selectCategory: 'Chọn ngành hàng',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công'
    },
    en: {
        productList: 'Product List',
        addProduct: 'Add Product',
        all: 'All',
        onSold: 'On Sold',
        outOfStock: 'Out of Stock',
        hidden: 'Hidden',
        draft: 'Draft',
        product: 'Product',
        multipleAddingTool: 'Multiple Adding Tool',
        SKUtype: 'SKU Type',
        classify: 'Classify',
        price: 'Price (VNĐ)',
        storage: 'Storage',
        sold: 'Sold',
        action: 'Action',
        rowsPerPage: 'Rows per Page',
        updateProduct: 'Update Product',
        deleteProduct: 'Delete Product',
        hideProduct: 'Hide Product',
        noDataFound: 'No data found',
        skuProduct: 'SKU Product:',
        readMore: 'Read More',
        readLess: 'Read Less',
        selectAll: 'Select All',
        show: 'Show',
        confirm: 'Confirm',
        cancel: 'Cancel',
        delete: 'Delete',
        hide: 'Hide',
        hideProductsConfirm: 'Are you sure you want to hide this product?',
        deleteProductsConfirm: 'Are you sure you want to delete this product?',
        showProductsConfirm: 'Are you sure you want to show this product?',
        search: 'Search',
        reInput: 'Reset',
        searchProduct: 'Search Product',
        productName: 'Product Name',
        productCode: 'Product Code',
        category: 'Category',
        minValue: 'Minimum',
        maxValue: 'Maximum',
        selectCategory: 'Select Category',
        actionFail: 'Action failed',
        actionSuccess: 'Action successful'
    }
};

export default i18nLabels;
