import {headerSelector, shopReportSelector} from 'app/selectors';
import moment from 'moment';
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {formatQuantity} from 'utils/formatQuantity';
import {getEndOfDate} from 'utils/getEndOfDate';
import {getRangeValue} from 'utils/getRangeValue';
import {getStartOfDate} from 'utils/getStartOfDate';
import propsProviders from './shopReportPropsProvider';
import {getBuyerPercentage, getOrderStatistics, getUserAccess} from './shopReportSlice';
import {Toast} from 'utils/toast';
import ShopReportMainView from './template/ShopReportMainView';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';

const dateFormat = 'DD/MM/yyyy';
const monthFormat = 'MM/yyyy';
const yearFormat = 'yyyy';
const currentTime = new Date();
const currentHour = currentTime.getHours();
const yesterday = moment(currentTime).subtract(1, 'd').set('hour', 23).set('minute', 59);
const yesterdayFormat = yesterday.format(dateFormat);

const hourChartLabels = getRangeValue(24, true).map((value) =>
    value === 23 ? '23:00' : value % 6 !== 0 ? '' : `${formatQuantity(value)}:00`
);
const last7DaysChartLabels = getRangeValue(7).map((value) => formatQuantity(value));
const monthChartLabels = getRangeValue(12).map((value) => formatQuantity(value));

const ShopReportContainer = (props) => {
    const {dispatch} = props;
    const intl = useIntl();
    const shopReport = useSelector(shopReportSelector);
    const [windowSize] = useDeviceSize();

    const filterTimePicker = [
        {value: `${intl.messages.to} ${formatQuantity((currentHour === 0 ? 24 : currentHour) - 1)}:00`},
        {value: yesterdayFormat},
        {
            value: `${moment(currentTime).subtract(8, 'd').format(dateFormat)} - ${yesterdayFormat}`
        },
        {
            value: `${moment(currentTime).subtract(31, 'd').format(dateFormat)} - ${yesterdayFormat}`
        }
    ];

    const [filterValue, setFilterValue] = useState(filterTimePicker[0].value);
    const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
    const [hoverFilterIndex, setHoverFilterIndex] = useState(0);
    const [dateValue, setDateValue] = useState(new Date());
    const [selectedCard, setSelectedCard] = useState(0);
    const [filteredTime, setFilteredTime] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState(30);
    const dateChartLabels = getRangeValue(daysInMonth).map((value) => formatQuantity(value));
    const timeSelectOptions = [
        {
            id: intl.messages.today,
            value: intl.messages.today,
            type: 'today',
            showCalendar: false,
            compareWith: intl.messages.yesterday,
            chartLabels: hourChartLabels
        },
        {
            id: intl.messages.yesterday,
            value: intl.messages.yesterday,
            type: 'yesterday',
            showCalendar: false,
            compareWith: intl.messages.last2Days,
            chartLabels: hourChartLabels
        },
        {
            id: intl.messages.inTheLast7Days,
            value: intl.messages.inTheLast7Days,
            type: 'lastWeek',
            showCalendar: false,
            compareWith: intl.messages.last7Days,
            chartLabels: last7DaysChartLabels
        },
        {
            id: intl.messages.inTheLast30Days,
            value: intl.messages.inTheLast30Days,
            type: 'lastMonth',
            showCalendar: false,
            compareWith: intl.messages.last30Days,
            chartLabels: dateChartLabels
        },
        {
            id: intl.messages.byDate,
            value: intl.messages.byDate,
            type: 'month',
            showCalendar: true,
            compareWith: intl.messages.yesterday,
            chartLabels: hourChartLabels
        },
        {
            id: intl.messages.byWeek,
            value: intl.messages.byWeek,
            type: 'month',
            showCalendar: true,
            compareWith: intl.messages.lastWeek,
            chartLabels: last7DaysChartLabels
        },
        {
            id: intl.messages.byMonth,
            value: intl.messages.byMonth,
            type: 'year',
            showCalendar: true,
            compareWith: intl.messages.lastMonth,
            chartLabels: dateChartLabels
        },
        {
            id: intl.messages.byYear,
            value: intl.messages.byYear,
            type: 'decade',
            showCalendar: true,
            compareWith: intl.messages.lastYear,
            chartLabels: monthChartLabels
        }
    ];

    const calendarRef = useRef(null);
    const {orderStatistics, buyerPercentage, userAccess} = shopReport;

    const handleFilterValue = (index, value) => {
        setSelectedFilterIndex(index);
        setFilterValue(value);
    };

    const handleMouseEnterFilter = (index) => {
        setHoverFilterIndex(index);
    };

    const handleSelectedSalesCard = (index) => {
        setSelectedCard(index);
    };

    const handleDateValueChange = (value, index, type = 'date') => {
        switch (type) {
            case 'date':
                setFilterValue(moment(value).format(dateFormat));
                break;
            case 'week':
                console.log(value);
                // setFilterValue(value);
                break;
            case 'month':
                setFilterValue(moment(value).format(monthFormat));
                break;
            case 'year':
                setFilterValue(moment(value).format(yearFormat));
                break;
            default:
                break;
        }
        setSelectedFilterIndex(index);
        setDateValue(value);
    };

    const getDataFromTimeStamps = useCallback(
        (timestamps = [], dataFilter = {}) => {
            let data;
            if ([0, 1, 4].includes(selectedFilterIndex)) {
                data = Array(24).fill(0);
                if (timestamps.length > 0) {
                    timestamps.forEach((timestamp) => {
                        data[new Date(timestamp).getHours()]++;
                    });
                } else {
                    Object.entries(dataFilter).forEach(([key, value]) => {
                        data[new Date(key).getHours()] += value;
                    });
                }
            } else if ([3, 6].includes(selectedFilterIndex)) {
                data = Array(daysInMonth).fill(0);
                if (timestamps.length > 0) {
                    timestamps.forEach((timestamp) => {
                        data[new Date(timestamp).getDate()]++;
                    });
                } else {
                    Object.entries(dataFilter).forEach(([key, value]) => {
                        data[new Date(key).getDate()] += value;
                    });
                }
            } else if (selectedFilterIndex === 2) {
                data = Array(7).fill(0);
                if (timestamps.length > 0) {
                    timestamps.forEach((timestamp) => {
                        data[new Date(timestamp).getDay()]++;
                    });
                } else {
                    Object.entries(dataFilter).forEach(([key, value]) => {
                        data[new Date(key).getDay()] += value;
                    });
                }
            } else if (selectedFilterIndex === 7) {
                data = Array(12).fill(0);
                if (timestamps.length > 0) {
                    timestamps.forEach((timestamp) => {
                        data[new Date(timestamp).getMonth()]++;
                    });
                } else {
                    Object.entries(dataFilter).forEach(([key, value]) => {
                        data[new Date(key).getMonth()] += value;
                    });
                }
            }
            return data;
        },
        [selectedFilterIndex]
    );

    const salesCardList = useMemo(() => {
        if (orderStatistics) {
            const {sales, order_count, sale_on_order, cancel_order_count, refund_order_count} = orderStatistics;
            const getRate = (current, previous) => {
                if (previous === 0) return 0;
                return Math.round(((current - previous) * 100) / previous);
            };

            return [
                {
                    title: intl.messages.sales,
                    value: sales?.current,
                    rate: getRate(sales?.current, sales?.previous),
                    tooltipLabel: intl.messages.salesTooltip
                },
                {
                    title: intl.messages.orders,
                    value: order_count?.current,
                    rate: getRate(order_count?.current, order_count?.previous),
                    tooltipLabel: intl.messages.ordersTooltip
                },
                {
                    title: intl.messages.salesPerOrder,
                    value:
                        typeof sale_on_order?.current === 'number'
                            ? sale_on_order?.current.toFixed(2)
                            : sale_on_order?.current,
                    rate: getRate(sale_on_order?.current, sale_on_order?.previous),
                    tooltipLabel: intl.messages.salesPerOrderTooltip
                },
                {
                    title: intl.messages.cancelOrder,
                    value: cancel_order_count?.current,
                    rate: getRate(cancel_order_count?.current, cancel_order_count?.previous),
                    tooltipLabel: intl.messages.cancelOrderTooltip
                },
                {
                    title: intl.messages.refundOrder,
                    value: refund_order_count?.current,
                    rate: getRate(refund_order_count?.current, refund_order_count?.previous),
                    tooltipLabel: intl.messages.refundOrderTooltip
                }
            ];
        } else return [];
    }, [orderStatistics]);

    const userCardList = useMemo(() => {
        const {
            current_buyer_current_month,
            current_buyer_passive_month,
            current_buyer_percent,
            new_buyer_current_month,
            new_buyer_passive_month,
            new_buyer_percent,
            potential_buyer_current_month,
            potential_buyer_percent,
            rate_of_return,
            rate_of_return_percentage
        } = buyerPercentage;

        const totalUser = current_buyer_current_month + new_buyer_current_month;
        const passTotalUser = current_buyer_passive_month + new_buyer_passive_month;
        const totalRate = passTotalUser ? Math.round((totalUser - passTotalUser) / passTotalUser) * 100 : 0;

        return [
            {
                title: intl.messages.totalNewUser,
                value: totalUser,
                rate: totalRate,
                tooltipLabel: intl.messages.totalNewUserTooltip
            },
            {
                title: intl.messages.newBuyer,
                value: new_buyer_current_month,
                rate: new_buyer_percent,
                tooltipLabel: intl.messages.newBuyerTooltip
            },
            {
                title: intl.messages.currentBuyer,
                value: current_buyer_current_month,
                rate: current_buyer_percent,
                tooltipLabel: intl.messages.currentBuyerTooltip,
                chartLabels: []
            },
            {
                title: intl.messages.potentialBuyer,
                value: potential_buyer_current_month,
                rate: potential_buyer_percent,
                tooltipLabel: intl.messages.potentialBuyerTooltip,
                chartLabels: []
            },
            {
                title: intl.messages.returnRateBuyer,
                value: typeof rate_of_return === 'number' ? rate_of_return.toFixed(2) : rate_of_return + '%',
                rate: rate_of_return_percentage,
                tooltipLabel: intl.messages.returnRateBuyerTooltip,
                chartLabels: []
            }
        ];
    }, [buyerPercentage]);

    const orderData = useMemo(() => {
        if (orderStatistics) {
            const {sales, order_count, cancel_order_count, refund_order_count} = orderStatistics;
            const salesTimestamps = getDataFromTimeStamps([], sales?.data_filter);
            const orderCountTimestamps = getDataFromTimeStamps(order_count?.order_success_timestamp);
            switch (selectedCard) {
                case 0:
                    return salesTimestamps;
                case 1:
                    return orderCountTimestamps;
                case 2:
                    const salesOnOrderTimestamps = salesTimestamps.map((value, i) =>
                        orderCountTimestamps[i] > 0 ? Math.round(value / orderCountTimestamps[i]) : 0
                    );
                    return salesOnOrderTimestamps;
                case 3:
                    return getDataFromTimeStamps(cancel_order_count.order_cancel_timestamp);
                case 4:
                    return getDataFromTimeStamps(refund_order_count.order_refund_timestamp);
                default:
                    return [];
            }
        } else return [];
    }, [orderStatistics, selectedCard, getDataFromTimeStamps]);

    const userAccessData = useMemo(() => {
        if (userAccess) {
            const {user_access_timestamp} = userAccess;

            return getDataFromTimeStamps(user_access_timestamp);
        } else return [];
    }, [userAccess, getDataFromTimeStamps]);

    // Sales chart
    useLayoutEffect(() => {
        let startTime, endTime;

        switch (selectedFilterIndex) {
            case 0:
                startTime = getStartOfDate(moment(currentTime)).format();
                endTime = moment(new Date()).subtract(1, 'hour').format();
                break;
            case 1:
                startTime = getStartOfDate(moment(yesterday)).format();
                endTime = moment(yesterday).format();
                break;
            case 2:
                startTime = getStartOfDate(moment(currentTime).subtract(8, 'd')).format();
                endTime = moment(yesterday).format();
                break;
            case 3:
                startTime = getStartOfDate(moment(currentTime).subtract(31, 'd')).format();
                endTime = moment(yesterday).format();
                break;
            case 4:
                startTime = getStartOfDate(moment(dateValue)).format();
                endTime = getEndOfDate(moment(dateValue)).format();
                break;
            case 5:
                // startTime = getStartOfDate(moment(currentTime).subtract(31, 'd')).format();
                // endTime = moment(yesterday).format();
                break;
            case 6:
                const momentValue = moment(dateValue);
                const days = momentValue.daysInMonth();
                setDaysInMonth(days);
                startTime = getStartOfDate(moment(dateValue).set('date', 1)).format();
                endTime = getEndOfDate(moment(dateValue).set('date', days)).format();
                break;
            case 7:
                startTime = getStartOfDate(moment(dateValue).set('date', 1).set('month', 0)).format();
                endTime = getEndOfDate(moment(dateValue).set('date', 31).set('month', 11)).format();
                break;
            default:
                break;
        }

        setFilteredTime({startTime, endTime});
    }, [dateValue, selectedFilterIndex]);

    useEffect(() => {
        try {
            if (filteredTime) {
                const fetchData = async () => {
                    await dispatch(getBuyerPercentage({filterDate: filteredTime.startTime})).unwrap();
                    await dispatch(getOrderStatistics(filteredTime)).unwrap();
                    await dispatch(
                        getUserAccess({start_date: filteredTime.startTime, end_date: filteredTime.endTime})
                    ).unwrap();
                };

                fetchData();
            }
        } catch (err) {
            Toast(false, intl.messages.error);
        }
    }, [filteredTime]);

    useLayoutEffect(() => {
        if (selectedFilterIndex === 0) {
            setFilterValue(filterTimePicker[0].value);
        }
    }, [selectedFilterIndex, intl]);

    const shopReportContainerProps = {
        ...props,
        dispatch,
        intl,
        filterValue,
        handleFilterValue,
        timeSelectOptions,
        handleMouseEnterFilter,
        selectedFilterIndex,
        hoverFilterIndex,
        filterTimePicker,
        dateValue,
        handleDateValueChange,
        salesCardList,
        selectedCard,
        handleSelectedSalesCard,
        userCardList,
        orderData,
        calendarRef,
        buyerPercentage,
        filteredTime,
        userAccessData,
        widthSize: windowSize.width
    };

    return <ShopReportMainView {...propsProviders(shopReportContainerProps)} />;
};

export default ShopReportContainer;
