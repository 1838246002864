import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import addressApi from 'api/addressApi';
import brandApi from 'api/brandApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        shopInfo: {},
        addressList: [],
        brandAddressList: []
    };
};

export const getBrandById = createAsyncThunk('adminShopInformation/getBrandById', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.getBrandById,
        payload: id
    });
});

export const updateBrand = createAsyncThunk('adminShopInformation/updateBrand', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.updateBrand,
        payload: data
    });
});

export const uploadBrandLogo = createAsyncThunk('adminShopInformation/uploadBrandLogo', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.uploadBrandLogo,
        payload: data
    });
});

export const getAddressList = createAsyncThunk('adminShopInformation/getAddressList', async (data, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: addressApi.getAddressList,
		payload: data
	});
});

export const getBrandAddresses = createAsyncThunk('adminShopInformation/getBrandAddresses', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.getBrandAddresses,
        payload: data
    });
});

export const addBrandAddress = createAsyncThunk('adminShopInformation/addBrandAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.addBrandAddress,
        payload: data
    });
});

export const updateBrandAddress = createAsyncThunk('adminShopInformation/updateBrandAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.updateBrandAddress,
        payload: data
    });
});

export const deleteBrandAddress = createAsyncThunk('adminShopInformation/deleteBrandAddress', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.deleteBrandAddress,
        payload: id
    });
});

const adminShopInformation = createSlice({
    name: 'adminShopInformation',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(getBrandById.fulfilled, (state, action) => {
                state.shopInfo = action.payload.data;
            })
            .addCase(getAddressList.fulfilled, (state, action) => {
                state.addressList = action.payload?.data;
            })
            .addCase(getBrandAddresses.fulfilled, (state, action) => {
                state.brandAddressList = action.payload?.data?.collection;
            })
    }
});

const {reducer} = adminShopInformation;
export default reducer;
