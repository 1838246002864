import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './chatSetting.i18n';
import ChatSettingContainer from './ChatSettingContainer';

const ChatSetting = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const chatSettingProps = {
		...props,
		dispatch,
		history,
		i18nLabels
	};

	return <DecorateContainer {...chatSettingProps} childComponent={ChatSettingContainer} />;
};

export default ChatSetting;