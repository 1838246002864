const i18nLabels = {
    vi: {
        editProfile: 'Sửa hồ sơ',
        myNotification: 'Thông báo của tôi',
        order: 'Cập nhật đơn hàng',
        voucher: 'Khuyến mãi',
        other: 'Khác',
        markAllAsRead: 'Đánh dấu tất cả đã đọc',
        readDetail: 'Xem chi tiết',
        rateProduct: 'Đánh giá sản phẩm',
        emptyNoti: 'Bạn chưa có thông báo nào!',
        pagesize: 'Số dòng trên trang'
    },
    en: {
        editProfile: 'Edit Profile',
        myNotification: 'My Notifications',
        order: 'Order Updates',
        voucher: 'Promotions',
        other: 'Other',
        markAllAsRead: 'Mark All as Read',
        readDetail: 'Read Details',
        rateProduct: 'Rate Product',
        emptyNoti: 'You have no notifications!',
        pagesize: 'Number of Rows per Page'
    }
};

export default i18nLabels;
