import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './UserVoucherPropsProvider';
import UserVoucherMainView from './template/UserVoucherMainView';
import {getAllVoucher} from './UserVoucherSlice';
import {useSelector} from 'react-redux';
import {userVoucherListSelector} from 'app/selectors';
import moment from 'moment';
import {getEndOfDate} from 'utils/getEndOfDate';

const UserVoucherContainer = (props) => {
    const {dispatch, history} = props;
    const voucher = useSelector(userVoucherListSelector);
    const intl = useIntl();
    const [isOpenVoucherFormModal, setIsOpenVoucherFormModal] = useState(false);
    const [selectedVoucherObj, setSelectedVoucherObj] = useState({});
    const [displayList, setDisplayList] = useState([]);
    const {userVoucherList} = voucher;

    const navigateTo = (path) => {
        history.push(path);
    };

    useEffect(() => {
        const params = {
            Sorts: '-expiry',
            Filters: `status==e22cbf21-506d-4d40-a37a-3e648f54d563,expiry>=${getEndOfDate(moment(new Date())).format(
                'YYYY/MM/DD'
            )}`
        };

        dispatch(getAllVoucher(params));
    }, []);

    useLayoutEffect(() => {
        if (userVoucherList?.collection) {
            setDisplayList(userVoucherList?.collection);
        }
    }, [voucher]);

    const handleSearch = (value) => {
        const newList = userVoucherList?.collection.filter((voucher) =>
            voucher.code.toLowerCase().includes(value.toLowerCase())
        );
        setDisplayList(newList);
    };

    const onOpenVoucherModal = (voucher) => {
        setIsOpenVoucherFormModal(true);
        setSelectedVoucherObj(voucher);
    };

    const closeVoucherModal = () => {
        setIsOpenVoucherFormModal(false);
    };

    const UserVoucherContainerProps = {
        dispatch,
        navigateTo,
        intl,
        voucher,
        onOpenVoucherModal,
        isOpenVoucherFormModal,
        selectedVoucherObj,
        closeVoucherModal,
        handleSearch,
        displayList
    };
    return <UserVoucherMainView {...propsProvider(UserVoucherContainerProps)} />;
};

export default UserVoucherContainer;
