import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router';
import {Toast} from 'utils/toast';
import propsProvider from './OrderDetailPropsProvider';
import {getOrderById, proceedOrderStep, proceedOrderSubStep} from './OrderDetailSlice';
import OrderDetailMainView from './template/OrderDetailMainView';
import {
    addNotificationWithStatus,
    sendPushNotification
} from '../../ShopSetting/NotificationSetting/NotificationSettingSlice';
import {pagePath} from 'configuration/routeConfig';
import {getUserDetail} from 'features/UserProfile/UserProfileSlice';

export const orderActions = {
    confirmOrder: 'confirmOrder',
    prepare: 'prepareOrder',
    handOver: 'handOver',
    confirmDelivered: 'confirmDelivered',
    successDelivery: 'successDelivery',
    cancel: 'cancel',
    print: 'print',
    accept: 'accept',
    reject: 'reject'
};

const OrderDetailContainer = (props) => {
    const {dispatch, adminOrderDetail} = props;
    const intl = useIntl();
    const {orderId} = useParams();
    const [currentStep, setCurrentStep] = useState('');
    const [countDown, setCountDown] = useState(null);
    const [isOpenReasonModal, setOpenReasonModal] = useState(false);
    const [reasonValue, setReasonValue] = useState('');
    const [isOpenRefundModal, setOpenRefundModal] = useState(false);

    const closeRefundModal = () => {
        setOpenRefundModal(false);
    };

    const openRefundModal = () => {
        setOpenRefundModal(true);
    };

    const closeReasonModal = () => {
        setOpenReasonModal(false);
    };

    const openReasonModal = () => {
        setOpenReasonModal(true);
    };

    const handleOrderStep = async (type = '') => {
        let notificationParams = {
            approvedStatusCode: 'SENT'
        };
        let res;
        try {
            switch (type) {
                case orderActions.confirmOrder:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Packing-Pending'}));
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'CONFIRMED'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đã được Xác nhận thành công',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    const {success} = res;
                    if (success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                case orderActions.prepare:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Delivery-Pending'}));
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'PENDING'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đang được chờ vận chuyển',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                case orderActions.handOver:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Delivery'}));
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'DELIVERING'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đang trong quá trình vận chuyển',
                                user_id: adminOrderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                case orderActions.confirmDelivered:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Delivered'}));
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'SHIPPED'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đã được giao thành công',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                case orderActions.cancel:
                    openReasonModal();
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đã được hủy',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    return;
                case orderActions.accept:
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'REFUND_ACCEPTED'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đã được chấp nhận hoàn tiền',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                case orderActions.reject:
                    await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'REFUND_DENIED'}));
                    res = await dispatch(
                        addNotificationWithStatus({
                            notificationParams,
                            data: {
                                summary: `Cập nhật trạng thái đơn hàng ${adminOrderDetail?.orderDetail?.order_information?.order_code}`,
                                details: 'Đơn hàng của bạn đã bị từ chối hoàn tiền',
                                user_id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id,
                                main_category_type: 0,
                                reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                            }
                        })
                    ).unwrap();
                    if (res?.success) {
                        const userRes = await dispatch(
                            getUserDetail({id: adminOrderDetail?.orderDetail?.order_receive_user?.user_id})
                        ).unwrap();
                        if (userRes?.success) {
                            await dispatch(
                                sendPushNotification({
                                    id: res?.data?.id,
                                    fcmClientToken: userRes?.data?.fcm_client_token || ''
                                })
                            ).unwrap();
                        }
                    }
                    fetchOrderById();
                    break;
                default:
                    break;
            }
            Toast(true, intl.messages.actionSuccess);
        } catch (err) {
            Toast(false, intl.messages.actionFail);
        }
    };

    const handleCancelOrder = async (reason) => {
        const params = {
            id: orderId,
            nextStepCode: 'Cancel',
            cancelReason: reason || reasonValue
        };
        const res = await dispatch(proceedOrderStep(params)).unwrap();
        await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'SHOP_CANCEL'}));
        const {success} = res;
        if (success) {
            Toast(success, 'Đã hủy đơn hàng');
            fetchOrderById();
        } else {
            Toast(success, 'Hủy đơn không thành công!');
        }
    };

    const fetchOrderById = async () => {
        if (orderId) {
            const res = await dispatch(getOrderById(orderId)).unwrap();
            const {success} = res;
            if (success) {
                const {data} = res;
                setCurrentStep({
                    name: data?.order_information?.extend_step?.name,
                    code: data?.order_information?.extend_step?.code
                });
                const timeLeft =
                    moment(data?.order_information?.order_created_at).add(3, 'd').valueOf() - moment().valueOf();
                setCountDown(timeLeft);
            }
        }
    };

    useEffect(() => {
        setReasonValue('');
    }, [isOpenReasonModal]);

    useEffect(() => {
        fetchOrderById();
    }, [orderId]);

    useEffect(() => {
        if (currentStep && currentStep?.name === 'Chờ xác nhận') {
            if (countDown > 0) {
                setTimeout(() => {
                    setCountDown((prev) => prev - 1000);
                }, 1000);
            } else {
                handleCancelOrder('Quá thời hạn xác nhận đơn hàng');
            }
        }
        return () => {
            clearTimeout();
        };
    }, [countDown, currentStep]);

    const orderDetailContainerProps = {
        ...props,
        dispatch,
        intl,
        adminOrderDetail,
        currentStep,
        handleOrderStep,
        countDown,
        isOpenReasonModal,
        closeReasonModal,
        handleCancelOrder,
        reasonValue,
        setReasonValue,
        isOpenRefundModal,
        closeRefundModal,
        openRefundModal
    };

    return <OrderDetailMainView {...propsProvider(orderDetailContainerProps)} />;
};

export default OrderDetailContainer;
