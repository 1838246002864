const i18nLabels = {
    vi: {
        exportCompanyInvoice: 'Xuất hóa đơn',
        companyName: 'Tên công ty',
        companyNamePlaceholder: 'Nhập tên công ty',
        companyAddress: 'Địa chỉ công ty',
        companyAddressPlaceholder: 'Nhập địa chỉ công ty',
        taxNumber: 'Mã số thuế',
        taxNumberPlaceholder: 'Nhập mã số thuế công ty',
        emailForEInvoice: 'Email nhận hóa đơn điện tử',
        emailPlaceholder: 'Nhập email nhận hóa đơn',
        emailSupportLabel: 'Hóa đơn điện tử sẽ gửi về email trên sau khi hoàn thành đơn hàng',
        note: 'Ghi chú',
        notePlaceholder: 'Nhập ghi chú (nếu có)',
        orderAction: 'Đặt hàng',
        yourInvoice: 'Hóa đơn của bạn',
        detail: 'Chi tiết',
        shorten: 'Thu gọn',
        productPrice: 'Tiền sản phẩm',
        deliveryFee: 'Phí vận chuyến',
        discount: 'Giảm giá',
        totalPrice: 'Tổng thanh toán',
        personal: 'Cá nhân',
        company: 'Công ty',
        receiverName: 'Tên người nhận',
        receiverNamePlaceholder: 'Nhập tên người nhận',
        receiverAddress: 'Địa chỉ người nhận',
        receiverAddressPlaceholder: 'Nhập địa chỉ người nhận',
        decrease: "Giảm",
        voucherCode: 'Mã giảm giá',
        voucherModalTitle:
            'Bạn vui lòng nhập mã khuyến mãi hoặc chọn những mã khuyến mãi được áp dụng bên dưới (nếu có) để mua hàng tiết kiệm hơn nhé!',
        voucherModalPlaceholder: 'Nhập hoặc chọn mã khuyến mãi',
        noCoupon: 'Không có mã khuyến mãi rồi!',
        couponCode: 'Mã khuyến mãi',
        couponCodeExpired: 'Mã khuyến mãi hết hạn',
        couponCodeNotExist: 'Mã khuyến mãi không tồn tại',
        couponCodeFail: 'Chưa thỏa điều kiện',
        apply: 'Áp dụng',
        cancelApply: 'Hủy áp dụng',
        allCoupons: 'Tất cả khuyến mãi',
        voucher: 'voucher',
        expiredDate: 'HSD',
        save: 'Lưu',
        appliedCoupon: 'Mã khuyến mãi đã được áp dụng, bạn được giảm',
        thisOrder: 'cho đơn hàng này đó!',
        notCondition: 'Không đủ điều kiện',
        updateOrderStatus: 'Cập nhật trạng thái đơn hàng',
        notiOrderDetails: 'Đơn hàng của bạn đang chờ xác nhận',
        close: 'Đóng',
        addAddressWarningMessage: 'Vui lòng thêm mới địa chỉ vận chuyển'
    },
    en: {
        exportCompanyInvoice: 'Export Invoice',
        companyName: 'Company Name',
        companyNamePlaceholder: 'Enter company name',
        companyAddress: 'Company Address',
        companyAddressPlaceholder: 'Enter company address',
        taxNumber: 'Tax Number',
        taxNumberPlaceholder: 'Enter company tax number',
        emailForEInvoice: 'Email for E-Invoice',
        emailPlaceholder: 'Enter email for invoice',
        emailSupportLabel: 'E-Invoice will be sent to the provided email after completing the order',
        note: 'Note',
        notePlaceholder: 'Enter note (if any)',
        orderAction: 'Place Order',
        yourInvoice: 'Your Invoice',
        detail: 'Detail',
        shorten: 'Shorten',
        productPrice: 'Product Price',
        deliveryFee: 'Delivery Fee',
        discount: 'Discount',
        totalPrice: 'Total Payment',
        personal: 'Personal',
        company: 'Company',
        receiverName: 'Receiver Name',
        receiverNamePlaceholder: 'Enter receiver name',
        receiverAddress: 'Receiver Address',
        receiverAddressPlaceholder: 'Enter receiver address',
        updateOrderStatus: 'Update order status',
        notiOrderDetails: 'Your order is awaiting confirmation',
        addAddressWarningMessage: 'Please add new delivery address'
    }
};

export default i18nLabels;
