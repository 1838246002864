import axiosClient from './axiosClient';

const usersApi = {
    forgotPassword: (data) => {
        const url = '/api/users/forgotPassword';
        return axiosClient.post(url, data, {params: data});
    },

    setPassword: (data) => {
        const url = '/api/users/setPassword';
        return axiosClient.put(url, data, {params: data});
    },

    registerUser: (data) => {
        const url = '/api/users';
        return axiosClient.post(url, data);
    },

    changePassword: (data) => {
        const url = '/api/users/changePassword';
        return axiosClient.post(url, data, {params: data});
    },

    updateUser: (data) => {
        const {id, body} = data;
        const url = '/api/users';
        return axiosClient.put(`${url}/${id}`, body);
    },

    upLoadAvatar: (data) => {
        const {id} = data;
        const {body} = data;
        const url = '/api/users';
        return axiosClient.put(`${url}/${id}/uploadAvatar`, body);
    },

    genOtpToChangeEmail: (data) => {
        const {id, newEmail, oldPassword} = data;
        const url = '/api/users';
        return axiosClient.put(`${url}/${id}/genOtpToChangeEmail?newEmail=${newEmail}&oldPassword=${oldPassword}`);
    },

    confirmOtpToChangeEmail: (data) => {
        const {id, newEmail, otp, oldPassword} = data;
        const url = '/api/users';
        return axiosClient.put(
            `${url}/${id}/confirmOtpToChangeEmail?newEmail=${newEmail}&otp=${otp}&oldPassword=${oldPassword}`
        );
    },

    userAddress: (params) => {
        const url = '/api/userAddresses/myAddress';
        return axiosClient.get(url, {params});
    },

    deleteUserAddress: (id) => {
        const url = '/api/userAddresses';
        return axiosClient.delete(`${url}/${id}`);
    },

    checkPhoneIsHave: (data) => {
        const {id, newPhone} = data;
        const url = '/api/users';
        return axiosClient.put(`${url}/${id}/checkPhoneIsHave`, newPhone);
    },

    changePhone: (data) => {
        const {id, newPhone} = data;
        const url = '/api/users';
        return axiosClient.put(`${url}/${id}/changePhone`, newPhone);
    },

    getUsers: (data) => {
        const url = '/api/users';
        return axiosClient.get(url, {params: data});
    },

    addUserAddress: (data) => {
        const url = '/api/userAddresses';
        return axiosClient.post(url, data);
    },

    updateUserAddress: (data) => {
        const {id} = data;
        const url = `/api/userAddresses/${id}`;
        return axiosClient.put(url, data);
    },

    addNewUser: (data) => {
        const {roleId, isSendToMail, body} = data;
        const url = '/api/users';
        return axiosClient.post(`${url}?roleId=${roleId}&isSendToMail=${isSendToMail}`, body);
    },

    deleteUser: (data) => {
        const {id} = data;
        const url = '/api/users';
        return axiosClient.delete(`${url}/${id}`);
    },

    updateFCMClientToken: (data) => {
        const url = `/api/users/updateFCMClientToken`;
        return axiosClient.put(url, data);
    },

    getUserDetail: (data) => {
        const {id} = data;
        const url = `/api/users/${id}`;
        return axiosClient.get(url);
    }
};

export let updateUser = usersApi.updateUser;

export default usersApi;
