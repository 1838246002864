import React from 'react';
import {couponFrame, notes, Shipper, Voucher} from 'assets/images';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import {FormattedNumber} from 'react-intl';
import Config from 'configuration';
import { formatThousands } from 'utils/formatThousands';

const ProductList = (props) => {
    const {intl, navigateTo, productList, note, voucherInfo, shippingUnitInfo, totalProduct, totalPrice} = props;

    return (
        <div className='payment-product-list'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <SectionTitle paled={false}>{intl.messages.productTitle}</SectionTitle>
                </div>
            </div>
            <div key={'brand_id'} style={{border: '1px solid #ecf0fe', borderRadius: 8}}>
                <div style={{borderBottom: '1px solid #ecf0fe'}}>
                    {productList &&
                        productList.map((product) => (
                            <div
                                key={product?.productId}
                                className='d-flex align-items-center'
                                style={{padding: '18px 16px'}}>
                                <div className='d-flex align-items-center col'>
                                    <img
                                        alt='product'
                                        src={`${Config.apiConfig.endPoint}${product?.productImg}`}
                                        style={{borderRadius: 8, marginRight: 8}}
                                        width='48'
                                        height='48'
                                    />
                                    <div>
                                        <div>
                                            <span style={{color: '#333333', fontSize: 15, fontWeight: 500}}>
                                                {product?.productName}
                                            </span>
                                        </div>
                                        <div style={{color: '#808080', fontSize: 13}}>
                                            <span>{product?.productDescription}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center col-2 product-unit-price'>
                                    <span style={{color: '#333333', fontSize: 15}}>
                                        <FormattedNumber
                                            style='currency'
                                            currency='VND'
                                            value={product?.productPrice}
                                        />
                                    </span>
                                </div>
                                <div className='d-flex align-items-center col-1'>
                                    <span style={{color: '#333333', fontSize: 15}}>x{product?.productQuantity}</span>
                                </div>
                                <div className='d-flex align-items-center col-sm-2 col-3'>
                                    <span style={{color: '#333333', fontSize: 15}}>
                                        <FormattedNumber
                                            style='currency'
                                            currency='VND'
                                            value={product?.productPrice * product?.productQuantity}
                                        />
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
                <div
                    className='d-flex justify-content-between align-items-center'
                    style={{borderBottom: '1px solid #ecf0fe', padding: 16}}>
                    <div className='d-flex align-items-center'>
                        <Voucher className='me-1 voucher-blue' />
                        <span style={{fontSize: 13, color: '#4568f2', fontWeight: 500}}>
                            {intl.messages.shopVoucher}
                        </span>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        {voucherInfo?.appliedVoucherValue > 0 && (
                            <div className='voucher-applied'>
                                <img src={couponFrame} alt={voucherInfo?.appliedVoucherValue} />
                                <span>
                                    {intl.messages.discountText} {formatThousands(Math.round(voucherInfo?.appliedVoucherValue))}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className='row' style={{padding: 16}}>
                    <div className='col-8 col-sm-4 d-flex align-items-center'>
                        <div className='d-flex' onClick={(e) => e.preventDefault()} style={{cursor: 'pointer'}}>
                            <Shipper className='me-1' />
                            <span style={{fontSize: 13, color: '#4568f2', fontWeight: 500, marginRight: 4}}>
                                {intl.messages.shippingUnit}
                            </span>
                        </div>
                    </div>
                    <div className='order-2 order-sm-1 mt-2 mt-sm-0 col-sm-6 d-flex align-items-center'>
                        <span style={{fontSize: 13, fontWeight: 500}}>{shippingUnitInfo?.unitName} &nbsp;</span>
                        <span style={{fontSize: 12, color: '#808080'}}>
                            {/* (Nhận hàng {moment(selectedShippingUnit.receive_from_at).format('DD/MM/YYYY')} đến{' '}
                            {moment(selectedShippingUnit.receive_to_at).format('DD/MM/YYYY')}) */}
                            (Nhận hàng từ 13/01/2023 đến 15/01/2023)
                        </span>
                    </div>
                    <div className='order-1 order-sm-2 col-4 col-sm-2 d-flex align-items-center'>
                        <span style={{fontSize: 15}}>
                            <FormattedNumber style='currency' currency='VND' value={shippingUnitInfo?.shippingFee} />
                        </span>
                    </div>
                </div>
                <div
                    className='d-flex justify-content-between align-items-center'
                    style={{borderBottom: '1px solid #ecf0fe', borderTop: '1px solid #ecf0fe', padding: 16}}>
                    <div className='d-flex align-items-center'>
                        <img src={notes} alt='notes' style={{marginRight: 4}} />
                        <span style={{fontSize: 13, color: '#808080', fontWeight: 500, marginRight: 4}}>
                            {intl.messages.note}
                        </span>
                    </div>
                    <div className='w-75 d-flex align-items-center'>
                        <span style={{color: '#c3c9d0'}}>{!note ? 'Không có ghi chú nào' : note}</span>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{padding: 16}}>
                    <span style={{color: '#333333', fontSize: 15, fontWeight: 500}}>
                        {intl.messages.totalText} ({totalProduct} {intl.messages.productText})
                    </span>
                    <span style={{color: '#4568f2', fontSize: 17, fontWeight: 500}}>
                        <FormattedNumber style='currency' currency='VND' value={totalPrice} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ProductList;
