import CustomInput from 'components/CustomInput/CustomInput';
import {Controller} from 'react-hook-form';
import {Approve, Edit} from 'assets/images';

const EmailRefund = (props) => {
    const {intl, openEditEmail, isDisabled, control} = props;
    return (
        <div className='refund-email'>
            <div className='refund-email-title refund-section-title'>{intl.messages.yourEmail}</div>
            <div className='refund-email-hint'>{intl.messages.emailHint}</div>
            <div className='refund-email-input refund-section-content'>
                <Controller
                    name='email'
                    control={control}
                    render={({field: {onChange, ...moreField}}) => {
                        return (
                            <CustomInput
                                {...moreField}
                                placeholder={intl.messages.yourEmail}
                                endicon={
                                    isDisabled ? (
                                        <Edit onClick={openEditEmail} className='icon-hover' />
                                    ) : (
                                        <Approve onClick={openEditEmail} className='icon-hover' />
                                    )
                                }
                                disabled={isDisabled}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};
export default EmailRefund;
