import {SliderRightArrow} from 'assets/images';
import {useEffect, useRef, useState} from 'react';
import {Carousel} from 'react-bootstrap';

function ProductImageCarousel(props) {
    const {imageSourceList = [], indicators, setNumberImg, activeIdx = 0} = props;
    const carouselRef = useRef(null);
    const [isHover, setIsHover] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const isShowArrows = isHover && imageSourceList.length > 1;
    const isShowPrevArrow = isShowArrows && activeIndex > 0;
    const isShowNextArrow = isShowArrows && activeIndex < imageSourceList.length - 1;

    const showArrow = () => setIsHover(true);
    const hideArrow = () => setIsHover(false);

    const handlePrev = (e) => {
        e.stopPropagation();

        if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        setNumberImg(activeIndex - 1);
    };

    const handleNext = (e) => {
        e.stopPropagation();

        if (activeIndex < imageSourceList.length - 1) setActiveIndex(activeIndex + 1);
        setNumberImg(activeIndex + 1);
    };

    useEffect(() => {
        if (activeIdx !== null) {
            setActiveIndex(activeIdx);
        }
    }, [activeIdx]);

    return (
        <div className='carousel-wrapper' onMouseEnter={showArrow} onMouseLeave={hideArrow}>
            <Carousel
                interval={null}
                wrap={false}
                ref={carouselRef}
                controls={false}
                activeIndex={activeIndex}
                indicators={indicators}>
                {imageSourceList.map((imageSrc, idx) => (
                    <Carousel.Item key={idx}>
                        <img className='d-block w-100' src={imageSrc} alt={`${idx}`} />
                    </Carousel.Item>
                ))}
                {props.children}
            </Carousel>

            <button className={`carousel-prev ${isShowPrevArrow ? 'carousel-prev--active' : ''}`} onClick={handlePrev}>
                <img src={SliderRightArrow} alt='go back' />
            </button>
            <button className={`carousel-next ${isShowNextArrow ? 'carousel-next--active' : ''}`} onClick={handleNext}>
                <img src={SliderRightArrow} alt='go forward' />
            </button>
        </div>
    );
}

export default ProductImageCarousel;
