import axiosClient from "./axiosClient";

const orderRefundApi={
    getOrderRefund:(data)=>{
        const url = '/api/orderRefund';
        return axiosClient.get(`${url}/${data}`)
    },
    postOrderRefund:(data)=>{
        const url = '/api/orderRefund';
        return axiosClient.post(url,data)
    },
    updateOrderRefund:(data)=>{
        const url = '/api/orderRefund/uploadAttachment';
        const {id} = data;
        return axiosClient.put(`${url}/${id}`,data.file)
    }
}

export default orderRefundApi;