import {Approve, ApproveFile, Bill, CloseCircle, DeleteFile, Printer, Shipper} from 'assets/images';
import {calcTotalPage} from 'utils/calcTotalPage';
import {formatQuantity} from 'utils/formatQuantity';
import {orderActions} from './ViewOrdersContainer';

export default function propsProvider(props) {
    const {
        orderListTabs,
        activeOrderListTab,
        changeOrderListTab,
        adminViewOrders,
        pageSizeRange,
        adminOrderList,
        paginationData,
        setPage,
        setPageSize,
        submitFilterProduct,
        intl,
        navigateTo,
        setFilterData,
        handleOrderItemAction,
        shippingUnits,
        handleSelectAllClick,
        handleSelectRow,
        checkedData,
        activeSubTab,
        changeSubOrderListTab,
        subTabs
    } = props;
    const orderTypeDataCount = 6;
    const currentSubTab = subTabs[orderListTabs[activeOrderListTab].title];
    const headCells = [
        {
            code: 'products',
            label: intl.messages.product
        },
        {
            code: 'total_price',
            label: intl.messages.totalPrice
        },
        {
            code: 'created_at',
            label: intl.messages.dateOfOrder
        },
        {
            code: 'delivery',
            label: intl.messages.delivery
        },
        {
            code: 'status',
            label: intl.messages.status
        }
    ];
    const {page, pageSize} = paginationData[activeOrderListTab];
    const tableData = adminOrderList?.collection;
    const columnsData = new Array(orderTypeDataCount);
    for (var i = 0; i < columnsData.length; i++) {
        columnsData[i] = [];
    }
    const transformTableData = [];

    tableData?.forEach((data) => {
        const {
            order_information,
            extend_order_user,
            order_user_id,
            code,
            payment_method,
            created_at,
            shipping_unit_name,
            total_price,
            extend_order_product,
            status_code,
            id,
            sub_step_name,
            sub_step_code
        } = data;
        const {avatar, full_name, email, phone} = extend_order_user;
        const {extend_step} = order_information;
        const {name, color, text_color} = undefined || extend_step;
        let tableRowActions = [
            {
                title: intl.messages.seeDetail,
                icon: <Bill />,
                onClick: handleOrderItemAction,
                actionType: orderActions.detail
            }
        ];

        let buyer = {
                id: order_user_id,
                name: full_name || email || phone,
                avatar
            },
            order = {
                code,
                id,
                method: payment_method.name,
                status: extend_step
                    ? {
                          name: name === 'Hoàn thành' ? 'Đã giao' : name,
                          bgcolor: color,
                          color: text_color
                      }
                    : {
                          name: 'Chờ xác nhận',
                          bgcolor: '#fdf8ed',
                          color: '#ebbc46'
                      },
                createdAt: new Date(created_at).toLocaleDateString('vi-VN'),
                deliveryUnit: shipping_unit_name === 'Giao Hàng Nhanh' ? 'GHN' : shipping_unit_name,
                totalPrice: total_price,
                sub_step_name,
                sub_step_code,
                status_code: status_code === 'Done' ? 'Delivered' : status_code
            },
            products = [];

        for (var orderProduct of extend_order_product) {
            let type = orderProduct.product_type_value_text.split(',');
            type = type.map((e) => (e = e.split(': ')[1]));
            products.push({
                name: orderProduct.product_name,
                type,
                quantity: orderProduct.quantity,
                price: orderProduct.product_price,
                image: orderProduct.product_default_image
            });
        }

        switch (status_code) {
            case 'Unconfirm':
                tableRowActions = [
                    {
                        title: intl.messages.seeDetail,
                        icon: <Bill />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.detail
                    },
                    {
                        title: intl.messages.confirmOrder,
                        icon: <ApproveFile />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.confirmOrder
                    },
                    {
                        title: intl.messages.cancelOrder,
                        icon: <DeleteFile />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.cancel
                    }
                ];
                break;
            case 'Packing-Pending':
                tableRowActions = [
                    {
                        title: intl.messages.seeDetail,
                        icon: <Bill />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.detail
                    },
                    {
                        title: intl.messages.printOrder,
                        icon: <Printer />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.print
                    }
                ];

                if (sub_step_code === 'CONFIRMED') {
                    tableRowActions.splice(1, 0, {
                        title: intl.messages.prepareOrder,
                        icon: <ApproveFile />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.prepare
                    });
                } else {
                    tableRowActions.splice(1, 0, {
                        title: intl.messages.handOverForDelivery,
                        icon: <Shipper />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.handOver
                    });
                }
                break;
            case 'Delivery':
                tableRowActions = [
                    {
                        title: intl.messages.seeDetail,
                        icon: <Bill />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.detail
                    },
                    {
                        title: intl.messages.confirmDelivery,
                        icon: <Approve />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.confirmDelivered
                    },
                    {
                        title: intl.messages.deliveryFail,
                        icon: <CloseCircle />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.cancel
                    }
                ];
                break;
            case 'Refund':
                tableRowActions = [
                    {
                        title: intl.messages.seeDetail,
                        icon: <Bill />,
                        onClick: handleOrderItemAction,
                        actionType: orderActions.detail
                    }
                ];

                if (sub_step_code === 'REFUND_PENDING') {
                    tableRowActions = [
                        ...tableRowActions,
                        {
                            title: intl.messages.accept,
                            icon: <Approve className='svg-success' />,
                            onClick: handleOrderItemAction,
                            actionType: orderActions.accept
                        },
                        {
                            title: intl.messages.reject,
                            icon: <CloseCircle className='svg-danger' />,
                            onClick: handleOrderItemAction,
                            actionType: orderActions.reject
                        }
                    ];
                }
                break;
            default:
                break;
        }

        transformTableData.push({buyer, order, products, tableRowActions});
    });

    let headerActions = [];

    if (activeOrderListTab === 1) {
        headerActions = [
            {
                title: `${intl.messages.confirmOrder} (${formatQuantity(checkedData.length)})`,
                variant: 'half-contained',
                size: 'md',
                onClick: () => handleOrderItemAction({type: orderActions.confirmOrder, payload: checkedData})
            },
            {
                title: `${intl.messages.cancelOrder} (${formatQuantity(checkedData.length)})`,
                color: 'danger',
                variant: 'half-contained',
                size: 'md',
                onClick: () => handleOrderItemAction({type: orderActions.cancel, payload: checkedData})
            }
        ];
    } else if (activeOrderListTab === 2) {
        if (activeSubTab === 0) {
            headerActions = [
                {
                    title: `${intl.messages.printOrder} (${formatQuantity(checkedData.length)})`,
                    variant: 'half-contained',
                    size: 'md',
                    onClick: () => handleOrderItemAction({type: orderActions.print, payload: checkedData})
                },
                {
                    title: `${intl.messages.prepareOrder} (${formatQuantity(checkedData.length)})`,
                    size: 'md',
                    onClick: () => handleOrderItemAction({type: orderActions.prepare, payload: checkedData})
                }
            ];
        } else {
            headerActions = [
                {
                    title: intl.messages.handOverForDelivery,
                    size: 'md',
                    onClick: () => handleOrderItemAction({type: orderActions.handOver, payload: checkedData})
                }
            ];
        }
    } else if (activeOrderListTab === 3) {
        headerActions = [
            {
                title: intl.messages.deliveryFail,
                color: 'danger',
                variant: 'half-contained',
                size: 'md',
                onClick: () => handleOrderItemAction({type: orderActions.cancel, payload: checkedData})
            },
            {
                title: intl.messages.confirmDelivery,
                variant: 'half-contained',
                size: 'md',
                onClick: () => handleOrderItemAction({type: orderActions.confirmDelivered, payload: checkedData})
            }
        ];
    } else if (activeOrderListTab === 6) {
        if (activeSubTab === 0) {
            headerActions = [
                {
                    title: intl.messages.accept,
                    variant: 'half-contained',
                    size: 'md',
                    onClick: () => handleOrderItemAction({type: orderActions.accept, payload: checkedData})
                },
                {
                    title: intl.messages.reject,
                    color: 'danger',
                    variant: 'half-contained',
                    size: 'md',
                    onClick: () => handleOrderItemAction({type: orderActions.reject, payload: checkedData})
                }
            ];
        }
    }

    const totalRows = currentSubTab ? currentSubTab[0].total + currentSubTab[1].total : adminOrderList?.total;
    const currentTotalPage = currentSubTab ? currentSubTab[activeSubTab].total : adminOrderList?.total;

    return {
        viewOrderListProps: {
            intl,
            navigateTo,
            orderListTabs,
            activeOrderListTab,
            changeOrderListTab,
            totalRows,
            activeSubTab,
            changeSubOrderListTab,
            headerActions,
            subTabs,
            isShowHeaderActions: checkedData.length > 0,
            tableOrderListProps: {
                intl,
                pageSizeRange,
                headCells,
                transformTableData,
                totalPage: calcTotalPage(currentTotalPage, pageSize),
                currentPage: page,
                pageSize,
                setPage,
                setPageSize,
                isHavingCheckbox: headerActions.length > 0,
                handleSelectAllClick,
                handleSelectRow,
                checkedData
            }
        },
        filterBarProps: {
            intl,
            submitFilterProduct,
            setFilterData,
            shippingUnits
        }
    };
}
