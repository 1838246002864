import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { emptyBox } from 'assets/images';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import CustomPagination from 'components/Pagination/Pagination';
import React from 'react';
import AddUserRoleModal from './AddUserRoleModal/AddUserRoleModal';
import RoleModal from './RoleModal/RoleModal';
import UserRoleModal from './UserRoleModal/UserRoleModal';

const Configuration = (props) => {
    const {
        headCells,
        intl,
        tableData,
        setPage,
        setPageSize,
        pageSizeRange,
        totalPage,
        currentPage,
        pageSize,
        userRoleModalProps,
        addUserRoleModalProps,
        roleModalProps,
        modalContent,
    } = props;
    const isDataEmpty = tableData.length === 0;

    return (
        <div className='configuration'>
            <ModalPopup {...modalContent} />
            <UserRoleModal {...userRoleModalProps} />
            <AddUserRoleModal {...addUserRoleModalProps} />
            <RoleModal {...roleModalProps} />
            <TableContainer className='configuration-container'>
                <Table className='configuration-container__table' aria-labelledby='tableTitle'>
                    <CustomTableHead headCells={headCells} />
                    <TableBody className='table-body'>
                        {!isDataEmpty ? (
                            tableData.map((row, idx) => (
                                <TableRow key={idx}>
                                    {Object.entries(row).map(([key, data]) => (
                                        <TableCell {...data} key={key}>
                                            <span className='cell-value'>{data.value}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow className='table-row--empty'>
                                <TableCell colSpan={headCells.length + 1}>
                                    <img src={emptyBox} alt='empty data' />
                                    <span>{intl.messages.noDataFound}</span>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {!isDataEmpty && (
                    <div className='table-pagination'>
                        <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={currentPage} />
                        <CustomPageSize
                            label={intl.messages.rowsPerPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageSizeRange={pageSizeRange}
                            id={'temp'}
                        />
                    </div>
                )}
            </TableContainer>
        </div>
    );
};

export default Configuration;
