import React from 'react';

const WrapperContainer = (props) => {
    const {bodyContent = false, children, isPaddingOnMobile = true, className = ''} = props;

    return (
        <div className={`container ${isPaddingOnMobile ? 'mobile-padding' : ''} ${className}`}>
            <div className={`wrapper-container ${bodyContent ? 'body-content' : ''}`}>{children}</div>
        </div>
    );
};

export default WrapperContainer;
