import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import i18nLabels from './userManagement.i8n';
import UserManagementContainer from './UserManagementContainer';

const UserManagement = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userManagementProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...userManagementProps} childComponent={UserManagementContainer} />;
};

export default UserManagement;
