import { FormControl } from '@mui/material';
import { Edit } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ShopLogo from './ShopLogo';

const ShopInfoSection = (props) => {
    const { intl, shopInfo, handleSubmitShopInfo, isEdit, setIsEdit, brandLogo, handleUploadLogo } = props;

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: {
            businessRegisNumber: '',
            issueDate: '',
            taxCode: '',
            phoneNumber: '',
            email: '',
            address: '',
            shopDescription: ''
        }
    });
    const watchAllFields = watch();

    const shopInformations = [
        {
            label: intl.messages.businessRegisNumber,
            name: 'businessRegisNumber',
            disabled: !isEdit,
        },
        {
            label: intl.messages.issueDate,
            name: 'issueDate',
            type: 'date',
            disabled: !isEdit
        },
        {
            label: intl.messages.taxCode,
            name: 'taxCode',
            disabled: !isEdit
        },
        {
            label: intl.messages.emailLabel,
            name: 'email',
            disabled: !isEdit
        },
        {
            label: intl.messages.phoneNumberLabel,
            name: 'phoneNumber',
            type: 'number',
            disabled: !isEdit
        },
        {
            label: intl.messages.addressLabel,
            name: 'address',
            disabled: !isEdit
        },
        {
            label: intl.messages.shopDescriptionLabel,
            name: 'shopDescription',
            type: 'textarea',
            disabled: !isEdit
        }
    ];

    useEffect(() => {
        if (shopInfo) {
            setValue('businessRegisNumber', shopInfo?.business_registration_number);
            setValue('issueDate', moment(shopInfo?.registration_date).format('YYYY-MM-DD'));
            setValue('taxCode', shopInfo?.tax_code);
            setValue('email', shopInfo?.email);
            setValue('phoneNumber', shopInfo?.phone);
            setValue('address', shopInfo?.location);
            setValue('shopDescription', shopInfo?.description);
        }
    }, [shopInfo, isEdit]);

    return (
        <div className='shop-info'>
            <h2 style={{ fontSize: '23px', fontWeight: 500, color: '#000' }}>{intl.messages.shopInformationTitle}</h2>
            {!isEdit && <span
                style={{ float: 'right', cursor: 'pointer' }}
                onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                }}>
                <Edit />
            </span>}
            <p style={{ fontSize: '15px', color: '#808080' }}>{intl.messages.shopInformationSubtitle}</p>

            <ShopLogo intl={intl} brandLogo={brandLogo} handleUploadLogo={handleUploadLogo} />

            <form action='#' noValidate onSubmit={handleSubmit(handleSubmitShopInfo)}>
                <div className='row' style={{ margin: '16px 0' }}>
                    {shopInformations.map((field, idx) => {
                        const { name, label, type, ...fieldProps } = field;
                        return (
                            <Controller
                                key={name}
                                control={control}
                                name={name}
                                render={({ field }) => (
                                    <FormControl
                                        className={idx < shopInformations.length - 1 ? 'col-6 mb-3' : 'col'}
                                        fullWidth>
                                        <CustomInput
                                            {...field}
                                            type={type}
                                            label={label}
                                            valid={!errors[name]}
                                            hideFieldset={fieldProps.disabled}
                                            {...fieldProps}
                                        />
                                    </FormControl>
                                )}
                            />
                        );
                    })}
                    <div className='d-flex justify-content-end align-items-center mt-3'>
                        {isEdit && (
                            <>
                                <CustomButton
                                    variant='text'
                                    size='md'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsEdit(!isEdit);
                                    }}>
                                    Hủy
                                </CustomButton>
                                <CustomButton size='md' type='submit'>
                                    Lưu thay đổi
                                </CustomButton>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ShopInfoSection;
