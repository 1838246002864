import {Trash} from 'assets/images';
import React from 'react';

const PreviewImage = (props) => {
    const {image, onDeleteImage, height='64px', width='64px'} = props;

    return (
        <div className='preview-image' style={{height:height,width:width}}>
            <img src={image} alt='' />

            {onDeleteImage && (
                <div className='preview-image__wrapper'>
                    <Trash onClick={onDeleteImage} />
                </div>
            )}
        </div>
    );
};

export default PreviewImage;
