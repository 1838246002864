import axiosClient from "./axiosClient";
import axiosFormData from "./axiosFormData";

const chatRoomApi = {
    getAllMyChatRooms: (params) => {
        const url = '/api/chatroom/getAllMyChatrooms';
        return axiosClient.get(url, { params });
    },
    createChatRoom: () => {
        const url = '/api/chatroom/createChatroom';
        return axiosClient.post(url);
    },
    getChatRoom: (params) => {
        const url = '/api/chatroom/getChatroom';
        return axiosClient.get(url, { params });
    },
    sendTextMessage: (data) => {
        const { chatRoomId, message } = data;
        const url = `/api/chatroom/sendTextMessage/${chatRoomId}`;
        return axiosClient.post(url, message, { params: data });
    },
    markAsRead: (params) => {
        const { chatRoomId } = params;
        const url = `/api/chatroom/${chatRoomId}/maskAsRead`;
        return axiosClient.put(url, params, { params });
    },
    pinChat: (params) => {
        const { chatRoomId } = params;
        const url = `/api/chatroom/${chatRoomId}/pinChat`;
        return axiosClient.put(url, params, { params });
    },
    readChatRoomMessage: (params) => {
        const { chatRoomId } = params;
        const url = `/api/chatroom/${chatRoomId}/readChatroomMessage`;
        return axiosClient.put(url, params, { params });
    },
    uploadImageMessage: (data) => {
        const { chatRoomId, file } = data;
        const url = `/api/chatroom/chatRoomAttachments/${chatRoomId}/uploadImage`;
        return axiosFormData.post(url, file);
    },
};

export default chatRoomApi;