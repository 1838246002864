import axiosClient from './axiosClient';

const reportApi = {
    buyerPercentage: (params) => {
        const url = '/api/report/buyer-percentage';
        return axiosClient.get(url, {params});
    },
    userAccess: (params) => {
        const url = '/api/report/user-access';
        return axiosClient.get(url, {params});
    },
    orderStatistics: (params) => {
        const url = '/api/report/order-statistics';
        return axiosClient.get(url, {params});
    },
    productSales: (params) => {
        const url = '/api/report/product-statistics/sales';
        return axiosClient.get(url, {params});
    },
    productView: (params) => {
        const url = '/api/report/product-statistics/view';
        return axiosClient.get(url, {params});
    },
    conversionRate: (params) => {
        const url = '/api/report/product-statistics/conversion-rate';
        return axiosClient.get(url, {params});
    },
    productSold: (params) => {
        const url = '/api/report/product-statistics/product-solded';
        return axiosClient.get(url, {params});
    }
};

export default reportApi;
