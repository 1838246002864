import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber';
import Config from 'configuration';
import React, {useRef} from 'react';

const SubProductDetails = (props) => {
    const {
        displayProductType,
        isOpen,
        setQuantityDemand,
        quantityDemand,
        quantitySupply,
        intl,
        handleProductTypeChange,
        className = ''
    } = props;

    const quantityRef = useRef(null);

    const quantityDecrement = () => {
        setQuantityDemand(quantityDemand <= 1 ? 1 : quantityDemand - 1);
    };
    const quantityIncrement = () => {
        setQuantityDemand(quantityDemand + 1);
    };
    const changeQuantity = (e) => setQuantityDemand(parseInt(e.target.value));
    const blurQuantity = () => {
        if (quantityRef.current) {
            const value = quantityRef.current.childNodes[1].value;
            setQuantityDemand(value > quantitySupply ? quantitySupply : value < 1 ? 1 : value);
        }
    };

    return (
        <div className={`sub-product ${isOpen ? 'd-sm-block' : 'd-none d-lg-block'} ${className}`}>
            {displayProductType.length > 0 &&
                displayProductType.map((pType, pi) => (
                    <div className='sub-product__detail' key={pi}>
                        <span>{pType[0].group_name}</span>

                        <div className='detail-list'>
                            {pType.map((type) => (
                                <div
                                    className='detail-option'
                                    key={type.id}
                                    aria-selected={type.isActive}
                                    data-primary={pi === 0 && !!type.physical_path}
                                    onClick={() => handleProductTypeChange(pi, type.id)}>
                                    {pi === 0 && !!type.physical_path && (
                                        <img src={Config.apiConfig.endPoint + type.physical_path} alt='' />
                                    )}
                                    <span>{type.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            <div className='sub-product__detail align-items-center'>
                <span className='quantity-message'>{intl.messages.quantity} </span>
                <div className='detail-list'>
                    <CustomInputNumber
                        minValue={1}
                        maxValue={quantitySupply}
                        quantity={quantityDemand}
                        decrementHandle={quantityDecrement}
                        incrementHandle={quantityIncrement}
                        allowNegative={false}
                        handleQuantityChange={changeQuantity}
                        handleQuantityBlur={blurQuantity}
                        ref={quantityRef}
                    />
                    <div className='d-none d-sm-block'>
                        <span>{intl.messages.productLeft}</span>
                        <span> {quantitySupply} </span>
                        <span>{intl.messages.product}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubProductDetails;
