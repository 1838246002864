export default function propsProvider(props) {
    const {
        dispatch,
        navigateTo,
        intl,
        isOpenCategoryModal,
        addCategoryHandler,
        categories,
        closeCategoryModal,
        setShowAction,
        setRows,
        setSelectCategory,
        selectCategory,
        addChildCategory,
        updateCategory,
        deleteCategory,
        showAction,
        addRows,
        rows,
        removeRow,
        updateRow,
        updateGetCategory,
        isFormChanged,
        setIsFormChanged
    } = props;

    var categoryList = categories.map((item) => Object.assign({}, item, {edit: false}));

    const setLevel = (categories, level = 0) => {
        categories.forEach((category) => {
            category.level = level;
            if (category.attributes) {
                let isInherited = false;
                category.attributes = category.attributes.map((item, idx) => {
                    const itemJson = JSON.parse(item);

                    if (idx === 0) {
                        isInherited = itemJson.disabled;
                    }

                    return itemJson;
                });
                category.isInherited = isInherited ? isInherited : false;
            }
            if (category.children) {
                category.children = category.children.map((item) =>
                    Object.assign({}, item, {parentAttributes: category.attributes})
                );
                setLevel(category.children, level + 1);
            }
        });
    };
    setLevel(categoryList);

    const categoryListProps = {
        categoryList,
        intl,
        setShowAction,
        setRows,
        setSelectCategory,
        selectCategory,
        addChildCategory,
        updateCategory,
        deleteCategory,
        showAction,
        addRows,
        rows,
        removeRow,
        updateRow,
        isFormChanged,
        setIsFormChanged
    };

    return {
        dispatch,
        intl,
        categoryAddModalProps: {
            open: isOpenCategoryModal,
            intl,
            doClose: closeCategoryModal,
            dispatch,
            updateGetCategory
        },
        addCategoryHandler,
        categoryListProps
    };
}
