import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';

const TransportInformation = (props) => {
    const {intl, transportInfo} = props;

    return (
        <div className='payment-transport-information'>
            <div className='d-flex justify-content-between align-items-center'>
                <SectionTitle paled={false}>{intl.messages.deliveryInformation}</SectionTitle>
            </div>

            <div className='row'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.receiverInfo}</span>
                </div>
                <div className='col-8 col-sm-9'>
                    <div className='d-flex align-items-center'>
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500
                            }}>
                            {transportInfo?.receiverName} &nbsp;
                        </span>
                        <div
                            style={{
                                width: 4,
                                height: 4,
                                backgroundColor: '#c3c9d0',
                                borderRadius: '50%'
                            }}></div>
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500
                            }}>
                            &nbsp; {transportInfo?.receiverPhone}
                        </span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.receiverAddress}</span>
                </div>
                <div className='col-8 col-sm-9'>
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}>
                        {transportInfo?.receiverAddress}
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.deliveryCode}</span>
                </div>
                <div className='col-8 col-sm-9'>
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}>
                        {transportInfo?.billOfLandingCode || '--------'}
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-4 col-sm-3'>
                    <span style={{color: '#808080', fontSize: 15}}>{intl.messages.deliveryProcess}</span>
                </div>
                <div className='col-8 col-sm-9'>
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}>
                        -----------
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TransportInformation;
