import React from 'react';
import CustomTableHead from 'components/CustomTableHead/CustomTableHead';
import CustomPagination from 'components/Pagination/Pagination';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';

const TableSection = (props) => {
    const {
        page,
        pageSize,
        setPage,
        setPageSize,
        pageSizeRange,
        total,
        voucherColumns,
        voucherListData,
        intl,
        setIsOpen,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        deleteVoucher
    } = props;

    return (
        <>
            <ModalPopup
                title={isOpenDeleteModal === null ? null : isOpenDeleteModal.title}
                content={intl.messages.deleteConfirmContent}
                closeText={intl.messages.cancel}
                confirmText={intl.messages.delete}
                onClose={() => setIsOpenDeleteModal(null)}
                onConfirm={() => deleteVoucher()}
            />
            <div className='voucher__list__table'>
                <div className='voucher__list__table__title'>
                    <h2>{intl.messages.voucherList}</h2>
                    <CustomButton
                        className='voucher__list__table__title__add'
                        variant='contained'
                        onClick={() => {
                            setIsOpen(true);
                        }}>
                        <AddIcon /> {intl.messages.addNew}
                    </CustomButton>
                </div>
                <TableContainer className='voucher__list__table__container'>
                    <Table>
                        <CustomTableHead
                            handleCheckAll={() => { }}
                            headCells={voucherColumns}
                            rowCount={voucherListData.length}
                        />
                        <TableBody key={'Table'}>
                            {voucherListData.length > 0 ? (
                                voucherListData.map((row, idx) => (
                                    <TableRow key={idx}>
                                        {Object.keys(row).map((key) => (
                                            <TableCell key={key} align={key !== 'name' ? 'center' : 'left'}>
                                                {row[key]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={voucherColumns.length}>
                                        <EmptyTableData label={intl.messages.noDataFound} />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <div className='voucher__list__table__pagination'>
                        <CustomPagination
                            setPage={setPage}
                            totalPage={parseInt(Math.ceil(total / pageSize))}
                            currentPage={page}
                        />
                        <CustomPageSize
                            label={intl.messages.rowsPerPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageSizeRange={pageSizeRange}
                            id={'temp'}
                        />
                    </div>
                </TableContainer>
            </div>
        </>
    );
};

export default TableSection;
