import {Checkbox, FormControlLabel} from '@mui/material';
import {couponFrame, deleteRedBtn, Voucher} from 'assets/images';
import React from 'react';
import ProductBlock from './ProductBlock';
import {FormattedNumber} from 'react-intl';
import {formatThousands} from 'utils/formatThousands';

const StallBlock = (props) => {
    const {stall, ...productProps} = props;
    const {intl, deleteItemsHandler, voucherHandler, checkItemsHandler, appliedVoucherValue} = productProps;
    const {stallId, stallName, items, isChecked = false} = stall;

    const renderedProductBlock = [];

    if (items.size > 0) {
        items.forEach((value, key) => {
            renderedProductBlock.push(<ProductBlock product={value} key={key} stallId={stallId} {...productProps} />);
        });
    }

    return (
        <div className={`cart-detail__stall ${isChecked ? 'stall-checked' : ''}`}>
            <div className='stall-header'>
                <div className='stall-header__title'>
                    <FormControlLabel
                        label={stallName}
                        control={
                            <Checkbox
                                name={stallName}
                                id={stallId}
                                value={stallId}
                                checked={isChecked}
                                onChange={() => checkItemsHandler({type: 'stall', payload: stallId})}
                            />
                        }
                    />
                </div>
                <button
                    className='stall-header__delete'
                    onClick={() => deleteItemsHandler({type: 'stall', payload: stallId})}>
                    <img src={deleteRedBtn} alt='delete stall' />
                </button>
            </div>

            {renderedProductBlock}

            <div className='stall-voucher'>
                <span
                    onClick={() => {
                        voucherHandler(stallId);
                    }}>
                    <Voucher />
                    {intl.messages.shopVoucher}
                </span>
                {appliedVoucherValue > 0 && (
                    <div className='stall-voucher__applied'>
                        <img src={couponFrame} alt={appliedVoucherValue} />
                        <span>
                            {intl.messages.decrease} {formatThousands(Math.round(appliedVoucherValue))}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StallBlock;
