import { emptyBox } from 'assets/images';
import React from 'react';

const EmptyTableData = ({ label }) => {
	return (
		<div className='empty-table'>
			<img src={emptyBox} alt='empty data' />
			<span>{label}</span>
		</div>
	);
};

export default EmptyTableData;