import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {CloseGray} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import React from 'react';

const UserRoleModal = (props) => {
    const {intl, userRoleHeadCells, userRoleTableData = [], open, doClose} = props;
    const isDataEmpty = userRoleTableData.length === 0;

    return (
        <Dialog
            maxWidth='md'
            fullWidth={true}
            open={open}
            onClose={doClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className='dialog dialog-user-role'>
            <DialogTitle className='dialog-title' id='alert-dialog-title'>
                <span>{intl.messages.userRoleList}</span>
                <button className='dialog-title__close'>
                    <CloseGray onClick={doClose} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content'>
                <div className='dialog-content__container'>
                    <div className='role-table' role='table'>
                        <div className='role-table__header'>
                            {userRoleHeadCells.map((headCell, idx) => (
                                <div className={`data-col data-col-${idx}`} key={idx}>
                                    {headCell.label}
                                </div>
                            ))}
                        </div>

                        <div className='role-table__body'>
                            {!isDataEmpty ? (
                                <div className='body-content'>
                                    {userRoleTableData.map((data, idx) => (
                                        <div className='content-row' key={idx}>
                                            {Object.entries(data).map(([key, tableData], i) => (
                                                <div
                                                    className={`data-col data-col-${i}`}
                                                    key={key}
                                                    onClick={tableData.onClick}>
                                                    {tableData.value}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <EmptyTableData label={intl.messages.noDataFound} />
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <CustomButton size='md' variant='text' onClick={doClose}>
                    {intl.messages.close}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default UserRoleModal;
