import { Plus, WhitePlus } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import React from 'react';
import DeleteUnitModal from './subViews/DeleteUnitModal';
import ShippingFormModal from './subViews/ShippingFormModal';
import ShippingUnitsTable from './subViews/ShippingUnitsTable';

const DeliverySettingMainView = (props) => {
    const {intl, shippingUnitsTableProps, shippingFormModalProps, handleOpenModal, deleteUnitModalProps} = props;

    return (
        <div className='delivery-setting'>
            <ShippingFormModal {...shippingFormModalProps} />
            <DeleteUnitModal {...deleteUnitModalProps} />
            <div className="delivery-setting__header">
                <h2 className='delivery-setting__header-title'>{intl.messages.shippingUnitList}</h2>
                <CustomButton size='md' onClick={() => handleOpenModal()} >
                    <img src={WhitePlus} alt="plus" />
                    {intl.messages.addNew}
                </CustomButton>
            </div>
            <ShippingUnitsTable {...shippingUnitsTableProps}/>
        </div>
    );
};

export default DeliverySettingMainView;
