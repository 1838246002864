import axiosClient from './axiosClient';

const homeApi = {
    getRecommendList: () => {
        const url = '/api/products/recommendProductList';
        return axiosClient.get(url);
    },

    getProductImagesById: (id) => {
        const url = `/api/productImages/getAllProductImageByProductID?id=${id}`;
        return axiosClient.get(url);
    },
    getBanners: () => {
        const url = '/api/banner?Sorts=sort_order';
        return axiosClient.get(url);
    }
};

export default homeApi;
