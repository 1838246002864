import {authSelector, homeSelector} from 'app/selectors';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import HomeContainer from './HomeContainer';
import i18nLabels from './home.i18n';
import {useHistory, useLocation} from 'react-router-dom';
import DecorateContainer from 'components/DecorateContainer';
import {confirmMomoPayment, confirmVNPayPayment} from 'features/Payment/paymentSlice';
import {pagePath} from 'configuration/routeConfig';
import Config from 'configuration';
import { Toast } from 'utils/toast';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

export default function Home(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const paymentMethod = localStorage.getItem('paymentMethod');

    const paymentConfirm = async () => {
        switch (paymentMethod) {
            case 'VNPAY': {
                const payload = {
                    vnp_Amount: urlParams.get('vnp_Amount'),
                    vnp_BankCode: urlParams.get('vnp_BankCode'),
                    vnp_BankTranNo: urlParams.get('vnp_BankTranNo'),
                    vnp_CardType: urlParams.get('vnp_CardType'),
                    vnp_OrderInfo: urlParams.get('vnp_OrderInfo'),
                    vnp_PayDate: urlParams.get('vnp_PayDate'),
                    vnp_ResponseCode: urlParams.get('vnp_ResponseCode'),
                    vnp_TmnCode: urlParams.get('vnp_TmnCode'),
                    vnp_TransactionNo: urlParams.get('vnp_TransactionNo'),
                    vnp_TransactionStatus: urlParams.get('vnp_TransactionStatus'),
                    vnp_TxnRef: urlParams.get('vnp_TxnRef'),
                    vnp_SecureHash: urlParams.get('vnp_SecureHash')
                };
                const res = await dispatch(confirmVNPayPayment(payload)).unwrap();
                const {success, message} = res;
                if(success) {
                    localStorage.removeItem('paymentMethod');
                    Toast(success, message);
                    sessionStorage.removeItem(Config.storageKey.cart);
                    window.location.replace(pagePath.thankYouUrl);
                }
                else {
                    localStorage.removeItem('paymentMethod');
                    Toast(success, message);
                }
                break;
            }
            case 'MOMO': {
                const payload = {
                    partnerCode: urlParams.get('partnerCode'),
                    accessKey: urlParams.get('accessKey'),
                    requestId: urlParams.get('requestId'),
                    amount: urlParams.get('amount'),
                    orderId: urlParams.get('orderId'),
                    orderInfo: urlParams.get('orderInfo'),
                    orderType: urlParams.get('orderType'),
                    transId: urlParams.get('transId'),
                    message: urlParams.get('message'),
                    localMessage: urlParams.get('localMessage'),
                    responseTime: urlParams.get('responseTime'),
                    errorCode: urlParams.get('errorCode'),
                    payType: urlParams.get('payType'),
                    extraData: urlParams.get('extraData'),
                    signature: urlParams.get('signature')
                };
                const res = await dispatch(confirmMomoPayment(payload)).unwrap();
                const {message} = res;
                if(message === "Success") {
                    localStorage.removeItem('paymentMethod');
                    sessionStorage.removeItem(Config.storageKey.cart);
                    window.location.replace(pagePath.thankYouUrl);
                }
                else {
                    localStorage.removeItem('paymentMethod');
                    Toast(false, message);
                }
                break;
            }
            default:
                return;
        }
        
    };

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Home page');
    }, [])

    useEffect(() => {
        if (location && paymentMethod) {
            paymentConfirm();
        }
    }, [location, paymentMethod]);

    const homeProps = {
        ...props,
        i18nLabels,
        dispatch,
        home,
        auth,
        history
    };
    return <DecorateContainer {...homeProps} childComponent={HomeContainer} />;
}
