import { authSelector, myPurchaseSelector } from 'app/selectors';
import { Help, OrderConfirmed, Profile, Setting } from 'assets/images';
import Config from 'configuration';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveTab, getMyOrders } from '../MyPurchase/myPurchaseSlice';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import { pagePath } from 'configuration/routeConfig';
import { Bell, CardHeart, Clipboard, Connection, Voucher } from 'assets/images';
import MobileNavAccordion from './MobileNavAccordion';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

const MobileLayout = (props) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const { myOrderList } = useSelector(myPurchaseSelector);
    const { permission } = auth;
    const { avatar, full_name, email, phone } = auth.userLoginInfo;

    const navigateTo = (path) => {
        history.push(path);
    };

    const orderTabs = useMemo(() => {

        if (myOrderList?.sum) {
            const { sum_un_confirm, sum_packing_pending, sum_delivering } = myOrderList.sum;

            return [
                {
                    icon: <PaymentIcon />,
                    title: intl.messages.waitingForConfirming,
                    quantity: sum_un_confirm,
                    onClick: () => dispatch(changeActiveTab(1))
                },
                {
                    icon: <OrderConfirmed />,
                    title: intl.messages.confirmed,
                    quantity: sum_packing_pending,
                    onClick: () => dispatch(changeActiveTab(2))
                },
                {
                    icon: <LocalShippingOutlinedIcon />,
                    title: intl.messages.delivering,
                    quantity: sum_delivering,
                    onClick: () => dispatch(changeActiveTab(3))
                },
                { icon: <StarOutlineIcon />, title: intl.messages.review, quantity: 0 }
            ];
        } else return [];
    }, [intl.messages, myOrderList]);

    const mobileNavList = useMemo(() => {
        const navSectionList = [
            [
                {
                    title: intl.messages.favoriteProduct,
                    icon: <CardHeart />,
                    to: pagePath.userMyLikeProductUrl
                },
                {
                    title: intl.messages.voucherStorage,
                    icon: <Voucher />,
                    to: pagePath.userVoucherUrl
                },
                {
                    title: intl.messages.myOrder,
                    to: pagePath.userMyPurchaseUrl,
                    icon: <Clipboard />
                },
                {
                    title: intl.messages.notification,
                    icon: <Bell />,
                    items: [
                        {
                            title: intl.messages.updateOrder,
                            to: pagePath.userNotiOrderUrl
                        },
                        {
                            title: intl.messages.voucher,
                            to: pagePath.userNotiPromotionUrl
                        },
                        {
                            title: intl.messages.other,
                            to: pagePath.userNotiOtherUrl
                        }
                    ]
                }
            ],
            [
                {
                    title: intl.messages.setting,
                    icon: <Setting />,
                    items: [
                        {
                            title: intl.messages.myProfile,
                            to: pagePath.userProfileUrl
                        },
                        {
                            title: intl.messages.myAddressList,
                            to: pagePath.userAddressUrl
                        }
                    ]
                },
                {
                    title: intl.messages.supportCenter,
                    icon: <Help />
                }
            ]
        ];

        if (Array.isArray(permission) && permission.length > 0) {
            navSectionList.splice(1, 0, [
                {
                    title: intl.messages.sellerPage,
                    icon: <Connection />,
                    isExpanded: false,
                    to: pagePath.adminUrl
                }
            ]);
        }

        return navSectionList;
    }, [intl.messages, permission]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        dispatch(getMyOrders({ signal }));

        return () => controller.abort();
    }, []);

    return (
        <div className='mobile-layout d-block d-sm-none'>
            <div className='mobile-layout__header'>
                <img src={avatar ? Config.apiConfig.endPoint + avatar : Profile} alt='' />
                <p className='header-name'>{full_name || email || phone}</p>
            </div>

            <div className='mobile-layout__body'>
                <div className='order-tabs'>
                    {orderTabs.map((tab, idx) => (
                        <div
                            className='tab-item'
                            key={idx}
                            onClick={() => {
                                if (tab.onClick) {
                                    tab.onClick();
                                    navigateTo(pagePath.userMyPurchaseUrl);
                                }
                            }}>
                            <div className='tab-item__icon'>
                                {tab.icon}
                                {tab.quantity > 0 && <span>{tab.quantity > 9 ? '9+' : tab.quantity}</span>}
                            </div>

                            <div className='tab-item__title'>
                                <p>{tab.title}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {mobileNavList.map((sectionList, idx) => (
                    <section className='body-section' key={idx}>
                        {sectionList.map((nav) => (
                            <MobileNavAccordion nav={nav} key={nav.title} navigateTo={navigateTo} />
                        ))}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default MobileLayout;
