import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { close, ErrorMark } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';

const DeleteAddressModal = (props) => {
    const { intl, isOpenDeleteAddressModal, closeDeleteAddressModal, onDeleteUserAddress } = props;

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={isOpenDeleteAddressModal}
            onClose={closeDeleteAddressModal}
            aria-labelledby='voucher-modal'
            aria-describedby='apply-voucher-to-get-discount'
            className='dialog'>
            <DialogTitle className='dialog-title' id='voucher-modal'>
                <span>Xác nhận</span>
                <button className='dialog-title__close'>
                    <img src={close} alt='' onClick={closeDeleteAddressModal} />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content'>
                <div className='d-flex justify-content-center align-items-center gap-2 modal-delete'>
                    <ErrorMark />
                    Bạn có chắc chắn xóa địa chỉ này?
                </div>
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <CustomButton size='md' variant='text' onClick={() => closeDeleteAddressModal()}>
                    Hủy
                </CustomButton>
                <CustomButton
                    size='md'
                    color={'danger'}
                    onClick={() => onDeleteUserAddress()}>
                    Xóa
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAddressModal;
