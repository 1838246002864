import React, {useEffect, useState} from 'react';
import {FormattedNumber} from 'react-intl';
import {FormControl, InputAdornment, OutlinedInput} from '@mui/material';
import {edit, dublicate, deleteIcon} from 'assets/images';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
export const ItemCard = ({
    data,
    handleChildCheckboxChange,
    selectedItems,
    item,
    allDiscount,
    allItemDiscount,
    isEdit,
    flashDealEditData,
    isDublicate,
    setDeleteId
}) => {
    const [discount, setDiscount] = useState(0);
    const [amountItemDiscount, setAmountItemDiscount] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    useEffect(() => {
        // const dPrice = data.origin_price - (data.origin_price * discount) / 100;
        // setDiscountPrice(data.origin_price - (data.origin_price * discount) / 100);
        data.discount_price = discount;
        data.price = discountPrice;
    }, [discount]);
    useEffect(() => {
        data.sale_quantity = amountItemDiscount;
    }, [amountItemDiscount]);
    useEffect(() => {
        if (allDiscount && selectedItems.includes(data.id)) {
            const price = calculatePriceFromPercentage(allDiscount);
            setDiscount(allDiscount);
            setDiscountPrice(price);
        }
        if (allItemDiscount && selectedItems.includes(data.id)) {
            setAmountItemDiscount(allItemDiscount);
        }
    }, [allDiscount, allItemDiscount]);
    useEffect(() => {
        if (isEdit || isDublicate) {
            const editdata = JSON.parse(flashDealEditData.product_list);
            editdata?.map((e) => {
                if (e.extend_product_type.length) {
                    e.extend_product_type.map((h) => {
                        if (h.id === data.id) {
                            setDiscount(h.discount_price);
                            setDiscountPrice(h.price);
                            setAmountItemDiscount(h.sale_quantity);
                        }
                    });
                } else {
                    if (data.id === e.id) {
                        setDiscount(e.discount_price);
                        setDiscountPrice(e.price);
                        setAmountItemDiscount(e.sale_quantity);
                    }
                }
            });
        }
    }, []);
    const calculatePriceFromPercentage = (percentage) => {
        // Implement your logic to calculate discount price from discount percentage
        // For example, if the discount percentage is 20% and the original price is $100,
        // the discount price would be $80 (i.e., 100 - (100 * 0.2))
        const originalPrice = data.origin_price; // Assuming the original price is 100
        return originalPrice - originalPrice * (percentage / 100);
    };

    const calculatePercentageFromPrice = (price) => {
        const originalPrice = data.origin_price; // Assuming the original price is 100
        const discountPercentage = ((originalPrice - price) / originalPrice) * 100;
        return Math.round(discountPercentage + (discountPercentage >= 0 ? 0.5 : -0.5));
    };

    const formatCurrency = (value) => {
        // Implement your logic to format the numeric value as currency
        // For example, you can use Intl.NumberFormat to format the value with
        // the desired currency symbol, separators, and decimal places
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'VND', // Assuming the currency is USD
            minimumFractionDigits: 2 // Assuming 2 decimal places
        }).format(value);
        return formattedValue;
    };
    const handleDiscountPriceChange = (e) => {
        const price = e.target.value;
        // Use regex to remove non-numeric characters and decimal places from the input
        const numericPrice = price.replace(/[^\d]/g, '');
        // Remove any decimal places from the numeric price
        const integerPrice = parseInt(numericPrice);
        // Check if the integer price is greater than the original price
        if (isNaN(integerPrice) || integerPrice > data.origin_price) {
            return;
        }
        // Format the numeric price with currency symbols and separators
        const formattedPrice = formatCurrency(integerPrice);
        setDiscountPrice(integerPrice);
        const percentage = calculatePercentageFromPrice(integerPrice);
        setDiscount(percentage);
    };

    const handleDiscountPercentageChange = (e) => {
        const percentage = e.target.value;
        setDiscount(percentage);
        const price = calculatePriceFromPercentage(percentage);
        setDiscountPrice(price);
    };
    return (
        <div className='after-row'>
            <div className='col-checkbox'>
                <CustomCheckbox
                    disableRipple
                    color='primary'
                    checked={selectedItems.includes(data.id)}
                    onChange={() => handleChildCheckboxChange(data.id, item.id)}
                    inputProps={{
                        'aria-label': 'select all data'
                    }}
                />
            </div>
            <div className='col-classified'>
                {data.id === item.id
                    ? '--'
                    : `${data.type1?.name ? data.type1?.name : ''}${data.type2?.name ? ` - ${data.type2?.name}` : ''}`}
            </div>
            <div className='col-price'>
                {<FormattedNumber style='currency' currency='VND' value={data.origin_price}></FormattedNumber>}
            </div>
            <div className='col-discountPrice'>
                {
                    /* {<FormattedNumber style='currency' currency='VND' value={discountPrice}></FormattedNumber>} */
                    <FormControl size='small' className='promotion-input' variant='outlined'>
                        <OutlinedInput
                            type='text'
                            endAdornment={<InputAdornment position='end'>đ</InputAdornment>}
                            value={discountPrice}
                            onChange={handleDiscountPriceChange}
                            inputProps={{
                                'aria-label': 'select all data'
                            }}></OutlinedInput>
                    </FormControl>
                }
            </div>
            <div className='col-promotion'>
                {
                    <FormControl size='small' className='promotion-input' variant='outlined'>
                        <OutlinedInput
                            type='number'
                            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                            value={discount}
                            onChange={handleDiscountPercentageChange}
                            inputProps={{
                                'aria-label': 'select all data'
                            }}></OutlinedInput>
                    </FormControl>
                }
            </div>
            <div className='col-ammountPromotion'>
                {
                    <FormControl size='small' className='ammountPromotion-input' variant='outlined'>
                        <OutlinedInput
                            type='number'
                            value={amountItemDiscount}
                            onChange={(e) => {
                                if (e.target.value <= data.quantity) setAmountItemDiscount(e.target.value);
                            }}
                            inputProps={{
                                'aria-label': 'select all data'
                            }}></OutlinedInput>
                    </FormControl>
                }
            </div>
            <div className='col-storage'>{data.quantity}</div>
            <div className='col-action'>
                <img src={deleteIcon} onClick={() => setDeleteId(data.id)}></img>
            </div>
        </div>
    );
};
