import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {RightArrow} from 'assets/images';
import React, {useState} from 'react';

const MobileNavAccordion = (props) => {
    const {nav, navigateTo} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const {title, items, to, icon} = nav;
    const haveSubItems = items?.length > 0;

    return (
        <Accordion
            key={title}
            className='mobile-accordion m-0'
            expanded={isOpen}
            onChange={() => {
                if (haveSubItems) toggleAccordion(title);
            }}>
            <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                className='mobile-accordion__summary'
                expandIcon={<RightArrow />}
                onClick={() => {
                    if (to) {
                        navigateTo(to);
                    }
                }}>
                <div className={`summary-content ${nav.isActive ? 'active' : ''}`}>
                    {icon}
                    <span>{title}</span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {items?.map((item, idx) => {
                    const {to, title, onClickAction} = item;

                    return (
                        <div
                            className='mobile-subItem'
                            key={idx}
                            onClick={() => {
                                navigateTo(to);

                                if (onClickAction) onClickAction();
                            }}>
                            <span>{title}</span>
                            <RightArrow />
                        </div>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
};

export default MobileNavAccordion;
