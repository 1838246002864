import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import i18nLabels from './notificationSetting.i18n';
import NotificationSettingContainer from './NotificationSettingContainer';

const NotificationSetting = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const notificationSettingProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...notificationSettingProps} childComponent={NotificationSettingContainer} />;
};

export default NotificationSetting;
