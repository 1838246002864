import propsProvider from './myRefundPropsProvider';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import MyRefundMainView from './template/MyRefundMainView';
import {useParams} from 'react-router-dom';
import {getOrderById, postOrderRefund, updateOrderRefund, getOrderRefund} from './myRefundSlice';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import {useForm} from 'react-hook-form';
import {useRef} from 'react';
import {proceedOrderStep} from 'features/Admin/template/subViews/Order/OrderDetail/OrderDetailSlice';
import {
    addNotificationWithStatus,
    sendPushNotification
} from 'features/Admin/template/subViews/ShopSetting/NotificationSetting/NotificationSettingSlice';
import {pagePath} from 'configuration/routeConfig';
import {getUserDetail} from 'features/UserProfile/UserProfileSlice';

const defaultValues = {
    note: '',
    email: ''
};

const MyRefundContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();
    const lstTemp = [
        {reason: intl.messages.reason1, checked: false, index: 0, code: 'miss'},
        {reason: intl.messages.reason2, checked: false, index: 1, code: 'lost'},
        {reason: intl.messages.reason3, checked: false, index: 2, code: 'forgot'},
        {reason: intl.messages.anotherReason, checked: false, index: 3, code: 'another'}
    ];
    const [checked, setChecked] = useState(false);
    const {orderId} = useParams();
    const [detailRefund, setDetailRefund] = useState();
    const [orderDetail, setOrderDetail] = useState();
    const [orderProducts, setOrderProducts] = useState([]);
    const [amountRefund, setAmountRefund] = useState(0);
    const [productImages, setProductImages] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const fileInputRef = useRef(null);
    const [reasonRefund, setReasonRefund] = useState('');
    const [isOpenReasonModal, setIsOpenReasonModal] = useState(false);
    const [lstReason, setLstReason] = useState(lstTemp);
    const {control, getValues} = useForm({defaultValues});
    const [justSubmitSuccessfully, setJustSubmitSuccessfully] = useState(false);

    const navigateTo = () => {
        history.push('/user/my-purchase');
    };
    const handleSelectRow = (e) => {
        setChecked(e.target.checked);
        const lstOrder = [...orderProducts];
        const tempData = [];
        lstOrder.forEach((item) => {
            item.isChecked = true;
            tempData.push(item);
        });
        setOrderProducts(tempData);
    };
    const handleSelectItem = (e, item) => {
        const newProduct = [];
        orderProducts.forEach((element) => {
            if (element.product_id === item.product_id) {
                newProduct.push({...element, isChecked: e.target.checked});
            } else {
                newProduct.push(element);
            }
        });
        setOrderProducts(newProduct);
    };
    const handleSelectReason = (e, item) => {
        const tempData = [...lstReason];
        const tempChecked = {...lstReason[item.index], checked: e.target.checked};
        tempData.splice(item.index, 1, tempChecked);
        setLstReason(tempData);
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };

    const validateRequired = async () => {
        const is_checked = await orderProducts.findIndex((item) => item.isChecked);
        const areSelectedReason = await lstReason.findIndex((item) => item.checked);
        if (is_checked < 0) {
            Toast(false, intl.messages.errorChoose);
            return true;
        }
        if (areSelectedReason < 0) {
            Toast(false, intl.messages.errorReason);
            return true;
        }
        if (productImages.length <= 0) {
            Toast(false, intl.messages.errorAttachment);
            return true;
        }
        if (!getValues('note').trim()) {
            Toast(false, intl.messages.errorNote);
            return true;
        }
        if (!getValues('note').trim()) {
            Toast(false, intl.messages.errorEmail);
            return true;
        }
    };

    const handleSubmitRefund = async () => {
        const checkRequired = await validateRequired();
        if (checkRequired) {
            return;
        }

        const params = {
            order_id: orderId,
            reason: reasonRefund,
            note: getValues('note'),
            money_refund: amountRefund,
            send_email: getValues('email')
        };

        const sendNoti = async (summary, details) => {
            let notificationParams = {
                approvedStatusCode: 'SENT'
            };
            const res = await dispatch(
                addNotificationWithStatus({
                    notificationParams,
                    data: {
                        summary: `${summary} ${orderDetail?.order_information?.order_code}`,
                        details: `${intl.messages.order} ${details}`,
                        user_id: orderDetail?.order_receive_user?.user_id,
                        reference_link: `${pagePath.userMyPurchaseUrl}/${orderId}`
                    }
                })
            ).unwrap();
            if (res?.success) {
                const userRes = await dispatch(getUserDetail({id: orderDetail?.order_receive_user?.user_id})).unwrap();
                if (userRes?.success) {
                    await dispatch(
                        sendPushNotification({
                            id: res?.data?.id,
                            fcmClientToken: userRes?.data?.fcm_client_token || ''
                        })
                    ).unwrap();
                }
            }
        };

        const res = await dispatch(postOrderRefund(params)).unwrap();
        const {success, data} = res;

        if (success) {
            await sendNoti(intl.messages.updateOrderStatus, intl.messages.refundOrder);
            handleUploadFile(data.id);
            setDetailRefund(data);
        }
    };

    const handleUploadFile = async (id) => {
        for (const index in productImages) {
            const fileFormData = new FormData();
            const file = productImages[index].file;
            if (file) {
                fileFormData.append('file', file);
                await dispatch(updateOrderRefund({id: id, file: fileFormData})).unwrap();
                setJustSubmitSuccessfully(true);
            }
        }
        await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Refund'}));
    };

    const handleUpload = async (e) => {
        const files = e.target.files;
        const previewImageUrls = [];
        let isOverSize = false;
        for (const file of files) {
            const {size} = file;

            if (size > 5 * 1024 * 1024) {
                isOverSize = true;
                return;
            }
            const result = await getBase64(file);
            previewImageUrls.push({url: result, file});
        }
        const newPreviewImageUrls = [...productImages, ...previewImageUrls];
        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else if (newPreviewImageUrls.length > 9) {
            Toast(false, intl.messages.overLimit);
        } else {
            setProductImages(newPreviewImageUrls);
        }
    };

    const handleDeleteProductImage = (id) => {
        const newImages = [];
        productImages.forEach((item, index) => {
            if (id !== index) {
                newImages.push(item);
            }
        });
        setProductImages(newImages);
    };

    const handleSubmitReason = () => {
        let stringReason = '';
        lstReason.forEach((item) => {
            if (item.checked) {
                stringReason += item.reason + ', ';
            }
        });
        setReasonRefund(stringReason);
        setIsOpenReasonModal(false);
    };

    const openEditEmail = () => {
        setIsDisabled(!isDisabled);
    };
    const openReasonModal = () => {
        setIsOpenReasonModal(true);
    };
    const closeReasonModal = () => {
        setIsOpenReasonModal(false);
    };

    function checkSamePropertyValue(arr, prop) {
        const firstValue = true;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][prop] !== firstValue) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        setChecked(checkSamePropertyValue(orderProducts, 'isChecked'));
        let totalAmount = 0;
        orderProducts.forEach((item) => {
            if (item.isChecked) {
                totalAmount += item.product_price * item.quantity;
            }
        });
        setAmountRefund(totalAmount);
    }, [orderProducts]);

    useEffect(() => {
        // const controller = new AbortController();
        // const {signal} = controller;
        // const data = {
        //     Filters:''
        // }
        dispatch(getOrderRefund(orderId)).then((res) => {
            setDetailRefund(res.payload.data);
        });
        dispatch(getOrderById(orderId)).then((res) => {
            const {success} = res.payload;
            const {data} = res.payload;
            const {extend_order_product} = res.payload.data;
            if (success) {
                setOrderDetail({...data});
                const tempProduct = [];
                extend_order_product.forEach((element) => {
                    tempProduct.push({...element, isChecked: true});
                });
                setOrderProducts(tempProduct);
            }
        });
    }, [orderId, dispatch]);

    const myRefundContainerProps = {
        intl,
        checked,
        orderDetail,
        orderProducts,
        amountRefund,
        control,
        fileInputRef,
        productImages,
        isDisabled,
        handleSelectRow,
        handleSelectItem,
        handleUpload,
        handleClick,
        handleDeleteProductImage,
        openEditEmail,
        handleSubmitRefund,
        closeReasonModal,
        reasonRefund,
        isOpenReasonModal,
        openReasonModal,
        handleSelectReason,
        lstReason,
        handleSubmitReason,
        navigateTo,
        detailRefund,
        justSubmitSuccessfully
    };

    return <MyRefundMainView {...propsProvider(myRefundContainerProps)} />;
};

export default MyRefundContainer;
