import {async} from '@firebase/util';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import cartProductApi from 'api/cartProductApi';
import voucherApi from 'api/voucherApi';
import serviceRequest from 'app/serviceRequest';
import Config from 'configuration';
import {checkObjectEmpty} from 'utils/checkObjectEmpty';

const initialState = () => {
    const storeCart = sessionStorage.getItem(Config.storageKey.cart);
    if (storeCart) {
        return {...JSON.parse(storeCart)};
    }

    return {
        cartList: [],
        checkedCartItemStates: {},
        voucherList: []
    };
};

export const getCartProducts = createAsyncThunk('cart/getProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.getCartProducts
    });
});

export const addCartProducts = createAsyncThunk('cart/addProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.addCartProducts,
        payload: data
    });
});

export const updateCartProducts = createAsyncThunk('cart/updateProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.updateCartProducts,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const deleteCartProducts = createAsyncThunk('cart/deleteProducts', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.deleteCartProducts,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

export const deleteFavCartProducts = createAsyncThunk('cart/deleteFavProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: cartProductApi.deleteFavCartProducts,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getVoucher = createAsyncThunk('cart/voucher', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucher,
        payload: id,
        options: {
            skipLoader: true
        }
    });
});

export const getVoucherList = createAsyncThunk('cart/voucherList', async (id, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucherList,
        payload: id
    });
});

export const applyVoucher = createAsyncThunk('cart/voucher/apply', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.applyVoucher,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const cancelVoucher = createAsyncThunk('cart/voucher/cancel', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.cancelVoucher,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        handleCheckedItems: (state, action) => {
            const cartProductId = action.payload;
            state.checkedCartItemStates[cartProductId] = !state.checkedCartItemStates[cartProductId];

            sessionStorage.setItem(Config.storageKey.cart, JSON.stringify(state));
        },

        handleCheckedAll: (state) => {
            const isAllChecked = Object.values(state.checkedCartItemStates).every((item) => item);

            Object.keys(state.checkedCartItemStates).forEach((key) => {
                state.checkedCartItemStates[key] = !isAllChecked;
            });

            sessionStorage.setItem(Config.storageKey.cart, JSON.stringify(state));
        },

        handleSaveVoucherCode: (state, action) => {
            const {stallId, code} = action.payload;
            state.voucherList[stallId].map((voucher) => {
                voucher.is_used = voucher.code === code;

                return voucher;
            });
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(getCartProducts.fulfilled, (state, action) => {
                state.cartList = action.payload.data.collection;

                if (checkObjectEmpty(state.checkedCartItemStates)) {
                    state.cartList.forEach((item) => {
                        if (item.extend_product.quantity > 0) {
                            state.checkedCartItemStates[item.id] = false;
                        }
                    });
                } else {
                    const checkedState = {};
                    state.cartList.forEach((item) => {
                        if (item.extend_product.quantity > 0) {
                            checkedState[item.id] = state.checkedCartItemStates[item.id];
                        }
                    });

                    state.checkedCartItemStates = checkedState;
                }

                sessionStorage.setItem(Config.storageKey.cart, JSON.stringify(state));
            })
            .addCase(addCartProducts.fulfilled, (state, action) => {
                const cartItemId = action.payload.data.id;
                if (!state.checkedCartItemStates[cartItemId]) {
                    state.checkedCartItemStates[cartItemId] = false;
                }
                sessionStorage.setItem(Config.storageKey.cart, JSON.stringify(state));
            })
            .addCase(getVoucherList.fulfilled, (state, action) => {
                state.voucherList = action.payload.data.collection;
            });
    }
});

const {reducer, actions} = cart;
export const {handleCheckedItems, handleCheckedAll, resetCouponStatus, handleSaveVoucherCode} = actions;
export default reducer;
