import { FormHelperText } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

const ValidatingInput = ({ control, inputValue, errors = {}, helperText, className = '', onChangeValue }) => {
    const {
        name,
        rules = {},
        label,
        isPrice = false,
        type = 'text',
        placeholder = '',
        value,
        noEndIcon = false,
        novalidate = false,
        ...otherProps
    } = inputValue;

    const baseInputProps = {
        name,
        type,
        placeholder,
        className: 'basic-input',
        novalidate,
        label: label && (
            <span>
                {label} {rules.required?.value ? <span className='text-required'>*</span> : ''}
            </span>
        )
    };

    if (onChangeValue) {
        Object.assign(baseInputProps, {
            value,
            onChange: onChangeValue
        });
    }

    return (
        <div className={`d-flex flex-column w-100 validating-input ${className}`}>
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field: { ref, ...restField } }) => {
                        const commonInputProps = {
                            ...baseInputProps,
                            ...restField
                        };

                        if (!onChangeValue) {
                            Object.assign(commonInputProps, {
                                valid: !errors[name]
                            });
                        }

                        return isPrice ? (
                            <NumericFormat
                                {...commonInputProps}
                                endicon={noEndIcon ? '' : 'đ'}
                                isGapEndIcon
                                thousandSeparator={isPrice}
                                suffix='đ'
                                customInput={CustomInput}
                            />
                        ) : (
                            <CustomInput ref={ref} {...commonInputProps} />
                        );
                    }}
                />
            ) : isPrice ? (
                <NumericFormat
                    {...baseInputProps}
                    endicon={noEndIcon ? '' : 'đ'}
                    isGapEndIcon
                    thousandSeparator={isPrice}
                    suffix='đ'
                    customInput={CustomInput}
                />
            ) : (
                <CustomInput {...baseInputProps} {...otherProps} />
            )}
            {(helperText || errors[name]?.message) && (
                <FormHelperText error={!!errors[name]}>
                    {errors[name]?.message.length > 0 ? errors[name].message : helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export default ValidatingInput;
