const domainConfig = {
	development: {
		endPoint: 'https://haidanggroup.develop.meu-solutions.com'
	},
	production: {
		endPoint: 'https://haidanggroup.meu-solutions.com'
	},
	empty: {
		endPoint: 'https://haidanggroup.erp.meu-solutions.com'
	}
};

export default domainConfig;