import DecorateContainer from 'components/DecorateContainer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SignupContainer from './SignupContainer';
import i18nLabels from 'features/Auth/auth.i18n';
import { signupSelector } from 'app/selectors';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

const Signup = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const signup = useSelector(signupSelector);

	useEffect(() => {
        googleAnalyticsActions.trackingPageView('Signup page');
    }, [])

	const signupProps = {
		dispatch,
		history,
		i18nLabels,
		signup
	};

	return (
		<DecorateContainer {...signupProps} childComponent={SignupContainer} />
	);
};

export default Signup;