import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {};
};

const admin = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: (builders) => { }
});

const { reducer } = admin;
export default reducer;
