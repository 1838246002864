import DecorateContainer from 'components/DecorateContainer';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18nLabels from './Admin.i18n';
import AdminContainer from './AdminContainer';
import { googleAnalyticsActions } from 'utils/googleAnalytics';

const Admin = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
        googleAnalyticsActions.trackingPageView('Admin page');
    }, [])

	const AdminProps = {
		...props,
		dispatch,
		history,
		i18nLabels,
	};
	return <DecorateContainer {...AdminProps} childComponent={AdminContainer} />;
};

export default Admin;