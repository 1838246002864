import CustomButton from 'components/CustomButton/CustomButton';
import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { formatQuantity } from 'utils/formatQuantity';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDeviceSize } from 'utils/hooks/useDeviceSize';

const CartSummary = (props) => {
    const { intl, goToPaymentPage, cartSummaryData } = props;
    const { quantity, discount, totalPrice } = cartSummaryData;
    const [size] = useDeviceSize();

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const cartSummaryPrice = [
        {
            label: intl.messages.totalPrice,
            value: totalPrice
        },
        {
            label: intl.messages.discount,
            value: discount > 0 ? -discount : 0
        },
        {
            label: intl.messages.finalPrice,
            value: totalPrice - discount > 0 ? totalPrice - discount : 0
        }
    ];

    const cartSummaryDetail = size.width > 769 ? cartSummaryPrice : isExpanded ? cartSummaryPrice : cartSummaryPrice.slice(2);

    return (
        <div className='cart-summary'>
            <div className='cart-summary__header'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <h4 className='summary-title m-0'>{intl.messages.cartSummary}</h4>
                    <span className='summary-product'>
                        {formatQuantity(quantity)} {intl.messages.product}
                    </span>
                </div>
                <p className='d-flex d-md-none m-0 align-items-center gap-1' onClick={toggleExpanded}>
                    {isExpanded ? intl.messages.shorten : intl.messages.readmore}

                    <KeyboardArrowDownIcon aria-expanded={isExpanded} />
                </p>
            </div>
            <hr className='cart-summary__divider' />
            <div className='cart-summary__price'>
                {cartSummaryDetail.map((item) => (
                    <section key={item.label} className='d-flex align-items-center justify-content-between'>
                        <p className='price-label'>{item.label}</p>
                        <p className='price-value'>
                            <FormattedNumber style='currency' currency='VND' value={item.value} />
                        </p>
                    </section>
                ))}
            </div>
            <CustomButton onClick={goToPaymentPage} disabled={quantity <= 0} fullwidth>
                {intl.messages.goPayment}
            </CustomButton>
        </div>
    );
};

export default CartSummary;
