import React, {useEffect, useState} from 'react';

export default function Banner(props) {
    const {bannersList = [], config} = props;

    const [activeBanner, setActiveBanner] = useState(0);

    useEffect(() => {
        const bannerSliderInterval = setInterval(() => {
            const nextActiveBanner = activeBanner + 1 >= bannersList.collection?.length ? 0 : activeBanner + 1;
            setActiveBanner(nextActiveBanner);
        }, 5000);

        return () => clearInterval(bannerSliderInterval);
    }, [activeBanner, setActiveBanner, bannersList.collection?.length]);
    return (
        <div className='home-banner'>
            <div className='banner-indicators d-flex align-items-center gap-3'>
                {bannersList.collection?.map((banner, idx) => (
                    <button
                        className={`${idx === activeBanner ? 'active' : ''}`}
                        onClick={() => setActiveBanner(idx)}
                        key={banner.id}
                    />
                ))}
            </div>
            <div className='banner-inner container-fluid p-0'>
                <div className='row m-0 p-0 flex-nowrap h-100'>
                    {bannersList.collection?.map((banner, idx) => (
                        <div
                            key={idx}
                            className='banner-inner__item col-12 p-0 h-100'
                            style={{transform: `translateX(-${activeBanner * 100}%)`}}>
                            <img src={`${config}${banner.image}`} className='d-block w-100 h-100' alt='banners' />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
