import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './Voucher.i18n';
import VoucherContainer from './VoucherContainer';

const Voucher = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const voucherProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...voucherProps} childComponent={VoucherContainer} />;
};

export default Voucher;
