const i18nLabels = {
    vi: {
        designCategoryTitle: 'Thiết kế hình ảnh danh mục',
        designCategorySubTitle: 'Hình ảnh đại diện cho từng danh mục được trưng bày tại trang chủ',
        categoryList: 'Danh sách ngành hàng',
        category: 'Ngành hàng',
        allCategory: 'Tất cả ngành hàng',
        image: 'Hình đại diện',
        categoryName: 'Tên ngành hàng',
        imageSize: 'Kích thước: Tối đa 2000 x 2000 px',
        imageFormat: 'Định dạng: JPG, JPEG, PNG, GIF',
        video: 'Video (không áp dụng cho video YouTube)',
        videoCapacity: 'Dung lượng: Tối đa 30MB',
        videoSize: 'Kích thước video: Tối đa 1280 x 1280 px',
        videoFormat: 'Thời lượng: 10-60 giây. Định dạng: MP4',
        cancel: 'Hủy',
        save: 'Lưu',
        actionFail: 'Thao tác thất bại',
        saveSuccess: 'Lưu thành công',
        overSize: 'Hình ảnh có dung lượng không được vượt quá 2MB',
    },
    en: {
        designCategoryTitle: 'Design Category Images',
        designCategorySubTitle: 'Representative images for each category displayed on the homepage',
        categoryList: 'Category List',
        category: 'Category',
        allCategory: 'All Categories',
        image: 'Image',
        categoryName: 'Category Name',
        imageSize: 'Size: Maximum 2000 x 2000 px',
        imageFormat: 'Format: JPG, JPEG, PNG, GIF',
        video: 'Video (not applicable for YouTube videos)',
        videoCapacity: 'Capacity: Maximum 30MB',
        videoSize: 'Video Size: Maximum 1280 x 1280 px',
        videoFormat: 'Duration: 10-60 seconds. Format: MP4',
        cancel: 'Cancel',
        save: 'Save',
        actionFail: 'Action Failed',
        saveSuccess: 'Save Successful'
    }
};

export default i18nLabels;
