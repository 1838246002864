import {Repeat, SearchBlue, SearchIcon} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GroupInput from 'components/GroupInput/GroupInput';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const defaultFilterValues = {
    filterBy: 'name',
    searchValue: '',
    category: '',
    minQuantity: '',
    maxQuantity: '',
    minSold: '',
    maxSold: ''
};

const FilterProductList = (props) => {
    const {intl, submitFilterProduct, categories = [], setFilterData} = props;
    const {control, reset, handleSubmit} = useForm({defaultValues: defaultFilterValues});
    const selectOptions = [
        {
            value: intl.messages.productName,
            id: 'name'
        },
        {
            value: intl.messages.productCode,
            id: 'sku'
        }
    ];
    const groupInputQuantityData = [
        {name: 'minQuantity', placeholder: intl.messages.minValue, type: 'number'},
        {name: 'maxQuantity', placeholder: intl.messages.maxValue, type: 'number'}
    ];
    const groupInputSoldData = [
        {name: 'minSold', placeholder: intl.messages.minValue, type: 'number'},
        {name: 'maxSold', placeholder: intl.messages.maxValue, type: 'number'}
    ];
    const categoriesSelectData = categories.map((category) => {
        return {
            id: category.id,
            value: category.name
        };
    });
    const handleReset = () => {
        reset(defaultFilterValues);
        setFilterData([]);
    };

    return (
        <div className='view-product__filter'>
            <form action='#' method='POST' className='w-100' onSubmit={handleSubmit(submitFilterProduct)}>
                <div className='row m-0 mb-4'>
                    <div className='filter-by'>
                        <Controller
                            name='filterBy'
                            control={control}
                            render={({field}) => (
                                <CustomSelect
                                    {...field}
                                    options={selectOptions}
                                    noPlaceholder
                                    inputClassName='filter-select'
                                />
                            )}
                        />
                    </div>
                    <div className='search-by'>
                        <Controller
                            name='searchValue'
                            control={control}
                            render={({field}) => (
                                <CustomInput
                                    {...field}
                                    placeholder={intl.messages.searchProduct}
                                    starticon={<SearchIcon />}
                                    inputClassName='filter-search'
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='row m-0 mb-4 gap-10px'>
                    <div className='col p-0'>
                        <Controller
                            name='category'
                            control={control}
                            render={({field}) => (
                                <CustomSelect
                                    {...field}
                                    label={intl.messages.category}
                                    placeholder={intl.messages.selectCategory}
                                    options={categoriesSelectData}
                                />
                            )}
                        />
                    </div>
                    <div className='col p-0'>
                        <GroupInput
                            control={control}
                            groupInputData={groupInputQuantityData}
                            label={intl.messages.storage}
                        />
                    </div>
                </div>

                <div className='row m-0 gap-10px'>
                    <div className='col p-0'>
                        <GroupInput control={control} groupInputData={groupInputSoldData} label={intl.messages.sold} />
                    </div>
                    <div className='col p-0 d-flex gap-2'>
                        <CustomButton className='p-2' type='submit' variant='half-contained'>
                            <SearchBlue className='me-1' />
                            {intl.messages.search}
                        </CustomButton>
                        <CustomButton className='p-2' type='reset' variant='text' onClick={handleReset}>
                            <Repeat className='me-1' />
                            {intl.messages.reInput}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FilterProductList;
