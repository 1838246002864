import React from 'react';

const LayoutWithSidebar = ({sidebar, children, className = ''}) => {
    return (
        <div className={`row m-0 flex-nowrap sidebar-layout ${className}`}>
            {sidebar && <div className='col-2 p-0 pe-3 sidebar-layout-container'>{sidebar}</div>}
            <div className='col p-0 sidebar-layout-children'>{children}</div>
        </div>
    );
};

export default LayoutWithSidebar;
