import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import addressApi from 'api/addressApi';
import usersApi from 'api/usersApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
	return {
		addressList: [],
		userAddressList: []
	};
};

export const getAddressList = createAsyncThunk('addressList/getAddressList', async (data, thunkAPI) => {
	return serviceRequest({
		dispatch: thunkAPI.dispatch,
		serviceMethod: addressApi.getAddressList,
		payload: data
	});
});

export const getUserAddressList = createAsyncThunk('addressList/getUserAddressList', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.userAddress,
        payload: data
    });
});

export const deleteUserAddress = createAsyncThunk('addressList/deleteUserAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.deleteUserAddress,
        payload: data
    });
});

export const addUserAddress = createAsyncThunk('addressList/addUserAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.addUserAddress,
        payload: data
    });
});

export const updateUserAddress = createAsyncThunk('addressList/updateUserAddress', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.updateUserAddress,
        payload: data
    });
});

const addressList = createSlice({
	name: 'addressList',
	initialState,
	reducers: {},
	extraReducers: (builders) => {
		builders
            .addCase(getAddressList.fulfilled, (state, action) => {
                state.addressList = action.payload?.data;
            })
			.addCase(getUserAddressList.fulfilled, (state, action) => {
                state.userAddressList = action.payload?.data?.collection;
            })
	}
});

const { reducer } = addressList;
export default reducer;
