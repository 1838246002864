import {FormControl, FormHelperText} from '@mui/material';
import {ErrorMark, frame2746, ornamentMobile, ornament_setPw2x, oval, ovalMobile} from 'assets/images';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';

const AuthSetPassword = (props) => {
    const {handleSetpassword, handleInvalidSubmit} = props;
    const {
        control,
        handleSubmit,
        formState: {errors},
        watch,
        getValues
    } = useForm({defaultValues: {password: '', repassword: ''}});
    const watchAllFields = watch();
    const checkButtonDisabled = Object.values(watchAllFields).some((field) => field.length === 0);
    const intl = useIntl();

    return (
        <AuthLayout src1={ornament_setPw2x} src2={frame2746} src3={oval} src4={ornamentMobile} src5={ovalMobile}>
            <div className='login-right'>
                <AuthModal>
                    <div className='login-header mb-2'>
                        <h2 className='m-0'>{intl.messages.setPasswordTitle}</h2>
                    </div>
                    <form
                        noValidate
                        action='#'
                        autoComplete='off'
                        onSubmit={handleSubmit(handleSetpassword, handleInvalidSubmit)}
                        className='w-100'>
                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='password'
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label={intl.messages.newPassword}
                                        placeholder={intl.messages.newPasswordLabel}
                                        type='password'
                                        ishavingpwicon='true'
                                        novalidate
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3'>
                            <Controller
                                name='repassword'
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        value === getValues('password') || intl.messages.repasswordError
                                }}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label={intl.messages.repasswordLabel}
                                        placeholder={intl.messages.repasswordInputLabel}
                                        type='password'
                                        ishavingpwicon='true'
                                        valid={!errors['repassword']}
                                        endicon={!errors['repassword'] ? null : <ErrorMark />}
                                    />
                                )}
                            />
                            {errors['repassword'] && (
                                <FormHelperText error>{errors['repassword'].message}</FormHelperText>
                            )}
                        </FormControl>
                        <div className='w-100 mb-2'>
                            <CustomButton type='submit' disabled={checkButtonDisabled} fullwidth>
                                {intl.messages.complete}
                            </CustomButton>
                        </div>
                    </form>
                </AuthModal>
            </div>
        </AuthLayout>
    );
};

export default AuthSetPassword;
