import DecorateContainer from 'components/DecorateContainer';
import i18nLabels from './AddProduct.i18n';
import AddProductContainer from './AddProductContainer';

const AddProduct = (props) => {
    const addProductProps = {
        ...props,
        i18nLabels
    };
    return <DecorateContainer {...addProductProps} childComponent={AddProductContainer} />;
};

export default AddProduct;
