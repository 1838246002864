import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import brandApi from 'api/brandApi';
import categoryApi from 'api/categoryApi';
import productApi from 'api/productApi';
import productTypesApi from 'api/productTypesApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        productDetails: {},
        categories: [],
        brands: []
    };
};

export const getCategories = createAsyncThunk('adminAddProduct/categories', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const getBrands = createAsyncThunk('adminAddProduct/brands', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: brandApi.getBrands
    });
});

export const addNewProducts = createAsyncThunk('adminAddProduct/add', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.addNewProduct,
        payload: data
    });
});

export const uploadProductImage = createAsyncThunk('adminAddProduct/productImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.uploadProductImages,
        payload: data
    });
});

export const deleteProductImage = createAsyncThunk('adminAddProduct/deleteImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.deleteProductImage,
        payload: data
    });
});

export const uploadProductTypeImage = createAsyncThunk('adminAddProduct/productTypeImage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productTypesApi.uploadImage,
        payload: data
    });
});

export const getProductDetails = createAsyncThunk('adminAddProduct/productDetails', async (params, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProductDetails,
        payload: params
    });
});

export const updateAdminProduct = createAsyncThunk('adminAddProduct/updateProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.updateAllDetailProduct,
        payload: data
    });
});

const adminAddProduct = createSlice({
    name: 'adminAddProduct',
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload.data;
            })
            .addCase(getBrands.fulfilled, (state, action) => {
                state.brands = action.payload.data.collection;
            })
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.productDetails = action.payload.data;
            });
    }
});

const { reducer } = adminAddProduct;
export default reducer;
