import {
    Autocomplete,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    TextField
} from '@mui/material';
import { close } from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import { useEffect } from 'react';
import { useState } from 'react';

const defaultValues = {
    city_obj: null,
    district_obj: null,
    ward_obj: null,
    house_address: '',
    receive_name: '',
    receive_phone: '',
    is_set_default: false,
    is_set_pickup: false,
    is_set_return: false
};

const AddressFormModal = (props) => {
    const {
        intl,
        isOpenAddressFormModal,
        closeAddressFormModal,
        cityList,
        onSubmitAddressFormModal,
        addressModalType,
        selectedAddressObj,
        addressType = 'USER'
    } = props;
    const [districtList, setDistrictList] = useState([]);
    const [wardList, setWardList] = useState([]);
    const [addressValue, setAddressValue] = useState(defaultValues);
    const [addressInput, setAddressInput] = useState({
        city: '',
        district: '',
        ward: ''
    });

    const checkButtonDisabled =
        addressValue.city_obj === null ||
        addressValue.district_obj === null ||
        addressValue.ward_obj === null ||
        addressValue.house_address.length === 0 ||
        addressValue.receive_name.length === 0 ||
        addressValue.receive_phone.length === 0;

    const isCityChose = !!addressValue.city_obj;
    const isDistrictChose = !!addressValue.district_obj;

    const onChangeCity = (event, newValue) => {
        if (newValue) {
            setAddressValue((prev) => ({
                ...prev,
                city_obj: newValue
            }));
            const { code } = newValue;
            const foundCity = cityList.find((el) => el.code === code);
            setDistrictList(foundCity.districts);
        } else {
            setAddressValue((prev) => ({
                ...prev,
                city_obj: null
            }));
            setDistrictList([]);
        }
        setAddressValue((prev) => ({
            ...prev,
            district_obj: null,
            ward_obj: null
        }));
        setWardList([]);
    };

    const onChangeDistrict = (event, newValue) => {
        if (newValue) {
            setAddressValue((prev) => ({
                ...prev,
                district_obj: newValue
            }));
            const { code } = newValue;
            const foundDistrict = districtList.find((el) => el.code === code);
            setWardList(foundDistrict.wards);
        } else {
            setAddressValue((prev) => ({
                ...prev,
                district_obj: null
            }));
            setWardList([]);
        }
        setAddressValue((prev) => ({
            ...prev,
            ward_obj: null
        }));
    };

    useEffect(() => {
        if (addressModalType === 'ADD') {
            setAddressValue(defaultValues);
            setDistrictList([]);
            setWardList([]);
        }
    }, [isOpenAddressFormModal]);

    useEffect(() => {
        if (addressModalType === 'UPDATE' && selectedAddressObj && cityList) {
            const { province_code, province } = selectedAddressObj;
            const foundProvince = cityList.find((el) => el.code === province_code);
            if (foundProvince) {
                setDistrictList(foundProvince.districts);
                setAddressValue((prev) => ({
                    ...prev,
                    city_obj: foundProvince,
                    house_address: selectedAddressObj.address,
                    receive_name:
                        addressType === 'USER' ? selectedAddressObj.fullname : selectedAddressObj.representative_name,
                    receive_phone:
                        addressType === 'USER' ? selectedAddressObj.phone : selectedAddressObj.representative_phone,
                    is_set_default: selectedAddressObj.is_default
                }));
                if (addressType === 'BRAND') {
                    setAddressValue((prev) => ({
                        ...prev,
                        is_set_pickup: selectedAddressObj.is_pickup,
                        is_set_return: selectedAddressObj.is_return
                    }));
                }
            }
            setAddressInput((prev) => ({
                ...prev,
                city: province
            }));
        }
    }, [selectedAddressObj, cityList, isOpenAddressFormModal]);

    useEffect(() => {
        if (addressModalType === 'UPDATE' && selectedAddressObj && districtList) {
            const { district_code, district } = selectedAddressObj;
            const foundDistrict = districtList.find((el) => el.code === district_code);
            setAddressInput((prev) => ({
                ...prev,
                district: district
            }));
            if (foundDistrict) {
                setWardList(foundDistrict.wards);
                setAddressValue((prev) => ({
                    ...prev,
                    district_obj: foundDistrict
                }));
            }
        }
    }, [selectedAddressObj, districtList, isOpenAddressFormModal]);

    useEffect(() => {
        if (addressModalType === 'UPDATE' && selectedAddressObj && wardList) {
            const { ward_code, ward } = selectedAddressObj;
            const foundWard = wardList.find((el) => el.code === ward_code);
            setAddressInput((prev) => ({
                ...prev,
                ward: ward
            }));
            if (foundWard) {
                setAddressValue((prev) => ({
                    ...prev,
                    ward_obj: foundWard
                }));
            }
        }
    }, [selectedAddressObj, wardList, isOpenAddressFormModal]);

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={isOpenAddressFormModal}
            onClose={closeAddressFormModal}
            aria-labelledby='voucher-modal'
            aria-describedby='apply-voucher-to-get-discount'
            className='dialog'>
            <DialogTitle className='dialog-title' id='voucher-modal'>
                <span>{addressModalType === 'ADD' ? 'Thêm mới địa chỉ' : 'Chỉnh sửa địa chỉ'}</span>
                <button className='dialog-title__close'>
                    <img src={close} alt='' onClick={closeAddressFormModal} />
                </button>
            </DialogTitle>
            <div className='payment-address-add-modal'>
                <form
                    method='post'
                    noValidate
                    action='#'
                    autoComplete='off'
                    onSubmit={(e) => onSubmitAddressFormModal(addressValue, e)}>
                    <DialogContent className='dialog-content'>
                        <h6 style={{ color: '#666666', marginBottom: 20 }}>Địa chỉ giao hàng</h6>
                        <div className='d-flex' style={{ marginBottom: 24 }}>
                            {/* Tỉnh/Thành Phố */}
                            <FormControl fullWidth required>
                                <Autocomplete
                                    id='cities-select'
                                    options={cityList}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={addressValue.city_obj}
                                    inputValue={addressInput.city}
                                    onInputChange={(e, value) =>
                                        setAddressInput((prev) => ({
                                            ...prev,
                                            city: value
                                        }))
                                    }
                                    onChange={(e, newValue) => {
                                        onChangeCity(e, newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Tỉnh/Thành phố' />}
                                />
                            </FormControl>
                        </div>

                        <div
                            className='d-flex justify-content-between align-items-center flex-column flex-sm-row gap-4 gap-sm-3'
                            style={{ marginBottom: 24 }}>
                            {/* Quận/Huyện */}
                            <FormControl fullWidth required>
                                <Autocomplete
                                    disabled={!isCityChose}
                                    id='district-select'
                                    options={districtList}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={addressValue.district_obj}
                                    inputValue={addressInput.district}
                                    onInputChange={(e, value) =>
                                        setAddressInput((prev) => ({
                                            ...prev,
                                            district: value
                                        }))
                                    }
                                    onChange={(e, newValue) => {
                                        onChangeDistrict(e, newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Quận/Huyện' />}
                                />
                            </FormControl>
                            {/* Phường */}
                            <FormControl fullWidth required>
                                <Autocomplete
                                    disabled={!isDistrictChose}
                                    id='ward-select'
                                    options={wardList}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={addressValue.ward_obj}
                                    inputValue={addressInput.ward}
                                    onInputChange={(e, value) =>
                                        setAddressInput((prev) => ({
                                            ...prev,
                                            ward: value
                                        }))
                                    }
                                    onChange={(e, newValue) => {
                                        setAddressValue((prev) => ({
                                            ...prev,
                                            ward_obj: newValue
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Phường' />}
                                />
                            </FormControl>
                        </div>
                        <div className='d-flex' style={{ marginBottom: 24 }}>
                            {/* Sổ nhà */}
                            <FormControl fullWidth>
                                <TextField
                                    name='house_address'
                                    label={'Số nhà, ấp, xã'}
                                    placeholder={'Nhập Số nhà, ấp, xã'}
                                    value={addressValue.house_address}
                                    onChange={(e) =>
                                        setAddressValue((prev) => ({
                                            ...prev,
                                            house_address: e.target.value
                                        }))
                                    }
                                    required
                                />
                            </FormControl>
                        </div>
                        <h6 style={{ color: '#666666', marginBottom: 20 }}>
                            Người {addressType === 'USER' ? 'nhận hàng' : 'đại diện'}
                        </h6>
                        <div
                            className='d-flex justify-content-between align-items-center flex-column flex-sm-row gap-4 gap-sm-3'
                            style={{ marginBottom: 16 }}>
                            {/* Tên người nhận */}
                            <FormControl fullWidth>
                                <TextField
                                    name='receive_name'
                                    type={'text'}
                                    label={'Họ và tên'}
                                    placeholder={'Nhập họ và tên'}
                                    value={addressValue.receive_name}
                                    onChange={(e) =>
                                        setAddressValue((prev) => ({
                                            ...prev,
                                            receive_name: e.target.value
                                        }))
                                    }
                                    required
                                />
                            </FormControl>
                            {/* Số điện thoại */}
                            <FormControl fullWidth>
                                <TextField
                                    name='receive_phone'
                                    type={'number'}
                                    label={'Số điện thoại'}
                                    placeholder={'Nhập số điện thoại'}
                                    value={addressValue.receive_phone}
                                    onChange={(e) =>
                                        setAddressValue((prev) => ({
                                            ...prev,
                                            receive_phone: e.target.value
                                        }))
                                    }
                                    required
                                />
                            </FormControl>
                        </div>
                        <div className='d-flex'>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={
                                                addressModalType === 'UPDATE' &&
                                                addressType === 'USER' &&
                                                selectedAddressObj.is_default
                                            }
                                            checked={addressValue.is_set_default}
                                            onChange={(e) =>
                                                setAddressValue((prev) => ({
                                                    ...prev,
                                                    is_set_default: e.target.checked
                                                }))
                                            }
                                        />
                                    }
                                    label='Chọn làm địa chỉ mặc định'
                                />
                            </FormControl>
                        </div>
                        {addressType === 'BRAND' && (
                            <div className='row'>
                                <div className='col'>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={addressValue.is_set_pickup}
                                                    onChange={(e) =>
                                                        setAddressValue((prev) => ({
                                                            ...prev,
                                                            is_set_pickup: e.target.checked
                                                        }))
                                                    }
                                                />
                                            }
                                            label='Chọn làm địa chỉ lấy hàng'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        )}
                        {addressType === 'BRAND' && (
                            <div className='row'>
                                <div className='col'>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={addressValue.is_set_return}
                                                    onChange={(e) =>
                                                        setAddressValue((prev) => ({
                                                            ...prev,
                                                            is_set_return: e.target.checked
                                                        }))
                                                    }
                                                />
                                            }
                                            label='Chọn làm địa chỉ trả hàng'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions className='dialog-actions'>
                        <CustomButton
                            size='md'
                            variant='text'
                            onClick={(e) => {
                                e.preventDefault();
                                closeAddressFormModal();
                            }}>
                            Hủy
                        </CustomButton>
                        <CustomButton size='md' disabled={checkButtonDisabled}>
                            Lưu
                        </CustomButton>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
};

export default AddressFormModal;
