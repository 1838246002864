import {NotiDefault} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import Config from 'configuration';
import {updateNotifications} from 'features/Notification/notificationSlice';
import moment from 'moment';
import React from 'react';
import {useDispatch} from 'react-redux';

const NotificationBlock = (props) => {
    const {noti, size = 'sm', navigateTo, btnLabel} = props;
    const dispatch = useDispatch();

    const handleClick = async () => {
        const params = {approvedStatusCode: noti.extend_status.code};
        await dispatch(updateNotifications({id: noti.id, params, data: {read: true}})).unwrap();
        navigateTo(`${noti?.reference_link} `);
    };

    return (
        <div
            className={`notification-block ${noti.read ? 'notification-block--read' : ''} block-${size}`}
            onClick={handleClick}>
            <div className='notification-block__img'>
                <img src={noti.image ? Config.apiConfig.endPoint + noti.image : NotiDefault} alt={noti.summary} />
            </div>

            <div className='notification-block__content'>
                <div className='noti-detail'>
                    <div className='noti-detail__title ellipsis'>
                        <span>{noti.summary}</span>
                    </div>
                    <p className='noti-detail__description'>{noti.details}</p>
                    <span className='noti-detail__time'>{moment(noti.created_at).format('HH:mm DD-MM-YYYY')}</span>
                </div>

                {btnLabel && (
                    <div className='noti-action d-none d-lg-block'>
                        <CustomButton
                            className='noti-action__btn'
                            variant='outlined'
                            size='md'
                            onClick={() => navigateTo(`${noti?.reference_link} `)}>
                            {btnLabel}
                        </CustomButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationBlock;
