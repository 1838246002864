import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseGray, Copy } from 'assets/images';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShareModal = (props) => {
	const { open, doClose, intl, socialLinks = [] } = props;

	return (
		<Dialog
			maxWidth={'xs'}
			fullWidth={true}
			open={open}
			onClose={doClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			className='dialog dialog-share'>
			<DialogTitle className='dialog-title' id='alert-dialog-title'>
				<span>{intl.messages.share}</span>
				<button className='dialog-title__close'>
					<CloseGray onClick={doClose} />
				</button>
			</DialogTitle>
			<DialogContent className='dialog-content'>
				<div className="dialog-content__url">
					<div className="url-value">
						Link sản phẩm
					</div>
					<CopyToClipboard text={window.location.href}>
						<IconButton><Copy /></IconButton>
					</CopyToClipboard>


				</div>

				<div className="dialog-content__social">
					{socialLinks.map((link, idx) => (
						<div className='social-link' key={idx}>
							{link.icon}
							<span>{link.label}</span>
						</div>
					))}
				</div>
			</DialogContent>

		</Dialog>
	);
};

export default ShareModal;