import React, {useState} from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import {DownArrow} from 'assets/images';
import {FormattedNumber} from 'react-intl';
import receiptBackground from 'assets/images/svg/receipt.svg';

const YourBill = (props) => {
    const {intl, billInfo, productList} = props;
    const [isExpandProductList, setExpandProductList] = useState(false);

    const onToggleExpandProductList = (isExpand) => {
        setExpandProductList(isExpand);
    };

    return (
        <div className='payment-your-bill'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <SectionTitle paled={false}>{intl.messages.billingTitle}</SectionTitle>
                </div>
                <div className='d-flex align-items-center' style={{cursor: 'pointer'}}>
                    <div onClick={() => onToggleExpandProductList(!isExpandProductList)}>
                        <span style={{color: '#4568f2', fontSize: 13}}>
                            {!isExpandProductList
                                ? `${intl.messages.billingDetailLabel}`
                                : `${intl.messages.billingCollapseLabel}`}{' '}
                        </span>
                        <img
                            src={DownArrow}
                            alt='detail-expand'
                            style={{
                                width: 20,
                                height: 20,
                                objectFit: 'contain',
                                filter: 'invert(40%) sepia(83%) saturate(4185%) hue-rotate(220deg) brightness(99%) contrast(92%)',
                                transform: isExpandProductList && 'rotate(180deg)'
                            }}
                        />
                    </div>
                </div>
            </div>
            {
                isExpandProductList && (
                    // paymentProductsData &&
                    // paymentProductsData.map((item) => {
                    //     const {productList} = item;
                    //     return (
                    <div
                        key={'item?.brand_id'}
                        className='product-list-expand'
                        style={{
                            background: 'rgba(236, 240, 254, 0.7)',
                            padding: 8,
                            borderRadius: 4,
                            marginBottom: 8
                        }}>
                        {productList &&
                            productList.map((product) => (
                                <div key={product?.productId} className='row' style={{padding: '8px 0'}}>
                                    <div className='col-6'>
                                        <span style={{color: '#333', fontSize: 15}}>{product?.productName}</span>
                                    </div>
                                    <div className='col-2'>
                                        <span style={{color: '#333', fontSize: 15}}>x{product?.productQuantity}</span>
                                    </div>
                                    <div className='col-4' style={{textAlign: 'right'}}>
                                        <span style={{color: '#333', fontSize: 15}}>
                                            <FormattedNumber style='currency' currency='VND' value={product?.productPrice} />
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>
                )
                //     );
                // })
            }
            <div className='price-information'>
                <div className='d-flex justify-content-between align-items-center' style={{marginBottom: 14}}>
                    <div>
                        <span style={{color: '#666', fontSize: 15}}>{intl.messages.productPrice}</span>
                    </div>
                    <div>
                        <span style={{color: '#333', fontSize: 17}}>
                            <FormattedNumber style='currency' currency='VND' value={billInfo?.totalProductPrice} />
                        </span>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{marginBottom: 14}}>
                    <div>
                        <span style={{color: '#666', fontSize: 15}}>{intl.messages.shippingFee}</span>
                    </div>
                    <div>
                        <span style={{color: '#333', fontSize: 17}}>
                            <FormattedNumber style='currency' currency='VND' value={billInfo?.shippingFee} />
                        </span>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{marginBottom: 14}}>
                    <div>
                        <span style={{color: '#666', fontSize: 15}}>{intl.messages.discountLabel}</span>
                    </div>
                    <div>
                        <span style={{color: '#333', fontSize: 17}}>
                            -<FormattedNumber style='currency' currency='VND' value={billInfo?.discount || 0} />
                        </span>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{marginBottom: 14}}>
                    <div>
                        <span style={{color: '#666', fontSize: 15, fontWeight: 500}}>{intl.messages.revenueLabel}</span>
                    </div>
                    <div>
                        <span style={{color: '#f25c5c', fontSize: 23, fontWeight: 500}}>
                            <FormattedNumber style='currency' currency='VND' value={billInfo?.totalPrice} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YourBill;
