import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import BreadCrumb from './subViews/BreadCrumb/BreadCrumb';
import PaymentMethod from './subViews/PaymentMethod/PaymentMethod';
import ProductList from './subViews/ProductList/ProductList';
import TransportInformation from './subViews/TransportInformation/TransportInformation';
import YourBill from './subViews/YourBill/YourBill';
import DeleteAddressModal from './subViews/DeleteAddressModal/DeleteAddressModal';
import AddressFormModal from 'components/AddressFormModal/AddressFormModal';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import {useDeviceSize} from 'utils/hooks/useDeviceSize';
import CustomDrawer from 'components/CustomDrawer/CustomDrawer';

export default function PaymentMainView(props) {
    const {
        transportInfoProps,
        addressFormModalProps,
        addressListModalProps,
        paymentMethodProps,
        deleteAddressModalProps,
        productListProps,
        yourBillProps,
        addressListModalContent
    } = props;
    const transportInformationProps = {transportInfoProps, addressFormModalProps, addressListModalProps};

    const [windowDimensions, setWindowDimensions] = useDeviceSize();

    return (
        <div className='payment'>
            <AddressFormModal {...addressFormModalProps} />
            {windowDimensions.width > 576 ? (
                <ModalPopup
                    width='sm'
                    type='vertical'
                    {...addressListModalContent}
                />
            ) : (
                <CustomDrawer
                    anchor='bottom'
                    {...addressListModalContent}
                />
            )}
            <DeleteAddressModal {...deleteAddressModalProps} />

            <WrapperContainer bodyContent>
                <BreadCrumb />
                <div className='payment-wrapper'>
                    <div className='payment-wrapper__left-side'>
                        <TransportInformation {...transportInformationProps} />
                        <ProductList {...productListProps} />
                    </div>
                    <div className='payment-wrapper__right-side'>
                        <PaymentMethod {...paymentMethodProps} />
                        <YourBill {...yourBillProps} />
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
}
