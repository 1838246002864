const i18nLabels = {
    vi: {
        titleChatAuto: 'Nội dung tin nhắn tự động',
        titleQuickChat: 'Câu trả lời nhanh',
        number: 'STT',
        content: 'Nội dung',
        btnAdd: '+ Thêm',
        tlpEdit: 'Chỉnh sửa',
        tlpDelete: 'Xóa',
        tlpSave: 'Lưu',
        tlpCancel: 'Đóng',
        contentConfirm: 'Bạn muốn xóa câu trả lời?',
        titleConfirm: 'Xác nhận',
        btnDelete: 'Xóa',
        btnCancel: 'Đóng',
        titlePage: 'Cài đặt chat tự động'
    },
    en: {
        titleChatAuto: 'Automatic Message Content',
        titleQuickChat: 'Quick Replies',
        number: 'No.',
        content: 'Content',
        btnAdd: '+ Add',
        tlpEdit: 'Edit',
        tlpDelete: 'Delete',
        tlpSave: 'Save',
        tlpCancel: 'Cancel',
        contentConfirm: 'Do you want to delete this reply?',
        titleConfirm: 'Confirmation',
        btnDelete: 'Delete',
        btnCancel: 'Cancel',
        titlePage: 'Automatic Chat Settings'
    }
};

export default i18nLabels;
