export default function propsProvider(props) {
    const {
        intl,
        auth,
        dispatch,
        handleSubmitChangePassword,
        handleSubmitInvalid,
        handleSubmitUpdateUserInfo,
        handleUploadAvatar,
        onOpenEmailModal,
        onOpenPhoneModal,
        isOpenUpdateModal,
        closeUpdateModal,
        isEmailModal,
        handleSubmitUpdateForm,
        isHaveUpdateSubmit,
        handleBackUpdateForm,
        handleConfirmOtp,
        handleSendPhoneOTP,
        setEnteredOTP,
        enteredOTP,
        handleConfirmPhoneOTP
    } = props;

    const passwordSettingInputs = [
        {
            label: intl.messages.oldPassword,
            placeholder: '*'.repeat(8),
            name: 'oldPassword',
            rules: {required: {value: true, message: intl.messages.requiredField}}
        },
        {
            label: intl.messages.newPassword,
            placeholder: intl.messages.enterNewPassword,
            name: 'newPassword',
            rules: {required: {value: true, message: intl.messages.requiredField}}
        },
        {
            label: intl.messages.confirmPassword,
            placeholder: intl.messages.enterConfirmPassword,
            name: 'confirmPassword',
            rules: {required: {value: true, message: intl.messages.requiredField}}
        }
    ];

    return {
        intl,
        dispatch,
        accountSettingProps: {
            intl,
            auth,
            handleSubmitUpdateUserInfo,
            handleUploadAvatar,
            onOpenEmailModal,
            onOpenPhoneModal,
            updateModalProps: {
                intl,
                auth,
                isEmailModal,
                isOpenUpdateModal,
                closeUpdateModal,
                handleSubmitUpdateForm,
                isHaveUpdateSubmit,
                handleBackUpdateForm,
                handleConfirmOtp,
                handleSendPhoneOTP,
                setEnteredOTP,
                enteredOTP,
                handleConfirmPhoneOTP
            }
        },
        passwordSettingProps: {
            intl,
            handleSubmitChangePassword,
            handleSubmitInvalid,
            passwordSettingInputs
        }
    };
}
