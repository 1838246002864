const i18nLabels = {
    vi: {
        shippingUnitList: 'Danh sách nhà vận chuyển',
        addNew: 'Thêm mới',
        edit: 'Chỉnh sửa',
        orderNumber: 'STT',
        shippingUnit: 'Đơn vị vận chuyển',
        transportForm: 'Hình thức vận chuyển',
        activeLabel: 'Đang hoạt động',
        rowOnPage: 'Số dòng trên trang',
        createNew: 'Tạo mới',
        fastShip: 'Giao nhanh',
        normalShip: 'Giao thường',
        ultimateShip: 'Hỏa tốc',
        selfShip: 'Tự vận chuyển',
        description: 'Mô tả',
        close: 'Đóng',
        add: 'Thêm',
        save: 'Lưu',
        cancel: 'Hủy',
        delete: 'Xóa',
        deleteUnit: 'Bạn có chắc muốn xóa đơn vị vận chuyển này?',
        unsuccessfullyDelete: 'Xóa không thành công',
        deleteNotification:
            'Hiện đang có các sản phẩm đang áp dụng đơn vị vận chuyển này, bạn vui lòng đổi đơn vị vận chuyển khác trước khi xóa đơn vị vận chuyển này.'
    },
    en: {
        shippingUnitList: 'Shipping Unit List',
        addNew: 'Add New',
        edit: 'Edit',
        orderNumber: 'No.',
        shippingUnit: 'Shipping Unit',
        transportForm: 'Transportation Method',
        activeLabel: 'Active',
        rowOnPage: 'Rows per Page',
        createNew: 'Create New',
        fastShip: 'Fast Shipping',
        normalShip: 'Standard Shipping',
        ultimateShip: 'Express Shipping',
        selfShip: 'Self-Shipping',
        description: 'Description',
        close: 'Close',
        add: 'Add',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        deleteUnit: 'Are you sure you want to delete this shipping unit?',
        unsuccessfullyDelete: 'Delete unsuccessful',
        deleteNotification:
            'There are currently products using this shipping unit. Please change to a different shipping unit before deleting.'
    }
};

export default i18nLabels;
