import {FormattedNumber} from 'react-intl';

const AmountRefund = (props) => {
    const {intl, amountRefund} = props;
    return (
        <div className='refund-amount'>
            <div className='refund-amount-title refund-section-title'>{intl.messages.returnRefund}</div>
            <div className='refund-amount-wrapper refund-section-content'>
                <div className='refund-amount-wrapper-title'>{intl.messages.refundAmount}</div>
                <div className='refund-amount-wrapper-input'>
                    <FormattedNumber style='currency' currency='VND' value={amountRefund} />
                </div>
            </div>
        </div>
    );
};

export default AmountRefund;
