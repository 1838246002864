import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import React, {useState} from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const RoleAccordion = (props) => {
    const {data, handleChecked, checkedRoleData} = props;
    const [key, role] = data;
    const isGroupChecked = role.every((roleData) => checkedRoleData[roleData.id]);

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleAccordion = () => setIsExpanded(!isExpanded);
    const haveSubItems = role.length > 1;

    const groupRow = (
        <div className='content-group__row'>
            {role.map(({id, ...displayData}) => (
                <div className='row-item' key={id}>
                    {Object.entries(displayData).map(([roleKey, roleValue], idx) => (
                        <div className={`data-col data-col-${idx}`} key={roleKey}>
                            {idx === 0 && role.length === 1 ? null : roleValue}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );

    return (
        <div className='content-group' role='rowgroup'>
            {role.length > 1 ? (
                <Accordion
                    className='m-0'
                    expanded={isExpanded}
                    onClick={() => {
                        if (haveSubItems) toggleAccordion();
                    }}>
                    <AccordionSummary
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                        className='sidebar-accordion__summary'
                        expandIcon={haveSubItems ? <ArrowForwardIosSharpIcon /> : undefined}>
                        <div className='summary-content'>
                            <div className='content-group__head'>
                                <div className='data-col data-col-0'></div>
                                <div className='data-col data-col-1'>{key}</div>
                                <div className='data-col data-col-2'></div>
                                <div className='data-col data-col-3'>
                                    <CustomCheckbox
                                        checked={isGroupChecked}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onChange={(e) =>
                                            handleChecked({
                                                type: 'group',
                                                payload: key,
                                                value: e.target.checked
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>{groupRow}</AccordionDetails>
                </Accordion>
            ) : (
                groupRow
            )}
        </div>
    );
};

export default RoleAccordion;
