import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import chatApi from "api/chatApi"
import serviceRequest from "app/serviceRequest"

const initialState=()=>{
    return {
        adminChatSetting:[],
        chatList: []
    }
}

export const getChatList = createAsyncThunk('chatList/getChatList', async (data, thunkAPI) =>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatApi.getAllChat,
        payload: data,
    })
})

export const addChatList = createAsyncThunk('chatList/addChatList',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatApi.postNewChat,
        payload:data
    })
})

export const updateChat = createAsyncThunk('chatList/updateChatList',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatApi.updateChat,
        payload: data,
    })
})

export const updateSwitch = createAsyncThunk('chatList/updateChatList',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatApi.updateChat,
        payload: data,
        options:{
            skipLoader:true
        }
    })
})

export const deleteChat = createAsyncThunk('chatList/deleteChat',async(data,thunkAPI)=>{
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatApi.deleteChat,
        payload: data
    })
})

const adminChatSetting = createSlice({
    name: 'adminChatSetting',
    initialState,
    reducers:{

    },
    extraReducers: (builders)=>{}
})

const {reducer} = adminChatSetting
export default reducer;