import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import voucherApi from 'api/voucherApi';
import categoryApi from 'api/categoryApi';
import approvedStatusApi from 'api/approvedStatusApi';
import shippingUnitApi from 'api/shippingUnitApi';
import serviceRequest from 'app/serviceRequest';
import productApi from 'api/productApi';

const initialState = () => {
    return {
        paginationData: {page: 1, pageSize: 10},
        total: 0,
        voucherList: [],
        categoryList: [],
        shippingUnitList: [],
        paymentMethodList: [],
        statusList: [],
        productList: {}
    };
};

export const getVoucherProducts = createAsyncThunk('productList/getAdminProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data
    });
});

export const getVouchers = createAsyncThunk('marketing/vouchers/getVouchers', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.getVoucherList,
        payload: data === undefined ? '' : data
    });
});

export const getCategories = createAsyncThunk('marketing/vouchers/categories', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

export const postVoucher = createAsyncThunk('marketing/vouchers/addNew', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.createVoucher,
        payload: data
    });
});

export const putVoucher = createAsyncThunk('marketing/vouchers/putVoucher', async (data, thunkAPI) => {
    let id = data.id;
    delete data.id;
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.editVoucher,
        payload: {id, ...data}
    });
});

export const delVoucher = createAsyncThunk('marketing/vouchers/deleteVoucher', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: voucherApi.deleteVoucher,
        payload: data
    });
});

export const getShippingList = createAsyncThunk('marketing/vouchers/shippingList', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: shippingUnitApi.getShippingUnit
    });
});

export const getPaymentMethodList = createAsyncThunk(
    'marketing/vouchers/paymentMethodList',
    async (query, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: voucherApi.getPaymentMethod,
            payload: query
        });
    }
);

export const getStatusList = createAsyncThunk('marketing/vouchers/status', async (type, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: approvedStatusApi.getAllByType,
        payload: type
    });
});

const adminMarketingVoucher = createSlice({
    name: 'adminMarketingVoucher',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const {page} = action.payload;
            state.paginationData.page = page;
        },
        handleChangePageSize: (state, action) => {
            const {pageSize} = action.payload;
            state.paginationData.pageSize = pageSize;
        },
        resetProductList: (state) => {
            state.productList = [];
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(getVouchers.fulfilled, (state, action) => {
                if (action.payload.success) state.voucherList = action.payload.data.collection;
                state.total = action.payload.data.total;
            })
            .addCase(getShippingList.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.shippingUnitList = action.payload.data.collection.map((unit) => ({
                        id: unit.id,
                        name: unit.name
                    }));
                }
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                const {success, data} = action.payload;
                if (success) {
                    const setLevel = (categories, parentIdx) => {
                        categories.forEach((category, idx) => {
                            category.parentIdx = parentIdx ? parentIdx : idx;
                            if (category.children) {
                                setLevel(category.children, category.parentIdx);
                            }
                        });

                        return categories;
                    };

                    state.categoryList = setLevel(
                        data.map((category) => {
                            const {id, name, productCount, children} = category;

                            return {
                                id,
                                name,
                                productCount,
                                children
                            };
                        })
                    );
                }
            })
            .addCase(getPaymentMethodList.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.paymentMethodList = action.payload.data.collection.map((method) => ({
                        id: method.id,
                        name: method.name
                    }));
                }
            })
            .addCase(getStatusList.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.statusList = action.payload.data.collection;
                }
            })
            .addCase(getVoucherProducts.fulfilled, (state, action) => {
                state.productList = action.payload.data;
            });
    }
});

const {reducer, actions} = adminMarketingVoucher;
export const {handleChangePage, handleChangePageSize, resetProductList} = actions;
export default reducer;
