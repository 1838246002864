export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const {
        intl,
        dispatch,
        payment,
        navigateTo,
        isOpenAddressFormModal,
        closeAddressFormModal,
        onOpenAddressFormModal,
        onSubmitAddressFormModal,
        addressListModalContent,
        onOpenAddressListModal,
        paymentMethod,
        setPaymentMethod,
        onDeleteUserAddress,
        onSaveDefaultAddress,
        isOpenDeleteAddressModal,
        closeDeleteAddressModal,
        onOpenDeleteAddressModal,
        addressModalType,
        selectedAddressObj,
        paymentProductsData,
        onPaymentOrder,
        selectedShippingUnit,
        setSelectedShippingUnit,
        note,
        setNote,
        shippingFee,
        setShippingFee,

        voucherDropdownDataState,
        voucherDropdownRef,
        openVoucherDropdown,
        closeVoucherDropdown,
        control,
        handleSubmit,
        isApplyDisabled,
        reset,
        voucherList,
        setAppliedVoucher,
        appliedVoucher,
        appliedVoucherValue
    } = props;

    return {
        navigateTo,
        addressListModalContent,
        transportInfoProps: {
            intl,
            transportInfoData: payment && payment.defaultAddress
        },
        addressFormModalProps: {
            intl,
            isOpenAddressFormModal,
            closeAddressFormModal,
            onOpenAddressFormModal,
            onSubmitAddressFormModal,
            cityList: payment && payment.addressList?.collection,
            addressModalType,
            selectedAddressObj
        },
        addressListModalProps: {
            intl,
            onOpenAddressListModal,
            userAddressList: payment && payment.userAddresses,
            onSaveDefaultAddress,
            onOpenAddressFormModal,
            onOpenDeleteAddressModal,
            addressListModalContent
        },
        paymentMethodProps: {
            intl,
            paymentMethodList: payment && payment.paymentMethods,
            paymentMethod,
            setPaymentMethod
        },
        deleteAddressModalProps: {
            intl,
            isOpenDeleteAddressModal,
            closeDeleteAddressModal,
            onDeleteUserAddress
        },
        productListProps: {
            intl,
            navigateTo,
            shippingUnitList: payment && payment.shippingUnits,
            defaultAddress: payment && payment.defaultAddress,
            paymentProductsData,
            selectedShippingUnit,
            setSelectedShippingUnit,
            note,
            setNote,
            shippingFee,
            setShippingFee,
            voucherDropdownDataState,
            voucherDropdownRef,
            openVoucherDropdown,
            closeVoucherDropdown,
            appliedVoucherValue,
            voucherModalProps: {
                intl,
                dispatch,
                voucherDropdownDataState,
                closeVoucherDropdown,
                control,
                isApplyDisabled,
                handleSubmit,
                voucherList,
                reset,
                paymentProductItems: paymentProductsData[0],
                setAppliedVoucher,
                appliedVoucher,
                selectedShippingUnit,
                paymentMethod
            }
        },
        yourBillProps: {
            intl,
            navigateTo,
            paymentProductsData,
            onPaymentOrder,
            shippingFee,
            appliedVoucherValue
        }
    };
}
