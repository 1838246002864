/* global grecaptcha */

import { pagePath } from 'configuration/routeConfig';
import { paramsKey } from 'configuration/storageKey';
import firebaseAuth from 'firebase-setup/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { checkValidationField } from 'utils/checkValidationField';
import { formatVietnamesePhone } from 'utils/formatPhoneNumber';
import { useQuery } from 'utils/hooks/useQuery';
import { Toast } from 'utils/toast';
import propsProvider from './AuthPropsProvider';
import { authenticate, handleStoredPhone } from './authSlice';
import AuthMainView from './template/AuthMainView';
import Config from 'configuration';

export default function AuthContainer(props) {
    const { dispatch, auth, history } = props;
    const query = useQuery();
    const [isEnteredSms, setIsEnteredSms] = useState(false);
    const intl = useIntl();
    const { enteredPhone } = auth;

    const sendOtp = async (reCaptchaContainer, data = null) => {
        try {
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.render().then(function (widgetId) {
                    grecaptcha.reset(widgetId);
                });
            }

            const appVerifier = new RecaptchaVerifier(reCaptchaContainer, { size: 'invisible' }, firebaseAuth);
            window.recaptchaVerifier = appVerifier;

            const formattedPhone = data ? formatVietnamesePhone(data) : formatVietnamesePhone(enteredPhone);
            const confirmationResult = await signInWithPhoneNumber(firebaseAuth, formattedPhone, appVerifier);

            window.confirmationResult = confirmationResult;
        } catch (err) {
            Toast(false, intl.messages.errorMessage);
        }
    };

    const handleSignInPhone = async (data) => {
        const { phoneNumber } = data;
        dispatch(handleStoredPhone(phoneNumber));

        await sendOtp('captcha-container', phoneNumber);
        Toast(true, intl.messages.checkPhone);
        setIsEnteredSms(true);
    };

    const handleLoginSubmit = async (data) => {
        try {
            let remember = data.remember
            delete data.remember
            if (!checkValidationField(data)) {
                Toast(false, intl.messages.validationError);
                return;
            }
            const handleLogin = await dispatch(authenticate(data)).unwrap();

            const { success, message } = handleLogin;
            if (success) {
                if (remember) {
                    localStorage.setItem("username", data.username)
                    localStorage.setItem("password", data.password)
                    localStorage.setItem(Config.storageKey.auth, sessionStorage.getItem(Config.storageKey.auth))
                } else {
                    localStorage.removeItem("username")
                    localStorage.removeItem("password")
                    localStorage.removeItem(Config.storageKey.auth)
                }
                navigateTo(query.get(paramsKey.returnUrl) || pagePath.rootUrl);
                Toast(success, 'Đăng nhập thành công');
            } else Toast(success, message);
        } catch (err) {
            console.log(err)
            Toast(false, intl.messages.loginErrorMessage);
        }
    };

    const handleInvalidSubmit = (_, event) => {
        event.target?.classList.add('wasvalidated');
    };

    const handleBack = () => {
        dispatch(handleStoredPhone(''));
        history.goBack();

        try {
            window.recaptchaVerifier.render().then(function (widgetId) {
                grecaptcha.reset(widgetId);
            });
        } catch (err) {
            Toast(false, intl.messages.errorMessage);
        }
    };

    const navigateTo = (path) => {
        history.push(path);
    };

    const authContainerProps = {
        navigateTo,
        intl,
        handleLoginSubmit,
        dispatch,
        handleSignInPhone,
        sendOtp,
        handleBack,
        handleInvalidSubmit,
        setIsEnteredSms,
        isEnteredSms,
        enteredPhone
    };
    return <AuthMainView {...propsProvider(authContainerProps)} />;
}
