import React, {useEffect, useState} from 'react';
import AddNotificationSection from './AddNotificationSection';
import {FormControl, FormHelperText} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import PreviewImage from 'components/PreviewImage/PreviewImage';
import InputFiles from '../../../../Product/AddProduct/template/subViews/InputFiles/InputFiles';
import Config from 'configuration';

const NotificationDetailSection = (props) => {
    const {
        intl,
        setIsImageUploadedError,
        isImageUploadedError,
        detailImages,
        setDetailImages,
        setNotificationDetail,
        selectedItem
    } = props;

    const AddNotificationSectionProps = {
        title: intl.messages.notificationDetailInfomation
    };

    const onChangeNotificationDetail = (event) => {
        setNotificationDetail(event);
    };

    const {
        control,
        watch,
        setValue,
        register,
        getFieldState,
        formState: {errors}
    } = useForm({
        defaultValues: {
            notificationDetail: selectedItem?.details ? selectedItem.details : '',
            images: []
        }
    });
    const watchAllFields = watch();
    const currentValue = watchAllFields['notificationDetail'];
    const isTouched = getFieldState('notificationDetail').isTouched;
    const helperText = `${currentValue?.length}/225 ${intl.messages.digit}`;
    const isErrorOnInput = currentValue?.length > 225 || (isTouched && currentValue?.length === 0);
    const maxImagesAllowed = 1;
    const [notificationValue, setNotificationValue] = useState();

    const handleUploadImages = async (e) => {
        const files = e.target.files;
        const previewImageUrls = [];
        let isOverSize = false;

        for (const file of files) {
            const {size} = file;

            if (size > 5 * 600 * 600) {
                isOverSize = true;
                return;
            }
            const fileUrl = await getBase64(file);
            const result = {image: fileUrl, file};
            previewImageUrls.push(result);
        }

        const newPreviewImageUrls = [...detailImages, ...previewImageUrls];
        const newImageFiles = [...watchAllFields['images'], ...files];

        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else if (newPreviewImageUrls.length > 9) {
            Toast(false, intl.messages.overLimit);
        } else {
            setDetailImages(newPreviewImageUrls);
            setValue('images', newImageFiles);
            setIsImageUploadedError(false);
        }
    };

    const deleteImage = (id) => {
        const currentImageFiles = watchAllFields['images'];

        const newCurrentImageFiles = currentImageFiles.filter((_, idx) => idx !== id);
        const newDetailImages = detailImages.filter((_, idx) => idx !== id);

        setValue('images', newCurrentImageFiles);
        setDetailImages(newDetailImages);
    };

    useEffect(() => {
        if (selectedItem) {
            setNotificationValue(selectedItem.details);
            setNotificationDetail(selectedItem.details);
            if (selectedItem.image) {
                const previewImageUrls = [];
                const result = {image: `${Config.apiConfig.endPoint}${selectedItem.image}`, file: null};
                previewImageUrls.push(result);
                setDetailImages(previewImageUrls);
            } else {
                setDetailImages([]);
            }
        } else {
            setDetailImages([]);
        }
    }, []);

    return (
        <AddNotificationSection {...AddNotificationSectionProps} className='detail-information'>
            <div className='row'>
                <div className='row'>
                    <span>{intl.messages.notificationDetailSubLabel}</span>
                </div>
                <div className='mb-3'>
                    <div className='d-flex align-items-center flex-wrap gap-3'>
                        {detailImages.map((image, idx) => (
                            <PreviewImage key={idx} image={image.image} onDeleteImage={deleteImage.bind(this, idx)} />
                        ))}
                        {detailImages.length < maxImagesAllowed && (
                            <InputFiles
                                type='image'
                                count={detailImages.length}
                                inputNotes={[intl.messages.formatImage, intl.messages.imageRequirement]}
                                accept={['.png', '.jpeg', '.jpg']}
                                handleUpload={handleUploadImages}
                                register={register}
                                name='images'
                            />
                        )}
                    </div>

                    {isImageUploadedError && <FormHelperText error>{intl.messages.imageError}</FormHelperText>}
                </div>
                <Controller
                    key={'notificationDetail'}
                    name='notificationDetail'
                    control={control}
                    rules={{required: true, maxLength: 75}}
                    render={({field: {onChange, ...restField}}) => (
                        <FormControl fullWidth>
                            <CustomInput
                                {...restField}
                                value={notificationValue}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    onChangeNotificationDetail(value);
                                    onChange(e);
                                    setNotificationValue(value);
                                }}
                                isRequired
                                label={intl.messages.notificationDetailInfomation}
                                placeholder={intl.messages.enternotificationDetailInfomation}
                                className='validate-input'
                                type='textarea'
                                valid={!(errors['notificationDetail'] || isErrorOnInput)}
                            />
                            <FormHelperText error={!!errors['notificationDetail'] || isErrorOnInput}>
                                {errors['notificationDetail']?.message
                                    ? errors['notificationDetail'].message
                                    : helperText}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
            </div>
        </AddNotificationSection>
    );
};

export default NotificationDetailSection;
