/* global grecaptcha */

import {authSelector} from 'app/selectors';
import {
    changePhone,
    changeUserPassword,
    checkPhoneIsHave,
    confirmOtpToChangeEmail,
    genOtpToChangeEmail,
    updateUser,
    uploadAvatar,
    getMyInfo
} from 'features/UserProfile/UserProfileSlice';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Toast} from 'utils/toast';
import propsProvider from './profilePropsProvider';
import ProfileMainView from './template/ProfileMainView';
import {RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';
import {formatVietnamesePhone} from 'utils/formatPhoneNumber';
import firebaseAuth from 'firebase-setup/firebase';
import {getAuthFirebaseErrorMessage} from 'utils/getAuthFirebaseErrorMsg';
import {pagePath} from 'configuration/routeConfig';

const ProfileContainer = (props) => {
    const {dispatch, history} = props;
    const auth = useSelector(authSelector);
    const intl = useIntl();
    const navigateTo = (path) => {
        history.push(path);
    };
    //Account Setting state
    const [isOpenUpdateModal, setOpenUpdateModal] = useState(false);
    const [isEmailModal, setEmailModal] = useState(false);
    const [isHaveUpdateSubmit, setHaveUpdateSubmit] = useState(false);
    const [newEmailChanged, setNewEmailChanged] = useState('');
    const [newPhoneChanged, setNewPhoneChanged] = useState('');
    const [enteredOTP, setEnteredOTP] = useState('');
    const [password, setPassword] = useState('');

    //Password Setting
    const handleSubmitChangePassword = async (data) => {
        const {email} = auth.userLoginInfo;
        const {oldPassword, newPassword, confirmPassword} = data;
        const changePasswordParams = {
            username: email,
            oldPassword,
            newPassword
        };

        if (newPassword === confirmPassword) {
            const response = await dispatch(changeUserPassword(changePasswordParams));
            const {message, success} = response.payload;

            Toast(success, message);
        }
    };
    const handleSubmitInvalid = (_, event) => {
        event.target.classList.add('wasvalidated');
    };

    //Account Setting
    const handleSubmitUpdateUserInfo = async (data) => {
        console.log(data)
        const {id} = auth.userLoginInfo;
        const {full_name, birthday, gender} = data;
        const updateUserBody = {
            full_name: full_name,
            birthday: birthday,
            gender: gender
        };
        // const response = await dispatch(updateUser({id: id, body: updateUserBody}));
        // const {message, success} = response.payload;
        // Toast(success, message);
        // if (success) {
        //     await dispatch(getMyInfo());
        // }
    };
    const handleUploadAvatar = async (data) => {
        if (!data) return;
        const {id} = auth.userLoginInfo;

        const bodyFormData = new FormData();
        bodyFormData.append('file', data);

        const response = await dispatch(uploadAvatar({id: id, body: bodyFormData}));
        const {message, success} = response.payload;
        Toast(success, message);
    };
    const onOpenEmailModal = () => {
        setOpenUpdateModal(true);
        setEmailModal(true);
    };
    const onOpenPhoneModal = () => {
        setOpenUpdateModal(true);
        setEmailModal(false);
    };
    const closeUpdateModal = () => {
        setOpenUpdateModal(false);
        setHaveUpdateSubmit(false);
    };
    const handleSubmitUpdateForm = async (data) => {
        const {id} = auth.userLoginInfo;
        let newEmail = '',
            oldPassword = '';

        if (data) {
            const {email, password} = data;
            newEmail = email;
            oldPassword = password;
            console.log(newEmail, password);
            setNewEmailChanged(email);
            setPassword(password);
        } else {
            newEmail = newEmailChanged;
            oldPassword = password;
        }

        const response = await dispatch(genOtpToChangeEmail({id: id, newEmail: newEmail, oldPassword: oldPassword}));
        const {value} = response.payload;
        const {success, message} = value;
        Toast(success, message);

        if (!success) return;
        setHaveUpdateSubmit(true);
    };
    const handleConfirmOtp = async () => {
        const {id} = auth.userLoginInfo;

        const response = await dispatch(
            confirmOtpToChangeEmail({id: id, newEmail: newEmailChanged, otp: enteredOTP, oldPassword: password})
        );
        const {value} = response.payload;
        const {success, message} = value;
        Toast(success, message);

        if (success) {
            setHaveUpdateSubmit(false);
            setOpenUpdateModal(false);
            navigateTo(pagePath.loginUrl);
            // await dispatch(getMyInfo());
        }
    };
    const handleBackUpdateForm = () => {
        setHaveUpdateSubmit(false);
    };
    const sendPhoneOtp = async (reCaptchaContainer, data = null) => {
        try {
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.render().then(function (widgetId) {
                    grecaptcha.reset(widgetId);
                });
            }
            const appVerifier = new RecaptchaVerifier(reCaptchaContainer, {size: 'invisible'}, firebaseAuth);
            window.recaptchaVerifier = appVerifier;

            const formattedPhone = formatVietnamesePhone(data);
            const confirmationResult = await signInWithPhoneNumber(firebaseAuth, formattedPhone, appVerifier);

            window.confirmationResult = confirmationResult;
        } catch (err) {
            Toast(false, intl.messages.errorMessage);
        }
    };
    const handleSendPhoneOTP = async (data) => {
        const {id} = auth.userLoginInfo;
        let newPhone = null;

        if (data) {
            const {phone} = data;
            newPhone = phone;
            setNewPhoneChanged(phone);
        } else {
            newPhone = newPhoneChanged;
        }

        const response = await dispatch(checkPhoneIsHave({id: id, newPhone: newPhone}));
        const {value} = response.payload;
        const {success, message} = value;

        if (success) {
            await sendPhoneOtp('captcha-container', newPhone);
            setHaveUpdateSubmit(true);
            Toast(true, 'Vui lòng kiểm tra số điện thoại của bạn');
        } else {
            Toast(false, message);
        }
    };
    const handleConfirmPhoneOTP = async () => {
        try {
            const {id} = auth.userLoginInfo;
            const {confirmationResult} = window;
            const firebaseResult = await confirmationResult.confirm(enteredOTP);
            if (firebaseResult) {
                const response = await dispatch(changePhone({id: id, newPhone: newPhoneChanged}));
                const {value} = response.payload;
                const {success, message} = value;

                Toast(success, message);
                if (success) {
                    setHaveUpdateSubmit(false);
                    setOpenUpdateModal(false);
                    navigateTo(pagePath.loginUrl);
                    // await dispatch(getMyInfo());
                }
            }
        } catch (err) {
            Toast(false, getAuthFirebaseErrorMessage(err.code, 'Lỗi xác nhận OTP'));
        }
    };

    const profileContainerProps = {
        dispatch,
        navigateTo,
        intl,
        handleSubmitChangePassword,
        handleSubmitInvalid,
        auth,
        handleSubmitUpdateUserInfo,
        handleUploadAvatar,
        onOpenEmailModal,
        onOpenPhoneModal,
        isEmailModal,
        isOpenUpdateModal,
        closeUpdateModal,
        handleSubmitUpdateForm,
        isHaveUpdateSubmit,
        handleBackUpdateForm,
        handleConfirmOtp,
        handleSendPhoneOTP,
        enteredOTP,
        setEnteredOTP,
        handleConfirmPhoneOTP
    };

    return <ProfileMainView {...propsProvider(profileContainerProps)} />;
};

export default ProfileContainer;
