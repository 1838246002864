import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import productApi from 'api/productApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {
        adminProductList: [],
        paginationData: Array(5).fill({ page: 1, pageSize: 10 }),
        categories: []
    };
};

export const getAdminProducts = createAsyncThunk('productList/getAdminProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data
    });
});

export const deleteProduct = createAsyncThunk('productList/deleteProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.deleteProduct,
        payload: data
    });
});

export const updateProduct = createAsyncThunk('productList/deleteProduct', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.updateProduct,
        payload: data
    });
});

export const getCategories = createAsyncThunk('viewAddProduct/categories', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getCategories
    });
});

const adminViewProducts = createSlice({
    name: 'adminViewProducts',
    initialState,
    reducers: {
        handleChangePage: (state, action) => {
            const { activePage, page } = action.payload;
            state.paginationData[activePage].page = page;
        },
        handleChangePageSize: (state, action) => {
            const { activePage, pageSize } = action.payload;
            state.paginationData[activePage].pageSize = pageSize;
        }
    },
    extraReducers: (builders) => {
        builders
            .addCase(getAdminProducts.fulfilled, (state, action) => {
                state.adminProductList = action.payload.data;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload.data;
            });
    }
});

const { reducer, actions } = adminViewProducts;
export const { handleChangePage, handleChangePageSize } = actions;
export default reducer;
