const i18nLabels = {
    vi: {
        notificationSetting: 'Bộ lọc thông báo',
        notificationList: 'Danh sách thông báo',
        search: 'Tìm kiếm',
        reInput: 'Đặt lại',
        cancel: 'Hủy',
        complete: 'Hoàn thành',
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        orderDate: 'Thời gian',
        notificationStatus: 'Trạng thái thông báo',
        enterNotificationStatus: 'Nhập Trạng thái thông báo',
        addNotification: 'Tạo thông báo',
        deleteFDtitle: 'Xóa thông báo',
        delteFdConfirm: 'Bạn có chắc muốn xóa thông báo này ?',
        successDelete: 'Xóa thông báo thành công !',
        notificationBasicInfomation: 'Thông tin cơ bản',
        noDataFound: 'Không có kết quả được tìm thấy',
        notificationName: 'Tên thông báo',
        enternotificationName: 'Nhập tên thông báo',
        digit: 'ký tự',
        notificationSend: 'Gửi ngay',
        notificationSchedule: 'Đặt lịch gửi',
        scheduleInfomation: 'Để tránh làm phiền người nhận, bạn chỉ có thể đặt thời gian gửi từ 9:00 - 21:00',
        scheduleDate: 'Lịch gửi',
        notificationTime: 'Thời gian',
        notificatinoDate: 'Ngày',
        notificationDetailInfomation: 'Nội dung thông báo',
        overSize: 'Tệp vượt quá giới hạn dung lượng cho phép',
        overLimit: 'Số tệp vượt quá lượng cho phép',
        formatImage: 'Định dạng tệp PNG, JPEG, JPG',
        imageRequirement: 'Kích thước: tối đa 5MB, độ phân giải tối đa 600x600 px',
        imageError: 'Cần tải lên ít nhất 1 ảnh',
        notificationDetailSubLabel: 'Nội dung gửi kèm',
        enternotificationDetailInfomation: 'Nhập nội dung thông báo tại đây',
        addNotificationSubmit: 'Lưu',
        addNotificationCancel: 'Hủy',
        sentScheduleError: 'Hãy nhập lịch gửi',
        sentImageError: 'Hãy thêm icon',
        sentScheduleTimeError: 'Lịch gửi không được sớm hơn thời điểm hiện tại',
        sentDetailAndNotificationError: 'Hãy nhập tên thông báo hoặc nội dung thông báo'
    },
    en: {
        notificationSetting: 'Notification Filter',
        notificationList: 'Notification List',
        search: 'Search',
        reInput: 'Reset',
        cancel: 'Cancel',
        complete: 'Complete',
        fromDate: 'From Date',
        toDate: 'To Date',
        orderDate: 'Order Date',
        notificationStatus: 'Notification Status',
        enterNotificationStatus: 'Enter Notification Status',
        addNotification: 'Create Notification',
        deleteFDtitle: 'Delete Notification',
        delteFdConfirm: 'Are you sure you want to delete this notification?',
        successDelete: 'Notification deleted successfully!',
        notificationBasicInfomation: 'Basic Information',
        noDataFound: 'No results found',
        notificationName: 'Notification Name',
        enternotificationName: 'Enter notification name',
        digit: 'characters',
        notificationSend: 'Send Now',
        notificationSchedule: 'Schedule Send',
        scheduleInfomation: 'To avoid disturbing the recipient, you can only schedule sending between 9:00 - 21:00',
        scheduleDate: 'Schedule Date',
        notificationTime: 'Time',
        notificatinoDate: 'Date',
        notificationDetailInfomation: 'Notification Details',
        overSize: 'File exceeds size limit',
        overLimit: 'Exceeded maximum number of files allowed',
        formatImage: 'File format: PNG, JPEG, JPG',
        imageRequirement: 'Size: maximum 5MB, maximum resolution 600x600 px',
        imageError: 'Please upload at least 1 image',
        notificationDetailSubLabel: 'Additional Message',
        enternotificationDetailInfomation: 'Enter notification content here',
        addNotificationSubmit: 'Save',
        addNotificationCancel: 'Cancel',
        sentScheduleError: 'Please enter a send schedule',
        sentImageError: 'Please add an icon',
        sentScheduleTimeError: 'Scheduled time cannot be earlier than the current time',
        sentDetailAndNotificationError: 'Please enter a notification name or notification content'
    }
};

export default i18nLabels;
