const i18nLabels = {
    vi: {
        designBannerTitle: 'Thiết kế banner quay vòng & văn bản',
        designBannerSubTitle:
            'Kết hợp hình ảnh, video trên Banner quay vòng và thêm mô tả Shop để giới thiệu gian hàng, sản phẩm và ưu đãi hiệu quả hơn.',
        uploadImage: 'Tải hình ảnh hoặc video',
        uploadImageShort: 'Tải tệp hình ảnh & video',
        addImageButtonLabel: 'Thêm mới hình ảnh/video',
        image: 'Hình ảnh',
        imageCapacity: 'Dung lượng: Tối đa 2MB',
        imageSize: 'Kích thước: Tối đa 2000 x 2000 px',
        imageFormat: 'Định dạng: JPG, JPEG, PNG, GIF',
        video: 'Video (không áp dụng cho video YouTube)',
        videoCapacity: 'Dung lượng: Tối đa 30MB',
        videoSize: 'Kích thước video: Tối đa 1280 x 1280 px',
        videoFormat: 'Thời lượng: 10-60 giây. Định dạng: MP4',
        cancel: 'Hủy',
        save: 'Lưu',
        actionFail: 'Thao tác thất bại',
        saveSuccess: 'Lưu thành công',
        overSize: 'Tệp vượt quá dung lượng cho phép',
        overLimit: 'Số lượng tệp vượt quá giới hạn cho phép'
    },
    en: {
        designBannerTitle: 'Design Rotating Banner & Text',
        designBannerSubTitle:
            'Combine images, videos on the Rotating Banner and add Shop description to effectively introduce your store, products, and promotions.',
        uploadImage: 'Upload Image or Video',
        uploadImageShort: 'Upload Image & Video Files',
        addImageButtonLabel: 'Add New Image/Video',
        image: 'Image',
        imageCapacity: 'Capacity: Maximum 2MB',
        imageSize: 'Size: Maximum 2000 x 2000 px',
        imageFormat: 'Format: JPG, JPEG, PNG, GIF',
        video: 'Video (not applicable for YouTube videos)',
        videoCapacity: 'Capacity: Maximum 30MB',
        videoSize: 'Video Size: Maximum 1280 x 1280 px',
        videoFormat: 'Duration: 10-60 seconds. Format: MP4',
        cancel: 'Cancel',
        save: 'Save',
        actionFail: 'Action Failed',
        saveSuccess: 'Save Successful',
        overSize: 'File size exceeds the allowed limit',
        overLimit: 'Number of files exceeds the allowed limit'
    }
};

export default i18nLabels;
