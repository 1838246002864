const i18nLabels = {
    vi: {
        homepage: 'Trang chủ',
        storeManagement: 'Quản lý cửa hàng',
        productManagement: 'Quản lý sản phẩm',
        voucherList: 'Danh sách voucher',
        allProducts: 'Tất cả sản phẩm',
        addProduct: 'Thêm sản phẩm',
        editProduct: 'Chỉnh sửa sản phẩm',
        orderManagement: 'Quản lý đơn hàng',
        orderDetail: 'Chi tiết đơn hàng',
        order: 'đơn hàng',
        addOrder: 'Thêm đơn hàng',
        totalPrice: 'Tổng thanh toán',
        dateOfOrder: 'Ngày đặt hàng',
        delivery: 'Vận chuyển',
        status: 'Trạng thái',
        marketing: 'Kênh marketing',
        financial: 'Tài chính',
        statisticData: 'Dữ liệu thống kê',
        customerService: 'Chăm sóc khách hàng',
        design: 'Thiết kế',
        designBanner: 'Thiết kế hình ảnh banner',
        designCategory: 'Thiết kế hình ảnh danh mục',
        shopSetting: 'Cấu hình cửa hàng',
        help: 'Trợ giúp',
        shopInformation: 'Thông tin cửa hàng',
        userManagement: 'Quản lý người dùng',
        categoryManagement: 'Quản lý danh mục',
        deliverySetting: 'Cài đặt vận chuyển',
        chatSetting: 'Cài đặt chat',
        subCategoryManagement: 'Thiết lập, quản lý danh mục ngành hàng',
        notificationSetting: 'Cài đặt thông báo',
        seeDetail: 'Xem chi tiết',
        prepareOrder: 'Chuẩn bị đơn hàng',
        cancelOrder: 'Hủy đơn hàng',
        printOrder: 'In phiếu giao',
        handOverForDelivery: 'Bàn giao cho ĐVVC',
        confirmOrder: 'Xác nhận đơn hàng',
        confirmDelivery: 'Xác nhận đã giao',
        confirmed: 'Đã xác nhận',
        waitForShippingUnit: 'Chờ bàn giao cho ĐVVC',
        deliveryFail: 'Giao không thành công',
        deliverySuccess: 'Giao thành công',
        shopCancel: 'Shop hủy',
        userCancel: 'Người mua hủy',
        process: 'Đã xử lý',
        unprocess: 'Chưa xử lý',
        preparingOrder: 'Đang chuẩn bị hàng',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công',
        accept: 'Đồng ý',
        reject: 'Từ chối',
        flashDeal: 'Flash deal'
    },
    en: {
        homepage: 'Home',
        storeManagement: 'Store Management',
        productManagement: 'Product Management',
        voucherList: 'Voucher List',
        allProducts: 'All Products',
        addProduct: 'Add Product',
        editProduct: 'Edit Product',
        orderManagement: 'Order Management',
        orderDetail: 'Order Detail',
        order: 'Order',
        addOrder: 'Add Order',
        totalPrice: 'Total Price',
        dateOfOrder: 'Date of Order',
        delivery: 'Delivery',
        status: 'Status',
        marketing: 'Marketing',
        financial: 'Financial',
        statisticData: 'Statistic Data',
        customerService: 'Customer Service',
        design: 'Design',
        designBanner: 'Design Banner Image',
        designCategory: 'Design Category Image',
        shopSetting: 'Shop Setting',
        help: 'Help',
        shopInformation: 'Shop Information',
        userManagement: 'User Management',
        categoryManagement: 'Category Management',
        deliverySetting: 'Delivery Setting',
        chatSetting: 'Chat Setting',
        subCategoryManagement: 'Subcategory Management',
        notificationSetting: 'Notification Setting',
        seeDetail: 'See Detail',
        prepareOrder: 'Prepare Order',
        cancelOrder: 'Cancel Order',
        printOrder: 'Print Delivery Note',
        handOverForDelivery: 'Hand Over for Delivery',
        confirmOrder: 'Confirm Order',
        confirmDelivery: 'Confirm Delivery',
        confirmed: 'Confirmed',
        waitForShippingUnit: 'Waiting for Shipping Unit',
        deliveryFail: 'Delivery Failed',
        deliverySuccess: 'Delivery Successful',
        shopCancel: 'Shop Canceled',
        userCancel: 'User Canceled',
        process: 'Processed',
        unprocess: 'Unprocessed',
        preparingOrder: 'Preparing Order',
        actionFail: 'Action Failed',
        actionSuccess: 'Action Successful',
        accept: 'Accept',
        reject: 'Reject',
        flashDeal: 'Flash Deal'
    }
};

export default i18nLabels;
