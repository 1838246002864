import {paymentSelector} from 'app/selectors';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PaymentContainer from './PaymentContainer';
import i18nLabels from './payment.i18n';
import DecorateContainer from 'components/DecorateContainer';
import {googleAnalyticsActions} from 'utils/googleAnalytics';

export default function Payment(props) {
    const dispatch = useDispatch();
    const payment = useSelector(paymentSelector);

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('Payment page');
    }, []);

    const paymentProps = {
        ...props,
        i18nLabels,
        dispatch,
        payment
    };
    return <DecorateContainer {...paymentProps} childComponent={PaymentContainer} />;
}
