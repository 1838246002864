import { Connection, Delivery, Logout, Profile } from 'assets/images';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';

export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
*/
    const {
        intl,
        navigateTo,
        dispatch,
        auth,
        showProductListSearchFilter,
        productList,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toggleDropdown,
        accountDropdownDataState,
        cart,
        header,
        goToNotification,
        notificationDropdownRef,
        accountDropdownRef,
        notiDropdownDataState,
        closeAccountDropdown,
        closeNotiDropdown
    } = props;

    const { isLogin, permission, userLoginInfo } = auth || undefined;
    const { full_name, avatar, email, phone } = userLoginInfo || {
        full_name: null,
        avatar: null,
        email: null,
        phone: null
    };
    const config = Config.apiConfig.endPoint;
    const { showSearchFilterState } = productList;

    const { cartList } = cart;
    const currentCartItems = cartList.length;

    const { notificationDetails, mobileTitle } = header;
    const { collection } = notificationDetails || { collection: [] };

    const accountDropdownData = [
        {
            label: intl.messages.myAccount,
            icon: <Profile />,
            path: pagePath.userUrl
        },
        {
            label: intl.messages.myOrder,
            icon: <Delivery />,
            path: pagePath.userMyPurchaseUrl
        },
        {
            label: intl.messages.signout,
            icon: <Logout />,
            path: 'logout'
        }
    ];

    if (Array.isArray(permission) && permission.length > 0) {
        accountDropdownData.splice(2, 0, {
            label: intl.messages.sellerPage,
            icon: <Connection />,
            path: pagePath.adminUrl
        });
    }

    return {
        isLogin,
        intl,
        navigateTo,
        full_name,
        avatar,
        config,
        showProductListSearchFilter,
        showSearchFilterState,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toggleDropdown,
        email,
        phone,
        currentCartItems,
        unreadNotifications: notificationDetails?.totalUnread,
        notiDropdownDataState,
        accountDropdownDataState,
        notificationDropdownRef,
        accountDropdownRef,
        goToNotification,
        notifications: collection,
        accountDropdownData,
        closeAccountDropdown,
        closeNotiDropdown,
        mobileTitle,
    };
}
