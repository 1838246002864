import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import React, {forwardRef, useRef} from 'react';
import {Controller} from 'react-hook-form';
import AddProductSection from '../AddProductSection/AddProductSection';

const OtherInformationSection = forwardRef((props, ref) => {
    const {intl, preOrderDate, setPreOrderDate, formActivated, isExpanded, toggleExpand, control, getValues} = props;

    const MIN_PREORDER_DATE = 7,
        MAX_PREORDER_DATE = 15;

    const addProductSectionProps = {
        title: intl.messages.progressOtherInformation,
        formActivated,
        isExpanded,
        toggleExpand
    };

    const orderItemStatusList = [
        {id: 'new', value: intl.messages.otherInformationSectionOrderStatusNew},
        {id: 'old', value: intl.messages.otherInformationSectionOrderStatusOld}
    ];

    const quantityRef = useRef(null);

    const incrementOrderDate = () => setPreOrderDate(preOrderDate + 1);
    const decrementOrderDate = () => setPreOrderDate(preOrderDate - 1);
    const changeOrderDate = (e) => setPreOrderDate(parseInt(e.target.value));
    const blurOrderDate = () => {
        if (quantityRef.current) {
            const value = quantityRef.current.childNodes[1].value;
            setPreOrderDate(
                value > MAX_PREORDER_DATE ? MAX_PREORDER_DATE : value < MIN_PREORDER_DATE ? MIN_PREORDER_DATE : value
            );
        }
    };

    return (
        <AddProductSection {...addProductSectionProps} ref={ref}>
            <h6 className='mt-1'>{intl.messages.otherInformationSectionIsPreOrder}</h6>
            <Controller
                name='require_preorder'
                control={control}
                render={({field}) => (
                    <RadioGroup row {...field}>
                        <FormControlLabel
                            value='false'
                            control={<Radio disableRipple />}
                            label={intl.messages.otherInformationSectionNo}
                        />
                        <FormControlLabel
                            value='true'
                            control={<Radio disableRipple />}
                            label={intl.messages.otherInformationSectionYes}
                        />
                    </RadioGroup>
                )}
            />

            {getValues('require_preorder') === 'true' && (
                <div className='d-flex align-items-center gap-2 mb-4'>
                    <span>{intl.messages.need}</span>{' '}
                    <CustomInputNumber
                        minValue={7}
                        maxValue={15}
                        quantity={preOrderDate}
                        decrementHandle={decrementOrderDate}
                        incrementHandle={incrementOrderDate}
                        handleQuantityChange={changeOrderDate}
                        handleQuantityBlur={blurOrderDate}
                        ref={quantityRef}
                    />{' '}
                    <span>{intl.messages.prepareDate}</span>
                </div>
            )}

            <div className='row form-group mb-4'>
                <div className='col'>
                    <Controller
                        name='condition'
                        control={control}
                        render={({field}) => (
                            <CustomSelect
                                label={intl.messages.otherInformationSectionOrderStatus}
                                options={orderItemStatusList}
                                noPlaceholder
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className='col'>
                    <Controller
                        name='sku'
                        control={control}
                        render={({field}) => (
                            <CustomInput label={intl.messages.otherInformationSectionOrderSKU} {...field} />
                        )}
                    />
                </div>
            </div>
        </AddProductSection>
    );
});

export default OtherInformationSection;
