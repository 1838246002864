import React from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './designCategoryPropsProvider';
import DesignCategoryMainView from './template/DesignCategoryMainView';

const DesignCategoryContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();

    const designCategoryContainerProps = {
        intl,
        dispatch,
        history
    };
    return <DesignCategoryMainView {...propsProvider(designCategoryContainerProps)} />;
};

export default DesignCategoryContainer;
