import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import propsProvider from './notificationSettingPropsProvider';
import NotificationSettingMainView from './template/NotificationSettingMainView.jsx';
import {
    getNotifications,
    addNotificationWithStatus,
    uploadNotificationImage,
    deleteNotifications,
    updateNotificationWithStatus
} from './NotificationSettingSlice';
import moment from 'moment';
import { notificationSettingSelector } from 'app/selectors';
import { useSelector } from 'react-redux';
import Events from 'utils/Events';
import { eventsKey } from 'configuration/storageKey';
import { Toast } from 'utils/toast';
import { calcTotalPage } from 'utils/calcTotalPage';

const pageSizeRange = [5, 10, 15, 20];
const NotificationSettingContainer = (props) => {
    const { dispatch, history } = props;
    const intl = useIntl();
    const navigateTo = (path) => {
        history.push(path);
    };
    const [isNotAdd, setIsAdd] = useState(true);
    const [filterData, setFilterData] = useState(['reference_link==created']);
    const notificationSetting = useSelector(notificationSettingSelector);
    const { notificationList } = notificationSetting;
    const [isSchedule, setIsSchedule] = useState(false);
    const [isImageUploadedError, setIsImageUploadedError] = useState(false);
    const [notificationName, setNotificationName] = useState('');
    const [detailImages, setDetailImages] = useState([]);
    const [notificationDetail, setNotificationDetail] = useState('');
    const [scheduleTime, setScheduleTime] = useState();
    const [scheduleDate, setScheduleDate] = useState();
    const [page, setPages] = useState(1);
    const [pageSize, setPageSizes] = useState(10);
    const [totalPage, setTotalPage] = useState(0);
    const [selectedItem, setselectedItem] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isDublicate, setIsDublicate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [deleteId, setDeleteId] = useState();

    //Notify Filter
    const submitFilterNotification = (data) => {
        const { fromDate, toDate, notificationStatus } = data;

        const tempFilterData = ['reference_link==created'];

        if (notificationStatus.length > 0 && notificationStatus !== 'all') {
            tempFilterData.push(`status_id==${notificationStatus}`);
        }
        if (fromDate) {
            tempFilterData.push(`sent_schedule>=${moment(new Date(fromDate)).format('YYYY/MM/DD')}`);
        }
        if (toDate) {
            tempFilterData.push(`sent_schedule<=${moment(new Date(toDate)).add(1, 'day').format('YYYY/MM/DD')}`);
        }
        setFilterData(tempFilterData);
    };

    const handleClodeModal = () => {
        setDeleteId(null);
    };

    const handleDelete = async () => {
        if (deleteId) {
            const res = await dispatch(deleteNotifications(deleteId));
            if (res.payload.success) {
                Toast(false, intl.messages.successDelete);
                let dataFilter = {
                    PageSize: pageSize,
                    Page: page
                };
                const respond = await dispatch(getNotifications(dataFilter));
                if (respond.payload.success) {
                    setDeleteId(null);
                    setTotalPage(calcTotalPage(res.payload.data.total, res.payload.data.pagesize));
                }
            }
        }
    };

    const handleUpdateNotificationList = () => {
        Events.emit(eventsKey.updateNotificationList);
    };

    useEffect(() => {
        try {
            const updateNotificationList = Events.addListener(eventsKey.updateNotificationList, async () => {
                let dataFilter = {
                    Filters: "main_category_type==1," + filterData.join(','),
                    Sorts: '-created_at',
                    PageSize: pageSize,
                    Page: page
                };
                const res = await dispatch(getNotifications({ params: dataFilter }));
                if (res.payload.success) {
                    setTotalPage(calcTotalPage(res.payload.data.total, res.payload.data.pagesize));
                }
            });
            handleUpdateNotificationList();

            return () => {
                updateNotificationList.remove();
            };
        } catch (e) {
            console.error(e);
        }
    }, [dispatch, filterData, page, pageSize, totalPage]);

    const getNotificationsF = async () => {
        let dataFilter = {
            Filters: "main_category_type==1," + filterData.join(','),
            Sorts: '-created_at',
            PageSize: pageSize,
            Page: page
        };
        const res = await dispatch(getNotifications({ params: dataFilter }));
        if (res.payload.success) {
            setTotalPage(calcTotalPage(res.payload.data.total, res.payload.data.pagesize));
        }
    };

    useEffect(() => {
        getNotificationsF();
    }, [isNotAdd, isEdit, isDublicate]);

    useEffect(() => {
        if (selectedItem) {
            selectedItem.sent_schedule ? setIsSchedule(true) : setIsSchedule(false);
        }
    }, [selectedItem]);

    const setPage = (value) => {
        setPages(value);
    };

    const setPageSize = (value) => {
        setPageSizes(value);
    };

    //Add Notify
    const handleRadioScheduleChange = (event) => {
        const value = event.target.value;
        if (value === 'true') setIsSchedule(true);
        else setIsSchedule(false);
    };

    const handleChangeDateBasicInfomation = (event) => {
        setScheduleDate(event);
    };

    const handleChangeTimeBasicInfomation = (event) => {
        setScheduleTime(event);
    };

    const handleSubmitNotification = async () => {
        //Validation
        if (notificationName === '' || notificationDetail === '') {
            Toast(false, intl.messages.sentDetailAndNotificationError);
            return;
        }
        if (isSchedule && (scheduleTime === undefined || scheduleDate === undefined)) {
            Toast(false, intl.messages.sentScheduleError);
            return;
        }
        if (scheduleDate === moment(new Date().toLocaleString()).format('YYYY/MM/DD')) {
            if (scheduleTime < moment(new Date().toLocaleString()).format('HH:mm') && isSchedule) {
                console.log(scheduleTime);
                Toast(false, intl.messages.sentScheduleTimeError);
                return;
            }
        }
        if (detailImages.length === 0) {
            Toast(false, intl.messages.sentImageError);
            return;
        }
        //Get Data
        const sentSchedule = isSchedule
            ? scheduleTime + ' ' + scheduleDate
            : moment(new Date().toLocaleString()).format('HH:mm YYYY/MM/DD');
        const notification = {
            summary: notificationName,
            details: notificationDetail,
            sent_schedule: sentSchedule,
            reference_link: 'created',
            main_category_type: 1
        };
        if (selectedItem && selectedItem.image && detailImages[0].file === null) {
            notification.image = selectedItem.image;
        }
        let notificationParams = {
            approvedStatusCode: isSchedule ? 'SCHEDULED' : 'SENT'
        };
        if (isEdit) {
            let notificationUpdateParams = {
                id: selectedItem.id,
                approvedStatusCode: isSchedule ? 'SCHEDULED' : 'SENT'
            };
            const res = await dispatch(
                updateNotificationWithStatus({ notificationUpdateParams, data: notification, id: selectedItem.id })
            );
            const { success, data: mainData, message } = res.payload;
            if (success) {
                const { id } = mainData;
                const fileFormData = new FormData();
                const file = detailImages[0].file;
                if (file) {
                    fileFormData.append('file', file);
                    const respond = await dispatch(uploadNotificationImage({ id, file: fileFormData }));
                    if (respond.payload.success) {
                        setDetailImages([]);
                        await dispatch(getNotificationsF());
                    }
                }
                setIsAdd(true);
                setIsEdit(false);
                setIsDublicate(false);
                setselectedItem(null);
                await dispatch(getNotificationsF());
                Toast(success, 'Chỉnh sửa thông báo thành công');
            } else {
                Toast(success, message);
            }
        } else {
            const notificationResponse = await dispatch(
                addNotificationWithStatus({ notificationParams, data: notification })
            );
            const { success, data: mainData, message } = notificationResponse.payload;
            if (success) {
                const { id } = mainData;
                const fileFormData = new FormData();
                const file = detailImages[0].file;
                if (file) {
                    fileFormData.append('file', file);
                    await dispatch(uploadNotificationImage({ id, file: fileFormData }));
                }
                // await dispatch(getNotifications());
                setIsAdd(true);
                setIsEdit(false);
                setIsDublicate(false);
                setselectedItem(null);
                Toast(success, message);
            } else {
                Toast(success, message);
            }
        }
    };

    const notificationSettingContainerProps = {
        ...props,
        navigateTo,
        dispatch,
        history,
        intl,
        submitFilterNotification,
        notificationList,
        notificationSetting,
        handleRadioScheduleChange,
        isSchedule,
        isImageUploadedError,
        setIsImageUploadedError,
        handleSubmitNotification,
        handleChangeDateBasicInfomation,
        handleChangeTimeBasicInfomation,
        notificationName,
        setNotificationName,
        detailImages,
        setDetailImages,
        notificationDetail,
        setNotificationDetail,
        isNotAdd,
        setIsAdd,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalPage,
        pageSizeRange,
        selectedItem,
        setselectedItem,
        isEdit,
        setIsEdit,
        isDublicate,
        setIsDublicate,
        isDelete,
        setIsDelete,
        deleteId,
        setDeleteId,
        handleClodeModal,
        handleDelete,
        setFilterData
    };

    return <NotificationSettingMainView {...propsProvider(notificationSettingContainerProps)} />;
};

export default NotificationSettingContainer;
