import {TableCell, TableHead, TableRow} from '@mui/material';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import React from 'react';

const CustomTableHead = (props) => {
    const {withCheckbox = false, handleCheckAll, headCells = [], selectedRows = 0, rowCount = 0} = props;

    return (
        <TableHead className='custom-tableHead'>
            <TableRow>
                {withCheckbox && (
                    <TableCell padding='checkbox'>
                        <CustomCheckbox
                            isContrast
                            color='primary'
                            indeterminate={selectedRows > 0 && selectedRows < rowCount}
                            checked={rowCount > 0 && selectedRows === rowCount}
                            onChange={handleCheckAll}
                            inputProps={{
                                'aria-label': 'select all data'
                            }}
                        />
                    </TableCell>
                )}
                {headCells.map((headCell, key) => {
                    const {id, align, padding, className = '', label, width} = headCell;

                    return (
                        <TableCell
                            key={`${id}-${key}`}
                            align={align}
                            padding={padding}
                            className={`custom-tableHead__cell ${className}`}>
                            {label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default CustomTableHead;
