import {EmptyNoti} from 'assets/images';
import React from 'react';

const NotificationEmpty = ({intl}) => {
    return (
        <div className='notification-empty'>
            <img src={EmptyNoti} alt='empty notification' />
            <span>{intl.messages.emptyNoti}</span>
        </div>
    );
};

export default NotificationEmpty;
