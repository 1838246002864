import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import ViewOrdersContainer from './ViewOrdersContainer';
import i18nLabels from "./viewOrders.i18n";

const ViewOrders = (props) => {
	const viewOrdersProps = {
		...props,
		i18nLabels
	};

	return (
		<DecorateContainer childComponent={ViewOrdersContainer}  {...viewOrdersProps} />
	);
};

export default ViewOrders;