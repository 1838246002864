import { adminOrderDetailSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './MyDetailPurchase.i18n';
import MyDetailPurchaseContainer from './MyDetailPurchaseContainer';

const MyDetailPurchase = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const adminOrderDetail = useSelector(adminOrderDetailSelector);

    const myDetailPurchaseProps = {
        ...props,
        dispatch,
        history,
        i18nLabels,
        adminOrderDetail
    };
    return <DecorateContainer {...myDetailPurchaseProps} childComponent={MyDetailPurchaseContainer} />;
};

export default MyDetailPurchase;
