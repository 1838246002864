const i18nLabels = {
    vi: {
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        firstLetter: 'DEAL',
        title: 'SỐC MỖI NGÀY',
        hotdealsPrice: '₫{price}',
        productCategoryLabel: 'Danh mục sản phẩm',
        readAll: 'Xem tất cả',
        sold: "Đã bán",
        recommendProduct: "Gợi ý sản phẩm 🧐",
        flashDeal: "Flash Deals 🔥",
        soldOut: "Bán hết",
        readMore: "Xem thêm",
    },
    en: {
        login: "Log in",
        signup: "Sign up",
        firstLetter: "DEAL",
        title: "SHOCK EVERY DAY",
        hotdealsPrice: "₫{price}",
        productCategoryLabel: "Product categories",
        readAll: "See all",
        sold: "Sold",
        recommendProduct: "Recommended products 🧐",
        flashDeal: "Flash Deals 🔥",
        soldOut: "Sold out",
        readMore: "Read more"
    }
};

export default i18nLabels;
