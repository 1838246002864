import React, { useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import { SearchIcon, HDLogo, Cart, filter, filterInactive, Bell, EmptyNoti, Translate } from 'assets/images';
import { pagePath } from 'configuration/routeConfig';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import { useLocation } from 'react-router';
import NotificationBlock from 'components/NotificationBlock/NotificationBlock';
import { Popover } from '@mui/material';
import { setLang } from '../headerSlice';
import { useDispatch } from 'react-redux';

const HeaderMainView = (props) => {
    const {
        isLogin,
        intl,
        navigateTo,
        full_name,
        avatar,
        config,
        showProductListSearchFilter,
        showSearchFilterState,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toggleDropdown,
        email,
        phone,
        currentCartItems,
        unreadNotifications,
        notiDropdownDataState,
        accountDropdownDataState,
        notificationDropdownRef,
        accountDropdownRef,
        goToNotification,
        notifications = [],
        accountDropdownData,
        closeAccountDropdown,
        closeNotiDropdown,
        mobileTitle,
    } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const isShowSearchFilter = showSearchFilterState === 'open';
    const isAdminPage = pathname.includes('admin');
    const isUserPage = pathname.includes('user');
    const isNotiPage = pathname.includes(pagePath.userNotiUrl);
    const isNotificationEmpty = notifications?.length === 0;
    const isCartButtonActive = pathname === pagePath.cartUrl;
    const [anchorShowTranslate, setAnchorShowTranslate] = useState(null);
    const changeLanguage = (locale) => {
        dispatch(setLang(locale));
        setAnchorShowTranslate(null);
        window.location.reload();
    };

    const handleShowTranslate = (event) => {
        setAnchorShowTranslate(event.currentTarget);
    };

    const handleCloseTranslate = () => {
        setAnchorShowTranslate(null);
    };

    return (
        <>
            <div className='header'>
                <WrapperContainer>
                    <div className='d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between'>
                        <div className='header-logo'>
                            <img
                                src={HDLogo}
                                alt='logo'
                                className='img-fluid'
                                onClick={() => navigateTo(pagePath.rootUrl)}
                            />
                        </div>
                        {!isAdminPage && !isUserPage && (
                            <div className='header-search'>
                                <form
                                    className='w-100 h-100'
                                    onSubmit={handleSubmit(onSearchProduct)}
                                    autoComplete='false'>
                                    <input
                                        type='search'
                                        placeholder={intl.messages.searchLabel}
                                        {...register('searchValue')}
                                        autoComplete='false'
                                    />
                                    <button type='submit' className='search-icon'>
                                        <SearchIcon />
                                    </button>
                                </form>
                                {isInProductList && (
                                    <div
                                        className='header-search__filter d-flex d-sm-none'
                                        onClick={showProductListSearchFilter}>
                                        <img src={isShowSearchFilter ? filterInactive : filter} alt='filter' />
                                        <span className={isShowSearchFilter ? 'inactive' : ''}>
                                            {intl.messages.filter}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='header-account d-flex align-items-center'>
                            {/* <div className='translate-icon'>
                                <ButtonIcon
                                    icon={<Translate />}
                                    onClick={handleShowTranslate}
                                    label='translate'
                                    isActive={!!anchorShowTranslate}
                                />
                                <Popover
                                    className='header-account__translate'
                                    open={!!anchorShowTranslate}
                                    anchorEl={anchorShowTranslate}
                                    onClose={handleCloseTranslate}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}>
                                    <p onClick={() => changeLanguage('vi')}>{intl.messages.vietnamese}</p>
                                    <p onClick={() => changeLanguage('en')}>{intl.messages.english}</p>
                                </Popover>
                            </div> */}

                            {isLogin ? (
                                <>
                                    <div className='cart-icon'>
                                        <ButtonIcon
                                            icon={<Cart />}
                                            onClick={() => navigateTo(pagePath.cartUrl)}
                                            label='cart'
                                            quantity={currentCartItems}
                                            isActive={isCartButtonActive}
                                        />
                                    </div>

                                    <div className='notification-icon' style={{ position: 'relative' }}>
                                        <ButtonIcon
                                            icon={<Bell />}
                                            label='notification'
                                            quantity={unreadNotifications}
                                            onClick={() => toggleDropdown('noti')}
                                            isActive={notiDropdownDataState === 'open' || isNotiPage}
                                        />
                                        <CustomDropdown
                                            closeDropdown={closeNotiDropdown}
                                            dropdownState={notiDropdownDataState}
                                            ref={notificationDropdownRef}
                                            className='header-account__notification'>
                                            <div className='notification-header'>{intl.messages.notification}</div>

                                            <div className='notification-body'>
                                                {!isNotificationEmpty ? (
                                                    notifications.map((noti, idx) => (
                                                        <NotificationBlock
                                                            noti={noti}
                                                            key={`${noti.summary}-${idx}`}
                                                            navigateTo={navigateTo}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className='empty-notification'>
                                                        <img src={EmptyNoti} alt='empty notification' />
                                                        <span>{intl.messages.emptyNoti}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {!isNotificationEmpty && (
                                                <div
                                                    className='notification-footer'
                                                    role='button'
                                                    onClick={goToNotification}>
                                                    {intl.messages.readAll}
                                                </div>
                                            )}
                                        </CustomDropdown>
                                    </div>
                                    <div
                                        className='header-account__info d-flex align-items-center gap-2'
                                        onClick={() => toggleDropdown('account')}>
                                        <img src={`${config}${avatar}`} alt='' className='account-avatar' />
                                        <div>
                                            <div className='account-name d-none d-sm-block'>
                                                {full_name ? full_name : email ? email : phone}
                                            </div>
                                        </div>
                                        <CustomDropdown
                                            closeDropdown={closeAccountDropdown}
                                            dropdownState={accountDropdownDataState}
                                            ref={accountDropdownRef}
                                            className='account-dropdown'>
                                            {accountDropdownData.map((data) => (
                                                <div
                                                    className='account-dropdown__item'
                                                    key={data.label}
                                                    onClick={() => navigateTo(data.path)}>
                                                    {data.icon}
                                                    <span>{data.label}</span>
                                                </div>
                                            ))}
                                        </CustomDropdown>
                                    </div>
                                </>
                            ) : (
                                <div className='account-group'>
                                    <CustomButton
                                        variant='text'
                                        className='header-account__signup'
                                        onClick={() => navigateTo(pagePath.signUpUrl)}>
                                        {intl.messages.signup}
                                    </CustomButton>
                                    <CustomButton
                                        variant='outlined'
                                        className='header-account__login'
                                        onClick={() => navigateTo(pagePath.authUrl)}>
                                        {intl.messages.login}
                                    </CustomButton>
                                </div>
                            )}
                        </div>
                    </div>
                </WrapperContainer>
            </div>
            {mobileTitle && (
                <WrapperContainer>
                    <div className='mobile-title d-block d-xs-none mb-2'>{mobileTitle}</div>
                </WrapperContainer>
            )}
        </>
    );
};

export default HeaderMainView;
