import React, {useEffect, useState} from 'react';
import './voucherList.scss';
import {Plus, WhitePlus} from 'assets/images';
import {useHistory} from 'react-router-dom';
import EmptyTableData from 'components/EmptyTableData/EmptyTableData';
import {AddNewModal} from './AddNewModal';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import {FormattedNumber} from 'react-intl';
import {FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import {ItemCard} from './ItemCard';
import {useDispatch} from 'react-redux';
import {addNewHotDeal, getProducts, updateFlashDeal, upDateProductTypes} from '../../FlashDealSlice';
import Config from 'configuration';
import {async} from '@firebase/util';

export const FlashDealAddNew = (props) => {
    const {
        intl,
        handleAddNewModal,
        setAddNewModal,
        isAddNewModal,
        startDate,
        endDate,
        isEdit,
        setIsEdit,
        flashDealEditData,
        setIsAddNew,
        isDublicate,
        setDublicate,
        errorDate,
        errorEndDate,
        setErrorEndDate
    } = props;
    const [checkedData, setCheckedData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAllCheckboxChecked, setSelectAllCheckboxChecked] = useState(false);
    const [allDiscount, setAllDiscount] = useState(0);
    const [allItemDiscount, setAllItemDiscount] = useState(0);
    const [discount, setDiscound] = useState(0);
    const [amountItemDiscount, setAmountItemDiscount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState();
    const [limit, setLimit] = useState(true);
    const [editSelected] = useState();
    const [deleteId, setDeleteId] = useState();
    const history = useHistory();

    const dispatch = useDispatch();
    const selectedRows = selectedItems.length;

    const addNewFlashDeal = async () => {
        const selectedItems = getSelectedObjects();
        selectedItems.map((e) => {
            e.extend_product_type.map(async (item) => {
                const data = {
                    id: item.id,
                    body: {
                        ...item,
                        product_id: e.id,
                        quantity: item.sale_quantity,
                        price: item.origin_price - (item.origin_price * Number.parseInt(item.discount_price)) / 100
                    }
                };
                const res = await dispatch(upDateProductTypes(data));
            });
        });
        if (isEdit) {
            const data = {
                id: flashDealEditData.id,
                product_id: selectedItems[0].id,
                start_deal: startDate,
                end_deal: endDate,
                product_list: JSON.stringify(selectedItems),
                total_product: selectedRows
            };
            const res = await dispatch(updateFlashDeal(data));
            if (res.payload.success) {
                history.push('/admin/flash-deal');
            }
        } else {
            const data = {
                product_id: selectedItems[0].id,
                start_deal: startDate,
                end_deal: endDate,
                product_list: JSON.stringify(selectedItems),
                total_product: selectedRows
            };
            const res = await dispatch(addNewHotDeal(data));
            if (res.payload.success) {
                history.push('/admin/flash-deal');
            }
        }
        setIsEdit(false);
        setDublicate(false);
        setIsAddNew(false);
    };

    const DeleteModal = () => {
        return (
            <Dialog open={deleteId} onClose={handleCloseModal}>
                <DialogTitle>{intl.messages.deleteFDtitle}</DialogTitle>
                <DialogContent>{intl.messages.delteFdConfirm}</DialogContent>
                <DialogActions>
                    <CustomButton
                        size='md'
                        variant='text'
                        onClick={(e) => {
                            handleCloseModal();
                        }}>
                        Hủy
                    </CustomButton>
                    <CustomButton color='danger' size='md' onClick={() => handleDeleteItem(deleteId)}>
                        Xác nhận
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    };

    const handleParentCheckboxChange = (parentId) => {
        const parentItem = tableData.find((item) => item.id === parentId);
        const allChildrenSelected = parentItem?.extend_product_type?.every((child) => selectedItems.includes(child.id));

        let newSelectedItems = [...selectedItems];

        if (allChildrenSelected) {
            parentItem.extend_product_type.forEach((child) => {
                newSelectedItems = newSelectedItems.filter((id) => id !== child.id);
            });
        } else {
            parentItem.extend_product_type.forEach((child) => {
                if (!selectedItems.includes(child.id)) {
                    newSelectedItems.push(child.id);
                }
            });
        }

        setSelectedItems(newSelectedItems);
    };

    const handleChildCheckboxChange = (childId, parentId) => {
        const newSelectedItems = [...selectedItems];
        const childIndex = newSelectedItems.indexOf(childId);

        if (childIndex === -1) {
            newSelectedItems.push(childId);
        } else {
            newSelectedItems.splice(childIndex, 1);
        }

        const parentItem = tableData.find((item) => item.id === parentId);
        const allChildrenSelected = parentItem.extend_product_type?.every((child) =>
            newSelectedItems.includes(child.id)
        );

        if (allChildrenSelected) {
            // newSelectedItems.push(parentId);
        } else {
            const parentIndex = newSelectedItems.indexOf(parentId);
            if (parentIndex !== -1) {
                newSelectedItems.splice(parentIndex, 1);
            }
        }

        setSelectedItems(newSelectedItems);
    };

    const handleSelectAllCheckboxChange = () => {
        const newSelectedItems = selectAllCheckboxChecked
            ? []
            : tableData.reduce((acc, item) => {
                  if (item.extend_product_type.length) {
                      item.extend_product_type.forEach((child) => {
                          acc.push(child.id);
                      });
                  } else {
                      acc.push(item.id);
                  }
                  return acc;
              }, []);

        setSelectedItems(newSelectedItems);
        setSelectAllCheckboxChecked(!selectAllCheckboxChecked);
    };

    const handleSelectRow = (event, id) => {
        const selectedIndex = checkedData.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedData, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedData.slice(1));
        } else if (selectedIndex === checkedData.length - 1) {
            newSelected = newSelected.concat(checkedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(checkedData.slice(0, selectedIndex), checkedData.slice(selectedIndex + 1));
        }

        setCheckedData(newSelected);
    };

    const removeVariantById = (idToRemove) => {
        const newData = tableData.map((item) => {
            if (item.extend_product_type) {
                item.extend_product_type = item.extend_product_type.filter((variant) => variant.id !== idToRemove);
            }
            return item;
        });
        let indexToRemove = newData.findIndex((obj) => obj.id === idToRemove);
        if (indexToRemove !== -1) {
            newData.splice(indexToRemove, 1);
        }
        return newData;
    };

    const handleCloseModal = () => {
        setDeleteId(null);
    };

    const handleDeleteItem = (itemID) => {
        const newData = removeVariantById(itemID);
        const newSelected = selectedItems.filter((e) => e !== itemID);
        setTableData(newData);
        setSelectedItems(newSelected);
        setDeleteId(null);
    };

    const handleApplyAll = () => {
        setAllDiscount(discount);
        setAllItemDiscount(amountItemDiscount);
    };

    const getSelectedObjects = () => {
        const selectedObjects = [];
        tableData?.forEach((parentItem) => {
            const selectedChildren = [];
            parentItem.extend_product_type.forEach((childItem) => {
                if (selectedItems.includes(childItem.id)) {
                    selectedChildren.push(childItem);
                }
            });
            if (selectedChildren.length > 0) {
                selectedObjects.push({
                    ...parentItem,
                    extend_product_type: selectedChildren
                });
            }
            if (selectedItems.includes(parentItem.id)) {
                selectedObjects.push(parentItem);
            }
        });
        return selectedObjects;
    };
    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
    };
    useEffect(() => {
        if (selectedRows > 0 && selectedRows === rowCount) {
            setSelectAllCheckboxChecked(true);
        } else {
            setSelectAllCheckboxChecked(false);
        }
    }, [selectedItems]);
    useEffect(() => {
        if (tableData) {
            let totalItem = 0;
            tableData.forEach((e) => {
                if (e.extend_product_type.length) {
                    totalItem += e.extend_product_type.length;
                } else {
                    totalItem += 1;
                }
            });
            setRowCount(totalItem);
        }
    }, [tableData]);
    useEffect(() => {
        if (flashDealEditData) {
            if (isEdit || isDublicate) {
                const data = JSON.parse(flashDealEditData?.product_list);
                let selected = [];
                data?.map((item) => {
                    if (item.extend_product_type.length) {
                        item.extend_product_type?.map((e) => {
                            selected.push(e.id);
                        });
                    } else {
                        selected.push(item.id);
                    }
                });
                setSelectedItems(selected);
                setTableData(data);
            }
        }
    }, [flashDealEditData]);
    useEffect(() => {
        if (selectedItems) console.log(selectedItems);
    }, [selectedItems]);
    return (
        <>
            <AddNewModal
                intl={intl}
                handleAddNewModal={handleAddNewModal}
                isAddNewModal={isAddNewModal}
                setTableData={setTableData}
                tableData={tableData}></AddNewModal>
            <div className='table-voucher-container'>
                <div className='table-voucher-header'>
                    <h1>Sản Phẩm tham gia Flash Sale</h1>
                    <button className='button-addProduct' onClick={handleAddNewModal}>
                        <img src={WhitePlus} alt='plus' />
                        <p>Thêm sản phẩm</p>
                    </button>
                </div>
                {selectedItems.length < 2 ? null : (
                    <div className='applyAll-flashSale'>
                        <p>{intl.messages.allSelected}</p>
                        <div className='applyAll-flashSale-content'>
                            <FormControl variant='outlined'>
                                <InputLabel>{intl.messages.promotion}</InputLabel>
                                <OutlinedInput
                                    className='percent-input'
                                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                                    label={intl.messages.promotion}
                                    value={discount}
                                    onChange={(e) => setDiscound(e.target.value)}
                                    inputProps={{
                                        'aria-label': 'select all data'
                                    }}></OutlinedInput>
                            </FormControl>
                            <FormControl size='small' variant='outlined'>
                                <InputLabel>{intl.messages.ammountPromotion}</InputLabel>
                                <OutlinedInput
                                    className='ammountPromotion-input'
                                    value={amountItemDiscount}
                                    label={intl.messages.ammountPromotion}
                                    onChange={(e) => setAmountItemDiscount(e.target.value)}
                                    inputProps={{
                                        'aria-label': 'select all data'
                                    }}></OutlinedInput>
                            </FormControl>
                            {/* <FormControl size='small'>
                                <InputLabel>{intl.messages.limitOrder}</InputLabel>
                                <Select
                                    className={'limit-input'}
                                    value={limit}
                                    onChange={handleChangeLimit}
                                    input={<OutlinedInput notched label={intl.messages.limitOrder} />}>
                                    <MenuItem value={true}>{intl.messages.limit}</MenuItem>
                                    <MenuItem value={false}>{intl.messages.noLimit}</MenuItem>
                                </Select>
                            </FormControl> */}
                            <button className='all-update' onClick={handleApplyAll}>
                                {intl.messages.update}
                            </button>
                            <button
                                className='all-delete'
                                onClick={() => {
                                    setAllDiscount(0);
                                    setAllItemDiscount(0);
                                    setDiscound(0);
                                    setAmountItemDiscount(0);
                                }}>
                                {intl.messages.delete}
                            </button>
                        </div>
                    </div>
                )}
                <div className='flashDeal-table'>
                    <div className='flashDeal-table__table'>
                        <header className='flashDeal-table__table__header'>
                            <div className='flashDeal-table__table__header__cell col-checkbox'>
                                {tableData && (
                                    <CustomCheckbox
                                        disableRipple
                                        isContrast
                                        color='primary'
                                        indeterminate={selectedRows > 0 && selectedRows < rowCount}
                                        checked={selectAllCheckboxChecked}
                                        onChange={handleSelectAllCheckboxChange}
                                        inputProps={{
                                            'aria-label': 'select all data'
                                        }}
                                    />
                                )}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-classified'>
                                {intl.messages.classified}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-price'>{intl.messages.price}</div>
                            <div className='flashDeal-table__table__header__cell col-discountPrice'>
                                {intl.messages.discountPrice}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-promotion'>
                                {intl.messages.promotion}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-ammountPromotion'>
                                {intl.messages.ammountPromotion}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-storage'>
                                {intl.messages.storage}
                            </div>
                            <div className='flashDeal-table__table__header__cell col-action'>
                                {intl.messages.action}
                            </div>
                        </header>
                        <div className='flashDeal-table__table__card'>
                            {!tableData ? (
                                <EmptyTableData></EmptyTableData>
                            ) : (
                                tableData?.map((data) => {
                                    const isItemchecked = checkedData.includes(data.id);
                                    return (
                                        <div className='flashDeal-addNew__table__card' key={data.id}>
                                            <div className='table-firstrow'>
                                                <div className='col-checkbox'>
                                                    {data.extend_product_type.length ? (
                                                        <CustomCheckbox
                                                            disableRipple
                                                            color='primary'
                                                            checked={data.extend_product_type.every((child) =>
                                                                selectedItems.includes(child.id)
                                                            )}
                                                            onChange={() => handleParentCheckboxChange(data.id)}
                                                            inputProps={{
                                                                'aria-label': 'select all data'
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                                <div className='col-product'>
                                                    <img
                                                        src={`${Config.apiConfig.endPoint}${data.default_image}`}
                                                        alt=''
                                                    />
                                                    <div className='product-content'>
                                                        <p>{data.name}</p>
                                                        {data?.extend_category.name}
                                                    </div>
                                                </div>
                                            </div>
                                            {data?.extend_product_type.length > 0 ? (
                                                data?.extend_product_type?.map((e, idx) => (
                                                    <ItemCard
                                                        tableData={tableData}
                                                        isEdit={isEdit}
                                                        setDeleteId={setDeleteId}
                                                        flashDealEditData={flashDealEditData}
                                                        handleDeleteItem={handleDeleteItem}
                                                        isDublicate={isDublicate}
                                                        setDublicate={setDublicate}
                                                        setTableData={setTableData}
                                                        allItemDiscount={allItemDiscount}
                                                        allDiscount={allDiscount}
                                                        data={e}
                                                        key={idx}
                                                        handleSelectRow={handleSelectRow}
                                                        isItemchecked={isItemchecked}
                                                        handleChildCheckboxChange={handleChildCheckboxChange}
                                                        selectedItems={selectedItems}
                                                        item={data}
                                                    />
                                                ))
                                            ) : (
                                                <ItemCard
                                                    tableData={tableData}
                                                    isEdit={isEdit}
                                                    setDeleteId={setDeleteId}
                                                    flashDealEditData={flashDealEditData}
                                                    handleDeleteItem={handleDeleteItem}
                                                    isDublicate={isDublicate}
                                                    setDublicate={setDublicate}
                                                    setTableData={setTableData}
                                                    allItemDiscount={allItemDiscount}
                                                    allDiscount={allDiscount}
                                                    handleSelectRow={handleSelectRow}
                                                    isItemchecked={isItemchecked}
                                                    handleChildCheckboxChange={handleChildCheckboxChange}
                                                    selectedItems={selectedItems}
                                                    item={data}
                                                    data={data}
                                                />
                                            )}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flashDeal-footer'>
                <CustomButton
                    size='md'
                    variant='text'
                    onClick={(e) => {
                        e.preventDefault();
                        setIsAddNew(false);
                        history.push('/admin/flash-deal');
                    }}>
                    Hủy
                </CustomButton>
                <CustomButton
                    size='md'
                    disabled={selectedItems.length === 0 || errorDate || errorEndDate}
                    onClick={() => addNewFlashDeal()}>
                    Xác nhận
                </CustomButton>
            </div>
            <DeleteModal></DeleteModal>
        </>
    );
};
