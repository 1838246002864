import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import chatRoomApi from 'api/chatRoomApi';
import orderApi from 'api/orderApi';
import productApi from 'api/productApi';
import serviceRequest from 'app/serviceRequest';

const initialState = () => {
    return {};
};

export const getAllMyChatRooms = createAsyncThunk('chatBox/getAllMyChatRooms', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.getAllMyChatRooms,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getChatRoom = createAsyncThunk('chatBox/getChatRoom', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.getChatRoom,
        payload: data
    });
});

export const createChatRoom = createAsyncThunk('chatBox/createChatRoom', async (_, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.createChatRoom,
        payload: _
    });
});

export const sendTextMessage = createAsyncThunk('chatBox/sendTextMessage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.sendTextMessage,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const markAsRead = createAsyncThunk('chatBox/markAsRead', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.markAsRead,
        payload: data
    });
});

export const pinChat = createAsyncThunk('chatBox/pinChat', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.pinChat,
        payload: data
    });
});

export const readChatRoomMessage = createAsyncThunk('chatBox/readChatRoomMessage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.readChatRoomMessage,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const uploadImageMessage = createAsyncThunk('chatBox/uploadImageMessage', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: chatRoomApi.uploadImageMessage,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getAllProducts = createAsyncThunk('chatBox/getAllProducts', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getProductById = createAsyncThunk('chatBox/getProductById', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProductDetails,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getMyOrders = createAsyncThunk('chatBox/getMyOrders', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getMyOrders,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getOrdersByUserId = createAsyncThunk('chatBox/getOrdersByUserId', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrders,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

export const getOrderById = createAsyncThunk('chatBox/getOrderById', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: orderApi.getOrderById,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

const chatBox = createSlice({
    name: 'chatBox',
    initialState,
    reducers: {},
    extraReducers: (builders) => {}
});

const {reducer} = chatBox;
export default reducer;
