/* eslint-disable jsx-a11y/img-redundant-alt */
import {useState} from 'react';
import {Approve, couponFrame, DownArrow, notes, Shipper, upperArrow, Voucher} from 'assets/images';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import {pagePath} from 'configuration/routeConfig';
import {Dropdown} from 'antd';
import moment from 'moment';
import {FormattedNumber} from 'react-intl';
import {useEffect} from 'react';
import Config from 'configuration';
import {useDispatch} from 'react-redux';
import {
    calculateShippingFeeGHN,
    getAllDistrictByProvinceGHN,
    getAllProvinceGHN,
    getAllWardByDistrictGHN
} from 'features/Payment/paymentSlice';
import {Toast} from 'react-bootstrap';
import VoucherModal from '../VoucherModal/VoucherModal';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import {truncateMoneyValue} from 'utils/truncateMoneyValue';
import {formatThousands} from 'utils/formatThousands';

const ShippingUnitItem = ({item, selectedShippingUnit}) => {
    return (
        <div className='d-flex justify-content-between align-items-center' style={{width: 250}}>
            <div style={{flex: 1}}>
                <div className='d-flex align-items-center'>
                    <span
                        style={{
                            color: '#333',
                            fontSize: 13,
                            fontWeight: 500
                        }}>
                        {item.name} &nbsp;
                    </span>
                    {/* <div
                        style={{
                            width: 4,
                            height: 4,
                            backgroundColor: '#c3c9d0',
                            borderRadius: '50%'
                        }}></div>
                    <span
                        style={{
                            color: '#333',
                            fontSize: 13,
                            fontWeight: 500
                        }}>
                        &nbsp; <FormattedNumber style='currency' currency='VND' value={item.unit_price_per_km} />
                    </span> */}
                </div>
                <div>
                    <span style={{fontSize: 10, color: '#808080'}}>
                        Nhận hàng {moment(item.receive_from_at).format('DD/MM/YYYY')} đến{' '}
                        {moment(item.receive_to_at).format('DD/MM/YYYY')}
                    </span>
                </div>
            </div>
            {selectedShippingUnit?.id === item.id && <Approve />}
        </div>
    );
};

const ProductList = (props) => {
    const {
        intl,
        navigateTo,
        shippingUnitList,
        defaultAddress,
        paymentProductsData,
        selectedShippingUnit,
        setSelectedShippingUnit,
        note,
        setNote,
        shippingFee,
        setShippingFee,
        voucherDropdownDataState,
        voucherDropdownRef,
        openVoucherDropdown,
        closeVoucherDropdown,
        voucherModalProps,
        appliedVoucherValue
    } = props;
    const [isOpenDropdown, setOpenDropDown] = useState(false);
    const dispatch = useDispatch();
    const [toDistrictId, setToDistrictId] = useState([]);
    const [toWardCode, setToWardCode] = useState([]);

    const shippingUnitItems =
        shippingUnitList &&
        shippingUnitList.map((item, idx) => ({
            label: <ShippingUnitItem item={item} selectedShippingUnit={selectedShippingUnit} />,
            key: idx
        }));

    const onClick = async ({key}) => {
        const selectedUnit = shippingUnitList[key];
        if (selectedUnit) {
            setSelectedShippingUnit(selectedUnit);
            if (selectedUnit.name === 'Giao Hàng Nhanh') {
                const res = await dispatch(
                    calculateShippingFeeGHN({
                        from_district_id: 1455, //Haidang's district_id (GHN)
                        service_id: 53320,
                        service_type_id: 2,
                        to_district_id: toDistrictId,
                        to_ward_code: toWardCode,
                        height: 20,
                        length: 20,
                        weight: 20,
                        width: 20
                    })
                ).unwrap();
                if (res && res.status === 200) {
                    const {data} = res;
                    setShippingFee(data.data.total);
                } else {
                    Toast(false, 'Lỗi khi lấy phí vận chuyển, vui lòng thử lại sau');
                }
            } else {
                setShippingFee(0);
            }
        }
        setOpenDropDown(!isOpenDropdown);
    };

    const fetchProvinceListGHN = async () => {
        const res = await dispatch(getAllProvinceGHN()).unwrap();
        if (res && res.status === 200) {
            const {data} = res;
            const provinceId = data.data.find((el) => el.ProvinceName === defaultAddress.province).ProvinceID;

            const response = await dispatch(getAllDistrictByProvinceGHN({province_id: provinceId})).unwrap();
            if (response && response.status === 200) {
                const {data} = response;
                const districtId = data.data.find((el) => el.DistrictName === defaultAddress.district).DistrictID;
                setToDistrictId(districtId);

                const result = await dispatch(getAllWardByDistrictGHN({district_id: districtId})).unwrap();
                if (result && result.status === 200) {
                    const {data} = result;
                    const wardCode = data.data.find((el) => el.WardName === defaultAddress.ward).WardCode;
                    setToWardCode(wardCode);
                }
            }
        }
    };

    useEffect(() => {
        fetchProvinceListGHN();
        setShippingFee(0);
    }, [defaultAddress]);

    useEffect(() => {
        if (shippingUnitList) {
            const firstUnit = shippingUnitList[0];
            if (firstUnit) setSelectedShippingUnit(firstUnit);
        }
    }, [shippingUnitList, defaultAddress]);

    return (
        <div className='payment-product-list'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <SectionTitle paled={false}>{'Sản phẩm'}</SectionTitle>
                </div>
                <div>
                    <span
                        style={{color: '#4568f2', fontSize: 15, cursor: 'pointer'}}
                        onClick={() => navigateTo(pagePath.cartUrl)}>
                        Thay đổi
                    </span>
                </div>
            </div>
            {paymentProductsData.map((item, idx) => {
                const {brand_name, productList, extend_brand_voucher} = item;
                // const appliedVoucherValue = extend_brand_voucher.reduce((sum, discount) => sum + discount.value, 0);
                const productCount =
                    productList && productList.reduce((total, currentItem) => total + currentItem.quantity, 0);
                const productsTotal =
                    productList && productList.reduce((total, currentItem) => total + currentItem.total, 0);
                return (
                    <div key={idx} style={{border: '1px solid #ecf0fe', borderRadius: 8}}>
                        {
                            //<div style={{borderBottom: '1px solid #ecf0fe', padding: 16}}>
                            //     <span style={{fontSize: 13, color: '#4d4d4d', fontWeight: 500}}>{brand_name}</span>
                            // </div>
                        }
                        <div style={{borderBottom: '1px solid #ecf0fe'}}>
                            {productList &&
                                productList.map((product) => (
                                    <div
                                        key={product?.name}
                                        className='d-flex align-items-center'
                                        style={{padding: '18px 16px'}}>
                                        <div className='d-flex align-items-center col'>
                                            <img
                                                alt='product'
                                                src={`${Config.apiConfig.endPoint}${product?.default_image}`}
                                                style={{borderRadius: 8, marginRight: 8}}
                                                width='48'
                                                height='48'
                                            />
                                            <div>
                                                <div>
                                                    <span style={{color: '#333333', fontSize: 15, fontWeight: 500}}>
                                                        {product?.name}
                                                    </span>
                                                </div>
                                                <div style={{color: '#808080', fontSize: 13}}>
                                                    <span>{product?.product_type_text}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center col-2 product-unit-price'>
                                            <span style={{color: '#333333', fontSize: 15}}>
                                                <FormattedNumber
                                                    style='currency'
                                                    currency='VND'
                                                    value={product?.price}
                                                />
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center col-1'>
                                            <span style={{color: '#333333', fontSize: 15}}>x{product?.quantity}</span>
                                        </div>
                                        <div className='d-flex align-items-center col-sm-2 col-3'>
                                            <span style={{color: '#333333', fontSize: 15}}>
                                                <FormattedNumber
                                                    style='currency'
                                                    currency='VND'
                                                    value={product?.total}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div
                            className='d-flex justify-content-between align-items-center'
                            style={{borderBottom: '1px solid #ecf0fe', padding: 16}}>
                            <div className='d-flex align-items-center gap-3'>
                                <div className='d-flex align-items-center' onClick={openVoucherDropdown} style={{cursor: 'pointer'}}>
                                    <Voucher className='me-1 voucher-blue' />
                                    <span style={{fontSize: 13, color: '#4568f2', fontWeight: 500, marginRight: 4}}>
                                        Giảm giá của shop
                                    </span>
                                    <img
                                        src={voucherDropdownDataState === 'open' ? upperArrow : DownArrow}
                                        alt='arrow'
                                        style={{
                                            width: 20,
                                            height: 20,
                                            objectFit: 'contain',
                                            filter: 'invert(40%) sepia(83%) saturate(4185%) hue-rotate(220deg) brightness(99%) contrast(92%)'
                                        }}
                                    />
                                </div>
                                <CustomDropdown
                                    closeDropdown={closeVoucherDropdown}
                                    dropdownState={voucherDropdownDataState}
                                    ref={voucherDropdownRef}
                                    className='account-dropdown'>
                                    <VoucherModal {...voucherModalProps} />
                                </CustomDropdown>
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                {appliedVoucherValue > 0 && (
                                    <div className='voucher-applied'>
                                        <img src={couponFrame} alt={appliedVoucherValue} />
                                        <span>
                                            {intl.messages.decrease} {formatThousands(Math.round(appliedVoucherValue))}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row' style={{padding: 16}}>
                            <div className='col-8 col-sm-4 d-flex align-items-center'>
                                <Dropdown
                                    menu={{items: shippingUnitItems, onClick}}
                                    trigger={['click']}
                                    open={isOpenDropdown}
                                    onOpenChange={() => setOpenDropDown(!isOpenDropdown)}>
                                    <div
                                        className='d-flex align-items-center'
                                        onClick={(e) => e.preventDefault()}
                                        style={{cursor: 'pointer'}}>
                                        <Shipper className='me-1' style={{width: 16, height: 16}} />
                                        <span style={{fontSize: 13, color: '#4568f2', fontWeight: 500, marginRight: 4}}>
                                            Đơn vị vận chuyển
                                        </span>
                                        <img
                                            src={isOpenDropdown ? upperArrow : DownArrow}
                                            alt='arrow'
                                            style={{
                                                width: 20,
                                                height: 20,
                                                objectFit: 'contain',
                                                filter: 'invert(40%) sepia(83%) saturate(4185%) hue-rotate(220deg) brightness(99%) contrast(92%)'
                                            }}
                                        />
                                    </div>
                                </Dropdown>
                            </div>
                            <div className='order-2 order-sm-1 mt-2 mt-sm-0 col-sm-6 d-flex align-items-center'>
                                <span style={{fontSize: 13, fontWeight: 500}}>{selectedShippingUnit.name} &nbsp;</span>
                                {
                                    // <span style={{fontSize: 12, color: '#808080'}}>
                                    //     (Nhận hàng {moment(selectedShippingUnit.receive_from_at).format('DD/MM/YYYY')} đến{' '}
                                    //     {moment(selectedShippingUnit.receive_to_at).format('DD/MM/YYYY')})
                                    // </span>
                                }
                               
                            </div>
                            <div className='order-1 order-sm-2 col-4 col-sm-2 d-flex align-items-center'>
                                <span style={{fontSize: 15}}>
                                    <FormattedNumber
                                        style='currency'
                                        currency='VND'
                                        value={shippingFee}
                                    />
                                </span>
                            </div>
                        </div>
                        <div
                            className='d-flex justify-content-between align-items-center'
                            style={{borderBottom: '1px solid #ecf0fe', borderTop: '1px solid #ecf0fe', padding: 16}}>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex'>
                                    <img src={notes} alt='notes' style={{marginRight: 4}} />
                                    <span style={{fontSize: 13, color: '#808080', fontWeight: 500, marginRight: 4}}>
                                        Ghi chú
                                    </span>
                                </div>
                            </div>
                            <div className='w-75 d-flex align-items-center'>
                                <input
                                    type='text'
                                    placeholder='Nhập ghi chú cho người bán..'
                                    style={{
                                        border: '1px solid #4568f2',
                                        outline: 'none',
                                        borderRadius: 4,
                                        height: 40,
                                        padding: '12px 8px',
                                        width: '100%'
                                    }}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center' style={{padding: 16}}>
                            <span style={{color: '#333333', fontSize: 15, fontWeight: 500}}>
                                Tổng cộng ({productCount} sản phẩm)
                            </span>
                            <span style={{color: '#4568f2', fontSize: 17, fontWeight: 500}}>
                                <FormattedNumber style='currency' currency='VND' value={productsTotal} />
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductList;
