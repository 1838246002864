import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import propsProvider from './NotificationPropsProvider';
import NotificationMainView from './template/NotificationMainView';
import { getAllNotifications, markAllAsRead, setActiveNotiType } from './notificationSlice';
import Events from 'utils/Events';
import { eventsKey } from 'configuration/storageKey';
import { pagePath } from 'configuration/routeConfig';

const NotificationContainer = (props) => {
    const { dispatch, history, notification, pathname } = props;
    const intl = useIntl();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
    };

    const handlePageChange = (value) => {
        setPage(value);
    };

    const resetState = () => {
        setPage(1);
        setPageSize(10);
    };

    const getNotiList = useCallback(
        async (e) => {

            const updateNotiEvents = async () => {
                let type = [pagePath.userNotiOrderUrl, pagePath.userNotiPromotionUrl, pagePath.userNotiOtherUrl].findIndex(
                    (value) => value === pathname
                )

                const notificationProps = {
                    Filters: `main_category_type==${type}`,
                    Sorts: '-created_at',
                    Page: page,
                    PageSize: pageSize
                };
                dispatch(setActiveNotiType(type))
                await dispatch(getAllNotifications({ params: notificationProps }));
            };

            handleUpdateNotifications();

            await updateNotiEvents();
        }, [dispatch, page, pageSize, pathname]
    )


    const handleReadAll = async () => {
        // const {collection} = notification.notificationDetails;
        // const unreadNotifications = collection.filter((noti) => !noti.read);

        // for (const noti of unreadNotifications) {
        //     const params = {approvedStatusCode: noti.extend_status.code};
        //     await dispatch(updateNotifications({id: noti.id, params, data: {read: true}}));
        // }
        // handleUpdateNotifications();
        let type = [pagePath.userNotiOrderUrl, pagePath.userNotiPromotionUrl, pagePath.userNotiOtherUrl].findIndex(
            (value) => value === pathname
        )
        var res = await dispatch(markAllAsRead(type)).unwrap()
        if (!res.success) return;
        return getNotiList();

    };

    const handleUpdateNotifications = () => {
        Events.emit(eventsKey.updateUserNoti);
    };

    useEffect(() => {
        getNotiList()
    }, [page, pageSize, pathname]);


    const notificationContainerProps = {
        ...props,
        // navigateTo,
        intl,
        notification,
        history,
        handlePageSizeChange,
        handlePageChange,
        handleReadAll,
        resetState,
        page
    };

    return <NotificationMainView {...propsProvider(notificationContainerProps)} />;
};

export default NotificationContainer;
