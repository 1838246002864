import Config from 'configuration';

export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const config = Config.apiConfig.endPoint;
    const {
        intl,
        i18nLabels,
        home,
        navigateTo,
        currentRecommendList,
        setCurrentRecommendList,
    } = props;

    return {
        navigateTo,
        i18nLabels,
        hotDealProps: {
            intl,
            navigateTo,
            flashDealList: home.hotDealDetails
        },
        bannerProps: {
            intl,
            config,
            bannersList: home.banners
        },
        categoryProps: {
            intl,
            navigateTo,
            categoryList: home.categoryDetails
        },
        recommendProps: {
            intl,
            navigateTo,
            recommendList: home.recommendDetails,
            currentRecommendList,
            setCurrentRecommendList
        }
    };
}
