import { adminShopInformationSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch, useSelector} from 'react-redux';
import i18nLabels from './ShopInformation.i18n';
import ShopInformationContainer from './ShopInformationContainer';

const ShopInformation = (props) => {
    const dispatch = useDispatch();
    const adminShopInformation = useSelector(adminShopInformationSelector);

    const shopInformationProps = {
        ...props,
        dispatch,
        i18nLabels,
        adminShopInformation
    };
    return <DecorateContainer {...shopInformationProps} childComponent={ShopInformationContainer} />;
};

export default ShopInformation;
