import React, { useEffect, useState } from 'react';
import VoucherList from './subViews/VoucherList'
import VoucherForm from './subViews/VoucherForm'

const VoucherMainView = (props) => {
    const { voucherListProps, voucherFormProps } = props
    return (
        <>
            <VoucherForm {...voucherFormProps}/>
            <VoucherList {...voucherListProps} />
        </>

    )
}

export default VoucherMainView;