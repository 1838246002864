import React, {useState, useEffect, useRef, useCallback} from 'react';
import {CloseGray} from 'assets/images';
import {collection, onSnapshot, orderBy, query, doc, getDoc, getDocs, addDoc, deleteDoc} from 'firebase/firestore';
import {
    createChatRoom,
    getChatRoom,
    readChatRoomMessage,
    sendTextMessage,
    uploadImageMessage
} from 'components/ChatBox/chatBoxSlice';
import CustomerList from '../CustomerList/CustomerList';
import MessageList from '../MessageList/MessageList';
import {Toast} from 'utils/toast';
import Config from 'configuration';

const ChatBoxContainer = (props) => {
    const {
        intl,
        navigateTo,
        dispatch,
        handleClose,
        isExpanded,
        setIsExpanded,
        firestore,
        userLoginInfo,
        chatRooms,
        fetchChatRoomList,
        searchValue,
        setSearchValue,
        handleSearch,
        chatBoxParams,
        handleFilter,
        handlePinChat,
        handleMarkAsRead,
        isAdmin
    } = props;
    const fileRef = useRef(null);
    const [chatRoom, setChatRoom] = useState('h1SChHtwrOFoSxb11aTG'); // chatroom đang mở
    const [messageContent, setMessageContent] = useState([]);
    const [textValue, setTextValue] = useState('');
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [chatRoomIdList, setChatRoomIdList] = useState([]);
    const [lightBoxToggler, setLightBoxToggler] = useState(false);
    const [index, setIndex] = useState(0); // index of image in lightbox
    const senderId = userLoginInfo.id;
    const scrollRef = useRef(null);
    const [chatRoomInfo, setChatRoomInfo] = useState({});

    const toggleLightBox = () => setLightBoxToggler(!lightBoxToggler);

    const handleOpenChatRoom = async (userId, roomInfo) => {
        if (!isExpanded) {
            console.log('messageContent', messageContent);
            readMessage(roomInfo?.id, messageContent[messageContent.length - 1].id);
            setChatRoomInfo(roomInfo);
            const res = await dispatch(getChatRoom({receiveUser: userId})).unwrap();
            const {success} = res;
            if (success) {
                const {data} = res;
                setChatRoom(data?.id);
            }
            if (!roomInfo?.is_read) {
                handleMarkAsRead(roomInfo?.id, roomInfo?.is_read);
            }
        }

        setIsExpanded(!isExpanded);
    };

    const handleSendMessage = async () => {
        if (!textValue) return;
        await dispatch(sendTextMessage({chatRoomId: chatRoom, message: textValue, type: 'text'}));
        fetchChatRoomList();
        setTextValue('');
    };

    const handleSendImage = async (e) => {
        const files = e.target.files;

        for (const file of files) {
            const {size} = file;
            if (size > 2 * 1024 * 1024) {
                await dispatch(
                    sendTextMessage({
                        chatRoomId: chatRoom,
                        message: 'Hình ảnh vượt quá dung lượng (2MB) cho phép!',
                        type: 'image'
                    })
                );
            } else {
                const formData = new FormData();
                formData.append('file', file);
                const res = await dispatch(uploadImageMessage({chatRoomId: chatRoom, file: formData})).unwrap();
                const {success} = res;
                if (success) {
                    const {data} = res;
                    await dispatch(
                        sendTextMessage({
                            chatRoomId: chatRoom,
                            message: `${Config.apiConfig.endPoint}${data?.physical_path}`,
                            type: 'image'
                        })
                    );
                }
            }
        }
        fetchChatRoomList();
        e.target.value = null;
    };

    const readMessage = async (chatRoomId, messageId) => {
        await dispatch(
            readChatRoomMessage({
                chatRoomId: chatRoomId,
                lastReadMessage: messageId
            })
        );
    };

    const handleSendProduct = async (productId) => {
        await dispatch(
            sendTextMessage({
                chatRoomId: chatRoom,
                message: productId,
                type: 'product'
            })
        );
    };

    const handleSendOrder = async (orderId) => {
        await dispatch(
            sendTextMessage({
                chatRoomId: chatRoom,
                message: orderId,
                type: 'order'
            })
        );
    };

    useEffect(() => {
        const fetchAdminChatRoom = async () => {
            const res = await dispatch(createChatRoom()).unwrap();
            const {success} = res;
            if (success) {
                const {data} = res;
                setChatRoom(data?.id);
            }
        };
        if (!isAdmin && !chatRoom) {
            fetchAdminChatRoom();
        }
    }, [isAdmin, chatRoom]);

    const fetchData = async () => {
        const q = query(collection(firestore, 'chatrooms'));
        const querySnapshot = await getDocs(q);

        let chatRooms = [];
        querySnapshot.forEach(async (doc) => {
            chatRooms.push(doc.id);
        });
        console.log('chatrooms', chatRooms);
        setChatRoomIdList(chatRooms);
    };

    useEffect(() => {
        fetchData();
    }, [firestore]);

    useEffect(() => {
        if (chatRoom === 'h1SChHtwrOFoSxb11aTG') {
            chatRoomIdList.forEach((el) => {
                const unsub = onSnapshot(
                    query(collection(firestore, `chatrooms/${el}/messages`), orderBy('createdAt', 'asc')),
                    (querySnapshot) => {
                        let messageContents = querySnapshot.docs.map((doc, idx) => {
                            let data = doc.data();
                            // message có chứa type để cho sau này làm các content khác như hình ảnh
                            return {
                                id: doc?.id,
                                person: data.sender === senderId ? 'self' : 'other',
                                content: data.content
                            };
                        });
                        setMessageContent(messageContents);
                        fetchChatRoomList();
                    }
                );
                return unsub;
            });
        } else {
            const unsub = onSnapshot(
                query(collection(firestore, `chatrooms/${chatRoom}/messages`), orderBy('createdAt', 'asc')),
                (querySnapshot) => {
                    let messageContents = querySnapshot.docs.map((doc, idx) => {
                        let data = doc.data();
                        // message có chứa type để cho sau này làm các content khác như hình ảnh
                        return {
                            id: doc?.id,
                            person: data.sender === senderId ? 'self' : 'other',
                            content: data.content,
                            type: data.type
                        };
                    });
                    setMessageContent(messageContents);
                    // readMessage(chatRoom, messageContents[0].id);
                }
            );
            return unsub;
        }
    }, [firestore, chatRoom, senderId, chatRoomIdList]);

    const scrollToBottomOfList = useCallback(() => {
        // See: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        });
    }, [scrollRef]);

    useEffect(() => {
        if (!isAdmin) scrollToBottomOfList();
        else {
            if (isExpanded) scrollToBottomOfList();
        }
    }, [messageContent, isAdmin, isExpanded]);

    const messageListProps = {
        dispatch,
        navigateTo,
        scrollRef,
        fileRef,
        messageContent,
        handleSendMessage,
        textValue,
        setTextValue,
        emojiPickerVisible,
        setEmojiPickerVisible,
        handleSendImage,
        lightBoxToggler,
        setLightBoxToggler,
        toggleLightBox,
        index,
        setIndex,
        handleSendProduct,
        handleSendOrder,
        isAdmin,
        chatRoomInfo,
        handleMarkAsRead
    };

    const customerListProps = {
        chatRooms,
        chatRoom,
        searchValue,
        setSearchValue,
        chatBoxParams,
        handleFilter,
        handleOpenChatRoom,
        handlePinChat,
        handleMarkAsRead,
        handleSearch
    };

    const totalUnread = chatRooms && chatRooms.reduce((total, item) => total + item?.unRead_count, 0);

    return (
        <div className={`chatbox-container ${isExpanded ? 'chatbox-container__expanded' : ''}`}>
            <div className='chatbox-container__header'>
                <h5 className='header-title'>Chat {!isAdmin ? 'với shop' : totalUnread > 0 ? `(${totalUnread})` : ''}</h5>
                <CloseGray style={{width: '20px', height: '20px', cursor: 'pointer'}} onClick={handleClose} />
            </div>
            <div className='chatbox-container__body'>
                {!isAdmin ? <MessageList {...messageListProps} /> : isExpanded && <MessageList {...messageListProps} />}
                {isAdmin && <CustomerList {...customerListProps} />}
            </div>
        </div>
    );
};

export default ChatBoxContainer;
