import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {SliderRightArrow} from 'assets/images';
import React, {useLayoutEffect, useState} from 'react';

const AddNotificationSection = (props) => {
    const {title, subTitle = '', className = '', children, activeStep, sectionActiveStep} = props;

    const [isExpanded, setIsExpanded] = useState(true);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    const isActive = true;
    const isFilledAndExpanded = isActive && isExpanded;

    useLayoutEffect(() => {
        setIsExpanded(activeStep === sectionActiveStep);
    }, [activeStep]);

    return (
        <Accordion className='add-notification__section' expanded={isFilledAndExpanded} onChange={toggleExpand}>
            <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                expandIcon={<img src={SliderRightArrow} alt='' />}>
                <div className='section-header' aria-expanded={isFilledAndExpanded}>
                    <div>
                        <h4 className={'title-active'}>{title}</h4>
                        {subTitle && <p className='m-0 mt-1'>{subTitle}</p>}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={`section-content ${className}`}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default AddNotificationSection;
