import React, {useEffect, useState} from 'react';
import AddNotificationSection from './AddNotificationSection';
import { FormControl, FormHelperText, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/CustomInput';
import { Help, ExclamationMark } from 'assets/images';
import GroupInputDateTime from 'components/GroupInputDateTime/GroupInputDateTime';
import moment from 'moment';

const BasicInformationSection = (props) => {
    const {
        intl,
        isSchedule,
        handleRadioScheduleChange,
        setNotificationName,
        handleChangeDateBasicInfomation,
        handleChangeTimeBasicInfomation,
        selectedItem
    } = props;

    const AddNotificationSectionProps = {
        title: intl.messages.notificationBasicInfomation
    };

    const [value, setValue] = useState('');

    useEffect(() => {
        if (selectedItem) {
            setValue(selectedItem.summary);
            setNotificationName(selectedItem.summary);
        }
    }, []);

    const selectedDate = new Date(selectedItem?.sent_schedule);
    const hours = selectedDate.getHours().toString().padStart(2, '0');
    const minutes = selectedDate.getMinutes().toString().padStart(2, '0');

    const groupScheduleInputs = [
        {
            name: 'timeSchedule',
            placeholder: intl.messages.notificationTime,
            type: 'time',
            defaultValue: selectedItem?.sent_schedule ? `${hours}:${minutes}` : null
        },
        {
            name: 'dateSchedule',
            placeholder: intl.messages.notificatinoDate,
            type: 'date',
            defaultValue: selectedItem?.sent_schedule
                ? moment.utc(new Date(selectedItem.sent_schedule)).format('YYYY/MM/DD')
                : null
        }
    ];

    const onChangeNotificationName = (event) => {
        setNotificationName(event);
    };

    const {
        control,
        watch,
        getFieldState,
        formState: {errors}
    } = useForm({
        defaultValues: {
            notificationName: selectedItem ? selectedItem.summary : ''
        }
    });
    const watchAllFields = watch();
    const isTouched = getFieldState('notificationName').isTouched;
    const currentValue = watchAllFields['notificationName'];
    const helperText = `${currentValue?.length}/75 ${intl.messages.digit}`;
    const isErrorOnInput = currentValue?.length > 75 || (isTouched && currentValue?.length === 0);

    return (
        <AddNotificationSection {...AddNotificationSectionProps} className='basic-information'>
            <div className='row'>
                <Controller
                    key={'notificationName'}
                    name='notificationName'
                    control={control}
                    rules={{required: true, maxLength: 75}}
                    render={({field: {onChange, ...restField}}) => (
                        <FormControl fullWidth>
                            <CustomInput
                                {...restField}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    onChangeNotificationName(value);
                                    onChange(e);
                                    setValue(value);
                                }}
                                value={value}
                                label={
                                    <span>
                                        {intl.messages.notificationName}
                                        <span className='text-required'>*</span>
                                    </span>
                                }
                                valid={!(errors['notificationName'] || isErrorOnInput)}
                                placeholder={intl.messages.enternotificationName}
                                className='validate-input'
                            />
                            <FormHelperText error={!!errors['notificationName'] || isErrorOnInput}>
                                {errors['notificationName']?.message ? errors['notificationName'].message : helperText}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
                <div className='row align-items-center mb-2'>
                    <span className='auto-width'>Thời gian gửi</span>
                    <RadioGroup
                        className='col-6 mb-0 d-flex align-items-center'
                        row
                        name='controlled-schedule-information'
                        value={isSchedule}
                        onChange={handleRadioScheduleChange}>
                        <FormControlLabel
                            value={false}
                            control={<Radio disableRipple />}
                            label={intl.messages.notificationSend}
                        />
                        <FormControlLabel
                            value={true}
                            control={<Radio disableRipple />}
                            label={intl.messages.notificationSchedule}
                        />
                        <Help src={Help} alt='help' className='cursor-pointer' />
                    </RadioGroup>
                </div>
                {isSchedule && (
                    <div className='mt-2'>
                        <GroupInputDateTime
                            control={control}
                            label={intl.messages.orderDate}
                            groupInputData={groupScheduleInputs}
                            disabledHours={[0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23]}
                            onChangeDate={handleChangeDateBasicInfomation}
                            onChangeTime={handleChangeTimeBasicInfomation}
                            fromToday={true}
                        />
                    </div>
                )}
                <div className='schedule-info mt-2'>
                    <ExclamationMark alt='exclam' />
                    <span>{intl.messages.scheduleInfomation}</span>
                </div>
            </div>
        </AddNotificationSection>
    );
};

export default BasicInformationSection;
