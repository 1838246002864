import axiosClient from "./axiosClient";

const chatApi = {
    getAllChat: (data, signal) => {
        const url = '/api/chat';
        return axiosClient.get(url, {params: data, signal})
    },
    postNewChat: (data)=>{
        const url = '/api/chat';
        return axiosClient.post(url,data)
    },
    updateChat: (data)=>{
        const {id} = data;
        const url = '/api/chat';
        return axiosClient.put(`${url}/${id}`,data)
    },
    deleteChat: (data)=>{
        const {id} = data;
        const url = '/api/chat';
        return axiosClient.delete(`${url}/${id}`);
    }
}

export default chatApi;