import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import i18nLabels from './UserProfile.i18n';
import UserProfileContainer from './UserProfileContainer';
import DecorateContainer from 'components/DecorateContainer';
import { pagePath } from 'configuration/routeConfig';
import { googleAnalyticsActions } from 'utils/googleAnalytics';
import { useDeviceSize } from 'utils/hooks/useDeviceSize';

const UserProfile = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [windowDimensions] = useDeviceSize();
    const { width } = windowDimensions;

    const { pathname } = location;

    useEffect(() => {
        googleAnalyticsActions.trackingPageView('User page');
        if (pathname === pagePath.userUrl && width >= 576) {
            history.push(pagePath.userProfileUrl);
        }
    }, [width]);

    const userProfileProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };
    return <DecorateContainer {...userProfileProps} childComponent={UserProfileContainer} />;
};

export default UserProfile;
