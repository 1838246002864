import CellPopover from 'components/CellPopover/CellPopover';
import StatusBox from 'components/StatusBox';
import {Chat} from 'assets/images';
import {threeDot} from 'assets/images';
import React, {Fragment, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import avatar from 'assets/images/png/avatar.png';
import Config from 'configuration';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

const CardOrder = (props) => {
    let parentClass = 'tabs-detail__table__card';
    const {buyer, order, products, intl, tableRowActions, noCheckbox, handleSelectRow, checked = false} = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
    const handleClickActionRow = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setAnchorPopoverEl(e.currentTarget);
    };
    let displayProducts = isExpanded ? products : products.slice(0, 1);
    return (
        <div className={parentClass}>
            <header className={`${parentClass}__buyer ${parentClass}__buyer--${noCheckbox ? 'firstTab' : 'tab'}`}>
                <div className={`${parentClass}__buyer__info`}>
                    {!noCheckbox && (
                        <CustomCheckbox
                            disableRipple
                            color='primary'
                            checked={checked}
                            onChange={(e) => handleSelectRow(e, order.id)}
                            inputProps={{
                                'aria-label': 'select all data'
                            }}
                        />
                    )}
                    <Avatar
                        src={buyer.avatar != null ? `${Config.apiConfig.endPoint}${buyer.avatar}` : avatar}
                        className='me-2'
                    />
                    {buyer.name}
                    <Chat style={{width: '20px', height: '20px', fill: '#4568f2'}} />
                </div>
                <div className={`${parentClass}__buyer__order`}>Mã đơn hàng: {order.code}</div>
            </header>
            <div className={`${parentClass}__detail`}>
                <div className={`${parentClass}__detail__products col_products ${noCheckbox ? 'col-firstTab' : ''}`}>
                    {displayProducts.map((product, index) => {
                        const {image, name, type, quantity, price} = product;

                        return (
                            <div className={`${parentClass}__detail__products__card`} key={index}>
                                <img
                                    src={image != null ? `${Config.apiConfig.endPoint}${image}` : avatar}
                                    alt='product'
                                />
                                <div className={`${parentClass}__detail__products__card__info`}>
                                    <div className='product__name'>{name}</div>
                                    <div className='product__features d-flex'>
                                        <div className='type d-flex align-items-center'>
                                            {(type && type.length) > 0 &&
                                                type.map((data, index) => {
                                                    if (index === type.length - 1) {
                                                        return <div key={index}>{data}</div>;
                                                    }
                                                    return (
                                                        <Fragment key={index}>
                                                            <div>{data}</div> <span>{'\u2B24'}</span>
                                                        </Fragment>
                                                    );
                                                })}
                                        </div>
                                        <div className='quantity'>x{quantity}</div>
                                    </div>
                                    <div className='product__footer d-flex'>
                                        {products.length > 1 && index === displayProducts.length - 1 && (
                                            <div
                                                className='readMore'
                                                onClick={() => {
                                                    setIsExpanded(!isExpanded);
                                                }}>
                                                {index === 0
                                                    ? `${(products.length - 1).toString()} ${intl.messages.product} ${
                                                          intl.messages.other
                                                      }`
                                                    : intl.messages.readLess}
                                            </div>
                                        )}

                                        <div className='price'>đ{price.toLocaleString('en-US')}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className={`${parentClass}__detail__total_price col_total_price 
                        d-flex justify-content-center flex-column`}>
                    <p className={`${parentClass}__detail__total_price__number m-0`}>
                        đ{order.totalPrice.toLocaleString('en-US')}
                    </p>
                    <p className={`${parentClass}__detail__total_price__method mb-0`}>{order.method}</p>
                </div>
                <div
                    className={`
                        d-flex align-items-center justify-content-center
                        ${parentClass}__detail__created_at col_created_at
                    `}>
                    {order.createdAt}
                </div>
                <div
                    className={`d-flex align-items-center justify-content-center ${parentClass}__detail__delivery col_delivery`}>
                    {order.deliveryUnit}
                </div>
                <div
                    className={`d-flex align-items-center justify-content-center ${parentClass}__detail__status col_status`}>
                    <StatusBox status={order.status} borderColor={order?.status_code === 'Refund' ? '#ccc' : null} />
                    <p
                        className={`mb-0 ${
                            order.sub_step_code === 'REFUND_ACCEPTED'
                                ? 'sub_step_success'
                                : order.sub_step_code === 'REFUND_DENIED'
                                ? 'sub_step_error'
                                : ''
                        }`}>
                        {order.sub_step_name}
                    </p>
                </div>
                <div
                    className={`d-flex align-items-center justify-content-center ${parentClass}__detail__action col_action`}>
                    <img src={threeDot} alt='' onClick={handleClickActionRow} />
                    <CellPopover
                        setAnchorPopoverEl={setAnchorPopoverEl}
                        tableRowActions={tableRowActions}
                        id={order.id}
                        open={!!anchorPopoverEl}
                        anchorEl={anchorPopoverEl}
                        onClose={() => {
                            setAnchorPopoverEl(null);
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardOrder;
