import {updateCategoryById} from 'features/Admin/template/subViews/ShopSetting/CategoryManagement/ConfigCategoriesSlice';
import axiosClient from './axiosClient';
import axiosFormData from './axiosFormData';

const categoryApi = {
    getCategories: () => {
        const url = '/api/categories';
        return axiosClient.get(url);
    },

    getAllRelatedCategories: (id) => {
        const url = '/api/categories/getAllRelatedCategoriesId';
        return axiosClient.get(`${url}/${id}`);
    },

    getCategoryById: (id) => {
        const url = `api/categories/${id}`;
        return axiosClient.get(url);
    },

    postCategory: (data) => {
        const url = '/api/categories';
        return axiosClient.post(url, data);
    },

    uploadCategoryImages: (data) => {
        const {id, file} = data;

        const url = `/api/categories/${id}/image`;
        return axiosFormData.put(url, file);
    },

    updateCategoryById: (payload) => {
        const {id, data} = payload,
            url = `/api/categories/${id}`;
        return axiosClient.put(url, data);
    },

    deleteCategoryById: (id) => {
        const url = `/api/categories/${id}`;
        return axiosClient.delete(url);
    },
    deleteImageCategoryById: (data) => {
        const {id} = data;
        const url = `/api/categories/${id}/image`;
        return axiosClient.delete(url);
    }
};

export default categoryApi;
