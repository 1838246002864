import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProfileContainer from './ProfileContainer';

const Profile = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const addProductProps = {
		...props,
		dispatch,
		history
	};
	return <ProfileContainer {...addProductProps} />;
};

export default Profile;