import React from 'react';
import {close} from 'assets/images';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import VoucherCard from './VoucherCard';
import {copyIcon} from 'assets/images';
import CopyToClipboard from 'react-copy-to-clipboard';
import {Toast} from 'utils/toast';

const VoucherModal = (props) => {
    const {intl, isOpenVoucherFormModal, closeVoucherModal, voucherList} = props;
    const date = new Date(voucherList.expiry);
    const expireDay = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const {value, min_order_apply} = voucherList;

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={isOpenVoucherFormModal}
            onClose={closeVoucherModal}
            aria-labelledby='voucher-modal'
            aria-describedby='apply-voucher-to-get-discount'
            className='dialog dialog-voucher-modal'>
            <DialogTitle className='dialog-title modal-title' id='voucher-modal'>
                <button className='dialog-title__close'>
                    <img
                        src={close}
                        alt='close-icon'
                        onClick={(e) => {
                            e.preventDefault();
                            closeVoucherModal();
                        }}
                    />
                </button>
            </DialogTitle>
            <DialogContent className='dialog-content modal-content'>
                <VoucherCard
                    styleBottom={{display: 'contents'}}
                    voucherList={voucherList}
                    isOpenVoucherFormModal={isOpenVoucherFormModal}
                    intl={intl}
                    haveButton={false}
                />
                <div className='voucher-detail'>
                    <span>{intl.messages.voucherCode}</span>
                    <span>{voucherList.code}</span>
                    <CopyToClipboard text={voucherList.code} onCopy={() => Toast(true, intl.messages.copyText)}>
                        <img src={copyIcon} alt='copy icon' />
                    </CopyToClipboard>
                </div>
                <div className='voucher-detail'>
                    <span>{intl.messages.expireDay}</span>
                    <span>{expireDay}</span>
                </div>
                <div className='voucher-method'>
                    <span>{intl.messages.condition}</span>
                    <ul>
                        <li>{`${intl.messages.decreaseMax} ${Math.round(value / 1000)}k ${
                            intl.messages.fromOrder
                        } ${Math.round(min_order_apply ? min_order_apply / 1000 : 0)}k`}</li>
                        <li>{intl.messages.applyMsg}</li>
                    </ul>
                </div>
                <div className='voucher-method'>
                    <span>{intl.messages.paymentMethod}</span>
                    <div className='d-flex align-items-center gap-2'>
                        {voucherList.extend_payment_methods?.map((method) => (
                            <div className='voucher-method-detail'>{method}</div>
                        ))}
                    </div>
                </div>
                <div className='voucher-method last'>
                    <span>{intl.messages.shippingUnit}</span>
                    <div className='d-flex align-items-center flex-wrap gap-2'>
                        {voucherList.extend_shipping_units?.map((method) => (
                            <div className='voucher-method-detail'>{method}</div>
                        ))}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default VoucherModal;
