import React, {useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from '@mui/material';
import {Edit, Trash} from 'assets/images';
import CustomPagination from 'components/Pagination/Pagination';
import CustomPageSize from 'components/CustomPageSize/CustomPageSize';
import AddNewModal from './subViews/AddNewModal';
import ModalPopup from 'components/ModalPopup/ModalPopup';

const Account = (props) => {
    const {
        intl,
        userList,
        totalPage,
        isOpenDeleteModal,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        setPageSize,
        setPage,
        setUserEdit,
        paginationData,
        handleOpenModal,
        addNewModalProps,
        handleConfirmDeleteAccount
    } = props;

    const headCells = [
        {
            code: '#',
            label: intl.messages.orderNumber
        },
        {
            code: 'name',
            label: intl.messages.fullName
        },
        {
            code: 'email',
            label: intl.messages.email
        },
        {
            code: 'permission',
            label: intl.messages.role
        },
        {
            code: 'phone',
            label: intl.messages.phone
        },
        {
            code: 'status',
            label: intl.messages.status
        },
        {
            code: 'action',
            label: ''
        }
    ];

    return (
        <div className='account'>
            {/* Modal */}
            <AddNewModal {...addNewModalProps} />
            {/* ModalPopup */}
            <ModalPopup
                title={isOpenDeleteModal}
                content={intl.messages.deleteConfirmContent}
                closeText={intl.messages.cancel}
                confirmText={intl.messages.delete}
                onClose={() => handleCloseDeleteModal()}
                onConfirm={() => handleConfirmDeleteAccount()}
            />
            {/* ----- */}
            <TableContainer className='account-table-container'>
                <Table>
                    <TableHead className='account-table-container-head'>
                        <TableRow>
                            {headCells.map((el, idx) => (
                                <TableCell
                                    key={idx}
                                    className='cell'
                                    style={{
                                        padding: el.code === '#' ? 4 : '0 8px 0 8px',
                                        textAlign: el.code === '#' || el.code === 'status' ? 'center' : 'left'
                                    }}>
                                    {el.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className='account-table-container-body'>
                        {userList.map((el, index) => (
                            <TableRow key={index} className='table-row'>
                                <TableCell className='cell cell-index' style={{textAlign: 'center'}}>
                                    <span>{index + 1}</span>
                                </TableCell>
                                <TableCell className='cell cell-full-name'>
                                    <Tooltip title={el.full_name || '--'}>
                                        <div className='ellipsis'>
                                            <span>{el.full_name || '--'}</span>
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='cell cell-email'>
                                    <Tooltip title={el.email || '--'}>
                                        <div className='ellipsis'>
                                            <span>{el.email || '--'}</span>
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='cell cell-role'>
                                    <Tooltip
                                        title={
                                            el.extend_role_user
                                                ? el.extend_role_user.map((p) => p.role_name).join(', ')
                                                : '--'
                                        }>
                                        <div className='ellipsis'>
                                            <span>
                                                {el.extend_role_user
                                                    ? el.extend_role_user.map((p) => p.role_name).join(', ')
                                                    : '--'}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='cell cell-phone'>
                                    <Tooltip title={el.phone || '--'}>
                                        <div className='ellipsis'>
                                            <span>{el.phone || '--'}</span>
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='cell cell-status'>
                                    <div
                                        className='status'
                                        style={{
                                            color: !el.disabled ? '#66bd50' : '#333',
                                            backgroundColor: !el.disabled ? '#f0f8ee' : '#f5f6fa',
                                            textAlign: 'center'
                                        }}>
                                        <Tooltip
                                            title={
                                                !el.disabled ? intl.messages.statusActive : intl.messages.statusUnActive
                                            }
                                            style={{
                                                width: '100%',
                                                marginRight: 100
                                            }}>
                                            <div>
                                                {!el.disabled
                                                    ? intl.messages.statusActive
                                                    : intl.messages.statusUnActive}
                                            </div>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                                <TableCell className='cell cell-action'>
                                    <div className='d-flex justify-content-between'>
                                        <Edit
                                            src={Edit}
                                            alt='edit'
                                            className='cursor-pointer'
                                            onClick={() => {
                                                setUserEdit(el);
                                                handleOpenModal(false);
                                            }}
                                        />
                                        <Trash
                                            className='cursor-pointer'
                                            onClick={() => {
                                                setUserEdit(el);
                                                handleOpenDeleteModal();
                                            }}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className='d-flex justify-content-between align-items-center pagination'>
                    <CustomPagination setPage={setPage} totalPage={totalPage} currentPage={paginationData[0].page} />
                    <CustomPageSize
                        label={intl.messages.rowOnPage}
                        pageSize={paginationData[0].pageSize}
                        setPageSize={setPageSize}
                        pageSizeRange={[5, 10, 15]}
                        id={'temp'}
                    />
                </div>
            </TableContainer>
        </div>
    );
};

export default Account;
