const i18nLabels = {
    vi: {
        orderInformation: 'Thông tin đơn hàng',
        orderCode: 'Mã đơn hàng',
        processStatus: 'Tình trạng xử lý',
        deliveryInformation: 'Thông tin vận chuyển',
        receiverInfo: 'Người nhận',
        receiverAddress: 'Địa chỉ nhận hàng',
        deliveryCode: 'Mã vận đơn',
        deliveryProcess: 'Quá trình vận đơn',
        productTitle: 'Sản phẩm',
        shopVoucher: 'Giảm giá của shop',
        discountText: 'Giảm',
        shippingUnit: 'Đơn vị vận chuyển',
        note: 'Ghi chú',
        paymentMethodTitle: 'Phương thức thanh toán',
        totalText: 'Tổng cộng',
        productText: 'sản phẩm',
        billingTitle: 'Hóa đơn của bạn',
        billingDetailLabel: 'Chi tiết',
        billingCollapseLabel: 'Thu gọn',
        productPrice: 'Tiền sản phẩm',
        shippingFee: 'Phí vận chuyển',
        discountLabel: 'Giảm giá',
        revenueLabel: 'Doanh thu',
        cancelText: 'Hủy',
        confirmText: 'Xác nhận',
        packingText: 'Chuẩn bị hàng',
        deliverText: 'Bàn giao cho ĐVVC',
        unsuccessfullyDelivered: 'Giao không thành công',
        successfullyDelivered: 'Xác nhận đã giao',
        packingPendingDescription: 'Đã xác nhận',
        deliveryPendingDescription: 'Chờ bàn giao cho ĐVVC',
        deliveredDescription: 'Đã giao',
        actionFail: 'Thao tác thất bại',
        actionSuccess: 'Thao tác thành công',
        cancelOrderModalTitle: 'Hủy đơn hàng',
        reasonLabel: 'Lý do',
        reasonInputPlaceholder: 'Vui lòng nhập lý do hủy...',
        closeText: 'Đóng',
        cancelReasonLabel: 'Lý do hủy',
        fromSellerOrBuyer: 'Từ người bán / mua',
        returnRefund: 'Trả hàng/Hoàn tiền',
        receivedConfirm: 'Đã nhận hàng',
        review: 'Đánh giá',
        reviewDetail: 'Xem đánh giá',
        buyAgain: 'Mua lại',
        detailReturnRefund: 'Chi tiết Trả hàng/Hoàn tiền',
        completeButton: 'Hoàn thành',
        closeButton: 'Đóng',
        backButton: 'Trở lại',
        usernameDisplay: 'Hiển thị tên đăng nhập trên đánh giá này',
        reviewCommentPlaceholder: 'Hãy chia sẻ những điều bạn thích về sản phẩm',
        productQuality: 'Chất lượng sản phẩm',
        detailProductReview: 'Chi tiết đánh giá',
        productReview: 'Đánh giá sản phẩm'
    },
    en: {
        orderInformation: 'Order Information',
        orderCode: 'Order Code',
        processStatus: 'Processing Status',
        deliveryInformation: 'Delivery Information',
        receiverInfo: 'Receiver',
        receiverAddress: 'Shipping Address',
        deliveryCode: 'Delivery Code',
        deliveryProcess: 'Delivery Process',
        productTitle: 'Product',
        shopVoucher: 'Shop Voucher',
        discountText: 'Discount',
        shippingUnit: 'Shipping Unit',
        note: 'Note',
        paymentMethodTitle: 'Payment Method',
        totalText: 'Total',
        productText: 'products',
        billingTitle: 'Your Invoice',
        billingDetailLabel: 'Details',
        billingCollapseLabel: 'Collapse',
        productPrice: 'Product Price',
        shippingFee: 'Shipping Fee',
        discountLabel: 'Discount',
        revenueLabel: 'Revenue',
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        packingText: 'Preparing Shipment',
        deliverText: 'Handover to Delivery Service',
        unsuccessfullyDelivered: 'Delivery Unsuccessful',
        successfullyDelivered: 'Delivery Confirmed',
        packingPendingDescription: 'Confirmed',
        deliveryPendingDescription: 'Waiting for Handover to Delivery Service',
        deliveredDescription: 'Delivered',
        actionFail: 'Action Failed',
        actionSuccess: 'Action Successful',
        cancelOrderModalTitle: 'Cancel Order',
        reasonLabel: 'Reason',
        reasonInputPlaceholder: 'Please enter cancellation reason...',
        closeText: 'Close',
        cancelReasonLabel: 'Cancellation Reason',
        fromSellerOrBuyer: 'From Seller/Buyer',
        returnRefund: 'Return/Refund',
        receivedConfirm: 'Received',
        review: 'Review',
        reviewDetail: 'View Review',
        buyAgain: 'Buy Again',
        detailReturnRefund: 'Return/Refund Details',
        completeButton: 'Complete',
        closeButton: 'Close',
        backButton: 'Back',
        usernameDisplay: 'Display username on this review',
        reviewCommentPlaceholder: 'Share what you like about the product',
        productQuality: 'Product Quality',
        detailProductReview: 'Review Details',
        productReview: 'Product Review'
    }
};

export default i18nLabels;
