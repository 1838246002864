const i18nLabels = {
    vi: {
        myAccount: 'Tài khoản của tôi',
        profile: 'Hồ sơ',
        addressList: 'Danh sách địa chỉ',
        order: 'Đơn hàng',
        notification: 'Thông báo',
        updateOrder: 'Cập nhật đơn hàng',
        voucher: 'Khuyến mãi',
        other: 'Khác',
        favoriteProduct: 'Sản phẩm yêu thích',
        voucherStorage: 'Kho voucher',
        sellerPage: 'Kênh người bán',
        setting: 'Cài đặt',
        supportCenter: 'Trung tâm hỗ trợ',
        password: 'Mật khẩu',
        oldPassword: 'Mật khẩu cũ',
        newPassword: 'Mật khẩu mới',
        confirmPassword: 'Nhập lại mật khẩu',
        enterNewPassword: 'Nhập mật khẩu mới',
        enterConfirmPassword: 'Nhập lại mật khẩu mới',
        update: 'Cập nhật',
        requiredField: 'Trường này là bắt buộc',
        passwordNotMatch: 'Mật khẩu không trùng khớp',
        accountSettingTitle: 'Thông tin cá nhân',
        accountSettingUploadImageLable: 'Chọn ảnh',
        accountSettingEmail: 'Tên đăng nhập',
        accountSettingEmailPlaceholder: 'Thêm tên đăng nhập',
        accountSettingDOB: 'Ngày sinh',
        accountSettingUsername: 'Tên hiển thị',
        accountSettingUsernamePlaceholder: 'Thêm tên hiển thị',
        accountSettingGender: 'Giới tính',
        accountSettingGenderMale: 'Nam',
        accountSettingGenderFemale: 'Nữ',
        accountSettingSaveButton: 'Lưu thay đổi',
        accountSettingPhoneAndEmail: 'Số điện thoại và Email',
        accountSettingPhone: 'Số điện thoại',
        accountSettingEmail2: 'Email',
        accountSettingUpdateEmailModalTitle: 'Cập nhật email',
        accountSettingUpdatePhoneModalTitle: 'Cập nhật Số điện thoại',
        accountSettingUpdateModalPhoneController: 'Số điện thoại',
        accountSettingUpdateModalMailController: 'Email',
        accountSettingUpdateModalMailOTPDescription:
            'Mã xác thực (OTP) sẽ được gửi đến email này để xác minh email này là của bạn',
        accountSettingUpdateModalMailPhoneDescription:
            'Mã xác thực (OTP) sẽ được gửi đến số điện thoại này để xác minh số điện thoại này là của bạn',
        accountSettingUpdateModalCancelButton: 'Hủy',
        accountSettingUpdateModalUpdateButton: 'Cập nhật',
        accountSettingUpdateModalOTPConfirmTitle: 'Nhập mã xác minh',
        accountSettingUpdateModalOTPConfirmDescription: 'Vui lòng nhập mã xác thực vừa vào email của bạn',
        accountSettingUpdateModalOTPConfirmCodeActiveIn: 'Mã có hiệu lực trong',
        accountSettingUpdateModalOTPNotReceiveCode: 'Không nhận được mã',
        accountSettingUpdateModalOTPResendCode: 'Gửi lại',
        accountSettingUpdateModalOTPSubmitButton: 'Xác nhận',
        myAddressList: 'Địa chỉ của tôi',
        noAddress: 'Bạn chưa có thông tin địa chỉ',
        addAddressInformation: 'Thêm thông tin địa chỉ',
        delete: 'Xóa',
        deleteAddress: 'Bạn có chắc chắn xóa địa chỉ này?',
        deliveryAddress: 'Địa chỉ nhận hàng',
        default: 'Mặc định',
        addNewAddress: 'Thêm địa chỉ mới',
        myAddress: 'Địa chỉ của tôi',
        actionBtn: 'Dùng',
        myVoucher: 'Voucher của tôi',
        noVoucher: 'Voucher của bạn trống',
        voucherCode: 'Mã đơn hàng',
        expireDay: 'Hạn sử dụng',
        condition: 'Điều kiện',
        paymentMethod: 'Thanh toán',
        shippingUnit: 'Đơn vị vận chuyển',
        company: 'Hai Dang',
        maximum: 'Cho đơn tối đa',
        discount: 'Giảm',
        myOrder: 'Đơn hàng của tôi',
        all: 'Tất cả',
        confirmed: 'Đã xác nhận',
        waitingForConfirming: 'Chờ xác nhận',
        waitingForPacking: 'Chờ lấy hàng',
        delivering: 'Đang giao',
        complete: 'Hoàn thành',
        cancelled: 'Đã hủy',
        returnRefund: 'Hoàn tiền',
        searchByCodePlaceholder: 'Tìm đơn hàng theo Mã đơn hàng',
        searchByProductNamePlaceholder: 'Tìm đơn hàng theo Tên sản phẩm',
        totalPrice: 'Tổng tiền',
        noDataFound: 'Không có kết quả được tìm thấy',
        receivedConfirm: 'Đã nhận hàng',
        return: 'Trả hàng/Hoàn tiền',
        review: 'Đánh giá',
        buyAgain: 'Mua lại',
        refundDetail: 'Chi tiết Trả hàng/Hoàn tiền',
        actionSuccess: 'Thao tác thành công',
        actionFail: 'Thao tác thất bại',
        productReview: 'Đánh giá sản phẩm',
        backButton: 'Trở lại',
        completeButton: 'Hoàn thành',
        productQuality: 'Chất lượng sản phẩm',
        reviewCommentPlaceholder: 'Hãy chia sẻ những điều bạn thích về sản phẩm',
        overSize: 'Tệp vượt quá giới hạn dung lượng cho phép',
        overLimit: 'Số tệp vượt quá lượng cho phép',
        usernameDisplay: 'Hiển thị tên đăng nhập trên đánh giá này',
        reviewDetail: 'Xem đánh giá',
        detailProductReview: 'Chi tiết đánh giá',
        closeButton: 'Đóng',
        myProfile: 'Hồ sơ của tôi',
        informationManagement: 'Quản lý thông tin hồ sơ để bảo mật tài khoản',
        accountSetting: 'Thiết lập tài khoản',
        passwordSetting: 'Thiết lập mật khẩu',
        //refund page
        hintProduct: 'Vui lòng chọn sản phẩm để “Trả hàng/ Hoàn tiền”',
        reason: 'Lý do',
        btnReason: 'Chọn lý do',
        pldReason: 'Vui lòng chọn lý do “Trả hàng/ Hoàn tiền',
        attachmentHint: 'Vui lòng đăng tải hình ảnh/ đoạn chat rõ ràng , thể hiện đúng tình trạng sản phẩm.',
        addImage: 'Thêm hình ảnh',
        note: 'Chú thích',
        noteHint: 'Nhập lý do và thêm rỏ (1) lỗi đơn hàng (2) gói hàng thể hiện rỏ mã đơn hàng và trạng thái đóng gói',
        placeholderNote: 'Nhập chú thích ở đây',
        refundAmount: 'Số tiền hoàn lại',
        yourEmail: 'Email của bạn',
        emailHint: 'Chúng tôi sẽ cố gắng gửi phản hồi sớm nhất cho bạn!',
        quantity: 'Số lượng: ',
        btnDone: 'Hoàn Thành',
        reason1: 'Hàng nhận được bị thiếu/ sai/ khác mô tả/ đã qua sử dụng',
        reason2: 'Chưa nhận được hàng nhưng Shipper đã cập nhật đơn hàng thành công',
        reason3: 'Chưa nhận được hàng sai thời gian giao hàng dự kiến',
        anotherReason: 'Lý lo khác',
        btnModalSave: 'Lưu',
        cancelOrder: 'Hủy đơn',
        cancelOrderModalTitle: 'Hủy đơn hàng',
        reasonLabel: 'Lý do',
        reasonInputPlaceholder: 'Vui lòng nhập lý do hủy...',
        closeText: 'Đóng',
        confirmText: 'Xác nhận',
        loungeRefund: 'Yêu cầu “Trả hàng/ Hoàn tiền” của bạn đã được gửi đi',
        errorChoose: 'Vui lòng chọn sản phẩm để hoàn tiền',
        errorReason: 'Vui lòng chọn lý do hoàn tiền',
        errorAttachment: 'Vui lòng thêm hình ảnh của sản phẩm lỗi',
        errorNote: 'Vui lòng điền chú thích',
        errorEmail: 'Vui lòng điền email của bạn',
        tltStatusRefund: 'Trạng thái hoàn tiền:',
        chooseCategory: 'Chọn ngành hàng',
        category: 'Ngành hàng',
        updateOrderStatus: 'Cập nhật trạng thái đơn hàng',
        canceled: 'đã bị hủy',
        confirmedDelivery: 'đã xác nhận nhận hàng thành công',
        refundOrder: 'đang yêu cầu hoàn tiền',
        rateOrder: 'được đánh giá thành công',
        cancel: 'Hủy'
    },
    en: {
        myAccount: 'My Account',
        profile: 'Profile',
        addressList: 'Address List',
        order: 'Order',
        notification: 'Notification',
        updateOrder: 'Update Order',
        voucher: 'Promotion',
        other: 'Other',
        favoriteProduct: 'Favorite Products',
        voucherStorage: 'Voucher Storage',
        sellerPage: 'Seller Page',
        setting: 'Setting',
        supportCenter: 'Support Center',
        password: 'Password',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        enterNewPassword: 'Enter New Password',
        enterConfirmPassword: 'Enter Confirm Password',
        update: 'Update',
        requiredField: 'This field is required',
        passwordNotMatch: 'Passwords do not match',
        accountSettingTitle: 'Personal Information',
        accountSettingUploadImageLable: 'Choose Image',
        accountSettingEmail: 'Email',
        accountSettingEmailPlaceholder: 'Add Email',
        accountSettingDOB: 'Date of Birth',
        accountSettingUsername: 'Display Name',
        accountSettingUsernamePlaceholder: 'Add Display Name',
        accountSettingGender: 'Gender',
        accountSettingGenderMale: 'Male',
        accountSettingGenderFemale: 'Female',
        accountSettingSaveButton: 'Save Changes',
        accountSettingPhoneAndEmail: 'Phone and Email',
        accountSettingPhone: 'Phone',
        accountSettingEmail2: 'Email',
        accountSettingUpdateEmailModalTitle: 'Update Email',
        accountSettingUpdatePhoneModalTitle: 'Update Phone',
        accountSettingUpdateModalPhoneController: 'Phone Number',
        accountSettingUpdateModalMailController: 'Email',
        accountSettingUpdateModalMailOTPDescription:
            'An OTP code will be sent to this email to verify that it belongs to you',
        accountSettingUpdateModalMailPhoneDescription:
            'An OTP code will be sent to this phone number to verify that it belongs to you',
        accountSettingUpdateModalCancelButton: 'Cancel',
        accountSettingUpdateModalUpdateButton: 'Update',
        accountSettingUpdateModalOTPConfirmTitle: 'Enter Verification Code',
        accountSettingUpdateModalOTPConfirmDescription: 'Please enter the verification code just entered your email',
        accountSettingUpdateModalOTPConfirmCodeActiveIn: 'Code active in',
        accountSettingUpdateModalOTPNotReceiveCode: 'Did not receive code?',
        accountSettingUpdateModalOTPResendCode: 'Resend',
        accountSettingUpdateModalOTPSubmitButton: 'Confirm',
        myAddressList: 'My Addresses',
        noAddress: 'You have no address information',
        addAddressInformation: 'Add Address Information',
        delete: 'Delete',
        deleteAddress: 'Are you sure you want to delete this address?',
        deliveryAddress: 'Delivery Address',
        default: 'Default',
        addNewAddress: 'Add New Address',
        myAddress: 'My Address',
        actionBtn: 'Use',
        myVoucher: 'My Vouchers',
        noVoucher: 'Your voucher collection is empty',
        voucherCode: 'Voucher Code',
        expireDay: 'Expiration Date',
        condition: 'Condition',
        paymentMethod: 'Payment Method',
        shippingUnit: 'Shipping Unit',
        company: 'Hai Dang',
        maximum: 'For maximum order',
        discount: 'Discount',
        myOrder: 'My Orders',
        all: 'All',
        waitingForConfirming: 'Waiting for Confirmation',
        waitingForPacking: 'Waiting for Packing',
        delivering: 'Delivering',
        complete: 'Complete',
        cancelled: 'Cancelled',
        returnRefund: 'Return/Refund',
        searchByCodePlaceholder: 'Search Order by Order Code',
        searchByProductNamePlaceholder: 'Search Order by Product Name',
        totalPrice: 'Total Price',
        noDataFound: 'No results found',
        receivedConfirm: 'Received Confirm',
        return: 'Return/Refund',
        review: 'Review',
        buyAgain: 'Buy Again',
        refundDetail: 'Refund Detail',
        actionSuccess: 'Action Successful',
        actionFail: 'Action Failed',
        productReview: 'Product Review',
        backButton: 'Back',
        completeButton: 'Complete',
        productQuality: 'Product Quality',
        reviewCommentPlaceholder: 'Share what you like about the product',
        overSize: 'File exceeds size limit',
        overLimit: 'Exceeded file limit',
        usernameDisplay: 'Display username on this review',
        reviewDetail: 'View Review',
        detailProductReview: 'Review Details',
        closeButton: 'Close',
        hintProduct: 'Please select a product for Return/Refund',
        reason: 'Reason',
        btnReason: 'Select reason',
        pldReason: 'Please select a Return/Refund reason',
        attachmentHint: 'Please upload clear images/chat logs that accurately depict the product condition.',
        addImage: 'Add Image',
        note: 'Note',
        noteHint:
            'Enter the reason and provide additional details (1) Order error (2) Package details, including order code and packaging status',
        placeholderNote: 'Enter note here',
        refundAmount: 'Refund Amount',
        yourEmail: 'Your Email',
        emailHint: 'We will try to send you a response as soon as possible!',
        quantity: 'Quantity: ',
        btnDone: 'Done',
        reason1: 'Received item is missing/wrong/different from description/used',
        reason2: 'Did not receive the item but the Shipper has successfully updated the order',
        reason3: 'Did not receive the item within the expected delivery time',
        anotherReason: 'Other concerns',
        btnModalSave: 'Save',
        cancelOrder: 'Cancel Order',
        cancelOrderModalTitle: 'Cancel Order',
        reasonLabel: 'Reason',
        reasonInputPlaceholder: 'Please enter cancellation reason...',
        closeText: 'Close',
        confirmText: 'Confirm',
        loungeRefund: 'Your request for Return/Refund has been submitted',
        errorChoose: 'Please select a product for refund',
        errorReason: 'Please select a refund reason',
        errorAttachment: 'Please add images of the faulty product',
        errorNote: 'Please enter a note',
        errorEmail: 'Please enter your email',
        tltStatusRefund: 'Refund Status:',
        chooseCategory: 'Choose category',
        category: 'Category',
        myProfile: 'My Profile',
        informationManagement: 'Information Management',
        accountSetting: 'Account Settings',
        passwordSetting: 'Password Settings',
        cancel: 'Cancel'
    }
};

export default i18nLabels;
