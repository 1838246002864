import {Drawer} from '@mui/material';
import {close} from 'assets/images';
import CustomButton from 'components/CustomButton/CustomButton';
import React, {useState, useEffect} from 'react';

const CustomDrawer = (props) => {
    const {
        anchor,
        content,
        onClose,
        onConfirm,
        title,
        closeText,
        confirmText,
        isDelete = true,
        contentClass = ''
    } = props;
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const closeModal = () => {
        toggle();
    };

    const doClose = () => {
        if (onClose) onClose();
        closeModal();
    };

    const doConfirm = () => {
        if (onConfirm) onConfirm();
        closeModal();
    };

    useEffect(() => {
        if (title && content) {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, content]);

    return (
        <Drawer anchor={anchor} open={open} onClose={doClose} className='drawer'>
            <div className='drawer-title' id='alert-dialog-title'>
                <span>{title}</span>
                <button className='dialog-title__close'>
                    <img src={close} alt='' onClick={doClose} />
                </button>
            </div>
            <div className={`drawer-content ${contentClass}`}>{content}</div>
            <div className='drawer-actions'>
                <CustomButton size='md' variant='text' onClick={doClose}>
                    {closeText}
                </CustomButton>
                {onConfirm && (
                    <CustomButton size='md' color={isDelete ? 'danger' : 'primary'} onClick={doConfirm}>
                        {confirmText}
                    </CustomButton>
                )}
            </div>
        </Drawer>
    );
};

export default CustomDrawer;
