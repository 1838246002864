import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import UserVoucherContainer from './UserVoucherContainer';
import i18nLabels from './userVoucher.i18n';
import DecorateContainer from 'components/DecorateContainer';

const UserVoucher = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userVoucherProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };
    return <DecorateContainer childComponent={UserVoucherContainer} {...userVoucherProps} />;
};

export default UserVoucher;
