import { DocumentImg, Marketing, Screen, Setting, ShoppingBag, ShowChart } from 'assets/images';
import { pagePath } from 'configuration/routeConfig';
import React, { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import propsProvider from './AdminPropsProvider';
import ProductManagementMainView from './template/AdminMainView';
import { capitalizeFirstLetter } from 'utils/formatString';
import { matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'app/selectors';
import { checkPermission } from 'utils/checkPermission';

const AdminContainer = (props) => {
    const { dispatch, history } = props;
    const auth = useSelector(authSelector);
    const { permission } = auth;
    const intl = useIntl();
    const location = useLocation();
    const { pathname } = location;
    const adminHomePageBreadcrumb = {
        title: intl.messages.homepage,
        to: pagePath.adminUrl
    };
    const adminSidebarItems = [
        {
            title: intl.messages.statisticData,
            icon: <ShowChart />,
            isExpanded: false,
            isActive: false,
            allowedRoles: ['DATA_ANALYST'],
            to: pagePath.adminStatisticalDataUrl
        },
        {
            title: intl.messages.productManagement,
            icon: <ShoppingBag />,
            isExpanded: false,
            allowedRoles: ['PRODUCT_MANAGEMENT'],
            items: [
                {
                    title: intl.messages.allProducts,
                    to: pagePath.adminProductManagementUrl,
                    isActive: false
                },
                {
                    title: intl.messages.addProduct,
                    to: pagePath.adminAddProductManagementUrl,
                    isActive: false
                },
                {
                    title: intl.messages.editProduct,
                    to: pagePath.adminEditProductManagementBaseUrl,
                    isActive: false,
                    isHidden: true
                }
            ]
        },
        {
            title: capitalizeFirstLetter(intl.messages.order),
            icon: <DocumentImg />,
            isExpanded: false,
            allowedRoles: ['ORDER_MANAGEMENT'],
            items: [
                {
                    title: intl.messages.orderManagement,
                    to: pagePath.adminOrderManagementUrl,
                    isActive: false
                }
            ]
        },
        {
            title: intl.messages.marketing,
            icon: <Marketing />,
            isExpanded: false,
            items: [
                // {
                //     title: intl.messages.flashDeal,
                //     to: pagePath.adminFlashDealUrl,
                //     isActive: false,
                //     allowedRoles: ['FLASH_DEAL']
                // },
                {
                    title: intl.messages.voucherList,
                    to: pagePath.adminMarketingVoucherUrl,
                    isActive: false,
                    allowedRoles: ['VOUCHER']
                }
            ]
        },
        // {
        //     title: intl.messages.financial,
        //     icon: <MoneyWithdrawal />,
        //     allowedRoles: ["financial"],
        //     isExpanded: false
        // },

        // {
        //     title: intl.messages.customerService,
        //     icon: <CardHeart />,
        //     allowedRoles: ["customer"],
        //     isExpanded: false
        // },
        {
            title: intl.messages.design,
            icon: <Screen />,
            isExpanded: false,
            items: [
                {
                    title: intl.messages.designBanner,
                    to: pagePath.adminDesignBannerUrl,
                    isActive: false,
                    allowedRoles: ['BANNER_DESIGN']
                },
                {
                    title: intl.messages.designCategory,
                    to: pagePath.adminDesignCategoryUrl,
                    isActive: false,
                    allowedRoles: ['CATEGORY_DESIGN']
                }
            ]
        },
        {
            title: intl.messages.shopSetting,
            icon: <Setting />,
            isExpanded: false,
            items: [
                {
                    title: intl.messages.shopInformation,
                    to: pagePath.adminShopInformationUrl,
                    isActive: false,
                    allowedRoles: ['SHOP_INFORMATION']
                },
                {
                    title: intl.messages.userManagement,
                    to: pagePath.adminUserManagementUrl,
                    isActive: false,
                    allowedRoles: ['USER_MANGEMENT']
                },
                {
                    title: intl.messages.categoryManagement,
                    to: pagePath.adminCategoryManagementUrl,
                    isActive: false,
                    allowedRoles: ['CATEGORY_MANAGEMENT']
                },
                {
                    title: intl.messages.deliverySetting,
                    to: pagePath.adminDeliverySettingUrl,
                    isActive: false,
                    allowedRoles: ['DELIVERY_MANAGEMENT']
                },
                // {
                //     title: intl.messages.chatSetting,
                //     to: pagePath.adminChatSettingUrl,
                //     isActive: false,
                //     allowedRoles: ['CHAT_MANAGEMENT']
                // },
                // {
                //     title: intl.messages.notificationSetting,
                //     to: pagePath.adminNotificationSettingUrl,
                //     isActive: false,
                //     allowedRoles: ['NOTI_MANAGEMENT']
                // }
            ]
        }
        // {
        //     title: intl.messages.help,
        //     icon: <Help />,
        //     allowedRoles: ["help"],
        //     isExpanded: false
        // }
    ];

    const [breadcrumbs, setBreadcrumbs] = useState([adminHomePageBreadcrumb]);
    const [adminSidebar, setAdminSidebar] = useState([]);

    const toggleAccordion = (title) => {
        const newAdminSidebar = adminSidebar.map((navItem) => {
            if (navItem.title === title) {
                navItem.isExpanded = !navItem.isExpanded;
            }

            return navItem;
        });

        setAdminSidebar(newAdminSidebar);
    };

    const navigateTo = (path) => {
        history.push(path);
    };

    useLayoutEffect(() => {
        let parentTitle = '',
            childTitle = '';
        let newAdminSidebar = [];

        adminSidebarItems.forEach((item) => {
            if (item.allowedRoles) {
                if (checkPermission(permission, item.allowedRoles)) newAdminSidebar.push(item);
            } else {
                if (item.items) {
                    const subItems = [];
                    item.items.forEach((subItem) => {
                        if (checkPermission(permission, subItem.allowedRoles)) {
                            subItems.push(subItem);
                        }
                    });

                    if (subItems.length > 0) {
                        newAdminSidebar.push({ ...item, items: subItems });
                    }
                }
            }
        });

        newAdminSidebar = newAdminSidebar.map((page) => {
            if (page.to && page.to === pathname) {
                parentTitle = page.title;
                page.isActive = true;
            } else {
                page.items?.map((item) => {
                    if (item.to === pathname) {
                        item.isActive = true;
                        childTitle = item.title;
                        parentTitle = page.title;
                    } else item.isActive = false;
                    return item;
                });
            }

            return page;
        });

        const newBreadcrumb =
            pathname === pagePath.adminUrl
                ? [adminHomePageBreadcrumb]
                : [adminHomePageBreadcrumb, { title: parentTitle }];

        if (childTitle !== '') newBreadcrumb.push({ title: childTitle });

        setAdminSidebar(newAdminSidebar);
        if (matchPath(pathname, { path: pagePath.adminOrderDetailUrl })) {
            setBreadcrumbs([
                {
                    title: intl.messages.orderManagement
                },
                {
                    title: intl.messages.orderDetail
                }
            ]);
        } else if (matchPath(pathname, { path: pagePath.adminEditProductManagementUrl })) {
            setBreadcrumbs([
                adminHomePageBreadcrumb,
                {
                    title: intl.messages.productManagement
                },
                {
                    title: intl.messages.editProduct
                }
            ]);
        } else if (matchPath(pathname, { path: pagePath.adminFlashDealAddNewUrl })) {
            setBreadcrumbs([
                adminHomePageBreadcrumb,
                {
                    title: intl.messages.marketing
                },
                {
                    title: 'FLASH SALES'
                },
                {
                    title: 'TẠO MỚI'
                }
            ]);
        } else {
            setBreadcrumbs(newBreadcrumb);
        }
    }, [pathname, permission]);

    const adminContainerProps = {
        ...props,
        navigateTo,
        intl,
        dispatch,
        breadcrumbs,
        adminSidebar,
        toggleAccordion
    };

    return <ProductManagementMainView {...propsProvider(adminContainerProps)} />;
};

export default AdminContainer;
