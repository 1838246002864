import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CommentIcon, ImageIcon} from 'assets/images';
import propsProvider from './productDetailsPropsProvider';
import {
    deleteCartProducts,
    getHotDeals,
    getProductDetails,
    getProductReviews,
    getRecommendList
} from './productDetailsSlice';
import {checkObjectEmpty} from 'utils/checkObjectEmpty';
import ProductDetailsMainView from './template/ProductDetailsMainView';
import productReviewApi from 'api/productReviewApi';
import {useLocation} from 'react-router';
import Config from 'configuration';
import {addCartProducts, deleteFavCartProducts, handleCheckedItems} from 'features/Cart/cartSlice';
import Events from 'utils/Events';
import {pagePath} from 'configuration/routeConfig';
import {Toast} from 'utils/toast';
import {eventsKey, paramsKey} from 'configuration/storageKey';
import {
    deleteMyLikeProducts,
    getlikeProductList
} from 'features/UserProfile/template/subViews/MyLikeProduct/MyLikeProductSlice';
import {useSelector} from 'react-redux';
import {cartSelector} from 'app/selectors';

const filterCategories = [
    {
        key: 'all',
        title: 'Tất cả',
        count: 0,
        icon: null,
        selected: true
    },
    {
        key: 'pictures',
        title: 'Hình ảnh',
        count: 0,
        icon: ImageIcon,
        selected: false
    },
    {
        key: 'comment',
        title: 'Bình luận',
        count: 0,
        icon: CommentIcon,
        selected: false
    }
];

const filterByStar = [
    {
        key: '5',
        value: 5,
        count: 0,
        selected: true
    },
    {
        key: '4',
        value: 4,
        count: 0,
        selected: false
    },
    {
        key: '3',
        value: 3,
        count: 0,
        selected: false
    },
    {
        key: '2',
        value: 2,
        count: 0,
        selected: false
    },
    {
        key: '1',
        value: 1,
        count: 0,
        selected: false
    }
];

export default function ProductDetailsContainer(props) {
    const {dispatch, auth, history, productId, productDetails} = props;
    const cart = useSelector(cartSelector);
    const [quantityDemand, setQuantityDemand] = useState(1);
    const [mainGroupTypeState, setMainGroupTypeState] = useState({quantity: 0, price: 0, origin_price: 0});
    const [displayProductType, setDisplayProductType] = useState([]);
    const [productImgs, setProductImgs] = useState([]);
    const [currentRecommendList, setCurrentRecommendList] = useState([]);
    const location = useLocation();
    const intl = useIntl();
    //states of review section
    const [allReviewList, setAllReviewList] = useState([]);
    const [reviewPage, setReviewPage] = useState(1);
    const [filterCategoryList, setFilterCategoryList] = useState(filterCategories);
    const [filterByStarList, setFilterByStarList] = useState(filterByStar);
    const [isOpenShareModal, setIsOpenShareModal] = useState(false);
    const {productInfo, recommendDetails} = productDetails;
    const productReviewsDetails = productDetails.productReviews.data;
    const [isBuyModal, setIsBuyModal] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [index, setIndex] = useState(0);
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const [favProdId, setFavProdId] = useState([]);
    const [checkFavProd, setCheckFavProd] = useState(false);

    const toggleLightbox = () => setIsOpenLightbox(!isOpenLightbox);

    const showBuyModalPopup = () => {
        setIsOpenPopup(true);
        setIsBuyModal(true);
    };
    const showAddModalPopup = () => {
        setIsOpenPopup(true);
        setIsBuyModal(false);
    };
    const closeModalPopup = () => setIsOpenPopup(false);
    const openShareModal = () => setIsOpenShareModal(true);
    const closeShareModal = () => setIsOpenShareModal(false);

    const navigateTo = (path) => history.push(path);

    const fetchAllReviewsByProductId = async () => {
        const productReviewParams = {
            Filters: null,
            Sorts: '-created_at',
            Page: 1,
            PageSize: 10000
        };
        const res = await productReviewApi.getReviewsByProductId(productId, productReviewParams);
        const {success} = res;
        if (success) {
            setAllReviewList(res?.data?.collection);
        }
    };

    const addToCartHandler = async ({id: productId, cartType, type = 'add'}) => {
        if (auth.isLogin) {
            try {
                const res = await dispatch(
                    addCartProducts({
                        productId,
                        quantity: quantityDemand,
                        type: cartType,
                        subTypeId: mainGroupTypeState.id
                    })
                ).unwrap();
                Events.emit(eventsKey.updateCart);
                if (cartType === 'wishlist') {
                    setCheckFavProd(true);
                }
                if (type === 'buy') {
                    const {success, data} = res;
                    if (success) {
                        if (!cart.checkedCartItemStates[data.id]) {
                            dispatch(handleCheckedItems(data.id));
                        }
                    }
                    navigateTo(pagePath.cartUrl);
                }
                Toast(true, 'Thêm thành công');
            } catch (err) {
                Toast(false, 'Thêm thất bại');
            }
        } else {
            navigateTo(`${pagePath.loginUrl}?${paramsKey.returnUrl}=${location.pathname}`);
        }
    };

    const handleLikeProduct = async (productId) => {
        if (auth.isLogin) {
            const userId = auth.userLoginInfo?.id;
            const data = {
                productId: productId,
                userId: userId,
                type: 'wishlist'
            };
            const res = await dispatch(deleteMyLikeProducts(data)).unwrap();
            if (res) {
                setCheckFavProd(false);
                dispatch(getlikeProductList());
                Toast(true, 'Xóa sản phẩm yêu thích thành công');
            } else {
                Toast(false, 'Xóa sản phẩm yêu thích thất bại');
            }
        }
    };

    const handleProductTypeChange = (mainGroup, typeId) => {
        const newProductType = [...displayProductType];
        let imgIndex = -1;
        newProductType[mainGroup] = newProductType[mainGroup].map((type, idx) => {
            const isActive = type.id === typeId;
            type.isActive = isActive;
            imgIndex = isActive && !!type.physical_path ? idx : imgIndex;

            return type;
        });

        if (mainGroup === 0 && imgIndex !== -1) {
            setIndex(imgIndex);
        }

        setDisplayProductType(newProductType);
    };
    const resetData = () => {
        setQuantityDemand(1);
        setReviewPage(1);
        setIndex(0);
    };

    useEffect(() => {
        if (productId) {
            const fetchData = async () => {
                const res = await dispatch(getProductDetails(productId)).unwrap();
                const {extend_images, extend_type} = res.data;
                const productImgs = [];

                extend_type.forEach((type) => {
                    if (type.sort_order === 0 && type.physical_path) {
                        productImgs.push({
                            url: `${Config.apiConfig.endPoint}${type.physical_path}`,
                            isActive: productImgs.length === 0
                        });
                    }
                });

                extend_images
                    .slice()
                    .sort((first, second) => first.sort_order - second.sort_order)
                    .forEach((img, idx) => {
                        productImgs.push({
                            url: `${Config.apiConfig.endPoint}${img.physical_path}`,
                            isActive: idx === 0 && productImgs.length === 0
                        });
                    });

                setProductImgs(productImgs);

                fetchAllReviewsByProductId();
            };

            fetchData();
            resetData();
        }
    }, [productId]);

    useEffect(() => {
        setFavProdId([]);
        dispatch(getRecommendList());
        dispatch(getHotDeals());
        dispatch(getlikeProductList());
    }, [dispatch]);
    useEffect(() => {
        if (productDetails.userLikeList) {
            productDetails.userLikeList.map((item) => {
                setFavProdId((preArr) => [...preArr, item.product_id]);
            });
        }
    }, [productDetails]);

    useEffect(() => {
        if (allReviewList) {
            const map = new Map();

            for (let i = 0; i < allReviewList.length; i++) {
                if (map.has(allReviewList[i].rating)) {
                    map.set(allReviewList[i].rating, map.get(allReviewList[i].rating) + 1);
                } else {
                    map.set(allReviewList[i].rating, 1);
                }
            }

            const newFilterByStartList = filterByStarList.map((item) => {
                if (map.has(item.value)) {
                    item.count = map.get(item.value);
                }
                return {...item};
            });
            setFilterByStarList(newFilterByStartList);

            const newFilterCategoryList = filterCategoryList.map((item) => {
                switch (item.key) {
                    case 'all':
                        item.count = allReviewList.length;
                        break;
                    default:
                        break;
                }
                return {...item};
            });
            setFilterCategoryList(newFilterCategoryList);
        }
    }, [productId, allReviewList]);

    useEffect(() => {
        const filterByStarValue = filterByStarList.find((item) => item.selected);
        const productReviewParams = {
            Filters: `rating==${filterByStarValue.value}`,
            Sorts: '-created_at',
            Page: reviewPage,
            PageSize: 3
        };

        dispatch(getProductReviews({id: productId, params: productReviewParams}));
    }, [productId, reviewPage, filterByStarList, filterCategoryList]);

    useLayoutEffect(() => {
        if (recommendDetails.length > 0) {
            setCurrentRecommendList(recommendDetails.slice(0, 16));
        }
    }, [recommendDetails]);

    useLayoutEffect(() => {
        if (!checkObjectEmpty(productInfo)) {
            const {extend_type, quantity, origin_price, price} = productInfo;
            if (extend_type && extend_type.length > 0) {
                const sortedExtendType = extend_type
                    .slice()
                    .sort((first, second) => first.sort_order - second.sort_order);
                const displayType = [];

                for (const type of sortedExtendType) {
                    const {sort_order} = type;

                    if (displayType[sort_order]) displayType[sort_order].push({...type, isActive: false});
                    else {
                        displayType[sort_order] = [{...type, isActive: true}];
                    }
                }
                setDisplayProductType(displayType);
            } else {
                setMainGroupTypeState({quantity, origin_price, price, subTypeId: ''});
            }
        }
    }, [productInfo]);

    useLayoutEffect(() => {
        if (displayProductType && displayProductType.length > 0) {
            const typeIds = [];
            displayProductType.forEach((gtypes) => {
                const activeType = gtypes.find((type) => type.isActive).id;
                typeIds.push(activeType);
            });
            const {extend_product_type} = productInfo;
            const productType = extend_product_type.find((type) => {
                const {type_id} = type;
                return type_id.every((id) => typeIds.includes(id));
            });

            if (productType) {
                const {quantity, id, price, origin_price} = productType;
                setMainGroupTypeState({quantity, id, price, origin_price});
            }
        }
    }, [displayProductType, productInfo]);

    const productDetailsContainerProps = {
        intl,
        navigateTo,
        productInfo,
        quantityDemand,
        productImgs,
        reviewPage,
        setReviewPage,
        allReviewList,
        productReviewsDetails,
        filterCategoryList,
        setFilterCategoryList,
        filterByStarList,
        setFilterByStarList,
        productDetails,
        currentRecommendList,
        setCurrentRecommendList,
        productId,
        showBuyModalPopup,
        showAddModalPopup,
        isBuyModal,
        closeModalPopup,
        isOpenPopup,
        addToCartHandler,
        index,
        setIndex,
        isOpenShareModal,
        closeShareModal,
        openShareModal,
        setQuantityDemand,
        mainGroupTypeState,
        displayProductType,
        handleProductTypeChange,
        toggleLightbox,
        isOpenLightbox,
        favProdId,
        handleLikeProduct,
        auth,
        checkFavProd
    };

    return <ProductDetailsMainView {...propsProvider(productDetailsContainerProps)} />;
}
