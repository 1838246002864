const i18nLabels = {
    vi: {
        searchLabel: 'Tìm kiếm sản phẩm',
        userName: 'Hi, Võ Đình Thiên',
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        filter: 'Lọc',
        signout: 'Đăng xuất',
        errorMessage: 'Đã xảy ra sự cố. Vui lòng thử lại sau giây lát',
        notification: 'Thông báo',
        readAll: 'Xem tất cả',
        myAccount: "Tài khoản của tôi",
        myOrder: "Đơn hàng của tôi",
        sellerPage: "Kênh người bán",
        emptyNoti: 'Bạn chưa có thông báo nào!',
        overSize: 'Tệp vượt quá giới hạn dung lượng cho phép',
        noDataFound: 'Không có kết quả được tìm thấy!'
    },
    en: {
        searchLabel: 'Search part name, part code, SKU...',
        userName: 'Hi, Võ Đình Thiên',
        filter: 'filter',
    }
};

export default i18nLabels;
