import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { forwardRef } from 'react';

const CustomSelect = forwardRef((props, ref) => {
    const {
        options = [],
        noPlaceholder = false,
        isRequired = false,
        valid = true,
        placeholder = 'placeholder',
        inputClassName = '',
        menuClassName = '',
        ...validSelectProps
    } = props;

    const { label, multiple = false, value = '', className = '' } = validSelectProps;

    return (
        <FormControl variant='outlined' error={!valid} fullWidth>
            <InputLabel id='select-label'>
                <span>
                    {label} {isRequired && <span className='text-required'>*</span>}
                </span>
            </InputLabel>
            <Select
                {...validSelectProps}
                labelId='select-label'
                value={value ? value : noPlaceholder ? '' : "defaultValue"}
                ref={ref}
                className={`custom-select ${className} ${multiple && value.length > 0 ? 'custom-select--shrink' : ''
                    } ${inputClassName}`}
                multiple={multiple}
                MenuProps={{ className: menuClassName }}>
                {!noPlaceholder && (
                    <MenuItem disabled value='defaultValue'>
                        {placeholder}
                    </MenuItem>
                )}
                {options.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default CustomSelect;
