import React, {useEffect} from 'react';
import NotificationFilter from './subViews/NotificationFilter';
import NotificationTable from './subViews/NotificationTable';
import {useState} from 'react';
import NotificationDetailSection from './subViews/NotificationDetailSection';
import BasicInformationSection from './subViews/BasicInfomationSection';
import NotificationFooterSection from './subViews/NotificationFooterSection';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';

const NotificationSettingMainView = (props) => {
    const {
        intl,
        notificationFilterBarProps,
        notificationTableProps,
        basicInformationProps,
        notificationDetailProps,
        NotificationFooterProps,
        isNotAdd,
        setIsAdd,
        isEdit,
        deleteId,
        setDeleteId,
        handleClodeModal,
        handleDelete,
        isDublicate,
        setIsEdit,
        setIsDublicate,
        setselectedItem
    } = props;

    const callbackAdd = (res) => {
        setIsAdd(res);
        setIsEdit(false);
        setIsDublicate(false);
        setselectedItem(null);
    };
    const DeleteModal = () => {
        return (
            <Dialog open={deleteId} onClose={handleClodeModal}>
                <DialogTitle>{intl.messages.deleteFDtitle}</DialogTitle>
                <DialogContent>{intl.messages.delteFdConfirm}</DialogContent>
                <DialogActions>
                    <CustomButton
                        size='md'
                        variant='text'
                        onClick={(e) => {
                            handleClodeModal();
                        }}>
                        Hủy
                    </CustomButton>
                    <CustomButton color='danger' size='md' onClick={handleDelete}>
                        Xác nhận
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    };
    if (isNotAdd && !isEdit && !isDublicate) {
        return (
            <div>
                <DeleteModal></DeleteModal>
                <div className='notification-setting'>
                    <div className='notification-setting__header'>
                        <NotificationFilter {...notificationFilterBarProps} />
                    </div>

                    <div className='notification-setting__body'>
                        <NotificationTable {...notificationTableProps} parentCallback={callbackAdd} />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='add-notification'>
                <BasicInformationSection {...basicInformationProps}></BasicInformationSection>
                <NotificationDetailSection {...notificationDetailProps}></NotificationDetailSection>
                <NotificationFooterSection
                    {...NotificationFooterProps}
                    parentCallback={callbackAdd}></NotificationFooterSection>
            </div>
        );
    }
};

export default NotificationSettingMainView;
