import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notificationApi from 'api/notificationApi';
import serviceRequest from 'app/serviceRequest';
import Config from 'configuration';

const initialHeader = () => {
    if (sessionStorage.getItem(Config.storageKey.header)) {
        return { ...JSON.parse(sessionStorage.getItem(Config.storageKey.header)) };
    }
    return {
        lang: 'vi',
        notificationDetails: {},
        mobileTitle: null
    };
};

export const getHeaderNotifications = createAsyncThunk('header/notification', async (data, thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: notificationApi.getNotifications,
        payload: data,
        options: {
            skipLoader: true
        }
    });
});

const header = createSlice({
    name: 'header',
    initialState: initialHeader(),
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;
            sessionStorage.setItem(Config.storageKey.header, JSON.stringify(state));
        },

        setMobileTitle: (state, action) => {
            state.mobileTitle = action.payload;
            sessionStorage.setItem(Config.storageKey.header, JSON.stringify(state));
        }
    },
    extraReducers: (builders) => {
        builders.addCase(getHeaderNotifications.fulfilled, (state, action) => {
            state.notificationDetails = action.payload.data;
        });
    }
});

const { reducer, actions } = header;
export const { setLang, setMobileTitle } = actions;
export default reducer;
