import AddressFormModal from "components/AddressFormModal/AddressFormModal";
import AddressSection from "./subViews/AddressSection";
import DeleteAddressModal from "./subViews/DeleteAddressModal";
import ShopInfoSection from "./subViews/ShopInfoSection";

export default function ShopInformationMainView(props) {
    const {shopInfoProps, addressProps} = props;
    const {addressFormModalProps, deleteModalProps} = addressProps;

    return (
        <div className='shop-information'>
            <AddressFormModal {...addressFormModalProps} />
            <DeleteAddressModal {...deleteModalProps} />
            <ShopInfoSection {...shopInfoProps} />
            <AddressSection {...addressProps} />
        </div>
    );
}
