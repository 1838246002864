import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import AmountRefund from './subViews/amountRefund';
import EmailRefund from './subViews/emailRefund';
import HeaderRefund from './subViews/headerRefund';
import NoteRefund from './subViews/noteRefund';
import ReasonRefund from './subViews/reasonRefund';
import CustomButton from 'components/CustomButton/CustomButton';
import ExtendWrapperContainer from 'features/PDP/template/subViews/ExtendWrapperContainer/ExtendWrapperContainer';

const MyRefundMainView = (props) => {
    const {
        intl,
        navigateTo,
        detailRefund,
        justSubmitSuccessfully,
        handleSubmitRefund,
        headerProps,
        reasonProps,
        noteProps,
        amountProps,
        emailProps
    } = props;

    return (
        <div className='refund'>
            <ExtendWrapperContainer>
                <HeaderRefund {...headerProps} />
                {!justSubmitSuccessfully && (
                    <div>
                        {!detailRefund ? (
                            <>
                                <ReasonRefund {...reasonProps} />
                                <NoteRefund {...noteProps} />
                                <AmountRefund {...amountProps} />
                                <EmailRefund {...emailProps} />
                                <div className='refund-footer'>
                                    <CustomButton variant='outlined' className='btnClose' onClick={navigateTo}>
                                        {intl.messages.closeButton}
                                    </CustomButton>
                                    <CustomButton variant='contained' onClick={handleSubmitRefund} className='btnDone'>
                                        {intl.messages.btnDone}
                                    </CustomButton>
                                </div>
                            </>
                        ) : (
                            <div className='refund-footer'>
                                <CustomButton variant='contained' onClick={navigateTo} className='btnClose'>
                                    {intl.messages.closeButton}
                                </CustomButton>
                            </div>
                        )}
                    </div>
                )}
            </ExtendWrapperContainer>
        </div>
    );
};

export default MyRefundMainView;
